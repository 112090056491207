import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loyalty-report',
  templateUrl: './loyalty-report.component.html',
  styleUrls: ['./loyalty-report.component.scss']
})
export class LoyaltyReportComponent implements OnInit {
  _globals = GlobalService

  colheader = [['Date', 'Location', 'Net Sale', 'Loyalty Sale', 'Loyalty Customer Count', 'Value Per Loyalty Customer', 'Points Redeemed', 'Discount Received', 'Points Acquired', 'Points Acquired $ value','Non-Loyalty Customers','Non-Loyalty Sales','Value per Non Loyalty Customer']];
  colflds = ['dateTime', 'locationName', 'salesTotal', 'loyaltySales', 'loyaltyCustomerCount', 'valuePerLoyaltyCustomer', 'pointsRedeemed', 'discountReceived', 'pointsAcquired', 'pointsAcquiredDollarValue','nonLoyaltyCustomerCount','nonLoyaltySales','valuePerNonLoyaltyCustomer'];
  summaryHeader = [['Date', 'Location', 'Sales Total ($)', 'Loyalty Sales ($)', 'Loyalty Customer Count', 'Value Per Loyalty Customer', 'Points Redeemed', 'Discount Received ($)', 'Points Acquired', 'Points Acquired $ value ($)','Non-Loyalty Customers','Non-Loyalty Sales','Value per Non Loyalty Customer']];
  domSummaryflds = ['locationName', 'salesTotal', 'loyaltySales', 'loyaltyCustomerCount', 'valuePerLoyaltyCustomer', 'pointsRedeemed', 'discountReceived', 'pointsAcquired', 'pointsAcquiredDollarValue','nonLoyaltyCustomerCount','nonLoyaltySales','valuePerNonLoyaltyCustomer'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];


  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';


  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3,Value:'All' }
    this._globals.componentName = 'Loyalty Report'
    this._abd.initDates()
    // this._abd.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;

    if (!selLocation || selLocation.length < 1) {
      selLocation = JSON.parse(JSON.stringify(this._abd.locations))
      this._filterService.selectedLocation = selLocation
    }

    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList(){
    this.getLocationsList();

    let x95 = this._filterService.selectedProductSellTypeFilter
    if (!x95) x95 = 'All'
    else x95 = x95.Value

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'LoyaltyDiscountReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'filterValue': this._filterService.searchText,
        'OrderPlacement' : x95
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._spinner.show();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if(x.isOnlineOrder){
          x.isOnlineOrder = 'Yes'
        }else if(!x.isOnlineOrder){
          x.isOnlineOrder = 'No'
        }
      })
      this._spinner.hide();
    })
    // console.log(data);
    // console.log(this.dataList);
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Loyalty Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Loyalty Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if(col == 'loyaltyCustomerCount' || col == 'pointsRedeemed' || col == 'nonLoyaltyCustomerCount' || col=='pointsAcquired'){
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }

      else if (col == 'salesTotal' || col == 'loyaltySales' || col == 'valuePerLoyaltyCustomer'  || col == 'discountReceived' || col == 'pointsAcquiredDollarValue' || col == 'nonLoyaltySales' || col == 'valuePerNonLoyaltyCustomer' ) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("percent")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2) + " %";
      }
      else {
        return "";
      }

      // total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
