import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs';
import { Vendor } from '../_models/vendor';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  _globals = GlobalService
  showNewEdit=false
  showNewNewEdit=false
  selectedState:any=''

  city:any=''
  isViewOnly = false
  activeItem:any = null
  isNewVendor = false

  constructor(private _bs: BaseService) {
    this.initActiveItem()
  }

initActiveItem() {
  this.activeItem = {
    vendorName: '',
    isActive: true,
    id: null,

    account: '',
    company: '',
    vendorWebsite: '',
    phone: '',

    street: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    terms: '',

    createdDate: new Date(),
  }
}
  getAllVendors(params: any, auth: boolean = false, showSpinner: boolean = true) {
    let url = environment.InventoryBaseURL+ environment.VendorURL

    return this._bs.httpGetParams(url, params).pipe(map(res => {
      return res
    }))
  }

  createUpdateVendor(vendor: any, isNew:boolean) {
    let url = environment.InventoryBaseURL+ environment.VendorURL
    // url += vendor.id !== '' ? ('/' + vendor.id) : ''
    if(!isNew) url += '/' + vendor.id

    if (!isNew) {
      return this._bs.httpPut(
        url,
        JSON.stringify(vendor), true).pipe(map(res => {
          if (res.success) {
            return res
          }
        }))
    } else {
      return this._bs.httpPost(
        url,
        JSON.stringify(vendor), true).pipe(map(res => {
          if (res.success) {
            return res
          }
        }))
    }
  }

  deleteVendor(id: string)
  {
    let url = environment.InventoryBaseURL+ environment.VendorURL+"/"+id ;

    return this._bs.httpDelete(url).pipe(map(response=>{
      return response;

    }))
  }

}
