import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CashDropConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-cashdrop-configuration',
  templateUrl: './cashdrop-configuration.component.html',
  styleUrls: ['./cashdrop-configuration.component.scss']
})
export class CashdropConfigurationComponent implements OnInit {
  cashdropform: FormGroup;
  cashdropSettingData: CashDropConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.cashdropSettingData = {
      cashDropThreshhold: this._abd.systemConfigs.cashDropConfig.cashDropThreshhold,
      cashDropWarningLimit: this._abd.systemConfigs.cashDropConfig.cashDropWarningLimit,
      lockInvoiceCreationAt: this._abd.systemConfigs.cashDropConfig.lockInvoiceCreationAt,
      active: this._abd.systemConfigs.cashDropConfig.active,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    const enable=this.cashdropform.get('enableCashdropForm');
    enable?.valueChanges
      .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){
    if(value==false){
      this.cashdropform.get('cashdropThreshold')?.disable();
      this.cashdropform.get('cashdropWarning')?.disable();
      this.cashdropform.get('lockInvoice')?.disable();
    }else if (value==true){
      this.cashdropform.get('cashdropThreshold')?.enable();
      this.cashdropform.get('cashdropWarning')?.enable();
      this.cashdropform.get('lockInvoice')?.enable();
    }
  }

  initForm() {
    this.cashdropform = this._fb.group({
      enableCashdropForm: [this.cashdropSettingData.active],
      cashdropThreshold : [{value:this.cashdropSettingData.cashDropThreshhold,disabled:!this.cashdropSettingData.active}, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      cashdropWarning : [{value:this.cashdropSettingData.cashDropWarningLimit,disabled:!this.cashdropSettingData.active}, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      lockInvoice : [{value:this.cashdropSettingData.lockInvoiceCreationAt,disabled:!this.cashdropSettingData.active}, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
    })
  }

  get f() {
    return this.cashdropform.controls;
  }

  saveFn(){
    let cashdropValue: CashDropConfig = {
      cashDropThreshhold: this.cashdropform.get('cashdropThreshold').value,
      cashDropWarningLimit: this.cashdropform.get('cashdropWarning').value,
      lockInvoiceCreationAt: this.cashdropform.get('lockInvoice').value,
      active: this.cashdropform.get('enableCashdropForm').value,
      isSaved: true,
    }
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveCashdropConfiguration(cashdropValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.cashDropConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Cashdrop settings saved successfully!`, 'success')
          this._businessSetupService.showCashdropConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("cashdrop");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving cashdrop settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showCashdropConfiguration = false
  }

}
