<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div>
    <h2 class="bs-page-header mb-3">Purchase Order - Direct Receive</h2>
</div>

<div class="grid">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-4 mt-3">PO#</div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="_purchaseService.newEditItem.PONumber"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">Receive#</div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="receiceId"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">Rec. Date</div>
                <div class="col">
                    <p-calendar
                        [(ngModel)]="dated"
                        showIcon="true"
                        [style]="{ width: '100%' }"
                        dateFormat="D d, MM yy"
                        [disabled]="isViewOnly"
                    ></p-calendar>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-4 mt-3">Location</div>
                <div class="col">
                    <p-dropdown
                        [options]="locations"
                        [(ngModel)]="selectedLocation"
                        optionLabel="locationName"
                        [style]="{ width: '100%' }"
                        (onChange)="locationChanged($event)"
                        [disabled]="isViewOnly"
                    >
                    </p-dropdown>
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">
                    Vendor<span style="margin-left: 5px"
                        ><a
                            style="
                                background-color: var(--bs-info);
                                color: white;
                                padding: 2px;
                                border-radius: 50%;
                            "
                            class="text-red-600 font-bold"
                            href="javascript:void(0);"
                            (click)="showVendorInfoDialogFn()"
                            ><span
                                ><i
                                    class="pi pi-info"
                                    style="color: white"
                                ></i></span></a
                    ></span>
                </div>
                <div class="col">
                    <p-dropdown
                        [options]="vendors"
                        [(ngModel)]="selectedVendor"
                        optionLabel="vendorName"
                        [style]="{ width: '100%' }"
                        (onChange)="vendorChanged($event)"
                        [disabled]="isViewOnly"
                    >
                    </p-dropdown>
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">Invoice# <span class="req" style="color:red;">*</span></div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        placeholder='Invoice#'
                        [(ngModel)]="invoiceNo"
                        pInputText
                        [disabled]="isViewOnly"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-12">Received By</div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="receivedByName"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
                <div class="grid mt-3">
                    <div class="col-12">
                        <div>Mode</div>
                    </div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="method"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="conn-card">
    <div class="grid">
        <div class="col-fixed" style="width: 300px">
            <div class="p-inputgroup">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search by scanning barcode"
                    [(ngModel)]="searchText"
                    (keydown.enter)="searchButtonClick($event)"
                    [disabled]="isViewOnly"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                    [disabled]="isViewOnly"
                ></button>
            </div>
        </div>
        <div class="col">
            <div class="flex justify-content-end flex-wrap" *ngIf="!isViewOnly">
                <div class="flex align-items-center justify-content-center m-2">
                    <button
                        *ngIf="showDeleteButton()"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                        iconPos="right"
                        (click)="removeItem()"
                        [disabled]="isViewOnly"
                    ></button>
                </div>

                <div class="flex align-items-center justify-content-center m-2">
                    <button
                        *ngIf="_purchaseService.newEditItem.POStatus == 'DRAFT'"
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-warning p-button-outlined'
                                : 'p-button-rounded p-button-info p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                        [disabled]="isViewOnly"
                    ></button>
                </div>
            </div>
        </div>
    </div>

    <br />
    <div>
        <p-table
            #www
            [value]="dataList"
            [autoLayout]="true"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center; width: 30px">
                        <p-checkbox
                            *ngIf="!isViewOnly"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th>
                    <th>Sr#</th>
                    <th>Barcode</th>
                    <th>SKU</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th style="text-align: center">Received</th>
                    <th style="text-align: center">Unit Cost($)</th>
                    <th style="text-align: center">Price A($)</th>
                    <th style="text-align: center">Price B($)</th>
                    <th style="text-align: center">Price C($)</th>
                    <th style="text-align: center">Discount(%)</th>
                    <th style="text-align: center">Net Cost</th>
                    <th style="text-align: center">Tax(%)</th>
                    <th style="text-align: center">Shipping($)</th>
                    <th style="text-align: right">Total Cost($)</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">
                        <p-checkbox
                            *ngIf="!isViewOnly"
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td>
                    <td class="select-none">{{ item.srNo }}</td>
                    <td class="select-none">{{ item.barcode }}</td>
                    <td class="select-none">{{ item.skuCode }}</td>
                    <td class="select-none">{{ item.productName }}</td>
                    <!-- <td class="select-none">
                        {{ item.description | slice : 0 : 25 }}
                        <span *ngIf="item.description?.length > 25">...</span>
                    </td> -->
                    <td class="select-none">{{ item.uomDescription }}</td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.receivedQuantity"
                                    [minFractionDigits]="0"
                                    [maxFractionDigits]="0"
                                    inputId="minmax-buttons"
                                    [min]="1"
                                    [max]="9999"
                                    (onInput)="orderedQtyChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.costPrice"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="costPriceChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                            <div
                                class="flex justify-content-start text-red-500 mt-1"
                                *ngIf="!costPriceValid(item) && !isViewOnly"
                            >
                                <span style="font-size: 12px">
                                    Cost price cannot be less than zero (0)
                                </span>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.salePriceA"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.salePriceB"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.salePriceC"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.discountPercent"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    [max]="100"
                                    placeholder="0"
                                    (onInput)="
                                        discountAmountChanged($event, item)
                                    "
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.netTotal"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '120px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.taxRate"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="taxAmountChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.shipping"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'right'
                                    }"
                                    (onInput)="
                                        itemShippingChanged($event, item)
                                    "
                                    [disabled]="
                                        isViewOnly ||
                                        divideShippingCostInput
                                    "
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.totalCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="16" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- ============================ -->
<br />
<div class="grid">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <label for="notes">Notes</label>
            <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                class="w-full"
                [(ngModel)]="notes"
                style="margin-top: 10px"
                [disabled]="isViewOnly"
            ></textarea>
        </div>
        <!-- <div class="conn-card" *ngIf="isFooterNoteEnabled">
            <label [innerHTML]="footerNotesText"></label>
        </div> -->
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <!-- <p-radioButton
                name="groupname"
                value="enable"
                label="Enable Item Level Shipping Cost"
                [(ngModel)]="selectedShippingCost"
                (onClick)="shippingOptionRadioButtonClicked()"
                [disabled]="isViewOnly"
            ></p-radioButton> -->
            <p-checkbox *ngIf="!isViewOnly"
                name="groupname_new"
                label="Divide Shipping Cost across all items"
                [(ngModel)]="divideShippingCostInput"
                (onChange)="shippingOptionButtonClicked()"
                class="mt-2"
                [disabled]="isViewOnly"
                [binary]="true"
            ></p-checkbox>
            <!-- <p-radioButton
                name="groupname"
                value="no-divide"
                label="Don’t Divide Shipping Across Items"
                [(ngModel)]="selectedShippingCost"
                (onClick)="shippingOptionRadioButtonClicked()"
                class="mt-2"
                [disabled]="isViewOnly"
            ></p-radioButton> -->
            <!-- <p-checkbox
            class="mt-7 flex text-lg text-500"
            name="groupname"
            value="valB"
            label="Divide Shipping Cost  across all items"
            [(ngModel)]="selectedOptions"
            (onChange)="onChangeValA($event)"
            [disabled]="isReadOnly"
        ></p-checkbox> -->
            <p-checkbox
                [(ngModel)]="postToInventory"
                [binary]="true"
                label="Post to Inventory"
                class="mt-3"
                [disabled]="isViewOnly"
            ></p-checkbox>

        </div>
    </div>
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Sub Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gsubtotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Total Discount($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gdiscount"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Total Tax($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gtax"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Shipping($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="shippingCost"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        (onInput)="shippingChanged($event)"
                        [disabled]="!divideShippingCostInput|| isViewOnly"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Grand Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="grandTotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<div class="flex justify-content-end flex-wrap">
    <div
        class="flex align-items-center justify-content-center m-2"
        *ngIf="!isViewOnly"
    >
        <p-checkbox
            [(ngModel)]="isDuplicate"
            [binary]="true"
            label="Duplicate"
            class="font-bold text-lg text-700"
            [disabled]="isViewOnly"
        ></p-checkbox>
    </div>
    <div class="flex align-items-center justify-content-center m-2">
        <button
            pButton
            type="button"
            label="Cancel"
            class="p-button-danger"
            [style]="{ width: '120px' }"
            (click)="cancelPO()"
        ></button>
    </div>
    <div
        class="flex align-items-center justify-content-center m-2"
        *ngIf="!isViewOnly"
    >
        <button
            pButton
            type="button"
            label="Save"
            [style]="{ width: '120px' }"
            [disabled]="isViewOnly || dataList.length == 0"
            (click)="savePO('DRAFT')"
        ></button>
    </div>
    <div class="flex align-items-center justify-content-center m-2">
        <button
            pButton
            class="p-button-help"
            label="Post"
            [style]="{ width: '120px' }"
            [disabled]="dataList.length == 0"
            (click)="savePO('CLOSEDFORRECEIVING')"
        ></button>
    </div>
</div>
<!-- ============================ -->
<p-dialog
    *ngIf="showVendorInfoDialog"
    header="Vendor Information"
    [(visible)]="showVendorInfoDialog"
    modal="false"
    [appendTo]="www"
>
    <app-new-edit-vendor
        [isNew]="false"
        [showActionButtons]="false"
    ></app-new-edit-vendor>
</p-dialog>
