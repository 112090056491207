import { Component, OnInit } from '@angular/core';

interface dayModel {
    name: string,
    title: string
}

interface CheckBoxOptions {
    title: string;
    active: boolean;
}

@Component({
  selector: 'app-points-redemption',
  templateUrl: './points-redemption.component.html',
  styleUrls: ['./points-redemption.component.scss']
})
export class PointsRedemptionComponent implements OnInit {

    daysList: dayModel[];

    selectedDays: dayModel[];
    date8: Date;
    InputCashierAlert: boolean = false;
    cashierAlerts: CheckBoxOptions[] = [];
    constructor() { }

    ngOnInit(): void {
        this.daysList = [
            { name: 'Sunday', title: 'Sunday' },
            { name: 'Monday', title: 'Monday' },
            { name: 'Tuesday', title: 'Tuesday' },
            { name: 'Wednesday', title: 'Wednesday' },
            { name: 'Thursday', title: 'Thursday' },
            { name: 'Friday', title: 'Friday' },
            { name: 'Saturday', title: 'Saturday' },
        ];

        this.selectedDays = [{ name: 'Sunday', title: 'Sunday' }, { name: 'Monday', title: 'Monday' }]
        this.date8 = new Date();

        this.cashierAlerts = [
            { title: 'Display the "Reward Redemption" prompt on the cashier screen when the designed points goal is reached.', active: false },
            { title: 'Trigger a "Sound Alert" for the cashier when the designed points goal is reached', active: false },
        ];
    }

}
