<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'hourly-sales-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()">
    </app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"  [autoLayout]="true"  responsiveLayout="scroll" *ngIf="dataList?.length > 0">
            <ng-template pTemplate="header">
                <tr>
                    <th  style="text-align: center" *ngFor="let col of domColHeader">
                        <strong>{{col}}</strong>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded">
                <tr>
                    <td style="text-align: center">{{ product.locationName }}</td>
                    <td style="text-align: center">
                        <span *ngIf="product.hour > -1">
                            <span *ngIf="product.hour < 12">{{product.hour}} AM</span>
                            <span *ngIf="product.hour == 12">{{product.hour}} PM</span>
                            <span *ngIf="product.hour > 12">{{product.hour -12}} PM</span>
                        </span>
                    </td>
                    <td style="text-align: center">{{ product.invoiceCount | number }}</td>
                    <td style="text-align: center">{{ product.salesItemCount | number }}</td>
                    <td style="text-align: center">{{ product.returnItemCount | number }}</td>
                    <td style="text-align: center"> {{ product.voidItemCount | number }}</td>
                    <td style="text-align: center">{{ product.totalCost | currency }}</td>
                    <td style="text-align: center">{{ product.totalSale | currency }}</td>
                    <td style="text-align: center">{{ product.salesTax | currency }}</td>
                    <td style="text-align: center">{{ product.grandTotal | currency }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr *ngIf="dataList?.length > 0">
                    <td rowspan="2" colspan="1" style="text-align: center">
                        <strong>Total</strong>
                    </td>
                    <td></td>
                    <td *ngFor="let col of domSummaryflds" style="text-align: center">
                        {{calculateSummaryTotal(col)}}
                    </td>
                </tr>
            </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
