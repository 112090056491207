import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrintingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-printing-configuration',
  templateUrl: './printing-configuration.component.html',
  styleUrls: ['./printing-configuration.component.scss']
})
export class PrintingConfigurationComponent implements OnInit {

  printingConfigurationform: FormGroup;
  printSettingData: PrintingConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.printSettingData = {
      active: this._abd.systemConfigs.printingConfig.active,
      enableRecieptPrinting: this._abd.systemConfigs.printingConfig.enableRecieptPrinting,
      enableElectricPaymentPrinting: this._abd.systemConfigs.printingConfig.enableElectricPaymentPrinting,
      enableCashDropPrinting: this._abd.systemConfigs.printingConfig.enableCashDropPrinting,
      enablePayOutPrinting: this._abd.systemConfigs.printingConfig.enablePayOutPrinting,
      isShowVoidItemsOnReciept: this._abd.systemConfigs.printingConfig.isShowVoidItemsOnReciept,
      enableReceiptBarCodePrinting: this._abd.systemConfigs.printingConfig.enableReceiptBarCodePrinting,
      footerText: this._abd.systemConfigs.printingConfig.footerText,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    const enable=this.printingConfigurationform.get('enableConfigurationForm');
    enable?.valueChanges
      .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){
    if(value==false){
      this.printingConfigurationform.get('receiptPrinting')?.disable();
      this.printingConfigurationform.get('paymentPrinting')?.disable();
      this.printingConfigurationform.get('cashdropPrinting')?.disable();
      this.printingConfigurationform.get('payoutPrinting')?.disable();
      this.printingConfigurationform.get('voidItemPrinting')?.disable();
      this.printingConfigurationform.get('barcodePrinting')?.disable();
      this.printingConfigurationform.get('footerText')?.disable();
    }else if (value==true){
      this.printingConfigurationform.get('receiptPrinting')?.enable();
      this.printingConfigurationform.get('paymentPrinting')?.enable();
      this.printingConfigurationform.get('cashdropPrinting')?.enable();
      this.printingConfigurationform.get('payoutPrinting')?.enable();
      this.printingConfigurationform.get('voidItemPrinting')?.enable();
      this.printingConfigurationform.get('barcodePrinting')?.enable();
      this.printingConfigurationform.get('footerText')?.enable();
    }
  }

  initForm() {
    this.printingConfigurationform = this._fb.group({
      enableConfigurationForm: [this.printSettingData.active, Validators.required],
      receiptPrinting : [{value:this.printSettingData.enableRecieptPrinting,disabled:!this.printSettingData.active}, Validators.required],
      paymentPrinting : [{value:this.printSettingData.enableElectricPaymentPrinting,disabled:!this.printSettingData.active}, Validators.required],
      cashdropPrinting : [{value:this.printSettingData.enableCashDropPrinting,disabled:!this.printSettingData.active}, Validators.required],
      payoutPrinting : [{value:this.printSettingData.enablePayOutPrinting,disabled:!this.printSettingData.active}, Validators.required],
      voidItemPrinting : [{value:this.printSettingData.isShowVoidItemsOnReciept,disabled:!this.printSettingData.active}, Validators.required],
      barcodePrinting : [{value:this.printSettingData.enableReceiptBarCodePrinting,disabled:!this.printSettingData.active}, Validators.required],
      footerText: [{value:this.printSettingData.footerText,disabled:!this.printSettingData.active}],
    })
  }

  saveFn(){
    let printingValue: PrintingConfig = {
      active: this.printingConfigurationform.get('enableConfigurationForm').value,
      enableRecieptPrinting: this.printingConfigurationform.get('receiptPrinting').value,
      enableElectricPaymentPrinting: this.printingConfigurationform.get('paymentPrinting').value,
      enableCashDropPrinting: this.printingConfigurationform.get('cashdropPrinting').value,
      enablePayOutPrinting: this.printingConfigurationform.get('payoutPrinting').value,
      isShowVoidItemsOnReciept: this.printingConfigurationform.get('voidItemPrinting').value,
      enableReceiptBarCodePrinting: this.printingConfigurationform.get('barcodePrinting').value,
      footerText: this.printingConfigurationform.get('footerText').value,
      isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.savePrintingConfiguration(printingValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.printingConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Print settings saved successfully!`, 'success')
          this._businessSetupService.showPrintingConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("printing");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving print settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showPrintingConfiguration = false
  }

}
