import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-purchasing-report',
  templateUrl: './purchasing-report.component.html',
  styleUrls: ['./purchasing-report.component.scss']
})
export class PurchasingReportComponent implements OnInit {
    _globals = GlobalService


  colheader = [['Location', 'PO #', 'Date', 'Status', 'Item', 'Barcode', 'SKU (#)','Orignal Order', 'Came In', 'Due', 'Orignal Cost Each','Orignal Total', 'Received Cost Each', 'Received Total','Committed Qty' ]];
  colflds = ['Location', 'PO #', 'Date', 'Status', 'Item', 'Barcode', 'SKU (#)','Orignal Order', 'Came In', 'Due', 'Orignal Cost Each','Orignal Total', 'Received Cost Each', 'Received Total','Committed Qty' ];
  summaryHeader = [['Location', 'PO #', 'Date', 'Status', 'Item', 'Barcode', 'SKU (#)','Orignal Order', 'Came In', 'Due', 'Orignal Cost Each','Orignal Total', 'Received Cost Each', 'Received Total','Committed Qty' ]];
  domSummaryflds = ['Location', 'PO #', 'Date', 'Status', 'Item', 'Barcode', 'SKU (#)','Orignal Order', 'Came In', 'Due', 'Orignal Cost Each','Orignal Total', 'Received Cost Each', 'Received Total','Committed Qty' ];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Purchasing Report'
    this._abd.selectedLocation = this._abd.locations[0];
    this._abd.selectedExtra1Location = this._abd.locations[0];
    this._abd.selectedTranferType = this._abd.transferType[0];
    this._abd.selectedTranferStatus = this._abd.transferStatus[0];
    if (!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date('1800-12-12')
    if (!this._abd.dateRange[1]) this._abd.dateRange[1] = new Date()
    this._abd.selectedLocation = null
    this._abd.initDates()
  }
  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._abd.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    const data = {
      "ReportName": 'TransferDetailReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "SenderLocationIds": [this._abd.selectedLocation.locationId.toString()],
        "ReceiverLocationIds": [this._abd.selectedExtra1Location.locationId.toString()],
        "TransferDetailsStatus": this._abd.selectedTranferStatus.value,
        "TransfersStatusCheck": this._abd.selectedTranferType.value,
        'filterValue': this._abd.searchText,
        'OrderPlacement': this._abd.selectedProductSellTypeFilter.Value
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if (x.isOnline) {
          x.isOnline = 'Yes'
        } else if (!x.isOnline) {
          x.isOnline = 'No'
        }
      })
      this._abd.hideSpinner();
    })
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Transfer Detail Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Transfer Detail Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("unitPrice") || col.includes("totalPrice") ||  col.includes("grossProfit")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("totalTransferredQty")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }


}
