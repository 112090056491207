<!-- <p-sidebar [(visible)]="showFilterPanel" position="right" [baseZIndex]="10000">
    <div class="conn-grid-col">
        <div>
            <h4 style="font-weight: normal">Filters</h4>
            <br />
        </div>
        <div class="p-float-label">
            <p-multiSelect
                [options]="locations"
                [(ngModel)]="selectedLocation"
                optionLabel="locationName"
                display="chip"
                appendTo="body"
                [style]="{ width: '250px' }"
            ></p-multiSelect>
            <label for="multiselect">Locations</label>
        </div>

        <div class="p-float-label" style="margin-top: 30px">
            <p-calendar
                [(ngModel)]="dateRange"
                selectionMode="range"
                [readonlyInput]="false"
                inputId="multiple"
                baseZIndex="0"
                appendTo="body"
                [style]="{ 'min-width': '100%' }"
            ></p-calendar>
            <label for="multiselect">Date Range</label>
        </div>

        <div>
            <button
                pButton
                type="button"
                label="Search"
                icon="pi pi-search"
                (click)="filterButtonPressed()"
                iconPos="right"
                [style]="{ 'margin-top': '30px', 'min-width': '100%' }"
            ></button>
        </div>
    </div>
</p-sidebar> -->

<div class="conn-card">
    <app-report-top-filter-bar *ngIf="1==1"
        [paraReportName]="'return-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>

    <!-- <div class="conn-flex">
        <div>
            <div *ngIf="!showFilterPanel" style="margin-top:20px;">
                <div class="p-inputgroup">
                    <div class="p-float-label">
                        <p-multiSelect
                            [options]="locations"
                            [(ngModel)]="selectedLocation"
                            optionLabel="locationName"
                            display="chip"
                            [style]="{ 'min-width': '100%' }"
                        ></p-multiSelect>
                        <label for="multiselect">Locations</label>
                    </div>
                    <div class="p-float-label" style="margin-left: 20px">
                        <p-calendar
                            [(ngModel)]="dateRange"
                            selectionMode="range"
                            [readonlyInput]="false"
                            inputId="multiple"
                        ></p-calendar>
                        <label for="multiselect">Date Range</label>
                    </div>

                    <div>
                        <button
                            pButton
                            type="button"
                            label="Search"
                            icon="pi pi-search"
                            iconPos="right"
                            [style]="{'margin-left':'10px'}"
                            (click)="filterButtonPressed()"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-th-large"
            class="p-button-rounded p-button-text"
            tooltipPosition="left"
            pTooltip="Click for filter options"
            style="margin-left: 20px"
            (click)="showFilterPanel = true"
        ></button>
    </div> -->

    <br />
    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center">Location</th>
                <th style="text-align: center">Cashier Name</th>
                <th style="text-align: center"># Return Invoices</th>
                <th style="text-align: center">Cash</th>
                <th style="text-align: center">Credit Card</th>
                <th style="text-align: center"># Items Return</th>
                <th style="text-align: center">Tax</th>
                <th style="text-align: center">Total Value</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-expanded="expanded">
            <tr *ngFor="let order of product.cashierReturns">
                <td style="text-align: center">{{ product.locationName }}</td>
                <td style="text-align: center">{{ order.userName }}</td>
                <td style="text-align: center">{{ order.invoiceCount }}</td>
                <td style="text-align: center">{{ order.cash | currency }}</td>
                <td style="text-align: center"> {{ order.creditCard | currency }}</td>
                <td style="text-align: center">{{ order.itemsCount }}</td>
                <td style="text-align: center">{{ order.tax | currency }}</td>
                <td style="text-align: center">{{ order.grandTotal | currency }}</td>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="footer">
            <tr >
                <td colspan="2" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>


<br />
<!-- <div class="conn-card">
    <ul style="color:rgb(151, 148, 148)">
        <li># Return Invoices is the total number of invoices generated by a system</li>
        <li>Clerk Count is the total number of clerks per store performing the returns</li>
        <li># Items is the total number of items returned</li>
        <li>Total Value is the value of all the items returned</li>
      </ul>
</div> -->
