import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-cash-reconciliation',
  templateUrl: './cash-reconciliation.component.html',
  styleUrls: ['./cash-reconciliation.component.scss']
})
export class CashReconciliationComponent implements OnInit,AfterViewInit  {
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  step = 1
  title = "End of Day (EOD)"
  title2 = ""

  totalCount = 0
  totalCoinsCount = 0
  totalDeposit = 0
  totalDepositCD = 0

  topTags: any = null
  dataList: any = null
  drawerCountList: any = null
  managerCountObj: any = null
  managerCountList: any = null
  eodList: any = null
  cols: any[]
  registers: any = []

  isViewOnly = false
  selectedRow1: any = null
  selectedDC: any = null
  selectedLocations: any = null

  cdSelectedLocation: any = null
  cdSelectedRegister: any = null

  isConfirmedDC = false
  enableDC = true
  enableRectification = true
  showFilter = true
  showCashDeposit = false

  drawerCountSummaryTotals: any = null

  drawerCountVerify: MenuItem[]
  lastDateRange: any = null

  mockData: any = []
  mockDataCD: any = []

  col1 = 'col-fixed font-bold text-sm white-space-nowrap'
  col2 = 'col text-600 text-sm'

  csvcols = [
    { field: '', header: 'Location' },
    { field: '', header: 'Counted By' },
    { field: '', header: 'Reg' },
    { field: '', header: 'Date/Time' },
    { field: '', header: '$100' },
    { field: '', header: '$50' },
    { field: '', header: '$20' },
    { field: '', header: '$10' },
    { field: '', header: '$5' },
    { field: '', header: '$2' },
    { field: '', header: '$1' },
    { field: '', header: '25 cents (Quarters)' },
    { field: '', header: '10 cents (Dimes)' },
    { field: '', header: '5 cents (Nickels)' },
    { field: '', header: '1 cent (Penny)' },
    { field: '', header: 'Total Count' },
    { field: '', header: 'System Count' },
    { field: '', header: 'Over/Short' },
    { field: '', header: 'Last Count' },
    { field: '', header: 'Verify' },
  ]

  _globals = GlobalService
  @ViewChild('dcmenu') dcmenu: OverlayPanel

  showCashDrop: boolean = false;
  cashDropList : any[] =[];

  visibleModal : boolean = false;
  selectLocationFilter: any[] =[]
  tillReportData: any[] = [];
  tillReportTotal: number = 0;

  currentShowingTableNumber : number = 1;

    payoutReportData: any[] = [];
    payoutReportTotal: number = 0;

    payInReportData: any[] = [];
    payInReportTotal: number = 0;

    refundReportData: any[] = [];
    refundReportTotal: number = 0;

    currentOpenedAccordion: number = 0;

    expectedCashInfoList: any[] = [];
    popupHeaderInfo : string = '';

    allowAddingCashDrop: boolean = false;
    allowAddingCashCount: boolean = false;
    lastReconciliationDate: Date = new Date()

    dropCashTime: Date = new Date();
    today: Date = new Date();

  constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Reconciliation'
    this._globals.secondTitle = 'End of Day (EOD)'

    try {
      if (this._abd.locations.length < 1) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error', detail: 'No locations in system location!'
        })
        window.history.back()
      }
    } catch {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'System locations error!'
      })
      window.history.back()
    }
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      try {
        let ppages = this._filterService.getPages(this._globals.componentName)
        if (ppages) {
          let lastpage = parseInt(ppages.page)
          if (lastpage < 0) lastpage = 0
          if (lastpage > 0) {
            this.page = lastpage
            this.first = lastpage * this.rows
          }
        }

        let filter = this._filterService.getFilters(this._globals.componentName)
        if (filter) {
          this._filterService.dateRange = []
          if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
          if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

          if (filter.selectedLocation) {
            this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == filter.selectedLocation)
          } else {
            this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations[0]))
          }
        } else {
          const loc = this._filterService.selectedLocation?.locationId
          if (!loc)
            this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.actLocations[0]
            ))
          if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
            this._filterService.initDates()
        }
      } catch { clearInterval(ti) }

      clearInterval(ti)
      this.getDataList()
    }, 100);

  }

  ngOnDestroy() {
    this._globals.componentName = ''
    this._globals.secondTitle = ''
  }

  getLocations() {
    let locations = []
    const loc = this._filterService.selectedLocation
    if (!loc) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
      return locations
    }

    for (let i = 0; i < loc.length; i++) {
      const id = loc[i].LocationId
      if (id)
        locations.push(id)
    }
    return locations
  }

  getDataList() {
    this.showFilter = true

    let locations = []

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
    } else {
      locations.push(this._filterService.selectedLocation.locationId)
    }

    const sd = this._filterService.dateRange[0]
    const ed = this._filterService.dateRange[1]
    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    let body =
    {
      "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'), //"12/29/2022",
      "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'), //"12/30/2022",
      "LocationIds": locations
    }
      this.selectLocationFilter = locations

    const _url = environment.OrderBaseURL + 'ReconciliationEOD'
    this._spinner.show()
    this._bs.httpPost(_url, body).subscribe(res => {
      if (res.success) {
        // this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
        this.eodList = res.data

        for (let i = 0; i < this.eodList.length; i++) {
          this.eodList[i].dated = new Date(this.eodList[i].dated)
        }

        // hide line start
        // this.eodList.map(f=> f.allowReconcilation = false);
        // this.eodList[0].allowReconcilation = true;
        // hide line end
          //  this.lastReconciliationDate = this.eodList[this.eodList.findLastIndex(f => f.isManagerCount)].dated
          //  this.eodList.slice(0, this.eodList.findLastIndex(f => f.isManagerCount)).map( f=> f.isManagerCount = true)

        this._spinner.hide()
      }
    })

    const outfilter = {
      selectedLocation: locations[0],
      startDate: this._filterService.dateRange[0],
      endDate: this._filterService.dateRange[1]
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
  }

  getCashDropDataList() {
    this.showFilter = true

    let locations = []

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
    } else {
      locations.push(this._filterService.selectedLocation.locationId)
    }

    const sd = this._filterService.dateRange[0]
    const ed = this._filterService.dateRange[1]
    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    let body =
    {
      "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'), //"12/29/2022",
      "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'), //"12/30/2022",
      "LocationIds": locations
    }

    const _url = environment.OrderBaseURL + 'ReconciliationEOD'
    this._spinner.show()
    this._bs.httpPost(_url, body).subscribe(res => {
      if (res.success) {
        // this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
        this.eodList = res.data

        for (let i = 0; i < this.eodList.length; i++) {
          this.eodList[i].dated = new Date(this.eodList[i].dated)
        }
        this._spinner.hide()
      }
    })

    const outfilter = {
      selectedLocation: locations[0],
      startDate: this._filterService.dateRange[0],
      endDate: this._filterService.dateRange[1]
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
  }

  reconcileClicked(item) {
    this.selectedRow1 = item
    // this.selectedRow1.push(item)
    this.isViewOnly = false

    let ti = null
    ti = setInterval(() => {
      this._filterService.singleDateForReconcile = ''
      this._filterService.singleDateForReconcile = new Date(item.dated)
      this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == item.locationId)

      clearInterval(ti)
      this.getDrawerCountList()
    }, 200);
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  getLocationsList() {
    let locationIds = [];
    let sel = this._filterService.selectedLocation
    if (!sel) return locationIds

    sel = (typeof sel != 'undefined' && sel instanceof Array) ? sel : [sel];
    sel.map(function (value: { locationId: { toString: () => string; }; }) {
      locationIds.push(value.locationId.toString())
    })
    return locationIds
  }

  makeCashierList() {
    let arr = []
    let obj = this.dataList
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }
    this._abd.cashiers = arr
  }

  calculateSummaryTotal(what) {
    try {
      const r = this.topTags.find(f => f.id == what)
      return r.value
    }
    catch { return 0 }
  }

  calcMockup() {
    for (let i = 0; i < this.mockData.length; i++) {
      this.mockData[i].total = this.mockData[i].qty * this.mockData[i].value
    }
  }

  getOverShortColor(tag) {
    // if (tag.id !== 3) return ""
    // if (tag.id == 4 && tag.value < 0) return "background: var(--red-400); color: white;"
    // else if (tag.id == 4 && tag.value > 0) return "background: var(--brandColor); color: white !important; "
    // else
    return ""
  }

  getOverShortCColor(tag) {
    // if (tag.id !== 4) return ""
    // if (tag.id == 4 && tag.value < 0) return "color: white;"
    // else if (tag.id == 4 && tag.value > 0) return "color: white;"
    // else
    return ""
  }

  getVerifyTotalStyle() {
    if (this.totalDeposit > 0)
      return { 'text-align': 'right', 'background-color': 'var(--green-500)', 'color': 'white' }
    else if (this.totalDeposit < 0)
      return { 'text-align': 'right', 'background-color': 'var(--red-500)', 'color': 'white' }
    else
      return { 'text-align': 'right', 'background-color': 'var(--brandColor)', 'color': 'white' }
  }

  fillInitData() {
    this.drawerCountVerify = [
      { label: 'Yes', command: () => { this.updateVerify('Yes') } },
      { label: 'No', command: () => { this.updateVerify('No') } }
    ]

    this.cols = [
      { field: 'locationName', header: 'Location' },
      { field: 'cashierName', header: 'Cashier Name' },
      { field: 'registerName', header: 'Reg' },
      { field: 'date', header: 'Date/Time' },
      { field: 'dollar100Qty', header: '$100' },
      { field: 'dollar50Qty', header: '$50' },
      { field: 'dollar20Qty', header: '$20' },
      { field: 'dollar10Qty', header: '$10' },
      { field: 'dollar5Qty', header: '$5' },
      { field: 'dollar2Qty', header: '$2' },
      { field: 'dollar1Qty', header: '$1' },
      { field: 'cent25Qty', header: '25 cents (Quarters)' },
      { field: 'cent10Qty', header: '10 cents (Dimes)' },
      { field: 'cent5Qty', header: '5 cents (Nickels)' },
      { field: 'cent1Qty', header: '1 cents (Penny)' },
      { field: 'total', header: 'Total Count' },
      { field: 'systemCount', header: 'System Count' },
      { field: 'overShort', header: 'Over/Short' },
      { field: 'lastCount', header: 'Last Count' },
    ]

    // this.topTags = [
    //     { id: 1, title: 'Total Counted', value: 0, allowPopup: false },
    //     { id: 2, title: 'System Total', value: 0, allowPopup: false },
    //     { id: 3, title: 'Over/Short', value: 0, allowPopup: false },
    // ]

    this.mockData = [
      { bill: '$100', qty: 1, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 1, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 7, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 2, total: 0, value: .01, verify: true },
    ]

    this.enableDC = true
    this.totalCount = 0
    this.totalCoinsCount = 0
    let moc = this.mockData
    for (let i = 0; i < moc.length; i++) {
      moc[i].total = moc[i].qty * moc[i].value
      this.totalCount += moc[i].total
      this.totalCoinsCount += moc[i].qty
      if (!moc[i].isConfirmed) this.enableDC = false
    }
    this.totalDeposit = this.totalCount
  }

  confirmCountClicked() {
    this.step = 2
    this.title = 'Count'
  }

  saveCountClicked() {
    this.step = 3
    this.title = 'Deposit'
  }

  countGoBackClicked() {
    this.step = 2
    this.title = 'Count'
  }

  cancelCountClicked() {
    //this.step = 2
  }

  callbackFuncFromFilter(e) {
    if (this.step == 1)
      this.getDataList()
    else if (this.step == 2)
      this.getDrawerCountList()
  }

  filterButtonPressed() { }

  getStatusText(item) {
    if (item.allowReconcilation) return ''

    const x = item.overShort

    if (x == 0) return 'Balanced'
    else if (x > 0) return 'Over by $' + this.formatNumberToString(x)
    else if (x < 0) return 'Short by $' + this.formatNumberToString(x)
    else return 'error'
  }

  formatNumberToString(num: number) {
    let x = Number(num.toFixed(2)).toLocaleString()
    return x
  }

  getDrawerCountList() {
    try {
      this.lastDateRange = JSON.parse(JSON.stringify(this._filterService.dateRange))
      let locations = []
      if (!this._filterService.selectedLocation) {
        locations.push(this._abd.locations[0].locationId)
      } else
        locations.push(this._filterService.selectedLocation.locationId)

      const paras = {
        "StartDate": formatDate(new Date(this._filterService.singleDateForReconcile), 'MM/dd/yyyy', 'en-US'),
        "LocationIds": locations
      }

      const _url = environment.OrderBaseURL + 'CashCount/Reconciliation'

      this._spinner.show()
      this._bs.httpPost(_url, paras).subscribe(res => {
        this._spinner.hide()

        if (res.success) {

        //     var q = new Date();
        //     var m = q.getMonth();
        //     var d = q.getDay();
        //     var y = q.getFullYear();

        //     var date = new Date(y, m, d);
        //     var dateInput = new Date(this._filterService.singleDateForReconcile);

        //     dateInput = new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDay());


        //   //  this.allowAddingCashCount = !res.data.isManagerCount;

        //     // available for current date only
        //     if (new Date().toDateString() == new Date(this._filterService.singleDateForReconcile).toDateString()) {
        //         this.allowAddingCashCount = true
        //     }
        //     else{
        //         if (!res.data.isManagerCount) {
        //             this.allowAddingCashCount = false
        //         }
        //     }

        //     var lastReconciliationDateInput = new Date(this.lastReconciliationDate);
        //     var m = lastReconciliationDateInput.getMonth();
        //     var d = lastReconciliationDateInput.getDay();
        //     var y = lastReconciliationDateInput.getFullYear();

        //     this.allowAddingCashDrop = false

        //     // disable for all previous dates
        //    // 2023-04-11 < 2023-03-24
        //     if (new Date(this.lastReconciliationDate).toDateString() < new Date(this._filterService.singleDateForReconcile).toDateString()) {
        //         this.allowAddingCashDrop = false

        //     }

        //     console.log(new Date(this._filterService.singleDateForReconcile).toDateString())
        //     console.log(new Date(this.lastReconciliationDate).toDateString())
        //     // if reconciled date not submitted
        //     if (!res.data.isManagerCount &&
        //         (new Date(this._filterService.singleDateForReconcile).toDateString() > new Date(this.lastReconciliationDate).toDateString())) {
        //         this.allowAddingCashDrop = true;
        //     }
        //     else{
        //         this.allowAddingCashDrop = false
        //     }

        // hide line start
        // res.data.allowAddingCashDrop = true;
        // res.data.allowAddingCashCount = false;
        // hide line end
        this.allowAddingCashDrop = res.data.allowAddingCashDrop && !this.isViewOnly
        this.allowAddingCashCount = res.data.allowAddingCashCount && !this.isViewOnly

          this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
          this.drawerCountList = res.data.cashCountDetail
          for (let i = 0; i < this.drawerCountList.length; i++) {
            this.drawerCountList[i].dated = new Date(this.drawerCountList[i].dated)
            this.drawerCountList[i].verify = this.drawerCountList[i].isVerified ? 'Yes' : 'No'

            let dc = this.drawerCountList[i]
            this.drawerCountList[i].totalCoinsCount =
              dc.dollar100Qty + dc.dollar50Qty + dc.dollar20Qty + dc.dollar10Qty + dc.dollar5Qty +
              dc.dollar2Qty + dc.dollar1Qty + dc.cent25Qty + dc.cent10Qty + dc.cent5Qty + dc.cent1Qty
          }

          if (this.step == 1) this.step = 2
          this.showFilter = true
          this.title2 = ' - Drawer Count'
          this.calcDrawerCountTotals()

          this.cashDropList = res.data.cashDrops

          this.topTags = [
              { icon_class:'pi pi-window-maximize', id: 1, title: 'Starting Cash', tooltip: 'Open Details', value: res.data.startingCash, allowPopup: true, class:'cursor-pointer' },
              { icon_class:'', id: 2, title: 'Cash Sales', tooltip: 'Cash from sales', value: res.data.cashSales, allowPopup: false, class: '' },
              { icon_class:'pi pi-window-maximize', id: 3, title: 'Adjustments', tooltip: 'Open Details', value: res.data.moneyAdjustments, allowPopup: true, class: 'cursor-pointer' },
              { icon_class:'pi pi-window-maximize', id: 4, title: 'Expected Cash', tooltip: 'Open Details', value: res.data.expectedCash, allowPopup: true, class: 'cursor-pointer' },
            // { icon_class:'pi pi-window-maximize', id: 1, title: 'System Cash', value: res.data.systemCash, description: "(Cash Sales+Pay In + Till) - (Cash Refund + Cash Drop + Payout) " },
            // { icon_class:'pi pi-window-maximize', id: 2, title: 'Last Count', value: res.data.registerCount },
              { icon_class:'', id: 5, title: 'Manager Count', tooltip: 'Manager Counted Amount', value: res.data.managerCount, allowPopup: false, class: '' },
              { icon_class:'', id: 6, title: 'Bank Deposit', tooltip: 'Bank Deposited Amount', value: res.data.bankDeposit, allowPopup: false, class: '' },
              { icon_class:'', id: 7, title: 'Over/Short', tooltip: 'Manager count - Expected cash', value: res.data.overShort, allowPopup: false, class: '' },
          ]

        }
        else{
            this.enableRectification = false;
            this.allowAddingCashDrop = false;
        }
      })
    }
    catch {
      this._spinner.hide()
    }
  }

  confirmCount(what: string) {
    try {
    //   if (this.drawerCountList.length < 1) {
    //     this._messageService.add({
    //       severity: 'error',
    //       summary: 'Error', detail: 'Nothing to process!'
    //     })
    //     return
    //   }

      const t = this.drawerCountList
      let data = []
    //  for (let i = 0; i < t.length; i++) {
        let x = {
        //   "Id": t[i].id,
            "LocationId": this._filterService.selectedLocation.locationId,
            "Dated": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
        //   "Dated": formatDate(new Date(), 'MM/dd/yyyy', 'en-US'),
           "IsVerified": true, //t[i].verify == 'Yes' ? true : false,
           "VerifiedBy": this._globals.userInfo.userID
        }
        data.push(x)
     // }

      this._spinner.show()
      const _url = environment.OrderBaseURL + 'CashCount/Verification?onlySave=' + what
      this._bs.httpPost(_url, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this.totalDeposit = res.data.amount
          if (what == 'false') {
            const d = res.data
            this.mockData = [
              { id: 0, bill: '$100', qty: d.dollar100Qty, total: 0, value: 100 },
              { id: 1, bill: '$50', qty: d.dollar50Qty, total: 0, value: 50, verify: true },
              { id: 2, bill: '$20', qty: d.dollar20Qty, total: 0, value: 20, verify: true },
              { id: 3, bill: '$10', qty: d.dollar10Qty, total: 0, value: 10, verify: true },
              { id: 4, bill: '$5', qty: d.dollar5Qty, total: 0, value: 5, verify: true },
              { id: 5, bill: '$2', qty: d.dollar2Qty, total: 0, value: 2, verify: true },
              { id: 6, bill: '$1', qty: d.dollar1Qty, total: 0, value: 1, verify: true },
              { id: 7, bill: '25 cents(Quarter)', qty: d.cent25Qty, total: 0, value: .25, verify: false },
              { id: 8, bill: '10 cents (Dimes)', qty: d.cent10Qty, total: 0, value: .10, verify: true },
              { id: 9, bill: '5 cents (Nickels)', qty: d.cent5Qty, total: 0, value: .05, verify: true },
              { id: 10, bill: '1 cent (Penny)', qty: d.cent1Qty, total: 0, value: .01, verify: true },
            ]
            this.calcMockup()
            //-----------------------
            this.managerCountObj = d
            this.step = 3
            this.title2 = ' -  Manager Count'
            this.showFilter = false

            this._globals.secondTitle = 'End of Day (EOD) - Manager Count'
          }
          this.calcDrawerCountTotals()
        }
      })
    }
    catch {
      this._spinner.hide()
      return
    }
  }

  saveManagerCount(what: boolean) {
    const m = this.mockData
    const today = new Date()
    const td = new Date(this.selectedRow1.dated)
    const data = {
      "locationId": this.selectedRow1.locationId,
      "amount": this.totalDeposit ? this.totalDeposit : 0,
      "dated": formatDate(today, 'MM/dd/yyyy', 'en-US'),
      "time": today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds(),
      "userId": this._globals.userInfo.userID,
      "reason": "Manager Count",
      "invoiceNumber": "",
      "details": "",
      "dollar100Qty": m[0].qty ? m[0].qty : 0,
      "dollar50Qty": m[1].qty ? m[1].qty : 0,
      "dollar20Qty": m[2].qty ? m[2].qty : 0,
      "dollar10Qty": m[3].qty ? m[3].qty : 0,
      "dollar5Qty": m[4].qty ? m[4].qty : 0,
      "dollar2Qty": m[5].qty ? m[5].qty : 0,
      "dollar1Qty": m[6].qty ? m[6].qty : 0,
      "cent25Qty": m[7].qty ? m[7].qty : 0,
      "cent10Qty": m[8].qty ? m[8].qty : 0,
      "cent5Qty": m[9].qty ? m[9].qty : 0,
      "cent1Qty": m[10].qty ? m[10].qty : 0,
      "CountLevel": "Manager",
      "IsConfirmed": what,
      "CountDated": formatDate(td, 'MM/dd/yyyy', 'en-US')
    }

    const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashCount'
    if (what) {
      this._confirmationService.confirm({
        header: `Bank Deposit Confirmation`,
        message: `Are you sure you want to deposit this amount?
        Once the deposit is made, the cash reconciliation cannot be changed.
        Please double check that the amount and details are correct before proceeding.`,
        accept: () => {
          try {
            this._abd.showSpinner()
            this._bs.httpPost(_url, data).subscribe(res => {
              this._spinner.hide()
              if (res.success) {
                this.step = 1
                this.getDataList()
              }
            })
          } catch { this._abd.hideSpinner() }
        },
        reject: () => { return }
      });
    } else {
      try {
        this._abd.showSpinner()
        this._bs.httpPost(_url, data).subscribe(res => {
          this._spinner.hide()
          if (res.success) {
            this.step = 1
            this.getDataList()
          }
        })
      } catch { this._abd.hideSpinner() }
    }


  }

  itemQtyChanged(e, item) {
    item.qty = e.value
    item.total = item.qty * item.value
    this.getTotalQtyDC()
  }

  itemQtyChangedCD(e, item) {
    item.qty = e.value
    item.total = item.qty * item.value
    this.getTotalQtyDC()
  }

  getTotalQtyCD() {
    try {
      let total = 0
      let sum = 0
      for (let i = 0; i < this.mockDataCD.length; i++) {
        this.mockDataCD[i].total = this.mockDataCD[i].qty * this.mockDataCD[i].value
        sum += this.mockDataCD[i].qty
        total += this.mockDataCD[i].qty * this.mockDataCD[i].value
      }
      this.totalDepositCD = total
      return total
    }
    catch {
      this.totalDepositCD = 0
      return 0
    }
  }

  getTotalQtyDC() {
    try {
      let total = 0
      let sum = 0
      for (let i = 0; i < this.mockData.length; i++) {
        this.mockData[i].total = this.mockData[i].qty * this.mockData[i].value
        sum += this.mockData[i].qty
        total += this.mockData[i].qty * this.mockData[i].value
      }
      this.totalDeposit = total
      return total
    }
    catch {
      this.totalDeposit = 0
      return 0
    }
  }

  calcDrawerCountTotals() {
    this.drawerCountSummaryTotals = [
      { name: 'total100', value: 0 },
      { name: 'total50', value: 0 },
      { name: 'total20', value: 0 },
      { name: 'total10', value: 0 },
      { name: 'total5', value: 0 },
      { name: 'total2', value: 0 },
      { name: 'total1', value: 0 },
      { name: 'total25c', value: 0 },
      { name: 'total10c', value: 0 },
      { name: 'total5c', value: 0 },
      { name: 'total1c', value: 0 },
      { name: 'totalCount', value: 0 },
      { name: 'systemCount', value: 0 },
      { name: 'overShort', value: 0 },
      { name: 'lastCount', value: 0 },
    ]

    let d = this.drawerCountSummaryTotals

    for (let i = 0; i < this.drawerCountList.length; i++) {
      const t = this.drawerCountList[i]

      d[0].value += t.dollar100Qty
      d[1].value += t.dollar50Qty
      d[2].value += t.dollar20Qty
      d[3].value += t.dollar10Qty
      d[4].value += t.dollar5Qty
      d[5].value += t.dollar2Qty
      d[6].value += t.dollar1Qty
      d[7].value += t.cent25Qty
      d[8].value += t.cent10Qty
      d[9].value += t.cent5Qty
      d[10].value += t.cent1Qty
      d[11].value += t.amount //t.totalCoinsCount
      d[12].value += t.systemCount
      d[13].value += t.overShort
      d[14].value += t.lastCount
    }
  }

  updateVerify(e) {
    this.selectedDC.verify = e
  }

  dcmenuClick(e) {
    this.selectedDC = e
  }

  onRowSelectStep1(e) {
    try {
      this.isViewOnly = true

      this._filterService.singleDateForReconcile = new Date(e.data.dated)
      this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == e.data.locationId)
      this.getDrawerCountList()
    } catch { }
  }

  onRowUnselectStep1(e) { }

  backButtonClicked(what) {
    if (what == 2) {
      this.step = 1
      this._globals.secondTitle = 'End of Day (EOD)'
    }
    else if (what == 3) {
      this.step = 2
      this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
    }
    this._filterService.dateRange[0] = new Date(this.lastDateRange[0])
    this._filterService.dateRange[1] = new Date(this.lastDateRange[1])
  }

  exportExcel() {
    if (this.step == 1) {
      let header = [
        'Date', 'System Total', 'Counted at Registers',
        'Manager Count', 'Status'
      ]

      let ws = []
      let tmp = []
      let data22 = JSON.parse(JSON.stringify(this.eodList))
      tmp.push(header)
      for (let i = 0; i < data22.length; i++) {
        const t = data22[i]
        t.dated = formatDate(new Date(t.dated), 'MMM/dd/yyyy', 'en-US')

        let s = "still to reconciliate"
        if (t.isManagerCount) s = t.managerCount

        let inner = []
        inner.push(t.dated)
        inner.push(t.systemTotal)
        inner.push(t.registersCount)
        inner.push(s)
        inner.push(this.getStatusText(t))
        tmp.push(inner)
      }

      ws.push(tmp)

      const workbook = {
        Sheets: {
          'Reconciliation-EOD': XLSX.utils.aoa_to_sheet(ws[0]),
        },
        SheetNames: ['Reconciliation-EOD']
      };

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = 'Reconciliation-EOD-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
      FileSaver.saveAs(data, filename)
    }

    if (this.step == 2) {
      let header = [
        'Register', 'Counted By', 'Date/Time',
        '$100', '$50', '$20', '$10', '$5', '$2', '$1',
        '25 cents (Quarters)', '10 cents (Dimes)',
        '5 cents (Nickels)', '1 cent (Penny)',
        'Total Count', 'System Count', 'Over/Short', 'Last Count'
      ]

      let ws = []
      let tmp = []
      const xd = this.drawerCountList
      tmp.push(header)
      for (let j = 0; j < xd.length; j++) {
        let inner = []
        inner.push(xd[j].registerName)
        inner.push(xd[j].cashierName)
        inner.push(formatDate(new Date(xd[j].dated), 'MM/dd/yyyy, h:mm a', 'en-US'))
        inner.push(xd[j].dollar100Qty)
        inner.push(xd[j].dollar50Qty)
        inner.push(xd[j].dollar20Qty)
        inner.push(xd[j].dollar10Qty)
        inner.push(xd[j].dollar5Qty)
        inner.push(xd[j].dollar2Qty)
        inner.push(xd[j].dollar1Qty)
        inner.push(xd[j].cent25Qty)
        inner.push(xd[j].cent10Qty)
        inner.push(xd[j].cent5Qty)
        inner.push(xd[j].cent1Qty)
        inner.push('$ ' + (xd[j].total * 1).toFixed(2))
        inner.push('$ ' + (xd[j].systemCount * 1).toFixed(2))
        inner.push('$ ' + (xd[j].overShort * 1).toFixed(2))
        inner.push('$ ' + (xd[j].lastCount * 1).toFixed(2))
        tmp.push(inner)
      }

      ws.push(tmp)

      const workbook = {
        Sheets: {
          'Reconciliation-Drawer-Count': XLSX.utils.aoa_to_sheet(ws[0]),
        },
        SheetNames: ['Reconciliation-Drawer-Count']
      };

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = 'Reconciliation-Drawer-Count-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
      FileSaver.saveAs(data, filename)
    }

    if (this.step == 3) {
      let header = ['Bills', 'Quantity', 'Total']

      let ws = []
      let tmp = []
      const xd = this.mockData
      tmp.push(header)
      for (let j = 0; j < xd.length; j++) {
        let inner = []
        inner.push(xd[j].bill)
        inner.push(xd[j].qty)
        inner.push('$ ' + xd[j].total.toFixed(2))
        tmp.push(inner)
      }

      let inner = []
      inner.push('')
      inner.push('TOTAL DEPOSIT:')
      inner.push('$ ' + (this.totalDeposit).toFixed(2))
      tmp.push(inner)

      ws.push(tmp)

      const workbook = {
        Sheets: {
          'reconciliation-manage-count': XLSX.utils.aoa_to_sheet(ws[0]),
        },
        SheetNames: ['reconciliation-manage-count']
      };

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = 'reconciliation-manage-count-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
      FileSaver.saveAs(data, filename);
    }
  }

  exportPdf() {
    if (this.step == 1) {
      let cols = [
        { dataKey: "dated", title: "Date", type: 'date' },
        { dataKey: "startingCash", title: "Starting Cash", type: 'currency' },
        { dataKey: "systemTotal", title: "System Total", type: 'currency' },
        { dataKey: "expectedCash", title: "Expected Cash", type: 'currency' },
        { dataKey: "registersCount", title: "Counted at Registers", type: 'currency' },
        { dataKey: "managerCount", title: "Manager Count", type: 'string' },
        { dataKey: "pstatus", title: "Status", type: 'string' },
      ]

      let data = JSON.parse(JSON.stringify(this.eodList))
      for (let i = 0; i < data.length; i++) {
        const t = data[i]
        t.dated = formatDate(new Date(t.dated), 'MMM/dd/yyyy', 'en-US')

        if (t.isManagerCount) {
          t.managerCount = t.managerCount
        } else {
          t.managerCount = "not reconciliated"
        }
        t.pstatus = this.getStatusText(t)
      }

      let doc = new jsPDF('l', 'mm', 'letter');
      doc.setFontSize(30)
      doc.setTextColor(40)
      doc.text("Reconciliation - End of Day (EOD)", 120, 20)
      doc['autoTable'](cols, data, { startY: 35 })

      let filename = 'Reconciliation-EOD-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
      doc.save(filename);
    } // end-step-1

    if (this.step == 2) {
      let cols = [
        { dataKey: "registerName", title: "Register", type: 'string' },
        { dataKey: "cashierName", title: "Counted By", type: 'string' },
        { dataKey: "dated", title: "Date", type: 'date' },
        { dataKey: "dollar100Qty", title: "$100", type: 'string' },
        { dataKey: "dollar50Qty", title: "$50", type: 'string' },
        { dataKey: "dollar20Qty", title: "$20", type: 'string' },
        { dataKey: "dollar10Qty", title: "$10", type: 'string' },
        { dataKey: "dollar5Qty", title: "$5", type: 'string' },
        { dataKey: "dollar2Qty", title: "$2", type: 'string' },
        { dataKey: "dollar1Qty", title: "$1", type: 'string' },
        { dataKey: "cent25Qty", title: "25 cents (Quarters)", type: 'string' },
        { dataKey: "cent10Qty", title: "10 cents (Dimes)", type: 'string' },
        { dataKey: "cent5Qty", title: "5 cents (Nickels)", type: 'string' },
        { dataKey: "cent1Qty", title: "1 cent (Penny)", type: 'string' },
        { dataKey: "total", title: "Total Count", type: 'currency' },
        { dataKey: "systemCount", title: "System Count", type: 'currency' },
        { dataKey: "overShort", title: "Over/Short", type: 'currency' },
        { dataKey: "lastCount", title: "Last Count", type: 'currency' },
        { dataKey: "verify", title: "Verify", type: 'string' },
      ]

      let data = JSON.parse(JSON.stringify(this.drawerCountList))
      for (let i = 0; i < data.length; i++) {
        const t = data[i]
        t.dated = formatDate(new Date(t.dated), 'MMM/dd/yyyy', 'en-US')
      }

      this.calcDrawerCountTotals()
      const d = {
        registerName: '',
        cashierName: '',
        dated: 'Totals:',
        dollar100Qty: this.drawerCountSummaryTotals[0].value ? this.drawerCountSummaryTotals[0].value : 0,
        dollar50Qty: this.drawerCountSummaryTotals[1].value ? this.drawerCountSummaryTotals[1].value : 0,
        dollar20Qty: this.drawerCountSummaryTotals[2].value ? this.drawerCountSummaryTotals[2].value : 0,
        dollar10Qty: this.drawerCountSummaryTotals[3].value ? this.drawerCountSummaryTotals[3].value : 0,
        dollar5Qty: this.drawerCountSummaryTotals[4].value ? this.drawerCountSummaryTotals[4].value : 0,
        dollar2Qty: this.drawerCountSummaryTotals[5].value ? this.drawerCountSummaryTotals[5].value : 0,
        dollar1Qty: this.drawerCountSummaryTotals[6].value ? this.drawerCountSummaryTotals[6].value : 0,
        cent25Qty: this.drawerCountSummaryTotals[7].value ? this.drawerCountSummaryTotals[7].value : 0,
        cent10Qty: this.drawerCountSummaryTotals[8].value ? this.drawerCountSummaryTotals[8].value : 0,
        cent5Qty: this.drawerCountSummaryTotals[9].value ? this.drawerCountSummaryTotals[9].value : 0,
        cent1Qty: this.drawerCountSummaryTotals[10].value ? this.drawerCountSummaryTotals[10].value : 0,
        total: '$ ' + (this.drawerCountSummaryTotals[11].value ? this.drawerCountSummaryTotals[11].value : 0),
        systemCount: '$ ' + (this.drawerCountSummaryTotals[12].value ? this.drawerCountSummaryTotals[12].value : 0),
        overShort: '$ ' + (this.drawerCountSummaryTotals[13].value ? this.drawerCountSummaryTotals[13].value : 0),
        lastCount: '$ ' + (this.drawerCountSummaryTotals[14].value ? this.drawerCountSummaryTotals[14].value : 0),
      }

      data.push(d)

      let doc = new jsPDF('l', 'mm', 'a3');
      doc.setFontSize(30)
      doc.setTextColor(40)
      doc.text("Reconciliation - Drawer Count", 120, 20)
      doc['autoTable'](cols, data, { startY: 35 })

      let filename = 'Reconciliation-Drawer Count-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
      doc.save(filename)

    } // end-step-2

  }

  cashDepositHandler() {
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.locationId == this.cdSelectedLocation.locationId)

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'No registers in system!'
      })
      return
    }

    this.mockDataCD = [
      { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
    ]

    this.showCashDeposit = true
      this.currentOpenedAccordion = 0;
  }

  cashDropsHandler() {
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.locationId == this.cdSelectedLocation.locationId)

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'No registers in system!'
      })
      return
    }

    this.mockDataCD = [
      { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
    ]
    this.showCashDrop = true
    this.currentOpenedAccordion = 1;
  }

  postCashDeposit() {
    const obj = {
      LocationId: this.cdSelectedLocation.locationId,
      RegisterId: this.cdSelectedRegister.registerId,
      Amount: this.totalDepositCD,
      UserId: this._globals.userInfo.userID,
      Dollar100Qty: this.mockDataCD[0].qty,
      Dollar50Qty: this.mockDataCD[1].qty,
      Dollar20Qty: this.mockDataCD[2].qty,
      Dollar10Qty: this.mockDataCD[3].qty,
      Dollar5Qty: this.mockDataCD[4].qty,
      Dollar2Qty: this.mockDataCD[5].qty,
      Dollar1Qty: this.mockDataCD[6].qty,
      Cent25Qty: this.mockDataCD[7].qty,
      Cent10Qty: this.mockDataCD[8].qty,
      Cent5Qty: this.mockDataCD[9].qty,
      Cent1Qty: this.mockDataCD[10].qty,
      CountLevel: 'CashDrawer',
    }

      this.showCashDeposit = false
    const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashCount'
    this._confirmationService.confirm({
      header: `Cash Deposit Confirmation`,
      message: `Are you sure you want to deposit this amount?
      Once the deposit is made, the cash reconciliation cannot be changed.
      Please double check that the amount and details are correct before proceeding.`,
      accept: () => {
        try {
          this.showCashDeposit = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
              this.step = 2
                this.getDrawerCountList()
            }
          })
        } catch {
          this.showCashDeposit = false
          this._abd.hideSpinner()
        }
      },
        reject: (type) => {
            this.showCashDeposit = true
        }
    });
  }
  postCashDrops() {
    const obj = {
        locationId: this.cdSelectedLocation.locationId,
        registerId: this.cdSelectedRegister.registerId,
        Amount: -1*(this.totalDepositCD),
        UserId: this._globals.userInfo.userID,
        Dollar100Qty: this.mockDataCD[0].qty,
        Dollar50Qty: this.mockDataCD[1].qty,
        Dollar20Qty: this.mockDataCD[2].qty,
        Dollar10Qty: this.mockDataCD[3].qty,
        Dollar5Qty: this.mockDataCD[4].qty,
        Dollar2Qty: this.mockDataCD[5].qty,
        Dollar1Qty: this.mockDataCD[6].qty,
        Cent25Qty: this.mockDataCD[7].qty,
        Cent10Qty: this.mockDataCD[8].qty,
        Cent5Qty: this.mockDataCD[9].qty,
        Cent1Qty: this.mockDataCD[10].qty,
        CountLevel: 'CashDrawer',
        type:'CashDrop',
        time: formatDate(this.dropCashTime, 'H:mm:ss', 'en-US'),
        dated: formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US')
    }

      const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashDrawer?getDateTime=true'
      this.showCashDrop = false
    this._confirmationService.confirm({
      header: `Cash Drops Confirmation`,


      message: `Are you sure you want to drop this amount?
      Once the drop is made, the cash reconciliation cannot be changed.
      Please double check that the amount and details are correct before proceeding.`,

      accept: () => {
        try {
          this.showCashDrop = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {

            if (res.success) {
                this.dropCashTime = new Date();
              this.step = 2
                this.getDrawerCountList()
                this._spinner.hide()
            }
            else{
                this._spinner.hide()
            }
          })

        } catch {
          this.showCashDrop = false
          this._abd.hideSpinner()
        }
      },
    reject: (type) => {
        this.showCashDrop = true
    }

    });
  }

  isTabletFn() {

    const w = this._abd.getScreenWidth
    const h = this._abd.getScreenHeight

    if (w > 1201) return '25vw'
    if (w < 1200 && w >= 991) return '35vw'
    if (w < 990 && w > 665) return '45vw'
    if (w > 764 && w > 550) return '85vw'
    else return '100vw'

    // const userAgent = navigator.userAgent.toLowerCase();
    // const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    // if(isTablet) return '80vw'
    // else return '35vw'
  }

  scrollPanelSize() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    if (isTablet) return '70vh'
    else return '40vh'
  }

  cdLocationChange(e) {
    const loc = e.value.locationId
    if (!loc) return

    this.registers = this._abd.registers.filter(f => f.locationId == loc)
  }

    getParas() {
        this.selectLocationFilter = [];
        this.selectLocationFilter.push(this._filterService.selectedLocation.locationId)

        const data = {
            "ReportName": this.getReportTypeCall(this.currentShowingTableNumber),
            "Parameter": {
                "StartDate": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
                "EndDate": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
                "LocationIds": this.selectLocationFilter,
                "CashierIds": this.getCashierList(),
                "filterValue": this._filterService.searchText,
            }
        }
        return data
    }

    getReportTypeCall(reportType: number){
        try {
            let returnReportType: string = 'CashDetailReport';
            switch (reportType) {
                case 1:
                    returnReportType
                    break;
                case 3:
                    returnReportType = 'CashAdjustmentDetail'
                    break;

                case 4:
                    returnReportType = 'ExpectedCashDetail'
                    break;

                default:

                    break;
            }
            return returnReportType;
        } catch (error) {

        }
    }


    getDataListForPopUp(objId:number) {
        const url = environment.InventoryBaseURL + environment.Report

        try {
            this._spinner.show()
            this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
                if (res.success) {
                    this.visibleModal = true;
                    if (objId == 1){
                        this.popupHeaderInfo = 'Till Report';
                        this.tillReportData = [];
                        this.tillReportTotal=0;
                        this.tillReportData = res.data.till
                        if (this.tillReportData.length > 0) {
                            this.tillReportTotal = this.tillReportData.reduce((a, b) => a + b.total, 0)
                        }
                    }


                    if (objId == 3) {
                        this.popupHeaderInfo = 'Adjustments';
                        this.payoutReportData = [];
                        this.payoutReportTotal=0;
                        this.payoutReportData = res.data.payoutDetail
                        if (this.payoutReportData.length > 0) {
                            this.payoutReportTotal = this.payoutReportData.reduce((a, b) => a + b.amount, 0)
                        }

                        this.payInReportData = [];
                        this.payInReportTotal=0;
                        this.payInReportData = res.data.payinDetail
                        if (this.payInReportData.length > 0) {
                            this.payInReportTotal = this.payInReportData.reduce((a, b) => a + b.amount, 0)
                        }

                        this.refundReportData = [];
                        this.refundReportData = res.data.refundDetail
                        if (this.refundReportData.length > 0) {
                            this.refundReportTotal = this.refundReportData.reduce((a, b) => a + b.amount, 0)
                        }
                    }

                    if(objId == 4){
                        this.popupHeaderInfo = 'Expected Cash';
                        this.expectedCashInfoList = [];
                        this.expectedCashInfoList = [
                            {
                                id:1,
                                title: 'Sales',
                                value: res.data.totalSales,
                                allowPopup: false,
                                class: ''
                            },
                            {
                                id: 2,
                                title: 'Pay-in',
                                value: res.data.totalPayin,
                                allowPopup: true,
                                class: 'cursor-pointer'
                            },
                            {
                                id: 3,
                                title: 'Pay-out',
                                value: res.data.totalPayout,
                                allowPopup: true,
                                class: 'cursor-pointer'
                            },
                            {
                                id: 4,
                                title: 'Refunds',
                                value:res.data.totalRefunds,
                                allowPopup: true,
                                class: ''
                            },
                            {
                                id: 5,
                                title: 'Gift Card',
                                value: res.data.totalGiftCardSales,
                                allowPopup: false,
                                class: ''
                            },
                            {
                                id: 5,
                                title: 'Expected Cash',
                                value: res.data.expectedCashAmount,
                                allowPopup: false,
                                class: 'bold-title'
                            },
                        ];
                    }

                }
                else{
                    this.payInReportData = [];
                    this.payoutReportData = [];
                    this.tillReportData = [];
                    this._spinner.hide()
                }
            })
        }
        catch {
            //console.log('**tender-detail-report**', url)
            this._spinner.hide()
        }
    }

    showDetail(objId: number, showPopup: boolean){
        if (showPopup){
            this.currentShowingTableNumber = objId;

            this.getDataListForPopUp(objId);
        }

    }

    open($event:any){
        this.currentOpenedAccordion = $event.index;
    }

    showOtherPopup(){
        this.currentShowingTableNumber = 3;
        this.visibleModal = true;
        this.getDataListForPopUp(3);
    }

    onSelect($event) {
        try {

            if (this.dropCashTime === null){
                this.dropCashTime = new Date();
            }
        } catch (error) {

        }
    }

}
