import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

export interface TipSummaryReport {
  date: string;
  locationId: string;
  locationName: string;
  totalSaleItems: number;
  totalVoidItems: number;
  discount: number;
  salesTax: number;
  nonTaxableTotals: number;
  taxableTotals: number;
  subTotal: number;
  grandTotal: number;
  tenderedAmount: number;
  totalInvoices: number;
  shippingAmount: number;
  shippingTax: number;
}

@Component({
  selector: 'app-tip-summary-report',
  templateUrl: './tip-summary-report.component.html',
  styleUrls: ['./tip-summary-report.component.scss']
})
export class TipSummaryReportComponent implements OnInit, OnDestroy {
  _globals = GlobalService

  dataList: TipSummaryReport[] = [];


  colheader = this._abd.reports.filter(x => x.componentName == 'Tip Summary Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Tip Summary Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Tip Summary Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Tip Summary Report')[0].summaryflds;

  csvcols = []
  exportColumns: any = ''
  locationIds: string[] = [];
  registerIds: string[] = [];
  cashierIds: string[] = [];

  constructor(private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Tip Summary Report'
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }
  getRegisterName(id){
   if(this._abd.registers != null){
     return    this._abd.registers.filter((value)=>value.id === id)[0]?.registerName
   }
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
  })
  }
  getRegisters(){
    let self = this;
    this.registerIds = [];
    let selregister = this._filterService.selectedRegisters;
    selregister = (typeof selregister != 'undefined' && selregister instanceof Array) ? selregister : [selregister];
    selregister.map(function (value: { registerId: { toString: () => string; }; }) {
     if(value){
       self.registerIds.push(value.registerId.toString())
     }
    })
  }
  getCashiers(){
    let self = this;
    this.cashierIds = [];
    let selcashier = this._filterService.selectedCashier;
    selcashier = (typeof selcashier != 'undefined' && selcashier instanceof Array) ? selcashier : [selcashier];
    selcashier.map(function (value: { userID: { toString: () => string; }; }) {
      if(value){
      self.cashierIds.push(value.userID.toString())
  }})
  }
  getDataList() {
    this.getLocationsList();
    this.getRegisters()
    this.getCashiers()
    const data = {
      // "ReportName": 'SaleReport',
      "ReportName": 'TipSummaryReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'RegisterIds': this.registerIds,
        'CashierIds': this.cashierIds,
      }
    }

    // const url = environment.InventoryBaseURL + environment.Report;
    const url = this._abd.reports.filter(x => x.paraReportName == 'tip-summary-report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
   
    })
  }
  getCashierName(id){
     return this._abd.cashiers.filter((v)=>v.userId === id)[0]?.displayName
  }

  filterButtonPressed() {
    // alert(this._abd.selectedProductSellTypeFilter.Type)
    this.getDataList()
  }


  callbackFuncFromFilter(event) {

    this.getDataList()
  }

  exportExcel() {
    // this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Daily Sales Report')
  }

  exportPdf() {
    // this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Daily Sales Report', true)
  }

  calculateSummaryTotal(col: string) {
    let total = "0";
    if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("totalinvoices")) {
      total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
    }
    else if (col == 'netSales') {
      total = this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + (item['subTotal'] - item['discount']), 0), 'USD', "$");
    }
    else {
      total = this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
    }
    return total;

  }

}
