<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <!-- <div class="conn-grid" style="margin-top: 1px"> -->
        <div></div>
        <button
           *ngxPermissionsOnly="['2014']"
            pButton
            pRipple
            type="button"
            label="Edit"
            class="p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-pencil"
            iconPos="right"
            (click)="editButtonClicked()"
        ></button>
        <button
           *ngxPermissionsOnly="['2015']"
            pButton
            pRipple
            type="button"
            label="Delete"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-trash"
            iconPos="right"
            (click)="optionDeleteClicked()"
        ></button>
        <!-- </div> -->
    </ng-template>
</p-overlayPanel>

<div class="fadeindown animation-duration-250 flex">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            *ngIf="showReportTopFilter"
            [paraReportName]="'locations-list'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
            (exportCsvFn)="dt.exportCSV()"
            (deleteItemFn)="deleteMultiple()"
        ></app-report-top-filter-bar>

        <p-table
            #dt1
            [tableStyle]="{ width: 'max-content' }"
            [value]="_abd.locationsBS$ | async"
            *ngIf="(_abd.locationsBS$ | async).length>0"
            [autoLayout]="true"
            responsiveLayout="scroll"
            [rows]="rows"
            [paginator]="false"
            showLoader="true"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} locations"
            [rowsPerPageOptions]="[50, 100, 500, 1000]"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            [globalFilterFields]="['locationName','phone']"
        >
        <ng-template pTemplate="caption">
            <div class="flex justify-content-end flex-wrap">
                <span class="p-input-icon-right p-ml-auto flex align-items-center justify-content-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Location
                        <p-columnFilter type="text" field="locationName" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th style="text-align: center">
                        Manage Inventory at Store
                    </th> -->
                    <!-- <th style="text-align: center">Allow Shipping</th> -->
                    <!-- <th style="text-align: center">Accept Online Orders</th> -->
                    <th style="text-align: center">Phone
                        <p-columnFilter type="text" field="phone" display="menu"></p-columnFilter></th>
                    <!-- <th style="text-align: center">Station</th> -->
                    <th style="text-align: center"  *ngxPermissionsOnly="['2014','2015']">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr class="text-center" [pSelectableRow]="item">
                    <td>
                        <span class="conn-flex-image-first-letter">
                            {{ item.locationName | slice: 0:2 }}</span
                        >
                        {{ item.locationName | slice: 0:35 }}
                        <span *ngIf="item.locationName.length > 35">...</span>
                    </td>
                    <!-- <td style="text-align: center">
                        {{ item.manageInventory ? "Yes" : "No" }}
                    </td> -->
                    <!-- <td style="text-align: center">
                        {{ item.allowShipping ? "Yes" : "No" }}
                    </td> -->
                    <!-- <td style="text-align: center">
                        {{ item.acceptOnlineOrders ? "Yes" : "No" }}
                    </td> -->
                    <td style="text-align: center">
                        {{ item.phone | mask: "(000) 000-0000" }}
                    </td>
                    <td style="text-align: center"  *ngxPermissionsOnly="['2014','2015']">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>

            </ng-template>
        </p-table>
        <div class="flex" *ngIf="(_abd.locationsBS$ | async).length==0">
            <div class="text-align-center" style="width: 100%">
                <span class="control-description text-muted text-align-center"> No Locations Exist. Please add a New
                    Location</span>
            </div>
        </div>
    </div>
</div>

<p-dialog
    *ngIf="_locationService.isEditLocation"
    header="New Location"
    [(visible)]="_locationService.isEditLocation"
    modal="true"
    [style]="{width: this.dialogWidth}"
>
    <app-location-new-edit
        [isNew]="false"
        [isViewOnly]="isViewOnly"
    ></app-location-new-edit>
</p-dialog>
