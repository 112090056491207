import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit } from '@angular/core';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';

@Component({
  selector: 'app-checkout-n-finish-signup',
  templateUrl: './checkout-n-finish-signup.component.html',
  styleUrls: ['./checkout-n-finish-signup.component.scss']
})
export class CheckoutNFinishSignupComponent implements OnInit {
  noOfLocations = 1
  displaySubscriptionPlans=false
  _globals = GlobalService

  constructor(public _subscriptionPlanService: SubscriptionPlanService) {
    //console.log(_subscriptionPlanService.selectedSubscription)
   }

  ngOnInit(): void {
  }

  changePlanButtonClicked() {
    this.displaySubscriptionPlans=true
  }

}
