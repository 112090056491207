import { Component, OnInit } from '@angular/core';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-personal-info',
  templateUrl: './customer-personal-info.component.html',
  styleUrls: ['./customer-personal-info.component.scss']
})
export class CustomerPersonalInfoComponent implements OnInit {
  _globals = GlobalService
  
  constructor(
    public _customerService: CustomersService
  ) { }

  ngOnInit(): void {
  }

}
