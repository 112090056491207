import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-signup-custom-app',
  templateUrl: './signup-custom-app.component.html',
  styleUrls: ['./signup-custom-app.component.scss']
})
export class SignupCustomAppComponent implements OnInit {
  @Input() showFooter = true

  constructor(
    public _signup: SignupService,
  ) { }

  ngOnInit(): void {
  }

  backClicked() {
    this._signup.currentStep--
  }

  nextClicked() {
      const obj = this._signup.customAppBaseData.find(f => f.id == this._signup.customAppBaseDataId)
      if (!obj) return
      if (obj.answer == 'yes')
        this._signup.currentStep++
      else this._signup.currentStep += 2
  }

}
