<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'product-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <br />
    <div class="grid summary">
        <div class="col">
            <div class="conn-card" style="background: #f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                                Sold Item Count
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{ calculateSummaryTotal("soldQty", dataList) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="conn-card" style="background: #f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title"> Gross Sales </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{
                                    calculateSummaryTotal("netSales", dataList)
                                }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="conn-card" style="background: #f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title"> Cost of Goods </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{ calculateSummaryTotal("netCost", dataList) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div
                class="conn-card"
                style="background: var(--brandColor); color: white"
            >
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title"> Gross Profit </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{
                                    calculateSummaryTotal(
                                        "grossProfit",
                                        dataList
                                    )
                                }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="conn-card" style="background: #f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title"> Profit Margin </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{
                                    calculateSummaryTotal(
                                        "grossMargin",
                                        dataList
                                    )
                                }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br />
    <div>
        <div style="display: inline-block">
            <span
                [style]="{
                    'font-weight': '700',
                    color: 'var(--gray-500)',
                    'margin-right': '10px'
                }"
                >Group By
            </span>
            <p-dropdown
                [options]="GroupByOptions"
                [(ngModel)]="GroupByView"
                selectionMode="single"
                optionLabel="option"
                (onChange)="groupByChanged()"
                placeholder="Group By"
                [style]="{ width: '300px' }"
            ></p-dropdown>
        </div>
    </div>

    <!-- Nasir -->
    <p-divider></p-divider>
    <br />
    <p-accordion>
        <p-accordionTab *ngFor="let dep of salesItems">
            <ng-template pTemplate="header">
                <div class="flex justify-content-start flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center mr-4"
                    >
                        <ng-container *ngIf="GroupByView.value == 'department'">
                            {{ dep.department }}
                        </ng-container>
                        <ng-container *ngIf="GroupByView.value == 'category'">
                            {{ dep.category }}
                        </ng-container>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Sold: {{ dep.soldQty }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Return Qty: {{ dep.returnedQty }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Returns: {{ dep.totalReturned | currency }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Disc: {{ dep.totalDiscount | currency }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Net Sales: {{ dep.netSales | currency }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        COGS: {{ dep.netCost | currency }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Gross Profit: {{ dep.grossProfit | currency }}
                    </div>
                    <div
                        class="flex align-items-center justify-content-center mr-4 text-base"
                    >
                        Profit Margin: {{ dep.grossmargin | currency }}
                    </div>
                </div>

                <ng-container> </ng-container>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="conn-card">
                    <p-table
                        [value]="dep?.items"
                        dataKey="productName"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        responsiveLayout="scroll"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem"></th>
                                <th style="width: 15rem">Product</th>
                                <th style="width: 6rem">Date</th>
                                <th style="width: 6rem">Sell</th>
                                <th style="width: 6rem">Location</th>
                                <th style="width: 6rem">Department</th>
                                <th style="width: 6rem">Category</th>
                                <th style="width: 6rem">UOM</th>
                                <th style="text-align: right; width: 8rem">
                                    Sold
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Return Qty
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Returns
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Discounts
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Net Sales
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    COGS
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Gross Profit
                                </th>
                                <th style="text-align: right; width: 8rem">
                                    Profit Margin
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template
                            pTemplate="body"
                            let-product
                            let-expanded="expanded"
                        >
                            <tr>
                                <td>
                                    <button
                                        type="button"
                                        pButton
                                        pRipple
                                        [pRowToggler]="product"
                                        class="p-button-text p-button-rounded p-button-plain"
                                        [icon]="
                                            expanded
                                                ? 'pi pi-chevron-down'
                                                : 'pi pi-chevron-right'
                                        "
                                    ></button>
                                </td>
                                <td>{{ product.productName }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right">
                                    {{ product.soldQty }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.returnedQty }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.totalReturned | currency }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.totalDiscount | currency }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.netSales | currency }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.netCost | currency }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.grossProfit | currency }}
                                </td>
                                <td style="text-align: right">
                                    {{ product.grossmargin | currency }}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-product>
                            <tr>
                                <td colspan="16">
                                    <div class="p-p-3">
                                        <p-table
                                            [value]="product.details"
                                            [autoLayout]="true"
                                            [tableStyle]="{
                                                width: 'max-content'
                                            }"
                                            responsiveLayout="scroll"
                                            dataKey="id"
                                        >
                                            <!-- <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Sell</th>
                                                    <th>Location</th>
                                                    <th>Department</th>
                                                    <th>Category</th>
                                                    <th>UOM</th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Sold
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Return Qty
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Returns
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Discounts
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Net Sales
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        COGS
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Gross Profit
                                                    </th>
                                                    <th
                                                        style="
                                                            text-align: right;
                                                        "
                                                    >
                                                        Profit Margin
                                                    </th>
                                                </tr>
                                            </ng-template> -->
                                            <ng-template
                                                pTemplate="body"
                                                let-order
                                            >
                                                <tr>
                                                    <td
                                                        style="width: 3rem"
                                                    ></td>
                                                    <td
                                                        style="width: 15rem"
                                                    ></td>
                                                    <td style="width: 6rem">
                                                        {{ order.date }}
                                                    </td>
                                                    <td style="width: 6rem">
                                                        {{ order.isOnline }}
                                                    </td>
                                                    <td style="width: 6rem">
                                                        {{ order.locationName }}
                                                    </td>
                                                    <td style="width: 6rem">
                                                        {{ order.department }}
                                                    </td>
                                                    <td style="width: 6rem">
                                                        {{ order.category }}
                                                    </td>
                                                    <td style="width: 6rem">
                                                        {{ order.uom }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{ order.soldQty }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{ order.returnedQty }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.totalReturned
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.totalDiscount
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.netSales
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.netCost
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.grossProfit
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        style="
                                                            text-align: right;
                                                            width: 8rem;
                                                        "
                                                    >
                                                        {{
                                                            order.grossmargin
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </ng-template>

                                            <!-- <ng-template pTemplate="footer">
                                                <tr
                                                    *ngIf="
                                                        salesItems?.length > 0
                                                    "
                                                >
                                                    <td
                                                        rowspan="1"
                                                        colspan="6"
                                                        [style]="{
                                                            'text-align':
                                                                'center',
                                                            'background-color':
                                                                'var(--gray-300)',
                                                            color: 'var(--gray-500)'
                                                        }"
                                                    >
                                                        <span
                                                            [style]="{
                                                                'font-size':
                                                                    '16px'
                                                            }"
                                                            >{{
                                                                product.productName
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{ product.soldQty }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.returnedQty
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.totalReturned
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.totalDiscount
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.netSales
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.netcost
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.grossProfit
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        [style]="{
                                                            'text-align':
                                                                'right',
                                                            'background-color':
                                                                'var(--gray-300)'
                                                        }"
                                                    >
                                                        {{
                                                            product.grossmargin
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </ng-template> -->

                                            <ng-template
                                                pTemplate="emptymessage"
                                            >
                                                <tr>
                                                    <td colspan="6">
                                                        There are no order for
                                                        this product yet.
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
    <br />

    <!-- <p-accordion>
        <p-accordionTab *ngFor="let value of groupbyArray">
            <ng-template pTemplate="header">{{ value }}</ng-template>
            <ng-template pTemplate="content">
                <p-table
                    [value]="getItemByGroup(value)"
                    responsiveLayout="scroll"
                    rowGroupMode="rowspan"
                    groupRowsBy="productName"
                    sortField="productName"
                    sortMode="single"
                    *ngIf="dataList?.length > 0"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th
                                *ngFor="let col of colFields"
                                [pSortableColumn]="col.field"
                            >
                                <strong>{{ col.header }}</strong>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template
                        pTemplate="body"
                        let-product
                        let-rowIndex="rowIndex"
                        let-rowgroup="rowgroup"
                        let-rowspan="rowspan"
                    >
                        <tr class="row-data">
                            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                                <span class="font-bold">{{
                                    product.productName
                                }}</span>
                                <br />

                                SKU:{{ product.sku }} Barcode:{{
                                    product.barcode
                                }}
                            </td>
                            <td>
                                <span>{{ product.date }}</span>
                            </td>
                            <td>
                                <span>{{ product.isOnline }}</span>
                            </td>
                            <td>
                                <span>{{ product.locationName }}</span>
                            </td>
                            <td>
                                <span>{{ product.department }}</span>
                            </td>
                            <td>
                                <span>{{ product.category }}</span>
                            </td>

                            <td>
                                <span>{{ product.uom }}</span>
                            </td>
                            <td>
                                <span>{{ product.soldQty }}</span>
                            </td>
                            <td>
                                <span>{{ product.returnedQty }}</span>
                            </td>
                            <td>
                                <span>{{
                                    product.totalReturned | currency
                                }}</span>
                            </td>
                            <td>
                                <span>{{
                                    product.totalDiscount | currency
                                }}</span>
                            </td>
                            <td>
                                <span>{{ product.netSales | currency }}</span>
                            </td>
                            <td>
                                <span>{{ product.netCost | currency }}</span>
                            </td>

                            <td>
                                <span>{{
                                    product.grossProfit | currency
                                }}</span>
                            </td>
                            <td>
                                <span>{{
                                    product.grossProfit /
                                        (product.netSales > 0
                                            ? product.netSales
                                            : 100) | percent : "1.2"
                                }}</span>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="footer">
                        <tr *ngIf="dataList?.length > 0">
                            <td
                                rowspan="2"
                                colspan="1"
                                style="text-align: center"
                            >
                                <strong>Total</strong>
                            </td>

                            <td
                                *ngFor="let col of domSummaryflds"
                                style="text-align: center"
                            >
                                {{
                                    calculateSummaryTotal(
                                        col,
                                        getItemByGroup(value)
                                    )
                                }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-accordionTab>
    </p-accordion> -->

    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
</div>
