import { BoolDropDownModel } from "./drop-down-model";

export let SameAsInputList: BoolDropDownModel[] = [
    {
        view: true,
        viewValue: 'Yes'
    },
    {
        view: false,
        viewValue: 'No'
    },
]
