 <!-- <div class="flex justify-content-center p-4 flex-column align-items-center " *ngIf="_signup.accountVerified"> -->
     <!-- <div class="flex justify-content-center my-1">
         <img src="assets/images/Verification.png" />
     </div> -->

     <!-- <div class="flex justify-content-center my-2">
         <div class="text-500 flex">Your email address has been verified</div>
     </div> -->
 <!-- </div> -->
    <h4 class="flex align-items-center justify-content-center my-4 font-semibold">
        Verify Your Email Address
    </h4>

<div class="flex align-items-center justify-content-center">
    <p class="text-center text-xl px-6 w-10 font-medium">
        We have sent an account verification email to 
        <span class="font-bold email">{{
            _signup.personalInfo.email
        }}</span>
       to verify your email and activate your account. If you don’t
        see it, please check your spam/junk folder. 
    </p>
</div> 
<div class="flex align-items-center justify-content-center my-4" >
    <p class="text-center text-lg text-gray-600" *ngIf="!showResendCode">Enter Verification Code Here in   <span class="text-lg text-gray-600 font-bold" *ngIf="!showResendCode">{{timeIntervalString}}</span></p>
    <p class="text-center text-lg text-gray-600  " *ngIf="showResendCode">Didn't receive the verification code? <span (click)="resendCodeClicked()" class="font-bold cursor-pointer" style="color: var(--brandColor);">Resend Code </span> </p> 
</div>

<div class="flex align-items-center justify-content-center mt-2" >
    <p-inputMask
        #inputMask
        [(ngModel)]="varificationCode"
        mask="9 9 9 9 9 9"
    ></p-inputMask>
</div>

<div class="flex align-items-center justify-content-center mt-4" *ngIf="!_signup.accountVerified">
    <button
        pButton
        type="button"
        label="Verify My Email"
        [disabled]="!enableVerifyButton || (varificationCode == '')"
        (click)="verifyMyEmailButtonClicked()"
    ></button>
</div>

<!-- <div *ngIf="showResendCode">
    <div class="flex align-items-center justify-content-center my-4" >
   <p class=" font-medium text-lg">Didn't receive the verification code?</p> 
</div> -->

<!-- <div class="flex align-items-center justify-content-center mt-4" *ngIf="showResendCode" > -->
<!-- <div class="flex align-items-center justify-content-center mt-4">
    <button
        pButton
        type="button"
        label="Resend Email"
        (click)="resendCodeClicked()"
    ></button>
</div>
</div> -->


<!-- <div class="flex align-items-center justify-content-center mt-4" *ngIf="!_signup.accountVerified">
    <p class="text-center text-sm text-gray-400">
       Didn’t receive the verification code?
        <span class="text-sm text-blue-700 font-bold"

            ><a style="cursor:pointer" *ngIf="showResendCode" (click)="resendCodeClicked()">Resend Code</a></span
        >
        <span class="text-sm text-gray-400 font-bold" *ngIf="!showResendCode">{{timeIntervalString}}</span
        >
    </p>
</div> -->

<div
    class="flex justify-content-between flex-wrap card-container purple-container mt-6"
>
    <div class="flex align-items-center justify-content-center">
     <span class="flex justify-content-center border-round px-5 py-3 align-items-center backbtn" (click)="backClicked()">
        <i class="pi pi-arrow-left"></i>
        <p class="px-2 font-semibold ">Back</p>
     </span>
        <!-- <button
            pButton
            class="p-button-secondary"
            label="Back"
            icon="pi pi-arrow-left"
            (click)="backClicked()"
        ></button> -->
    </div>
    <div class="flex align-items-center justify-content-end"
        *ngIf="_signup.accountVerified && !_signup.freeTrialAccount">
       <!-- <p-button  label="Continue"
           icon="pi pi-arrow-right" iconPos="right" (click)="nextStep()"></p-button> -->
    </div>
</div>

<p-dialog [showHeader]="false" [(visible)]="displayConfirmContinueDialog" [style]="{width: '35vw'}" modal="true"
    [baseZIndex]="10000" class="p3" >
    <!-- <ng-template pTemplate="header">
        <div class="flex justify-content-center">
            <div class="text-blue-500 text-2xl font-bold flex align-items-center justify-content-center mt-0">Verification Success</div>
        </div>
    </ng-template> -->
    <div class="flex justify-content-center  px-4 py-2 flex-column align-items-center " *ngIf="_signup.freeTrialAccount">
        <div class="flex justify-content-center my-1">
            <img src="assets/images/Verification.png" />
        </div>
        <div class="flex justify-content-center my-2">
            <div
                class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                Your
                30-day free trial has started!</div>
        </div>
        <div class="flex justify-content-center my-2 text-align-center">
            <div class="text-500 flex">Your free trial will end on <span
                    class="font-bold mx-1 text-900">{{trialDate | date: 'fullDate'}}</span></div>
        </div>
        <div class="flex justify-content-center my-2 text-align-center">
          <!--  <div class="text-500 flex">For next 30 days, you will enjoy the following features of {{_globals.whiteLabel.wlName}} for free.
                Explore {{_globals.whiteLabel.wlName}} and grow your business effectively. </div> -->
            <div class="text-500 flex">Please Explore {{_globals.whiteLabel.wlName}} and feel free to contact us if you
                have any questions!</div>

        </div>
    </div>
    <div class="flex justify-content-center p-4 flex-column align-items-center " *ngIf="!_signup.freeTrialAccount">
        <div class="flex justify-content-center my-1">
            <img src="assets/images/Verification.png" />
        </div>
        <div
            class="flex justify-content-center my-2 device-price text-2xl font-bold flex align-items-center justify-content-center mt-0">
                Verification Success
            </div>

        <div class="flex justify-content-center my-1">
            <div class="text-500 flex">Your email address has been verified</div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex align-items-end justify-content-center" *ngIf="_signup.freeTrialAccount">
            <p-button
                label="Login to Dashboard"
                iconPos="right"
                (onClick)="login()"
                >
            </p-button>
        </div>
        <div class="flex align-items-end justify-content-center" *ngIf="!_signup.freeTrialAccount">
            <p-button label="Continue" icon="pi pi-arrow-right" iconPos="right" (onClick)="continueButtonClicked()">
            </p-button>
        </div>
    </ng-template>
</p-dialog>
