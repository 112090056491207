<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'discount-sales-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th  *ngFor="let col of domColHeader">
                    <strong>{{ col }}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td >
                    {{ product.dateTime | date:'MM/dd/yyyy, h:mm a'}}
                </td>
                <td >
                    {{ product.invoiceNumber }}
                </td>
                <td >
                    {{ product.discountType }}
                </td>
                <td >
                    {{ product.name }}
                </td>
                <td >
                    {{ product.value | currency }}
                </td>
                <td >
                    {{ product.discountCode }}
                </td>
                <td >
                    {{ product.customerName }}
                </td>
                <!-- <td >
                    {{ product.customerId }}
                </td> -->
                <td >
                    {{product.customerPhone==''?'':product.customerPhone=="00000"?'':_globals.generatePhoneFormatUS(product.customerPhone)  }}
                </td>
                <td >
                    {{ product.locationName }}
                </td>
                <td >
                    {{ product.isOnlineOrder }}
                </td>
                <td >
                    {{ product.registerName }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="0" >
                    <strong>Total</strong>
                </td>

                <td
                    *ngFor="let col of domSummaryflds"
                    
                >
                    {{ calculateSummaryTotal(col) }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>