export interface OtherOwnersArrayModel{
    id: number;
    title: string;
    description : string;
    selected: boolean;
}

export let OtherOwnersListArray: OtherOwnersArrayModel[] = [
    {
        id: 1,
        title: '2nd Owner',
        description: 'My business has a second owner',
        selected : false
    },
    {
        id: 2,
        title: '3rd Owner',
        description: 'My business has a third owner',
        selected: false
    },
    {
        id: 3,
        title: '4th Owner',
        description: 'My business has a fourth owner',
        selected: false
    },
]
