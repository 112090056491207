import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  pagesToDisplay = 5
  totalPages = 0
  pageArray: any = []
  displayString = ''
  selectedPageCount = 10

  lastPage = 0

  @Input() totalRecords: number = 0;
  @Input() page: number;
  @Input() rows: number;
  @Input() rowsPerPageOptions: any = [10, 25, 50, 100, 250, 500, 1000];
  @Output() paginatorCallbackFn = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
    this.setPageArray()
  }

  setTotalRecords_n_Pages(pages: any, currentPage: any, rows: any) {
    this.totalRecords = pages
    this.page = currentPage
    this.rows = rows
    this.setPageArray()
  }

  setTotalPages() {
    this.totalPages = Math.ceil(this.totalRecords / this.rows)
  }

  setPageArray() {
    if (this.totalRecords <= 0) return
    this.setTotalPages()
    const start = [{ label: '<<', isActive: true, disabled: false }, { label: '<', isActive: false, disabled: false }]
    const end = [{ label: '>', isActive: false, disabled: true }, { label: '>>', isActive: false, disabled: false }]

    let sp = this.page

    if (sp > 3) {
      sp = sp - 2
    }
    let ep = this.totalPages - sp


    if (ep > this.pagesToDisplay) ep = sp + this.pagesToDisplay
    else ep = this.totalPages

    let mid: any = []
    if (this.totalPages > 5) {
      for (let i = sp; i < ep; i++)  mid.push({ label: (i + 1 + ''), isActive: false, disabled: false })
    } else {
      for (let i = 1; i <= this.totalPages; i++)  mid.push({ label: (i + ''), isActive: false, disabled: false })
    }

    //-- final step
    this.pageArray = start.concat(mid)
    this.pageArray = this.pageArray.concat(end)
    let startpage = this.page * this.rows + 1
    let endpage = startpage + this.rows - 1
    if (endpage > this.totalRecords) endpage = this.totalRecords

    this.displayString = `Showing ${startpage} to ${endpage} of ${this.totalRecords} entries`
    this.activateDeactivatePages()
  }

  activateSinglePage(page) {
    this.page = page
    this.activateDeactivatePages()
    this.lastPage = page
    const pa = this.pageArray

    this.paginatorCallbackFn.emit({ page: this.page, rows: this.rows, isdummy: true })
  }

  activateDeactivatePages() {
    const pa = this.pageArray
    for (let i = 0; i < pa.length; i++) {
      pa[i].isActive = false
      pa[i].disabled = false
    }

    const pp = (this.page + 1) + ""
    for (let i = 0; i < pa.length; i++) {
      if (pa[i].label == pp) {
        pa[i].isActive = true
        break
      }
    }

    if (this.page == 0) {
      pa[0].disabled = true
      pa[1].disabled = true
    }


    if (pa[pa.length - 3].label == (this.page + 1)) {
      pa[pa.length - 1].disabled = true
      pa[pa.length - 2].disabled = true
    }

    let lastpage = pa.length
  }

  pageClicked(p) {
    if (p.label == '<<') this.page = 0;
    else if (p.label == '<') this.page--;
    else if (p.label == '>>') this.page = this.totalPages - 1
    else if (p.label == '>') this.page++;
    else this.page = (p.label * 1) - 1

    if (this.page < 0) this.page = 0
    if (this.page > this.totalPages) this.page = this.totalPages

    if (this.lastPage == this.page) return
    this.lastPage = this.page
    this.setPageArray()
    this.makeCallback()
  }

  makeCallback() {
    this.paginatorCallbackFn.emit({ page: this.page, rows: this.rows, isdummy: false })
  }

  rowsCountChange(e) {
    this.paginatorCallbackFn.emit({ page: 0, rows: e.value, isdummy: false })
  }

  dummy() {
    this.activateSinglePage(1)

  }


}
