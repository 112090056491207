import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsStates } from 'src/app/_data/us-states';
import { CountryList } from 'src/app/_models/next-level-integration/business&others/country-list';
import { FormIdList } from 'src/app/_models/next-level-integration/business&others/form-id';
import { LocationTypeList } from 'src/app/_models/next-level-integration/business&others/location-type';
import { OwnershipTypeList } from 'src/app/_models/next-level-integration/business&others/ownership-types';
import { RefundPolicyList } from 'src/app/_models/next-level-integration/business&others/refund-policy';
import { SameAsInputList } from 'src/app/_models/next-level-integration/business&others/same-as-drop-down';
import { TaxTypeIdList } from 'src/app/_models/next-level-integration/business&others/tax-type-id';
import { BusinessTypeList } from 'src/app/_models/next-level-integration/business&others/type-of-business';
import { BusinessAndOtherDetails } from 'src/app/_models/next-level-integration/business-and-others-details';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

interface City {
    name: string;
    code: string;
}


@Component({
  selector: 'app-business-and-other',
  templateUrl: './business-and-other.component.html',
  styleUrls: ['./business-and-other.component.scss']
})
export class BusinessAndOtherComponent implements OnInit {

    @Output() businessDetailSaved: EventEmitter<boolean> = new EventEmitter();
    @Output() backButtonClicked: EventEmitter<boolean> = new EventEmitter();
    isSubmitted: boolean = false;
    usaStates = UsStates;
    othersOwnersNumber: number = 0;
    public fields: any[] = [];
    unsubscribe: any;
    public arrayTitle = ['2nd Owner', '3rd Owner', '4th Owner'];
    showOwnerZipCodeLoading: boolean = false;

    businessDetail!: FormGroup;
    defaultState = this.usaStates[0];

    ownershipTypeList = OwnershipTypeList;
    businessTypeList = BusinessTypeList;
    taxTypeIdList = TaxTypeIdList;
    sameAsInputList = SameAsInputList;
    countryList = CountryList;
    locationTypeList = LocationTypeList;
    formIdList = FormIdList;
    refundPolicyList = RefundPolicyList;
    isMailingZipCodeDataLoaded: boolean = false;
    isLocationZipCodeDataLoaded: boolean = false;

    businessAndOtherObj = <BusinessAndOtherDetails>{};
    constructor(private fb: FormBuilder, public _abd: AppBaseDataService) { }

    ngOnInit(): void {
        this.initSessionValue();
        this.initBusinessDetail();
    }

    initSessionValue() {
        this.businessAndOtherObj.ownership_type =
            this.ownershipTypeList[0].view;
        this.businessAndOtherObj.business_incorporated = '';
        this.businessAndOtherObj.irs_tax_id_no = '';
        this.businessAndOtherObj.business_type = this.businessTypeList[0].view;

        this.businessAndOtherObj.irs_tax_id_type = this.taxTypeIdList[0].view;
        this.businessAndOtherObj.length_of_ownership = '';
        this.businessAndOtherObj.no_locations = 0;
        this.businessAndOtherObj.business_fax = '';
        this.businessAndOtherObj.business_phone = '';
        this.businessAndOtherObj.mailing_address = '';
        this.businessAndOtherObj.mailing_zip = '';
        this.businessAndOtherObj.mailing_state = this.usaStates[0].name;
        this.businessAndOtherObj.mailing_city = '';
        this.businessAndOtherObj.email = '';
        this.businessAndOtherObj.url = '';
        this.businessAndOtherObj.same_as_mailing = false;
        this.businessAndOtherObj.contact_first_name = '';
        this.businessAndOtherObj.contact_last_name = '';
        this.businessAndOtherObj.contact_phone = '';
        this.businessAndOtherObj.contact_fax = '';
        this.businessAndOtherObj.location_country = 'USA';
        this.businessAndOtherObj.location_address = '';
        this.businessAndOtherObj.location_city = '';
        this.businessAndOtherObj.location_state = this.usaStates[0].name;
        this.businessAndOtherObj.location_zip = '';
        this.businessAndOtherObj.avg_ticket_amount = 0;
        this.businessAndOtherObj.ave_month_volume = 0;
        this.businessAndOtherObj.own_other_business = 'false';
        this.businessAndOtherObj.processingAccount = 'false';
        this.businessAndOtherObj.bankcard_terminated = 'false';
        this.businessAndOtherObj.bank_name = '';
        this.businessAndOtherObj.bank_transit_number = '';
        this.businessAndOtherObj.bank_dda_no = '';
        this.businessAndOtherObj.location_type = this.locationTypeList[0].view;
        this.businessAndOtherObj.form_of_id = this.ownershipTypeList[0].view;
        this.businessAndOtherObj.business_description = '';
        this.businessAndOtherObj.charging_policy =
            this.refundPolicyList[0].view;
        this.businessAndOtherObj.delivery_time_of_sale = 'false';
    }

    getSessionOwnerValue() {
        return sessionStorage.getItem('business_and_other_details');
    }

    initBusinessDetail() {
        if (this.getSessionOwnerValue() != undefined) {
            let temp = JSON.parse(this.getSessionOwnerValue());
            this.businessAndOtherObj.ownership_type =
                this.ownershipTypeList.filter(f => f.view == temp.ownerShipType)[0].view;
            this.businessAndOtherObj.business_incorporated =
                temp.businessIncorporate;
            this.businessAndOtherObj.irs_tax_id_no = temp.federalTaxId;
            this.businessAndOtherObj.business_type =
                this.businessTypeList.filter(f => f.view == temp.typeOfBusiness)[0].view;

            this.businessAndOtherObj.irs_tax_id_type =
                this.taxTypeIdList.filter(f => f.view == temp.taxTypeId)[0].view;
            this.businessAndOtherObj.length_of_ownership =
                temp.lengthOgOwnership;
            this.businessAndOtherObj.no_locations = temp.numberOfLocations;
            this.businessAndOtherObj.business_fax = temp.businessFax;
            this.businessAndOtherObj.business_phone = temp.businessPhone;
            this.businessAndOtherObj.mailing_address = temp.businessPhone;
            this.businessAndOtherObj.mailing_zip = temp.mailingZipCode;
            this.businessAndOtherObj.mailing_state = this.usaStates.filter(f => f.name == temp.mailingState)[0].name;
            this.businessAndOtherObj.mailing_city = temp.mailingCity;
            this.businessAndOtherObj.email = temp.emailAddress;
            this.businessAndOtherObj.url = temp.url;
            this.businessAndOtherObj.same_as_mailing =
                temp.sameAsMailingAddress;
            this.businessAndOtherObj.contact_first_name = temp.contactFirstName;
            this.businessAndOtherObj.contact_last_name = temp.contactLastName;
            this.businessAndOtherObj.contact_phone = temp.contactPhone;
            this.businessAndOtherObj.contact_fax = temp.contactFax;
            this.businessAndOtherObj.location_country = 'USA';
            this.businessAndOtherObj.location_address = temp.locationAddress;
            this.businessAndOtherObj.location_city = temp.locationCity;
            this.businessAndOtherObj.location_state = this.usaStates.filter(f => f.name == temp.locationState)[0].name;
            this.businessAndOtherObj.location_zip = temp.locationZipCode;
            this.businessAndOtherObj.avg_ticket_amount =
                temp.averageTicketAmount;
            this.businessAndOtherObj.ave_month_volume = temp.averageMonthVolume;
            this.businessAndOtherObj.own_other_business = temp.haveOtherBusiness.toString();
            this.businessAndOtherObj.processingAccount = temp.processingAccount.toString();
            this.businessAndOtherObj.bankcard_terminated = temp.bankCardTerminated.toString();
            this.businessAndOtherObj.bank_name = temp.bankName;
            this.businessAndOtherObj.bank_transit_number = temp.bankTransitNumber;
            this.businessAndOtherObj.bank_dda_no = temp.bankDDANumber;
            this.businessAndOtherObj.location_type = this.locationTypeList.filter(f => f.view == temp.locationType)[0].view;
            this.businessAndOtherObj.form_of_id = this.formIdList.filter(f => f.view == temp.formOfId)[0].view;
            this.businessAndOtherObj.business_description =
                temp.businessDescription;
            this.businessAndOtherObj.charging_policy =
                this.refundPolicyList.filter(f => f.view == temp.refundPolicy)[0].view;
            this.businessAndOtherObj.delivery_time_of_sale = this.sameAsInputList.filter(f => f.view == temp.deliveryTime)[0].view.toString();
        }


        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        this.businessDetail = this.fb.group({
            ownerShipType: [
                this.businessAndOtherObj.ownership_type,
                Validators.required,
            ],
            businessIncorporate: [
                this.businessAndOtherObj.business_incorporated,
                Validators.required,
            ],
            federalTaxId: [
                this.businessAndOtherObj.irs_tax_id_no,
                Validators.required,
            ],
            typeOfBusiness: [this.businessAndOtherObj.business_type],
            taxTypeId: [this.businessAndOtherObj.irs_tax_id_type],
            lengthOgOwnership: [
                this.businessAndOtherObj.length_of_ownership,
                Validators.required,
            ],
            numberOfLocations: [
                this.businessAndOtherObj.no_locations,
                Validators.required,
            ],
            businessFax: [this.businessAndOtherObj.business_fax],
            businessPhone: [
                this.businessAndOtherObj.business_phone,
                Validators.required,
            ],
            mailingAddress: [
                this.businessAndOtherObj.mailing_address,
                Validators.required,
            ],
            mailingZipCode: [
                this.businessAndOtherObj.mailing_zip,
                Validators.required,
            ],
            mailingCity: [
                this.businessAndOtherObj.mailing_city,
                Validators.required,
            ],
            mailingState: [
                this.usaStates.filter(f => f.name == this.businessAndOtherObj.mailing_state)[0].name
                ,
                Validators.required,
            ],
            emailAddress: [this.businessAndOtherObj.email],
            url: [this.businessAndOtherObj.url],
            contactFirstName: [
                this.businessAndOtherObj.contact_first_name,
                Validators.required,
            ],
            contactLastName: [
                this.businessAndOtherObj.contact_last_name,
                Validators.required,
            ],
            contactPhone: [
                this.businessAndOtherObj.contact_phone,
                Validators.required,
            ],
            contactFax: [this.businessAndOtherObj.contact_fax],
            sameAsMailingAddress: [this.businessAndOtherObj.same_as_mailing ? true : false],
            locationCountry: ['USA'],
            locationAddress: [
                this.businessAndOtherObj.location_address,
                Validators.required,
            ],
            locationZipCode: [
                this.businessAndOtherObj.location_zip,
                Validators.required,
            ],
            locationCity: [
                this.businessAndOtherObj.location_city,
                Validators.required,
            ],
            locationState: [
                this.usaStates.filter(f => f.name == this.businessAndOtherObj.location_state)[0].name,
                Validators.required,
            ],
            averageTicketAmount: [
                this.businessAndOtherObj.avg_ticket_amount,
                Validators.required,
            ],
            averageMonthVolume: [
                this.businessAndOtherObj.ave_month_volume,
                Validators.required,
            ],
            haveOtherBusiness: [this.businessAndOtherObj.own_other_business == "true" ? true : false],
            processingAccount: [this.businessAndOtherObj.processingAccount == "true" ? true : false],
            bankCardTerminated: [this.businessAndOtherObj.bankcard_terminated == "true" ? true : false],
            bankName: [this.businessAndOtherObj.bank_name, Validators.required],
            bankTransitNumber: [this.businessAndOtherObj.bank_transit_number, Validators.required],
            bankDDANumber: [this.businessAndOtherObj.bank_dda_no, Validators.required],
            locationType: [this.businessAndOtherObj.location_type],
            formOfId: [this.businessAndOtherObj.form_of_id],
            businessDescription: [this.businessAndOtherObj.business_description, Validators.required],
            refundPolicy: [this.businessAndOtherObj.charging_policy],
            deliveryTime: [this.businessAndOtherObj.delivery_time_of_sale == "true" ? true : false],
        });
    }


    get f() {
        return this.businessDetail.controls;
    }

    getZipCode(zipCode: number) {
        if (zipCode == 1) {
            if (
                !this.f.mailingZipCode.value ||
                this.f.mailingZipCode.value.length !== 5
            )
                return;
            this.isMailingZipCodeDataLoaded = true;
            this._abd
                .getCityAndStateInfo(this.f.mailingZipCode.value)
                .subscribe(
                    (res: any) => {
                        if (res.success && res.data.city != undefined) {
                            this.f.mailingCity.setValue(res.data.city);
                            let selectedState = this.usaStates.filter(
                                (f) => f.abbreviation == res.data.state
                            )[0];
                            this.f.mailingState.setValue(
                                this.usaStates.filter(
                                    (f) => f.abbreviation == res.data.state
                                )[0].name
                            );

                            this.isMailingZipCodeDataLoaded = false;
                        } else {
                            this.isMailingZipCodeDataLoaded = false;
                        }
                    },
                    (error) => {
                        this.f.mailingCity.setValue('');
                        this.isMailingZipCodeDataLoaded = false;
                    }
                );
        }

        if (zipCode == 2) {
            if (
                !this.f.locationZipCode.value ||
                this.f.locationZipCode.value.length !== 5
            )
                return;
            this.isLocationZipCodeDataLoaded = true;
            this._abd
                .getCityAndStateInfo(this.f.locationZipCode.value)
                .subscribe(
                    (res: any) => {
                        if (res.success && res.data.city != undefined) {
                            this.f.locationCity.setValue(res.data.city);
                            let selectedState = this.usaStates.filter(
                                (f) => f.abbreviation == res.data.state
                            )[0];
                            this.f.locationState.setValue(
                                this.usaStates.filter(
                                    (f) => f.abbreviation == res.data.state
                                )[0].name
                            );
                            this.isLocationZipCodeDataLoaded = false;
                        } else {
                            this.f.locationCity.setValue('');
                            this.isLocationZipCodeDataLoaded = false;
                        }
                    },
                    (error) => {
                        this.f.locationCity.setValue('');
                        this.isLocationZipCodeDataLoaded = false;
                    }
                );
        }
    }

    saveForm() {
        if (this.businessDetail.invalid) {
            // Got focus to the error field
            let invalidFields = [].slice.call(
                document.getElementsByClassName('ng-invalid')
            );
            invalidFields[1].focus();
        }

        this.saveFormValues();

        this.isSubmitted = true;
        this.businessDetailSaved.emit(true);
    }

    saveFormValues() {
        let obj = <any>{};
        obj.ownerShipType = this.f.ownerShipType.value;
        obj.businessIncorporate = this.f.businessIncorporate.value;
        obj.federalTaxId = this.f.federalTaxId.value;
        obj.typeOfBusiness = this.f.typeOfBusiness.value;
        obj.typeOfBusiness = this.f.typeOfBusiness.value;
        obj.taxTypeId = this.f.taxTypeId.value;
        obj.lengthOgOwnership = this.f.lengthOgOwnership.value;
        obj.numberOfLocations = this.f.numberOfLocations.value;
        obj.businessFax = this.f.businessFax.value;
        obj.businessPhone = this.f.businessPhone.value;
        obj.mailingAddress = this.f.mailingAddress.value;
        obj.mailingZipCode = this.f.mailingZipCode.value;
        obj.mailingCity = this.f.mailingCity.value;
        obj.mailingState = this.f.mailingState.value;
        obj.emailAddress = this.f.emailAddress.value;
        obj.url = this.f.url.value;
        obj.contactFirstName = this.f.contactFirstName.value;
        obj.contactLastName = this.f.contactLastName.value;
        obj.contactPhone = this.f.contactPhone.value;
        obj.contactFax = this.f.contactFax.value;
        obj.sameAsMailingAddress = this.f.sameAsMailingAddress.value;
        obj.locationCountry = this.f.locationCountry.value;
        obj.locationAddress = this.f.locationAddress.value;
        obj.locationZipCode = this.f.locationZipCode.value;
        obj.locationCity = this.f.locationCity.value;
        obj.locationState = this.f.locationState.value;
        obj.averageTicketAmount = this.f.averageTicketAmount.value;
        obj.averageMonthVolume = this.f.averageMonthVolume.value;
        obj.haveOtherBusiness = this.f.haveOtherBusiness.value;
        obj.processingAccount = this.f.processingAccount.value;
        obj.bankCardTerminated = this.f.bankCardTerminated.value;
        obj.bankName = this.f.bankName.value;
        obj.bankTransitNumber = this.f.bankTransitNumber.value;
        obj.bankDDANumber = this.f.bankDDANumber.value;
        obj.locationType = this.f.locationType.value;
        obj.formOfId = this.f.formOfId.value;
        obj.businessDescription = this.f.businessDescription.value;
        obj.refundPolicy = this.f.refundPolicy.value;
        obj.deliveryTime = this.f.deliveryTime.value;
        sessionStorage.setItem(
            'business_and_other_details',
            JSON.stringify(obj)
        );
    }

    backButtonClick() {
        this.saveFormValues();
        this.backButtonClicked.emit(true);
    }

    locationInfoFiller() {
        if (this.f.sameAsMailingAddress.value) {
            this.f.locationAddress.setValue(this.f.mailingAddress.value);
            this.f.locationZipCode.setValue(this.f.mailingZipCode.value);
            this.f.locationCity.setValue(this.f.mailingCity.value);
            // console.log(this.f.mailingState.value);

            this.f.locationState.setValue(
                this.usaStates.filter(
                    (f) => f.name == this.f.mailingState.value
                )[0].name
            );
        } else {
            this.f.locationAddress.setValue('');
            this.f.locationZipCode.setValue('');
            this.f.locationCity.setValue('');
            this.f.locationState.setValue(this.defaultState);
        }
    }

}
