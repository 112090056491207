import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { LoyaltyConfig } from 'src/app/_models/reward-program/loyalty-configuration';
import { LoyaltyConfigType } from 'src/app/_models/reward-program/reward-program-response';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';

interface RewardTypeModel {
    name: string,
    title: string
}

@Component({
  selector: 'app-reward-configuration',
  templateUrl: './reward-configuration.component.html',
  styleUrls: ['./reward-configuration.component.scss']
})
export class RewardConfigurationComponent implements OnInit {

    _globals = GlobalService
    @ViewChild('op') op: OverlayPanel
    page = 0
    rows = 10
    first = 0
    totalRecords = 0
    dataList: any = []
    searchText = ''
    lastSearchText = ''
    masterDataList: any = null
    locationIds: string[] = [];
    isViewOnly = false

    actions: MenuItem[] = [
        { label: 'Edit', icon: 'pi pi-book' },
        { label: 'Delete', icon: 'pi pi-trash' },
    ]

    colheader = [[
        'Name', 'Codes', 'Start Date', 'End Date'
        , 'Limit Discount', 'Customer Type', 'Allowed Locations'
    ]];

    colflds = ['discountName', 'couponCodesListWithSpace', 'validityStartDate', 'validityEndDate',
        'limitDiscount', 'customerType', 'allowedLocationsListString'
    ];

    @ViewChild('p', { static: false }) paginator: Paginator;

    selectedValue: string = '';
    currentTab: string = '';
    showGeneralRewardSaveModel : boolean = false;
    rewardTypeList: RewardTypeModel[] = [];
    regularRewardProgramObj = <LoyaltyConfigType>{};
    loyaltyObj = <LoyaltyConfig>{};
    isFirstTimeLoading: boolean = true;

    constructor(
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService,
        private _confirmationService: ConfirmationService,
        public _abd: AppBaseDataService,

        public _filterService: FilterServiceService,
        public _rewardProgramService: RewardProgramService
    ) { }

    ngOnInit(): void {
        this.initPage();
    }

    initPage(){
        this.rewardTypeList = [
            {name: 'Regular', title:"Regular"},
            { name: 'Tiered', title:"Tiered"},
        ]


        if (this._globals.userInfo.businessSetup[0].enableLoyaltyReward) {
            this.getLoyaltyConfiguration();
        }


        this.isFirstTimeLoading = false;
    }

    getLoyaltyConfiguration() {
        try {
            this._abd.showSpinner()
            this.isFirstTimeLoading = true;
            this._rewardProgramService.getRegularDiscount(true).subscribe((r) => {
                if (r.success) {
                    this.loyaltyObj = r.data;
                    this.getLoyaltyConfigurationV1();
                }
                else {
                    this._rewardProgramService.isFirstTimeLoading = false;
                    this.isFirstTimeLoading = true;
                    this._abd.hideSpinner()
                }
            }, error => {
                this._rewardProgramService.isFirstTimeLoading = false;
                this.isFirstTimeLoading = true;
                this._abd.hideSpinner()
            })
        } catch (error) {

        }
    }

    getLoyaltyConfigurationV1(){
        try {
            this._abd.showSpinner()
            this.isFirstTimeLoading = true;
            this._rewardProgramService.getLoyaltyConfigType().subscribe((r) => {
                if (r.success) {
                    this.regularRewardProgramObj = r.data;

                    this.selectedValue = this.rewardTypeList.filter(t => t.name == r.data.rewardType)[0].name;
                    this.currentTab = this.selectedValue;

                    this._rewardProgramService.isFirstTimeLoading = false;
                    this.isFirstTimeLoading = true;
                    this._abd.hideSpinner()
                }
                else {
                    this._rewardProgramService.isFirstTimeLoading = false;
                    this.isFirstTimeLoading = true;
                    this._abd.hideSpinner()
                }
            }, error => {
                this._rewardProgramService.isFirstTimeLoading = false;
                this.isFirstTimeLoading = true;
                this._abd.hideSpinner()
            })
        } catch (error) {

        }
    }

    changeProgram(newValue: string, oldValue: string){

        this._confirmationService.confirm({
            message: 'Would you like to change reward program type?',
            accept: () => {
                this.selectedValue = newValue
                this.currentTab = newValue
                this._rewardProgramService.saveRewardType(this.currentTab).subscribe((r)=>{
                    if(r.success){
                        this.getLoyaltyConfigurationV1();
                    }
                },error=>{

                })
            },
            reject: () => {
                this.selectedValue = oldValue
                this.currentTab = oldValue
            }
        });
    }


}
