<div>
    <p-progressBar mode="indeterminate"></p-progressBar>
    <div>
        <p class="p-10"     style="text-align: center;">Please wait while we are loading payment form....</p>
    </div>
</div>

<!-- <div [style]="IsTokenFound?'':'visibility:hidden'">
    <iframe name="paytm"  width="100%"  height="1000px" frameBorder="1" [src]="paymentFormURL"></iframe>
    <form #myFormPost  name="myFormPost"  [action]="paymentFormURL" method="POST" target="paytm">
        <input type="hidden" #poptoken id="popupToken" name="token" value="{{token}}" />
    </form>

</div> -->

   



<!-- <i  class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> -->

<!-- <button type="button" (click)="onPost()">Post</button> -->
