import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ConnMessageService } from '../_services/conn-message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        let doSkip = ''
        try {
          doSkip = request.headers.get('do-need-to-process-interceptor')
        } catch { }

        if (doSkip == 'skip') {

          return throwError(() => error);
        }
        if (error) {
          //console.log("ERROR-EXCEPTION-INTERCEPTOR", error)
          this._abd.hideSpinner()
          switch (error.status) {
            case 400:

              try {
                if (error.error.message && error.error.responseStatusCode === 926) {
                  this._connMessageService.showToastMessage("Default data can't be deleted!");
                  break;
                }
              }
              catch { }

              if (error.error.message) {
                this._connMessageService.showToastMessage(error.error.message);
                break;
              }

              if (error.error.errors) {
                const modalStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modalStateErrors.push(error.error.errors[key])
                  }
                }
                throw modalStateErrors.flat();
              } else if (typeof (error.error) === 'object') {
                //alert(error.statusText)
                this._connMessageService.showToastMessage(error.statusText);
              } else {
                this._connMessageService.showToastMessage(error.error);
                //alert(error.error)
              }

              break;
            case 401:
              //alert(error.statusText)
              this._connMessageService.showToastMessage(error.statusText);
              break;
            case 404:
              this._connMessageService.showToastMessage(error.statusText)
              // this.router.navigateByUrl('pages/notfound');
              break;
            case 500:
              try {
                if (!error.error.success) {
                  //this._connMessageService.showToastMessage('No data found')
                  return
                }
              } catch { return }

              try {
                if (request?.body?.Name === 'GETUSERBYUSERNAME') {
                  localStorage.removeItem('user')
                  if (window.location.href.includes('/handheld'))
                    this._router.navigate(['handheld/signin'])
                  else
                    this._router.navigate(['pages/signin'])
                  return
                }
                return;
                this._connMessageService.showToastMessage(error.error.data.error.message)
                break;
              } catch { }

              const navigationExtras: NavigationExtras = { state: { error: error.error } }
              this._router.navigateByUrl('/server-error', navigationExtras);
              break;
            case 503:
              this._connMessageService.showToastMessage('HTTP Error 503. The service is unavailable.')
              this._router.navigate([""])
              break
            case 504:
              this._connMessageService.showToastMessage('HTTP Error 504. Something is wrong from server side.')
              break
            default:
              const rr = this._router.url
              if (rr !== 'pages/signin') {
                if (window.location.href.includes('/handheld'))
                  this._router.navigate(['handheld/signin'])
                else
                  this._router.navigate(['pages/signin'])
                break;
              }
              //alert('fatel error')
              this._connMessageService.showToastMessage('Something went wrong, please make sure you are connected to internet');
              //console.log('DEFAULT-ERROR', error);
              break;
          }
        }
        return throwError(() => error);
      })
    )
  }
}
