<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="securityConfigurationform"
                    (ngSubmit)="securityConfigurationform.valid && saveFn()"
                    autocomplete="off"
                >
                    
                    <div class="mb-4 hidden">
                        <p-inputSwitch [formControl]="$any(securityConfigurationform.controls)['enableSecurityForm']" inputId="rememberme20"></p-inputSwitch> 
                        <label class="control-label" for="rememberme20"><strong> Enable</strong></label>
                    </div>
                    <br/>
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(securityConfigurationform.controls)['enableLoginUserName']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Enable Login By UserName/Password</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(securityConfigurationform.controls)['enableLoginCard']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Login by card</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(securityConfigurationform.controls)['enableLoginFingerPrint']"
                            inputId="rememberme3"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme3">Enable Login By FingerPrint</label>
                    </div>
                    <br/>

                  


                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!securityConfigurationform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
    