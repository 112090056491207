import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { CategoryService } from 'src/app/_services/category.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { GlobalService } from 'src/app/_services/global.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HttpParams } from '@angular/common/http';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { BaseService } from 'src/app/_services/base.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-inventory-categories',
    templateUrl: './inventory-categories.component.html',
    styleUrls: ['./inventory-categories.component.scss'],
})
export class InventoryCategoriesComponent implements OnInit {
    _globals = GlobalService;
    @ViewChild('op') op: OverlayPanel;
    first = 0;
    page = 0;
    rows = 10;
    totalRecords = 0;

    searchText = '';
    lastSearchText = '';
    dataList: any = null;
    selectAll = false;

    selectedRow: any = '';

    //order
    orderParam = '';
    orderBy = '';

    colheader = [['Category Name', 'Department Name', 'Status']];

    colflds = ['categoryName', 'departmentName', 'isActive'];
    csvcols = [];
    exportColumns: any = '';

    actions: MenuItem[] = [
        {
            label: 'Edit',
            icon: 'pi pi-book',
            command: () => {
                this.update();
            },
        },
        {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
                this.delete();
            },
        },
    ];

    @ViewChild('p', { static: false }) paginator: Paginator;

    constructor(
        private _bs: BaseService,
        public _categoryService: CategoryService,
        public _reportService: ReportsService,
        public _spinner: NgxSpinnerService,
        public _connMessageService: ConnMessageService,
        public _confirmationService: ConfirmationService,
        public _abd: AppBaseDataService,
        public _filterService: FilterServiceService
    ) {
        this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds);
        this.exportColumns = this.csvcols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
    }

    ngOnInit(): void {
        // try {
        //   // this.refreshMotherData()
        //   this.totalRecords = this._abd.actCategories.length
        //   // this.dataList = this._abd.categories
        //   this._abd.initCategories(this._abd.actCategories)
        // } catch {
        //   // this._abd.loadInit()
        // }
        // this.getDataList()
        // this._abd.getAllCategories();
    }

    ngAfterViewInit() {
        let ti = null;
        ti = setInterval(() => {
            //--
            this._globals.componentName = 'Categories';
            this._globals.showProductDelButton = false;

            let ppages = this._filterService.getPages(
                this._globals.componentName
            );
            if (ppages) {
                let lastpage = parseInt(ppages.page);
                if (lastpage < 0) lastpage = 0;
                if (lastpage > 0) {
                    this.page = lastpage;
                    this.first = lastpage * this.rows;
                }
            }

            let filter = this._filterService.getFilters(
                this._globals.componentName
            );
            if (filter) {
                if (filter.searchText) {
                    this._filterService.searchText = filter.searchText;
                    this.searchText = filter.search;
                }
            }

            let oorderby = this._filterService.getOrderby(
                this._globals.componentName
            );
            if (oorderby) {
                this.orderParam = oorderby.orderParam;
                this.orderBy = oorderby.orderBy;
            }

            this.getDataList();
            //--
            clearInterval(ti);
        }, 200);
    }

    ngOnDestroy() {
        this._globals.componentName = '';
    }

    refreshMotherData() {
        this.searchText = '';
        this._abd.searchText = '';
        this._abd.loadInit().subscribe((res) => {
            if (res.success) {
                const xx = this._abd.initCategories(this._abd.actCategories);
                this.totalRecords = this._abd.actCategories.length;
            }
        });
    }

    getDataList() {
        try {
            this._spinner.show();
            this.searchText = this._filterService.searchText;
            let params = new HttpParams();

            this.page = this.page < 1 ? 0 : this.page;
            this.rows = this.rows < 1 ? 10 : this.rows;

            params = params.append('pageNo', this.page);
            params = params.append('pageSize', this.rows);
            if (this.searchText)
                params = params.append('filterValue', this.searchText);

            let qryParas = null;
            if (this.orderParam && this.orderParam.length > 0) {
                qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`;
                this._filterService.updateOrderby(this._globals.componentName, {
                    orderParam: this.orderParam,
                    orderBy: this.orderBy,
                });
            }

            const outfilter = {
                searchText: this.searchText ? this.searchText : '',
            };

            this._filterService.updateFilters(
                this._globals.componentName,
                outfilter
            );
            this._filterService.updatePages(this._globals.componentName, {
                page: this.page,
                rows: this.rows,
            });

            let url = environment.InventoryBaseURL + environment.CategoriesUrl;
            if (qryParas) url += qryParas;

            return this._bs.httpGetParams(url, params).subscribe((res) => {
                this._spinner.hide();
                if (res.success) {
                    this.totalRecords = res.totalRecords;
                    this.dataList = res.data;
                }
                for (let i = 0; i < this.dataList.length; i++)
                    this.dataList[i].selected = false;
                return res.data;
            });
        } catch {
            this._spinner.hide();
        }
    }

    onPageChange(e) {
        e.totalRecords = this.totalRecords;
        this.rows = e.rows;
        this.page = e.page;

        this.getDataList();
    }

    addNewFn(e) {
        if (e !== 'category') return;
        this._categoryService.showNewNewEdit = true;
    }

    refreshFn(e) {
        this.getDataList();
    }

    actionButtonClick(item: any) {
        this._categoryService.activeItem = JSON.parse(JSON.stringify(item));
        const xx = this._abd.categories.filter(
            (f) => f.id === this._categoryService.activeItem.departmentId
        );
        this._categoryService.selectedDepartment = xx[0];
    }

    update() {}

    delete() {}

    optionEditClicked() {
        this._categoryService.isViewOnly = false;
        this._categoryService.showNewEdit = true;
        this.op.hide();
    }

    clearSearchText() {
        this.searchText = '';
        this._filterService.searchText = '';
        if (this.lastSearchText.length > 0) {
            this.lastSearchText = '';
            this.filterButtonPressed();
        }
    }

    searchTextSearch() {
        if (this.searchText === this.lastSearchText) return;

        this.lastSearchText = this.searchText;
        this.filterButtonPressed();
    }

    implementLocalFilter() {
        this.page = 0;
        this.getDataList();
    }

    filterButtonPressed() {
        this.page = 0;
        // this.paginator.changePage(0)
        this.implementLocalFilter();
    }

    uncheckAll() {
        for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].selected = false;
        }
    }

    selectAllChanged(event) {
        for (let i = 0; i < this.dataList.length; i++)
            this.dataList[i].selected = event.checked;

        this._globals.showProductDelButton = event.checked;
    }

    selectionChanged(event) {
        let ret = false;
        if (!event.checked) {
            this.selectAll = this.selectAll ? !this.selectAll : this.selectAll;
        }

        for (let i = 0; i < this.dataList.length; i++)
            if (this.dataList[i].selected) {
                ret = true;
                break;
            }
        if (event.checked) {
            const sel = this.dataList.filter((f) => f.selected);
            if (sel.length == this.dataList.length) this.selectAll = true;
            else this.selectAll = false;
        }

        this._globals.showProductDelButton = ret;
    }

    commaSeperatedDeparmentIds(): string {
        let selectedItems: string = '';
        let z = this.dataList.filter((x) => x.selected);
        z.forEach((x) => {
            selectedItems += x.id + ',';
        });
        selectedItems = selectedItems.slice(0, -1);
        return selectedItems;
    }

    deleteMultipleItem() {
        let selectedItems = this.commaSeperatedDeparmentIds();
        // alert('ACTION-NOT-ALLOWED')
        this.op.hide();
        this._confirmationService.confirm({
            message:
                'Deleting process causes permanent loss of information. Are you sure to proceed',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._abd.showSpinner();
                try {
                    this._categoryService
                        .deleteCategory(selectedItems)
                        .subscribe((res) => {
                            this._abd.hideSpinner();
                            if (res.success) {
                                this._globals.showProductDelButton = false;
                                this.refreshMotherData();
                                this.getDataList();
                                this._connMessageService.showToastMessage(
                                    `Selected Categories deleted!`,
                                    'success'
                                );
                            }
                        });
                } catch {
                    this._abd.hideSpinner();
                }
            },
            reject: () => {},
        });
    }

    deleteItem() {
        this.op.hide();
        this._confirmationService.confirm({
            message:
                'Deleting process causes permanent loss of information. Are you sure to proceed',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._abd.showSpinner();
                try {
                    this._categoryService
                        .deleteCategory(this._categoryService.activeItem.id)
                        .subscribe((res) => {
                            this._abd.hideSpinner();
                            if (res.success) {
                                this._globals.showProductDelButton = false;
                                this.searchText = '';
                                this._abd.searchText = '';
                                let index = this.dataList.findIndex((el) =>el.id === this._categoryService.activeItem.id);
                                            this.dataList.splice(index, 1)
                                // this.refreshMotherData();
                                // this.getDataList();
                                this._connMessageService.showToastMessage(
                                    `Category "${this._categoryService.activeItem.categoryName}" deleted!`,
                                    'success'
                                );
                            }
                        });
                } catch {
                    this._abd.hideSpinner();
                }
            },
            reject: () => {},
        });
    }

    callbackFuncFromFilter(event) {
        //
        // this.searchText = this._abd.searchText
        this._globals.showProductDelButton = false;
        this.implementLocalFilter();
    }

    exportExcel() {
        let data = [];
        this._abd.categories$.subscribe((res) => {
            data = res;
        });

        this._abd.exportExcel(
            data,
            this.colheader[0],
            this.colflds,
            'Category List'
        );
    }

    exportPdf() {
        let data = [];
        this._abd.categories$.subscribe((res) => {
            data = res;
        });

        this._abd.exportPdf(
            data,
            this.colheader,
            this.colflds,
            'Category List'
        );
    }

    onRowSelect(e) {
        try {
            const el = e.originalEvent.srcElement.className;
            if (el && el.includes('p-checkbox')) return;
        } catch {}

        this._categoryService.activeItem = JSON.parse(JSON.stringify(e.data));
        this._categoryService.isViewOnly = true;
        this._categoryService.showNewEdit = true;
    }
    onRowUnselect(e) {}

    changeStatusClicked() {
        this.op.hide();

        this._confirmationService.confirm({
            message:
                'Modification process causes permanent loss of information. Are you sure to proceed',
            header: 'Modification Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._categoryService.activeItem.isActive =
                    !this._categoryService.activeItem.isActive;
                this._abd.showSpinner();
                try {
                    return this._categoryService
                        .createUpdateCategory(this._categoryService.activeItem)
                        .subscribe((res) => {
                            if (res.success) {
                                this.refreshMotherData();
                                this.getDataList();
                                this._abd.hideSpinner();
                                return res;
                            }
                        });
                } catch {
                    this._abd.hideSpinner();
                }
            },
            reject: () => {},
        });
    }

    customSort(e) {
        const last = this.orderParam;

        switch (e.sortField) {
            case 'categoryName':
                this.orderParam = 'categoryName';
                break;
            default:
                this.orderParam = '';
        }

        if (this.orderParam !== '') {
            this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc';
            if (last !== this.orderParam) this.orderBy = 'asc';
            this.getDataList();
        }
    }
}
