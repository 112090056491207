<div class="grid">
	<div class="col-12 md:col-6">
		<div class="card">
			<h5>Default</h5>
			<button pButton pRipple label="Submit" class="mr-2 mb-2"></button>
			<button pButton pRipple label="Disabled" disabled="true" class="mr-2 mb-2"></button>
			<p-button label="Link" styleClass="p-button-link mr-2 mb-2"></p-button>
		</div>

		<div class="card">
			<h5>Severities</h5>
			<button pButton pRipple type="button" label="Primary" class="mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-secondary mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-success mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-info mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-warning mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-help mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-danger mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Text</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-secondary p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-success p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-info p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-warning p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-help p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-danger p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Plain" class="p-button-text p-button-plain mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Outlined</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-outlined p-button-secondary mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-outlined p-button-success mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-outlined p-button-info mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-outlined p-button-warning mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-outlined p-button-help mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-outlined p-button-danger mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Button Set</h5>
			<span class="p-buttonset">
				<button pButton pRipple label="Save" icon="pi pi-check"></button>
				<button pButton pRipple label="Delete" icon="pi pi-trash"></button>
				<button pButton pRipple label="Cancel" icon="pi pi-times"></button>
			</span>
		</div>

		<div class="card">
			<h5>SplitButton</h5>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-info mr-2 mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-success mr-2 mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-warning mr-2 mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-help mr-2 mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-danger mr-2 mb-2"></p-splitButton>
		</div>
		<div class="card">
			<h5>Templating</h5>
			<p-button styleClass="mr-2 mb-2 px-3">
				<ng-template pTemplate="content">
					<img alt="logo" src="https://primefaces.org/primeng/showcase/assets/showcase/images/primeng-icon.svg" style="width: 1.5rem"/>
				</ng-template>
			</p-button>
			
			<p-button styleClass="p-button-outlined mr-2 mb-2">
				<img alt="logo" src="https://primefaces.org/primeng/showcase/assets/showcase/images/primeng-icon.svg" style="width: 1.5rem"/>
				<span class="ml-2 font-bold">PrimeNG</span>
			</p-button>
		</div>
	</div>
	<div class="col-12 md:col-6">
		<div class="card">
			<h5>Icons</h5>
			<button pButton pRipple icon="pi pi-star-fill" class="mr-2 mb-2"></button>
			<button pButton pRipple label="Submit" icon="pi pi-bookmark" class="mr-2 mb-2"></button>
			<button pButton pRipple label="Submit" icon="pi pi-bookmark" iconPos="right" class="mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Raised</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-raised mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-raised p-button-secondary mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-raised p-button-success mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-raised p-button-info mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-raised p-button-warning mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-raised p-button-help mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-raised p-button-danger mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Rounded</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-rounded mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-rounded p-button-secondary mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-rounded p-button-success mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-rounded p-button-info mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-rounded p-button-warning mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-rounded p-button-help mr-2 mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-rounded p-button-danger mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Rounded Icons</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Rounded Text</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-filter" class="p-button-rounded p-button-text p-button-plain mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Rounded Outlined</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help p-button-outlined mr-2 mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined mr-2 mb-2"></button>
		</div>

		<div class="card">
			<h5>Loading</h5>
			<p-button label="Search" icon="pi pi-search" styleClass="mr-2 mb-2" [loading]="loading[0]" (onClick)="load(0)"></p-button>
			<p-button label="Search" icon="pi pi-search" iconPos="right" styleClass="mr-2 mb-2" [loading]="loading[1]" (onClick)="load(1)"></p-button>
			<p-button icon="pi pi-search" styleClass="mr-2 mb-2" [loading]="loading[2]" (onClick)="load(2)"></p-button>
			<p-button label="Search" styleClass="mr-2 mb-2" [loading]="loading[3]" (onClick)="load(3)"></p-button>
		</div>
	</div>
</div>
