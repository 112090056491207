import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { UserService } from 'src/app/_services/user.service';
import { CurrentPlanGeneralInformation } from 'src/app/_models/subscription-management/current-plan-general-info';
import {
    PlanInfo,
    UserSubscriptionDetail,
    UserSubscriptionInfo,
} from 'src/app/_models/subscription-management/user-subscription-info';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {
    stateOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]
    isAsc =  true;
      invoiceDetail: any = []
      productsDetail: any = []


    dataList: any = [];
    totalRecords: number = 0;
    currentPlanSubscription = <UserSubscriptionDetail>{};
    loadingContent: boolean = false;
  planPricePerMonth: number = 0;
  basePlanPrice: number = 0;
  costHeading: string = "";
  userCurrentPlan = <PlanInfo>{};
  currentPlanGeneralInformation: CurrentPlanGeneralInformation[] = [];
  subscribedPackages: any[] = [];

    constructor(
        public _bizzCustomerService: BizzCustomersService,
        private _subscriptionPlanService: SubscriptionPlanService,
        private _abd: AppBaseDataService,
        public _signup: SignupService,
        ) { }

    ngOnInit(): void {
        this.initSubscriptionFormInfo();
        this.getUserCurrentSubscriptionInvoicesDetails();

     }
     getUserCurrentSubscriptionInvoicesDetails() {
        this._abd.showSpinner()
        this.loadingContent = true;
        this._subscriptionPlanService.getBusinessBillingInvoices(this._bizzCustomerService.activeItem.currentSubscription.businessNameWithDomain).subscribe(
            (res: UserSubscriptionInfo) => {
                if (res.responseStatusCode == 903) {
                    this.currentPlanSubscription = res.data;
                    this.dataList = this.currentPlanSubscription
                    this.customSort()
                    this.loadingContent = false;
                    this.initSubscriptionFormInfo();
                    this._abd.hideSpinner()
                }
            },
            (error) => {
                this.loadingContent = false;
            }
        );
    }

    initSubscriptionFormInfo() {
        try {
            let item = this._bizzCustomerService.activeItem.invoice
            if (this._bizzCustomerService.activeItem.isFreeTrial) {
                let startDate: Date = new Date(this._bizzCustomerService.activeItem.freeTrialStartDate.toString());
                let endDate: Date = new Date(this._bizzCustomerService.activeItem.freeTrialEndDate.toString());
                this.currentPlanGeneralInformation = [
                    {
                        title: 'Expires on:',
                        value: formatDate(
                            endDate,
                            'MM/dd/yyyy',
                            'en-US'
                        ),
                    },

                ];
            } else {

                let details = this._bizzCustomerService.activeItem.invoice.packageDetails
                let selectedPlanInterval = this._bizzCustomerService.activeItem.subscriptionInterval

                this.subscribedPackages = this._bizzCustomerService.activeItem.currentSubscription.subscribedPackages

            }

        } catch (error) {

        }

    }

     getWordsWithSpace(str) {
         if (str != '' && str != undefined) {
             return str.replace(/[A-Z]/g, ' $&').trim()
         }

     }
     customSort() {
         if(this.dataList.length > 1){
             this.isAsc = !this.isAsc 
             this.dataList = this.dataList.slice().reverse()
         }
    }
}
