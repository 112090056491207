import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-credit-card-summary-report',
  templateUrl: './credit-card-summary-report.component.html',
  styleUrls: ['./credit-card-summary-report.component.scss']
})
export class CreditCardSummaryReportComponent implements OnInit, OnDestroy {
  _globals = GlobalService

  dataList: any[] = [];
  locationDataList: any[] = [];
  yearDataList: any[] = [];

  colheader = this._abd.reports.filter(x => x.componentName == 'Credit Card Summary Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Credit Card Summary Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Credit Card Summary Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Credit Card Summary Report')[0].summaryflds;

  csvcols = []
  exportColumns: any = '';
  activeState: boolean[] = [true, false, false];
  locationIds: string[] = [];


  constructor(
    private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Credit Card Summary Report'
    this.getDataList();
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    if (!this._filterService.selectedLocation)
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))

    if (!this._filterService.selectedProductSellTypeFilter)
      this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }

    this.getLocationsList();

    let x95 = this._filterService.selectedProductSellTypeFilter
    if (!x95) x95 = 'All'
    else x95 = x95.Value

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'CCSummaryReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'OrderPlacement': x95
      }
    }

    const url = this._abd.reports.filter(x => x.componentName == 'Credit Card Summary Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {

      this.dataList = res.data;
      this._abd.hideSpinner();
      // console.log(this.dataList);
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }


  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportExcel(data, this.summaryHeader[0], this.colflds, 'Credit Card Summary Report')
  }

  exportPdf() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportPdf(data, this.summaryHeader, this.colflds, 'Credit Card Summary Report', true)
  }


  /*
      The following code seperates the ccdetail data from the API data and passes it to the PDF generator
  */
  refineCCDetails(dataInput: any[]) {

    let dummyData = []
    dataInput.filter(user => {
      user.ccDetails.filter(cc => dummyData.push(cc));
    });
    return dummyData;
  }

  calculateSummaryTotal(col: string) {
    //

    let data = this.refineCCDetails(this.dataList);
    ;
    let total = "0";
    if (1 == 1) {
      if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.includes("invoiceCount") || col.toLocaleLowerCase().includes("totalinvoices")) {
        total = data.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        total = this.currencyPipe.transform(data.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
    }
    else {
      total = data.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

  calculateSummaryTotalNoFormating(col: string) {
    //

    let data = this.refineCCDetails(this.dataList);
    ;
    let total = 0;


    total = data.reduce((sum, item) => sum + item[col], 0);

    return total;

  }

}
