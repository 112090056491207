import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';
import { SignupPersonalInfoComponent } from '../signup-personal-info/signup-personal-info.component';

@Component({
  selector: 'app-bottom-navigator',
  templateUrl: './bottom-navigator.component.html',
  styleUrls: ['./bottom-navigator.component.scss']
})
export class BottomNavigatorComponent implements OnInit {
  disableBackButton = true
  hideBackButton = false

  @Input() currentStep: any
  @ViewChild(SignupPersonalInfoComponent) __signupPersonalInfo!: SignupPersonalInfoComponent

  constructor(
    public _signup: SignupService,
  ) { }

  ngOnInit(): void {
  

    
  }

  backClicked() {
    if (this._signup.currentStep === 1) {
      this.hideBackButton = true
      return
    }
    
    this.hideBackButton = false
    this._signup.currentStep--;
    if (this._signup.currentStep === 1) this.hideBackButton = true
  }

  nextClick() {
    const cs = this._signup.currentStep

    if(cs==2) {
      if(this.__signupPersonalInfo.isFormValidFn()) {
        // console.log('yes')
      } else {
        // console.log('no')
      }
    }

    // this._signup.currentStep++;
    // if (this._signup.currentStep > 1) {
    //   this.hideBackButton = false
    // }
  }

  enableDisable() {
    // if (this.currentStep == 2) {
    //   // if(this._signup.ok2savePersonalInfo) alert('done!') //-- to be removed later
    //   return this._signup.ok2savePersonalInfo ? false : true
    // }

    // return true
  }

}
