import { DropDownModel } from "./drop-down-model";

export let LocationTypeList: DropDownModel[] = [
    {
        view: 'Retail',
        viewValue: 'Retail'
    },
    {
        view: 'Office Building',
        viewValue: 'Office Building'
    },
    {
        view: 'Industrial Building',
        viewValue: 'Industrial Building'
    },
    {
        view: 'Residence',
        viewValue: 'Residence'
    },
    {
        view: 'Office Building',
        viewValue: 'Office Building'
    },
    {
        view: 'Trade Show',
        viewValue: 'Trade Show'
    },
    {
        view: 'Other',
        viewValue: 'Other'
    },
    {
        view: 'Internet',
        viewValue: 'Internet'
    },
]
