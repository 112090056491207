import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paymentprocessingstatus',
  templateUrl: './paymentprocessingstatus.component.html',
  styleUrls: ['./paymentprocessingstatus.component.scss']
})
export class PaymentprocessingstatusComponent implements OnInit {

  constructor() { }

  ngOnInit  (): void {
    this.checkout()
  }
  ngAfterViewInit (): void {
    this.checkout()
  }
  checkout() {
    location.href = location.origin + '/#/setup/signup?paymentcanceld=yes'
  }
}
