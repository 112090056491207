<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'app-inventory-adjustment-list'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
        (locationChangedEmitter)="locationChanged($event)"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]="csvcols"
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Date</th>
                <th>User</th>
                <!-- <th>Adjustment ID</th> -->
                <th>Adjustment Type</th>
                <th>Location</th>
                <th>Cost ($)</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.dated | date : "mediumDate" }}</td>
                <td>{{ item.user }}</td>
                <!-- <td>{{ item.adjustmentId }}</td> -->
                <td>{{ item.adjustmentType }}</td>
                <td>{{ item.locationName }}</td>
                <td>{{ item.cost | currency }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="10" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
