import { DropDownModel } from "./drop-down-model";

export let OwnershipTypeList: DropDownModel[] =[
    {
        view:'Sole Proprietor',
        viewValue: 'Sole Proprietor'
    },
    {
        view: 'Partnership',
        viewValue: 'Partnership'
    },
    {
        view: 'Corporation',
        viewValue: 'Corporation'
    },
    {
        view: 'LLC',
        viewValue: 'LLC'
    },
    {
        view: 'LLP',
        viewValue: 'LLP'
    },
    {
        view: 'Non-Profit',
        viewValue: 'Non-Profit'
    },
    {
        view: 'PA or PC',
        viewValue: 'PA or PC'
    },
    {
        view: 'Assoc/Estates/Trusts',
        viewValue: 'Assoc/Estates/Trusts'
    },
    {
        view: 'Joint Venture',
        viewValue: 'Joint Venture'
    },
    {
        view: 'Government',
        viewValue: 'Government'
    },
    {
        view: 'Tax Exempt Org',
        viewValue: 'Tax Exempt Org'
    },
    {
        view: 'Civic Assoc',
        viewValue: 'Civic Assoc'
    },
    {
        view: 'Single Member LLC',
        viewValue: 'Single Member LLC'
    },
    {
        view: 'Multi Member LLC',
        viewValue: 'Multi Member LLC'
    },
    {
        view: 'Other',
        viewValue: 'Other'
    },
]
