import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CashHandlingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-invoice-configuration',
  templateUrl: './invoice-configuration.component.html',
  styleUrls: ['./invoice-configuration.component.scss']
})
export class InvoiceConfigurationComponent implements OnInit {

  invoiceform: FormGroup
  invoiceConfigurationData: CashHandlingConfig;
  @Input() singupBusinessConfig: boolean = true;

    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService
              ) { }

  ngOnInit(): void {
    this.invoiceConfigurationData = {
      // active: this._abd.systemConfigs.cashHandlingConfig.active,
      cashVoidLimit: this._abd.systemConfigs.cashHandlingConfig.cashVoidLimit,
      maxDiscountPer: this._abd.systemConfigs.cashHandlingConfig.maxDiscountPer,
      maxDiscountFlat: this._abd.systemConfigs.cashHandlingConfig.maxDiscountFlat,
      maxLineItemPrice: this._abd.systemConfigs.cashHandlingConfig.maxLineItemPrice,
      allowItemDiscount: this._abd.systemConfigs.cashHandlingConfig.allowItemDiscount,
      allowInvoiceDiscount: this._abd.systemConfigs.cashHandlingConfig.allowInvoiceDiscount,
      overrideItemDiscountIfInvoiceDiscount: this._abd.systemConfigs.cashHandlingConfig.overrideItemDiscountIfInvoiceDiscount,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    // const enable=this.invoiceform.get('enableInvoiceForm');
    // enable?.valueChanges
    //   .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){
    // if(value==false){
    //   // this.invoiceform.get('cashVoidLimit')?.disable();
    //   // this.invoiceform.get('maxDiscountPercent')?.disable();
    //   // this.invoiceform.get('maxDiscountDollar')?.disable();
    //   // this.invoiceform.get('maxLineItemPrice')?.disable();
    //   this.invoiceform.get('itemDiscount')?.disable();
    //   this.invoiceform.get('invoiceDiscount')?.disable();
    //   this.invoiceform.get('overrideDiscount')?.disable();
    // }else if (value==true){
    //   // this.invoiceform.get('cashVoidLimit')?.enable();
    //   // this.invoiceform.get('maxDiscountPercent')?.enable();
    //   // this.invoiceform.get('maxDiscountDollar')?.enable();
    //   // this.invoiceform.get('maxLineItemPrice')?.enable();
    //   this.invoiceform.get('itemDiscount')?.enable();
    //   this.invoiceform.get('invoiceDiscount')?.enable();
    //   this.invoiceform.get('overrideDiscount')?.enable();
    // }
  }

  initForm() {
      this.invoiceConfigurationData.allowItemDiscount = true;
      this.invoiceConfigurationData.allowInvoiceDiscount = true;
      this.invoiceConfigurationData.overrideItemDiscountIfInvoiceDiscount = false;
    this.invoiceform = this._fb.group({
    enableInvoiceForm: [this.invoiceConfigurationData.active],
      cashVoidLimit: [this.invoiceConfigurationData.cashVoidLimit, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maxDiscountPercent : [this.invoiceConfigurationData.maxDiscountPer, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maxDiscountDollar : [this.invoiceConfigurationData.maxDiscountFlat, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maxLineItemPrice : [this.invoiceConfigurationData.maxLineItemPrice, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      itemDiscount : [this.invoiceConfigurationData.allowItemDiscount],
      invoiceDiscount : [this.invoiceConfigurationData.allowInvoiceDiscount],
      overrideDiscount : [this.invoiceConfigurationData.overrideItemDiscountIfInvoiceDiscount],
    })
  }

  get f() {
    return this.invoiceform.controls;
  }

  saveFn(){
    let invoiceValue: CashHandlingConfig = {
      // active: this.invoiceform.get('enableInvoiceForm').value,
      cashVoidLimit: this.invoiceform.get('cashVoidLimit').value,
      maxDiscountPer: this.invoiceform.get('maxDiscountPercent').value,
      maxDiscountFlat: this.invoiceform.get('maxDiscountDollar').value,
      maxLineItemPrice: this.invoiceform.get('maxLineItemPrice').value,
      allowItemDiscount: this.invoiceform.get('itemDiscount').value,
      allowInvoiceDiscount: this.invoiceform.get('invoiceDiscount').value,
      overrideItemDiscountIfInvoiceDiscount: this.invoiceform.get('overrideDiscount').value,
       isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveInvoiceConfiguration(invoiceValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.cashHandlingConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Invoice settings saved successfully!`, 'success')
          this._businessSetupService.showInvoiceConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("invoice");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving invoice settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showInvoiceConfiguration = false
  }

}
