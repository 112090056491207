<h3 style="margin: 15px; color: var(--bluegray-400); font-weight: 700">
    Change Customer Information <span style="color: red"></span>
</h3>
<h1>this is it</h1>
<form
    [formGroup]="editform"
    (ngSubmit)="editform.valid && saveFn()"
    autocomplete="off"
>
    <div class="grid" style="margin-top: 10px">
        <div class="col" style="margin-top: 7px">
            <label class="control-label"
                ><strong
                    >First Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <app-text-input
                [formControl]="$any(editform.controls)['firstName']"
                [name]="'First Name'"
                [label]=""
                (input)="
                    _customerService?.activeItem.firstName = $event.target.value
                "
            ></app-text-input>
        </div>
        <div class="col">
            <div class="col">
                <label class="control-label"
                    ><strong
                        >Last Name
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['lastName']"
                    [name]="'Last Name'"
                    [label]=""
                    (input)="
                        _customerService?.activeItem.lastName =
                            $event.target.value
                    "
                ></app-text-input>
            </div>
        </div>
    </div>

    <div class="grid" style="margin-top: 10px">
        <div class="col" style="margin-top: 7px">
            <label class="control-label"
                ><strong
                    >Phone
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <app-text-input
                [formControl]="$any(editform.controls)['phone']"
                [name]="'Phone'"
                [label]=""
                (input)="
                    _customerService?.activeItem.phone = $event.target.value
                "
            ></app-text-input>
        </div>
        <div class="col">
            <div class="col">
                <label class="control-label"
                    ><strong
                        >Email
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['email']"
                    [name]="'Email'"
                    [label]=""
                    (input)="
                        _customerService?.activeItem.email = $event.target.value
                    "
                ></app-text-input>
            </div>
        </div>
    </div>

    <div class="grid" style="margin-top: 10px">
        <div class="col" style="margin-top: 7px">
            <label class="control-label"
                ><strong
                    >Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <app-text-input
                [formControl]="$any(editform.controls)['zip']"
                [label]="''"
                (input)="zipcodeInputEvent($event.target.value)"
            ></app-text-input>
        </div>

        <div class="col">
            <div class="col">
                <label class="control-label"
                    ><strong
                        >City
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <span class="p-input-icon-right" [style]="{ width: '100%' }">
                    <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                    <app-text-input
                        formControlName="city"
                        [label]="''"
                        (input)="
                            _customerService?.activeItem.city =
                                $event.target.value
                        "
                    ></app-text-input>
                </span>
            </div>
        </div>

        <div class="col">
            <div class="col">
                <label class="control-label"
                    ><strong
                        >State
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <p-dropdown
                    [style]="{
                        width: '100%',
                        'min-width': '200px',
                        'margin-top': '7px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="usaStates"
                    optionLabel="name"
                    [filter]="true"
                    filterBy="name"
                    [(ngModel)]="_customerService.selectedState"
                    [ngModelOptions]="{ standalone: true }"
                ></p-dropdown>
            </div>
        </div>
    </div>

    <br />
        <p-divider type="dashed"></p-divider>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-3 mb-4"
            [style]="{ 'margin-top': '20px' }"
        >
            <div class="flex align-items-center justify-content-center">
                <button
                    pButton
                    pRipple
                    type="submit"
                    label="Save"
                    (click)="saveFn()"
                    [disabled]="!editform.valid"
                ></button>
            </div>
            <div class="flex align-items-center justify-content-center ml-2">
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    class="p-button-outlined p-button-danger"
                    (click)="cancelButtonClick()"
                ></button>
            </div>
        </div>
</form>
