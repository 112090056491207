import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
import { LocationsService } from 'src/app/_services/locations.service';


@Component({
  selector: 'app-customer-group',
  templateUrl: './customer-group.component.html',
  styleUrls: ['./customer-group.component.scss']
})
export class CustomerGroupComponent implements OnInit {

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  totalRecords = 0

  searchText = ''
  lastSearchText = ''
  dataList: any = null
  exportColumns: any = ''

  actions: MenuItem[] = [
    // { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]
  @ViewChild('p', { static: false }) paginator: Paginator;

    showCustomerGroupUserModal: boolean = false;
    groupInfo:any=null;
    isNew:boolean=true;
  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    private _router: Router) { }

  ngOnInit(): void {
    this._globals.componentName = 'Customer Group'
    this.getDataList();
  }

  getDataList(){
    let params = new HttpParams();
    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    if (this._abd.searchText != '') {
        this.page = 0;
    }

    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());

//come back again
    if (this._abd.searchText.length) params = params.append('filterValue', this._abd.searchText.trim());
    this._abd.showSpinner();
    this._customerService.getAllCustomerGroups(params).subscribe(res => {
      this.dataList = res.data;
      this.totalRecords=res.totalRecords
      this._abd.hideSpinner();
    })
  }

  actionButtonClick(item: any){
    this.groupInfo = JSON.parse(JSON.stringify(item));
    this._customerService.groupInfo = this.groupInfo;
  }

  optionEditClicked(){
    this.isNew = false;
    this._customerService.groupInfo = JSON.parse(JSON.stringify(this.groupInfo));
    this._customerService.showNewNewGroupEdit = true;
  }

  optionStatusChangeClicked(){
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Do you wish to change Status this customer group?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        // this._abd.showSpinner()
        try {
            this._customerService.groupInfo.isActive = !this._customerService.groupInfo.isActive;
          this._customerService.updateCustomerGroup(this._customerService.groupInfo, this._customerService.groupInfo.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Customer Status Changed!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  optionDeleteClicked(){
    // console.log("Delete Clicked");
    this.op.hide()

    this._confirmationService.confirm({
      message: 'Do you wish to delete this customer group?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        // this._abd.showSpinner()
        try {
          this._customerService.deleteCustomerGroup(this._customerService.groupInfo.id).subscribe(res => {
            if (res.success) {
                this.getDataList();
                this._customerService.groupInfo = null;
                this.groupInfo = null;
                this._connMessageService.showToastMessage(`Customer group deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }


  callbackFuncFromFilter(event) {

    this._globals.showProductDelButton = false
    this.getDataList()
  }



    onRowSelect(event: any){

        this._customerService.activeItem = JSON.parse(JSON.stringify(event.data));
        this._customerService.isViewOnly = true;
        //this._customerService.showNewGroupEdit = true;
    }

    showCustomerGroupUsers(o:any) {
        // this.isNew = false;
        // this._customerService.groupInfo = JSON.parse(JSON.stringify(o));
        // console.log(this._customerService.groupInfo)
        // this._customerService.showNewNewGroupEdit = true;
    }

    hideCustomerGroupModal(e: any){
        this._customerService.showNewNewGroupEdit = false;
    }

    refrestDataList(e: any) {
        this.getDataList();
        this._customerService.showNewNewGroupEdit = false;
    }

    onPageChange(e) {
        e.totalRecords = this.totalRecords
        this.rows = e.rows
        this.page = e.page
        this.getDataList()
    }

}
