<div class="card">
    <div class="grid">
        <div class="col-7">
            <img width="100%" src="{{ hardware.image }}" alt="..." />
        </div>
        <div class="col-5 flex align-items-center">
            <div>
                <h1 class="text-primary">{{ hardware.title }}</h1>

                <ul class="list-none p-0 m-0 flex-grow-1">
                    <li
                        class="flex align-items-center mb-3"
                        *ngFor="let sub of hardware.features"
                    >
                        <span *ngIf="sub.isActive">
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span class="text-900">{{ sub.data }}</span>
                        </span>
                    </li>
                </ul>

                <h1>{{ hardware.price | currency }}</h1>
                <div class="flex flex-grow-1">
                    <p-toggleButton
                        [(ngModel)]="selected"
                        onLabel="Selected"
                        offLabel="Select"
                        (click)="selectClicked(hardware.id)"
                        style="width: 100%"
                    ></p-toggleButton>
                </div>
            </div>
        </div>
    </div>
</div>
