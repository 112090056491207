import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { SalesChannelService } from 'src/app/_business-dashboard/_services/bizz-sales-channel.services';
import { CouponReportsService } from 'src/app/_business-dashboard/_services/reports.services';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';



@Component({
  selector: 'app-bizz-cupon-summary-report-list',
  templateUrl: './bizz-cupon-summary-report-list.component.html',
  styleUrls: ['./bizz-cupon-summary-report-list.component.scss']
})
export class BizzCouponSummaryReportListComponent implements OnInit {

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  isViewOnly = false
  orderParam = ''
  orderBy = 'asc'
  salesChannelList:any[] = [];
  subscriptionPlanList:any[]=[];
  couponCodesList:any[]=[];

  colHeader = this._abd.reports.filter(x => x.paraReportName == 'Coupon Summary')[0].summaryHeader;
  colflds = this._abd.reports.filter(x => x.paraReportName == 'Coupon Summary')[0].colflds;
 

  @ViewChild('p', { static: false }) paginator: Paginator;


  constructor(
    public salesChannelServices: SalesChannelService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
    private currencyPipe: CurrencyPipe,
    private _subscriptionPlanService: SubscriptionPlanService,
    private _reportsService: CouponReportsService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Reports'
    this._globals.secondTitle = 'Coupon Summary'

  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
         // if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
        // if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)
      } 
      clearInterval(ti)
      this.getSubscriptionPlans()
    }, 500);
  }

  getDataList() {
    this._abd.showSpinner()    
    const obj = {
      "filterValue": this._filterService.searchText,
      "discountType": this._filterService.selectedCouponCodeDiscountType !== null ? this._filterService.selectedCouponCodeDiscountType.id: -1,
      "discountStart": this._filterService.startAmount,
      "discountEnd": this._filterService.endAmount ,
      "startDate": formatDate(this._filterService.dateRange[0], 'yyyy-MM-dd', 'en-US'),
      "endDate": formatDate(this._filterService.dateRange[1], 'yyy-MM-dd', 'en-US'),
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    }
    try {
     
      this._reportsService.getCouponReportsList(obj).subscribe(res => {
        if (res.success) {
          this._abd.hideSpinner()
          this.totalRecords = res.totalRecords
          this.dataList = res.data
          this.masterDataList = this.dataList;
        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }
  getSubscriptionPlanList(value?:any){
  let subscriptionPlan = [];
    const dataArray: string[] = value.split(',');
    dataArray.map((v)=>{
      let data = this.subscriptionPlanList.filter((x)=>x.id === v)[0]?.title
      subscriptionPlan.push(data)
    })
    return subscriptionPlan.join(',')
  }
getDiscountType(value:number){
  if( value === 0){
    return 'Flat';
  }else if(value === 1){
    return 'Percentage';
  }else{
    return 'Tracking Only'
  }
}
getDiscountValue(value:number,type:number){
  if( type === 0){
    return '$'+value;
  }else if(type === 1){
    return value+'%';
  }else{
    return '-'
  }
}
  getSubscriptionPlans(){
    this._abd.showSpinner()
     this._subscriptionPlanService.getSubscriptionPlanList().subscribe(res => {
      this.getDataList();
      if (res.success) {
      this.subscriptionPlanList = res.data    
      }
    },()=>{
      this.getDataList();
      this._abd.hideSpinner()
    })
}

  ngOnDestroy() {
    this._globals.componentName = ''
    this._globals.secondTitle = ''
  }

  refrestDataList() {
    this.getDataList();
  }


  callbackFuncFromFilter(value:any) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }
  exportExcel() {
    let list = this.dataList.map((v)=>{
      return{
          ...v,subscriptionPlanList:this.getSubscriptionPlanList(v.subscriptionPlan),discountValueType:v.discountType
      }
  })
    this._abd.exportExcel(list, this.colHeader[0], this.colflds, 'Coupon Summary')
  }

  exportPdf() {
    let list = this.dataList.map((v)=>{
      return{
          ...v,subscriptionPlanList:this.getSubscriptionPlanList(v.subscriptionPlan),discountValueType:v.discountType
      }
  })
    this._abd.exportPdf(list, this.colHeader, this.colflds, 'Coupon Summary', true,false,false)
  }
  calculateSummaryTotal(col: string) {
    let total = "0";
    // if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("noCouponUsed")) {
    if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.includes("couponUsed")) {
      total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
    }
    else {
      total = this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
    }
    return total;

  }
  customSort(e) {
    const last = this.orderParam
    switch (e.sortField) {

      case 'channelName':
        this.orderParam = 'AppNotificationText'
        break;
      case 'couponCode':
        this.orderParam = 'couponCode'
        break;
      case 'discountType':
        this.orderParam = 'discountValueType'
        break;
      case 'discountAmount':
        this.orderParam = 'discountAmount'
        break;
      case 'subscriptionPlanTitle':
        this.orderParam = 'subscriptionPlanTitle'
        break;
      case 'couponUsed':
        this.orderParam = 'couponUsed'
        break;
      case 'discountAmount':
        this.orderParam = 'discountAmount'
        break;
      default:
        this.orderParam = ''
    }
    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }

}