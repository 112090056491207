<div>
    <h3 class="text-center text-primary">Tell us about your business</h3>
</div>
<form [formGroup]="signupForm" (ngSubmit)="(signupForm.valid)">
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid">
                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">Company Name *</label>
                        <input
                            pInputText
                            [formControl]="signupForm.controls['companyName']"
                            [class.class-input-error]="companyNameIsAvailable"
                        />
                    </div>
                    <div class="field col">
                        <label htmlFor="email2">Type of Industry *</label>
                        <p-dropdown
                            [options]="industryTypes"
                            [formControl]="signupForm.controls['selectedType']"
                            optionLabel="name"
                        ></p-dropdown>
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col sm:w-full">
                        <label htmlFor="name2">Business Phone *</label>
                        <!-- <app-phone-input [formControl]="signupForm.controls['businessPhone']">
                        </app-phone-input> -->
                        <p-inputMask
                            mask="(999) 999-9999"
                            id="name2"
                            type="text"
                            [formControl]="signupForm.controls['businessPhone']"
                        ></p-inputMask>
                    </div>
                    <div class="field col"></div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">Address *</label>
                        <input
                            pInputText
                            id="name2"
                            type="text"
                            [formControl]="
                                signupForm.controls['businessAddress']
                            "
                        />
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col-3">
                        <label htmlFor="name2">Zip Code *</label>
                        <p-inputMask
                            [formControl]="
                                signupForm.controls['businessZipCode']
                            "
                            mask="99999"
                        ></p-inputMask>
                    </div>
                    <div class="field col-6">
                        <label htmlFor="name2">City *</label>
                        <input
                            pInputText
                            id="name2"
                            type="text"
                            [formControl]="signupForm.controls['businessCity']"
                        />
                    </div>
                    <div class="field col-3">
                        <label htmlFor="name2">State *</label>
                        <p-dropdown
                            [options]="usaStates"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            placeholder="Select a State"
                            [formControl]="signupForm.controls['selectedState']"
                        ></p-dropdown>
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <p-checkbox
                            [formControl]="
                                signupForm.controls[
                                    'isBusinessAddressForBilling'
                                ]
                            "
                            binary="true"
                            inputId="binary"
                            label="Use Business Address for billing"
                        ></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="flex justify-content-between flex-wrap card-container">
    <button
        pButton
        pRipple
        type="button"
        label="Back"
        class="p-button-warning"
        (click)="backButtonPressed()"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        label="Next"
        (click)="nextButtonPressed()"
    ></button>
</div>
