import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-signup-hardware-list',
  templateUrl: './signup-hardware-list.component.html',
  styleUrls: ['./signup-hardware-list.component.scss']
})
export class SignupHardwareListComponent implements OnInit {
  SelectedHardware: any = {
    selectedQty: 0,
    addons: []
  }

  HardWarePackages: any
  yesHaveHardware = true
  yesDoProcessOnlinePayments = true

    @Input() showFooter: boolean = true
    @Output() closeDialogHardware = new EventEmitter<any>()

    addonAdded: string = "";
    constructor(public _signup: SignupService,
        public _abd: AppBaseDataService,
        private _spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        this.yesHaveHardware = true
        let obj = this._signup.haveHardware.find(f => f.id == this._signup.selectedHaveHardwareId)
        this.yesHaveHardware = obj.answer === 'yes' ? true : false

        this.yesDoProcessOnlinePayments = true
        let obj1 = this._signup.doProcessOnlinePayments.find(f => f.id == this._signup.selectedDoProcessOnlinePaymentsId)
        this.yesDoProcessOnlinePayments = obj1.answer === 'yes' ? true : false

        this.makeHardwareList()


    }

    backButton(){
        this._signup.currentStep = this._signup.currentStep - 1
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
    }

    makeHardwareList() {
        let items = JSON.parse(JSON.stringify(this._signup.hardWarePackagesList))
        for (let i = 0; i < items.length; i++) {
            if (!this.yesDoProcessOnlinePayments && items[i].packageTypeString == 'PaymentDevices') {
                items.splice(i, 1)
                break
            }
        }

        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < items[i].items.length; j++) {

                if (!items[i].items[j].isActive || items[i].items[j].isTrial || items[i].items[j].isCustom) {
                    items[i].items.splice(j, 1)
                    continue
                } else if (this.yesHaveHardware) {
                    if (items[i].items[j].title !== "" && items[i].items[j].packageTypeString == 'Hardware') {
                        items[i].items.splice(j, 1)
                        continue
                    }
                } else if (!this.yesHaveHardware) {
                    if (items[i].items[j].title == "" && items[i].items[j].packageTypeString == 'Hardware') {
                        items[i].items.splice(j, 1)
                        continue
                    }
                }

                items[i].items[j].selectedQty = 1
                items[i].items[j].StockOnHand = 100


        //--nas-begin
            try {
            let item = items[i].items[j]
            item.netPrice = item.price

            let addons = items[i].items[j].addons
            for (let k = 0; k < addons.length; k++)
                addons[k].isSelected = false

            let exists = this._signup.selectedHardware
            for (let x = 0; x < exists.length; x++) {
                if (exists[x].id == items[i].items[j].id) {
                items[i].items[j] = exists[x]
                break
                }
            }
            } catch { }
        //--nas-end
      }
    }
    this._spinner.hide();

    // console.log("SELECTED-ITEMS", this._signup.selectedHardware)

    //-- nas-begin

    //-- nas-end
    this.HardWarePackages = JSON.parse(JSON.stringify(items));
    this.HardWarePackages.map(f=> f.showPackage = true);
    let self = this;
    this.HardWarePackages.map(function(v){
        v.items.map(f => f.addedInCart = false)
    })

    if (this._signup.selectedHaveHardwareId == 0){
        this.HardWarePackages.filter(f => f.packageTypeString == "Hardware")[0].showPackage = false;
    }

    let selectedHardware: any = sessionStorage.getItem('selectedHardware');

    if (selectedHardware != undefined) {
        selectedHardware = JSON.parse(selectedHardware);
        this.HardWarePackages.map(function (v) {
            v.items.map(function(f){
                if (selectedHardware.filter(x=> x.id == f.id).length > 0){

                    f.selectedQty = selectedHardware.filter(x => x.id == f.id)[0].selectedQty;
                    if (selectedHardware.filter(x => x.id == f.id)[0].addonAdded != undefined){
                        if (f.addons.length > 0){
                            f.addons.map(function (a) {

                                a.addonAdded = (selectedHardware.filter(x => x.id == f.id)[0].addons.filter(fa => fa.id == a.id)[0].includeAddon ? "yes" : "no")

                            })
                        }

                       // f.addons.filter() = (selectedHardware.filter(x => x.id == f.id)[0].addons[0].addonAdded ? "yes":"no");
                        //this._signup.selectedHardware
                    }

                    f.addedInCart = true;
                }
            })
        })
    }
    //   }
    // })
  }

    itemAddonSelected(selectedHardware, addon, addonAdded:string) {
        //addon.isSelected = e.checked

        this.SelectedHardware.addons.filter(f => f.id != addon.id).map(f => f.includeAddon = false)
        addon.includeAddon = (addonAdded == "yes" ? true : false) ;
        addon.addonAdded = addonAdded;
        selectedHardware.addonAdded = addon.includeAddon
        this._signup.calcSelectedHardwarePrice(selectedHardware)
    }

    getWordsWithSpace(str) {
        if (str != '' && str != undefined){
            return str.replace(/[A-Z]/g, ' $&').trim()
        }

    }

    displayPopup = false;
    showDialog(item) {
        this.SelectedHardware = item;
        if (this.SelectedHardware.addons.length > 0 && !item.addedInCart){
            this.SelectedHardware.addons.map(f=> f.includeAddon = false)
            this.SelectedHardware.addons.map(f => f.addonAdded = '')

        }
        this.displayPopup = true;
    }

    changeQuantity(e: any, SelectedHardware: any){
        try {
            if (e.target == undefined || e.target == null) {
                SelectedHardware.selectedQty = 0;
            }
            if (e.value.toString() == ""
                || isNaN(e.value) || e.value == null) {
                SelectedHardware.selectedQty = 0;
            }

            SelectedHardware.selectedQty = parseInt(e.value)
        }
        catch(ex){

        }
    }

    AddToCart(item:any) {

        try {

            if (!item.addedInCart) {
                this.displayPopup = false;
                item.addedInCart = true;

                let found = this._signup.selectedHardware.filter(x => x.id == item.id)
                let singleHardwareAddonTotal = 0;
                if (item.addons.length > 0) {
                    singleHardwareAddonTotal = item.addons.filter(f => f.includeAddon).reduce((a, b) => a + b.price, 0)
                }

                item.withoutAddonPrice = (item.price * item.selectedQty)
                item.subtotal = (item.price * item.selectedQty) + singleHardwareAddonTotal

                this._signup.selectedHardware.push(item);
            }
            else {
                this.displayPopup = false;
                this._signup.selectedHardware.filter(f => f.id == item.id)[0].addonAdded = item.addonAdded
                let self = this;
                this._signup.selectedHardware.filter(f => f.id == item.id)[0].addons.map(function(value){
                    value.addonAdded = item.addons.filter(f => f.id == value.id)[0].addonAdded
                    value.includeAddon = item.addons.filter(f => f.id == value.id)[0].includeAddon

                })
                if (item.selectedQty > 0) {

                    let found = this._signup.selectedHardware.filter(x => x.id == item.id)
                    let singleHardwareAddonTotal = 0;
                    if (item.addons.length > 0) {
                        singleHardwareAddonTotal = item.addons.filter(f => f.includeAddon).reduce((a, b) => a + b.price, 0)
                    }

                    item.withoutAddonPrice = (item.price * item.selectedQty)
                    item.subtotal = (item.price * item.selectedQty) + singleHardwareAddonTotal
                    this._signup.selectedHardware.filter(f => f.id == item.id)[0].selectedQty = item.selectedQty
                    this._signup.selectedHardware.filter(f => f.id == item.id)[0].subtotal = item.subtotal
                }
                else {
                    item.addedInCart = false;
                    item.withoutAddonPrice = (item.price * item.selectedQty)
                    item.subtotal = (item.price * item.selectedQty)
                    this._signup.selectedHardware.filter(f => f.id == item.id)[0].selectedQty = 0
                    this._signup.selectedHardware.filter(f => f.id == item.id)[0].subtotal = 0
                }

            }
        } catch (error) {

        }
    }

    continueButtonClick() {
        if (this.showFooter){
            this._signup.currentStep = this._signup.currentStep + 1
            this._signup.savedSteps = this._signup.currentStep;

            if (this._signup.savedSteps > 8) {
                this._signup.savedSteps = 8
            }

            let selectedHardware: any = sessionStorage.getItem('selectedHardware');

            if (this.HardWarePackages.filter(f => f.showPackage).length > 0){
                let packages :any = [];
                this.HardWarePackages.map(function(value){
                    if (value.items.filter(f => f.addedInCart).length> 0){
                        value.items.filter(f => f.addedInCart).map(function(f){
                            packages.push(f)
                        })

                    }
                });

                sessionStorage.setItem('selectedHardware', JSON.stringify(packages));
            }



            sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
            sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
        }
        else
        this.closeDialogHardware.emit()
    }

    isAlreadyinCart(e) {
        let found = false
        let obj = this._signup.selectedHardware
        obj.addedInCart = false;
        for(let i=0; i<obj.length; i++) {
            if(e.id == obj[i].id) {
                found = true
                obj.addedInCart = true;
                break
            }
        }

        return found
    }

    removeHardware(item: any){
        item.addedInCart = false;
        this._signup.selectedHardware.splice(this._signup.selectedHardware.findIndex(f=> f.id == item.id),1)
        this.displayPopup = false;
    }

}
