import { GlobalService } from 'src/app/_services/global.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { map } from 'rxjs';
import { Category } from '../_models/category';
import { ConnMessageService } from './conn-message.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  showNewEdit = false
  showNewNewEdit = false
  activeItem: Category
  selectedDepartment:any=''

  isViewOnly = false

  constructor(
    private _bs: BaseService,
    public _connMessageService: ConnMessageService,) { }

  getAllCategories(params: any, auth: boolean = false, showSpinner: boolean = true) {
    let url = environment.InventoryBaseURL + environment.CategoriesUrl

    return this._bs.httpGetParams(url, params).pipe(map(res => {
      return res
    }))
  }

  createUpdateCategory(obj: any) {
    // 
    let url = environment.InventoryBaseURL+ environment.CategoriesUrl
    url += obj.id ? ('/' + obj.id) : ''

    const model = {
      "id": obj.id,
      "categoryName": obj.categoryName,
      "isActive": obj.isActive,
      "departmentId": obj.departmentId
    }

    if (obj.id) {
      return this._bs.httpPut(
        url,
        JSON.stringify(model), true).pipe(map(res => {
          if (res.success) {
            this._connMessageService.showToastMessage('Category updated successfully.', 'success');
            return res
          }
        }))
    } else {
      return this._bs.httpPost(
        url,
        JSON.stringify(model), true).pipe(map(res => {
          if (res.success) {
            this._connMessageService.showToastMessage('Category saved successfully.', 'success');
            return res
          }
        }))
    }
  }

  deleteCategory(id:string){
    let url = environment.InventoryBaseURL+ environment.CategoriesUrl+"/"+id;

    return this._bs.httpDelete(url).pipe(map(response=>{
      return response;
    }))
  }


} 
