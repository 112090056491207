import { CategoryService } from 'src/app/_services/category.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { HttpParams } from '@angular/common/http';
import { InventoryCategoriesComponent } from '../../inventory/inventory-categories/inventory-categories.component';
import { Category } from 'src/app/_models/category';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-new-edit-category',
  templateUrl: './new-edit-category.component.html',
  styleUrls: ['./new-edit-category.component.scss']
})
export class NewEditCategoryComponent implements OnInit {
  whatAction = ''
  privateSelected: any = null
  name: any = ''
  editform: FormGroup
  validationErrors: string[] = []
  _globals = GlobalService
  @Input() isNew: boolean = true
  @Input() isEdit: boolean = false
  @ViewChild(InventoryCategoriesComponent) __parent!: InventoryCategoriesComponent
  @Output() refreshFn = new EventEmitter<any>()

  constructor(
    public _abd: AppBaseDataService,
    public _fb: FormBuilder,
    public _categoryService: CategoryService,
    public _productService: ProductsService,
    public _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
  ) { }

  ngOnInit(): void {

    if (this.isNew) {
      this.whatAction='Save'
      if(this.isNew) {
        this._categoryService.activeItem = this.initModel()
        this._categoryService.selectedDepartment = this._abd.departments[0]
      }
    } else {
      this.whatAction='Edit'
      const xx = this._abd.departments.filter(f => f.id === this._categoryService.activeItem.departmentId)
      // console.log(xx)
      this._categoryService.selectedDepartment = xx[0]
    }

    if(this.isEdit && !this._categoryService.isViewOnly) {
      this.whatAction='Save'
      const xx = this._abd.departments.filter(f => f.id === this._categoryService.activeItem.departmentId)
      this._categoryService.selectedDepartment = xx[0]
    }

  }

  ngAfterContentInit() {
    this.initForm()
  }

  initModel(): Category {
    var model: Category = {
      id: null,
      categoryName: null,
      departmentId: null,
      categoryId: null,
      createdDate: null,
      isActive: true,
      isDefault: null,
      lastEdited: null,
      addedBy: null,
      partitionKey: null,
      departmentName: null,
    }
    return model
  }

  initForm() {
    let self = this;
    let deptNameObj = this._abd.actDepartments.find(x => x.departmentName == self._categoryService.activeItem?.departmentName)
    this.editform = this._fb.group({
      categoryName: [{value: this._categoryService.activeItem?.categoryName, disabled: this._categoryService.isViewOnly}, Validators.required],
      // department: ['', Validators.required],
    })
  }

  onSelectItem(e) {
    this.isNew = false
    this.privateSelected = JSON.parse(JSON.stringify(e))
    this.editform.patchValue({ categoryName: e.categoryName })
  }


  // The below function fetches the department name from the formcontrol and maps it against the correct department id
  setDepartmentId() {
    // let deptName = this.editform.get('department').value.departmentName
    try {
      this._categoryService.activeItem.departmentId = this._categoryService.selectedDepartment.id //this._abd.actDepartments.filter(dep => dep.departmentName == deptName)[0].id
    }
    catch { }
  }

  saveFn() {
    if(this.whatAction=='Edit') {
      this._categoryService.isViewOnly = false
      this.whatAction='Save'
      this.editform.controls['categoryName'].enable()
      return
    }
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    if (!this.isNew) {
      try {
        this.setDepartmentId();
      } catch { }
    }
    if (this.isNew) {
      try {
        this.setDepartmentId();
      } catch { }
      //       const xx=this.editform.get('department').value
      // console.log('XX',this.editform)
      this._categoryService.activeItem.addedBy = this._globals.userInfo.userID
      this._categoryService.activeItem.id = null
    }

    this._abd.showSpinner()
    this._categoryService.createUpdateCategory(this._categoryService.activeItem).subscribe(res => {
      if (res.success) {
        // this._connMessageService.showToastMessage(`Vendor saved successfully!`, 'success')
        this.refreshFn.emit(true)
        this.cancelButtonClick()
        this.refreshParentData()
        this._abd.hideSpinner()
      }
    })
  }

  cancelButtonClick() {
    this._categoryService.showNewEdit = false
    this._categoryService.showNewNewEdit = false
    this._productService.isNewCategory = false
    this._categoryService.isViewOnly=false
  }

  refreshParentData() {
    this._abd.loadInit().subscribe(res => {
      if (res.success) {
        this._abd.loadInit().subscribe(res => {
          this._abd.initCategories(this._abd.initActCategories())
          this._abd.selectedCategory = this._abd.actCategories.filter(f => f.categoryName.toLowerCase() == this.editform.get('categoryName').value.toLowerCase())[0]
          return res
        })
      }
    })
  }



}
