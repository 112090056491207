import { ProductsService } from 'src/app/_services/products.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Router } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { observable, Observable } from 'rxjs';
import { Toast } from 'primeng/toast';
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportProductsComponent implements OnInit {
  _globals = GlobalService;
  importOption: any;
  selectedLocation: any;
  activeLocations: any;
  importStatus: string;
  importMessage: string;
  ngOnInit(): void {

    this._globals.componentName = 'Inventory Import'
    this.activeLocations = this._abd.initActLocations();
    this.importStatus = "";


  }
  uploadedFiles: any[] = [];
  constructor(
    public _productsService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    private messageService: MessageService,
    private _router: Router,
    ) { }
  ngOnDestroy() {
    this._globals.componentName = ''
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    // alert();
    this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }
  filestoupload: any;
  uploaderFn(event) {

    this.filestoupload = event.files
    this.importInventory();
  }
  gotoaddlocation()
  {
    this._router.navigate(['app/locations-list'])
  }
  importInventory() {

    try {
      let paras: any;
      if ((this.filestoupload != null)) {
        this.importStatus = "started";
        this._spinner.show();
        //this.errorInInventoryProducts = [];
        //this.importServerSideError = "";
        paras = {
          file: this.filestoupload[0],
          importOption: this.importOption,
          locationid: this.activeLocations > 1 ? this.selectedLocation.id : this.activeLocations[0].id
        }

        this._productsService.import(paras).subscribe(
          (res: any) => {
            this._spinner.hide()
            if (res.success) {
              if (res.data.length > 0 && res.data[0].skuCode === "") {

                    this.importStatus = "completed"
              }
              else
              {
                this.importStatus = "completed"
              }
              this.importMessage = res.message;
              this._abd.loadInit();
              
            }
            else {
              this.importStatus = "error"
              this.importMessage = res.message;
            }
          },
          (error: any) => {
            this.importMessage = error
            this.importStatus = "error"
          }
        )
        return;

        this._productsService.import(paras).subscribe(res => {
          if (res.success) {
            if (res.data.length > 0) {
              //  location.reload();
              // this.importServerSideError = d.message
              // this.errorInInventoryProducts = d.data;
              // if (this.errorInInventoryProducts.length > 0){
              //   this.fileInput.nativeElement.value = "";
            }
            //this.resetImportProduct();
            //this.importServerSideError = d.message
            else {
              //location.reload();
            }
            //this.loadNewDataAfterImport();
            this._abd.hideSpinner();

          }
          else {
            //console.log("Inventory Import failed");
            //this.importServerSideError = d.message;
            //this.fileImportingLoader = false;

            // if (d.code == "IMS0040903"){
            //   this.importServerSideError = this.errorList.filter(e => e.code == d.code)[0].message;
            // }

            // if (this.errorList.filter(e => e.code == d.code && (e.responseStatusCode != 903)).length > 0) {
            //   this.importServerSideError = this.errorList.filter(e => e.code == d.code)[0].message;
            // }
            // if (this.errorList.filter(e => e.code == d.code && (e.responseStatusCode != 903) && e.unAuthorize).length > 0) {
            //   this.service.logout();
            // }
          }

        }, error => {
          //this.importServerSideError = error.message;
          if (error.responseStatusCode == 900 || error.responseStatusCode == 901) {
            //this.importServerSideError = error.exception;
          }
          else {
            //this.importServerSideError = error.message;
          }
          this._abd.hideSpinner();

          //this.fileImportingLoader = false;
        })
      }

    } catch (error) {

    }
    return false;
  }

}
