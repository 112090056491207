import { formatDate } from '@angular/common';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoyaltyConfig } from 'src/app/_models/loyalty-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

export const pointsExpiry = [
  {
    id: 0,
    value: "Never"
  },
  {
    id: 1,
    value: "Every Month"
  },
  {
    id: 2,
    value: "Every Week"
  },
  {
    id: 3,
    value: "Yearly"
  }
]
export const redeemDay = [
  {
    id: 0,
    value: "Sunday"
  },
  {
    id: 1,
    value: "Monday"
  },
  {
    id: 2,
    value: "Tuesday"
  },
  {
    id: 3,
    value: "Wednesday"
  },
  {
    id: 4,
    value: "Thursday"
  },
  {
    id: 5,
    value: "Friday"
  },
  {
    id: 6,
    value: "Saturday"
  },
  {
    id: 7,
    value: "Everyday"
  }
]

export const weekDays = [
  {
    id: 0,
    value: "Sunday"
  },
  {
    id: 1,
    value: "Monday"
  },
  {
    id: 2,
    value: "Tuesday"
  },
  {
    id: 3,
    value: "Wednesday"
  },
  {
    id: 4,
    value: "Thursday"
  },
  {
    id: 5,
    value: "Friday"
  },
  {
    id: 6,
    value: "Saturday"
  },

]

@Component({
  selector: 'app-loyalty-program-configuration',
  templateUrl: './loyalty-program-configuration.component.html',
  styleUrls: ['./loyalty-program-configuration.component.scss']
})
export class LoyaltyProgramConfigurationComponent implements OnInit {

  loyaltyform: FormGroup;
  @Input() singupBusinessConfig: boolean = true;
  date7: Date;
  redeemDayList = redeemDay;
  pointsExpiryList =  pointsExpiry;
  weekDaysList = weekDays;
  loyaltyConfigData: LoyaltyConfig;


  constructor(private _fb: FormBuilder,
    public _businessSetupService: BusinessSetupService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.loyaltyConfigData = {
      active: this._abd.systemConfigs.loyaltyConfig?.active,
      maxAllowedPoints: this._abd.systemConfigs.loyaltyConfig?.maxAllowedPoints,
      minRedeemPPoints: this._abd.systemConfigs.loyaltyConfig?.minRedeemPPoints,
      maxRedeemPoints: this._abd.systemConfigs.loyaltyConfig?.maxRedeemPoints,
      eachPointDiscount: this._abd.systemConfigs.loyaltyConfig?.eachPointDiscount,
      pointsGoal: this._abd.systemConfigs.loyaltyConfig?.pointsGoal,
      minSpendAmountForReward: this._abd.systemConfigs.loyaltyConfig?.minSpendAmountForReward,
      minSpendAmountReward: this._abd.systemConfigs.loyaltyConfig?.minSpendAmountReward,
      redeemDay: this._abd.systemConfigs.loyaltyConfig?.redeemDay,
      pointsExpire: this._abd.systemConfigs.loyaltyConfig?.pointsExpire,
      startDate: this._abd.systemConfigs.loyaltyConfig?.startDate,
      endDate: this._abd.systemConfigs.loyaltyConfig?.endDate,
      weekDay: this._abd.systemConfigs.loyaltyConfig?.weekDay,
      time: this._abd.systemConfigs.loyaltyConfig?.timeParse,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    const enable=this.loyaltyform.get('active');
    enable?.valueChanges
    .subscribe(value=>this.disableControls(value))
  }

  ngAfterViewInit(){
    this.setCustomValidator()
  }


  disableControls(value: any): void {
    if(value == false){
      this.loyaltyform.get('maxAllowedPoints')?.disable();
      this.loyaltyform.get('minRedeemPPoints')?.disable();
      this.loyaltyform.get('maxRedeemPoints')?.disable();
      this.loyaltyform.get('eachPointDiscount')?.disable();
      this.loyaltyform.get('pointsGoal')?.disable();
      this.loyaltyform.get('minSpendAmountForReward')?.disable();
      this.loyaltyform.get('minSpendAmountReward')?.disable();
      this.loyaltyform.get('redeemDay')?.disable();
      this.loyaltyform.get('pointsExpire')?.disable();
      this.loyaltyform.get('startEndMonthDate')?.disable();
      this.loyaltyform.get('weekDay')?.disable();
      this.loyaltyform.get('time')?.disable();

    }
    else if(value == true){
      this.loyaltyform.get('maxAllowedPoints')?.enable();
      this.loyaltyform.get('minRedeemPPoints')?.enable();
      this.loyaltyform.get('maxRedeemPoints')?.enable();
      this.loyaltyform.get('eachPointDiscount')?.enable();
      this.loyaltyform.get('pointsGoal')?.enable();
      this.loyaltyform.get('minSpendAmountForReward')?.enable();
      this.loyaltyform.get('minSpendAmountReward')?.enable();
      this.loyaltyform.get('redeemDay')?.enable();
      this.loyaltyform.get('pointsExpire')?.enable();
      this.loyaltyform.get('startEndMonthDate')?.enable();
      this.loyaltyform.get('weekDay')?.enable();
      this.loyaltyform.get('time')?.enable();
    }
  }

  get f() {
    return this.loyaltyform.controls;
  }

  saveFn(){
    // let data: LoyaltyConfig = {
    //   active: this.f.active.value,
    //   maxAllowedPoints: parseInt(this.f.maxAllowedPoints.value),
    //   minRedeemPPoints: parseInt(this.f.minRedeemPPoints.value),
    //   maxRedeemPoints: parseInt(this.f.maxRedeemPoints.value),
    //   eachPointDiscount: parseInt(this.f.eachPointDiscount.value),
    //   pointsGoal: parseInt(this.f.pointsGoal.value),
    //   redeemDay: this.f.redeemDay.value.id,
    //   weekDay: this.f.weekDay.value.id,
    //   pointsExpire: this.f.pointsExpire.value.id,
    //   minSpendAmountForReward: parseInt(this.f.minSpendAmountForReward.value),
    //   minSpendAmountReward: parseInt(this.f.minSpendAmountReward.value),
    //   time: this.f.time.value.toLocaleTimeString('en-GB'),
    //   startDate: formatDate(this.f.startEndMonthDate.value[0], 'MM/dd/yyyy', 'en-US'),
    //   endDate: formatDate(this.f.startEndMonthDate.value[1], 'MM/dd/yyyy', 'en-US'),
    // };

    let data: LoyaltyConfig = {
      active: this.f.active.value,
      maxAllowedPoints: parseFloat(this.f.maxAllowedPoints.value),
      minRedeemPPoints: parseFloat(this.f.minRedeemPPoints.value),
      maxRedeemPoints: parseFloat(this.f.maxRedeemPoints.value),
      eachPointDiscount: parseFloat(this.f.eachPointDiscount.value),
      pointsGoal: parseFloat(this.f.pointsGoal.value),
      redeemDay: this.f.redeemDay.value.id,
      weekDay: this.f.weekDay.value.id,
      pointsExpire: this.f.pointsExpire.value.id,
      minSpendAmountForReward: parseFloat(this.f.minSpendAmountForReward.value),
      minSpendAmountReward: parseFloat(this.f.minSpendAmountReward.value),
      time: this.f.time.value.toLocaleTimeString('en-GB'),
      startDate: formatDate(this.f.startEndMonthDate.value[0], 'MM/dd/yyyy', 'en-US'),
      endDate: formatDate(this.f.startEndMonthDate.value[1], 'MM/dd/yyyy', 'en-US'),
    };

    // //console.log(data)

    try {
      this._abd.showSpinner()
      this._businessSetupService.saveLoyaltyConfigSettings(data).subscribe(res => {
        if (res.success) {
          this._connMessageService.showToastMessage('Loyalty program settings saved successfully.', 'success');
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._abd.hideSpinner()
            }
          })
        }
      })
    } catch {
      this._connMessageService.showToastMessage(`Error saving Loyalty program settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  initForm() {
    // Fixing the issue of timezone
    const dateStr = this.loyaltyConfigData?.time
    const date = new Date(dateStr);
    let  result = new Date(date.toISOString().slice(0, -1));

    this.loyaltyform = this._fb.group({
      active: [this.loyaltyConfigData.active, Validators.required],
      maxAllowedPoints: [{value: this.loyaltyConfigData.maxAllowedPoints, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      minRedeemPPoints: [{value: this.loyaltyConfigData.minRedeemPPoints, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maxRedeemPoints: [{value: this.loyaltyConfigData.maxRedeemPoints, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      eachPointDiscount: [{value: this.loyaltyConfigData.eachPointDiscount, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      pointsGoal: [{value: this.loyaltyConfigData.pointsGoal, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      minSpendAmountForReward: [{value: this.loyaltyConfigData.minSpendAmountForReward, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      minSpendAmountReward: [{value: this.loyaltyConfigData.minSpendAmountReward, disabled: !this.loyaltyConfigData.active},[Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      redeemDay: [{value: this.redeemDayList.filter(x => x.id == this.loyaltyConfigData.redeemDay)[0], disabled: !this.loyaltyConfigData.active},Validators.required],
      pointsExpire: [{value: this.pointsExpiryList.filter(x => x.id == this.loyaltyConfigData.pointsExpire)[0], disabled: !this.loyaltyConfigData.active},Validators.required],
      startEndMonthDate: [{value: [new Date(this.loyaltyConfigData.startDate),new Date(this.loyaltyConfigData.endDate)], disabled: !this.loyaltyConfigData.active}],
      weekDay: [{value: this.weekDaysList.filter(x => x.id == this.loyaltyConfigData.weekDay)[0], disabled: !this.loyaltyConfigData.active}],
      time: [{value: result, disabled: !this.loyaltyConfigData.active}],
    })
  }

  setCustomValidator(){
    this.f.pointsExpire.valueChanges.subscribe(value=>{
      if(value.value == 'Never'){
        this.f.startEndMonthDate.clearValidators();
        this.f.weekDay.clearValidators();
        this.f.time.clearValidators();
      }
      else if(value.value == 'Every Month'){
        this.f.startEndMonthDate.setValidators([Validators.required]);
        this.f.weekDay.clearValidators();
        this.f.time.setValidators([Validators.required]);
      }
      else if(value.value == 'Every Week'){
        this.f.startEndMonthDate.clearValidators();
        this.f.weekDay.setValidators([Validators.required]);
        this.f.time.setValidators([Validators.required]);
      }else if(value.value == 'Yearly'){
        this.f.startEndMonthDate.clearValidators();
        this.f.weekDay.clearValidators();
        this.f.time.setValidators([Validators.required]);
      }
      this.f.startEndMonthDate.updateValueAndValidity();
      this.f.weekDay.updateValueAndValidity();
      this.f.time.updateValueAndValidity();
    })
  }


}
