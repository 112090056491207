<div class="contaier next-level-page">
    <!-- header section start-->
    <div class="header-container flex flex-column justify-content-between align-items-center gap-3">
        <div class="logo-container">
            <img [src]="_globals.whiteLabel.logo" alt="logo" width="180px" />
        </div>
        <div class="page-heading">
            <h3>Merchant Account Application</h3>
        </div>
    </div>
    <!-- header section end-->
    <div class="tab-header">
        <div class="flex flex-row justify-content-center ">
            <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true" (onChange)="saveBusinessAndOther($event)">
                <p-tabPanel *ngFor="let tab of tabList" [header]="tab.label" [disabled]="tab.disabled">
                    <app-owner-details [formObj]="_nextLevelIntegrationService.formObj"
                        *ngIf="tab.label == 'Owner(s) Details'"
                        (ownersFormSaved)="ownersFormDetailsSave($event)">
                    </app-owner-details>
                    <app-beneficiaries-details [formObj]="_nextLevelIntegrationService.formObj"
                        (backButtonClicked)="backStep()"
                        (beneficiariesFormSaved)="beneficiariesFormSaved($event)"
                        *ngIf="tab.label == 'Beneficiaries Details'">
                    </app-beneficiaries-details>
                    <app-business-and-other [formObj]="_nextLevelIntegrationService.formObj"
                        (backButtonClicked)="backStep()"
                        *ngIf="tab.label == 'Business & Other Details'">
                    </app-business-and-other>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
