import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-inventory-adjustment-detail',
  templateUrl: './inventory-adjustment-detail.component.html',
  styleUrls: ['./inventory-adjustment-detail.component.scss']
})
export class InventoryAdjustmentDetailComponent implements OnInit {
  _globals = GlobalService

  colheader = [['Date', 'User', 'Adjustment ID', 'Adjustment Type', 'Location', 'Item', 'SKU', 'Barcode', 'Qty (#)', 'UOM', 'Unit Cost ($)', 'Total Price ($)']];
  colflds = ['dated', 'user', 'adjustmentId', 'adjustmentTypeString', 'locationName', 'itemName', 'skuCode', 'barcode', 'quantity', 'UOM', 'unitCost', 'totalCost'];
  summaryHeader = [['Date', 'User', 'Adjustment ID', 'Adjustment Type', 'Location', 'Item', 'SKU', 'Barcode', 'Qty (#)', 'UOM', 'Unit Cost ($)', 'Total Price ($)']];
  domSummaryflds = ['user', 'adjustmentId', 'adjustmentTypeString', 'locationName', 'itemName', 'skuCode', 'barcode', 'quantity', 'UOM', 'unitCost', 'totalCost'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';
  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Inventory Adjustment Details'
    this._filterService.selectedLocation = this._abd.locations[0];

    if (!this._filterService.dateRange[0]) this._abd.dateRange[0] = new Date('1800-12-12')
    if (!this._abd.dateRange[1]) this._abd.dateRange[1] = new Date()

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this.getDataList()
  }
  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  //   this.dataList[i].uomDescription = this._abd.uom.find(f => f.id == this.dataList[i].uomId).unitName

  getDataList() {
    if (!this._filterService.selectedInventoryAdjustment)
      this._filterService.selectedInventoryAdjustment = this._abd.inventoryAdjustmentTypes[0]
    const data = {
      "ReportName": 'AdjustmentDetailReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": [this._filterService.selectedLocation.locationId.toString()],
        "VendorIds": [],
        'filterValue': this._filterService.searchText,
        "AdjustmentType": this._filterService.selectedInventoryAdjustment.name
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      let self = this;
      this.dataList.map(function (value) {

        value.uom = self._abd.uom.find(f => f.id == value.uomId).unitName
      })
      this.dataList.map(x => {
        if (x.adjustmentType == '4') {
          x.adjustmentType = 'Transfer In'
        }
        else if (x.adjustmentType == '5') {
          x.adjustmentType = 'Transfer Out'
        }
        else if (x.adjustmentType == '6') {
          x.adjustmentType = 'Purchase'
        }
        else if (x.adjustmentType == '7') {
          x.adjustmentType = 'Inventory Count'
        }
        else if (x.adjustmentType == '8') {
          x.adjustmentType = 'Damage'
        }
        else if (x.adjustmentType == '9') {
          x.adjustmentType = 'Return To Sender'
        }

        else if (x.adjustmentType == '10') {
          x.adjustmentType = 'Lost'
        }
        else if (x.adjustmentType == '11') {
          x.adjustmentType = 'Expired'
        }
        else if (x.adjustmentType == '12') {
          x.adjustmentType = 'Exchange'

        }
        else if (x.adjustmentType == '13') {
          x.adjustmentType = 'Other'

        }
        else if (x.adjustmentType == '2') {
          x.adjustmentType = 'Auto Adjustment'

        }

      })
      this._abd.hideSpinner();
    })
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Inventory Adjustment Detail Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Inventory Adjustment Detail Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("totalCost")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("quantity")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
