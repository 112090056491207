<!-- <div class="conn-grid-2x"> -->
<div>
    <div>
        <div>
            <!-- <label class="control-label"
                    ><strong
                        >Invoice Configuration
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
            <br />
            <form [formGroup]="loyaltyform" (ngSubmit)="loyaltyform.valid && saveFn()" autocomplete="off">
                <div class="mb-4">
                    <p-inputSwitch [formControl]="$any(loyaltyform.controls)['active']" inputId="rememberme2">
                    </p-inputSwitch>
                    <label class="control-label" for="rememberme2"><strong> Enable</strong></label>
                </div>
                <br />

                <label class="control-label"><strong>Maximum Points Allowed</strong></label>
                <app-text-input [formControl]="$any(loyaltyform.controls)['maxAllowedPoints']" [label]=""
                    [name]="'Maximum Points Allowed'" (input)="0" [description]=""></app-text-input>
                <div *ngIf="f.maxAllowedPoints.touched && f.maxAllowedPoints.invalid" class="invalid-feedback">
                    <div *ngIf="f.maxAllowedPoints.errors.pattern">Value should be number</div>
                </div>
                <br />

                <label class="control-label"><strong>Minimum Points required to redeem</strong></label>
                <app-text-input [formControl]="$any(loyaltyform.controls)['minRedeemPPoints']" [label]=""
                    [name]="'Minimum Points redeem required to redeem Value'" (input)="0" [description]="">
                </app-text-input>
                <div *ngIf="f.minRedeemPPoints.touched && f.minRedeemPPoints.invalid" class="invalid-feedback">
                    <div *ngIf="f.minRedeemPPoints.errors.pattern">Value should be number</div>
                </div>
                <br />

                <!-- <label class="control-label"><strong>Max Points Redeem at once</strong></label>
                <app-text-input [formControl]="$any(loyaltyform.controls)['maxRedeemPoints']" [label]=""
                    [name]="'Max Points Redeem at once'" (input)="10" [description]=""></app-text-input>
                <div *ngIf="f.maxRedeemPoints.touched && f.maxRedeemPoints.invalid" class="invalid-feedback">
                    <div *ngIf="f.maxRedeemPoints.errors.pattern">Value should be number</div>
                </div>
                <br /> -->

                <label class="control-label"><strong>Each Points Equal to ($)</strong></label>
                <app-text-input [formControl]="$any(loyaltyform.controls)['eachPointDiscount']" [label]=""
                    [name]="'For Each Point, give discount'" (input)="10" [description]=""></app-text-input>
                <div *ngIf="f.eachPointDiscount.touched && f.eachPointDiscount.invalid" class="invalid-feedback">
                    <div *ngIf="f.eachPointDiscount.errors.pattern">Value should be number</div>
                </div>
                <br />


                <!-- <label class="control-label"><strong>Set Points Goal</strong></label>
                <app-text-input [formControl]="$any(loyaltyform.controls)['pointsGoal']" [label]=""
                    [name]="'Set Points Goal'" (input)="10" [description]=""></app-text-input>
                <div *ngIf="f.pointsGoal.touched && f.pointsGoal.invalid" class="invalid-feedback">
                    <div *ngIf="f.pointsGoal.errors.pattern">Value should be number</div>
                </div>
                <br /> -->

                <div class="label-with-input">

                    <label class="control-label"><strong>For purchase of </strong></label>
                    <div class="flex-wrap mx-2">
                        <app-text-input [formControl]="$any(loyaltyform.controls)['minSpendAmountForReward']" [label]=""
                            [name]="'a Dollar value'" (input)="10" [description]=""></app-text-input>
                        <div *ngIf="f.minSpendAmountForReward.touched && f.minSpendAmountForReward.invalid"
                            class="invalid-feedback">
                            <div *ngIf="f.minSpendAmountForReward.errors.pattern">Value should be number</div>
                        </div>
                    </div>
                    <label class="control-label"><strong> $,  add  </strong></label>
                    <div class="flex-wrap mx-2">
                        <app-text-input [formControl]="$any(loyaltyform.controls)['minSpendAmountReward']" [label]=""
                            [name]="'a numeric value'" (input)="10" [description]=""></app-text-input>
                        <div *ngIf="f.minSpendAmountReward.touched && f.minSpendAmountReward.invalid"
                            class="invalid-feedback">
                            <div *ngIf="f.minSpendAmountReward.errors.pattern">Value should be number</div>
                        </div>
                    </div>
                    <label class="control-label"><strong> Reward Point(s)</strong></label>

                </div>
                <br />

                <!-- reward redeem day -->
                <div class="mt-4">

                    <label class="control-label"><strong>Set Reward Redeem Day</strong></label>
                    <br />
                    <p-dropdown [formControl]="$any(loyaltyform.controls)['redeemDay']" [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }" class="p-dropdown-1" [options]="redeemDayList" optionLabel="value" placeholder=" ">
                    </p-dropdown>
                    <br />
                </div>
                <!-- <div class="flex">
                        <div class="flex-1 flex font-bold text-gray-900 m-2">
                        </div>
                    </div> -->

                <!-- Points Expiry -->
                <div class="mt-4">

                    <label class="control-label"><strong>Do Points Expire</strong></label>
                    <br />
                    <p-dropdown [formControl]="$any(loyaltyform.controls)['pointsExpire']" [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }" class="p-dropdown-1" [options]="pointsExpiryList" optionLabel="value" placeholder=" ">
                    </p-dropdown>
                    <br />
                </div>

                <div class="p-float-label hide-1024 mt-4" *ngIf="f.pointsExpire.value.id == 1">

                    <p-calendar [formControl]="$any(loyaltyform.controls)['startEndMonthDate']" selectionMode="range"
                        [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }" [readonlyInput]="false" inputId="multiple" class="filter" baseZIndex="0"
                        appendTo="body">
                    </p-calendar>
                    <label for="multiselect">Date Range</label>
                </div>

                <div class="p-float-label hide-1024 mt-4" *ngIf="f.pointsExpire.value.id == 2">

                    <p-dropdown [formControl]="$any(loyaltyform.controls)['weekDay']" [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }" class="p-dropdown-1" [options]="weekDaysList" optionLabel="value" placeholder=" ">
                    </p-dropdown>
                    <label for="multiselect">Day of Week</label>
                </div>

                <div class="p-float-label hide-1024 mt-4" *ngIf="f.pointsExpire.value.id != 0">

                    <!-- <p-dropdown
                            [formControl]="$any(loyaltyform.controls)['pointsExpiryHourlyTime']"
                            [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }"
                            class="p-dropdown-1"
                            [options]="weekDays"
                            optionLabel="value"
                            placeholder=" "
                        ></p-dropdown> -->
                    <p-calendar [formControl]="$any(loyaltyform.controls)['time']" [style]="{
                                height: '3rem',
                                width: '40rem',
                                'min-width': '120px'
                            }" [timeOnly]="true" [hourFormat]="12" inputId="timeonly">
                    </p-calendar>
                    <label for="multiselect">Time</label>
                </div>


                <div class="flex justify-content-end flex-wrap card-container green-container mt-3">
                    <div class="flex align-items-center justify-content-center">
                        <button pButton pRipple type="submit" label="Save" [disabled]="!loyaltyform.valid" [style]="{'width':'150px'}"></button>
                    </div>
                    <div class="flex align-items-center justify-content-center ml-2"
                        *ngIf="singupBusinessConfig == true">
                        <button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
                            (click)="cancelButtonClick()"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
