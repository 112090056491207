import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SecondaryScreen } from 'src/app/_models/secondary-screen';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-secondary-screen-configuration',
  templateUrl: './secondary-screen-configuration.component.html',
  styleUrls: ['./secondary-screen-configuration.component.scss']
})
export class SecondaryScreenConfigurationComponent implements OnInit {

  _globals = GlobalService
  
  fileToUpload: any;
  imageData = "assets/150-26.jpg";
  @Input() singupBusinessConfig: boolean = true;
  
  constructor(public _businessSetupService: BusinessSetupService,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService
    ) { }

  ngOnInit(): void {
    this.loadImages();
  }


  loadImages() {
    this._abd.showSpinner()
    this._businessSetupService.getSecondaryScreenSettings().subscribe(res => {
      if (res.success) {
        this._abd.hideSpinner()
        this._businessSetupService.thImages = []
        this._businessSetupService.thImageNames = []
        for (let f of res.data) {
          this._businessSetupService.thImages.push(f.content1)
          this._businessSetupService.thImageNames.push(f.content1)
        }
      }
    })
  }

  handleFileInput(event: any) {
    let file = event.target.files;
    this.fileToUpload = file.item(0);
    let fileName = this.fileToUpload.name
    const self = this
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageData = event.target.result;
    }
    reader.onloadend = function () {
      self._businessSetupService.thImages.push(reader.result)
      self._businessSetupService.thImageNames.push(fileName)
      // //console.log(self._businessSetupService.thImageNames)
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  handleFileInputMultiple(event: any) {
    let file = event.target.files;
    const self = this
    for (let i = 0; i < file.length; i++) {
      this.fileToUpload = file.item(i);
      let fileName = this.fileToUpload.name
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageData = event.target.result;
      }
      reader.onloadend = function () {
        self._businessSetupService.thImages.push(reader.result)
        self._businessSetupService.thImageNames.push(fileName)
        // //console.log(self._businessSetupService.thImageNames)
      }
      reader.readAsDataURL(this.fileToUpload);
    } 
  }

  onRemoveClick(event: any, i: any) {
    this._businessSetupService.thImages.splice(i, 1)
    this._businessSetupService.thImageNames.splice(i, 1)
  }

  saveFn(){
    // //console.log("Save button pressed.")
    let thfiles:SecondaryScreen[]  = []
    if (this._businessSetupService.thImages.length) {
      let x = 1
      for (let f of this._businessSetupService.thImages) {
        thfiles.push({
          locationId: 0,
          order: x++,
          content1: f,
          addedBy: this._globals.userInfo.userID,
          isActive: true,
          showAlways: true,
          contentType: 'Image',
          useType: 'Promotional',
          platform: 'POSAPP'
        })
      }
    }


    // //console.log(thfiles)

    try {
      this._abd.showSpinner()
      this._businessSetupService.saveSecondaryScreenSettings(JSON.stringify(thfiles)).subscribe(res => {
        if (res.success) {
          this._connMessageService.showToastMessage('Secondary screen settings saved successfully.', 'success');
          // saved = true
          this._abd.hideSpinner()
          // window.history.back()
        }
      })
    } catch { this._abd.hideSpinner() }


  }


}
