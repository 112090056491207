<div>
    <h3 class="text-center text-primary">Tell us about yourself</h3>
</div>
<form [formGroup]="signupForm" (ngSubmit)="(signupForm.valid)">
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid">
                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">First Name *</label>
                        <input
                            pInputText
                            [formControl]="signupForm.controls['firstName']"
                        />
                    </div>
                    <div class="field col">
                        <label htmlFor="name2">Last Name *</label>
                        <input
                            pInputText
                            [formControl]="signupForm.controls['lastName']"
                        />
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">Email *</label>
                        <input
                            pInputText
                            [formControl]="signupForm.controls['email']"
                            [class.class-input-error]="emailAlreadyExist"
                        />
                        <!-- <p
                            *ngIf="userAlreadyExist && inputEmail.length > 0"
                            class="text-danger d-block"
                        >
                            <i class="fa fa-info-circle"></i> This user already
                            exist. For login click
                            <a routerLink="" class="">here</a>
                        </p> -->
                    </div>
                    <div class="field col">
                        <label htmlFor="name2">Confirm Email *</label>
                        <input
                            pInputText
                            [formControl]="signupForm.controls['confirmEmail']"
                        />
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">Password *</label>
                        <p-password
                            class="w-fill"
                            [formControl]="signupForm.controls['password']"
                            [toggleMask]="true"
                        ></p-password>
                    </div>
                    <div class="field col">
                        <label htmlFor="name2">Confirm Password *</label>
                        <p-password
                            class="w-fill"
                            [formControl]="
                                signupForm.controls['confirmPassword']
                            "
                            [toggleMask]="true"
                            required
                        ></p-password>
                        <!-- <div class="row" *ngIf="validationErrors.length > 0">
                            <ul style="color:brown">
                                <li *ngFor="let error of validationErrors">
                                    {{error}}
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <p-checkbox
                            [formControl]="
                                signupForm.controls['useSameBusinessPhone']
                            "
                            binary="true"
                            inputId="binary"
                            label="Use the same business phone number"
                            (onChange)="checkboxOnChange($event)"
                        ></p-checkbox>
                    </div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <label htmlFor="name2">Phone *</label>
                        <p-inputMask
                            mask="(999) 999-9999"
                            id="name2"
                            type="text"
                            [formControl]="signupForm.controls['phone']"
                            [disabled]="
                                signupForm.controls['useSameBusinessPhone']
                                    .value
                            "
                        ></p-inputMask>
                    </div>
                    <div class="field col"></div>
                </div>

                <div class="p-formgrid grid">
                    <div class="field col">
                        <p class="text-center">
                            By Pressing Next, you are agreeing to our&nbsp;
                            <span
                                ><a
                                    href="https://www.termsfeed.com/live/d0de8709-41c8-4f69-908e-a9c53b6d8828"
                                    target="_blank"
                                >
                                    terms and conditions</a
                                ></span
                            >. Your account will be created and available for
                            future logins
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="flex justify-content-between flex-wrap card-container">
    <button
        pButton
        pRipple
        type="button"
        label="Back"
        class="p-button-warning"
        (click)="backButtonPressed()"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        label="Next"
        (click)="nextButtonPressed()"
    ></button>
</div>
