<style>
    .hd-cat-stock {
        padding: 5px;
        margin: 5px;
        border: 1px dashed #0d910d;
        border-radius: 5px;
        text-align: center;
        width: 123px;
        color: #15a315;
    }

    .already-in-cart {
        padding: 5px;
        margin: 5px;
        border: 1px dashed #0d910d;
        border-radius: 5px;
        text-align: center;
        width: 123px;
        background-color: #15a315;
        color:white;
    }

    .hd-cat-image img {
        width: 100%;
    }

    .hd-cat-title {
        font-size: 22px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .hd-cat-description p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .hd-cat-price {
        font-size: 22px;
        font-weight: bold;
        color: var(--BrandColor);
    }

    .hd-cat-action-btns {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .hd-cat-price label {
        color: var(--brandColor);
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        /* float: right; */
    }

    .hd-cat-action-btns
        input.p-inputtext.p-component.p-element.p-inputnumber-input {
        max-width: 50px !important;
    }

    .side-sticky-button {
        position: fixed;
        right: 0px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: var(--brandColor);
        color: var(--primary-color-text);
        text-align: center;
        z-index: 9999;
        overflow: hidden;
        cursor: pointer;
        border-radius: 50%;
        /* border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; */
    }
</style>

<div class="my-2">
    <p-dialog
        [modal]="true"
        [header]="SelectedHardware?.Name"
        [(visible)]="displayPopup"
        [breakpoints]="{ '1300px': '80vw', '640px': '100vw' }"
        [style]="{ width: '60vw' }"

        [resizable]="true"
    >
        <div class="grid ">
            <div class="col-6">
                <div class="hd-cat-image">
                    <img [src]="SelectedHardware?.image" />
                </div>
            </div>
            <div class="col-6">
                <div class=" mx-0 hd-cat-stock"
                 >
                  <!-- [ngClass]="{'hd-cat-stock':SelectedHardware?.StockOnHand > 0,
                 'not-hd-cat-stock':SelectedHardware?.StockOnHand <= 0}" -->
                    <label>
                        {{
                            SelectedHardware?.StockOnHand > 0
                                ? "Available in Stock"
                                : "Not Available in Stock"
                        }}
                    </label>
                </div>

                <div class="hd-cat-title">
                    <label>
                        {{ SelectedHardware?.title }}
                    </label>
                </div>
                <div class="hd-cat-description">
                    <p [innerHTML]="SelectedHardware?.rawData"></p>
                </div>

                <div
                    class="hd-cat-addons"
                    *ngIf="SelectedHardware?.addons.length > 0"
                >


                    <div class="flex align-items-center" *ngFor="let addon of SelectedHardware.addons">
                        <div
                            [ngClass]="{'selected-option': (addon.addonAdded == 'no')}"
                            (click)=" itemAddonSelected(SelectedHardware, addon , 'no' )"
                            class="flex ecommerceOptions cursor-pointer col-2 mr-2 md:col-2 lg:col-2 xl:col-2 xxl:col-2 justify-content-center align-items-center">
                            No</div>
                        <div
                            [ngClass]="{'selected-option': (addon.addonAdded == 'yes')}"
                            class="flex ecommerceOptions cursor-pointer col-9 md:col-9 lg:col-9 xl:col-9 xxl:col-9
                            align-items-center" (click)=" itemAddonSelected(SelectedHardware, addon, 'yes' )">
                            Yes, add a
                            {{ addon.title }} in my cart.
                            <div class="addon-price relative font-semibold right-0 signup-color pl-4 text-lg">
                                +{{ addon.price | currency }}</div>
                        </div>
                    </div>


                </div>
                <div class="flex align-items-center signup-hardware-quantity my-4">
                    <div class="col-6 md:col-2 lg:col-2 xl:col-2 xxl:col-2 flex justify-content-start">
                        <label class="font-semibold">Quantity </label>
                    </div>
                    <div class="col-6 md:col-4 lg:col-4 xl:col-4 xxl:col-4 flex justify-content-start">
                        <p-inputNumber
                            [min]="1"
                            [(ngModel)]="SelectedHardware.selectedQty"
                            [max]="100"
                            mode="decimal"
                             [allowEmpty]="false"
                              [maxlength]="3"
                            [showButtons]="true"
                            (onInput)="changeQuantity($event,SelectedHardware)"
                        ></p-inputNumber>
                    </div>
                    <div
                        class="col-6 md:col-6 lg:col-6 xl:col-6 xxl:col-6 flex  align-items-center justify-content-end">
                         <a *ngIf="SelectedHardware.addedInCart" (click)="removeHardware(SelectedHardware)"
                             class="text-red-600 mx-2 font-bold"
                             style="cursor:pointer"
                             >Remove</a>
                         <p-button
                            (onClick)="AddToCart(SelectedHardware)"
                            [disabled]="SelectedHardware.selectedQty <= 0"

                               class="p-button-sm"
                               label=""
                               [ngClass]="{'signup-back-button': !SelectedHardware.addedInCart}"
                              iconPos="right">
                             <span *ngIf="!SelectedHardware.addedInCart">Add to Cart</span>
                             <span *ngIf="SelectedHardware.addedInCart">Added to cart</span>
                         </p-button>
                    </div>
                </div>

            </div>
        </div>
    </p-dialog>

    <div *ngFor="let hwtype of HardWarePackages">
        <h3 class="hardware-type-title my-1" *ngIf="hwtype.showPackage">
            {{ hwtype.packageTitle }}
            <img title="{{ hwtype.packageTitle }}" src="assets/images/info.svg" />
        </h3>
        <div class="product-hardware-catalog grid" *ngIf="hwtype.showPackage">
            <div
                class="xl:col-4 lg:col-4 md:col-6 col-12"
                *ngFor="let item of hwtype.items"
            >
                <div ngClass="conn-card">
                    <div class="" *ngIf="item.title !== ''"
                        [ngClass]="{'hd-cat-stock':item.StockOnHand > 0,
                        'not-hd-cat-stock':item.StockOnHand <= 0}"
                    >
                        <label>
                            {{
                                item.StockOnHand > 0
                                    ? "Available in Stock"
                                    : "Available in Stock"
                            }}

                        </label>
                    </div>
                    <div class="hd-cat-image">
                        <img [src]="item.image" />
                    </div>
                    <div class="hd-cat-title">
                        <label>
                            {{ item.title }}
                        </label>
                    </div>
                    <div class="hd-cat-description device-description">
                        <p [innerHTML]="item.rawData"></p>
                    </div>
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                            <div class="hd-cat-price device-price" *ngIf="item.title !== ''">
                                <label>
                                    {{ item.price | currency }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="hd-cat-action-btns" *ngIf="item.title !== ''">
                        <p-button
                            (onClick)="showDialog(item)"
                            class="p-button-sm"
                            label=""
                            [ngClass]="{'signup-back-button':  !item.addedInCart}"
                            iconPos="right"
                        >
                        <span *ngIf="!item.addedInCart">Buy Now</span>
                        <span *ngIf="item.addedInCart">Added to cart</span>
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="flex justify-content-between flex-wrap card-container purple-container mt-8"
>
    <div class="flex align-items-center justify-content-center">
        <p-button
            *ngIf="showFooter"
            label="Back"
            class="signup-back-button"
            icon="pi pi-arrow-left"
            (onClick)="backButton()"
        ></p-button>
    </div>
    <!--  -->
    <div class="flex align-items-center justify-content-center">
        <p-button
            label="Continue"
            *ngIf="showFooter"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="continueButtonClick()"
        ></p-button>
    </div>
</div>
