import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AfterViewInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizePaymentTokenModel } from 'src/app/_models/authorize.net/authorize-net-payment-token-modal';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { environment } from 'src/environments/environment';

declare var window: any;

export interface iframeInterface {
    token: string;
    messages: Messages;
}

export interface subscInterface {
    refId: string;
    messages: Messages;
}

export interface Messages {
    resultCode: string;
    message: Message[];
}

export interface Message {
    code: string;
    text: string;
}

export interface BillTo {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

export interface TransactionData {
    accountType: string;
    accountNumber: string;
    transId: string;
    responseCode: string;
    authorization: string;
    merchantName: string;
    billTo: BillTo;
    totalAmount: string;
    dateTime: string;
}

@Component({
  selector: 'app-change-subscription-card',
  templateUrl: './change-subscription-card.component.html',
  styleUrls: ['./change-subscription-card.component.scss']
})
export class ChangeSubscriptionCardComponent implements AfterViewInit {

    title = 'pay';
    posting = false;
    @ViewChild('myFormPost', { static: false })
    myFormPost!: ElementRef;
    token: any = null
    paymentFormURL: any;
    customerProfileId: any
    _globals = GlobalService
    private httpClient: HttpClient;

    subscriptionPrice: number = 0.001

    constructor(
        private handler: HttpBackend,
        private http: HttpClient,
        private window: Window,
        private _signup: SignupService,
        private sanitizer: DomSanitizer,
        private _connMessageService: ConnMessageService,
        private _router: Router
    ) {
        this.setAuthorizePaymentEnvironment()
        this.httpClient = new HttpClient(this.handler)
        this.onPost()
     }

    ngOnInit(): void {

        const url = window.location.href;
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        this.customerProfileId = httpParams.get("pid");
        if(!this.customerProfileId)
        {
            alert("You are not allowed to change card, please contact support.")
            this._router.navigateByUrl('app/subscription-management')
        }
    }



    ngAfterViewChecked() {
        if (this.posting && this.myFormPost) {
            this.myFormPost.nativeElement.submit();
        }
    }

    onPost(): void {
        try {


        let websiteDomain: string = "";
        websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
        var n = websiteDomain.lastIndexOf('/');
        var result = websiteDomain.substring(n + 1);

        if (result == "") {
            websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

        }

        let token = <AuthorizePaymentTokenModel>{};

        token = {
            "getHostedPaymentPageRequest": {
                "merchantAuthentication": {
                    "name": environment.AUTHORIZE_NET_API_LOGIN_NAME,
                    "transactionKey": environment.AUTHORIZE_NET_API_LOGIN_KEY
                },
                "transactionRequest": {
                    "transactionType": "authOnlyTransaction",
                    "amount": this.subscriptionPrice,
                    "profile": {
                        "customerProfileId": this.customerProfileId

                    },
                    "customer": {
                        "email": this._globals.userInfo.email
                    },
                    "billTo": {
                        "firstName": this._globals.userInfo.firstName,
                        "lastName": this._globals.userInfo.lastName,
                        "company": this._globals.userInfo.companyName,
                        "address": this._globals.userInfo.businessSetup[0].address,
                        "city": this._globals.userInfo.businessSetup[0].city,
                        "state": this._globals.userInfo.businessSetup[0].state,
                        "zip": this._globals.userInfo.postalCode,
                        "country": this._globals.userInfo.country,
                    }

                },
                "hostedPaymentSettings": {
                    "setting": [{
                        "settingName": "hostedPaymentReturnOptions",
                        "settingValue": JSON.stringify({
                            "showReceipt": false, "url": websiteDomain + "/card-authorize-success.html",
                            "urlText": "Continue",
                            "cancelUrl": websiteDomain + "/card-authorize-success.html?response=cancel",
                            "cancelUrlText": "Cancel"
                        })

                    }, {
                        "settingName": "hostedPaymentButtonOptions",
                        "settingValue": "{\"text\": \"Pay\"}"
                    }, {
                        "settingName": "hostedPaymentStyleOptions",
                        "settingValue": "{\"bgColor\": \"blue\"}"
                    }, {
                        "settingName": "hostedPaymentPaymentOptions",
                        "settingValue": "{\"cardCodeRequired\": false, \"showCreditCard\": true, \"showBankAccount\": false}"
                    }, {
                        "settingName": "hostedPaymentSecurityOptions",
                        "settingValue": "{\"captcha\": false}"
                    }, {
                        "settingName": "hostedPaymentShippingAddressOptions",
                        "settingValue": "{\"show\": false, \"required\": false}"
                    }, {
                        "settingName": "hostedPaymentBillingAddressOptions",
                        "settingValue": "{\"show\": true, \"required\": false}"
                    }, {
                        "settingName": "hostedPaymentCustomerOptions",
                        "settingValue": "{\"showEmail\": false, \"requiredEmail\": false, \"addPaymentProfile\": true}"
                    }, {
                        "settingName": "hostedPaymentOrderOptions",
                        "settingValue": "{\"show\": true, \"merchantName\": \"G and S Questions Inc.\"}"
                    }, {
                        "settingName": "hostedPaymentIFrameCommunicatorUrl",
                        "settingValue":
                            JSON.stringify({ "url": websiteDomain + "/IFrameCommunicator.html" })
                    }]
                }
            }
        }

        this.http.post<iframeInterface>(environment.AuthorizeMerchantURL, token).subscribe((data) => {
            if (data["token"] != "") {
                this.token = data["token"];
                this.posting = true;
            }
            else {
                this.onPost();
            }
        })

        } catch (error) {
            console.log(error)
        }

    }

    setAuthorizePaymentEnvironment() {
        this.paymentFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.AUTHORIZE_NET_PAYMENT_URL);
    }

    ngAfterViewInit() {
        window.CommunicationHandler = {};
        window.CommunicationHandler.onReceiveCommunication = (argument: any) => {
            //Get the parameters from the argument.
            let params: any = this.parseQueryString(argument.qstr);
          //

            switch (params['action']) {
                case 'transactResponse':
                    const transResponse: any = JSON.parse(params['response']);
                    if (transResponse.responseCode == "1") {
                        let websiteDomain: string = "";
                        websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
                        var n = websiteDomain.lastIndexOf('/');
                        var result = websiteDomain.substring(n + 1);

                        if (result == "") {
                            websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

                        }

                        localStorage.setItem('goto', websiteDomain + "/#/app/subscription-management")
                        window.location.href = "/app/subscription-management";
                    }

                    break;

                default:

                    break;
            }
        };
    }


    parseQueryString(str: any) {
        var vars = [];
        var arr = str.split('&');
        var pair;
        for (var i = 0; i < arr.length; i++) {
            pair = arr[i].split('=');
            //vars[pair[0]] = unescape(pair[1]);
            vars[pair[0]] = pair[1];
        }
        return vars;
    }

}



