import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
    selector: 'app-inventory-adjustment-list',
    templateUrl: './inventory-adjustment-list.component.html',
    styleUrls: ['./inventory-adjustment-list.component.scss']
})
export class InventoryAdjustmentListComponent implements OnInit {
    page = 0
    rows = 10
    totalRecords = 0
    lsearchText = ''
    csvcols = []
    dataList: any = ''
    selectedRow: any = ''
    actions: MenuItem[] = []

    selectedLoc: any = null

    _globals = GlobalService
    @ViewChild('opActions') opActions: OverlayPanel
    constructor(
        public _abd: AppBaseDataService,
        private _productService: ProductsService,
        private _spinner: NgxSpinnerService,
        private _router: Router,
        private _messageService: MessageService,
        private _filterService: FilterServiceService,
    ) { }

    ngOnInit(): void {
        this._globals.componentName = 'Inventory Adjustment'



        const locations = this._abd.initActLocations()

        if (this._abd.initActLocations()) {
            this._abd.selectedLocation = locations[0]
        } else {
            this._messageService.add({
                severity: 'error',
                summary: 'Error', detail: 'Please select a location!'
            })
            return
        }
        this.selectedLoc = this._abd.selectedLocation
        this.getDataList()
    }

    ngOnDestroy() {
        this._globals.componentName = ''
    }


    getDataList() {
        try {
            const sd = this._filterService.dateRange[0]
            const ed = this._filterService.dateRange[1]
            if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
                this._filterService.dateRange[1] = sd
            else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
                this._filterService.dateRange = []
                this._filterService.dateRange[0] = new Date()
                this._filterService.dateRange[1] = new Date()
            }
        } catch {
            this._filterService.dateRange = []
            this._filterService.dateRange[0] = new Date()
            this._filterService.dateRange[1] = new Date()
        }


        this._productService.isReject = false
        this._spinner.show()
        if (!this._filterService.selectedLocation)
            this._filterService.selectedLocation = this.selectedLoc
        else this.selectedLoc = this._filterService.selectedLocation

        if (!this._filterService.selectedLocation) {
            this._messageService.add({
                severity: 'error',
                summary: 'Error', detail: 'Please select a location!'
            })
            return
        }

        if (!this._filterService.selectedInventoryAdjustment)
            this._filterService.selectedInventoryAdjustment = this._abd.inventoryAdjustmentTypes[0]

        let temp = {
            "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
            "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
            "LocationIds": [this._filterService.selectedLocation ? this._filterService.selectedLocation.locationId : ''],
            "VendorIds": [],
            "filterValue": this._filterService.searchText,
            "AdjustmentType": this._filterService.selectedInventoryAdjustment.name,
            "PageNo": this.page,
            "PageSize": this.rows
        }
        this._productService.getInventoryAdjustmentList(temp).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
                this.totalRecords = res.totalRecords
                this.dataList = res.data
                this.dataList.map(x => {
                    if (x.adjustmentType == '4') {
                        x.adjustmentType = 'Transfer In'
                    }
                    else if (x.adjustmentType == '5') {
                        x.adjustmentType = 'Transfer Out'
                    }
                    else if (x.adjustmentType == '6') {
                        x.adjustmentType = 'Purchase'
                    }
                    else if (x.adjustmentType == '7') {
                        x.adjustmentType = 'Inventory Count'
                    }
                    else if (x.adjustmentType == '8') {
                        x.adjustmentType = 'Damage'
                    }
                    else if (x.adjustmentType == '9') {
                        x.adjustmentType = 'Return To Sender'
                    }

                    else if (x.adjustmentType == '10') {
                        x.adjustmentType = 'Lost'
                    }
                    else if (x.adjustmentType == '11') {
                        x.adjustmentType = 'Expired'

                    }
                    else if (x.adjustmentType == '12') {
                        x.adjustmentType = 'Exchange'

                    }
                    else if (x.adjustmentType == '13') {
                        x.adjustmentType = 'Other'

                    }
                    else if (x.adjustmentType == '2') {
                        x.adjustmentType = 'Auto Adjustment'

                    }

                })

                for (let i = 0; i < this.dataList.length; i++) {
                    this.dataList[i].srNo = i + 1
                }
            }
        })
    }

    callbackFuncFromFilter(event) {
        if (this._abd.searchText == '') {
            // this._abd.resetFilters()
            this.getDataList()
            this.lsearchText = this._abd.searchText
            return
        }
        if (this.lsearchText == this._abd.searchText) return
        this.lsearchText = this._abd.searchText
        this.getDataList()
    }

    filterButtonPressed() {
        this.page = 0
        this.getDataList()
    }

    onRowSelect(e) {
        this._productService.activeItem = e.data
        // console.log(this._productService.activeItem)
        this._productService.isViewOnly = true
        this._productService.isEdit = false
        if (this._abd.whichRoute == 'any') this._router.navigate(['app/app-inventory-adjustment'])
    }

    onRowUnselect(e) { }

    exportExcel() { }

    exportPdf() { }

    onPageChange(e) {
        e.totalRecords = this.totalRecords
        this.rows = e.rows
        this.page = e.page

        this.getDataList()
    }

    actionButtonClicked(item) {
        this._productService.activeItem = item
        let canEdit = false
        let canCancel = false

        if (item.transfersOutStatus !== 1) {
            canEdit = true
            canCancel = true
        }

        this.actions = [
            // { id: '1', label: 'New', escape: false, command: (event) => { }, disabled: true },
            { id: '2', label: 'Edit', escape: false, command: (event) => { this.optionEditClicked() }, disabled: canEdit },
            { id: '3', label: 'Cancel', command: (event) => { }, disabled: canCancel },
        ]
    }
    optionEditClicked() {
        const item = this._productService.activeItem

        if (!item || item.transfersOutStatus !== 1) {
            return
        }

        this._productService.isViewOnly = false
        this._productService.isNew = false
        this._productService.isEdit = true
        this.opActions.hide()
        // console.log("transfer-out-ACTIVE-ITEM", this._productService.activeItem)
        if (this._abd.whichRoute == 'any') this._router.navigate(['app/transfer-out-form'])
        else this._router.navigate(['handheld/' + 'transfer-out-form'])
    }
    optionDeleteClicked() {  }

    onEditClick(item) { }

    locationChanged(e) {
        this.getDataList()
    }

    doEnableActionButton(item) {
        const showOn = [1]
        // try {
        if (showOn.find(f => f == item)) return false
        else return true
        // } catch { return true }
    }

    // _abd.transferStatusOut = [
    //   { id: 1, title: 'Draft' },
    //   { id: 2, title: 'Cancelled' },
    //   { id: 3, title: 'Posted' },
    //   { id: 4, title: 'Transferred' },
    //   { id: 5, title: 'Completed' },
    // ]


}
