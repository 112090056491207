<div class="flex justify-content-center">
    <div class="flex align-items-center justify-content-center font-bold text-gray-900 text-3xl pt-2 my-2">
        Subscription Plan
    </div>
</div>

<div class="flex justify-content-center">
    <div class="flex align-items-center justify-content-center plan-description text-lg my-1">
        Choose a plan that's right for your business needs
    </div>
</div>

<div class="flex justify-content-center">
    <div class="flex align-items-center justify-content-center mt-2 body-text ">
        <p-selectButton [options]="planButtons" [(ngModel)]="_signup.outerSelectedPlanTime" optionLabel="label"
            optionValue="value" (onChange)="changeOption($event)">
        </p-selectButton>
    </div>
</div>

<!-- <div class="flex justify-content-center">
    <div class="flex align-items-center justify-content-center">Subscription Plan</div> -->
<!-- <p class="body-text pricing-plan-tagline m-0 mb-3">Choose a plan that's right for your business needs</p>
    <div class="flex justify-content-center mb-2">
        <p-selectButton [options]="planButton" [(ngModel)]="_signup.subscriptionPlan.planButtonValue" optionLabel="label"
            optionValue="value" (onChange)="changeOption($event)">
        </p-selectButton>
    </div> -->
<!-- </div> -->