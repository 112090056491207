
<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<div class="conn-card p-0" *ngIf="isProductLabel && fromList">

    <div class="grid align-items-center">
        <div class="col-3">
            <label class="control-label">
                <strong>
                  Product
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>       
        </div>  
        <div class="col" style="width: 300px">
            <div class="p-inputgroup col-6">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search by scanning barcode"
                    [(ngModel)]="searchText"
                    (keydown.enter)="searchButtonClick($event)"
                    [disabled]="isLoading"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                    [disabled]="isLoading"
                ></button>
            </div>
            <span class="control-description text-muted" *ngIf="isProductLabel && !isNew && fromList" >In edit mode, you can add or edit one product at a time. For multiple products, use "Add New Product" on the main screen.</span>
        </div>
    </div>
        <div class="flex justify-content-center py-2 " *ngIf="isProductLabel && isProductLoad && !isNew">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem;color:var(--brandColor)"></i>
        </div>
    <br />
    
       <p-table
            #www
            *ngIf="dataList.length > 0"
            [value]="dataList"
            [autoLayout]="true"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                   <!-- <th style="text-align: center; width: 30px">
                        <p-checkbox
                            *ngIf="!isViewOnly"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th>  -->
                    <th>Barcode</th>
                    <th>SKU</th>
                    <th>Product</th>
                    <!-- <th>Location</th> -->
                    <th>Price</th>
                    <th>Action</th>

                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <!-- <td style="text-align: center">
                        <p-checkbox
                            *ngIf="!isViewOnly"
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td> -->
                    <td >{{ item.barcode }}</td>
                    <td >{{ item.skuCode }}</td>
                    <td >{{ item.productName }}</td>
                    <!-- <td >{{ item.locationName}}</td> -->
                    <td >{{ item.salePriceA | currency}}</td>
                    <td class="cursor-pointer flex justify-content-center" (click)="removeItem(item)" style="color: red;" ><i class="pi pi pi-times"></i></td>
                   
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="16" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    
</div> 
<ng-container *ngIf="!isProductLabel && fromList">
<form  
[formGroup]="customform"
autocomplete="off"
>
<div >
    <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                         Product Name
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>       
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <app-text-input
                    [formControl]="$any(customform.controls)['productName']"
                    [name]="'Product Name'"
                    [label]=""
                   >
                   </app-text-input>
                </div>
              </div> 
      
    </div>
    <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                         Barcode
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>       
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <app-text-input
                    [formControl]="$any(customform.controls)['barcode']"
                    [name]="'Barcode'"
                    [label]=""
                   >
                   </app-text-input>
                </div>
              </div> 
      
    </div>
    <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                        SKU
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>       
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <app-text-input
                    [formControl]="$any(customform.controls)['sku']"
                    [name]="'SKU'"
                    [label]=""
                   >
                   </app-text-input>
                </div>
              </div> 
      
    </div>
    <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                        Business
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>       
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <app-text-input
                    [formControl]="$any(customform.controls)['business']"
                    [name]="'Business'"
                    [label]=""
                   >
                   </app-text-input>
                </div>
              </div> 
      
    </div>
    <!-- <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                         Location
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>      
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <p-dropdown
                 [formControl]="$any(customform.controls)['location']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="singleLocationList"
                 optionLabel="locationName"
                 [autoDisplayFirst]="false"
             >
             </p-dropdown>
           
                    <div *ngIf="$any(customform.controls)['location'].invalid && $any(customform.controls)['location'].touched" class="invalid-feedback">
                        Please Select a Location
                    </div>
                </div>
              </div>  
      
    </div> -->
    <div class="grid align-items-center">

        <div class=" col-3">
                  <label class="control-label">
                      <strong>
                        Price($)
                          <span style="color: red; font-size: 1.25rem">*</span>
                      </strong>
                  </label>       
      </div>  
              <div class="col-9 p-0 row">
                <div class="col-6">
                    <app-text-input
                    [formControl]="$any(customform.controls)['price']"
                    [name]="'Price'"
                    [label]=""
                   >
                   </app-text-input>
                </div>
              </div> 
      
    </div>
</div>
</form>
</ng-container>
    <div class="grid align-items-center">

  <div class=" col-3">
            <label class="control-label">
                <strong>
                   Template
                    <span style="color: red; font-size: 1.25rem" >*</span>
                </strong>
            </label>       
</div>  
        <div class="col-9 p-0 flex row">  
            <div class="p-3 col-4 cursor-pointer" *ngFor="let temp of templateList">
           <img 
           [class]="
               temp.templateId == activeTemplateId
                   ? 'active-template'
                   : 'inactive-template'
           " (click)="activeTemplate(temp.templateId)"
            [src]="temp.templateURL" height="65vh"  alt="template">
               </div>
         
       
        </div> 

    </div>

<form
    [formGroup]="editform"
    autocomplete="off"
   >
   <div class="grid align-items-center">

    <div class=" col-3">
              <label class="control-label">
                  <strong>
                     Location
                      <span style="color: red; font-size: 1.25rem">*</span>
                  </strong>
              </label>      
  </div>  
          <div class="col-9 p-0 row">
            <div class="col-6">
                <p-dropdown
             [formControl]="$any(editform.controls)['location']"
             [style]="{}"
             class="p-dropdown-1"
             [options]="singleLocationList"
             optionLabel="locationName"
             [autoDisplayFirst]="false"
         >
         </p-dropdown>
         <span class="control-description text-muted">This location will be printed on the printing label.</span>
                <div *ngIf="$any(editform.controls)['location'].invalid && $any(editform.controls)['location'].touched" class="invalid-feedback">
                    Please Select a Location
                </div>
            </div>
          </div>  
  
</div>
    <div class="grid align-items-center">

  <div class=" col-3">
            <label class="control-label">
                <strong>
                    Printer Locations
                    <span style="color: red; font-size: 1.25rem" *ngIf="fromList">*</span>
                </strong>
            </label>       
</div>  
        <div class="col-9 p-0 row">
            <div class="col-6">
      <ng-container *ngIf="!isNew && fromList">
        <p-dropdown
        [formControl]="$any(editform.controls)['locations']"
        [style]="{}"
        class="p-dropdown-1"
        [options]="multiLocationList"
        optionLabel="locationName"
        [autoDisplayFirst]="false"
    >
    </p-dropdown>
      </ng-container>
      <ng-container *ngIf="isNew">
             <p-multiSelect
            [formControl]="$any(editform.controls)['locations']"
            [style]="{}"
            [options]="multiLocationList"
            optionLabel="locationName"
            [filter]="false"
            appendTo="body"
            [showToggleAll]="true"
        >
        <ng-template pTemplate="header">
           <div class="checkbox-all-text">Select All</div>
         </ng-template>
        </p-multiSelect>
        <div *ngIf="$any(editform.controls)['locations'].invalid && $any(editform.controls)['locations'].touched" class="invalid-feedback">
           Please enter a Location
       </div> 
      </ng-container>
           
            </div>
        
        </div> 

    </div>


    <div class="grid align-items-center">

  <div class=" col-3">
            <label class="control-label">
                <strong>
                   No of Labels to print
                    <span style="color: red; font-size: 1.25rem" *ngIf="fromList">*</span>
                </strong>
            </label>       
</div>  
        <div class="col-9 p-0 row">
          <div class="col-6">
              <app-text-input
              [formControl]="$any(editform.controls)['noOfCopy']"
              [name]="'No of Labels to print'"
              [label]=""
             >
             </app-text-input>
          </div>
        </div> 

    </div>
</form>
    <p-divider style="margin-top: 10px"></p-divider>
    <div class="grid" >
        <div class="col">
            <div
                class="flex justify-content-end flex-wrap card-container green-container mt-3"
            >
              
                <div
                    class="flex align-items-center justify-content-center mr-2"
                >
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        class="p-button-outlined p-button-danger"
                        (click)="cancelButtonClick()"
                        [style]="{ width: '120px' }"
                    ></button>
                </div>  
                <div class="flex align-items-center justify-content-center">
                    <button
                        pButton
                        pRipple
                        type="submit"
                        [label]="saveButton"
                        [disabled]="checkValidity()"
                        *ngIf="!isViewOnly"
                        (click)="saveFn()"
                        
                    ></button>
                </div>
            </div>
        </div>
    </div>          
 


