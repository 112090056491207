<div class="card">
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-3" *ngFor="let subscription of listSubscriptions">

            <app-subscription-plan-base [subscription]="subscription" (onSubscriptionSelected)="changeSubscription(subscription.id)"></app-subscription-plan-base>
        </div>
    </div>
</div>
<div class="flex justify-content-end" *ngIf="mode!=='modal'">
    <p-button label="Next" (onClick)="jumpNext()"></p-button>
</div>
