<form
    [formGroup]="editform"
    (ngSubmit)="editform.valid && saveFn()"
    (keyup)="saveFormDataToService()"
    autocomplete="off"
    class="signup-business-info"
>
    <div class="grid mt-2" *ngIf="!_signup.userAccountPendingForPayment">
        <div class="col-6">
            <label class="control-label">
                Company Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <br />
            <input
                style="width: 100%; margin-top: 7px"
                type="text"
                pInputText
                placeholder=""
                [formControl]="$any(editform.controls)['companyName']"
                [name]="'Company Name'"
                (blur)="doesCompanyExist()"
                [ngClass]="{'shipping-address-disabled':_signup.accountVerified}"
                (input)="
                    _signup?.businessInfo.companyName = $event.target.value
                "
            />
            <div *ngIf="f.companyName.touched">
                <div
                    *ngIf="f.companyName.errors?.required"
                    class="invalid-feedback"
                    style="margin-top: 7px"
                >
                    Please enter a company name!
                </div>
                <div
                    *ngIf="f.companyName.errors?.companyexist"
                    class="invalid-feedback"
                    style="margin-top: 7px"
                >
                    Company name already taken, please choose another name!
                </div>
            </div>
        </div>
    </div>

    <div class="grid mt-2" *ngIf="_signup.userAccountPendingForPayment">
        <div class="col">
            <label class="control-label">
                <strong>Company Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <br />
           <div class="col-12 company-name-disabled-input">
             <label> {{_signup?.businessInfo.companyName}}</label>
           </div>

        </div>
    </div>

    <div class="grid mt-2">
        <div class="col">
            <label class="control-label">
                Type of Industry
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
        </div>
    </div>
    <div class="grid w-11">
        <div class="col p-3 cursor-pointer" *ngFor="let ind of _signup.industries">
            <div
                [class]="
                    ind.id == _signup.businessInfo.industryTypeId
                        ? 'active-button'
                        : 'inactive-button'
                "
                (click)="industryTypeClick(ind.id)"
            >
            <div class="p-2 m-4" >
                 <div
                    class="flex align-items-center justify-content-center w-full font-bold text-xl text-900 mt-3 cursor-pointer"
                >
                    {{ ind.name }}
                </div>
                <div
                    class="flex align-items-center justify-content-center text-align-center w-full px-5 text-base text-500 mt-2 cursor-pointer"
                >
                    My business belongs to this category
                </div>
            </div>
               
            </div>
        </div>
    </div>

    <!-- <div class="grid mt-2">
        <div class="col">
            <label class="control-label">
                Business Phone
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <div class="p-inputgroup" style="margin-top: 7px">
                <div class="p-inputgroup">
                    <app-phone-input
                        [formControl]="$any(editform.controls)['phone']"
                        (input)="dummy()"
                    >
                    </app-phone-input>
                </div>
            </div>
        </div>
        <div class="col">
            <label class="control-label">
                Business Email
                    <span
                        style="
                            color: red;
                            font-size: 1.25rem;
                            margin-bottom: 7px; ;
                        "
                        >*</span
                    >
                </label
            >
            <div class="form-group" style="margin-top: 0px">
                <app-text-input
                    [formControl]="$any(editform.controls)['email']"
                    [name]="'Email'"
                    [label]="'Business Email'"
                    (input)="_signup?.businessInfo.email = $event.target.value"
                ></app-text-input>
            </div>

        </div>
    </div> -->

    <!-- <div class="grid mt-2">
        <div class="col">
            <label class="control-label">
                Address
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <app-text-input
                [formControl]="$any(editform.controls)['address']"
                [name]="'Address'"
                [label]="'Address'"
                (input)="_signup?.businessInfo.address = $event.target.value"
            ></app-text-input>
        </div>
    </div> -->

    <!-- <div class="grid mt-2">
        <div class="field col-12 md:col-4">
            <label class="control-label">
                Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <app-text-input
                formControlName="postalCode"
                [label]="'Zip Code'"
                [name]="'zip code'"
                (input)="zipInputEvent($event.target.value)"
            ></app-text-input>
        </div>
        <div class="field col-12 md:col-4">
            <label class="control-label">
               City
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <span class="p-input-icon-right" [style]="{ width: '100%' }">
                <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                <app-text-input
                     [name]="'city'"
                    formControlName="city"
                    [label]="'City'"
                ></app-text-input>
            </span>
        </div>
        <div class="field col-12 md:col-4">
            <label class="control-label">
                State
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label
            >
            <p-dropdown
                [style]="{
                    width: '100%',
                    'min-width': '200px',
                    'margin-top': '7px'
                }"
                class="p-dropdown-1"
                appendTo="body"
                [options]="usaStates"
                [(ngModel)]="selectedState"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [ngModelOptions]="{ standalone: true }"
            ></p-dropdown>
        </div>
    </div> -->
</form>

<div
    class="flex justify-content-between flex-wrap card-container purple-container mt-6"
>

    <div class="flex align-items-center justify-content-center">
        <p-button
            label="Back"
            class="signup-back-button"
            icon="pi pi-arrow-left"
            (onClick)="backClicked()"
        ></p-button>
    </div>

    <div class="flex align-items-center justify-content-center">
        <p-button
            [disabled]="!editform.valid || (_signup.businessInfo.industryTypeId == 0) || isButtonDisable"
            label="Continue"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="saveFn()"
        ></p-button>
    </div>
</div>
