<p-overlayPanel #opNotes [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <textarea
            [rows]="5"
            [cols]="30"
            pInputTextarea
            [(ngModel)]="this.activeItem4Editing.lineNotes"
        ></textarea>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
<!-- [value]="dataList" -->
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="searchList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice: 0:48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div>
    <h2 class="bs-page-header mb-3">Transfer Invoice</h2>
</div>

<div class="grid">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="text-muted">Transfer Info</div>
            <br />
            <div class="col">
                <div class="grid">
                    <div class="col-12">Transfer ID</div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="transferId"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-12">Sender Name</div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="userName"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="text-muted">Dates</div>
            <br />
            <div class="col">
                <div class="grid">
                    <div class="col-12">Creation Date</div>
                    <div class="col-12">
                        <p-calendar
                            [(ngModel)]="creationDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                        ></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-12">Expected Receiving Date</div>
                    <div class="col-12">
                        <p-calendar
                            [(ngModel)]="expectedDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="text-muted">Locations</div>
            <br />
            <div class="col">
                <div class="grid">
                    <div class="col-12">From Location</div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="locations"
                            [(ngModel)]="fromLocation"
                            optionLabel="locationName"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                            (onChange)="locationFromChanged($event)"
                            [ngClass]="[
                                fromLocation?.id == toLocation?.id
                                    ? 'ng-invalid ng-dirty border-2 border-red-300 flex align-items-center justify-content-center border-round-md'
                                    : ''
                            ]"
                        >
                        </p-dropdown>
                        <div
                            class="text-red-300 mt-1"
                            *ngIf="
                                fromLocation?.id == toLocation?.id && !isViewOnly
                            "
                        >
                            <span style="font-size: 12px">
                                To & From Locations cannot be same
                            </span>
                        </div>
                    </div>
                </div>
                <div class="grid mt-3">
                    <div class="col-12">To Location</div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="locations"
                            [(ngModel)]="toLocation"
                            optionLabel="locationName"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                            (onChange)="locationFromChanged($event)"
                            [ngClass]="[
                                fromLocation?.id == toLocation?.id
                                    ? 'ng-invalid ng-dirty border-2 border-red-300 flex align-items-center justify-content-center border-round-md'
                                    : ''
                            ]"
                        >
                        </p-dropdown>
                        <div
                            class="text-red-300 mt-1"
                            *ngIf="
                                fromLocation?.id == toLocation?.id && !isViewOnly
                            "
                        >
                            <span style="font-size: 12px">
                                To & From Locations cannot be same
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="conn-card">
    <br />
    <div class="grid">
        <div class="col-fixed" style="width: 300px">
            <div class="p-inputgroup">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search by scanning barcode"
                    [disabled]="isLoading || isViewOnly"
                    [(ngModel)]="searchText"
                    (keydown.enter)="searchButtonClick($event)"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [disabled]="isLoading || isViewOnly"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                ></button>
            </div>
        </div>
        <div class="col">
            <div class="flex justify-content-end flex-wrap">
                <div class="flex align-items-center justify-content-center m-2">
                    <button
                        *ngIf="activeItem.transfersOutStatus == 1 || isNew"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                        iconPos="right"
                        (click)="removeItem()"
                        [disabled]="isViewOnly || isEnableDeleteButton"
                    ></button>
                    <!-- label="Delete" -->
                </div>

                <div
                    *ngIf="!isNew"
                    class="flex align-items-center justify-content-center m-2"
                >
                    <button
                        *ngIf="activeItem.transfersOutStatus == 1"
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-warning p-button-outlined'
                                : 'p-button-rounded p-button-info p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                    ></button>
                    <!-- label="Edit" -->
                </div>

                <div class="flex align-items-center justify-content-center">
                    <p-chip
                        *ngIf="!isNew"
                        [label]="
                            _abd.getTransfersOutStatus(
                                activeItem.transfersOutStatus
                            )
                        "
                        [styleClass]="
                            _abd.getTransfersOutClass(
                                activeItem.transfersOutStatus
                            )
                        "
                    ></p-chip>
                    <p-chip
                        *ngIf="isNew"
                        label="New"
                        [styleClass]="_abd.getTransfersOutClass(0)"
                    ></p-chip>
                </div>
            </div>
        </div>
    </div>

    <br />
    <div>
        <p-table
            [value]="dataList"
            [autoLayout]="true"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center; width: 30px">
                        <p-checkbox
                        [(ngModel)]="selectAll"
                            [binary]="true"
                            [(ngModel)]="selectAll"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th>
                    <th>Sr#</th>
                    <th>SKU</th>
                    <th>Barcode</th>
                    <th>Description</th>
                    <th>Notes</th>
                    <th>Expiry Date</th>
                    <th>UOM</th>
                    <th style="text-align: center">Stock in Hand</th>
                    <th style="text-align: center">Xfer Qty</th>
                    <th style="text-align: right">Unit Cost ($)</th>
                    <th style="text-align: right">Total Cost ($)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td>
                    <td>{{ item.srNo }}</td>

                    <!-- <td>{{ item.itemSKUId }}<br />{{ item.barcode }}</td> -->
                     <td>
                        {{ item.skuItemCode }}
                        <!-- {{ item.productName }} -->
                    </td>
                    <td>
                        {{ item.barcode }}
                    </td>
                    <td>
                        {{ item.description | slice: 0:25 }}
                        <span *ngIf="item.description?.length > 25">...</span>
                    </td>
                    <td (onFocus)="lineNotesEditClick(e, item)">
                        <button
                            pButton
                            type="button"
                            icon="pi pi-book"
                            [ngClass]="
                                item.lineNotes?.length > 0
                                    ? 'p-button-rounded'
                                    : 'p-button-rounded  p-button-text'
                            "
                            (click)="lineNotesEditClick($event, item)"
                            [disabled]="isViewOnly"
                        ></button>
                        <!-- (click)="lineNotesEditClick($event, item)" -->
                    </td>
                    <td>
                        <p-calendar
                            appendTo="body"
                            [(ngModel)]="item.expiryDate"
                            [showIcon]="true"
                            inputId="icon"
                            [disabled]="isViewOnly"
                        ></p-calendar>
                    </td>
                    <td>{{ item.uomDescription }}</td>
                    <td style="text-align: center">{{ item.stockInHand }}</td>
                    <!-- <td>{{ product.transferQuantity }}</td> -->
                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.transferQuantity"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="
                                        transferQuantityChanged($event, item)
                                    "
                                    [ngClass]="[
                                        !transferQuantityValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!transferQuantityValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                zero or greater than stock in hand
                            </span>
                        </div>
                    </td>
                    <!-- <td style="text-align: right">
                        {{ item.costPrice | number: "1.2-2" }}
                    </td> -->
                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.costPrice"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="costPriceChanged($event, item)"
                                    [ngClass]="[
                                        !costPriceValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [inputStyle]="{
                                        width: '120px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!costPriceValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                Cost price cannot be less than zero (0)
                            </span>
                        </div>
                    </td>
                    <td>
                        <!-- {{ item.totalCost | number: "1.2-2" }} -->
                        <div class="flex justify-content-end">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.totalCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="11" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="9"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Products:
                    </td>
                    <td
                        style="text-align: center"
                        class="text-700 font-medium text-lg"
                    >
                        {{ calculateItemTotal() }}
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="11"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Product Cost:
                    </td>
                    <td style="text-align: right">
                        <p-inputNumber
                            [disabled]="true"
                            [(ngModel)]="productCost"
                            mode="currency"
                            currency="USD"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                            [inputStyle]="{
                                width: '130px',
                                'text-align': 'right'
                            }"
                        ></p-inputNumber>
                        <!-- {{ grandTotal - shippingCost | number: "1.2-2" }} -->
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="11"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Shipping Cost:
                    </td>
                    <td>
                        <p-inputNumber
                            [disabled]="!dataList.length || isViewOnly"
                            [(ngModel)]="shippingCost"
                            mode="currency"
                            currency="USD"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            (onInput)="shippingCostChanged($event)"
                            [maxFractionDigits]="2"
                            [inputStyle]="{
                                width: '130px',
                                'text-align': 'right'
                            }"
                        ></p-inputNumber>
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="11"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Cost:
                    </td>
                    <td>
                        <div>
                            <p-inputNumber
                                [disabled]="true"
                                [(ngModel)]="grandTotal"
                                mode="currency"
                                currency="USD"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                [disabled]="true"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'right'
                                }"
                            ></p-inputNumber>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <br />
    <!-- <p-divider type="dashed"></p-divider> -->
    <div class="grid" *ngIf="dataList.length > 0">
        <div class="field col">
            <label for="notes">Notes</label>
            <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                class="w-full"
                [(ngModel)]="notes"
                [disabled]="isViewOnly"
            ></textarea>
            <!-- <input id="firstname2" type="text" class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"> -->
        </div>
        <!-- <div class="col" style="margin-left: auto; margin-right: 0">
            <label for="firstname2">&nbsp;</label>
            <div class="grid">
                <div class="flex justify-content-end flex-wrap m-1">
                    <div
                        class="flex align-items-center justify-content-center m-1"
                    >
                        <label>Shipping</label>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center m-1"
                    >
                        <p-inputNumber
                            style="text-align: right"
                            [disabled]="!dataList.length || isViewOnly"
                            [(ngModel)]="shippingCost"
                            mode="decimal"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            (onInput)="shippingCostChanged($event)"
                            [maxFractionDigits]="2"
                        ></p-inputNumber>
                    </div>
                </div>
            </div>

            <div class="grid">
                <div class="flex justify-content-end flex-wrap m-1">
                    <div
                        class="flex align-items-center justify-content-center m-1"
                    >
                        <label>Total Cost</label>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center m-1"
                    >
                        <p-inputNumber
                            [disabled]="true"
                            [(ngModel)]="grandTotal"
                            mode="decimal"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                            [disabled]="isViewOnly"
                        ></p-inputNumber>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <br />
    <br />
    <div
        class="flex justify-content-end flex-wrap"
        *ngIf="activeItem.transfersOutStatus == 1 || isNew"
    >
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Save as Draft"
                (click)="saveTransferInventory(1)"
                class="p-button-info"
                [disabled]="!isOk2Save()"
            ></button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
            <p-button
                label="Post Transfer"
                (onClick)="saveTransferInventory(3)"
                [disabled]="!isOk2Save()"
            ></p-button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="!isNew"
        >
            <button
                pButton
                class="p-button-danger"
                label="Cancel Transfer"
                (click)="cancelTransfer()"
                [disabled]="!isOk2Save()"
            ></button>
        </div>
    </div>
</div>
