import { UserService } from 'src/app/_services/user.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  usTimeZones = usTimeZoneList
  selectedTimeZone: any = ''

  weightUnitList = [
    {
      id: 1,
      value: "Kg"
    },
    {
      id: 2,
      value: "Gram"
    },
  ]

  _globals = GlobalService

  constructor(
    private fb: FormBuilder,
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _userService: UserService,
    private _router: Router,
  ) { }

  ngOnInit(): void {

    if (this._globals.userInfo) {
      try {
        this.selectedTimeZone = this.usTimeZones.find(f => f.value == this._globals.userInfo.businessSetup[0].standardsForms.timeZone)
        this._businessSetup.activeItem.businessType = this._globals.userInfo.businessSetup[0].businessType
      }
      catch {
        this.selectedTimeZone = this.usTimeZones[2]
        this._businessSetup.activeItem.businessType = this._abd.businessTypes[0].id
      }
    }
    else {

      const user = JSON.parse(localStorage.getItem('user'))
      if (!user) {
        if (window.location.href.includes('/handheld'))
            this._router.navigate(['handheld/signin'])
          else
            this._router.navigate(['pages/signin'])
      } else {
        this._userService.getUserByUserName(this._userService.currentUserObj.userName).subscribe(res => {
          if (res.success) {

            try {
              const user = res.data
              this._globals.userInfo = user
              const xx = this._abd.businessTypes.find(f => f.id == this._globals.userInfo.businessSetup[0].businessType)
              this._businessSetup.initActiveItem()
              this.selectedTimeZone = this.usTimeZones.find(f => f.value == this._globals.userInfo.businessSetup[0].standardsForms.timeZone)
              this._businessSetup.activeItem.businessType = this._globals.userInfo.businessSetup[0].businessType

              if(!this.selectedTimeZone) {
                this.selectedTimeZone = this.usTimeZones[2]
              }
                
                this._businessSetup.selectedTimeZone = this.selectedTimeZone
              if(!this._businessSetup.activeItem.businessType) this._businessSetup.activeItem.businessType = this._abd.businessTypes[0].id
            } catch {
              this.selectedTimeZone = this.usTimeZones[2]
              this._businessSetup.activeItem.businessType = this._abd.businessTypes[0].id
            }
          }
        })
      }
      this.selectedTimeZone = this._businessSetup.selectedTimeZone
        this._businessSetup.selectedTimeZone = this.selectedTimeZone
    }



  }

  ngAfterViewInit() { }

  ngOnDestroy() { }

  selectedTimezoneChange() {
    try {

      this._businessSetup.selectedTimeZone = this.selectedTimeZone
    } catch {
    }
  }

  selectBusinessType(obj: any) {
    this._businessSetup.activeItem.businessType = obj.id
  }



}
