import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { CategoryService } from './category.service';
import { ConnMessageService } from './conn-message.service';
import { ManufacturersService } from './manufacturers.service';
import { VendorsService } from './vendors.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  // locations: any = null
  // departments: any = null
  // categories: any = null
  // vendors: any = null
  // manufacturers: any = null

  // selectedLocation: any = null
  // selectedDepartment: any = null
  // selectedCategory: any = null
  // selectedVendor: any = null
  // selectedManufacturer: any = null

  // selectedTransactionType: any = ''
  // selectedInvoiceStatus: any = ''
  // selectedInvoiceType: any = ''

  selectedInvoiceRow: any = ''
  selectedInvoiceDetails: any = ''
  invoiceOrderDetail:any=''
  invoiceCustomerDetails:any=''
  invoiceDocuments:any=''
  showTransferDetail: boolean = false;

  // singleDate: any = null
  // dateRange: any = null

  // searchText = ''

  // //-- Invoice related
  // transactionTypes = [
  //   {
  //     id: 0,
  //     transactionType: "All Types",
  //     selected: true,
  //     enumType: 0
  //   },
  //   {
  //     id: 1,
  //     transactionType: "Posted",
  //     selected: true,
  //     enumType: 1
  //   },
  //   {
  //     id: 2,
  //     transactionType: "Return",
  //     selected: true,
  //     enumType: 2
  //   },
  //   {
  //     id: 3,
  //     transactionType: "Void",
  //     selected: true,
  //     enumType: 3
  //   },
  // ]

  // invoiceStatus = [
  //   {
  //     id: 0,
  //     title: "All Types"
  //   },
  //   {
  //     id: 2,
  //     title: "Completed"
  //   },
  //   {
  //     id: 1,
  //     title: "Draft"
  //   },
  //   {
  //     id: 3,
  //     title: "Hold"
  //   },
  //   {
  //     id: 4,
  //     title: "Processing"
  //   },
  //   {
  //     id: 5,
  //     title: "Pending Payment"
  //   },
  //   {
  //     id: 6,
  //     title: "Cancelled"
  //   },
  //   {
  //     id: 7,
  //     title: "Refunded"
  //   },
  //   {
  //     id: 8,
  //     title: "Failed"
  //   }
  // ]

  // invoiceType = [
  //   {
  //     id: 0,
  //     title: "All Types",
  //   },
  //   {
  //     id: 1,
  //     title: "Sale",
  //   },
  //   {
  //     id: 2,
  //     title: "Refund",
  //   },
  //   {
  //     id: 3,
  //     title: "Void",
  //   },
  // ]

  // public static postedType = [
  //   {
  //     id: 0,
  //     title: "All Types",
  //   },
  //   {
  //     id: 1,
  //     title: "Draft",
  //   },
  //   {
  //     id: 2,
  //     title: "Posted",
  //   },
  // ]

  constructor(
    private _bs: BaseService,
    private _catService: CategoryService,
    private _vendors: VendorsService,
    private _manufacturers: ManufacturersService,
    private _connMessageService: ConnMessageService
  ) { }

  // // getAllLocations() {
  // //   if(!this.locations) {
  // //     let url = environment.BusinessBaseURL + environment.GetAllLocations
  // //     return this._bs.httpGet(url).pipe(map(res => {
  // //       this.locations=res
  // //       return res
  // //     }))
  // //   } else
  // //   return of(this.locations)
  // // }

  // getAllDepartments(params: any, auth: boolean = false, showSpinner: boolean = true) {
  //   let url = environment.InventoryBaseURL + environment.GetDepartmentList
  //   return this._bs.httpGetParams(url, params).pipe(map(res => {
  //     return res
  //   }))
  // }

  // getAllLocations(showSpinner: boolean = true) {
  //   let url = environment.BusinessBaseURL + environment.GetAllLocations
  //   return this._bs.httpGet(url, showSpinner).pipe(map(res => {
  //     return res
  //   }))
  // }

  UpdateInvoiceStatus(data: any) {
    let url = environment.OrderBaseURL +  "ChangeOrderStatus?notifyOnlineStore=true";
    return this._bs.httpPost(url, data).pipe(map(res => {
      return res;
    }))
  }
  searchInvoiceByID(orderNumber: string) {
    let url = environment.OrderBaseURL + environment.GetInvoiceByInvoiceNumber + "/" + orderNumber;
    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }

  // getAllCategories() {
  //   let params = new HttpParams();

  //   params = params.append('pageNo', 0);
  //   params = params.append('pageSize', 500);

  //   this._catService.getAllCategories(params).subscribe(res => {
  //     if (res.success) {
  //       this.categories = res.data
  //       this.categories.unshift({ id: '', categoryName: "All Categories" })
  //       this.selectedCategory = this.categories[0]
  //     }
  //   })
  // }

  // getAllVendors() {
  //   let params = new HttpParams();

  //   params = params.append('pageNo', 0);
  //   params = params.append('pageSize', 500);

  //   this._vendors.getAllVendors(params).subscribe(res => {
  //     if (res.success) {
  //       this.vendors = res.data
  //       this.vendors.unshift({ id: '', vendorName: "All Vendors" })
  //       this.selectedVendor = this.vendors[0]
  //     }
  //   })
  // }

  // getAllManufacturers() {
  //   let params = new HttpParams();
  //   params = params.append('pageNo', 0);
  //   params = params.append('pageSize', 500);

  //   this._manufacturers.getAllManufacturers(params).subscribe(res => {
  //     if (res.success) {
  //       this.manufacturers = res.data
  //       this.manufacturers.unshift({ id: '', manufacturerName: "All Manufacturers" })
  //       this.selectedManufacturer = this.manufacturers[0]
  //     }
  //   })
  // }



}
