import { DropDownModel } from "./drop-down-model";

export let RefundPolicyList: DropDownModel[] = [
    {
        view: 'No Refund',
        viewValue: 'No Refund'
    },
    {
        view: 'Refunds 30 Days',
        viewValue: 'Refunds 30 Days'
    },
    {
        view: 'Refunds 60 Days',
        viewValue: 'Refunds 60 Days'
    },
    {
        view: 'Refunds 90 Days',
        viewValue: 'Refunds 90 Days'
    },
]
