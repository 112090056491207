import { PurchaseService } from 'src/app/_services/purchase.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { VendorsService } from 'src/app/_services/vendors.service';
import { HttpParams } from '@angular/common/http';
import { Paginator } from 'primeng/paginator';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/_services/base.service';
import { formatDate } from '@angular/common';

export interface purchaseTypeInterface {
  id: number,
  title: string
}

export const purchaseFilter: Array<purchaseTypeInterface> = [
  {
    id: 0,
    title: "Purchase"
  },
]

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss']
})
export class PurchaseReportComponent implements OnInit {
  dataList: any = null

  page = 0
  rows = 10
  totalRecords = 0

  selectAll: false
  searchText = ''
  lastSearchText = ''

  startDate = new Date(new Date().setDate(new Date().getDate() - 7))
  endDate = new Date()
  vendors: any = ''
  selectedVendor: any = ''

  _globals = GlobalService

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    private _bs: BaseService,
    private _purchaseService: PurchaseService,
    private _vendors: VendorsService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Purchases'
    this.getAllVendors()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getAllVendors() {
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 500);

    this._vendors.getAllVendors(params).subscribe(res => {
      if (res.success) {
        this.vendors = res.data
        this.vendors.unshift({ id: '', vendorName: "All Vendors" })
        this.selectedVendor = this.vendors[0]
        this.getDataList()
      }
    })
  }

  makeParams() {
    let params = new HttpParams();

    params = params.append('vendorId', this.selectedVendor.id);
    this.page = this.page < 1 ? 1 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    let startDate: any = new Date()
    let endDate: any = new Date()
    try {
      if (this.startDate) startDate = formatDate(this.startDate, 'MM/dd/yyyy', 'en-US')
    }
    catch {
      startDate = new Date(new Date().setDate(new Date().getDate() - 7))
      startDate = formatDate(startDate, 'MM/dd/yyyy', 'en-US')
    }

    try {
      if (this.endDate) endDate = formatDate(this.endDate, 'MM/dd/yyyy', 'en-US')
     }
    catch { 
      endDate = new Date()
      endDate = formatDate(endDate, 'MM/dd/yyyy', 'en-US')
    }

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());
    if (this.searchText.length > 4) params = params.append('filterValue', this.searchText);

    return params
  }

  getDataList() {
    let url = environment.InventoryBaseURL + environment.GetPurchaseOrderItems;
    this._bs.httpGetParams(url, this.makeParams()).subscribe(res => {
      if (res.success) {
        
        this.totalRecords = res.totalRecords
        this.dataList = res.data
        //console.log('purchase-report', this.dataList);
      }
    })
  }

  clearSearchText() {
    this.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  filterButtonPressed() {
    this.page = 0
    this.paginator.changePage(0)
    this.getDataList()
  }

  searchTextSearch() {
    if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return

    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

}
