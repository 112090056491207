<div class="flex points-redemption-page flex-column p-fluid w-6">
    <div class="field">
        <label htmlfor="InputRewardDay">Reward Day & Time <span class="text-red-500">*</span> </label>
        <p-multiSelect [options]="daysList" [(ngModel)]="selectedDays" optionLabel="name"></p-multiSelect>

    </div>

   <!-- <div class="field  w-full align-content-center">
        <div class="flex align-content-center">
            <div class="col-12 md:col-4">

            </div>
            <div class="col-12 md:col-4 text-center justify-content-center">
                <label class="time-label">Start Time</label>
            </div>
            <div class="col-12 md:col-4 text-center justify-content-center">
                <label class="time-label">End Time </label>
            </div>
        </div>
        <div class="flex align-content-center ">
            <div class="col-12 md:col-4 flex align-items-center">
                <label>Sunday</label>
            </div>
            <div class="col-12 md:col-4">
                 <p-calendar [(ngModel)]="date8" [timeOnly]="true" [hourFormat]="12" inputId="timeonly"></p-calendar>
            </div>
            <div class="col-12 md:col-4">
                 <p-calendar [(ngModel)]="date8" [timeOnly]="true" [hourFormat]="12" inputId="timeonly"></p-calendar>
            </div>
        </div>
        <div class="flex align-content-center ">
            <div class="col-12 md:col-4 flex align-items-center">
                <label>Monday</label>
            </div>
            <div class="col-12 md:col-4">
                <p-calendar [(ngModel)]="date8" [timeOnly]="true" [hourFormat]="12" inputId="timeonly"></p-calendar>
            </div>
            <div class="col-12 md:col-4">
                <p-calendar [(ngModel)]="date8" [timeOnly]="true" [hourFormat]="12" inputId="timeonly"></p-calendar>
            </div>
        </div>
    </div> -->
    <div class="field flex w-full">
        <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-8 md:mb-0">
            Cashier Alert
        </label>
        <div class="col-12 md:col-4">
            <p-inputSwitch [(ngModel)]="InputCashierAlert" inputId="InputCashierAlert">
            </p-inputSwitch>
            <label class="control-label" for="InputCashierAlert"><strong> Enable</strong></label>
        </div>
    </div>
    <div class="card border-dotted-custom mb-5" [ngClass]="{'disabled-input': !InputCashierAlert}">
        <div class="flex" *ngFor="let alertOption of cashierAlerts">
            <div class="col-12 md:col-1">
                <p-checkbox
                    [ngClass]="{'disabled-input': !InputCashierAlert}"
                    [binary]="true"
                    [(ngModel)]="alertOption.active"></p-checkbox>
            </div>
            <label  class="col-12 mb-2 md:col-11 md:mb-0">
               {{alertOption.title}}
            </label>
        </div>

    </div>
</div>
