<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Invoice 
</h3>
<p-divider></p-divider>

<ng-container *ngFor="let inv of invoiceDetail; let i = index">
    <br />
    <div class="nas-grid-2x">
        <div class="nas-label">{{ inv.label }}
        </div>
        <div class="nas-value" *ngIf="inv.label.includes('Date')">
            {{ inv.value | date: "fullDate" }}
        </div>
        <div class="nas-value" *ngIf="!inv.label.includes('Date')">
           <span *ngIf="i != 4"> {{ inv.value }}</span>
           <span *ngIf="i == 4"> {{ inv.value | currency}}</span>
        </div>
    </div>
</ng-container>

<br />
<br />
<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Invoice Detail
</h3>
<p-divider></p-divider>
<br />
<br />
<p-table
    *ngIf="productsDetail?.length > 0"
    styleClass="p-datatable-striped"
    [autoLayout]="true"
    [tableStyle]="{ width: 'max-content' }"
    [value]="productsDetail"
    responsiveLayout="scroll"
    rowHover="true"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Sr#</th>
            <th>Description</th>
            <th style="text-align: right">Cost</th>
            <th style="text-align: right">Qty</th>
            <th style="text-align: right">Total</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{item.srNo}}</td>
            <td>{{getWordsWithSpace(item.description)}}</td>
            <td style="text-align: right">{{item.price}}</td>
            <td style="text-align: right">{{item.qty}}</td>
            <td style="text-align: right">{{item.subTotal}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="4" style="text-align: right">Sub-Total</td>
            <td  style="text-align: right">{{this._bizzCustomerService.activeItem.invoice.invoiceSubtotal | currency: 'USD'}}</td>
        </tr>
        <tr>
            <td colspan="4" style="text-align: right">Discount</td>
            <td  style="text-align: right">{{this._bizzCustomerService.activeItem.invoice.totalDiscountAmount | currency: 'USD'}}</td>
        </tr>
        <tr>
            <td colspan="4" style="text-align: right">Tax</td>
            <td  style="text-align: right">{{this._bizzCustomerService.activeItem.invoice.totalTaxAmount | currency: 'USD'}}</td>
        </tr>
        <tr>
            <td colspan="4" style="text-align: right">Total</td>
            <td  style="text-align: right">{{this._bizzCustomerService.activeItem.invoice.grandTotal | currency: 'USD'}}</td>
        </tr>
    </ng-template>
</p-table>
