import { CurrencyPipe, formatDate, PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gross-profit-report',
  templateUrl: './gross-profit-report.component.html',
  styleUrls: ['./gross-profit-report.component.scss']
})
export class GrossProfitReportComponent implements OnInit {

  _globals = GlobalService

  page = 0
  rows = 10
  first = 0
  totalRecords = 0

  //order
  orderParam = ''
  orderBy = ''

  colheader = [['Date', 'Sell', 'Location', 'Net Sales', 'COGs', 'Gross Profit', 'Profit Margin']];
  colflds = ['date', 'isOnline', 'locationName', 'netSales', 'netCost', 'grossProfit', 'grossMargin'];
  summaryHeader = [['Date', 'Sell', 'Location', 'Net Sales', 'COGs', 'Gross Profit', 'Profit Margin']];
  domSummaryflds = ['isOnline', 'locationName', 'netSales', 'netCost', 'grossProfit', 'grossMargin'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  deptIds: string[] = [];
  groupby: string = 'Location';

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Profit Report'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.searchText) this._filterService.searchText = filter.searchText

        const rr = this._abd.getProductSellTypeFilter(filter.selectedProductSellTypeFilter)
        if (filter.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = rr

        if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
        if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

        //if(filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }

      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let sel = this._filterService.selectedLocation;
    if(!sel || sel.length<1) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
      sel = this._filterService.selectedLocation;
    }
    for(let i=0; i<sel.length; i++) {
      this.locationIds.push(sel[i].locationId)
    }
  }

  getDepartmentList() {
    let self = this;
    this.deptIds = [];
    // let selDepartment = this._filterService.selectedDepartment;
    // // The below line of code verifies if the selDepartment is already an array or not. If not, it converts it to an array.
    // selDepartment = (typeof selDepartment != 'undefined' && selDepartment instanceof Array) ? selDepartment : [selDepartment];
    // selDepartment.map(function (value: { id: { toString: () => string; }; }) {
    //   self.deptIds.push(value.id.toString())
    // })
  }

  getCategoryList() {
    let self = this;
    this.catIds = [];
    // let selCategory = this._filterService.selectedCategory || this._abd.actCategories;
    // // The below line of code verifies if the selCategory is already an array or not. If not, it converts it to an array.
    // selCategory = (typeof selCategory != 'undefined' && selCategory instanceof Array) ? selCategory : [selCategory];
    // selCategory.map(function (value: { id: { toString: () => string; }; }) {
    //   self.catIds.push(value.id.toString())
    // })
  }

  getDataList() {
    this.getLocationsList();
    this.getDepartmentList();
    this.getCategoryList();

    let op=this._filterService.selectedProductSellTypeFilter?.Value
    if(!op) op='All'

    // console.log("category selected",this._abd.selectedCategory);
    // console.log("department selected",this._abd.selectedDepartment);
    const data = {
      "ReportName": 'GrossProfitReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        // "CategoriesIds": this.catIds,
        "DepartmentIds": this.deptIds,
        // "DiscountType": this.groupby,
        // 'filterValue': this._abd.searchText,
        'OrderPlacement': op
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if (x.isOnline) {
          x.isOnline = 'Online'
        } else if (!x.isOnline) {
          x.isOnline = 'In-Store'
        }
      })
      this._abd.hideSpinner();
    })
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Gross Profit Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Gross Profit Report', true)
  }
  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {

      let total = "0";
      if (col.includes("totalDiscount") || col.includes("totalReturned") || col.includes("netSales") || col.includes("netCost") || col.includes("grossProfit")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("grossMargin")) {
        try {
          let COGS = this.dataList.reduce((sum, item) => sum + (item["netCost"]), 0)
          let GrossProfit = this.dataList.reduce((sum, item) => sum + (item["netSales"] - item["netCost"]), 0)
          let netSales = this.dataList.reduce((sum, item) => sum + item["netSales"], 0)
          return this.percentPipe.transform(GrossProfit / (netSales > 0 ? netSales : 100), '.2')

        }
        catch (e) {
          alert(e);
          return 0.0;
        }


      }
      else if (col.includes("soldQty") || col.includes("returnedQty")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
