<div class="flex justify-content-center flex-wrap card-container yellow-container">

    <div class="jumbotron text-center" style="margin-top: 50px;
    margin-bottom: 50px;">
        <h1 class="display-3">Thank You!</h1>
        <p class="lead"><strong>Setup is done</strong> please click finish to redirect to Dashboard</p>
        <hr>
       
      
    </div>
</div>
