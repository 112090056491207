<div class="conn-card">
    <app-report-top-filter-bar *ngIf="1==1"
        [paraReportName]="'tip-summary-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"   responsiveLayout="scroll" [autoLayout]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="date" ><strong>Date</strong> <p-sortIcon field="date"></p-sortIcon></th>
                <th pSortableColumn="locationName" ><strong>Location</strong> <p-sortIcon field="locationName"></p-sortIcon></th>
                <th pSortableColumn="registerId" ><strong>Register</strong> <p-sortIcon field="registerId"></p-sortIcon></th>
                <th pSortableColumn="cashierId" ><strong>Cashier</strong> <p-sortIcon field="cashierId"></p-sortIcon></th>
                <th pSortableColumn="tipCollected" ><strong>Tip Collected</strong> <p-sortIcon field="tipCollected"></p-sortIcon></th>
                <th pSortableColumn="totalSale" ><strong>Total Sale</strong> <p-sortIcon field="totalSale"></p-sortIcon></th>
                  </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-expanded="expanded">
            <tr>
                <td>{{product.date | date:'MM/dd/yyyy'}}</td>
                <td>{{ product.locationName }}</td>
                <td>{{ product.registerName}}</td>
                <td>
                    {{ product.cashierName }}
                </td>
                <!-- <td>
                    {{ getCashierName(product.cashierTd) }}
               </td> -->
                <td>
                    {{ product.tipCollected | currency }}
                </td>
                <td>
                    {{ product.totalSale | currency }}
                </td>
            
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td *ngFor="let col of domSummaryflds" style="text-align: left">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>

    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>

</div>
