<!-- <h3 style="margin: 15px; color: var(--bluegray-400); font-weight: 700">
    Add New Discount Coupon <span style="color: red"></span>
</h3> -->

<!--
    1. input is missing in app-text-input tags
    2. ngSubmit is missing in form tag
 -->

<form
    [formGroup]="editform"
    autocomplete="off"
    (ngSubmit)="editform.valid && saveFn()"
>
    <div class="conn-card">
        <div class="conn-grid-2x">
            <div>
                <label class="control-label">
                    <strong>
                        Discount Name
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['discountName']"
                    [name]="'Discount Name'"
                    [label]="'Discount Name'"
                >
                </app-text-input>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        How to Apply Coupon
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['applyBy']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="ApplyByList"
                    optionLabel="viewValue"
                    placeholder=" "
                >
                </p-dropdown>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Customer Type
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['customerType']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="customerTypeList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Discount Type
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['discountValueType']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="discountValueTypeList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Discount Value
                        <span *ngIf="f.discountValueType.value.id == '1'">
                            (%)
                        </span>
                        <span *ngIf="f.discountValueType.value.id == '2'">
                        </span>
                        <span *ngIf="f.discountValueType.value.id == '0'">
                            ($)
                        </span>

                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['discountValue']"
                    [name]="'Discount Value'"
                    [label]="'Discount Value'"
                ></app-text-input>
                <div
                    *ngIf="f.discountValue.touched && f.discountValue.invalid"
                    class="invalid-feedback"
                >
                    <div *ngIf="f.discountValue.errors.pattern">
                        Value should be number
                    </div>
                    <div *ngIf="f.discountValue.errors.min">
                        Minumum discount cannot be zero
                    </div>
                </div>
            </div>

            <div *ngIf="f.discountValueType.value.id == 1">
                <label class="control-label">
                    <strong>
                        Maximum Discount Allowed ($)
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['maximumAllowed']"
                    [name]="'Maximum Discount'"
                    [label]="'Maximum Discount'"
                ></app-text-input>
            </div>

            <!-- <div>
            <label class="control-label">
                <strong>
                    Limit Discount
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <p-dropdown [formControl]="$any(editform.controls)['limitDiscount']" [style]="{


                            }" class="p-dropdown-1" [options]="limitDiscountList" optionLabel="viewValue"
                placeholder=" "></p-dropdown><br />
        </div> -->
            <div>
                <label class="control-label">
                    <strong>
                        Max Coupon Use Count
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['maxCouponUse']"
                    [name]="'Max Coupon Use'"
                    [label]="'Max Coupon Use'"
                ></app-text-input
                ><br />
            </div>

            <div *ngIf="f.applyBy.value.id != 0">
                <label class="control-label">
                    <strong>
                        Max Coupon Code Use Limit
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="
                        $any(editform.controls)['maxCouponCodeUseLimit']
                    "
                    [name]="'Max Coupon Code Use Limit'"
                    [label]="'Max Coupon Code Use Limit'"
                ></app-text-input>
                <div
                    *ngIf="
                        f.maxCouponCodeUseLimit.touched &&
                        f.maxCouponCodeUseLimit.invalid
                    "
                    class="invalid-feedback"
                >
                    <div *ngIf="f.maxCouponCodeUseLimit.errors.pattern">
                        Value should be number, must be greater then 0.01
                    </div>
                </div>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Limit Use
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['ifOneTimeUse']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="ifOneTimeUseList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown
                ><br />
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Validity
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['validity']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="validityList"
                    optionLabel="viewValue"
                    placeholder=" "
                >
                </p-dropdown>
            </div>

            <div *ngIf="f.validity.value.id == 1">
                <label class="control-label">
                    <strong>
                        Validity dates
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-calendar
                    [formControl]="$any(editform.controls)['validityDate']"
                    selectionMode="range"
                    [style]="{}"
                    [readonlyInput]="false"
                    inputId="multiple"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                >
                </p-calendar>
            </div>

            <div>
                <label class="control-label">
                    <strong>
                        Allowed days
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-multiSelect
                    [formControl]="$any(editform.controls)['allowedDaysList']"
                    [style]="{}"
                    [options]="allowedDaysList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-multiSelect>
            </div>

            <!-- <div >
            <label class="control-label">
                <strong>
                    Minimum Items in cart
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <app-text-input [formControl]="$any(editform.controls)['minimumItemsInCart']"
                [name]="'Minimum Items in cart'" [label]=""></app-text-input>
        </div> -->

            <!--
        <div >
            <label class="control-label">
                <strong>
                    Maximum Items in cart
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <app-text-input [formControl]="$any(editform.controls)['maximumItemsInCart']"
                [name]="'Maximum Items in cart'" [label]=""></app-text-input>
        </div> -->

            <div>
                <label class="control-label">
                    <strong>
                        Minimum cart value $
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="
                        $any(editform.controls)['minimumCartValueInDollar']
                    "
                    [name]="'Minimum cart value '"
                    [label]="'Minimum cart value '"
                ></app-text-input>
            </div>

            <!-- <div >
            <label class="control-label">
                <strong>
                    Maximum Items value
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <app-text-input [formControl]="$any(editform.controls)['maximumItemsValueInDollar']"
                [name]="'Maximum Items value'" [label]=""></app-text-input>
        </div> -->

            <div style="display: none">
                <label class="control-label">
                    <strong>
                        Override all other discounts
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="
                        $any(editform.controls)['overrideAllOtherDiscounts']
                    "
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="OverrideAllOtherDiscountsList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown>
            </div>
            <div>
                <label class="control-label">
                    <strong>
                        Allowed Channels
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['allowedChannels']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="AllowedChannelsList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown>
            </div>

            <div *ngIf="_abd.actLocations.length > 1">
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Allowed Location
                        <span style="color: red; font-size: 1.25rem"> *</span>
                    </strong></label
                >
                <p-multiSelect
                    [options]="_abd.locationsBS$ | async"
                    [formControl]="
                        $any(editform.controls)['allowedLocationsList']
                    "
                    optionLabel="locationName"
                    display="chip"
                    appendTo="body"
                    [style]="{ width: '100%', 'margin-top': '10px' }"
                ></p-multiSelect>
            </div>
            <div>
                <label class="control-label">
                    <strong>
                        Notify
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['notify']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="NotifyList"
                    optionLabel="viewValue"
                    placeholder=" "
                >
                </p-dropdown>
            </div>

            <div *ngIf="f.notify.value.id == 1">
                <label class="control-label">
                    <strong>
                        Print Text in Footer
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="$any(editform.controls)['printTextInFooter']"
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="PrintTextInFooterList"
                    optionLabel="viewValue"
                    placeholder=" "
                ></p-dropdown>
            </div>

            <div *ngIf="f.printTextInFooter.value.id == 1">
                <label class="control-label">
                    <strong>
                        Print Text in Footer Content
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['receiptFooterText']"
                    [name]="'Footer Text'"
                    [label]="'Footer Text'"
                ></app-text-input>
            </div>

            <div>
                <label class="control-label">
                    <strong title="Print coupon after each invoice">
                        Print Coupon at Register
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong>
                </label>
                <br />
                <p-dropdown
                    [formControl]="
                        $any(editform.controls)['printCouponAtRegister']
                    "
                    [style]="{}"
                    class="p-dropdown-1"
                    [options]="PrintCouponList"
                    optionLabel="viewValue"
                    placeholder=" "
                >
                </p-dropdown>
            </div>

            <!-- <div *ngIf = "f.printCouponAtRegister.value.id == 1">
            <label class="control-label">
                <strong>
                    Print Coupon Text
                    <span style="color: red; font-size: 1.25rem"></span>
                </strong>
            </label>
            <br />
            <p-dropdown [formControl]="$any(editform.controls)['printCouponText']" [style]="{


                            }" class="p-dropdown-1" [options]="PrintCouponTextList" optionLabel="viewValue" placeholder=" ">
            </p-dropdown>
        </div> -->

            <div *ngIf="f.printCouponAtRegister.value.id == 1">
                <label class="control-label">
                    <strong>
                        Coupon Text
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <app-text-input
                    [formControl]="$any(editform.controls)['printText']"
                    [name]="'Coupon Text'"
                    [label]="'Coupon Text'"
                >
                </app-text-input>
            </div>

            <!-- <div>
            <label class="control-label">
                <strong>
                    Print Image
                    <span style="color: red; font-size: 1.25rem"></span>
                </strong>
            </label>
            <br />
            <p-dropdown [formControl]="$any(editform.controls)['printImage']" [style]="{


                            }" class="p-dropdown-1" [options]="PrintImageList" optionLabel="viewValue" placeholder=" ">
            </p-dropdown>
        </div> -->

            <div *ngIf="f.printCouponAtRegister.value.id == 1">
                <!-- <div > -->
                <div
                    class="uploadbox"
                    style="
                        min-height: auto;
                        padding: 1.5rem 1.75rem;
                        text-align: center;
                        cursor: pointer;
                        border: 1px dashed var(--brandColor);
                        border-radius: 0.475rem !important;
                        background: #edf1f5;
                    "
                >
                    <div class="grid">
                        <div class="col-2 upload-btn-div">
                            <label
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Add/Change image"
                            >
                                Click to upload Coupon Image
                                <br />
                                <br />
                                <i
                                    class="pi pi-cloud-upload"
                                    style="
                                        color: var(--bs-primary);
                                        font-size: 3rem;
                                    "
                                ></i>
                                <input
                                    id="id-input-image"
                                    type="file"
                                    name="avatar"
                                    accept=".png, .jpg, .jpeg"
                                    (click)="$event.target.value = null"
                                    (change)="handleFileInputMultiple($event)"
                                    [hidden]="true"
                                />
                                <input type="hidden" name="avatar_remove" />
                            </label>
                        </div>
                        <div class="col">
                            <ng-container>
                                <div class="grid">
                                    <img
                                        [src]="
                                            _discountsService.activeItem
                                                ?.printImage
                                        "
                                        *ngIf="
                                            _discountsService.activeItem
                                                ?.printImage && !fileToUpload
                                        "
                                        style="width: 80%"
                                    />
                                    <div *ngIf="fileToUpload">
                                        <div
                                            *ngFor="
                                                let image of _discountsService.thImages;
                                                let i = index
                                            "
                                            class="img-container conn-card"
                                        >
                                            <a
                                                class="rm-btn"
                                                pTooltip="Remove Image"
                                                (click)="
                                                    onRemoveClick($event, i)
                                                "
                                                ><i
                                                    class="pi pi-minus-circle"
                                                ></i
                                            ></a>
                                            <p-image
                                                [src]="image"
                                                preview="true"
                                                alt="Image"
                                                width="150px"
                                            ></p-image>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.printCouponAtRegister.value.id == 1">
                <label class="control-label">
                    <strong>
                        Printing Validity dates
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong>
                </label>
                <br />
                <p-calendar
                    [formControl]="$any(editform.controls)['printValidityDate']"
                    selectionMode="range"
                    [style]="{}"
                    [readonlyInput]="false"
                    inputId="multiple"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                >
                </p-calendar>
            </div>
        </div>
        <div>
            <label class="control-label">
                <strong>
                    Coupon Codes (Comma seperated values)
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <!-- <p-chips [formControl]="$any(editform.controls)['couponCodesList']" separator="," allowDuplicate=false
            addOnBlur="true" (onAdd)="validateKey($event)"></p-chips> -->
            <p-chips
                type="text"
                [formControl]="$any(editform.controls)['couponCodesList']"
                separator=","
                addOnBlur="true"
                (onAdd)="validateKey($event)"
                [allowDuplicate]="false"
                [ngClass]="{'ng-dirty ng-invalid': f.couponCodesList.touched && $any(editform.controls)['couponCodesList'].invalid }"
                ></p-chips>
                <span  class="coupon-info-message">
           You can only use more then 4 alphanumeric characters to create a coupon code.
            <!-- for example: ABCDEF,ABC123, 123ABD, 12345. -->
                </span>

            <!-- <app-text-input [formControl]="$any(editform.controls)['couponCodesList']" [name]="'Coupon Codes'"
            [label]=""></app-text-input> -->
            <div
            *ngIf="f.couponCodesList.touched && f.couponCodesList.invalid"
                class="invalid-feedback"
            >
       
                <div *ngIf="f.allowedChannels.value.viewValue != 'Online'">
                    <div *ngIf="f.couponCodesList.errors?.maxlength">
                        Maximum 15 coupon codes can be added when Allowed
                        Channel is set to In-Store
                    </div>
                </div>
                <div *ngIf="f.allowedChannels.value.viewValue == 'Online'">
                    <div *ngIf="f.couponCodesList.errors?.maxlength">
                        Maximum 1 coupon code when Allowed Channel is set to
                        Online
                    </div>
                </div>
                <!-- <div *ngIf="f.couponCodesList.hasError('invalidName')">
                    Cannot have special characters for coupon code
                </div> -->
            </div>
        </div>
    </div>

    <br />
    <p-divider style="margin-top: 10px"></p-divider>
    <div class="grid">
        <div class="col">
            <div
                class="flex justify-content-end flex-wrap card-container green-container mt-3"
            >
                <div class="flex align-items-center justify-content-center">
                    <button
                        pButton
                        pRipple
                        type="submit"
                        label="Save"
                        [disabled]="!editform.valid"
                        *ngIf="!isViewOnly"
                        [style]="{ width: '120px' }"
                    ></button>
                </div>
                <div
                    class="flex align-items-center justify-content-center ml-2"
                    *ngIf="singupBusinessConfig == true"
                >
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        class="p-button-outlined p-button-danger"
                        (click)="cancelButtonClick()"
                        [style]="{ width: '120px' }"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</form>


