import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() name: string;
  @Input() mode: string = 'decimal'
  @Input() description = '';
  @Input() minFractionDigits: number;
  @Input() maxFractionDigits: number;
  @Input() step: number;
  @Input() max: number;

  constructor(@Self() public ngControl: NgControl) { this.ngControl.valueAccessor = this }
 
  writeValue(obj: any): void {
    // throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }



}
