import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    selector: 'app-signup-hardware',
    templateUrl: './signup-hardware.component.html',
    styleUrls: ['./signup-hardware.component.scss']
})
export class SignupHardwareComponent implements OnInit {
    _global = GlobalService
    @Input() showFooter: boolean = true
    @Output() backButtonClick = new EventEmitter();
    constructor(
        public _signup: SignupService,
    ) { }

    ngOnInit(): void {
    }

    selectHaveHardware(hw) {
        this._signup.selectedHaveHardwareId = hw.id
    }

    backClicked() {
        const obj = this._signup.customAppBaseData.find(f => f.id == this._signup.customAppBaseDataId)
        if (!obj) return
        if (obj.answer == 'yes')
            this._signup.currentStep--
        else this._signup.currentStep -= 2
        this.backButtonClick.emit(true);
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
    }

    nextClicked() {
        // debugger
        // if (this._signup.selectedHaveHardwareId.toString() == "0" && this._signup.selectedDoProcessOnlinePaymentsId.toString() == "1"){
        //     this._signup.currentStep = 8
        //     this._signup.savedSteps = this._signup.currentStep
        // }
        // else{



        //  }

        this._signup.currentStep++
        this._signup.savedSteps++;

        sessionStorage.setItem('selectedHaveHardwareId', this._signup.selectedHaveHardwareId.toString());
        sessionStorage.setItem('selectedDoProcessOnlinePaymentsId', this._signup.selectedDoProcessOnlinePaymentsId.toString());
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());

        if (this._signup.selectedHaveHardwareId.toString() == "1") {
            this._signup.selectedDoProcessOnlinePaymentsId = 1
            sessionStorage.setItem('selectedDoProcessOnlinePaymentsId', this._signup.selectedDoProcessOnlinePaymentsId.toString());
        }


    }



}
