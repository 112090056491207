<div *ngIf="token">
    <form #myFormPost name="myFormPost" [action]="paymentFormURL" method="POST" target="paytm">
        <input type="hidden" #poptoken id="popupToken" name="token" value="{{token}}" />
    </form>
    <iframe name="paytm" width="100%" height="1000px" frameBorder="1" [src]="paymentFormURL"></iframe>
</div>

<div *ngIf="!token">
    <p-progressBar mode="indeterminate"></p-progressBar>
</div>
