import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';

@Component({
  selector: 'app-configuration-button-partial',
  templateUrl: './configuration-button-partial.component.html',
  styleUrls: ['./configuration-button-partial.component.scss']
})
export class ConfigurationButtonPartialComponent implements OnInit {

  @Input() partialCSSid: any = '';
  @Input() partialHeader: any = '';
  @Input() additionalCSSinformaiton: any = '';
    @Input() isSaved: boolean = false;
    widgetSavedCC: string = '';
    @Output() widgetSavedContainer = new EventEmitter();
  constructor(
    public _businessSetupService: BusinessSetupService
  ) { }

  ngOnInit(): void {

    if(this.isSaved){
        this.widgetSavedCC = 'child-saved';
    }
  }

    widgetSaved(){

        this.widgetSavedContainer.emit(true)
    }

}
