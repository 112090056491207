import { formatDate } from '@angular/common';
import { Component, Input, OnInit, AfterContentInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-till-report',
  templateUrl: './till-report.component.html',
  styleUrls: ['./till-report.component.scss']
})
export class TillReportComponent implements OnInit,AfterContentInit {
  currentTills: any = null
  tillHistory: any = null

  tillsSum=0
  detailSum=0
  negativeSum: number =0;

  _globals = GlobalService
  @Input() showFilter: boolean = true
  @Input() filterDate: Date = new Date();
  @Input() filterLocation: string = "";;

  constructor(private _spinner: NgxSpinnerService, public _abd: AppBaseDataService, private _bs: BaseService, public _filterService: FilterServiceService,) {


   }
    ngAfterContentInit(): void {
      //  this._globals.componentName = 'Till Summary Report'
    }

  ngOnInit(): void {
    if(this.showFilter){
        this._globals.componentName = 'Till Summary Report'
    }
    this.getDataList()
  }

  getDataList() {
    const url = environment.InventoryBaseURL + environment.Report
    try {
      this._spinner.show()
      this.tillsSum=0
      this.detailSum=0
      this.negativeSum = 0;

      this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
        if (res.success) {
          this.currentTills = res.data.currentTill
          this.tillHistory = res.data.tillHistory

          for(let t of this.currentTills) {
            this.tillsSum+=t.totalTillAmount


          }

          for(let t of this.tillHistory) {
           // this.detailSum+=t.amount
            if(t.removedAmount < 0){
                this.negativeSum  +=t.removedAmount
            }

            if(t.addedAmount > 0){
                this.detailSum +=t.addedAmount
            }
          }
        }
        this._spinner.hide()
      })
    }
    catch (error) {
      alert(error)
      this._spinner.hide()
    }
  }

  getParas() {
    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
    }

    if (!this._filterService.dateRange || this._filterService.dateRange?.length < 1) {
      this._filterService.initDates()
    }

    const data = {
      "ReportName": "TillReport",
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.getLocationsList(),
        "CashierIds": this.getCashierList(),
        "filterValue": this._filterService.searchText,
        "NotCurrent": (this.showFilter ? false : true)
      }
    }
    if(!this.showFilter){
        data.Parameter.StartDate = formatDate(this.filterDate, 'MM/dd/yyyy', 'en-US'),
        data.Parameter.EndDate = formatDate(this.filterDate, 'MM/dd/yyyy', 'en-US')
    }
    return data
  }

  getLocationsList() {
    let locationIds = [];
    // locationIds.push(this._abd.locations[0].locationId) // to be deleted later
    let sel = this._filterService.selectedLocation
    if (!sel) return locationIds

    sel = (typeof sel != 'undefined' && sel instanceof Array) ? sel : [sel];
    sel.map(function (value: { locationId: { toString: () => string; }; }) {
      locationIds.push(value.locationId.toString())
    })
    if(!this.showFilter){
        locationIds = [];
        locationIds.push(this.filterLocation)
    }
    return locationIds
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  exportExcel() { }

  exportPdf() { }

}
