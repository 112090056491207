<div class="flex align-items-center flex-grow-1 py-3 my-2 " *ngFor="let item of stepsArray; let i = index"
    [ngClass]="{'step-enabled':(item.informationSaved || item.isEnabled)
    ,'step-disabled':!item.informationSaved &&
    !item.currentStep , 'd-none':!item.showStepForFreeTrial, 'my-2': i > 1,
    'singup-step-wizard-free': _signup.freeTrialAccount,
    'singup-step-wizard': !_signup.freeTrialAccount,
    'singup-step-wizard-resubscribe': resubscribe
}"
>
    <div class="flex flex-row flex-wrap gap-1"
     *ngIf="item.showStepForFreeTrial">
        <div
            class="flex align-items-center justify-content-center z-4 font-bold text-xl
            border-round"
            style="width: 3rem; height: 3rem"
            [ngClass]="{
                'enabled-step-number':!item.currentStep ,
                'disabled-step-number': (_signup.savedSteps < item.stepNumber) && !item.currentStep,
                'cursor-disabled': (_signup.savedSteps < item.stepNumber) && !item.currentStep,
                'active-step':item.currentStep
            }"
        >
         <!-- 'enabled-step-number': item.isEnabled,
         'disabled-step-number':!item.isEnabled, -->
        <span *ngIf=" item.informationSaved && (item.currentStep != _signup.currentStep)">
            <i class="pi pi-check" style="font-size: 1.2em; font-weight: 700;"></i></span>
        <span *ngIf=" item.informationSaved && (item.currentStep == _signup.currentStep)">
            <i class="pi pi-check saved-number"></i></span>
        <span  *ngIf="!item.informationSaved"
        >{{item.stepNumber}}</span>
        </div>
    </div>
    <div class="flex flex-row flex-wrap pl-4 " *ngIf="item.showStepForFreeTrial"
        (click)="changeStep(item.id)" [ngClass]="{
        'cursor-disabled' : (_signup.savedSteps < item.stepNumber) && !item.currentStep}">
        <div class="text-xl"
            [ngClass]="{'enabled-step-title': item.currentStep,'disabled-step-title': !item.currentStep}"
            [class]="getTitleClass(item.id)">
            {{item.title}}
        </div>
        <div class="flex align-items-center step-description w-full text-left text-500 mt-1 mb-0 white-space-normal ">
            {{item.detail}}
        </div>
    </div>
</div>
