import { SignupService } from 'src/app/_services/signup.service';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-signup-layout',
    templateUrl: './signup-layout.component.html',
    styleUrls: ['./signup-layout.component.scss'],
})
export class SignupLayoutComponent implements OnInit, AfterViewInit {
    optionsList: any = [];
    showSpinner: boolean = true;
    _globals = GlobalService;
    currentIndex: string;
    showSelectedPlan: string;
    freeAccountChecked: boolean = false;
    displayConfirmContinueDialog = false;
    currentSubscriptionPlan: string;
    trialDate: Date = new Date();

    constructor(
        // private _appMain: AppMainComponent,
        public _signup: SignupService,
        private activeRouter: ActivatedRoute,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _cd: ChangeDetectorRef
    ) {}
    ngAfterViewInit(): void {}

    ngOnInit(): void {
        // this.showSelectedPlan = sessionStorage.getItem('show-selected-plan');
        // let selectplan = sessionStorage.getItem('selectedPlan');
        // let currentplan = sessionStorage.getItem('currentPlan');
        // if (selectplan !== '' && selectplan !== undefined) {
        //     this.selectedPlan = JSON.parse(selectplan);
        // }
        // if (currentplan !== '' && currentplan !== undefined) {
        //     this.currentSubscriptionPlan = JSON.parse(currentplan);
        // }
      
        document.body.style.background = '#f2f8fc';
        this.getIndustries();
        this.getEcomSubscriptionPlans();
        this.getHardwarePlanList();
        this.getCustomerAppSubscriptionPlans();
        // this._appMain.toggleMenuDummy()

        var now = new Date();
        this.trialDate = new Date(now.setDate(now.getDate() + 30));

        let fromBusinessWebsite =
            this.activeRouter.snapshot.queryParamMap
                .get('platform')
                ?.toString() ?? '';

        if (fromBusinessWebsite != '') {
            this.getSubscriptionPlansLoad();
            // this._signup.selectedPlan = this._signup.subscriptionPlans.find(f => f.planId == fromBusinessWebsite)
            //  sessionStorage.setItem('selected-from-business-website', "1")
            // this.planSelectedFromBusinessWebsite();
        }

        if (
            this._router.url == '/setup/signup' ||
            this._router.url.includes('/setup/signup')
        ) {
            let isAnyUserInfoSaved: any = localStorage.getItem('user');
            if (
                isAnyUserInfoSaved != undefined &&
                isAnyUserInfoSaved != '' &&
                !this._signup.isLoginWithSubDashboard()
            ) {
                sessionStorage.setItem('sub-from-dashboard', '1');

                if (this._globals.userInfo != undefined) {
                    this._router.navigateByUrl('app/subscription-management');
                }
                /* let accountVerified: any = sessionStorage.getItem('selectedPlan');
                if (accountVerified == undefined) {
                    let fromBusinessWebsite = (this.activeRouter.snapshot.queryParamMap.get('selected_plan')?.toString() ?? "")

                    if (fromBusinessWebsite != "") {

                        sessionStorage.setItem('selected-from-business-website', "1")
                   }
                }
                */
            }

            if (
                isAnyUserInfoSaved != undefined &&
                isAnyUserInfoSaved != '' &&
                !this._signup.isLoginWithSubDashboard()
            ) {
                localStorage.removeItem('user');
                localStorage.removeItem('lastLoginDT');
            }
        }
    }
    checkPlan() {}
    backClicked(value: string) {
        if (value === 'true') {
            this._router.navigateByUrl('setup/subscription-plans');
        }
        if (value === 'false') {
            this._router.navigateByUrl('app/subscription-management');
            this._signup.showPlanSelectionModal = true;
        }
    }
    getSubscriptionPlansLoad() {
        try {
            this._spinner.show();
            this._signup.getSubscriptionPlans().subscribe((res) => {
                if (res.success) {
                    this._spinner.hide();
                    this._signup.subscriptionPlans = res.data;
                    this._signup.subscriptionPlans.map(
                        (f) => (f.showPlan = true)
                    );

                    let obj = this._signup.subscriptionPlans;
                    this._signup.subscriptionPlans.map(
                        (f) => (f.showPlan = true)
                    );
                    for (let i = 0; i < obj.length; i++) {
                        obj[i].locationAmount = 0;
                        obj[i].registerAmount = 0;
                    }

                    let fromBusinessWebsite =
                        this.activeRouter.snapshot.queryParamMap
                            .get('platform')
                            ?.toString() ?? '';

                    if (fromBusinessWebsite != '') {
                        const firstTimeLoad = sessionStorage.getItem(
                            'selected-from-business-website'
                        );
                        if (
                            firstTimeLoad == undefined &&
                            !this._signup.isLoginWithSubDashboard()
                        ) {
                            localStorage.removeItem('lastLoginDT');
                            localStorage.removeItem('user');
                        }

                        this._signup.currentStep = 1;
                        let duration =
                            this.activeRouter.snapshot.queryParamMap
                                .get('duration')
                                ?.toString() ?? '';
                        let selectedPlanTemp =
                            this.activeRouter.snapshot.queryParamMap
                                .get('selected_plan')
                                ?.toString() ?? '';
                        this._signup.selectedPlan =
                            this._signup.subscriptionPlans.find(
                                (f) => f.planId == selectedPlanTemp
                            );
                        sessionStorage.setItem(
                            'selected-from-business-website',
                            '1'
                        );

                        this._signup.selectedPlanTimeframe =
                            duration == 'm'
                                ? 'BilledMonthly'
                                : 'BilledAnnually';

                        this._signup.outerSelectedPlanTime =
                            this._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                                ? 'Monthly'
                                : 'Annually (Saves upto 30%)';

                        this._signup.outerLocationQty = 1;
                        this._signup.checkoutPageCalcVars.locationsQty = 1;

                        this._signup.outerRegisterQty = 1;
                        this._signup.checkoutPageCalcVars.registersQty = 1;
                        let self = this;
                        if (this._signup.selectedPlan.isTrial) {
                            sessionStorage.setItem('currentStep', '1');

                            this._signup.steps.map(function (v) {
                                if (
                                    self._signup.selectedPlan.isTria &&
                                    v.stepNumber > 3
                                ) {
                                    v.showStepForFreeTrial = false;
                                }
                            });
                            const accountVerified =
                                sessionStorage.getItem('accountVerified');
                            if (accountVerified != undefined) {
                                this._signup.currentStep = 3;
                                this._signup.accountVerified = true;
                                this.displayConfirmContinueDialog = true;
                                sessionStorage.setItem('currentStep', '3');
                            }

                            this.trialAccountSet();
                        } else {
                            if (this._signup.isLoginWithSubDashboard()) {
                                this._signup.currentStep = 4;
                                sessionStorage.setItem(
                                    'selectedPlan',
                                    JSON.stringify(this._signup.selectedPlan)
                                );
                                sessionStorage.setItem(
                                    'selectedPlanTimeframe',
                                    duration == 'm'
                                        ? 'BilledMonthly'
                                        : 'BilledAnnually'
                                );
                                sessionStorage.setItem(
                                    'free-trial-account',
                                    '0'
                                );
                                sessionStorage.setItem('accountVerified', '1');
                            } else {
                                sessionStorage.setItem('currentStep', '1');
                                sessionStorage.setItem(
                                    'selectedPlan',
                                    JSON.stringify(this._signup.selectedPlan)
                                );
                                sessionStorage.setItem('locationsQty', '1');
                                sessionStorage.setItem('registersQty', '1');
                                sessionStorage.setItem(
                                    'selectedPlanTimeframe',
                                    duration == 'm'
                                        ? 'BilledMonthly'
                                        : 'BilledAnnually'
                                );
                                sessionStorage.setItem(
                                    'free-trial-account',
                                    '0'
                                );
                                this._signup.freeTrialAccount = false;
                                this.sessionStorageTime();
                            }
                        }
                    }

                    let fromBusinessWebsiteq =
                        this.activeRouter.snapshot.queryParamMap
                            .get('selected_plan')
                            ?.toString() ?? '';
                    //   this.getSubscriptionPlansLoad();
                    if (fromBusinessWebsiteq != '') {
                        this._signup.selectedPlan =
                            this._signup.subscriptionPlans.find(
                                (f) => f.planId == fromBusinessWebsiteq
                            );
                        sessionStorage.setItem(
                            'selectedPlan',
                            JSON.stringify(this._signup.selectedPlan)
                        );
                    }

                    // console.log("getSubscriptionPlansLoad ", this._signup.currentStep)
                }
            });
        } catch {
            this._spinner.hide();
        }
    }

    trialAccountSet() {
        if (
            this._router.url == '/setup/signup' ||
            this._router.url.includes('/setup/signup')
        ) {
            if (this._globals.userInfo != undefined) {
                this._signup.currentStep = 3;
                this._signup.savedSteps = 3;
            } else {
                this._signup.currentStep = 1;
                this._signup.savedSteps = 1;
            }
        }
        // console.log("trialAccountSet ", this._signup.currentStep)
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());

        this._signup.selectedPlan = this._signup.subscriptionPlans.filter(
            (f) => f.isTrial
        )[0];
        sessionStorage.setItem(
            'selectedPlan',
            JSON.stringify(this._signup.selectedPlan)
        );
        sessionStorage.setItem('free-trial-account', '1');
        this._signup.freeTrialAccount = true;
        this.freeAccountChecked = true;
        this.sessionStorageTime();
    }

    sessionStorageTime() {
        const isSignupLSInfoSaved = sessionStorage.getItem(
            'signup-info-temp-hours'
        );
        if (isSignupLSInfoSaved == undefined) {
            var d1 = new Date(),
                d2 = new Date(d1);
            d2.setMinutes(d1.getMinutes() + 180);
            sessionStorage.setItem('signup-info-temp-hours', d2.toString());
        } else {
            if (
                Date.parse(
                    sessionStorage.getItem('signup-info-temp-hours').toString()
                ) < Date.parse(new Date().toString())
            ) {
                if (!this._signup.isLoginWithSubDashboard()) {
                    this._signup.loadInit();
                    this._signup.outerLocationQty = 1;
                    this._signup.outerRegisterQty = 1;
                    this._signup.checkoutPageCalcVars.locationsQty = 1;
                    this._signup.checkoutPageCalcVars.registersQty = 1;
                    this._signup.outerSelectedPlanTime = 'Monthly';
                    this._signup.selectedPlanTimeframe = 'BilledMonthly';
                    this._signup.currentStep = 1;

                    this._signup.freeTrialAccount = false;
                    sessionStorage.clear();
                }
            }
        }
        // console.log("sessionStorageTime ", this._signup.currentStep)
    }

    initSignupWizard() {
        try {
            this._signup.currentStep = 1;
            this._signup.savedSteps = 1;

            const anyCurrentStep = sessionStorage.getItem('currentStep');
            const anySavedSteps = sessionStorage.getItem('savedSteps');

            let fromBusinessWebsite =
                this.activeRouter.snapshot.queryParamMap
                    .get('selected_plan')
                    ?.toString() ?? '';
            if (anyCurrentStep != undefined && anySavedSteps != undefined) {
                this._signup.currentStep = parseInt(anyCurrentStep);
                this._signup.savedSteps = parseInt(anySavedSteps);

                if (this._signup.isLoginWithSubDashboard()) {
                    let checkout =
                        this.activeRouter.snapshot.queryParamMap
                            .get('paymentcanceld')
                            ?.toString() ?? '';

                    if (checkout) {
                        this._signup.currentStep = 8;
                    } else {
                        this._signup.currentStep = 4;
                    }

                    if (
                        Object.keys(this._signup.returnSelectedPlanInf())
                            .length === 0
                    ) {
                        this.getSubscriptionPlansLoad();
                    }
                    sessionStorage.setItem('accountVerified', '1');

                    sessionStorage.setItem(
                        'currentStep',
                        this._signup.currentStep.toString()
                    );
                    if (this._signup.savedSteps < 4) {
                        this._signup.savedSteps = 4;
                        sessionStorage.setItem(
                            'savedSteps',
                            this._signup.savedSteps.toString()
                        );
                    }

                    if (fromBusinessWebsite == '1') {
                        this._signup.currentStep = 3;
                        this._signup.savedSteps = 3;

                        sessionStorage.setItem('un-verified-account', '3');
                        sessionStorage.setItem('accountVerified', '3');

                        sessionStorage.setItem(
                            'currentStep',
                            this._signup.currentStep.toString()
                        );
                        sessionStorage.setItem(
                            'savedSteps',
                            this._signup.savedSteps.toString()
                        );
                    }
                }

                if (this._signup.currentStep == 0) {
                    this._router.navigateByUrl('setup/subscription-plans');
                }
            } else {
                if (this._signup.isLoginWithSubDashboard()) {
                    if (
                        Object.keys(this._signup.returnSelectedPlanInf())
                            .length === 0
                    ) {
                        this.getSubscriptionPlansLoad();
                    }

                    this._signup.currentStep = 4;
                    sessionStorage.setItem('currentStep', '4');

                    sessionStorage.setItem(
                        'currentStep',
                        this._signup.currentStep.toString()
                    );
                    if (this._signup.savedSteps < 4) {
                        this._signup.savedSteps = 4;
                        sessionStorage.setItem(
                            'savedSteps',
                            this._signup.savedSteps.toString()
                        );
                    }

                    if (this._globals.userInfo != undefined) {
                        this._signup.currentStep = 4;
                        sessionStorage.setItem('currentStep', '4');
                    }
                } else {
                    sessionStorage.setItem('currentStep', '1');
                    sessionStorage.setItem('savedSteps', '1');

                    if (this._globals.userInfo != undefined) {
                        this._signup.currentStep = 4;
                        sessionStorage.setItem('currentStep', '4');
                    }
                }
            }

            // console.log("initSignupWizard ", this._signup.currentStep)
        } catch (error) {

        }
    }

    getIndustries() {
        this._signup.getIndustries().subscribe((res) => {
            if (res.success) {
                this._signup.industries = res.data;
                this._signup.businessInfo.industryTypeId = res.data[1].id;
                // console.log('INDUSTRIES', this._signup.industries)
            }
        });
    }

    getSubscriptionPlans() {
        this._signup.getSubscriptionPlans().subscribe((res) => {
            if (res.success) {
                // console.log('this.subscriptionPlans', res.data)
                this._signup.subscriptionPlans = res.data;

                this._signup.selectedPlan = this._signup.subscriptionPlans[2]; // --2BD--
                this._signup.selectedPlanTimeframe = 'BilledMonthly'; //--2bd--  BilledMonthly
            }
        });
    }

    getEcomSubscriptionPlans() {
        this._signup.getEComSubscriptionPlans().subscribe((res) => {
            if (res.success) {
                this._signup.eComSubscriptionPlans = res.data;

                let obj = this._signup.eComSubscriptionPlans;
                for (let i = 0; i < obj.length; i++) {
                    obj[i].selected = false;
                    obj[i].wantDomain = 0;
                }
                // console.log('this._signup.eComSubscriptionPlans', this._signup.eComSubscriptionPlans)
            }
        });
    }

    getCustomerAppSubscriptionPlans() {
        this._signup.getCustomerAppSubscriptionPlans().subscribe((res) => {
            if (res.success) {
                this._signup.customerAppSubscriptionPlans = res.data;

                let obj = this._signup.customerAppSubscriptionPlans;
                for (let i = 0; i < obj.length; i++) obj[i].selected = false;
            }
        });
    }

    getHardwarePlanList() {
        this._signup.getHardwarePlanListv2().subscribe((res) => {
            if (res.success) {
                // let items = res.data
                // for (let i = 0; i < items.length; i++) {
                //   for (let j = 0; j < items[i].items.length; j++) {
                //     if (!items[i].items[j].isActive || items[i].items[j].isTrial || items[i].items[j].isCustom || items[i].items[j].title == "") {
                //       items[i].items.splice(j, 1)
                //     }
                //     else {
                //       items[i].items[j].selectedQty = 1
                //       items[i].items[j].StockOnHand = 100
                //     }

                //     try {
                //       let item = items[i].items[j]
                //       item.netPrice = item.price

                //       let addons = items[i].items[j].addons
                //       for (let k = 0; k < addons.length; k++)
                //         addons[k].isSelected = false

                //       let exists = this._signup.selectedHardware
                //       for (let x = 0; x < exists.length; x++) {
                //         if (exists[x].id == items[i].items[j].id) {
                //           items[i].items[j] = exists[x]
                //           break
                //         }
                //       }
                //     } catch { }
                //   }
                // }

                this._signup.hardWarePackagesList = res.data;
                if (!this._signup.isLoginWithSubDashboard()) {
                    this._signup.currentStep = 1;
                } else {
                    let checkout =
                        this.activeRouter.snapshot.queryParamMap
                            .get('paymentcanceld')
                            ?.toString() ?? '';

                    if (checkout) {
                        this._signup.currentStep = 8;
                    } else this._signup.currentStep = 4;
                }

        //   console.log("getHardwarePlanList ", this._signup.currentStep)
        this.showSpinner = false;
          this.initSignupWizard();
      }
    })
  }

    dummy() {
        this._signup.currentStep = 10;
    }

    viewCheckout() {
        this._signup.currentStep = 8;
        this._signup.savedSteps = this._signup.currentStep;
        sessionStorage.setItem(
            'currentStep',
            this._signup.currentStep.toString()
        );
        sessionStorage.setItem(
            'savedSteps',
            this._signup.savedSteps.toString()
        );
    }

    backButtonClickHandle() {
        this._signup.currentStep = this._signup.currentStep;
    }

    login() {
        this._router.navigate(['/pages/signin']);
        sessionStorage.clear();
    }
}
