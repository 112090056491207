import { BaseService } from 'src/app/_services/base.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsStates } from 'src/app/_data/us-states'
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-signup-business-detail',
  templateUrl: './signup-business-detail.component.html',
  styleUrls: ['./signup-business-detail.component.scss']
})
export class SignupBusinessDetailComponent implements OnInit {
  industryTypes: any = []
  usaStates = UsStates
  companyNameIsAvailable = true
  signupForm: FormGroup

  formName = 'app-signup-business-detail'
  _globals = GlobalService

  constructor(private fb: FormBuilder, private _router: Router, private _bs: BaseService) {
    this.industryTypes = [
      { name: 'CBD', code: 'CBD' },
      { name: 'General Retail', code: 'GR' },
      { name: 'Thrift', code: 'TH' },
    ]
  }

  async ngOnInit() {
    await this.intitializeForm()
    const fdata = this._globals.getCacheFormData(this.formName)
    if (fdata) this._globals.setFormData(this.signupForm, fdata)
  }

  async intitializeForm() {
    this.signupForm = this.fb.group({
      companyName: ['yahoo-com', [this.validateCompanyName(), Validators.required]],
      selectedType: ['General Retail', Validators.required],
      businessPhone: ['1234561234', Validators.required],
      businessAddress: ['any address', Validators.required],
      businessZipCode: ['99950', [this.validateZipcode(), Validators.required]],
      businessCity: ['New York', Validators.required],
      selectedState: ['New York', Validators.required],
      isBusinessAddressForBilling: [false],
    })
  }

  validateCompanyName(): ValidatorFn {
    return (control: AbstractControl) => {
      const xy = this.companyNameExists(control.value)
      return !this.companyNameIsAvailable ? null : { isMatching: true }
    }
  }

  validateZipcode(): ValidatorFn {
    return (control: AbstractControl) => {
      const x = control.value * 1
      return (x < 10000 || x > 99950) ? { isMatching: true } : null
    }
  }

  companyNameExists(name: string) {
    name = name.trim()
    this.companyNameIsAvailable = false
    if (name.length < 3) return false
    let ret = false
    this._bs.httpPost('auth-svc/api/IsCompanyExist', { CompanyName: name }).subscribe(res => {

      if (res.success && res.data) {
        this.companyNameIsAvailable = res.data
        return res.data
      } else {
        return false
      }
    })
  }

  backButtonPressed() {
    this._globals.updateCacheFormData(this.formName, this.signupForm.value)
    this._router.navigateByUrl('uikit/subscription')
  }

  nextButtonPressed() {
    this._globals.updateCacheFormData(this.formName, this.signupForm.value)
    this._router.navigateByUrl('uikit/signupYourDetail')
  }

}


