import { Component, OnInit } from '@angular/core';
import { CustomersService } from 'src/app/_services/customers.service';

@Component({
  selector: 'app-customer-permissions',
  templateUrl: './customer-permissions.component.html',
  styleUrls: ['./customer-permissions.component.scss']
})
export class CustomerPermissionsComponent implements OnInit {

  constructor(
    public _customerService: CustomersService
  ) { }

  ngOnInit(): void {
  }

}
