import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { BusinessSetupModel, SubscriptionModel } from 'src/app/_models/subscription-management/subscription-model';
import { PlanInfo } from 'src/app/_models/subscription-management/user-subscription-info';
import { Plan } from 'src/app/_models/subscription-plan/plan';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PermissionCheckerService } from 'src/app/_services/permission-checker.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-subscription-management',
    templateUrl: './subscription-management.component.html',
    styleUrls: ['./subscription-management.component.scss']
})
export class SubscriptionManagementComponent implements OnInit {

    items: MenuItem[];


    activeItem: MenuItem;
    _globals = GlobalService;
    planInfo = <SubscriptionModel>{};
    businessSetup = <BusinessSetupModel>{}
    dayLeftInRenewal: number = 0;
    talkToSupportExpertForm:boolean=false;
    expertSupportQuestion: string = "";
    expertSupportMessage: string = "";
    businessTransferFormRequest:boolean=false;
    changerOwnerShipSubject: string = "";
    changerOwnerShipMessage: string = "";
    constructor(private _subscriptionManagementService: SubscriptionPlanService,
        private _router: Router,
        private _abd: AppBaseDataService,
        private _subscriptionPlanService: SubscriptionPlanService,
        private _bs: BaseService,
        private router: Router,
        private route: ActivatedRoute,
        private _spinner: NgxSpinnerService,
        public _bizzCustomerService: BizzCustomersService,
        private _confirmationService: ConfirmationService,
        public _signup: SignupService,
        private _userService: UserService,
        private _messageService: MessageService,
        private _connMessageService: ConnMessageService,
        private _permissionsCheckerService:PermissionCheckerService,
        ) { }

   async ngOnInit(){
        this.items = [
            { label: 'Subscription', 
            visible: await this._permissionsCheckerService.isPermissionValid(['2096']),
        },
            { label: 'Billing History',
            visible: await this._permissionsCheckerService.isPermissionValid(['2097']),
        },
            { label: 'Hardware',
            visible: await this._permissionsCheckerService.isPermissionValid(['2098']),
        },
        ];


        this.getData();

    }

    getData() {
        this._abd.showSpinner()
        try {
            this.activeItem = this.items[0];
            this.businessSetup = this._globals.userInfo.businessSetup[0];
            this.planInfo = this._globals.userInfo.businessSetup[0].subscription;



        if (this.planInfo.isFreeTrial){
            this.items.splice(1,1);
            this.items.splice(1,1);
        }

            if (this.planInfo.isFreeTrial) {

                let startDate: Date = new Date(this.planInfo.freeTrialStartDate.toString());
                let endDate: Date = new Date(this.planInfo.freeTrialEndDate.toString());

                let diff = Math.abs(endDate.getTime() - startDate.getTime());
                this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));
            }
            else {
                if(this._permissionsCheckerService.isPermissionValid(['2094'])){
                    this.items.push({
                    label: 'Billing Address',
                }) 
                }
               
                let startDate: Date = new Date(this.planInfo.createdDate.toString());
                let endDate: Date = new Date(this.planInfo.nextChargeDate.toString());

                let diff = Math.abs(endDate.getTime() - startDate.getTime());
                this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));
            }

        }
        catch
        {

        }
        finally
        {
            this._abd.hideSpinner()
        }

    }
    talkToExpertSupportGuy() {
        this.talkToSupportExpertForm = true;
    }
    submitTalkExperGuyRequest() {
        const obj = {
            // buyNoOfLocations: this.buyLocQty,
            userEmail: this._bizzCustomerService.activeItem.email,
            companyName: this._globals.userInfo.companyName,
            question: this.expertSupportQuestion,
            message: this.expertSupportMessage
        }
        this.talkToSupportExpertForm = false
        const _url = environment.BaseURL + 'EmailForAddonsPlan'
        this._confirmationService.confirm({
            header: `Confirmation to Contact Support`,
            message: `Are you sure you want to submit a support request to our team?`,
            accept: () => {
                try {
                    this.talkToSupportExpertForm = false
                    this._abd.showSpinner()
                    this._bs.httpPost(_url, obj).subscribe(res => {
                        this._spinner.hide()
                        if (res.success) {
                            this._connMessageService.showToastMessage('Thank you! Your request is sent to our support team, someone from our team will contact you as soon as possible.', 'success')
                            this.expertSupportQuestion=''
                            this.expertSupportMessage=''
                        }
                    })
                } catch {
                    this.talkToSupportExpertForm = false
                    this._abd.hideSpinner()
                }
            },
            reject: (type) => {
                this.talkToSupportExpertForm = true
                // verifyObj.isVerified = !e.checked;
            }
        });
    }
    transferBusinessRrquestForm() {
        this.businessTransferFormRequest = true;
    }
    sendTransferBusinessRequest() {
       // this.changerOwnerShipSubject ='Change Ownership';
        const obj = {
            // buyNoOfLocations: this.buyLocQty,
            userEmail: this._bizzCustomerService.activeItem.email,
            companyName: this._globals.userInfo.companyName,
            question: this.changerOwnerShipSubject,
            message: this.changerOwnerShipMessage
        }
        this.businessTransferFormRequest = false
        const _url = environment.BaseURL + 'EmailForAddonsPlan'
        this._confirmationService.confirm({
            header: `Change Ownership Request Confirmation`,
            message: `Are you sure you want to send this message to our expert person of our team?.`,
            accept: () => {
                try {
                    this.businessTransferFormRequest = false
                    this._abd.showSpinner()
                    this._bs.httpPost(_url, obj).subscribe(res => {
                        this._spinner.hide()
                        if (res.success) {
                            this._connMessageService.showToastMessage('Thank you! Your request is sent to our support team, someone from our team will contact you as soon as possible.', 'success')
                            this.changerOwnerShipSubject=''
                            this.changerOwnerShipMessage=''
                        }
                    })
                } catch {
                    this.businessTransferFormRequest = false
                    this._abd.hideSpinner()
                }
            },
            reject: (type) => {
                this.businessTransferFormRequest = true
                // verifyObj.isVerified = !e.checked;
            }
        });
    }
    onTabItemClick(tab: any) {
        this.activeItem = tab.activeItem
    }

}
