import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-invoice-documents-details',
  templateUrl: './invoice-documents-details.component.html',
  styleUrls: ['./invoice-documents-details.component.scss']
})
export class InvoiceDocumentsDetailsComponent implements OnInit {
  showPrintPreview = false
  title=''

  constructor(public _reportService: ReportsService,) { }

  ngOnInit(): void {
    // console.log('INVOICE-ROW', this._reportService.selectedInvoiceRow)
    // console.log('INVOICE-DS', this._reportService.selectedInvoiceDetails.invoiceDocuments)
    // console.log('INVOICE-D', this._reportService.invoiceOrderDetail)
    this.title='Invoice #: '+ this._reportService.selectedInvoiceRow.invoiceNumber
  }

  printButtonClicked() { 
    this.showPrintPreview = true
  }

}
