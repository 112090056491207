import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { UsStates } from 'src/app/_data/us-states';
import { NextLevelFormIntegrationModel } from 'src/app/_models/next-level-integration/api-integration-model/next-level-integration';
import { OwnerDetailModel, otherOwnerDetailModel } from 'src/app/_models/next-level-integration/next-level-model';
import { OtherOwnersListArray, OtherOwnersArrayModel } from 'src/app/_models/next-level-integration/other-owners-array-model';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

interface City {
    name: string;
    code: string;
}

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.scss']
})
export class OwnerDetailsComponent implements OnInit {

    items!: MenuItem[];
    activeItem!: MenuItem;
    value1!: string;
    cities!: City[];
    selectedCity1!: City;
    value2!: number;
    ownersDetailForm!: FormGroup;
    defaultVal = <OwnerDetailModel>{};
    defalutotherVal = <otherOwnerDetailModel>{};

    @Output() ownersFormSaved: EventEmitter<boolean> = new EventEmitter();
    isSubmitted: boolean = false;
    usaStates = UsStates;
    othersOwnersNumber: number = 0;
    public fields: any[] = [];
    unsubscribe: any;
    public arrayTitle = ['2nd Owner', '3rd Owner', '4th Owner'];
    showOwnerZipCodeLoading: boolean = false;
    constructor(private fb: FormBuilder, public _abd: AppBaseDataService) { }

    otherOwnersListArray = OtherOwnersListArray;
    dob: Date = new Date();
    dob1: Date = new Date();
    dob01: Date = new Date();
    dob02: Date = new Date();
    dob03: Date = new Date();

    otherOwnerFormSelected: boolean = true;

    @Input() formObj = <NextLevelFormIntegrationModel>{}

    ngOnInit() {
        this.defaultValue();
        this.defalutOtherOwnerValue();
        this.intiOwnerForm();
    }

    defaultValue() {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        this.defaultVal.firstName = '';
        this.defaultVal.lastName = '';
        this.defaultVal.title = '';
        this.defaultVal.dob = new Date(currentDate);
        this.defaultVal.ssn = '';
        this.defaultVal.ownerPercentage = 1;
        this.defaultVal.ownerPhone = '';
        this.defaultVal.role = '';
        this.defaultVal.address = '';
        this.defaultVal.zipCode = '';
        this.defaultVal.city = '';
        this.defaultVal.state = this.usaStates[0].name;
        this.defaultVal.issueState = '';
        this.defaultVal.issueDate = new Date(currentDate);
    }

    defalutOtherOwnerValue() {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        this.defalutotherVal.firstName = '';
        this.defalutotherVal.lastName = '';
        this.defalutotherVal.title = '';
        this.defalutotherVal.dob = new Date(currentDate);
        this.defalutotherVal.ssn = '';
        this.defalutotherVal.ownerPercentage = 1;
        this.defalutotherVal.ownerPhone = '';
        this.defalutotherVal.role = '';
        this.defalutotherVal.address = '';
        this.defalutotherVal.zipCode = '';
        this.defalutotherVal.city = '';
        this.defalutotherVal.state = this.usaStates[0].name;
    }

    getSessionOwnerValue() {
        return sessionStorage.getItem('Owner Detail');
    }

    intiOwnerForm() {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        let ownerObj: any = this.getSessionOwnerValue();
        if (ownerObj != undefined) {
            ownerObj = JSON.parse(ownerObj);

            this.defaultVal.firstName = ownerObj.firstName;
            this.defaultVal.lastName = ownerObj.lastName;
            this.defaultVal.title = ownerObj.title;
            this.defaultVal.dob = new Date(ownerObj.dob);
            this.defaultVal.ssn = ownerObj.ssn;
            this.defaultVal.ownerPercentage = ownerObj.ownerPercentage;
            this.defaultVal.ownerPhone = ownerObj.ownerPhone;
            this.defaultVal.role = ownerObj.role;
            this.defaultVal.address = ownerObj.address;
            this.defaultVal.zipCode = ownerObj.zipCode;
            this.defaultVal.city = ownerObj.city;
            this.defaultVal.state = this.usaStates.filter(
                (f) => f.abbreviation == ownerObj.state
            )[0].name;
            this.defaultVal.issueState = ownerObj.issueState;
            this.defaultVal.issueDate = new Date(ownerObj.issueDate);
        }

        this.ownersDetailForm = this.fb.group({
            firstName: [this.defaultVal.firstName, Validators.required],
            lastName: [this.defaultVal.lastName, Validators.required],
            title: [this.defaultVal.title, Validators.required],
            dob: [
                formatDate(new Date(this.defaultVal.dob), 'MM/dd/yyy', 'en'),
                Validators.required,
            ],
            ssn: [this.defaultVal.ssn, Validators.required],
            ownerPercentage: [
                this.defaultVal.ownerPercentage,
                Validators.required,
            ],
            ownerPhone: [this.defaultVal.ownerPhone, Validators.required],
            role: [this.defaultVal.role, Validators.required],
            address: [this.defaultVal.address, Validators.required],
            zipCode: [this.defaultVal.zipCode, Validators.required],
            city: [this.defaultVal.city, Validators.required],
            state: [
                this.usaStates.filter(
                    (f) => f.name == this.defaultVal.state
                )[0],
            ],
            issueState: [this.defaultVal.issueState],
            issueDate: [
                formatDate(
                    new Date(this.defaultVal.issueDate),
                    'MM/dd/yyy',
                    'en'
                ),
                Validators.required,
            ],
            otherOwners: this.fb.array([]),
        });
        this.othersOwnersNumber = 0;
        this.initOthersOwnersForm();



    }

    get otherOwners(): FormArray {
        return this.ownersDetailForm.get('otherOwners') as FormArray;
    }

    getOtherOwnersDetails(i: number) {
        let anyOtherOwnerFound: any = this.getSessionOwnerValue();
        if (anyOtherOwnerFound != undefined) {
            anyOtherOwnerFound = JSON.parse(anyOtherOwnerFound);

            if (i < anyOtherOwnerFound.othersOwnersList.length) {
                if (anyOtherOwnerFound.othersOwnersList.length > 0) {
                    this.othersOwnersNumber = i + 1;
                    this.otherOwnersListArray
                        .filter((f) => f.id != this.othersOwnersNumber)
                        .map((f) => (f.selected = false));
                    return anyOtherOwnerFound.othersOwnersList[i];
                }
            }
        }
        return false;
    }

    createFields(i: number): FormGroup {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        let showForm = '0';
        if (typeof this.getOtherOwnersDetails(i) === 'object') {
            let t = this.getOtherOwnersDetails(i);
            this.defalutotherVal.firstName = t.firstName;
            this.defalutotherVal.lastName = t.lastName;
            this.defalutotherVal.title = t.title;
            this.defalutotherVal.dob = new Date(t.dob);
            this.defalutotherVal.ssn = t.ssn;
            this.defalutotherVal.ownerPercentage = t.ownerPercentage;
            this.defalutotherVal.ownerPhone = t.ownerPhone;
            this.defalutotherVal.role = t.role;
            this.defalutotherVal.address = t.address;
            this.defalutotherVal.zipCode = t.zipCode;
            this.defalutotherVal.city = t.city;
            this.defalutotherVal.state = this.usaStates.filter(
                (f) => f.abbreviation == t.state
            )[0].name;
            showForm = '1';
            currentDate = formatDate(
                new Date(this.defalutotherVal.dob),
                'MM/dd/yyy',
                'en'
            );
            this.otherOwnersListArray
                .filter((f) => f.id == this.othersOwnersNumber)
                .map((f) => (f.selected = true));
        }

        return this.fb.group({
            showOwnerZipCodeLoading: ['0'],
            showForm: [showForm],
            firstNameControlName: [this.defalutotherVal.firstName],
            lastNameControlName: [this.defalutotherVal.lastName],
            roleControlName: [this.defalutotherVal.title],
            ownerPercentageControlName: [this.defalutotherVal.ownerPercentage],
            dobControlName: [currentDate],
            ssnControlName: [this.defalutotherVal.ssn],
            ownerPhoneControlName: [this.defalutotherVal.ownerPhone],
            addressControlName: [this.defalutotherVal.address],
            zipCodeControlName: [this.defalutotherVal.zipCode],
            cityControlName: [this.defalutotherVal.city],
            stateControlName: [
                this.usaStates.filter(
                    (f) => f.name == this.defalutotherVal.state
                )[0],
            ],
        });
    }

    initOthersOwnersForm() {
        this.fields = [];
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        for (let i = 0; i < 3; i++) {
            this.otherOwners.push(this.createFields(i));
        }
    }

    setOtherOwnersLimit(o: OtherOwnersArrayModel) {
        this.othersOwnersNumber = o.id;
        this.otherOwnersListArray
            .filter((f) => f.id != o.id)
            .map((f) => (f.selected = false));
        o.selected = !o.selected;
        for (let i = 0; i < this.othersOwnersNumber; i++) {
            this.ownersDetailForm.controls.otherOwners['controls'][
                i
            ].controls.showForm.setValue('0');
        }

        if (o.selected) {


            for (let i = 0; i < this.othersOwnersNumber; i++) {
                this.ownersDetailForm.controls.otherOwners['controls'][
                    i
                ].controls.showForm.setValue('1');
            }
        } else {
            this.otherOwnerFormSelected = true;
            this.othersOwnersNumber = -1;

            Object.keys(this.ownersDetailForm.controls).forEach(key => {

                if (key == 'issueDate' && this.ownersDetailForm.controls[key].valid) {
                   // this.otherOwnerFormSelected = false;
                }

            });
        }
    }

    onTabItemClick(tab: any) {
        this.activeItem = tab.activeItem;
    }

    get f() {
        return this.ownersDetailForm.controls;
    }

    get othersOwners() {
        return this.ownersDetailForm.controls.otherOwners['controls'];
    }

    saveOwner() {
        this.isSubmitted = true;
        if (this.ownersDetailForm.invalid) {
            // Got focus to the error field
            let invalidFields = [].slice.call(
                document.getElementsByClassName('ng-invalid')
            );
            invalidFields[1].focus();
            return false;
        }

        let tempObj = <any>{};
        this.formObj.owner1_first_name = this.f.firstName.value;
        this.formObj.owner1_last_name = this.f.lastName.value;
        this.formObj.owner1_title = this.f.title.value;
        this.formObj.owner1_dob = this.f.dob.value;
        this.formObj.owner1_social_securrity_no = this.f.ssn.value;
        this.formObj.owner1_ownership_perc = this.f.ownerPercentage.value;
        this.formObj.owner1_home_phone = this.f.ownerPhone.value;
        this.formObj.owner1_last_name = this.f.role.value;
        this.formObj.owner1_home_address = this.f.address.value;
        this.formObj.owner1_home_zip = this.f.zipCode.value;
        this.formObj.owner1_home_city = this.f.city.value;
        this.formObj.owner1_home_state = this.f.state.value.abbreviation;
        this.formObj.owner1_issuer_state = this.f.issueState.value;
        this.formObj.owner1_issued_date = this.f.issueDate.value;

        let othersOwnersList: any[] = [];
        if (
            this.othersOwnersNumber > 0 &&
            this.otherOwnersListArray.filter((f) => f.selected).length > 0
        ) {
            this.othersOwners.map(function (value) {
                if (value.controls.showForm.value == '1') {
                    othersOwnersList.push({
                        firstName: value.controls.firstNameControlName.value,
                        lastName: value.controls.lastNameControlName.value,
                        title: value.controls.roleControlName.value,
                        dob: value.controls.dobControlName.value,
                        ssn: value.controls.ssnControlName.value,
                        ownerPercentage:
                            value.controls.ownerPercentageControlName.value,
                        ownerPhone: value.controls.ownerPhoneControlName.value,
                        address: value.controls.addressControlName.value,
                        zipCode: value.controls.zipCodeControlName.value,
                        city: value.controls.cityControlName.value,
                        state: value.controls.stateControlName.value
                            .abbreviation,
                    });
                }
            });
        }
        tempObj.othersOwnersList = othersOwnersList;
        sessionStorage.setItem('Owner Detail', JSON.stringify(tempObj));


        this.ownersFormSaved.emit(true);
    }

    getZipCode() {
        if (!this.f.zipCode.value || this.f.zipCode.value.length !== 5) return;
        this.showOwnerZipCodeLoading = true;
        this._abd.getCityAndStateInfo(this.f.zipCode.value).subscribe(
            (res: any) => {
                if (res.success && res.data.city != undefined) {
                    this.f.city.setValue(res.data.city);
                    let selectedState = this.usaStates.filter(
                        (f) => f.abbreviation == res.data.state
                    )[0];
                    this.f.state.setValue(selectedState);
                    this.showOwnerZipCodeLoading = false;
                } else {
                    this.f.city.setValue('');
                    this.showOwnerZipCodeLoading = false;
                }
            },
            (error) => {
                this.showOwnerZipCodeLoading = false;
                this.f.city.setValue('');
            }
        );
    }

    ownersZipFiled(formIndex: number) {
        let t = this.othersOwners[formIndex];
        if (
            !this.otherOwners.value[formIndex].zipCodeControlName ||
            this.otherOwners.value[formIndex].zipCodeControlName.length !== 5
        )
            return;
        t.controls.showOwnerZipCodeLoading.setValue('1');
        this._abd
            .getCityAndStateInfo(
                this.otherOwners.value[formIndex].zipCodeControlName
            )
            .subscribe((res: any) => {
                if (res.success && res.data.city != undefined) {
                    t.controls.showOwnerZipCodeLoading.setValue('0');
                    t.controls.cityControlName.setValue(res.data.city);
                    let selectedState = this.usaStates.filter(
                        (f) => f.abbreviation == res.data.state
                    )[0];
                    t.controls.stateControlName.patchValue(selectedState);
                } else {
                    t.controls.showOwnerZipCodeLoading.setValue('0');
                    t.controls.cityControlName.setValue('');
                }
            });
    }
    validateDate(inputType: number, isOtherBeneficiariesIndex: number = -1) {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');

        switch (inputType) {
            case 1:
                if (
                    Object.prototype.toString.call(this.f.dob.value) ===
                    '[object Date]'
                ) {
                    if (isNaN(this.f.dob.value)) {
                        this.f.dob.setValue(currentDate);
                    }
                } else {
                    this.f.dob.setValue(currentDate);
                }
                break;
            case 2:
                if (
                    Object.prototype.toString.call(this.f.issueDate.value) ===
                    '[object Date]'
                ) {
                    if (isNaN(this.f.issueDate.value)) {
                        this.f.issueDate.setValue(currentDate);
                    }
                } else {
                    this.f.issueDate.setValue(currentDate);
                }
                break;
            case 3:
                let t = this.othersOwners[isOtherBeneficiariesIndex];

                if (
                    Object.prototype.toString.call(
                        t.controls.dobControlName
                    ) === '[object Date]' &&
                    isOtherBeneficiariesIndex > -1
                ) {
                    if (isNaN(t.controls.dobControlName)) {
                        t.controls.dobControlName.setValue(currentDate);
                    }
                } else {
                    t.controls.dobControlName.setValue(currentDate);
                }
                break;

            default:
                break;
        }
    }

}
