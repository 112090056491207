<form [formGroup]="editform" (ngSubmit)="editform.valid && saveFn()" autocomplete="off">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label class="control-label">
                <strong>First Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-text-input [formControl]="$any(editform.controls)['firstName']" [name]="'First Name'"
                [label]="'First Name'"></app-text-input>
        </div>
        <div class="field col-12 md:col-6">
            <label class="control-label">
                <strong>Last Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-text-input [formControl]="$any(editform.controls)['lastName']" [name]="'Last Name'"
                [label]="'Last Name'"></app-text-input>
        </div>
        <br />
        <div class="field col-12 md:col-6">
            <label class="control-label">
                <strong>Phone
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-phone-input class="mt-2" [formControl]="$any(editform.controls)['phone']">
            </app-phone-input>
        </div>
        <div class="field col-12 md:col-6">
            <label class="control-label">
                <strong>Email
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-text-input [formControl]="$any(editform.controls)['email']" [name]="'Email'"
                [label]="'Email'"></app-text-input>
        </div>
        <br />
        <div class="field col-12 md:col-4">
            <label class="control-label">
                <strong>Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-text-input formControlName="postalCode" [label]="'Zip Code'" [name]="'Zip Code'"
                (input)="zipInputEvent($event.target.value)"></app-text-input>
        </div>
        <div class="field col-12 md:col-4">
            <label class="control-label">
                <strong>City
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <span class="p-input-icon-right" [style]="{ width: '100%' }">
                <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                <app-text-input formControlName="city" [name]="'City'" [label]="'City'"></app-text-input>
            </span>
        </div>
        <div class="field col-12 md:col-4">
            <label class="control-label">
                <strong>State
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <p-dropdown [style]="{
                    width: '100%',
                    'min-width': '200px',
                    'margin-top': '7px'
                }" class="p-dropdown-1" appendTo="body" [options]="usaStates" [(ngModel)]="selectedState"
                optionLabel="name" [filter]="true" filterBy="name" [ngModelOptions]="{ standalone: true }"></p-dropdown>
        </div>
    </div>

    <br />
    <p-divider></p-divider>
    <div class="flex justify-content-end flex-wrap card-container green-container mt-3">
        <div class="flex align-items-center justify-content-center">
            <button pButton pRipple type="submit" label="Save" [disabled]="!(editform.valid &&
                    editform.get('postalCode').value.trim().length == 5)
                "></button>
        </div>
        <div class="flex align-items-center justify-content-center ml-2">
            <button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
                (click)="cancelButtonClick()"></button>
        </div>
    </div>
</form>