import { DropDownModel } from "./drop-down-model";

export let TaxTypeIdList: DropDownModel[] = [
    {
        view: 'Social Security Number',
        viewValue: 'Social Security Number'
    },
    {
        view: 'Employer Identification Number',
        viewValue: 'Employer Identification Number'
    },
]
