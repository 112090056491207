import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-left-navigator',
  templateUrl: './left-navigator.component.html',
  styleUrls: ['./left-navigator.component.scss']
})
export class LeftNavigatorComponent implements OnInit {

    stepsArray : any[] =[];

    @Input() currentStep: number = 1;
    previousValue : number = 0;
    resubscribe: boolean = false;
    constructor(
        public _signup: SignupService,
        private activeRouter: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.updateNumbers();
        let subscriptionAgain: any = sessionStorage.getItem('sub-from-dashboard');

        if (subscriptionAgain != undefined) {
            this.resubscribe = true;
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes['currentStep'];
            let cur  = JSON.stringify(chng?.currentValue);
            let prev = JSON.stringify(chng?.previousValue);
            this.previousValue = parseInt(prev)
            this.updateNumbers();
        }
    }
    updateNumbers(){
        this.stepsArray = this._signup.steps.filter(f=> f.show);
        let subscriptionAgain: any = sessionStorage.getItem('sub-from-dashboard');
        if (subscriptionAgain != undefined) {

            this._signup.steps.map(f=> f.show = false)

            let selectedPlanTemp = (this.activeRouter.snapshot.queryParamMap.get('selected_plan')?.toString() ?? "")
            if (selectedPlanTemp != ""){
                if (selectedPlanTemp == "1"){
                    this._signup.steps.filter(f => f.id < 4).map(f => f.show = true)
                }
                else{
                    this._signup.steps.map(f => f.show = true)
                    this._signup.steps.filter(f => f.id < 4).map(f => f.show = false)
                }
            }
            else{
                this._signup.steps.map(f => f.show = true)
                this._signup.steps.filter(f => f.id < 4).map(f => f.show = false)
            }

        }
        let self = this;

        this.stepsArray.map(function (value, index) {
            value.isActive = ((value.stepNumber == self._signup.currentStep) ) ? true : false;
            value.isEnabled = (value.stepNumber <= self._signup.savedSteps || value.id == self._signup.savedSteps || value.stepNumber == self.previousValue) ? true : false;;
            value.currentStep = (value.id == self._signup.currentStep) ? true : false;
            value.informationSaved = (value.id < self._signup.savedSteps) ? true : false;
            value.showStepForFreeTrial = true;
            if (self._signup.freeTrialAccount && value.stepNumber > 3) {
                value.showStepForFreeTrial = false;
            }


            if (self._signup.currentStep == 7) {
                self.stepsArray.filter(f => f.id == 6)[0].informationSaved = false;
                self.stepsArray.filter(f => f.id == 6)[0].currentStep = true;
                self.stepsArray.filter(f => f.id == 6)[0].isActive = true;
                self.stepsArray.filter(f => f.id == 6)[0].isEnabled = true;
            }

            if (self._signup.accountVerified && (value.id == 8 ) && !self._signup.freeTrialAccount) {

                value.informationSaved = false;


            }

            if (self._signup.accountVerified && value.id == 3 && !self._signup.freeTrialAccount) {
                //   value.showStepForFreeTrial = false;
            }

            // if (self._signup.accountVerified && self._signup.currentStep == 8 && !self._signup.freeTrialAccount) {
            //     //   value.showStepForFreeTrial = false;
            //     value.informationSaved == false
            //     value.currentStep = true;
            //     value.isEnabled = true;
            //     value.isActive = true;
            // }

            if (self._signup.accountVerified && value.id > 3 && !self._signup.freeTrialAccount) {
                //value.stepNumber--;
            }

        })

        let fromBusinessWebsite = (this.activeRouter.snapshot.queryParamMap.get('selected_plan')?.toString() ?? "")

        if (fromBusinessWebsite != "") {
            this._signup.selectedPlan = this._signup.returnSelectedPlanInf();
            if (fromBusinessWebsite == "1") {
                this.stepsArray.map(function (v) {
                    if (v.stepNumber > 3) {
                        v.showStepForFreeTrial = false;
                    }
                })
            }
            else{
                this.stepsArray.map(function (v) {
                    if (v.stepNumber > 3) {
                        v.showStepForFreeTrial = true;
                    }
                })
            }
        }
    }

    getTitleClass(id) {
        if (id == this._signup.currentStep)
        return 'flex align-items-center w-full text-left font-bold text-lg text-blue-6 00 mb-2'
        else return 'flex align-items-center w-full text-left font-bold text-lg text-700 mb-2'
    }

    changeStep(stepNumber: number){
        //if(stepNumber > this._signup.savedSteps)
            return false

       // this._signup.currentStep = stepNumber;
       // this.updateNumbers();
    }

}
