import { ProductsService } from 'src/app/_services/products.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Router } from '@angular/router';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { ThisReceiver } from '@angular/compiler';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsComponentList implements OnInit {
  _globals = GlobalService

  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  doChangePage = true
  selectedRow: any = ''
  selectAll = false

  lastSearchText = ''
  dataList: any = null

  //order
  orderParam = ''
  orderBy = ''

  colheader = [[
    'Product Name', 'Barcode', 'SKU', 'Track Quantity'
    , 'Sell Online', 'Sale Price', 'Last Updated'
  ]];

  colflds = ['itemName', 'barCode', 'skuCode', 'trackStock',
    'sellOnline', 'sellingPrice', 'lastEdited'
  ];

  csvcols = []
  exportColumns: any = ''

  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    private _router: Router,
    private _productService: ProductsService,
    private _connMessageService: ConnMessageService,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
    private _confirmationService: ConfirmationService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService,
    private http: HttpClient,
    private _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Products'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.searchText) this._filterService.searchText = filter.searchText
        const rr = this._abd.getProductSellTypeFilter(filter.selectedProductSellTypeFilter)
        if (filter.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = rr
        if (filter.selectedCategory) this._filterService.selectedCategory = filter.selectedCategory
        if (filter.selectedDepartment) this._filterService.selectedDepartment = JSON.parse(JSON.stringify(filter.selectedDepartment))
        if (filter.selectedManufacturer) this._filterService.selectedManufacturer = filter.selectedManufacturer
        if (filter.selectedVendor) this._filterService.selectedVendor = filter.selectedVendor
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }

      const outfilter = {
        searchText: this._filterService.searchText ? this._filterService.searchText : '',
        // selectedProductSellTypeFilter: sellOnline == 1 ? 'true' : 'false',
        selectedDepartment: this._filterService.selectedDepartment,
        selectedCategory: this._filterService.selectedCategory,
        selectedVendor: this._filterService.selectedVendor,
        selectedManufacturer: this._filterService.selectedManufacturer,
      }
      this.getInitData()
      clearInterval(ti)
    }, 700);

  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  makeParams() {
    //--
    let filter: any = {}
    let params = new HttpParams();

    let locationId: any = null
    let departmentId = null
    let categoryId = null
    let vendorId = null
    let manufactureId = null

    let sellOnline = this._filterService.selectedProductSellTypeFilter?.id;
    if (sellOnline) {
      if (sellOnline == 1)
        params = params.append('sellOnline', 'true')
      else if (sellOnline == 2)
        params = params.append('sellOnline', 'false')
    } else {
      sellOnline = 3
      // params = params.append('sellOnline', 'true')
    }

    try {
      categoryId = this._filterService.selectedCategory?.id.toString()
      locationId = this._filterService.selectedLocation?.id
      departmentId = this._filterService.selectedDepartment?.id.toString()
      vendorId = this._filterService.selectedVendor?.id.toString()
      manufactureId = this._filterService.selectedManufacturer?.id.toString()
    } catch { }

    //if (locationId) params = params.append('locationId', locationId.toString());
    if (departmentId) params = params.append('departId', departmentId.toString());
    if (categoryId) params = params.append('categoryId', categoryId.toString());
    if (vendorId) params = params.append('vendorId', vendorId.toString());
    if (manufactureId) params = params.append('manufactureId', manufactureId.toString());

    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());

    if (this._filterService.searchText?.length > 0) {
      params = params.append('filterValue', this._filterService.searchText);
    }

    //------
    const outfilter = {
      searchText: this._filterService.searchText ? this._filterService.searchText : '',
      selectedProductSellTypeFilter: sellOnline,
      selectedDepartment: this._filterService.selectedDepartment,
      selectedCategory: this._filterService.selectedCategory,
      selectedVendor: this._filterService.selectedVendor,
      selectedManufacturer: this._filterService.selectedManufacturer,
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
    return params
    //--

  }

  getDataList() {
    let hparas = this.makeParams()
    let qryParas = null
    if (this.orderParam && this.orderParam.length > 0) {
      qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`
      this._filterService.updateOrderby(this._globals.componentName, { orderParam: this.orderParam, orderBy: this.orderBy })
    }

    this._abd.showSpinner()
    this._productService.getAllProductList4Inventory(hparas, qryParas).subscribe(res => {
      if (res.success) {
        this._abd.hideSpinner()
        this.totalRecords = res.totalRecords
        this.dataList = res.data
        this._globals.showProductDelButton = false
        this._barcodeLabelServices.isLabelPrintBtn = false;

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].selected = false

          try { this.dataList[i].barCode = this.dataList[i].crsItemSKUs[0].barcode }
          catch { this.dataList[i].barCode = '--' }

          try { this.dataList[i].skuCode = this.dataList[i].crsItemSKUs[0].skuCode }
          catch { this.dataList[i].skuCode = '--' }

          try { this.dataList[i].sellingPrice = this.dataList[i].crsItemSKUs[0].salePriceA }
          catch { this.dataList[i].sellingPrice = 0 }
        }
        return this.dataList
      }
    })
  }


  ExportCompleteInventory() {
    this._abd.showSpinner()
    let params = new HttpParams();
    let locationId = this._abd.actLocations[0].id
    let categoryId = this._filterService.selectedCategory?.id.toString()
      let departmentId = this._filterService.selectedDepartment?.id.toString()
      let vendorId = this._filterService.selectedVendor?.id.toString()
     let manufactureId = this._filterService.selectedManufacturer?.id.toString()
     let sellOnline = this._filterService.selectedProductSellTypeFilter?.Value;
    if (locationId) params = params.append('warehouseId', locationId.toString());
    if (departmentId) params = params.append('departId', departmentId.toString());
    if (categoryId) params = params.append('categoryId', categoryId.toString());
    if (vendorId) params = params.append('vendorId', vendorId.toString());
    if (manufactureId) params = params.append('manufactureId', manufactureId.toString());
    if(sellOnline != 'All'){
        if (sellOnline) params = params.append('sellOnline', sellOnline == "Online" ? true : false);
    }

    this._productService.ExportInventoryCSV(params).subscribe(res => this.downLoadFile(res, "application/csv"))
  }
  /**
       * Method is use to download file.
       * @param data - Array Buffer data
       * @param type - type of the document.
       */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = url

    downloadLink.setAttribute('download', "inventory.csv");

    document.body.appendChild(downloadLink);
    downloadLink.click();

    this._abd.hideSpinner();
  }

  actionButtonClick(item: any) {
    this._productService.activeItem = item
    this._productService.isNewVendor = false
  }

  getInitData() {
    this.getDataList()
  }

  clearSearchText() {
    if (this.lastSearchText?.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  searchTextSearch() {
    if (this._filterService.searchText?.length < 1 && this.lastSearchText?.length < 1) return
    this.lastSearchText = this._filterService.searchText //this.searchText
    this.filterButtonPressed()
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  selectAllChanged(event) {
    if (event.checked) {
      this._globals.showProductDelButton = true
      this._barcodeLabelServices.isLabelPrintBtn = true;
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      this._globals.showProductDelButton = false
      this._barcodeLabelServices.isLabelPrintBtn = false;
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }
  }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {
        ret = true
        break
      }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) this.selectAll = true
      else this.selectAll = false
    }

    this._globals.showProductDelButton = ret
    this._barcodeLabelServices.isLabelPrintBtn = ret;
  }

  productDelete() {
    this._confirmationService.confirm({
      header: `Delete Product: ${this._productService.activeItem.itemName}?`,
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        try {
          this._abd.showSpinner()
          this._productService.deleteProduct(this._productService.activeItem.id).subscribe(res => {
            if (res.success) {
              this.filterButtonPressed()
              this.selectAll = false
              this._connMessageService.showToastMessage(`Product Deleted Successfully.`, 'success');
            }
          })
        } catch { this._abd.hideSpinner() }
      }
    });
  }

  commaSeperatedIds(data: any): string {
    let selectedItems: string = "";
    let z = data.filter(x => x.selected)
    if (z.length > 100) {
      this._connMessageService.showToastMessage('Cannot delete more than 100 items at a single time.', 'error')
      return null
    }
    z.forEach(x => { selectedItems += x.id + "," })
    selectedItems = selectedItems.slice(0, -1);
    return selectedItems
  }

  deleteMultiple() {
    let marked = this.dataList.filter(f => f.selected)
    let markedIds = this.commaSeperatedIds(marked);
    if (!marked || !markedIds) return

    let header = 'Deleting product Confirmation'
    if (marked.length > 1) header = `Deleting ${marked.length} product(s) Confirmation`

    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed?',
      header: header,
      icon: 'pi pi-info-circle',
      accept: () => {
        try {
          this._abd.showSpinner()
          this._productService.deleteProduct(markedIds).subscribe(res => {
            if (res.success) {
              this._connMessageService.showToastMessage(`Deleted ${marked.length} product(s) successfully.`, 'success');
              this.getDataList()
              this._abd.hideSpinner()
              this.selectAll = false
            }
          })
        } catch {
          this.getDataList()
          this._abd.hideSpinner()
        }
      },
      reject: () => {
      }
    });
  }
  printLabel(){
    let list = this.dataList.filter(f => f.selected)
    list = list.map((v)=>{
      return{
        productId:v.id,
        productName:v.itemName,
        barcode:v.barCode,
        skuCode:v.skuCode,
        salePriceA:v.sellingPrice,
        locationName:"",
        locationId:"",
      }
    })
    this._barcodeLabelServices.selectedListToPrintLabel = list
    this._barcodeLabelServices.productListPrintBarcodeLabel = true;
    this._globals.showProductDelButton = false
    this._barcodeLabelServices.isLabelPrintBtn = false;
    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].selected = false
    }
  }

  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }

  callbackFuncFromFilter() {
    this.filterButtonPressed()
  }

  exportExcel() {
    this.ExportCompleteInventory();
  }

  exportPdf() {
    let x = this.paginator
    this.paginator.changePage(3)
    //return
    this._abd.exportPdf(this.dataList, this.colheader, this.colflds, 'Product Report')
  }

  redirectToNewProduct() {
    this.dataList = ''
    for (let j = 0; j < this._productService.selectedLocations.length; j++) {
      let line = this._productService.selectedLocations[j]
      if (line.stockOnHand) line.stockOnHand = 0
      if (line.openingStock) line.openingStock = 0
      if (line.stockOnHandQoh) line.stockOnHandQoh = line.stockOnHand
    }

    this._router.navigate(['app/new-product'])
  }

  productViewClicked() {
    this._productService.isNew = false
    this._productService.isEdit = false
    this._productService.isViewOnly = true
    this.redirectToNewProduct()
  }

  productEditClicked() {
    this._productService.isNew = false
    this._productService.isEdit = true
    this._productService.isViewOnly = false
    this.redirectToNewProduct()
  }

  onRowSelect(e) {
    this._productService.activeItem = e.data
  }
  onRowUnselect(e) { }

  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'lastEdited':
        this.orderParam = 'LastEdited'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
}
