import { ProductsService } from 'src/app/_services/products.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-barcode-label-printing-form',
  templateUrl: './barcode-label-printing-form.component.html',
  styleUrls: ['./barcode-label-printing-form.component.scss']
})
export class BarcodeLabePrintingForm implements OnInit {
  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel

// iscustom = 0 false
// iscustom = 1 true

  destroy$: Subject<boolean> = new Subject<boolean>()
  editform: FormGroup
  customform: FormGroup
  saveButton:string = 'Add to Printing Queue'
  activeTemplateId = 1;
  templateList:any[] =[
    {templateId:1,templateURL:'assets/images/barcode.png'},
  ]
  isLoading:boolean = false;
  searchText = ''

  @Input() isNew: boolean = true
  @Input() isProductLabel: boolean = true
  @Input() isViewOnly: boolean 
  @Input() fromList: boolean = true
  @Output() onSubmit = new EventEmitter();
  lastSearchText: string;
  isListLoading: boolean;
  dataList: any = []
  searchList: any = []
  formValues:any;
  isProductLoad:boolean = false;
  singleLocationList: any []=[];
  multiLocationList: any []=[];
  
  constructor(
    public _abd: AppBaseDataService,
    private _productService: ProductsService,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _fb: FormBuilder,
    private _messageService: MessageService,
    ) { }


  ngOnInit(): void {
    this.singleLocationList = this._abd.locations
    this.multiLocationList = this._abd.locations
    this.initForm();
    this.activeTemplate(1)
    if(this.isProductLabel && !this.isNew){
      this.isProductLoad =true
      let obj ={
      pageNo :0,
      pageSize: 10,
      filterValue:this._barcodeLabelServices.activeItem.productId,
      }
    this._productService.getAllProductList4Inventory(obj).subscribe((v)=>{
      this.isProductLoad =false;
      this.dataList = v.data.map((v)=>{
        return{
          ...v,
          productId:v.id,
          productName:v.itemName,
          barcode:v.crsItemSKUs[0].barcode,
          skuCode:v.crsItemSKUs[0].skuCode,
          salePriceA:v.crsItemSKUs[0].salePriceA,
        }
      })
    },(error)=>{
      this.isProductLoad =false
    })
    }

   if(this.isProductLabel && !this.fromList){
    if(this._barcodeLabelServices.selectedListToPrintLabel !== null)
    this.dataList = this._barcodeLabelServices.selectedListToPrintLabel
    this._barcodeLabelServices.selectedListToPrintLabel = []
   }


  }

activeTemplate(id){
      this.activeTemplateId = id; 
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

 get f() {
    return this.editform.controls;
  }
  get cf() {
    return this.customform.controls;
  }
  initForm() {
    if(!this.isProductLabel && this.fromList){
 
      this.activeTemplateId = this._barcodeLabelServices.activeItem.templateId
       this.formValues ={
         productName:this._barcodeLabelServices.activeItem?.productName,
         barcode:this._barcodeLabelServices.activeItem?.barcode , 
         sku:this._barcodeLabelServices.activeItem?.sku , 
         business:this._barcodeLabelServices.activeItem?.business || this._globals.userInfo?.businessSetup[0].businessDisplayName,
         locationId:this._barcodeLabelServices.activeItem?.locationId,
         price:this._barcodeLabelServices.activeItem?.price || "0.00",
         noOfCopy:this._barcodeLabelServices.activeItem?.noOfCopy,
         location:+this._barcodeLabelServices.activeItem?.location
       }

    }else{
      this.formValues ={
        noOfCopy:this._barcodeLabelServices.activeItem?.noOfCopy || '',
        locationId:+this._barcodeLabelServices.activeItem?.locationId || '',
        location:+this._barcodeLabelServices.activeItem?.location || '',
      }
      this.activeTemplateId = this._barcodeLabelServices.activeItem?.templateId
    }


    this.editform = this._fb.group({
     location: [{value: this.singleLocationList.filter(x => x.id == this.formValues.locationId)[0], disabled: this.isViewOnly}, Validators.required],
     locations: [{value: this.multiLocationList.filter((x)=>x.id == this.formValues.location)[0], disabled: this.isViewOnly}, Validators.required],
     noOfCopy: [{value: this.formValues.noOfCopy, disabled: this.isViewOnly},[Validators.required,Validators.maxLength(6),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
    })
    if(!this.isProductLabel){
       this.customform = this._fb.group({
      productName: [{value: this.formValues.productName, disabled: this.isViewOnly}, Validators.required],
      barcode: [{value: this.formValues.barcode, disabled: this.isViewOnly}, [Validators.required,Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      sku: [{value: this.formValues.sku, disabled: this.isViewOnly},[ Validators.required,Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      business: [{value: this.formValues.business, disabled: this.isViewOnly},Validators.required],
      // location: [{value: this.singleLocationList.filter(x => x.id == this.formValues.locationId)[0] , disabled: this.isViewOnly},Validators.required],
      price: [{value: this.formValues.price, disabled: this.isViewOnly},[Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/) ]],
    })
    }

 }
  removeItem(value){
    this.dataList = this.dataList.filter((v)=>v.productId !== value.productId)
  }
  checkValidity(){
   if(this.isProductLabel){
       if(this.editform.valid && this.dataList.length > 0){
       return  false
       } 
   }
   else if(!this.isProductLabel){
     if(this.customform.valid && this.editform.valid){
      return false
     }
    }
    return true
}
  searchButtonClick(e) {
    if (this._barcodeLabelServices.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
  }
  performSearch(e) {
    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 1) this.op.toggle(e)
          else {
            this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
            this.op.hide()
          }
      }
    })
  }
  onRowSelect(e) {
    this.op.hide()
    this.insertRow(e.data)
  }
  insertRow(e) {
    if(this.isProductLabel && !this.isNew){
      this.dataList = []
    }
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }
    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomId = uom.id
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.itemSkuId
    data.selected = false

    this.op.hide()

    this.dataList.push(data)
  }

  saveFn(){
    // this._abd.showSpinner();
 let objList =[]  
 let obj
 let date =formatDate(new Date(), 'yyyy-MM-dd', 'en-US');  
//   From List Add Label/From Product List

if(this.isProductLabel && this.isNew){
  this.f.locations.value.map((loc)=>{
    let list = this.dataList.map((v)=>{
      // if(!this.fromList){
     let locId = this.f.location.value.locationId
      let location = this._abd.locations.filter((val)=>val.locationId === locId)[0]
   let address = location.locationName+", "+ location.address+","+location.city+", "+location.state+", "+location.zip
   
    return {
          ProductId: v.productId,
          ProductName: v.productName,
          Barcode: v.barcode,
          Sku: v.skuCode,
          Location: loc.id,
          LocationName: address,
          LocationId: this.f.location.value.id,
          Business: this._globals.userInfo?.businessSetup[0].businessDisplayName,
          Price: v.salePriceA,
          NoOfCopy: this.f.noOfCopy.value,
          TemplateId: this.activeTemplateId,
          iscustom:0,
          Status:'0',
        }
      // }else{
      //   return {
      //   ProductId: v.productId,
      //   ProductName: v.productName,
      //   Barcode: v.barcode,
      //   Sku: v.skuCode,
      //   Location: loc.id,
      //   Price: v.salePriceA,
      //   LocationName: v.locationName,
      //   LocationId: v.locationId,
      //   NoOfCopy: this.f.noOfCopy.value,
      //   TemplateId: this.activeTemplateId,
      //   iscustom:0,
      //   Status:'0',
      // }
      // }

      
     })
    objList.push(...list)
   }) 
    this._barcodeLabelServices.saveBarcodeLabel(objList).subscribe(res => {
    if(res.success){
      this.cancelButtonClick();
      this.onSubmit.emit(true)
      this._abd.hideSpinner();
      this._connMessageService.showToastMessage('Product Label Printing saved successfully', 'success');
    } 
    else {
      this._connMessageService.showToastMessage(`Product Label Printing could not be saved.`, 'error');
      this._abd.hideSpinner()
    }
  },(error)=>{
    this._abd.hideSpinner()
  })
  }
  else if(!this.isProductLabel && this.isNew){
    // Add Custom Label
   objList = this.f.locations.value.map((loc)=>{
    let locId = this.f.location.value.locationId
      let location = this._abd.locations.filter((val)=>val.locationId === locId)[0]
   let address = location.locationName+", "+ location.address+","+location.city+", "+location.state+", "+location.zip
             return {
          ProductName: this.cf.productName.value,
          Barcode: this.cf.barcode.value,
          Sku: this.cf.sku.value,
          Location: loc.id,
          Business: this.cf.business.value,
          Price: +this.cf.price.value,
          LocationName: address,
          LocationId: this.f.location.value.locationId,
          NoOfCopy: +this.f.noOfCopy.value,
          TemplateId: this.activeTemplateId,
          iscustom:1,
          Status:'0',
        }
      })
       this._barcodeLabelServices.saveBarcodeLabel(objList).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Custom Label Print updated successfully', 'success');
          this._abd.hideSpinner();
        } 
        else {
          this._connMessageService.showToastMessage(`Custom Label Print could not be updated.`, 'error');
          this._abd.hideSpinner()
        }
      },(error)=>{
        this._abd.hideSpinner()
      })
      }
      
      // Update
  // else if(this.isProductLabel && !this.isNew){
  //   // Form List Edit Label 
  //   obj ={
  //       ProductId:this.dataList[0].productId ,
  //       ProductName: this.dataList[0].productName,
  //       Barcode: this.dataList[0].barcode,
  //       Sku: this.dataList[0].skuCode,
  //       Location: this.f.locations.value.locationId,
  //       Price: this.dataList[0].salePriceA,
  //       LocationName:  "",
  //       LocationId: "",
  //       Business: this._globals.userInfo?.businessSetup[0].businessDisplayName,
  //       NoOfCopy: this.f.noOfCopy.value,
  //       TemplateId: this.activeTemplateId,
  //       LastModified:date,
  //       iscustom:0,
  //       Status:'0',
  //     }
  //    this._barcodeLabelServices.updateBarcodeLabel(obj,this._barcodeLabelServices.activeItem.dbid).subscribe(res => {
  //     if(res.success){
  //       this.cancelButtonClick();
  //       this._abd.hideSpinner();
  //       this.onSubmit.emit(true)
  //       this._connMessageService.showToastMessage('Product Label Print updated successfully', 'success');
  //     } 
  //     else {
  //       this._connMessageService.showToastMessage(`Product Label Print could not be updated.`, 'error');
  //       this._abd.hideSpinner()
  //     }
  //   },(error)=>{
  //     this._abd.hideSpinner()
  //   })
  //   }

      //   else if(!this.isProductLabel && !this.isNew){
    // // Edit Custom Label
    // obj =
    //  {
    //     ProductName: this.cf.productName.value,
    //     Barcode: this.cf.barcode.value,
    //     Sku: this.cf.sku.value,
    //     Location: this.f.locations.value.locationId,
    //     Business: this.cf.business.value,
    //     Price: +this.cf.price.value,
    //     LocationName: this.cf.location.value.locationName,
    //     LocationId: this.cf.location.value.locationId,
    //     NoOfCopy: +this.f.noOfCopy.value,
    //     TemplateId: this.activeTemplateId,
    //     LastModified:date,
    //     iscustom:1,
    //     Status:'0',
    //   }
      
    //      this._barcodeLabelServices.updateBarcodeLabel(obj,this._barcodeLabelServices.activeItem.dbid).subscribe(res => {
    //       if(res.success){
    //         this.cancelButtonClick();
    //         this.onSubmit.emit(true)
    //         this._abd.hideSpinner();
    //         this._connMessageService.showToastMessage('Label Print updated successfully', 'success');
    //       } 
    //       else {
    //         this._connMessageService.showToastMessage(`Label Print could not be updated.`, 'error');
    //         this._abd.hideSpinner()
    //       }
    //     },(error)=>{
    //       this._abd.hideSpinner()
    //     })
    //     }

 }

  cancelButtonClick(){
    this._barcodeLabelServices.activeItem = ''
    this._barcodeLabelServices.showEditBarcodeLabel = false;
    this._barcodeLabelServices.showNewEditBarcodeLabel = false;
    this._barcodeLabelServices.showEditCustomBarcodeLabel = false;
    this._barcodeLabelServices.showNewEditCustomBarcodeLabel = false;
    this._barcodeLabelServices.productListPrintBarcodeLabel = false
    this._barcodeLabelServices.productDetailPrintBarcodeLabel= false
  }
  
} 