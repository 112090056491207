import { ManufacturersService } from 'src/app/_services/manufacturers.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { GlobalService } from 'src/app/_services/global.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ReportsService } from 'src/app/_services/reports.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { BaseService } from 'src/app/_services/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inventory-manufacturer',
  templateUrl: './inventory-manufacturer.component.html',
  styleUrls: ['./inventory-manufacturer.component.scss']
})
export class InventoryManufacturerComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  @ViewChild('p', { static: false }) paginator: Paginator

  first = 0
  page = 0
  rows = 10
  totalRecords = 0

  searchText = ''
  lastSearchText = ''
  dataList: any = null
  selectAll = false

  selectedRow: any = ''

  //order
  orderParam = ''
  orderBy = ''

  colheader = [[
    'Manufacturer', 'Status'
  ]];

  colflds = [
    'manufacturerName', 'isActive'
  ];

  csvcols = []
  exportColumns: any = ''

  actions: MenuItem[] = [
    {
      label: 'Edit', icon: 'pi pi-book', command: () => {
        this.update();
      }
    },
    {
      label: 'Delete', icon: 'pi pi-trash', command: () => {
        this.delete();
      }
    },
  ]

  constructor(
    private _bs: BaseService,
    public _reportService: ReportsService,
    public _manufacturerService: ManufacturersService,
    public _spinner: NgxSpinnerService,
    public _connMessageService: ConnMessageService,
    public _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      //--
      this._globals.componentName = 'Manufacturer'
      this._globals.showProductDelButton = false

      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }

      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.searchText) {
          this._filterService.searchText = filter.searchText
          this.searchText = filter.search
        }
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }

      this.getDataList()
      //--
      clearInterval(ti)
    }, 200);
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  makeParams() {
    this.searchText = this._filterService.searchText
    let params = new HttpParams();

    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());
    if (this.searchText.length) params = params.append('filterValue', this.searchText);
    return params
  }


  getDataList() {
    this.searchText = this._filterService.searchText
    const outfilter = {
      searchText: this.searchText ? this.searchText : '',
    }

    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    let qryParas = null
    if (this.orderParam && this.orderParam.length > 0) {
      qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`
      this._filterService.updateOrderby(this._globals.componentName, { orderParam: this.orderParam, orderBy: this.orderBy })
    }

    this._abd.showSpinner()
    let url = environment.InventoryBaseURL + environment.GetManufacturer
    if (qryParas) url += qryParas

    this._bs.httpGetParams(url, this.makeParams()).subscribe(res => {
      if (res.success) {
        this._abd.hideSpinner()
        this.totalRecords = res.totalRecords
        this.dataList = res.data

        for (let i = 0; i < this.dataList.length; i++)
          this.dataList[i].selected = false
      } else { this._abd.hideSpinner() }
    })
  }

  addNewFn(e) {
    if (e !== 'manufacturer') return
    this._manufacturerService.showNewNewEdit = true
  }

  refreshFn(e) {
    this.getDataList()
  }

  update() { }

  delete() { }

  actionButtonClick(item: any) {
    //
    this._manufacturerService.activeItem = JSON.parse(JSON.stringify(item))
    // //console.log(item)
  }

  optionEditClicked() {
    this._manufacturerService.isViewOnly = false
    this._manufacturerService.showNewEdit = true
    this.op.hide()
  }

  clearSearchText() {
    this.searchText = ''
    this._filterService.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  searchTextSearch() {
    // if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return

    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

  implementLocalFilter() {
    this.page = 0
    this.getDataList()
  }

  filterButtonPressed() {
    this.page = 0
    // this.paginator.changePage(0)
    // this.getDataList()
    this.implementLocalFilter()
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page

    this.getDataList()
  }

  uncheckAll() {
    this._globals.showProductDelButton = false
    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].selected = false
    }
  }

  selectAllChanged(event) {
    for (let i = 0; i < this.dataList.length; i++)
      this.dataList[i].selected = event.checked

    this._globals.showProductDelButton = event.checked
  }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {
        ret = true
        break
      }


    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) this.selectAll = true
      else this.selectAll = false
    }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) this.selectAll = true
      else this.selectAll = false
    }

    this._globals.showProductDelButton = ret
  }

  commaSeperatedIds(): string {
    let selectedItems: string = "";
    let z = this.dataList.filter(x => x.selected)
    z.forEach(x => { selectedItems += x.id + "," })
    selectedItems = selectedItems.slice(0, -1);
    return selectedItems
  }

  deleteMultipleItem() {
    if (this.dataList.length < 1) {
      this._connMessageService.showToastMessage(`Nothing to delete!`, 'error')
      return
    }

    let marked = this.dataList.filter(f => f.selected)
    let markedIds = this.commaSeperatedIds();
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._manufacturerService.deleteManufacturer(markedIds).subscribe(res => {
            this._abd.hideSpinner()
            if (res.success) {
              this._globals.showProductDelButton = false
              this.refreshMotherData()
              this.getDataList()
              this._connMessageService.showToastMessage(`Selected Manufacturer deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  refreshMotherData() {
    this.searchText = ''
    this.page = 0
    this._abd.getAllManufacturers()
  }

  deleteItem() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._manufacturerService.deleteManufacturer(this._manufacturerService.activeItem.id).subscribe(res => {

            if (res.success) {
              this._globals.showProductDelButton = false
              this.getDataList()
              this.refreshMotherData()
              this._abd.hideSpinner()
              this._connMessageService.showToastMessage(`Selected Manufacturer deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  callbackFuncFromFilter(event) {
    this.implementLocalFilter()
  }

  exportExcel() {
    let data = [];
    this._abd.manufacturer$.subscribe(res => {
      data = res;
    })

    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Manufacturer List')
  }

  exportPdf() {
    let data = [];
    this._abd.manufacturer$.subscribe(res => {
      data = res;
    })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Manufacturer List')
  }

  onRowSelect(e) {
    try {
      const el = e.originalEvent.srcElement.className
      if (el && el.includes('p-checkbox')) return
    } catch { }

    this._manufacturerService.activeItem = JSON.parse(JSON.stringify(e.data))
    this._manufacturerService.isViewOnly = true
    this._manufacturerService.showNewEdit = true
  }

  onRowUnselect(e) { }

  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'manufacturerName':
        this.orderParam = 'manufacturerName'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }

}
