<div class="grid gap-4" style="margin-top: 50px;">
    <div class="col-12">
        <div class="logo-wizard">
            <div class="flex align-items-center justify-content-center" style="margin-top: 1rem; margin-bottom: 1rem">
                <img [src]="_globals.whiteLabel.logo" alt="logo" width="150px" />
            </div>
        </div>
    </div>
    <!-- <div class="conn-card col-3">
        <div class="grid" *ngFor="let item of mitems">
            <div class="col-fixed my-3" style="width:60px;">
                <div class="flex justify-content-start flex-wrap card-container blue-container">
                    <div class="flex align-items-center justify-content-center w-3rem h-3rem n-setup-number"
                        [ngClass]="item.id==currentStep?'biz-setup-number-active':'n-setup-number'">
                        <span *ngIf="!item.actionSaved">{{item.id}}</span>
                        <span *ngIf="item.actionSaved">
                            <i class="pi pi-check"
                                style="font-size: 1.2em; font-weight: 700;"></i></span>
                    </div>
                </div>
            </div>
            <div class="col my-3">
                <p [ngClass]="item.id==currentStep ? 'biz-setup-menu-header-active':'biz-setup-menu-header'"><span
                        [ngClass]="item.id==currentStep?'wizard-title-active':'wizard-title'">{{item.title}}</span></p>
                <p class="biz-setup-menu-detail text-muted">{{item.desc}}</p>
            </div>
        </div>
    </div> -->
    <!-- <div class="grid">
            <div class="col-3 align-self-center">
                <span id="biz-setup-number">1</span>
            </div>
            <div class="col-8 my-4">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    General Info
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        ngbTooltip="Billing is issued based on your selected account type"
                    ></i>
                </h3>

                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Set some generic info about your business
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-3 align-self-center">
                <span id="biz-setup-number">2</span>
            </div>
            <div class="col-8 my-4">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    Location Setup
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        ngbTooltip="Billing is issued based on your selected account type"
                    ></i>
                </h3>

                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Manage different locations of your business
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-3 align-self-center">
                <span id="biz-setup-number">3</span>
            </div>
            <div class="col-8 my-4">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    User Setup
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        ngbTooltip="Billing is issued based on your selected account type"
                    ></i>
                </h3>

                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Manage different users of your business
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-3 align-self-center">
                <span id="biz-setup-number">4</span>
            </div>
            <div class="col-8 my-4">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    Configuration
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        ngbTooltip="Billing is issued based on your selected account type"
                    ></i>
                </h3>

                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Set some basic configurations according to your business
                </div>
            </div>
        </div> -->
    <!-- </div> -->

    <div class="conn-card col" style="margin-right:30px;">
        <!-- <app-general-information *ngIf="activeTab == 'general-info-1'"></app-general-information> -->
        <!-- <app-location-information *ngIf="activeTab == 'location-setup'"></app-location-information> -->
        <!-- <app-user-information *ngIf="activeTab == 'user-setup'"></app-user-information> -->
        <app-configuration-information ></app-configuration-information>
        <!-- <app-final-step *ngIf="activeTab == 'final-step'"></app-final-step> -->


        <div class="flex flex-nowrap card-container mb-12 justify-content-end" >
            <!-- <div class="flex align-items-center border-round mx-2" *ngIf="showBackButton">
                <button pButton type="button" class="light-button" label="Back" icon="pi pi-arrow-left"
                    (click)="back()" iconPos="left"
                    [style]="{
                        'margin-top': '30px',
                        'min-width': '100%',
                        width: '150px',
                        height: '45px'
                    }"></button>
            </div> -->
            <div class="flex align-items-center border-round mx-2">
                <button pButton type="button" [label]="'Finish'"
                     (click)="saveFn()"  [style]="{
                        'margin-top': '30px',
                        'min-width': '100%',
                        width: '200px',
                        height: '45px'
                    }" ></button>
            </div>
        </div>
    </div>
</div>
