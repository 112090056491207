<div class="margin">
    <form [formGroup]="ownersDetailForm" (ngSubmit)="saveOwner()" autocomplete="off">
        <div class="p-fluid p-formgrid grid">
            <div class="grid">
                <div class="col-12 md:col-6 field">
                    <label>First Name <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="firstName" class="p-inputtext-lg w-full mt-1" pInputText
                        required />

                    <div *ngIf="
                            (!ownersDetailForm.get('firstName')?.valid && (ownersDetailForm.get('firstName')?.dirty
                            || ownersDetailForm.get('firstName')?.touched))
                        ">

                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('firstName').value
                                    ?.length == 0 &&
                                !ownersDetailForm.get('firstName').errors
                                    .required
                            ">
                            Please enter a First Name
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Last Name <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="lastName" class="p-inputtext-lg w-full mt-1" pInputText
                        required />
                    <div *ngIf="
                            (!ownersDetailForm.get('lastName')?.valid &&
                                (ownersDetailForm.get('lastName')?.dirty ||
                                    ownersDetailForm.get('lastName')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('lastName').value
                                    ?.length == 0 &&
                                !ownersDetailForm.get('lastName').errors
                                    .required
                            ">
                            Please enter a Last Name
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Title <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="title" class="p-inputtext-lg w-full my-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Your title in an organization i.e. Manager,
                        CEO,President</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('title')?.valid &&
                                (ownersDetailForm.get('title')?.dirty ||
                                    ownersDetailForm.get('title')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('title').value?.length ==
                                    0 &&
                                !ownersDetailForm.get('title').errors.required
                            ">
                            Please enter a Title
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label for="dob">Date of Birth
                        <span class="text-red-500">*</span></label>
                    <div class="p-inputgroup align-items-center">
                        <span class="p-inputgroup-addon" style="height: 40px">
                            <img src="../../../../assets/images/Calender.svg" alt="Calender Icon" />
                        </span>
                        <span class="p-float-label">
                            <!-- <p-calendar  [showIcon]="true" inputId="icon"></p-calendar> -->
                            <p-calendar [(ngModel)]="dob" selectionMode="single" inputId="dob" formControlName="dob"
                                styleClass="my-1" (onBlur)="validateDate(1, -1)" required>
                            </p-calendar>
                        </span>
                    </div>
                    <small id="username1-help" class="block text-muted">Your date of birth here</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Social Security Number
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="ssn" class="p-inputtext-lg w-full my-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Your social security number here</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('ssn')?.valid &&
                                (ownersDetailForm.get('ssn')?.dirty ||
                                    ownersDetailForm.get('ssn')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('ssn').value?.length ==
                                    0 &&
                                !ownersDetailForm.get('ssn').errors.required
                            ">
                            Please enter a Social Security Number
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Owner Percentage (%)
                        <span class="text-red-500">*</span></label>
                    <p-inputNumber formControlName="ownerPercentage" [min]="1" class="p-inputtext-lg w-full my-1"
                        [max]="100" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" required>
                    </p-inputNumber>
                    <div *ngIf="
                            !ownersDetailForm.get('ownerPercentage')?.valid ||
                            ownersDetailForm.get('ownerPercentage').value == 0
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('ownerPercentage').value
                                    ?.length == 0 &&
                                !ownersDetailForm.get('ownerPercentage').errors
                                    .required
                            ">
                            Please enter a Owner Percentage
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Phone<span class="text-red-500">*</span></label>
                    <div class="p-inputgroup align-items-center">
                        <span class="p-inputgroup-addon">
                            <img _ngcontent-ewi-c77="" src="assets/images/us-flag-icon.png" alt="USA Flag Icon"
                                width="19" />
                        </span>
                        <span class="p-float-label">
                            <p-inputMask mask="(999) 999-9999" formControlName="ownerPhone" class="p-inputtext-lg my-1"
                                styleClass="w-full" required>
                            </p-inputMask>
                        </span>
                    </div>
                    <small id="username1-help" class="block text-muted">Your home phone number</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('ownerPhone')?.valid && (ownersDetailForm.get('ownerPhone')?.dirty
                             || ownersDetailForm.get('ownerPhone')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('ownerPhone').value
                                    ?.length == 0 &&
                                !ownersDetailForm.get('ownerPhone').errors
                                    .required
                            ">
                            Please enter a 10 digits valid phone number!
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Role <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="role" pInputText class="p-inputtext-lg w-full mb-1" pInputText
                        required />
                    <small id="username1-help" class="block text-muted">Owner or Signer (1 = Owner, 2=Non Owner but
                        Authorized
                        Signer, 3=Owner and Authorized Signer)</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('role')?.valid &&
                                (ownersDetailForm.get('role')?.dirty ||
                                    ownersDetailForm.get('role')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('role').value?.length ==
                                    0 &&
                                !ownersDetailForm.get('role').errors.required
                            ">
                            Please enter a Role
                            <i class="fas fa-critical-role"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-12 field">
                    <label>Address <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="address" class="p-inputtext-lg w-full my-1" pInputText
                        required />
                    <small id="username1-help" class="block text-muted">Your home address here</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('address')?.valid &&
                                (ownersDetailForm.get('address')?.dirty ||
                                    ownersDetailForm.get('address')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('address').value?.length ==
                                    0 &&
                                !ownersDetailForm.get('address').errors.required
                            ">
                            Please enter a Address
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label>Zip Code <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="zipCode" class="p-inputtext-lg w-full my-1" pInputText required
                        (keyup)="getZipCode()" />
                    <small id="username1-help" class="block text-muted">Your home address zip code here</small>
                    <div *ngIf="
                                (!ownersDetailForm.get('zipCode')?.valid &&
                                    (ownersDetailForm.get('zipCode')?.dirty ||
                                        ownersDetailForm.get('zipCode')
                                            ?.touched))
                            ">
                        <div class="input-required mt-1" [hidden]="
                                    ownersDetailForm.get('zipCode').value
                                        ?.length == 0 &&
                                    !ownersDetailForm.get('zipCode').errors
                                        .required
                                ">
                            Please enter a zip code
                        </div>
                    </div>
                    <div *ngIf="showOwnerZipCodeLoading">
                        <i class="pi pi-spin pi-spinner"></i>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label>City <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="city" class="p-inputtext-lg w-full mt-1" pInputText required />
                    <div *ngIf="
                                (!ownersDetailForm.get('city')?.valid &&
                                    ownersDetailForm.get('city')?.dirty) ||
                                (isSubmitted &&
                                    !ownersDetailForm.get('city').value?.length)
                            ">
                        <div class="input-required mt-1" [hidden]="
                                    ownersDetailForm.get('city').value
                                        ?.length == 0 &&
                                    !ownersDetailForm.get('city').errors
                                        .required
                                ">
                            Please enter a city
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label>State <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="usaStates" formControlName="state" optionLabel="name" styleClass="mt-1">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Issuer State <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="issueState" class="p-inputtext-lg w-full my-1" pInputText
                        required />
                    <small id="username1-help" class="block text-muted">The name of your state/city issuer</small>
                    <div *ngIf="
                            (!ownersDetailForm.get('issueState')?.valid && (ownersDetailForm.get('issueState')?.dirty
                            || ownersDetailForm.get('issueState')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                ownersDetailForm.get('issueState').value
                                    ?.length == 0 &&
                                !ownersDetailForm.get('issueState').errors
                                    .required
                            ">
                            Please enter a Issuer State
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label for="issueDate">Issued Date <span class="text-red-500">*</span></label>
                    <div class="p-inputgroup align-items-center">
                        <span class="p-inputgroup-addon" style="height: 40px">
                            <img src="../../../../assets/images/Calender.svg" alt="Calender Icon" />
                        </span>
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="dob1" selectionMode="single" inputId="issueDate"
                                formControlName="issueDate" styleClass="my-1" (onBlur)="validateDate(2, -1)" required>
                            </p-calendar>
                        </span>
                    </div>
                    <small id="username1-help" class="block text-muted">Issue date</small>
                </div>
            </div>
        </div>

        <!-- Certification of Beneficial Owner(s) Start -->
        <div class="col-12 md:col-12">
            <h4 class="mb-1">Certification of Beneficial Owner(s)</h4>
            <p style="font-size: 14px">
                Federal Beneficial Owner(s) regulations require information for
                each individual who owns 25% or more of the legal entity or an
                individual responsible for managing the legal entity e.g., Chief
                Executive Officer, Chief Financial Officer, Managing Member,
                General Partner, President, Vice President, Treasurer.
            </p>
            <div class="flex justify-content-center w-full" style="gap: 50px">
                <div *ngFor="let o of otherOwnersListArray" class="col-lg-3 owner-btn-unselected my-4"
                    (click)="setOtherOwnersLimit(o)" [ngClass]="{
                        'owner-btn-selected': o.selected,
                        'owner-btn-unselected': !o.selected
                    }">
                    <h6 class="fw-bold mb-1">{{o.title}}</h6>
                    <p class="text-muted">{{o.description}}</p>
                </div>

            </div>
        </div>
        <!-- Certification of Beneficial Owner(s) End -->

        <div class="col-12 md:col-12" formArrayName="otherOwners">
            <div class="mt-5" *ngFor="let obj of otherOwners.controls; let formIndex = index">
                <h4 *ngIf="formIndex < othersOwnersNumber">
                    {{ arrayTitle[formIndex] }} Details
                </h4>
                <div class="" [formGroup]="obj">
                    <div *ngIf="formIndex < othersOwnersNumber">
                        <div class="p-fluid p-formgrid grid">
                            <div class="col-12 md:col-6 field">
                                <label>First Name
                                    <span class="text-red-500">*</span></label>
                                <input type="text" class="p-inputtext-lg w-full mt-1" pInputText
                                    formControlName="firstNameControlName" name="firstNameControlName"
                                    [id]="firstNameControlName" required />

                                <div *ngIf="
                                        (!obj.get('firstNameControlName')
                                            ?.valid &&
                                            (obj.get('firstNameControlName')
                                                ?.dirty ||
                                                obj.get('firstNameControlName')
                                                    ?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('firstNameControlName')
                                                .value?.length == 0 &&
                                            !obj.get('firstNameControlName')
                                                .errors.required
                                        ">
                                        Please enter a First Name
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Last Name
                                    <span class="text-red-500">*</span></label>
                                <input type="text" formControlName="lastNameControlName" [id]="lastNameControlName"
                                    name="lastNameControlName" class="p-inputtext-lg w-full mt-1" pInputText required />
                                <div *ngIf="
                                        (!obj.get('lastNameControlName')
                                            ?.valid &&
                                            (obj.get('lastNameControlName')
                                                ?.dirty ||
                                                obj.get('lastNameControlName')
                                                    ?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('lastNameControlName').value
                                                ?.length == 0 &&
                                            !obj.get('lastNameControlName')
                                                .errors.required
                                        ">
                                        Please enter a Last Name
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Title
                                    <span class="text-red-500">*</span></label>
                                <input type="text" class="p-inputtext-lg w-full my-1" pInputText
                                    formControlName="roleControlName" [id]="roleControlName" name="roleControlName"
                                    required />
                                <small id="username1-help" class="block text-muted">
                                    {{ arrayTitle[formIndex] }} title in an
                                    organization i.e. Manager, CEO, President
                                </small>

                                <div *ngIf="
                                        (!obj.get('roleControlName')?.valid &&
                                            (obj.get('roleControlName')
                                                ?.dirty ||
                                                obj.get('roleControlName')
                                                    ?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('roleControlName').value
                                                ?.length == 0 &&
                                            !obj.get('roleControlName').errors
                                                .required
                                        ">
                                        Please enter a Title
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Date of Birth
                                    <span class="text-red-500">*</span></label>
                                <div class="p-inputgroup align-items-center">
                                    <span class="p-inputgroup-addon" style="height: 40px">
                                        <img src="../../../../assets/images/Calender.svg" alt="Calender Icon" />
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 0">
                                        <p-calendar [(ngModel)]="dob01" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}">
                                        </p-calendar>
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 1">
                                        <p-calendar [(ngModel)]="dob02" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}"
                                            (onBlur)="validateDate(3, formIndex)">
                                        </p-calendar>
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 2">
                                        <p-calendar [(ngModel)]="dob03" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}"
                                            (onBlur)="validateDate(3, formIndex)">
                                        </p-calendar>
                                    </span>
                                </div>
                                <small id="username1-help" class="block text-muted">Your date of birth here</small>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Social Security Number
                                    <span class="text-red-500">*</span></label>
                                <input type="text" formControlName="ssnControlName" [id]="ssn" name="ssn"
                                    class="p-inputtext-lg w-full my-1" pInputText required />
                                <small id="username1-help" class="block text-muted">Social security number here</small>
                                <div *ngIf="
                                        (!obj.get('ssnControlName')?.valid && (obj.get('ssnControlName')?.dirty
                                        || obj.get('ssnControlName')?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('ssnControlName').value
                                                ?.length == 0 &&
                                            !obj.get('ssnControlName').errors
                                                .required
                                        ">
                                        Please enter a Social Security Number
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Ownership Percentage (%)</label>
                                <p-inputNumber formControlName="ownerPercentageControlName" [min]="1" [max]="100"
                                    mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                                    class="p-inputtext-lg w-full mb-1 mt-1" required>
                                </p-inputNumber>
                                <small id="username1-help" class="block text-muted">Percentage of the company owned by
                                    owner</small>
                                <div *ngIf="
                                        (!ownersDetailForm.get('ownerPercentageControlName')?.valid &&
                                        (ownersDetailForm.get('ownerPercentageControlName')?.dirty ||
                                        ownersDetailForm.get('ownerPercentageControlName')?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get(
                                                'ownerPercentageControlName'
                                            ).value?.length == 0 &&
                                            !obj.get(
                                                'ownerPercentageControlName'
                                            ).errors.required
                                        ">
                                        Please enter a Ownership Percentage
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 field">
                                <label>Phone<span class="text-red-500">*</span></label>
                                <div class="p-inputgroup align-items-center">
                                    <span class="p-inputgroup-addon">
                                        <img _ngcontent-ewi-c77="" src="assets/images/us-flag-icon.png"
                                            alt="USA Flag Icon" width="19" />
                                    </span>
                                    <span class="p-float-label">
                                        <p-inputMask mask="(999) 999-9999" formControlName="ownerPhoneControlName"
                                            class="p-inputtext-lg my-1" styleClass="w-full" required>
                                        </p-inputMask>
                                    </span>
                                </div>
                                <small id="username1-help" class="block text-muted">Your home phone number</small>
                                <div *ngIf="
                                        (!obj.get('ownerPhoneControlName')?.valid &&
                                            (obj.get('ownerPhoneControlName')?.dirty ||
                                                obj.get('ownerPhoneControlName')?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('ownerPhoneControlName').value?.length == 0 &&
                                            !obj.get('ownerPhoneControlName').errors.required
                                        ">
                                        Please enter a 10 digits valid phone
                                        number!
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-12 field">
                                <label>Address
                                    <span class="text-red-500">*</span></label>
                                <input type="text" formControlName="addressControlName"
                                    class="p-inputtext-lg w-full my-1" pInputText required />
                                <small id="username1-help" class="block text-muted">Home address
                                </small>
                                <div *ngIf="
                                        (!obj.get('addressControlName')?.valid &&
                                            (obj.get('addressControlName')?.dirty ||
                                                obj.get('addressControlName')?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('addressControlName').value?.length == 0 &&
                                            !obj.get('addressControlName').errors.required
                                        ">
                                        Please enter a Address
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 field">
                                <label>Zip Code
                                    <span class="text-red-500">*</span></label>
                                <input type="text" formControlName="zipCodeControlName"
                                    class="p-inputtext-lg w-full my-1" (keyup)="ownersZipFiled(formIndex)" required
                                    pInputText />
                                <small id="username1-help" class="block text-muted">Home address zip code here</small>
                                <div *ngIf="
                                        (!obj.get('zipCodeControlName')?.valid &&
                                            (obj.get('zipCodeControlName')?.dirty ||
                                                obj.get('zipCodeControlName')
                                                    ?.touched))
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('zipCodeControlName').value?.length == 0 &&
                                            !obj.get('zipCodeControlName').errors.required
                                        ">
                                        Please enter a zip code
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        ownersDetailForm.controls.otherOwners['controls'][formIndex].controls.showOwnerZipCodeLoading.value =='1'">
                                    <i class="pi pi-spin pi-spinner"></i>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 field">
                                <label>City
                                    <span class="text-red-500">*</span></label>
                                <input type="text" formControlName="cityControlName" required
                                    class="p-inputtext-lg w-full mt-1" pInputText />
                                <div *ngIf="
                                        (!ownersDetailForm.get('city')?.valid &&ownersDetailForm.get('city')?.dirty) ||
                                        (isSubmitted && !ownersDetailForm.get('city').value?.length)
                                    ">
                                    <div class="input-required mt-1" [hidden]="
                                            obj.get('cityControlName').value?.length == 0 &&
                                            !obj.get('cityControlName').errors.required
                                        ">
                                        Please enter a city
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 field">
                                <label>State
                                    <span class="text-red-500">*</span></label>
                                <p-dropdown [options]="usaStates" formControlName="stateControlName" optionLabel="name"
                                    styleClass="my-1">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid mt-5 justify-content-end">

            <p-button type="submit" [disabled]="((!ownersDetailForm.valid) || !otherOwnerFormSelected)" label="Continue"
                icon="pi pi-arrow-right"
                iconPos="right" styleClass="continue-btn">
            </p-button>
        </div>
    </form>
</div>
