<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'tiered-reward-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th  *ngFor="let col of domColHeader">
                    <strong>{{ col }}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td >
                    {{ product.date | date:'MM/dd/yyyy'}}
                </td>
                <td >
                    {{ product.perkName }}
                </td>
                <td >
                    {{ product.accumulatedValue | currency  }}
                </td>
                <td >
                    {{ product.qtyOfRedemptions}}
                </td>
                <!-- <td >
                    {{ product.locationName }}
                </td>
                <td >
                    {{ product.redeemPoints }}
                </td> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="0" >
                    <strong>Total</strong>
                </td>

                <td
                    *ngFor="let col of domSummaryflds"

                >
                    {{ calculateSummaryTotal(col) }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
