export interface Perk{
    displayName: string;
    pointsGoal: number;
    rewardType: string;
    active: boolean;
    redeemed: number;
}

export interface PerksReward{
    loyaltyConfigId: string;
    displayName: string;
    active: boolean;
    rewardType: string;
    pointsGoal: number;
    discountAmount: number;
    perkProducts: PerkProduct[];
    maxDiscount: number;
    id: string;
}

export interface PerkProduct {
    itemSKUId: string;
    qty: number;
    productName:string;
    productCode: string;
    priceA: number;
    perksRewardId: string;
    id: string;
    cost: number;

}

export enum PerksRewardTypes {
    FlatDiscount = 'FlatDiscount',
    PercentageDiscount = 'PercentageDiscount',
    FreeItems = 'FreeItems'
}

export enum Layout {
    Grid,
    List
}

export enum RewardTypes {
    Regular,
    Tiered
}

export interface SaveRegularReward{
    minRedeemPPoints: number;
    eachPointDiscount: number;
}
