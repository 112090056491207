<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'category-sales-report'"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed($event)"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols"  [scrollable]="true" styleClass="p-datatable-striped" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center" *ngFor="let col of domColHeader">
                    <strong>{{ col }}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td style="text-align: center">{{ product.groupValue }}</td>
                <td style="text-align: center">
                    {{ product.totalSales | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.invoiceCount | number }}
                </td>
                <td style="text-align: center">
                    {{ product.totalProfit | currency }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>

                <td
                    *ngFor="let col of domSummaryflds"
                    style="text-align: center"
                >
                    {{ calculateSummaryTotal(col) }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
