import { GlobalService } from './../../_services/global.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BizzCommonService {
_globals = GlobalService

  constructor() { }

  makeHttpHeader(auth: boolean = true) {
    const token = this._globals.getUserToken()
    if (token) {
      return new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      })
    } else {
      return null
    }
  }
}
