import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-cash-drawer-report',
  templateUrl: './cash-drawer-report.component.html',
  styleUrls: ['./cash-drawer-report.component.scss']
})
export class CashDrawerReportComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  nosaleList: any = null
  cashList: any = null
  currentList: any = null
  otherList: any = null

  _globals = GlobalService

  constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._globals.componentName = 'Cash Drawer Report'
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  makeCashierList() {
    let arr = []
    let obj = this.nosaleList
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }

    obj = this.nosaleList
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }

    this._abd.cashiers = arr
  }

  getDataList() {
    const url = environment.InventoryBaseURL + environment.Report

    this._spinner.show()
    this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
      if (res.success) {
        const dt = res.data
        this.nosaleList = dt.noSale
        this.cashList = dt.resetCashDrawer
        this.currentList = dt.currentCashCount
        this.otherList = dt.otherTransactions
      }
      this._spinner.hide()
    })
  }

  getParas() {
    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
    }

    const page = this.page < 0 ? 0 : this.page
    const rows = this.rows < 1 ? 10 : this.rows

    const data = {
      "ReportName": "CashDrawerReport",
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.getLocationsList(),
        "CashierIds": [],
        "filterValue": this._filterService.searchText
      }
    }
    return data
  }

  getLocationsList() {
    let locationIds = [];
    let sel = this._filterService.selectedLocation
    if (!sel) return locationIds

    sel = (typeof sel != 'undefined' && sel instanceof Array) ? sel : [sel];
    sel.map(function (value: { locationId: { toString: () => string; }; }) {
      locationIds.push(value.locationId.toString())
    })
    return locationIds
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let header = ['Date/Time', 'Location', 'Register', 'User']
    const ws = []

    let xd = this.nosaleList
    let tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(formatDate(new Date(xd[j].date), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].locationName)
      inner.push(xd[j].registerName)
      inner.push(xd[j].cashierName)
      tmp.push(inner)
    }
    ws.push(tmp)

    header = ['Date/Time', 'Location', 'Register', 'User', 'Amount Adjusted']
    xd = this.cashList
    tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(formatDate(new Date(xd[j].date), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].locationName)
      inner.push(xd[j].registerName)
      inner.push(xd[j].user)
      inner.push(xd[j].user)
      tmp.push(inner)
    }
    ws.push(tmp)

    header = ['Location', 'Register', 'Amount']
    xd = this.currentList
    tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].locationName)
      inner.push(xd[j].registerName)
      inner.push('$ ' + xd[j].amount.toFixed(2))
      tmp.push(inner)
    }
    ws.push(tmp)

    header = ['Location', 'Register', 'Payin #', 'Payout #', 'Drops #', 'Count #', 'Cash Sale #', 'Cash Refund #', 'Till #']
    xd = this.otherList
    tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].locationName)
      inner.push(xd[j].registerName)
      inner.push('$ ' + xd[j].payIn.toFixed(2))
      inner.push('$ ' + xd[j].payOut.toFixed(2))
      inner.push('$ ' + xd[j].drops.toFixed(2))
      inner.push('$ ' + xd[j].count.toFixed(2))
      inner.push('$ ' + xd[j].cashSale.toFixed(2))
      inner.push('$ ' + xd[j].cashRefund.toFixed(2))
      inner.push('$ ' + xd[j].till.toFixed(2))
      tmp.push(inner)
    }
    ws.push(tmp)

    const workbook = {
      Sheets: {
        'No-Sale': XLSX.utils.aoa_to_sheet(ws[0]),
        'Cash Drawer Reset': XLSX.utils.aoa_to_sheet(ws[1]),
        'Current (Amount Cash Drawer)': XLSX.utils.aoa_to_sheet(ws[2]),
        'Other Transactions': XLSX.utils.aoa_to_sheet(ws[3]),
      },
      SheetNames: ['No-Sale', 'Cash Drawer Reset', 'Current (Amount Cash Drawer)', 'Other Transactions']
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filename = 'cash-drawer-summary-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
    FileSaver.saveAs(data, filename);
  }

  exportPdf() {
    let cols1 = [
      { dataKey: "date", title: "Date/Time", type: 'date' },
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "registerName", title: "Register", type: 'string' },
      { dataKey: "cashierName", title: "User", type: 'string' },
    ]
    let data1 = this._abd.parseForExport(this.nosaleList, cols1)

    let cols2 = [
      { dataKey: "date", title: "Date/Time", type: 'date' },
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "registerName", title: "Register", type: 'string' },
      { dataKey: "cashierName", title: "User", type: 'string' },
      { dataKey: "amount", title: "Amount Adjusted", type: 'string' },
    ]
    let data2 = this._abd.parseForExport(this.cashList, cols2)

    let cols3 = [
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "registerName", title: "Register", type: 'string' },
      { dataKey: "amount", title: "Amount", type: 'currency' },
    ]
    let data3 = this._abd.parseForExport(this.currentList, cols3)

    let cols4 = [
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "registerName", title: "Register", type: 'string' },
      { dataKey: "payIn", title: "Payin #", type: 'currency' },
      { dataKey: "payOut", title: "Payout #", type: 'currency' },
      { dataKey: "drops", title: "Drops #", type: 'currency' },
      { dataKey: "count", title: "Count #", type: 'currency' },
      { dataKey: "cashSale", title: "Cash Sale #", type: 'currency' },
      { dataKey: "cashRefund", title: "Cash Refund #", type: 'currency' },
      { dataKey: "till", title: "Till #", type: 'currency' },
    ]
    let data4 = this._abd.parseForExport(this.otherList, cols4)

    let doc = new jsPDF('l', 'mm', 'a3');
    doc.setFontSize(30)
    doc.setTextColor(40)
    doc.text("CASH DRAWER REPORT", 150, 20)

    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("No-Sale", 15, 30)
    doc['autoTable'](cols1, data1, { startY: 35 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Cash Drawer Reset", 15, 15)
    doc['autoTable'](cols2, data2, { startY: 20 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Current (Amount Cash Drawer)", 15, 15)
    doc['autoTable'](cols3, data3, { startY: 20 })

    doc.addPage();
    doc.setFontSize(25)
    doc.setTextColor(40)
    doc.text("Other Transactions", 15, 15)
    doc['autoTable'](cols4, data4, { startY: 20 })

    let filename = 'other-transactions-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
    doc.save(filename);
  }

}
