import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user/user';
import { take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  _globals = GlobalService
  constructor(private _userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url.toLocaleLowerCase()
    if(request.url.includes('authorize.net')) {
      return next.handle(request)
    }

    let currentUser: User;
    this._userService.currentUser$.pipe(take(1)).subscribe((user: User) => currentUser = user);
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          WLID: (this._globals.whiteLabel) ? this._globals.whiteLabel.id:'',
          Authorization: `Bearer ${currentUser.token}`
        }
      })
    } else {
      request = request.clone({
        setHeaders: {
          WLID: (this._globals.whiteLabel) ? this._globals.whiteLabel.id:'',
        }
      })
    }

    return next.handle(request);
  }
}
