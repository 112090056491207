import { Component, OnInit, ViewChild } from '@angular/core';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { GlobalService } from 'src/app/_services/global.service';
import { HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { CustomerInfoChangeComponent } from './customer-info-change/customer-info-change.component';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
    _globals = GlobalService;
    @ViewChild('op') op: OverlayPanel;
    page = 0;
    rows = 10;
    first = 0;
    totalRecords = 0;

    // searchText = ''
    lastSearchText = '';
    dataList: any = null;

    // colheader = [['ID', 'Last Transaction']];

    colHeader = this._abd.reports.filter(x => x.paraReportName == 'customer')[0].summaryHeader;
    colflds = this._abd.reports.filter(x => x.paraReportName == 'customer')[0].colflds;

    // colflds = [
    //     'custID',
    //     'lastTransaction',
    //     // userID?: string;
    //     // firstName: string;
    //     // lastName: string;
    //     // email: string;
    //     // username: string;
    //     // password: any;
    //     // companyName: string;
    //     // photo: any;
    //     // role: string[];
    //     // street?: any;
    //     // city: string;
    //     // state: string;
    //     // country: any;
    //     // postalCode: string;
    //     // phone: string;
    //     // parentID: string;
    //     // age: string;
    //     // gender: any;
    //     // // purchaseHistories?: purchaseHistory[];
    //     // formattedPhone: string;
    //     // fullName: string;
    //     // actions: string[];
    //     // status:boolean;
    // ];

    csvcols = [];
    exportColumns: any = '';

    actions: MenuItem[] = [
        // { label: 'Edit', icon: 'pi pi-book' },
        { label: 'Delete', icon: 'pi pi-trash' },
    ];
    @ViewChild('p', { static: false }) paginator: Paginator;
    @ViewChild('changecustomer', { static: false })
    changecustomer: CustomerInfoChangeComponent;

    constructor(
        public _customerService: CustomersService,
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService,
        private _confirmationService: ConfirmationService,
        public _abd: AppBaseDataService,
        private _router: Router,
        public _filterService: FilterServiceService
    ) {}

    ngOnInit(): void {
        // this.searchText = this._filterService.searchText
        this._globals.componentName = 'Customers';
        this._globals.showProductDelButton = false;
        try {
            this.getInitData();
        } catch (e) {
            //console.log(e);
        }
    }

    ngAfterViewInit() {
        if (this._customerService.lastSearchText)
            this._filterService.searchText =
                this._customerService.lastSearchText;

        //-- restore page number **begin
        const obj = this._abd.getOptionsFromLocalStorage(
            this._globals.componentName,
            'last-page'
        );
        if (!obj && obj !== 0) return;

        let lastpage = parseInt(obj);
        if (lastpage < 0) lastpage = 0;
        if (lastpage > 0) {
            this.page = lastpage;
            this.first = lastpage * this.rows;
            this.getDataList();
        } else {
            this.page = 0;
            this.first = 0;
            this.getDataList();
        }
        //-- restore page number **end
    }

    ngOnDestroy() {
        this._globals.componentName = '';
    }

    refreshData() {
        try {
        } catch {
            //console.log('ERROR-999')
        }
    }

    makeParams() {
        let params = new HttpParams();
        //console.log(this.searchText);
        this.page = this.page < 1 ? 0 : this.page;
        this.rows = this.rows < 1 ? 10 : this.rows;

        params = params.append('pageNo', this.page.toString());
        params = params.append('pageSize', this.rows.toString());
        params = params.append('type', 'all');
        if (this._filterService.searchText.length) {
            params = params.append(
                'filterValue',
                this._filterService.searchText.trim()
            );
            this._customerService.lastSearchText =
                this._filterService.searchText.trim();
        } else {
            this._customerService.lastSearchText = null;
        }

        return params;
    }

    getDataList() {
        this._abd.showSpinner();
        this._customerService
            .getAllCustomers(this.makeParams())
            .subscribe((res) => {
                if (res.success) {
                    this._abd.hideSpinner();
                    this.totalRecords = res.totalRecords;
                    this.dataList = res.data;
                    //console.log('customers', res.data)
                }
            });
    }
    getInitData() {
        this.getDataList();
    }

    update() {}

    delete() {
        alert();
    }

    clearSearchText() {
        // this.searchText = ''
        if (this.lastSearchText.length > 0) {
            this.lastSearchText = '';
            this.filterButtonPressed();
        }
    }

    searchTextSearch() {
        if (
            this._filterService.searchText.length < 1 &&
            this.lastSearchText.length < 1
        )
            return;
        if (this._filterService.searchText === this.lastSearchText) return;

        this.lastSearchText = this._filterService.searchText;
        this.filterButtonPressed();
    }

    implementLocalFilter() {}

    callbackFuncFromFilter(event) {
        this.page = 0;
        this.getDataList();
    }

    filterButtonPressed() {
        this.page = 0;
        this.implementLocalFilter();
    }

    onPageChange(e) {
        e.totalRecords = this.totalRecords;
        this.rows = e.rows;
        this.page = e.page;
        this._abd.setOptionToLocalStorage(
            this._globals.componentName,
            'last-page',
            e.page
        );

        this.getDataList();
    }

    selectAllChanged(event) {
        if (event.checked) {
            this._globals.showProductDelButton = true;
            for (let i = 0; i < this.dataList.length; i++) {
                this.dataList[i].selected = true;
            }
        } else {
            this._globals.showProductDelButton = false;
            for (let i = 0; i < this.dataList.length; i++) {
                this.dataList[i].selected = false;
            }
        }
    }

    selectionChanged(event) {
        if (event.selected) {
            for (let i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i].custID != event.custID)
                    this.dataList[i].selected = false;
            }
            this._customerService.activeItem = event;
        } else {
            this._customerService.activeItem = null;
        }
    }

    deleteMultipleItem() {}

    commaSeperatedIds(data: any): string {
        let selectedItems: string = '';
        let z = data.filter((x) => x.selected);
        z.forEach((x) => {
            selectedItems += x.id + ',';
        });
        selectedItems = selectedItems.slice(0, -1);
        return selectedItems;
    }

    optionDeleteClicked() {
        this.op.hide();
        let activate = 'Do you wish to activate this customer?'
        let deactivate = 'Do you wish to deactivate this customer?'
        this._confirmationService.confirm({
            message: this._customerService.activeItem.status === true ? deactivate : activate ,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._abd.showSpinner();
                try {
                    this._customerService
                        .updateCustomerStatus(
                            !this._customerService.activeItem.status,
                            this._customerService.activeItem.custID
                        )
                        .subscribe((res) => {
                            this._abd.hideSpinner();
                            if (res.success) {
                                this._customerService.activeItem.status =
                                    !this._customerService.activeItem.status;
                                this._abd.loadInit().subscribe((resD) => {
                                    if (resD.success) {
                                    }
                                });
                                this._connMessageService.showToastMessage(
                                    `Customer Status Changed!`,
                                    'success'
                                );
                            }
                        });
                } catch {
                    this._abd.hideSpinner();
                }
            },
            reject: () => {},
        });
    }

    actionButtonClick(item: any) {
        this._customerService.activeItem = item;
        //console.log("this._customerService.activeItem", this._customerService.activeItem)
    }
    ViewCustomer() {
        this._customerService.activeItem = JSON.parse(
            JSON.stringify(this._customerService.activeItem)
        );

        this._router.navigate(['app/customer-details']);
    }
    optionEditClicked() {
        this._customerService.activeItem = JSON.parse(
            JSON.stringify(this._customerService.activeItem)
        );
        // this.changecustomer.initForm();
        this._customerService.showNewEdit = true;
    }
    addNewFn(e) {
        if (e !== 'customer') return;
        this._customerService.activeItem = this._customerService.blankActiveItem();
        this._customerService.showNewNewEdit = true;
    }
    exportExcel() {
        let list = this.dataList.map((v)=>{
            return{
                ...v,isActive:v.status
            }
        })
        this._abd.exportExcel(list, this.colHeader[0], this.colflds, 'customer',false)
      }
    
      exportPdf() {
        let list = this.dataList.map((v)=>{
            return{
                ...v,isActive:v.status
            }
        })
        this._abd.exportPdf(list, this.colHeader, this.colflds, 'customer', false,false,false)
      }
    // exportExcel() {
    //     // this._globals.exportExcel(this.dataList, this.colflds, 'Manufacturer Report')
    // }

    // exportPdf() {
    //     let data = [];

    //     for (let i = 0; i < this.dataList.length; i++) {
    //         let row = [];
    //         for (let j = 0; j < this.colflds.length; j++)
    //             row.push(this.dataList[i][this.colflds[j]]);
    //         data.push(row);
    //     }

    //     // this._globals.exportPdf(data, this.colheader, 'Manufacturer Report')
    // }

    activateCustomterStatus() {
        return false;
    }

    onRowSelect(e) {
        if (e.originalEvent.path[0].classList.value.includes('p-checkbox')) {
            return;
        }
        this._customerService.activeItem = JSON.parse(JSON.stringify(e.data));

        //console.log('this._customerService.activeItem', this._customerService.activeItem)
        this._router.navigate(['app/customer-details']);
    }

    onRowUnselect(e) {}

    saveUpdateCallback(e) {}
}
