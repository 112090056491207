<div class="margin">
    <form [formGroup]="beneficiariesForm" (ngSubmit)="saveForm()" autocomplete="off">
        <div class="" formArrayName="beneficiaries">
            <div class="" *ngFor="let obj of beneficiaries.controls; let formIndex = index">
                <div class="other-owners-container" [formGroup]="obj">
                    <h4 class="mb-3">{{arrayTitle[formIndex]}} Details</h4>
                    <div class="p-fluid p-formgrid grid">
                        <div class="col-12 md:col-6 field">
                            <label>Title <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full my-1" formControlName="title" pInputText
                                required />
                            <small id="username1-help" class="block text-muted">{{arrayTitle[formIndex]}}
                                beneficiary title in an organization i.e. Manager, CEO, President</small>
                            <div *ngIf="(!obj.get('title')?.valid
                                && (obj.get('title')?.dirty || obj.get('title')?.touched)
                                )|| (isSubmitted && !obj.get('title')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('title').value?.length == 0 &&
                                    !obj.get('title').errors.required">
                                    Please enter a Title
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Ownership Percentage (%) <span class="text-red-500">*</span></label>
                            <p-inputNumber formControlName="ownerPercentageControlName" [min]="1" [max]="100"
                                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                                class="p-inputtext-lg w-full my-1" required>
                            </p-inputNumber>
                            <small id="username1-help" class="block text-muted">Percentage of
                                {{arrayTitle[formIndex]}} beneficiary i.e. 25%
                            </small>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Social Security Number <span class="text-red-500">*</span></label>
                            <input type="text" formControlName="ssnControlName" class="p-inputtext-lg w-full my-1"
                                pInputText required />
                            <!-- <small id="username1-help" class="block text-muted">Social security number here</small> -->
                            <small id="username1-help" class="block text-muted">Social security number of
                                {{arrayTitle[formIndex]}} beneficiary here</small>
                            <div *ngIf="(!obj.get('ssnControlName')?.valid
                                && (obj.get('ssnControlName')?.dirty || obj.get('ssnControlName')?.touched)
                                )|| (isSubmitted && !obj.get('ssnControlName')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('ssnControlName').value?.length == 0 &&
                                !obj.get('ssnControlName').errors.required">
                                    Please enter a Social Security Number
                                </div>
                            </div>

                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Date of Birth <span class="text-red-500">*</span></label>
                            <div class="p-inputgroup align-items-center">
                                <div class="p-inputgroup align-items-center">
                                    <span class="p-inputgroup-addon" style="height: 40px;">
                                        <img src="../../../../assets/images/Calender.svg" alt="Calender Icon">
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 0">
                                        <p-calendar [(ngModel)]="dob1" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}">
                                        </p-calendar>
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 1">
                                        <p-calendar [(ngModel)]="dob2" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}">
                                        </p-calendar>
                                    </span>
                                    <span class="p-float-label" *ngIf="formIndex == 2">
                                        <p-calendar [(ngModel)]="dob3" styleClass="my-1"
                                            formControlName="dobControlName" [ngModelOptions]="{standalone: true}">
                                        </p-calendar>
                                    </span>
                                </div>
                            </div>
                            <small id="username1-help" class="block text-muted">Date of birth of
                                {{arrayTitle[formIndex]}} beneficiary here</small>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Form of ID <span class="text-red-500">*</span></label>
                            <p-dropdown [options]="formIdList" optionLabel="viewValue" class="" formControlName="formId"
                                id="formOfId" optionValue="view" [showTransitionOptions]="'0ms'"
                                [hideTransitionOptions]="'0ms'">
                            </p-dropdown>
                            <div *ngIf="(!obj.get('formId')?.valid
                                && (obj.get('formId')?.dirty || obj.get('formId')?.touched)
                                )|| (isSubmitted && !obj.get('formId')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('formId').value?.length == 0 &&
                            !obj.get('formId').errors.required">
                                    Please enter a Form Id
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Beneficiary Number <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full " pInputText
                                formControlName="beneficiaryNumber" required />
                            <small id="username1-help" class="block text-muted">{{arrayTitle[formIndex]}}
                                beneficiary number here i.e. 574839</small>
                            <div *ngIf="(!obj.get('beneficiaryNumber')?.valid
                                && (obj.get('beneficiaryNumber')?.dirty || obj.get('beneficiaryNumber')?.touched)
                                )|| (isSubmitted && !obj.get('beneficiaryNumber')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('beneficiaryNumber').value?.length == 0 &&
                            !obj.get('beneficiaryNumber').errors.required">
                                    Please enter a Beneficiary Number
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Expiration <span class="text-red-500">*</span></label>
                            <div class="p-inputgroup align-items-center">
                                <span class="p-inputgroup-addon" style="height: 40px;">
                                    <img src="../../../../assets/images/Calender.svg" alt="Calender Icon">
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 0">
                                    <p-calendar [(ngModel)]="dob01" styleClass="my-1" formControlName="expiryDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 1">
                                    <p-calendar [(ngModel)]="dob02" styleClass="my-1" formControlName="expiryDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 2">
                                    <p-calendar [(ngModel)]="dob03" styleClass="my-1" formControlName="expiryDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                            </div>
                            <small id="username1-help" class="block text-muted">Expiration date of
                                {{arrayTitle[formIndex]}} beneficiary here</small>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Phone <span class="text-red-500">*</span></label>
                            <div class="p-inputgroup align-items-center">
                                <span class="p-inputgroup-addon">
                                    <img src="assets/images/us-flag-icon.png" alt="USA Flag Icon" width="19">
                                </span>
                                <span class="p-float-label">
                                    <p-inputMask mask="(999) 999-9999" formControlName="ownerPhoneControlName"
                                        class="p-inputtext-lg w-full my-1" required>
                                    </p-inputMask>
                                </span>
                            </div>
                            <small id="username1-help" class="block text-muted">Home phone number of
                                {{arrayTitle[formIndex]}}
                                beneficiary</small>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Role <span class="text-red-500">*</span></label>
                            <input type="text" formControlName="role" class="p-inputtext-lg w-full my-1" pInputText
                                required />
                            <small id="username1-help" class="block text-muted">Owner or Signer (1 = Owner, 2= Non
                                Owner but
                                Authorized Signer, 3=Owner and Authorized Signer)
                            </small>
                            <div *ngIf="(!obj.get('role')?.valid
                                && (obj.get('role')?.dirty || obj.get('role')?.touched)
                                )|| (isSubmitted && !obj.get('role')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('role').value?.length == 0 &&
                                    !obj.get('role').errors.required">
                                    Please enter a Role
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-12 field">
                            <label>Address <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full my-1" pInputText
                                formControlName="addressControlName" required />
                            <small id="username1-help" class="block text-muted">Home address of
                                {{arrayTitle[formIndex]}} beneficiary here</small>
                            <div *ngIf="(!obj.get('addressControlName')?.valid
                                && (obj.get('addressControlName')?.dirty || obj.get('addressControlName')?.touched)
                                )|| (isSubmitted && !obj.get('addressControlName')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('addressControlName').value?.length == 0 &&
                                    !obj.get('addressControlName').errors.required">
                                    Please enter a Address
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-4 field">
                            <label>Zip Code <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full my-1" pInputText required
                                (keyup)="ownersZipFiled(formIndex)" formControlName="zipCodeControlName" />
                            <small id="username1-help" class="block text-muted">Your home address zip code
                                here</small>
                            <div *ngIf="(!obj.get('zipCodeControlName')?.valid
                                && (obj.get('zipCodeControlName')?.dirty || obj.get('zipCodeControlName')?.touched)
                                )|| (isSubmitted && !obj.get('zipCodeControlName')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('zipCodeControlName').value?.length == 0 &&
                                !obj.get('zipCodeControlName').errors.required">
                                    Please enter a Zip Code
                                </div>
                            </div>
                            <div *ngIf="beneficiariesForm.controls.beneficiaries['controls'][formIndex].controls.showOwnerZipCodeLoading.value
                                == '1'">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>
                        </div>
                        <div class="col-12 md:col-4 field">
                            <label>City <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full  mt-1" pInputText required
                                formControlName="cityControlName" />
                            <div *ngIf="(!obj.get('cityControlName')?.valid
                                && (obj.get('cityControlName')?.dirty || obj.get('cityControlName')?.touched)
                                )|| (isSubmitted && !obj.get('cityControlName')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('cityControlName').value?.length == 0 &&
                                    !obj.get('cityControlName').errors.required">
                                    Please enter a City
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-4 field">
                            <label>State <span class="text-red-500">*</span></label>
                            <p-dropdown [options]="usaStates" formControlName="stateControlName" optionLabel="name"
                                styleClass="mt-1">
                            </p-dropdown>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Issuer State <span class="text-red-500">*</span></label>
                            <input type="text" class="p-inputtext-lg w-full my-1" pInputText required
                                formControlName="issueStateControlName" />
                            <small id="username1-help" class="block text-muted">The name of the state/city issuer
                                of beneficiary</small>
                            <div
                                *ngIf="(!obj.get('issueStateControlName')?.valid
                                                            && (obj.get('issueStateControlName')?.dirty || obj.get('issueStateControlName')?.touched)
                                                            )|| (isSubmitted && !obj.get('issueStateControlName')?.dirty)">

                                <div class="input-required mt-1" [hidden]="obj.get('issueStateControlName').value?.length == 0 &&
                                                                !obj.get('issueStateControlName').errors.required">
                                    Please enter a Issuer State
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 field">
                            <label>Issued Date <span class="text-red-500">*</span></label>
                            <div class="p-inputgroup align-items-center">
                                <span class="p-inputgroup-addon" style="height: 40px;">
                                    <img src="../../../../assets/images/Calender.svg" alt="Calender Icon">
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 0">
                                    <p-calendar [(ngModel)]="dob001" styleClass="my-1" formControlName="issueDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 1">
                                    <p-calendar [(ngModel)]="dob002" styleClass="my-1" formControlName="issueDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                                <span class="p-float-label" *ngIf="formIndex == 2">
                                    <p-calendar [(ngModel)]="dob003" styleClass="my-1" formControlName="issueDate"
                                        [ngModelOptions]="{standalone: true}">
                                    </p-calendar>
                                </span>
                            </div>
                            <small id="username1-help" class="block text-muted">{{arrayTitle[formIndex]}}
                                beneficiary date issued</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid mt-5 justify-content-between">
            <p-button label="Back" class="next-level-back-button" (click)="backButtonClick()" icon="pi pi-arrow-left"
                iconPos="left"></p-button>
            <p-button label="Continue" type="submit" [disabled]="!beneficiariesForm.valid" icon="pi pi-arrow-right"
                iconPos="right"></p-button>
        </div>
    </form>
</div>
