import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-connect-cbd-terms-and-conditions',
  templateUrl: './connect-cbd-terms-and-conditions.component.html',
  styleUrls: ['./connect-cbd-terms-and-conditions.component.scss']
})
export class ConnectCbdTermsAndConditionsComponent implements OnInit {
  _global = GlobalService
  constructor() { }

  ngOnInit(): void {
  }

}
