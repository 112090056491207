<div class="flex flex-column p-fluid w-6">
    <div class="field">
        <label htmlfor="InputDollarValue">Dollar Value <span class="text-red-500">*</span> </label>
        <input
            pinputtext=""
            id="InputDollarValue"
            type="text"
            placeholder="Dollar Value"
            class="p-inputtext p-component p-element">
        <div class="text-sm text-bluegray-300 mt-1">
            Define value of each dollar to points i.e. 1 Dollar$ = X Points
        </div>
    </div>
    <div class="field">
        <label htmlfor="InputPointAccumulationThreshold">Point Accumulation Threshold <span
                class="text-red-500">*</span></label>
          <div class="col-12 md:col-12">
              <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">$</span>
                  <input type="text" pInputText placeholder="Point Accumulation Threshold">
              </div>
          </div>
        <div class="text-sm text-bluegray-300 mt-1">
            Minimum purchase needed for points accumulation i.e. $X Dollars
        </div>
    </div>
    <div class="field">
        <label htmlfor="InputMaxPointsAllowed">Maximum Points Allowed <span class="text-red-500">*</span> </label>
        <input pinputtext="" id="InputMaxPointsAllowed" type="text" placeholder="Maximum Points Allowed"
            class="p-inputtext p-component p-element">
        <div class="text-sm text-bluegray-300 mt-1">
            Maximum limit to points accumulation
        </div>
    </div>
    <div class="field w-5">
        <label htmlfor="InputPointsExpiry">Points Expiry</label>
        <p-dropdown [options]="expiryDurationList" [(ngModel)]="selectedExpiry" optionLabel="name"></p-dropdown>
    </div>
    <div class="field flex w-full">
        <label htmlfor="InputDiscountOnItem" class="col-12 mb-2 md:col-8 md:mb-0">
            Allow points accumulation on discounted items
        </label>
        <div class="col-12 md:col-4">
             <p-inputSwitch [(ngModel)]="InputDiscountOnItem" inputId="InputDiscountOnItem">
             </p-inputSwitch>
             <label class="control-label" for="InputDiscountOnItem"><strong> Enable</strong></label>
        </div>
    </div>
    <div class="field flex w-full">
        <label htmlfor="InputDiscountOpenDepart" class="col-12 mb-2 md:col-8 md:mb-0">
            Allow points accumulation on open department items
        </label>
        <div class="col-12 md:col-4">
            <p-inputSwitch [(ngModel)]="InputDiscountOpenDepart" inputId="InputDiscountOpenDepart">
            </p-inputSwitch>
            <label class="control-label" for="InputDiscountOpenDepart"><strong> Enable</strong></label>
        </div>
    </div>
    <!--
    <div class="flex">
         <label htmlfor="InputDiscountOpenDepart" class="col-12 mb-2 md:col-8 md:mb-0">
             Preferred Layout
         </label>
    </div>
     <div class="field flex">

        <div class="col-12 md:col-6">
            <div class="flex flex-column justify-content-center">
                <p-image src="/assets/images/Grid Layout.png" alt="Image" width="250">
                    <ng-template pTemplate="indicator">
                        Indicator Content
                    </ng-template>
                </p-image>
                <p-radioButton name="groupname" class="justify-content-center" label="Grid Layout" value="val1"
                    [(ngModel)]="selectedValue">

                </p-radioButton>
            </div>
        </div>
        <div class="col-12 md:col-6">
             <div class="flex flex-column justify-content-center">
                <p-image src="/assets/images/List Layout.png" alt="Image" width="250">
                    <ng-template pTemplate="indicator">
                        Indicator Content
                    </ng-template>
                </p-image>
                 <p-radioButton name="groupname" class="justify-content-center" value="val1" label="List Layout"
                     [(ngModel)]="selectedValue">

                 </p-radioButton>
             </div>
        </div>
     </div> -->
</div>
