import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/_services/base.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';

@Component({
  selector: 'app-hardware-plan-widget-list',
  templateUrl: './hardware-plan-widget-list.component.html',
  styleUrls: ['./hardware-plan-widget-list.component.scss']
})
export class HardwarePlanWidgetListComponent implements OnInit {
  hardwareList: any = []
  totalHardwareCost = 0

  constructor(private _bs: BaseService, private _subscriptionPlanService: SubscriptionPlanService, private _router: Router) { }

  ngOnInit(): void {
    this.loadInit()
  }

  loadInit() {
    const url = ''
    return this._bs.httpGet('auth-svc/api/GetHardwarePackages').subscribe(res => {
      if (res.success) {
        this.hardwareList = res.data;

        for (let i = 0; i < this.hardwareList.length; i++) {
          this.hardwareList[i].selected = false
        }
      }
    })

  }

  onSelectionChanged(id) {
    this.totalHardwareCost = 0
    for (let i = 0; i < this.hardwareList.length; i++) {
      if (this.hardwareList[i].id == id) {
        this.hardwareList[i].selected = !this.hardwareList[i].selected
        break
      }
    }

    for (let i = 0; i < this.hardwareList.length; i++) {
      if (this.hardwareList[i].selected) this.totalHardwareCost += this.hardwareList[i].price
    }
  }

  nextButtonPressed() {
    this._subscriptionPlanService.selectedHardware = []

    for (let i = 0; i < this.hardwareList.length; i++) {
      if (this.hardwareList[i].selected)
        this._subscriptionPlanService.selectedHardware.push(this.hardwareList[i])
    }

    this._router.navigateByUrl('uikit/CheckoutNFinishSignupComponent')
  }



}
