<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'transfer-detail-report2'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>
    <p-table [value]="dataList.stockTransfersDetails" dataKey="name" #dt styleClass="p-datatable-striped" [scrollable]="true" >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>
                    <span>{{ product.productName }}</span>
                </td>
                <td>
                    <span>{{ product.itemSKUId }}</span>
                </td>
                <td>
                    <span>{{ product.barcode }}</span>
                </td>
                <td>
                    <span>{{ product.expiryDate | date:'MM/dd/yyyy' }}</span>
                </td>
                <td>
                    <span>{{ product.transferQuantity }}</span>
                </td>
                
                
            </tr>
        </ng-template>

        <!-- <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                
                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template> -->
    </p-table>

    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>