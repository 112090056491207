import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({
  selector: 'app-purchase-order-receive-list',
  templateUrl: './purchase-order-receive-list.component.html',
  styleUrls: ['./purchase-order-receive-list.component.scss']
})
export class PurchaseOrderReceiveListComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  selectedRow: any

  dataList: any = []

  _globals = GlobalService
  @ViewChild('opActions') opActions: OverlayPanel

  constructor(
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    public _purchaseService: PurchaseService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._purchaseService.baseFilterOptions = this._abd.appCookedData(false)
    this._globals.componentName = 'PO Receive List'
    // if (!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date().setDate(new Date().getDate() - 7)
    if (!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date('1800-12-12')
    if (!this._abd.dateRange[1]) this._abd.dateRange[1] = new Date()
    this._abd.selectedLocation = null
    this.getDataList()
  }

  getDataList() {
    let obj = this._abd
    let vendorid = obj.selectedVendor ? obj.selectedVendor.vendorId : ''
    let locationid = obj.selectedLocation ? obj.selectedLocation.locationId : ''
    let poStatus = obj.selectedStatus ? obj.selectedStatus : 'All'

    this._spinner.show()
    this._purchaseService.getPoList(
      this._abd.searchText,
      true,
      locationid,
      vendorid,
      poStatus,
      formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
      this.page,
      this.rows,

    ).subscribe(res => {
      this._spinner.hide()
      if (res.success) {
        this.totalRecords = res.totalRecords
        this.dataList = res.data
        this.totalRecords = res.totalRecords

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
        }
        // console.log('transfer-data-list', this.dataList)
      }
    })
  }

  actionButtonClicked(item) {
    this._purchaseService.mapItemTonewEditItem(item)
    let obj = this._purchaseService.newEditItem
    obj.isViewOnly = true
    obj.isNew = false
    obj.isEdit = false
    //this._router.navigate(['app/purchase-orders-create-edit'])
  }

  doEnableActionButton(item) {
    const showOn = [1]
    try {
      if (showOn.find(f => f == item)) return false
      else return true
    } catch { return true }
  }

  optionEditClicked() {
    let obj = this._purchaseService.newEditItem
    obj.isViewOnly = false
    obj.isNew = false
    obj.isEdit = true
    this.opActions.hide()
    this._router.navigate(['app/purchase-orders-receive'])
  }

  onRowSelect(e) {
    this._purchaseService.mapItemTonewEditItem(e.data)
    let obj = this._purchaseService.newEditItem
    obj.isViewOnly = true
    obj.isNew = false
    obj.isEdit = false
    this._router.navigate(['app/purchase-orders-receive'])
  }

  onRowUnselect(e) { }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.page = 0
    this.getDataList()
  }

  onPageChange(e) {
    if (this.page == e.page && this.rows == e.rows) return
    this.page = e.page
    this.rows = e.rows
    this.getDataList()
  }

  exportExcel() {
    let header = [
      'Sr#', 'Date', 'PO#', 'Location', 'Vendor', 'Order Qty',
      'Receive Qty', 'Sub Total', 'Discount', 'Net Cost', 'Total Tax',
      'Total Cost', 'Status'
    ]
    const ws = []
    const xd = this.dataList
    let tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].srNo)
      inner.push(formatDate(new Date(xd[j].dated), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].poNumber)
      inner.push(xd[j].locationName)
      inner.push(xd[j].vendorName)
      inner.push(xd[j].totalQty.toFixed(2))
      inner.push(xd[j].totalReceivedQty.toFixed(2))
      inner.push('$ ' + xd[j].subTotal.toFixed(2))
      inner.push('$ ' + xd[j].discountTotal.toFixed(2))
      inner.push('$ ' + xd[j].netTotal.toFixed(2))
      inner.push('$ ' + xd[j].taxTotal.toFixed(2))
      inner.push('$ ' + xd[j].grandTotal.toFixed(2))
      inner.push(xd[j].poStatus)
      tmp.push(inner)
    }
    ws.push(tmp)

    const workbook = {
      Sheets: {
        'PO Receive List': XLSX.utils.aoa_to_sheet(ws[0]),
      },
      SheetNames: ['PO Receive List']
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filename = 'po-receive-list-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
    FileSaver.saveAs(data, filename);
  }

  exportPdf() {
    let cols = [
      { id: 0, dataKey: "srNo", title: "Sr#", type: 'string' },
      { id: 1, dataKey: "dated", title: "Date", type: 'date' },
      { id: 2, dataKey: "poNumber", title: "PO#", type: 'string' },
      { id: 3, dataKey: "locationName", title: "Location", type: 'string' },
      { id: 4, dataKey: "vendorName", title: "Vendor", type: 'string' },
      { id: 5, dataKey: "totalQty", title: "Order Qty", type: 'int' },
      { id: 6, dataKey: "totalReceivedQty", title: "Receive Qty", type: 'int' },
      { id: 7, dataKey: "subTotal", title: "Sub Total", type: 'currency' },
      { id: 8, dataKey: "discountTotal", title: "Discount", type: 'currency' },
      { id: 9, dataKey: "netTotal", title: "Net Cost", type: 'currency' },
      { id: 10, dataKey: "taxTotal", title: "Total Tax", type: 'currency' },
      { id: 11, dataKey: "grandTotal", title: "Total Cost", type: 'currency' },
      { id: 12, dataKey: "poStatus", title: "Status", type: 'string' },
    ]

    const data = this._abd.parseForExport(this.dataList, cols)

    const doc = new jsPDF('l', 'mm', 'a3');
    doc['autoTable'](cols, data)
    const filename = 'purchase-order-receive-list-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
    doc.save(filename);
   }

}
