<div class="form-group">
    <div class="p-inputgroup" >
        <div class="p-inputgroup" [style]="{ width: '100%' }">
            <span class="p-inputgroup-addon"
                ><img src="assets/images/us-flag-icon.png" style="width: 25px"
            /></span>
            <p-inputMask
                [class.is-invalid]="ngControl.touched && ngControl.invalid"
                [style]="{ 'width': '100%' }"
                mask="(999) 999-9999"
                [formControl]="$any(ngControl.control)"
                [name]="'Phone'"
                placeholder=" "
                [autoClear]="false"
                [styleClass]="{'width':'100%'}"
            >
            </p-inputMask>
        </div>
    </div>
    <div
        *ngIf="
            (ngControl.touched && ngControl.control?.errors?.required) ||
          (ngControl.touched && ngControl.control?.errors?.phoneValError)
        "
    class="invalid-feedback" >  Please enter a 10 digits valid phone number!
    </div>
</div>
