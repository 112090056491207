<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'loyalty-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>


    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" [tableStyle]="{ width: 'max-content' }" [autoLayout]="true"  styleClass="p-datatable-striped" responsiveLayout="scroll"  *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td style="text-align: center">{{ product.dateTime | date:'MM/dd/yyyy'}}</td>
                <td style="text-align: center">{{ product.locationName }}</td>
                <td style="text-align: center">{{ product.salesTotal | currency }}</td>
                <td style="text-align: center">{{ product.loyaltySales | currency }}</td>
                <td style="text-align: center">{{ product.loyaltyCustomerCount | number }}</td>
                <td style="text-align: center">{{ product.valuePerLoyaltyCustomer | currency }}</td>
                <td style="text-align: center">{{ product.pointsRedeemed | number }}</td>
                <td style="text-align: center">{{ product.discountReceived | currency }}</td>
                <td style="text-align: center">{{ product.pointsAcquired | number }}</td>
                <td style="text-align: center">{{ product.pointsAcquiredDollarValue | currency }}</td>
                <td style="text-align: center">{{ product.nonLoyaltyCustomerCount }}</td>
                <td style="text-align: center">{{ product.nonLoyaltySales | currency}}</td>
                <td style="text-align: center">{{ product.valuePerNonLoyaltyCustomer | currency }}</td>

            </tr>
        </ng-template>
      <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>

                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>

    </p-table>

    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
