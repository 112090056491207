import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-barcode-label-printing-list',
  templateUrl: './barcode-label-printing-list.component.html',
  styleUrls: ['./barcode-label-printing-list.component.scss']
})
export class BarcodeLabePrintingList implements OnInit {
  _globals = GlobalService
 
  @ViewChild('op') op: OverlayPanel

  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  doChangePage = true
  selectedRow: any = ''
  selectAll = false

  isProduct:boolean = true;

  lastSearchText = ''
  dataList: any = null
  locationIds: string[] = [];
  //order
  orderParam = ''
  orderBy = 'asc'

  colheader = [[
    'Product Name', 'Barcode', 'SKU', 'Track Quantity'
    , 'Sell Online', 'Sale Price', 'Last Updated'
  ]];

  colflds = ['itemName', 'barcode', 'sku', 'trackStock',
    'sellOnline', 'sellingPrice', 'lastEdited'
  ];

  csvcols = []
  exportColumns: any = ''

  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  @ViewChild('p', { static: false }) paginator: Paginator;
   
 

  constructor(
    private _connMessageService: ConnMessageService,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
    private _confirmationService: ConfirmationService,
    private _abd: AppBaseDataService,
    private _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._globals.componentName = 'Label Printing'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }


      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }

      this.getDataList()
      clearInterval(ti)
    }, 700);
  }
  ngOnDestroy() {
    this._globals.componentName = ''
  }
  getLocationsList() {
    let self = this;
    this.locationIds = [];
    if(this._filterService.selectedLocation !== ""){
        let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
    }
  
  }
  getLocationName(locationId){
    return this._abd.locations.filter((val)=>val.id === locationId)[0].locationName;
  }
  getDataList() {
    this.getLocationsList()
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const obj:any = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    } 
    if(this.locationIds.length > 0){
      obj.locationIds = this.locationIds.join(',');
    }

    this._abd.showSpinner()
     try {
       this._barcodeLabelServices.getAllbarcodeLabelList(obj).subscribe(res => {
         if (res.success) {
           this.totalRecords = res.totalRecords
            this.dataList = res.data
           if(this.dataList.length < 1){
            this._globals.showProductDelButton = false
           }
           this._abd.hideSpinner()

        }
      })

      // this._filterService.updateFilters(this._globals.componentName, obj)
      // this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }
  refrestDataList() {
    this.getDataList();
  }
  reloadList(){
    this.filterButtonPressed() 
  }
  /**
       * Method is use to download file.
       * @param data - Array Buffer data
       * @param type - type of the document.
       */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = url

    downloadLink.setAttribute('download', "inventory.csv");

    document.body.appendChild(downloadLink);
    downloadLink.click();

    this._abd.hideSpinner();
  }

  actionButtonClick(item: any) {
     this._barcodeLabelServices.activeItem = item
  }

  getInitData() {
    this.getDataList()
  }

  clearSearchText() {
    if (this.lastSearchText?.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  searchTextSearch() {
    if (this._filterService.searchText?.length < 1 && this.lastSearchText?.length < 1) return
    this.lastSearchText = this._filterService.searchText //this.searchText
    this.filterButtonPressed()
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }


  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }

  callbackFuncFromFilter() {
    this.filterButtonPressed()
  }
  optionDeleteClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'This will permanently remove the selected label from your records. This action cannot be reversed.',
      header: 'Delete Print Label?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._barcodeLabelServices.deleteBarcodeLabel(this._barcodeLabelServices.activeItem.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Label deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }
  selectAllChanged(event) {
    if (event.checked) {
      this._globals.showProductDelButton = true
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      this._globals.showProductDelButton = false
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }
  }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {
        ret = true
        break
      }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) this.selectAll = true
      else this.selectAll = false
    }

    this._globals.showProductDelButton = ret
  }

  commaSeperatedIds(data: any): string {
    let selectedItems: string = "";
    let z = data.filter(x => x.selected)
    if (z.length > 100) {
      this._connMessageService.showToastMessage('Cannot delete more than 100 items at a single time.', 'error')
      return null
    }
    z.forEach(x => { selectedItems += x.id + "," })
    selectedItems = selectedItems.slice(0, -1);
    return selectedItems
  }

  deleteMultiple() {
    let marked = this.dataList.filter(f => f.selected)
    let markedIds = this.commaSeperatedIds(marked);
    if (!marked || !markedIds) return

    let header = 'Deleting Label Confirmation'
    if (marked.length > 1) header = `Deleting ${marked.length} product(s) Confirmation`

    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed?',
      header: header,
      icon: 'pi pi-info-circle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        try {
          this._abd.showSpinner()
          this._barcodeLabelServices.deleteBarcodeLabel(markedIds).subscribe(res => {
            if (res.success) {
              this._connMessageService.showToastMessage(`Deleted ${marked.length} product(s) successfully.`, 'success');
              this.filterButtonPressed()
              this._globals.showProductDelButton = false
              this._abd.hideSpinner()
              this.selectAll = false
            }
          })
        } catch {
          this.getDataList()
          this._abd.hideSpinner()
        }
      },
      reject: () => {
      }
    });
  }
   onRowSelect(e) {
    this.isProduct = !this.isProduct
    this._barcodeLabelServices.activeItem = e.data
   if(this.isProduct){
    this._barcodeLabelServices.showEditBarcodeLabel = true;
    }else{
      this._barcodeLabelServices.showEditCustomBarcodeLabel = true;
    }

  }
  editLabelPrint() {
   let data = this._barcodeLabelServices.activeItem
   if(data.productId && data.isCustom === false){
    this._barcodeLabelServices.showEditBarcodeLabel = true
   }else{
    this._barcodeLabelServices.showEditCustomBarcodeLabel = true
   }
  }
  onRowUnselect() { }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'productName'
        break;
      case 'barcode':
        this.orderParam = 'barcode'
        break;
      case 'sku':
        this.orderParam = 'sku'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
}
