import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-subscription-plan-counter',
    templateUrl: './subscription-plan-counter.component.html',
    styleUrls: ['./subscription-plan-counter.component.scss'],
})
export class SubscriptionPlanCounterComponent implements OnInit {
    locationQty: number;
    registerQty: number;

    disabledLocationAndRegister: boolean = false;
    @Input() currentSubscriptionId: number = 1;

    @Output() locationChanged = new EventEmitter();
    @Output() registerChanged = new EventEmitter();
    totalLocation: number = 0;
    constructor(public _signup: SignupService) {}

    ngOnInit(): void {
        try {
            this.totalLocation = this._signup.outerLocationQty;
            let locationsQty: any = sessionStorage.getItem('locationsQty');
            if (locationsQty != undefined) {
                this._signup.checkoutPageCalcVars.locationsQty =
                    parseInt(locationsQty);
                this._signup.outerLocationQty = parseInt(locationsQty);

                this.totalLocation = this._signup.outerLocationQty;
            }
            this.calcTotals();
        } catch (error) {}
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            for (let propName in changes) {
                let chng = changes['currentSubscriptionId'];
                let cur = JSON.stringify(chng?.currentValue);
                let prev = JSON.stringify(chng?.previousValue);

                this.validateLocations();
                this.calcTotals();
            }
        } catch (error) {}
    }

    validateLocations() {
        try {
            if (this._signup.selectedPlan != undefined) {
                let featureData = this._signup.selectedPlan.featureData;
                this.disabledLocationAndRegister = false;
                if (
                    featureData.filter((f) => f.typeString == 'Locations')
                        .length > 0
                ) {
                    let locationValue = featureData.filter(
                        (f) => f.typeString == 'Locations'
                    )[0].value;
                    let registerValue = featureData.filter(
                        (f) => f.typeString == 'Registers'
                    )[0].value;

                    this._signup.allowedLocationMoreThanOne = true;
                    this._signup.allowedRegisterMoreThanOne = true;

                    if (locationValue == '1' && registerValue == '1') {
                        this.disabledLocationAndRegister = true;
                        this._signup.outerLocationQty = 1;
                        this._signup.outerRegisterQty = 1;
                        this.totalLocation = 1;
                        this._signup.allowedLocationMoreThanOne = false;
                        this._signup.allowedRegisterMoreThanOne = false;
                    }
                }
            }
        } catch (error) {}
    }

    changeValueLoc(input: any) {
        try {
            if (input.value != null && parseInt(input.value) > 0) {
                if (this.totalLocation > parseInt(input.value)) {
                    this._signup.outerRegisterQty =
                        this._signup.outerRegisterQty - 1;
                } else {
                    this._signup.outerRegisterQty =
                        this._signup.outerRegisterQty + 1;
                }
                this._signup.outerLocationQty = input.value;
            }

            this.totalLocation = this._signup.outerLocationQty;
            this.calcTotals();
            this.locationChanged.emit(true);
        } catch (error) {
            this._signup.outerLocationQty = 1;
            this.totalLocation = 1;
            this._signup.outerRegisterQty = 1;
        }
    }

    changeValueReg(e: any) {
        try {
            this.calcTotals();
            this.registerChanged.emit(true);
        } catch (error) {}
    }

    calcTotals() {
        try {
            let ser = this._signup;

            let obj = this._signup.subscriptionPlans;
            let price = 0;
            let qty = ser.outerRegisterQty - ser.outerLocationQty;
            if (qty <= 0) {
                // ser.outerLocationQty = 1
                for (let i = 0; i < obj.length; i++) {
                    obj[i].registerAmount = 0;
                }
            } else {
                for (let i = 0; i < obj.length; i++) {
                    // price = obj[i].featureData.find(f => f.title == 'AdditionalRegister').price
                    price = obj[i].featureData[6].price;
                    price = qty * price;
                    obj[i].registerAmount = price;
                }
            }

            for (let i = 0; i < obj.length; i++) {
                price = obj[i].featureData.find(
                    (f) => f.title == 'AdditionalLocationPlus1Reg'
                ).price;
                price *= this._signup.outerLocationQty - 1;
                obj[i].locationAmount = price;
                // obj[i].registerAmount = obj[i].featureData[6].price*(this._signup.outerLocationQty - 1)
            }
        } catch (error) {}
    }
}
