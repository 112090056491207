<div class="grid p-fluid">
    <div class="col-12 lg:col-6">
        <div class="card">
            <h5>Linear Chart</h5>
            <p-chart type="line" [data]="lineData" [options]="lineOptions" [style]="{'width': '50%'}"></p-chart>
        </div>

        <div class="card flex flex-column align-items-center">
            <h5 class="text-left w-full">Pie Chart</h5>
            <p-chart type="pie" [data]="pieData" [options]="pieOptions" [style]="{'width': '50%'}"></p-chart>
        </div>

        <div class="card flex flex-column align-items-center">
            <h5 class="text-left w-full">Polar Area Chart</h5>
            <p-chart type="polarArea" [data]="polarData" [options]="polarOptions" [style]="{'width': '50%'}"></p-chart>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="card">
            <h5>Bar Chart</h5>
            <p-chart type="bar" [data]="barData" [options]="barOptions"></p-chart>
        </div>

        <div class="card flex flex-column align-items-center">
            <h5 class="text-left w-full">Doughnut Chart</h5>
            <p-chart type="doughnut" [data]="pieData" [options]="pieOptions" [style]="{'width': '50%'}"></p-chart>
        </div>

        <div class="card flex flex-column align-items-center">
            <h5 class="text-left w-full">Radar Chart</h5>
            <p-chart type="radar" [data]="radarData" [options]="radarOptions" [style]="{'width': '50%'}"></p-chart>
        </div>
    </div>
</div>
