import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-customer-app-plans',
  templateUrl: './customer-app-plans.component.html',
  styleUrls: ['./customer-app-plans.component.scss']
})
export class CustomerAppPlansComponent implements OnInit {

  @Input() showFooter = true
  @Output() calcCustomerAppPrice = new EventEmitter<any>()
  @Output() closeDialogCustApp = new EventEmitter<any>()


  constructor(public _signup: SignupService,

    public _abd: AppBaseDataService,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
  }

  nextStep() {
    if (this.showFooter)
      this._signup.currentStep++;
    else
      this.closeDialogCustApp.emit()
  }

  backStep() {
    this._signup.currentStep--;
  }
  
  selectPlan(e, item) {
    this._signup.selectedCustomerAppPlan = null
    if (e.checked) this._signup.selectedCustomerAppPlan = item

    let obj = this._signup.customerAppSubscriptionPlans
    for (let i = 0; i < obj.length; i++) {
      item.id == obj[i].id && e.checked ? obj[i].selected = true : obj[i].selected = false
    }
    this.calcCustomerAppPrice.emit()
  }
  // AppPlans = [
  //   {
  //     "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
  //     "orderNo": 1,
  //     "planId": "4",
  //     "title": "Loyalty",
  //     "image": "",
  //     selected: false,
  //     "description": "",
  //     "price": 9.99,
  //     "prices": [
  //       {
  //         "period": 2,
  //         "periodString": "Monthly",
  //         "priceLimitType": 1,
  //         "priceLimitTypeString": "BilledMonthly",
  //         "value": 0.0
  //       }
  //     ],
  //     PricePlan: [
  //       {
  //         PlanID: "1",
  //         MonthlyPrice: 9.99,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "2",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "3",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "4",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       }
  //     ]
  //     ,
  //     "features": [

  //       { "name": "", "value": "Banner Slider (Homepage)" },
  //       { "name": "", "value": "Featured Products" },
  //       { "name": "", "value": "Product image zoom" },
  //       { "name": "", "value": "eNewsletter Facility" },],
  //     "note": "",
  //     "rawData": "",
  //     "isIncluded": true,
  //     "isCustom": false,
  //     "isRenewal": true,
  //     "isTrial": false,
  //     "isUpgrade": true,
  //     "isDowngrade": true,
  //     "recommended": false,
  //     "isActive": true,
  //     "modified": "2022-10-14T13:48:49.9562747Z",
  //     "wlApplicationEnvironment": 2,
  //     "id": "162851697001",
  //     "createdDate": "2022-10-14T13:48:49.9562747Z",
  //     "partitionKey": "4"
  //   },
  //   {
  //     "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
  //     "orderNo": 2,
  //     "planId": "4",
  //     "title": "Shopping & Loyalty",
  //     "image": "",
  //     selected: false,
  //     "description": "",
  //     "value": "Included",
  //     "price": 49.99,
  //     "prices": [
  //       {
  //         "period": 2,
  //         "periodString": "Monthly",
  //         "priceLimitType": 1,
  //         "priceLimitTypeString": "BilledMonthly",
  //         "value": 0.0
  //       }
  //     ],
  //     PricePlan: [
  //       {
  //         PlanID: "1",
  //         MonthlyPrice: 9.99,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "2",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "3",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       },
  //       {
  //         PlanID: "4",
  //         MonthlyPrice: 0.0,
  //         YearlyPrice: 0.0,
  //         DiscountedPrice: 0.0,
  //       }
  //     ]
  //     ,
  //     "features": [
  //       { "name": "", "value": "Up to 500 products/variants Products" },
  //       { "name": "", "value": "Banner Slider (Homepage)" },
  //       { "name": "", "value": "Featured Products" },
  //       { "name": "", "value": "Product image zoom" },
  //       { "name": "", "value": "Product Sorting and Filtering" },
  //       { "name": "", "value": "Product reviews" },
  //       { "name": "", "value": "Age Verfication" },
  //       { "name": "", "value": "Product Quick View" },
  //       { "name": "", "value": "Compare and Add products to wishlist" },
  //       { "name": "", "value": "Abandoned cart recovery" },
  //       { "name": "", "value": "eNewsletter Facility" },
  //       { "name": "", "value": "Blogs/Videos" },
  //       { "name": "", "value": "Loyalty/Reward program" },
  //       { "name": "", "value": "Flexible Coupon Discounts" }],
  //     "note": "",
  //     "rawData": "",
  //     "isIncluded": true,
  //     "isCustom": false,
  //     "isRenewal": true,
  //     "isTrial": false,
  //     "isUpgrade": true,
  //     "isDowngrade": true,
  //     "recommended": false,
  //     "isActive": true,
  //     "modified": "2022-10-14T13:48:49.9562747Z",
  //     "wlApplicationEnvironment": 2,
  //     "id": "162851697002",
  //     "createdDate": "2022-10-14T13:48:49.9562747Z",
  //     "partitionKey": "4"
  //   },
  //   {
  //     "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
  //     "orderNo": 3,
  //     "planId": "4",
  //     "title": "Customized Design & Features",
  //     "image": "",
  //     selected: false,
  //     "description": "",
  //     "value": "299.99",
  //     "price": 299.99,
  //     "prices": [
  //       {
  //         "period": 2,
  //         "periodString": "Monthly",
  //         "priceLimitType": 1,
  //         "priceLimitTypeString": "BilledMonthly",
  //         "value": 299.99
  //       }
  //     ],
  //     "features": [
  //       { "name": "", "value": "Up to 1500 products/variants Products" },
  //       { "name": "", "value": "Banner Slider (Homepage)" },
  //       { "name": "", "value": "Featured Products" },
  //       { "name": "", "value": "Product image zoom" },
  //       { "name": "", "value": "Product Sorting and Filtering" },
  //       { "name": "", "value": "Product reviews" },
  //       { "name": "", "value": "Age Verfication" },
  //       { "name": "", "value": "Product Quick View" },
  //       { "name": "", "value": "Compare and Add products to wishlist" },
  //       { "name": "", "value": "Abandoned cart recovery" },
  //       { "name": "", "value": "eNewsletter Facility" },
  //       { "name": "", "value": "Blogs/Videos" },
  //       { "name": "", "value": "Loyalty/Reward program" },
  //       { "name": "", "value": "Flexible Coupon Discounts" }],
  //     "note": "",
  //     "rawData": "",
  //     "isIncluded": false,
  //     "isCustom": false,
  //     "isRenewal": true,
  //     "isTrial": false,
  //     "isUpgrade": true,
  //     "isDowngrade": true,
  //     "recommended": false,
  //     "isActive": true,
  //     "modified": "2022-10-14T13:48:49.9562747Z",
  //     "wlApplicationEnvironment": 2,
  //     "id": "162851697003",
  //     "createdDate": "2022-10-14T13:48:49.9562747Z",
  //     "partitionKey": "4"
  //   },

  // ]

}
