import { map } from 'rxjs';
import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-po-direct-receive',
  templateUrl: './po-direct-receive.component.html',
  styleUrls: ['./po-direct-receive.component.scss']
})
export class PoDirectReceiveComponent implements OnInit {
  locations: any = null
  vendors: any = null

  activeItem: any = null
  histList: any = null
  searchText = ''
  lastSearchText = ''
  method = 'Direct Receive'
  invoiceNo: ''
  //itemName : any
  receiceId: any = null
  dated = new Date()
  selectedLocation: any
  selectedVendor: any

  receivedById: any = ''
  receivedByName: any = ''

  isLoading = false
  isListLoading = false
  isDataLoaded = false

  dataList: any = []
  searchList: any = []
  selectedItemsRows: any = null

  gsubtotal = 0
  gdiscount = 0
  gtax = 0
  gitems = 0
  gNetTotal = 0

  productCost = 0.0
  shippingCost = 0.0
  grandTotal = 0

  isDuplicate = false
  postToInventory = false
  notes = ''

  isViewOnly = false
  isNew = false
  isEdit = false
  isEnableDeleteButton = false
  selectedShippingCost = 'enable'
  totalQuantity: number = 0
   quantityBasedShipping: number = 0;
   divideShippingCostInput: boolean = false;

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel

  isFooterNoteEnabled : boolean = false;
  footerNotesText: string = '';
  constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    public _vendorService: VendorsService,
    private _spinner: NgxSpinnerService,
    private _productService: ProductsService,
    private _messageService: MessageService,
    private _router: Router,
    private _confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.isNew = this._purchaseService.newEditItem.isNew
    this.isViewOnly = this._purchaseService.newEditItem.isViewOnly
    this.isEdit = this._purchaseService.newEditItem.isEdit

    this.getFooterNotesConfigValue();
    if (this._purchaseService.isNewDirectPO) {
      this.isNew = true
      this.isViewOnly = false
      this._purchaseService.isNewDirectPO = false
    }

    if (this.isNew) {
      this.receiceId = (new Date()).getTime()
      this._purchaseService.newEditItem.PONumber = this.receiceId

      this.receivedById = this._globals.userInfo.userID
      this.receivedByName = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    }

    if (!this.isNew) this.loadInit()

    this.locations = this._abd.initActLocations()
    this.vendors = this._abd.initActVendors()
  }

  loadInit() {
    this._spinner.show()
    this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id, false).subscribe(res => {
      if (res.success) {
        this.isDataLoaded = false

        this.activeItem = res.data
        let item = this.activeItem

        this.dataList = []

        if (res.data.dpoItems)
          this.dataList = JSON.parse(JSON.stringify(res.data.dpoItems))
          this.dataList.map(function(v){
            v.salePriceA = v.priceA
            v.salePriceB = v.priceB
            v.salePriceC = v.priceC
            v.shipping = v.shipping
            v.itemName=v.productName
          })
        item.receiveNo = this._purchaseService.generatePoNumber()
        item.receiveBy = this._globals.userInfo.displayName
        item.receiveDate = new Date(this.activeItem.dueDate)
        item.dated = new Date(this.activeItem.dated)
        item.locationObj = this.locations.find(f => f.locationId == item.locationId)
        this.selectedLocation = this.locations.find(f => f.locationId == item.locationId)
        item.vendorObj = this.vendors.find(f => f.id == item.vendorId)
        this.selectedVendor = this.vendors.find(f => f.id == item.vendorId)

        if (item.shippingByItem) this.selectedShippingCost = 'enable'
        else {
          if (item.dividedShippingItems) this.selectedShippingCost = 'divide'
          else this.selectedShippingCost = 'no-divide'
        }
        //this.itemName=item.productName
        //item.shipping = item.shipping
        this.shippingCost = item.shipping
        this.invoiceNo = item.invoiceNumber
        this.dated = new Date(item.dated)
        this.receivedByName = item.receivedBy
        this.receiceId = item.poNumber
        this.notes = item.notes

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
        //   if (this.selectedShippingCost == 'enable') this.dataList[i].shipping = 0
          this.dataList[i].receivedQty = this.dataList[i]?.receivedQuantity
        }
        this.calculateTotals()
      }
      this._spinner.hide()
    })
    this.getFooterNotesConfigValue();
  }

  getFooterNotesConfigValue() {
    try {
        this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
            if (r.success) {
                this.isFooterNoteEnabled = r.data.po_enablefooternote;
                this.footerNotesText = r.data.po_footernote
            }
            else {
                this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
            }
        }, error => {
            this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
        })
    } catch (error) {

    }
}

  locationChanged(e) { }

  vendorChanged(e) {
    this.lastSearchText = ''
  }

  showVendorInfoDialog = false
  showVendorInfoDialogFn() {
    this._vendorService.isViewOnly = true
    this._vendorService.activeItem = this.selectedVendor
    this.showVendorInfoDialog = true
  }

  searchButtonClick(e) {
    if (this._purchaseService.newEditItem.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
  }

  performSearch(e) {
    let ser = this._purchaseService.newEditItem

    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('locationId', this.selectedLocation.id)
    //params = params.append('vendorId', this.selectedVendor.id)
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        for (let i = 0; i < this.searchList.length; i++) {
          this.searchList[i].receivedQty = 1
          this.searchList[i].totalCost = 0
          this.searchList[i].selected = false
          //this.searchList[i].costPrice = 12.13 // to be deleted
        }

        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 1) this.op.toggle(e)
          else {
            this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
            this.op.hide()
          }
      }
    })
  }

  insertRow(e) {
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }

    let sku = ''

    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomId = uom.id
    data.uomDescription = uom.unitName
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.itemSkuId
    data.isValueChanged = true
    data.receivedQty = 1
    data.subTotal = data.costPrice * data.receivedQty
    data.discountPercent = 0
    data.discountAmount = 0
    data.taxAmount = 0
    data.netTotal = data.subTotal - data.discountAmount
    data.totalCost = data.netTotal - data.taxAmount
    data.lineNotes = ''
    data.shipping = 0
    data.notes = ''
    data.selected = false


    this.op.hide()

    this.dataList.push(data)
    this.calculateTotals()
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  discountAmountChanged(e, item) {
    try {
      if (e.value > 100) item.discountPercent = 100
      else item.discountPercent = e.value
      item.isValueChanged = true
    }
    catch { }
    this.calculateTotals()
  }

  taxAmountChanged(e, item) {
    try {
      item.taxRate = e.value
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  shippingChanged(e) {
    // console.log(e.value)
    this.shippingCost = e.value
    setTimeout(() => {
        this.calculateTotals()
    }, 600);

  }

  itemShippingChanged(e, item) {
    try {
      item.shipping = e.value
    //   if (this.divideShippingCostInput) {
        this.shippingCost = 0
        for (let i = 0; i < this.dataList.length; i++) {
          this.shippingCost += this.dataList[i].shipping
          // this.dataList[i].shipping = 0
        }
        // this.calculateTotals()
      }
   // }
    catch {
    }
    this.calculateTotals()
  }

  orderedQtyChanged(e, item) {
    try {
      item.receivedQty = e.value
      if (!item.receivedQuantity) item.receivedQuantity = 0
      else item.receivedQuantity = e.value

      this.calculateTotals()
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }


  calculateTotals() {
    this.gsubtotal = 0
    this.gdiscount = 0
    this.gtax = 0
    this.gitems = 0
    this.gNetTotal = 0

    let sum = 0
    let length = 0
    try { length = this.dataList.length }
    catch { length = 0 }

    if (length < 1) return

    let data = this.dataList

    this.totalQuantity = this.dataList.reduce((a,b)=> a+ b.receivedQty,0)
    this.quantityBasedShipping = (parseFloat(parseFloat(this.shippingCost.toString()).toFixed(2)) / this.totalQuantity )
    for (let i = 0; i < data.length; i++) {
      let dr = data[i]
      dr.srNo = i + 1

      dr.costPrice = !dr.costPrice ? 0 : dr.costPrice
      dr.receivedQuantity = !dr.receivedQuantity ? 1 : dr.receivedQuantity
      dr.discountPercent = !dr.discountPercent ? 0 : dr.discountPercent
      dr.taxRate = !dr.taxRate ? 0 : dr.taxRate
      this.shippingCost = !this.shippingCost ? 0 : this.shippingCost

      dr.netCost = dr.costPrice * dr.receivedQuantity
      dr.discountAmount = dr.netCost * (dr.discountPercent / 100)
      dr.netTotal = dr.netCost - dr.discountAmount

      dr.taxAmount = dr.netTotal * (dr.taxRate / 100)

      dr.totalCost = dr.netTotal + dr.taxAmount + dr.shipping

      this.gsubtotal += dr.netCost
      this.gdiscount += dr.discountAmount
      this.gtax += dr.taxAmount
      this.gNetTotal += dr.netTotal
      sum += dr.netTotal
    }

      if (this.divideShippingCostInput) {
        let sc = 0
        let self = this;
        this.dataList.map(function(v){
            let sc = v.receivedQty * self.quantityBasedShipping
            sc =  parseFloat(parseFloat(sc.toString()).toFixed(2))
            v.shipping = sc
        })
      }
    this.grandTotal = this.gNetTotal + this.shippingCost + this.gtax
    // console.log(this.dataList)
  }

  shippingOptionButtonClicked() {
    if(!this.divideShippingCostInput){
        this.dataList.map(f=> f.shipping = 0)
    }
    this.calculateTotals()
  }

  onChangeValShipping(e) {
    this.calculateTotals()
  }
  removeItem() {
    this.dataList = this.dataList.filter(f => !f.selected)
    for (let i = 0; i < this.searchList.length; i++) {
      this.searchList[i].selected = false
    }
    this.isEnableDeleteButton = true
    this.calculateTotals()
  }
  editButtonClick() {

    if (this.isViewOnly) {
      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = true
      this.isViewOnly = false
      this.isEdit = true
    }
    else if (this.isEdit) {
      this.isViewOnly = true
      this.isEdit = false
    }
    else{
        this.isViewOnly = false
        this._purchaseService.newEditItem.isViewOnly = false
    }
  }
  selectAllChanged(event) {
    if (event.checked) {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }

    this.showDeleteButton()
  }
  selectionChanged(e) {
    this.showDeleteButton()
  }
  showDeleteButton() {
    if (this.isViewOnly) return false
    if (this.dataList.length < 1) return false

    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].selected) {
        return true
      }
    }

    return false
  }

  onRowSelect(e) {
    this.op.hide()
    this.insertRow(e.data)
  }

  cancelPO() {
    this._confirmationService.confirm({
      header: `Cancel changes?`,
      message: 'Changes you made will be lost. Are you sure you want to proceed?',
      accept: () => {
        try {
          this._router.navigate(['app/purchase-orders'])
        } catch { this._abd.hideSpinner() }
      },
    });
  }

  savePO(what) {
    if (!this.dataList.length) {
      this._messageService.add({ severity: 'error', summary: 'Nothing to save!' })
      return
    }

    let itemsSelected = this.dataList.filter(f => f.receivedQty > 0)
    if (!itemsSelected || itemsSelected.length < 1) {
      this._messageService.add({ severity: 'error', summary: 'Nothing to save!' })
      return
    }

    if (!this.invoiceNo) {
      this._messageService.add({ severity: 'error', summary: 'Invalid invoice number!' })
      return
    }

    let isValidDate = this.dated.getTime()
    if (isNaN(isValidDate)) {
      this._messageService.add({ severity: 'error', summary: 'Invalid receiving date!' })
      return
    }

    let items = []
    for (let i = 0; i < itemsSelected.length; i++) {
      const obj = {
        "itemSKUId": this.dataList[i].itemSKUId,
        "invoiceNumber": this.invoiceNo,
        "notes": "",
        "receivedQuantity": this.dataList[i].receivedQty,
        "costPrice": this.dataList[i].costPrice,
        "priceA": this.dataList[i].salePriceA,
        "priceB": this.dataList[i].salePriceB,
        "priceC": this.dataList[i].salePriceC,
        "subTotal": this.dataList[i].netTotal,
        "discountPercent": this.dataList[i].discountPercent,
        "discountAmount": this.dataList[i].discountAmount,
        "netTotal": this.dataList[i].netTotal,
        "taxRate": this.dataList[i].taxRate,
        "taxAmount": this.dataList[i].taxAmount,
        "shipping": this.dataList[i].shipping,
        "totalAmount": this.dataList[i].totalCost,
      }
      items.push(obj)
    }
    let data = {
      "poNumber": this._purchaseService.newEditItem.PONumber,
      "invoiceNumber": this.invoiceNo,
      "dated": formatDate(this.dated, 'MM/dd/yyyy', 'en-US'),
      "vendorId": this.selectedVendor.id,
      "locationId": this.selectedLocation.locationId,
      "notes": this.notes,
      "poStatus": what,
      "subTotal": this.gsubtotal,
      "discountTotal": this.gdiscount,
      "netTotal": this.gNetTotal,
      "taxTotal": this.gtax,
      "shipping": this.shippingCost,
      "grandTotal": this.grandTotal,
      "receivedDate": formatDate(this.dated, 'MM/dd/yyyy', 'en-US'),
      "receivedById": this._globals.userInfo.userID,
      "shippingByItem": this.selectedShippingCost == 'enable' ? true : false,
      "dividedShippingItems": this.selectedShippingCost == 'divide' ? true : false,
      "dpoItems": items,
      "FooterNotes": this.isFooterNoteEnabled ? this.footerNotesText : ''
    }

    try {
      this._spinner.show()
      this._purchaseService.directOrder(data, this.postToInventory).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._messageService.add({ severity: 'success', summary: 'Operation completed successfully!' })

          if (!this.isDuplicate) {
            this._router.navigate(['app/purchase-orders'])
          }
          if (this.isDuplicate) {
            this.receiceId = (new Date()).getTime()
            this._messageService.add({ severity: 'info', summary: 'Duplicate created!' })
          }
        }
      })
    } catch { this._spinner.hide() }
  }

}
