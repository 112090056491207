<p-overlayPanel #op>
    <ng-template pTemplate>
        <div></div>
        <div class="conn-grid" style="margin-top: 10px">
            <label htmlFor="">Vendor</label>
            <p-dropdown
                [style]="{ 'min-width': '100%' }"
                class="p-dropdown-1"
                [options]="vendors"
                [(ngModel)]="selectedVendor"
                optionLabel="vendorName"
                [filter]="true"
                filterBy="vendorName"
            ></p-dropdown>

            <label htmlFor="">Start Date</label>
            <p-calendar
                [(ngModel)]="startDate"
                [showIcon]="true"
                inputId="icon"
            ></p-calendar>

            <label htmlFor="">End Date</label>
            <p-calendar
                [(ngModel)]="endDate"
                [showIcon]="true"
                inputId="icon"
            ></p-calendar>
        </div>

        <div class="conn-grid" style="margin-top: 10px">
            <div></div>
            <div></div>
            <div></div>
            <button
                pButton
                type="button"
                label="Search"
                icon="pi pi-search"
                iconPos="right"
                (click)="filterButtonPressed()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="conn-card">
    <div class="conn-flex">
        <div>
            <div>
                <div class="p-inputgroup" style="margin: 5px">
                    <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-times"
                        styleClass="p-button-danger"
                        (click)="clearSearchText()"
                        [disabled]="searchText.length < 1"
                    ></button>
                    <input
                        type="text"
                        pInputText
                        placeholder="Search text"
                        [(ngModel)]="searchText"
                        [style]="{ width: '300px' }"
                    />
                    <button
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-search"
                        styleClass="p-button-warn"
                        (click)="searchTextSearch()"
                        [disabled]="searchText.length < 1"
                    ></button>
                </div>
            </div>
        </div>
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-th-large"
            class="p-button-rounded p-button-text"
            (click)="op.toggle($event)"
            tooltipPosition="left"
            pTooltip="Click for filter options"
            style="margin-left: 20px"
        ></button>
    </div>

    <br />
    <p-table
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        [customSort]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Date</th>
                <th>P.O</th>
                <th>Vendor</th>
                <th style="text-align: center">Barcode</th>
                <th style="text-align: center">SKU</th>
                <th>Product</th>
                <th style="text-align: center">Purchase Qty</th>
                <th style="text-align: center">Purchases ($)</th>
                <th style="text-align: center">Discount (%)</th>
                <th style="text-align: right">Discount ($)</th>
                <th style="text-align: center">Tax (%)</th>
                <th style="text-align: right">Tax ($)</th>
                <th style="text-align: right">Total Cost ($)</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr class="text-center">
                <td>{{ item.dated | date: "LLL dd, YYYY" }}</td>
                <td>{{ item.poNumber }}</td>
                <td>{{ item.vendor }}</td>
                <td>--**--</td>
                <td style="text-align: center">{{ item.skuItemCode }}</td>
                <td>
                    {{ item.productName | slice: 0:35 }}
                    <span *ngIf="item.productName.length > 35">...</span>
                </td>
                <td style="text-align: center">{{ item.quantity }}</td>
                <td style="text-align: center">{{ item.subTotal | currency: "USD":"symbol-narrow":"0.2-2" }}</td>
                <td style="text-align: center">{{ item.discountPercent }}</td>
                <td style="text-align: right">{{ item.discountAmount  | currency: "USD":"symbol-narrow":"0.2-2"}}</td>
                <td style="text-align: center">{{ item.taxRate }}</td>
                <td style="text-align: right">{{ item.taxAmount  | currency: "USD":"symbol-narrow":"0.2-2"}}</td>
                <td style="text-align: right">{{ item.totalAmount  | currency: "USD":"symbol-narrow":"0.2-2"}}</td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator
        #p
        *ngIf="dataList"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    ></p-paginator>
</div>
