import { HttpClient, HttpBackend } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SignupService } from 'src/app/_services/signup.service';
import { environment } from 'src/environments/environment';

declare var window: any;

export interface iframeInterface {
    token: string;
    messages: Messages;
}

export interface subscInterface {
    refId: string;
    messages: Messages;
}

export interface Messages {
    resultCode: string;
    message: Message[];
}

export interface Message {
    code: string;
    text: string;
}

export interface BillTo {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

export interface TransactionData {
    accountType: string;
    accountNumber: string;
    transId: string;
    responseCode: string;
    authorization: string;
    merchantName: string;
    billTo: BillTo;
    totalAmount: string;
    dateTime: string;
}

@Component({
    selector: 'app-authorize-net-integration',
    templateUrl: './authorize-net-integration.component.html',
    styleUrls: ['./authorize-net-integration.component.scss']
})
export class AuthorizeNetIntegrationComponent implements AfterViewInit {
    title = 'pay';
    posting = false;
    @ViewChild('myFormPost', { static: false })
    myFormPost!: ElementRef;
    token: any = null
    paymentFormURL = 'https://test.authorize.net/payment/payment'
    customerProfileId: any

    SANDBOX_API_LOGIN_NAME = '5T6cxQ6t';
    SANDBOX_API_LOGIN_KEY = '2b33wmJuS23ac9Pu';
    SANDBOX_API_AUTHORIZE_NET_PAYMENT_URL =
        'https://test.authorize.net/payment/payment';

    LOY_API_LOGIN_NAME = '76N3Zq7k5';
    LOY_API_LOGIN_KEY = '2CsD7T7x48ruw642';
    LOY_API_AUTHORIZE_NET_PAYMENT_URL =
        'https://accept.authorize.net/payment/payment';

    private httpClient: HttpClient;

    constructor(
        private handler: HttpBackend,
        private http: HttpClient,
        private window: Window,
        private _signup: SignupService,
        private sanitizer: DomSanitizer,
    ) {
        this.customerProfileId = Math.floor(Date.now() / 1000).toString().substring(0, 9)
        this.setAuthorizePaymentEnvironment()
        this.httpClient = new HttpClient(handler)
        this.onPost()
    }

    ngOnInit(): void {
        // console.log('RETURN-OBJECT FROM CHILD===>', this._signup.mainReturnObject)
    }

    setAuthorizePaymentEnvironment() {
        if (environment.AuthorizeEnvironment == 'testing') {
            this.paymentFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.LOY_API_AUTHORIZE_NET_PAYMENT_URL) + ''
        } else if (environment.AuthorizeEnvironment == 'staging') {
            this.paymentFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.LOY_API_AUTHORIZE_NET_PAYMENT_URL) + ''
        } else if (environment.AuthorizeEnvironment == 'production') {
            this.paymentFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.LOY_API_AUTHORIZE_NET_PAYMENT_URL) + ''
        } else {
            this.paymentFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.LOY_API_AUTHORIZE_NET_PAYMENT_URL) + ''
        }
    }

    //

    onPost(): void {
        let token = {
            "getHostedPaymentPageRequest": {
                "merchantAuthentication": {
                    "name": "76N3Zq7k5",
                    "transactionKey": "2CsD7T7x48ruw642"
                },
                "transactionRequest": {
                    "transactionType": "authCaptureTransaction",
                    "amount": Math.floor(Date.now() / 1000).toString().substring(0, 3),
                    "profile": {
                        "customerProfileId": Math.floor(Date.now() / 1000).toString().substring(0, 9)

                    },
                    "customer": {
                        "email": "abcsdfahfg@mail.com"
                    },
                    "billTo": {
                        "firstName": "ali",
                        "lastName": "hassan",
                        "company": "Zilon",
                        "address": "johar town Main st",
                        "city": "sargodha",
                        "state": "TX",
                        "zip": "44628",
                        "country": "USA"
                    }
                },
                "hostedPaymentSettings": {
                    "setting": [{
                        "settingName": "hostedPaymentReturnOptions",
                        "settingValue": "{\"showReceipt\": false, \"url\": \"https://stg.connect-cbd.com/authorize-success.html\", \"urlText\": \"Continue\", \"cancelUrl\": \"https://stg.connect-cbd.com/setup/payment.html\", \"cancelUrlText\": \"Cancel\"}"
                    }, {
                        "settingName": "hostedPaymentButtonOptions",
                        "settingValue": "{\"text\": \"Pay\"}"
                    }, {
                        "settingName": "hostedPaymentStyleOptions",
                        "settingValue": "{\"bgColor\": \"blue\"}"
                    }, {
                        "settingName": "hostedPaymentPaymentOptions",
                        "settingValue": "{\"cardCodeRequired\": false, \"showCreditCard\": true, \"showBankAccount\": false}"
                    }, {
                        "settingName": "hostedPaymentSecurityOptions",
                        "settingValue": "{\"captcha\": false}"
                    }, {
                        "settingName": "hostedPaymentShippingAddressOptions",
                        "settingValue": "{\"show\": false, \"required\": false}"
                    }, {
                        "settingName": "hostedPaymentBillingAddressOptions",
                        "settingValue": "{\"show\": true, \"required\": false}"
                    }, {
                        "settingName": "hostedPaymentCustomerOptions",
                        "settingValue": "{\"showEmail\": false, \"requiredEmail\": false, \"addPaymentProfile\": true}"
                    }, {
                        "settingName": "hostedPaymentOrderOptions",
                        "settingValue": "{\"show\": true, \"merchantName\": \"G and S Questions Inc.\"}"
                    }, {
                        "settingName": "hostedPaymentIFrameCommunicatorUrl",
                        "settingValue": "{\"url\": \"https://stg.connect-cbd.com/IFrameCommunicator.html\"}"
                    }]
                }
            }
        }
        this.http.post<iframeInterface>('https://api2.authorize.net/xml/v1/request.api', token).subscribe((data) => {
            if (data["token"] != "") {
                this.token = data["token"];
                this.posting = true;
            }
            else {
                this.onPost();
            }
        })
    }


    getToken() {
        return this.token
    }

    ngAfterViewChecked() {
        if (this.posting && this.myFormPost) {
            this.myFormPost.nativeElement.submit();
        }
    }

    ngAfterViewInit() {
        window.CommunicationHandler = {};
        window.CommunicationHandler.onReceiveCommunication = (argument: any) => {
            //Get the parameters from the argument.
            let params: any = this.parseQueryString(argument.qstr);
            switch (params['action']) {
                case 'resizeWindow':
                    localStorage.setItem('payment-successfully', '0');
                    break;
                case 'successfulSave':
                    localStorage.setItem('payment-successfully', '1');
                    break;
                case 'cancel':
                    localStorage.setItem('payment-successfully', '0');
                    break;
                case 'transactResponse':
                    const transResponse: TransactionData = JSON.parse(params['response']);
                    this.proceed(transResponse.transId);
                    this.posting = false;
                    break;
                default:
                    // console.log('Unknown action: ' + params['action'])
                    break;
            }
        };
    }

    proceed(transId: string) {
        // console.log('PROCEED', transId)
        throw new Error('Method not implemented.');
    }

    parseQueryString(str: any) {
        var vars = [];
        var arr = str.split('&');
        var pair;
        for (var i = 0; i < arr.length; i++) {
            pair = arr[i].split('=');
            //vars[pair[0]] = unescape(pair[1]);
            vars[pair[0]] = pair[1];
        }
        return vars;
    }

    prepareObject() {

    }

    sendData2Server() { }

    getHardwarePkg() {

    }

}
