<div class="conn-card">
    <app-report-top-filter-bar  *ngIf="0==0"
        [paraReportName]="'tender-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>
    <br />
    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true"  *ngIf="dataList?.length>0">
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="text-align: center" *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th> -->
                <th><strong>Location Name</strong></th>
                <th><strong>Payment Method</strong></th>
                <th style="text-align: center"><strong>Invoice Count</strong></th>
                <th style="text-align: center"><strong>Payment Method Count</strong></th>
                <th style="text-align: center"><strong>Total Sales</strong></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-expanded="expanded">
            <tr *ngFor="let order of product.paymentMethodSales">
                <td style="text-align: center">{{ product.locationName }}</td>
                <td style="text-align: center">{{ order.paymentMethod }}</td>
                <td style="text-align: center">{{ order.invoiceCount }}</td>
                <td style="text-align: center">{{ order.paymentMethodCount }} </td>
                <td style="text-align: center">{{ order.totalSales | currency }}</td>
                <!-- <td style="text-align: center">{{ order.returnInvoiceCount }}</td>
                <td style="text-align: center">{{ order.returnAmount | currency }}</td> -->
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                
                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>

</div>