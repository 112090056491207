<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'app-inventory-adjustement-summary-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>


    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"  [autoLayout]="true"   responsiveLayout="scroll" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: left"  *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-purchase>
            <tr>
                <td>
                   <span>{{ purchase.dated | date:'MM/dd/yyyy'  }}</span>
                </td>
                <td>
                   <span>{{ purchase.user }}</span>
                </td>
                <!-- <td>
                   <span>{{ purchase.adjustmentId }}</span>
                </td> -->
                <td>
                   <span>{{ purchase.adjustmentType }}</span>
                </td>
                <td>
                   <span>{{ purchase.locationName }}</span>
                </td>
                <td>
                   <span>{{ purchase.cost | currency }}</span>
                </td>
                <!-- <td>
                   <span>{{ purchase.totalPrice | currency }}</span>
                </td> -->
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: left">
                    <strong>Total</strong>
                </td>

                <td *ngFor="let col of domSummaryflds" style="text-align: left">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
