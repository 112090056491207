import { UserService } from 'src/app/_services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-settings-rights-management',
  templateUrl: './settings-rights-management.component.html',
  styleUrls: ['./settings-rights-management.component.scss']
})
export class SettingsRightsManagementComponent implements OnInit {
  _globals = GlobalService

  roles: any = []
  rights: TreeNode[]
  rightGroups: any = []
  selectedRole: any = null
  checkAllRights = false
  roletree: any = []

  constructor(private _router: Router,
    private _userService: UserService) { }

  ngOnInit(): void {
    this._globals.componentName = 'Rights Management'
    this.initLoad()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  initLoad() {
    this._userService.getRightGroups().subscribe(res => {
      this.rightGroups = res.data.rightsGroup
      //console.log("RIGHTS-GROUPS", this.rightGroups)
    })

    this._userService.getRoles().subscribe(res => {
      if (res.success) {
        this.roles = res.data.role
        //console.log("ROLES", this.roles)
        if (this.roles) this.selectedRole = this.roles[0].name
      }
    })

    this._userService.getRights().subscribe(res => {
      // this.rights =<TreeNode[]> (res.data.right)
      this.rights = <TreeNode[]> this.makeRollTree(res.data.right)
      //console.log("RIGHTS", this.rights)
    })
  }

  makeRollTree(arr:any) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.rightId] = arrElem;
      mappedArr[arrElem.rightId]['children'] = [];
    }

    for (var rightId in mappedArr) {
      if (mappedArr.hasOwnProperty(rightId)) {
        mappedElem = mappedArr[rightId];
        if (mappedElem.parentId) {
          mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    ////console.log('POINT-2',tree)
    return tree;
  }



}
