import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { delay, retry } from 'rxjs';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    selector: 'app-signup-verification',
    templateUrl: './signup-verification.component.html',
    styleUrls: ['./signup-verification.component.scss'],
})
export class SignupVerificationComponent implements OnInit {
    @ViewChild('inputMask', { static: true }) inputMask: ElementRef;
    varificationCode = '';
    showResendCode = false;
    showConfirmDialog = false;
    displayConfirmContinueDialog = false;
    enableVerifyButton = true;

    delay = 1 * 60 * 1000;
    timeLeft = 0;
    timeInterval: any;
    timeIntervalString = '2:00 sec';

    _globals = GlobalService;
    trialDate: Date = new Date();
    @Output() backButtonClick = new EventEmitter();
    constructor(
        public _signup: SignupService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService,
        private renderer: Renderer2,
        private activeRouter: ActivatedRoute
    ) {}
    ngAfterViewInit() {
        // Set the focus on the input element when the component loads
        if (!this._signup.isLoginWithSubDashboard()) {
            this.renderer
                .selectRootElement(this.inputMask.nativeElement)
                .focus();
        }
    }
    ngOnInit(): void {
        if (
            !this._signup.accountVerified &&
            !this._signup.isLoginWithSubDashboard()
        ) {
            let otpSend: any = sessionStorage.getItem('otpSend');
            if (otpSend == undefined) {
                this.resendCodeClicked();
            }
            this.doTimerRoutine();
        }

        if (
            this._signup.accountVerified ||
            this._signup.isLoginWithSubDashboard()
        ) {
            let selectedPlanTemp =
                this.activeRouter.snapshot.queryParamMap
                    .get('selected_plan')
                    ?.toString() ?? '';
            if (this._signup.selectedPlan.isTrial || selectedPlanTemp == '1') {
                this.displayConfirmContinueDialog = true;
                this.enableVerifyButton = false;
                this._signup.accountVerified = true;

                var now = new Date();
                this.trialDate = new Date(now.setDate(now.getDate() + 30));
                this.trialAccountCreation();
            }
        }
    }

    doTimerRoutine() {
        clearInterval(this.timeInterval);
        this.showResendCode = false;
        this.timeLeft = this.delay - 1000;
        this.timeInterval = setInterval(() => {
            if (this.timeLeft <= 0) {
                clearInterval(this.timeInterval);
                sessionStorage.removeItem('otpSend');
            }
            let d = this.timeLeft / 1000;
            let min = Math.floor(d / 60);
            let sec = d - min * 60;

            this.timeIntervalString =
                min > 0
                    ? `${min}:${('00' + sec).slice(-2)} sec`
                    : `${('00' + sec).slice(-2)} sec`;
            this.timeLeft -= 1000;
        }, 1000);

        setTimeout(() => {
            this.showResendCode = true;
            clearInterval(this.timeInterval);
        }, this.delay); // delay for 2 minutes
    }

    ngOnDestroy() {
        clearInterval(this.timeInterval);
    }

    confirm() {
        this.displayConfirmContinueDialog = !this.displayConfirmContinueDialog;
    }

    backClicked() {
        this.backButtonClick.emit(true);
        this._signup.currentStep--;

        sessionStorage.setItem(
            'currentStep',
            this._signup.currentStep.toString()
        );
    }

    resendCodeClicked() {
        if (!this._signup.accountVerified) {
            this.doTimerRoutine();
            const obj = {
                BusinessId: this._signup.businessInfo.businessId,
                UserId: this._signup.personalInfo.userID,
            };

            this._signup.resendCode(obj).subscribe((res) => {
                if (res.success) {
                    sessionStorage.setItem('otpSend', '1');
                    this._connMessageService.showToastMessage(
                        `A new OTP code is sent to your email!`,
                        'success'
                    );
                } else {
                    this._connMessageService.showToastMessage(
                        `Problem sending OTP!`,
                        'error'
                    );
                }
            });
        }
    }

    verifyMyEmailButtonClicked() {
        try {
            if (
                this._signup.businessInfo.businessId > 0 &&
                this._signup.personalInfo.userID != ''
            ) {
                const obj = {
                    BusinessId: this._signup.businessInfo.businessId,
                    UserId: this._signup.personalInfo.userID,
                    Code: this.varificationCode.replace(/\D/g, ''),
                };

                this._spinner.show();

                this._signup.verifyOTP(obj).subscribe((res) => {
                    if (res.success) {
                        if (!res.data) {
                            this._signup.accountVerified = false;
                            sessionStorage.setItem('accountVerified', '0');
                            this.displayConfirmContinueDialog = false;
                            if (res.code == 'AS0032906') {
                                res.message =
                                    'You have entered invalid otp code';
                            }
                            this.enableVerifyButton = true;
                            this._connMessageService.showToastMessage(
                                `${res.message}!`,
                                'error'
                            );
                        } else {
                            // if trial account created
                            if (this._signup.freeTrialAccount) {
                                var now = new Date();
                                this.trialDate = new Date(
                                    now.setDate(now.getDate() + 30)
                                );
                                this.trialAccountCreation();
                            } else {
                                this._signup.accountVerified = true;
                                sessionStorage.setItem('accountVerified', '1');
                                this.displayConfirmContinueDialog = true;
                                this.enableVerifyButton = false;
                            }
                        }
                    } else
                        this._connMessageService.showToastMessage(
                            `Unable to verify OTP!`,
                            'error'
                        );
                });
            }
        } catch (error) {}
    }

    login() {
        this._router.navigate(['/pages/signin']);
        sessionStorage.clear();
    }

    continueButtonClicked() {
        this.enableVerifyButton = false;
        this._signup.currentStep++;
        this._signup.savedSteps++;
        this.displayConfirmContinueDialog = false;
        sessionStorage.setItem(
            'currentStep',
            this._signup.currentStep.toString()
        );
        sessionStorage.setItem(
            'savedSteps',
            this._signup.savedSteps.toString()
        );
    }

    nextStep() {
        this._signup.currentStep++;
        this._signup.savedSteps++;
        this.displayConfirmContinueDialog = false;
        sessionStorage.setItem(
            'currentStep',
            this._signup.currentStep.toString()
        );
        sessionStorage.setItem(
            'savedSteps',
            this._signup.savedSteps.toString()
        );
    }

    trialAccountCreation() {
        try {
            if (!this._signup.isLoginWithSubDashboard()) {
                let data = {
                    businessId: this._signup.businessInfo.businessId,
                    NoOfLocations: 1,
                    PerNoOfRegister: 1,
                    NoOfRegisters: 1,
                    userID: this._signup.personalInfo.userID,
                    SaveInfo: false,
                    subscription: {
                        TransactionDetails: null,
                        hardwarePkgId: [],
                        IsFreeTrial: true,
                        totalHardwarePkgCost: 0,
                        planCost: 0,
                        planId: this._signup.selectedPlan.id,
                        totalCost: 0,
                        subscriptionPlanPkg: [
                            {
                                packageId: this._signup.selectedPlan.id,
                                qty: 1,
                                pricePerPackage:
                                    this._signup.checkoutPageCalcVars.planCost,
                                subTotalCost: 0,
                                packageType: 0,
                                discountValue: 0,
                                taxValue: 0,
                                discountAmount: 0,
                                taxAmount: 0,
                                isDiscountPercent: false,
                                isTaxPercent: false,
                                totalPrice: 0,
                            },
                        ],
                        'WooCommercePlanPkg ': [],
                        DomainPlanPkg: [],
                        hardwarePkg: [],
                        TotalTaxAmount: 0,
                        subTotalCost: 0,
                        TotalDiscountAmount: 0,
                        TotalTaxValue: 0,
                        TotalDiscountValue: 0,
                        IsTotalTaxPercente: false,
                        IsTotalDiscountPercent: false,
                        SubscribedFeatures: [],
                    },
                    CCCustomerProfile: null,
                };

                this._signup.saveUserSignupInfo(data).subscribe(
                    (res) => {
                        if (res.success && res.responseStatusCode == 903) {
                            this.displayConfirmContinueDialog = true;
                            this.enableVerifyButton = false;
                            this._signup.accountVerified = true;
                            this._signup.loadInit();
                            sessionStorage.setItem('accountVerified', '1');
                        } else {
                            this._connMessageService.showToastMessage(
                                res.message,
                                'error'
                            );
                        }
                    },
                    (error) => {
                        this._connMessageService.showToastMessage(
                            error.message,
                            'error'
                        );
                    }
                );
            } else {
                this.displayConfirmContinueDialog = true;
                this.enableVerifyButton = false;
                this._signup.accountVerified = true;
                this._signup.loadInit();
                sessionStorage.setItem('accountVerified', '1');
            }
        } catch (error) {}
    }
}
