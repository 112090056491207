import { Component, OnInit } from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-subscription-plan-details',
  templateUrl: './subscription-plan-details.component.html',
  styleUrls: ['./subscription-plan-details.component.scss']
})
export class SubscriptionPlanDetailsComponent implements OnInit {

  constructor(
    public _signup: SignupService,
  ) { }

  ngOnInit(): void {
  }

}
