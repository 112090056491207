<p-overlayPanel #op [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }">
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button pButton pRipple type="button" label="Edit" class="p-button-text" [style]="{ width: '100%' }"
                icon="pi pi-pencil" iconPos="right" (click)="optionEditClicked()"></button>
            <button pButton pRipple type="button" label="Delete" class="p-button-danger p-button-text"
                icon="pi pi-trash" iconPos="right" (click)="optionDeleteClicked()"></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="conn-card">
    <app-report-top-filter-bar [paraReportName]="'departments'" (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()" (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()" (exportCsvFn)="dt.exportCSV()" (deleteItemFn)="deleteMultipleItem()"
        (addNewFn)="addNewFn($event)"></app-report-top-filter-bar>
    <div class="conn-flex"></div>

    <br />
    <p-table #dt [columns]="csvcols" styleClass="p-datatable-striped" [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }" [value]="dataList" responsiveLayout="scroll" showLoader="true"
        rowHover="true" selectionMode="single" [(selection)]="selectedRow" dataKey="id"
        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [lazy]="true"
        (onLazyLoad)="customSort($event)">
        <ng-template pTemplate="header">
            <tr style="text-align: center">
                <th style="text-align: center; width: 30px">
                    <p-checkbox [(ngModel)]="selectAll" [binary]="true"
                        (onChange)="selectAllChanged($event)"></p-checkbox>
                </th>

                <th pSortableColumn="departmentName">
                    <p-sortIcon field="departmentName"></p-sortIcon>Department
                </th>
                <th class="text-center">Taxable</th>
                <th class="text-center">Show Online</th>
                <th class="text-center">Tax Free Weekend</th>
                <th class="text-center">Allow Discount</th>
                <th class="text-center">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td style="text-align: center">
                    <p-checkbox [(ngModel)]="item.selected" [binary]="true"
                        (onChange)="selectionChanged($event)"></p-checkbox>
                </td>

                <td>
                    <span class="conn-flex-image-first-letter">
                        {{ item.departmentName | slice : 0 : 2 }}</span>
                    {{ item.departmentName }}
                </td>

                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td>
                    <button pButton pRipple icon="pi pi-angle-down" iconPos="right" type="button" label="Actions"
                        class="n-action-button" style="text-align: center" (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="10" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator #p *ngIf="dataList?.length" (onPageChange)="onPageChange($event)" [rows]="rows"
        [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"></p-paginator>
</div>

<p-dialog *ngIf="_departmentService.showNewEdit" header="Edit Department " [(visible)]="_departmentService.showNewEdit"
    modal="true">
    <app-new-edit-department [isNew]="false" (refreshFn)="refreshFn($event)"></app-new-edit-department>
</p-dialog>

<p-dialog *ngIf="_departmentService?.showNewNewEdit" header="New Department"
    [(visible)]="_departmentService.showNewNewEdit" [style]="{ width: '400px' }" modal="true">
    <app-new-edit-department [isNew]="true" (refreshFn)="refreshFn($event)"></app-new-edit-department>
</p-dialog>