import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-invoice-customer-details',
  templateUrl: './invoice-customer-details.component.html',
  styleUrls: ['./invoice-customer-details.component.scss']
})
export class InvoiceCustomerDetailsComponent implements OnInit {
 

  constructor(public _reportService: ReportsService,) { }

  ngOnInit(): void {
  }

}
