import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { LoyaltyConfig } from 'src/app/_models/reward-program/loyalty-configuration';
import { PerkProduct, PerksReward, PerksRewardTypes } from 'src/app/_models/reward-program/perk-model';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';

interface DropDownModel {
    name: string,
    title: string
}

@Component({
  selector: 'app-new-edit-regular-reward',
  templateUrl: './new-edit-regular-reward.component.html',
  styleUrls: ['./new-edit-regular-reward.component.scss']
})
export class NewEditRegularRewardComponent implements OnInit, AfterContentInit {

    statusList: DropDownModel[];
    rewardTypeList: DropDownModel[];

    currentSelectedStatus: DropDownModel;

    perkForm!: FormGroup;
    productList: any[] =[];
    discountType: string = "Flat Discount";
    searchText: string = "";
    searchList: any = []
    @ViewChild('op') op: OverlayPanel
    loadingSearchData: boolean = false;
    @ViewChild('search') searchElement: ElementRef;
    selectAll: boolean = false;
    @Input() isNew: boolean = true;
    @Output() onSubmit = new EventEmitter();

    page = 0
    rows = 10
    first = 0
    totalRecords = 0
    lastSearchText = ''
    oldPage: number =  0;

    marginStyle = {  'margin-top': '20px' };

    selectedCategory: any = null ;
    selectedDepartment: any = null;
    toggleOverlay = ({ originalEvent }) => this.op.toggle(originalEvent);
    isListLoading:boolean = false;

    categoriesList: any[] = [];
    departmentList: any[] = [];
    @Input() loyaltyObj = <LoyaltyConfig>{}
    showProductDialog: boolean = false;
    _globals = GlobalService
    //order
    orderParam = 'itemName'
    orderBy = 'asc'
    tempSearchProduct: any[] = [];
    firstTimeLoad: boolean = false;
    constructor(
        private fb: FormBuilder,
        private _messageService: MessageService,
        private _productService: ProductsService,
        public _rewardProgramService: RewardProgramService,
        public _abd: AppBaseDataService,
        private _filterService: FilterServiceService,
        private confirmationService: ConfirmationService
        ) {

        this.currentSelectedStatus = { name: 'Active', title: 'Active' }
    }

    ngAfterContentInit(): void {
        if (!this.isNew) {
            this.f.displayName.setValue(this._rewardProgramService.discountRewardObj.displayName);
            this.f.pointsGoal.setValue(this._rewardProgramService.discountRewardObj.pointsGoal);
            let s = this._rewardProgramService.discountRewardObj.active;
            this.f.status.setValue(s ? "Active": "Inactive")
            this.firstTimeLoad = true;
            this.f.rewardType.setValue(this._abd.getWordsWithSpace(this._rewardProgramService.discountRewardObj.rewardType))

            this.discountType = this.f.rewardType.value

            if (this.discountType == 'Flat Discount') {
                this.f.discountAmount.setValue(this._rewardProgramService.discountRewardObj.discountAmount);
            }

            if (this.discountType == 'Percentage Discount') {
                this.f.maxDiscount.setValue(this._rewardProgramService.discountRewardObj.maxDiscount);
                this.f.percentageDiscount.setValue(this._rewardProgramService.discountRewardObj.discountAmount);
            }

            if (this.discountType == 'Free Items') {
                if (this._rewardProgramService.discountRewardObj.perkProducts.length > 0){
                    this.productList = [];
                    let self = this;
                    this._rewardProgramService.discountRewardObj.perkProducts.map(function(value){
                        self.productList.push({
                            serial: self.productList.length + 1,
                            product: value.productName,
                            cost: value.cost,
                            priceA: value.priceA,
                            quantity: value.qty,
                            itemSkuId: value.itemSKUId,
                            productCode: value.productCode,
                            valid: true,
                            checked: true
                        });
                    })
                }

            }
        }
    }

    ngOnInit(): void {
        this._globals.componentName = 'Products'
        this.statusList = [
            { name: 'Active', title: 'Active' },
            { name: 'Inactive', title: 'Inactive' },
        ];
        this.rewardTypeList = [
            { name: 'Flat Discount', title: 'Flat Discount' },
            { name: 'Percentage Discount', title: 'Percentage Discount' },
            { name: 'Free Items', title: 'Free Items' },
        ];
        this.firstTimeLoad = true;
        this.initAddPerkForm();
    }

    // Form
    get f() { return this.perkForm.controls; }

    initAddPerkForm() {
        this.selectAll = false;
        this.perkForm = this.fb.group({
            displayName: ['', Validators.required],
            status: [this.statusList[0].name, Validators.required],
            rewardType: [this.rewardTypeList[0].name, Validators.required],
            pointsGoal: [0, Validators.compose([Validators.required,  Validators.maxLength(10), Validators.min(0)])],
            discountAmount: [0.00, Validators.compose([Validators.required,  Validators.maxLength(10), Validators.min(0)])],
            maxDiscount: [0.00, Validators.compose([Validators.required, Validators.maxLength(10), Validators.min(0)])],
            percentageDiscount: [0.00, Validators.compose([Validators.required, Validators.maxLength(10), Validators.min(0)])],
        });
        this.productList = [];
        this.tempSearchProduct = [];
         this.searchList = [];
        this.showProductDialog = false;
        this.categoriesList = this._abd.initActCategories();
        let catObj: any = { ...this.categoriesList[0] }
        catObj.categoryName = "Select All"
        catObj.id = ""
        this.categoriesList.unshift(catObj)
        this.selectedCategory = this.categoriesList[0];


        this.departmentList = this._abd.initActDepartments()
        let departObj: any = { ...this.departmentList[0] }
        departObj.departmentName = "Select All"
        departObj.id = ""
        this.departmentList.unshift(departObj)
        this.selectedCategory = this.departmentList[0];

    }

    saveForm(){
        try {
            if (this.perkForm.invalid) {
                // Got focus to the error field
                let invalidFields = [].slice.call(
                    document.getElementsByClassName('ng-invalid')
                );
                invalidFields[1].focus();
            }

            if (this.discountType == 'Free Items'){

                if(this.productList.length == 0){
                    this._messageService.add({ severity: 'error', summary: 'Please select product' })
                    return false;
                }
            }

            let obj = <PerksReward>{};
            if (!this.isNew) {
                obj.loyaltyConfigId = this._rewardProgramService.discountRewardObj.id
            }

            obj.displayName = this.f.displayName.value;
            obj.active = this.f.status.value == 'Active' ? true : false;
            obj.rewardType = this.setRewardTypeValue();
            obj.pointsGoal = this.f.pointsGoal.value;
            obj.discountAmount = this.f.discountAmount.value;
            obj.maxDiscount = this.f.maxDiscount.value;

            if (this.discountType == 'Flat Discount') {
                obj.discountAmount = this.f.discountAmount.value;
            }

            if (this.discountType == 'Percentage Discount') {
                obj.discountAmount = this.f.percentageDiscount.value
            }

            if(!this.isNew){
                obj.id = this._rewardProgramService.discountRewardObj.id;
            }

            if (this.productList.length > 0){

                let pList: PerkProduct[] = [];
                this.productList.map(function(value){
                    let p = <PerkProduct>{};
                    p.itemSKUId = value.itemSkuId;
                    p.qty = value.quantity;
                    pList.push(p)
                })
                obj.perkProducts = pList

            }
            this.updateSerial();
            this._abd.showSpinner()

            this._rewardProgramService.saveTieredRewardInfo(obj).subscribe((r)=>{
                if (r.success) {
                    this._rewardProgramService.showNewEditTieredModal = false;
                    this.onSubmit.emit(true);
                    this._messageService.add({ severity: 'success', summary: 'Tiered program has been saved' })
                }
            }, error => {
                this._messageService.add({ severity: 'error', summary: 'Unable to save tiered program ' })
            })

        } catch (error) {

        }
    }

    setRewardTypeValue() {
        try {
            let reward = "";
            switch (this.f.rewardType.value) {
                case 'Flat Discount':
                    reward = PerksRewardTypes.FlatDiscount.toString()

                    break;
                case 'Percentage Discount':
                    reward = PerksRewardTypes.PercentageDiscount.toString()
                    break;

                case 'Free Items':
                    reward = PerksRewardTypes.FreeItems.toString()
                    break;
            }

            return reward;
        } catch (error) {

        }
    }

    rewardType(){
        if (this.f.rewardType.value) {
            this.discountType = this.f.rewardType.value

            switch (this.discountType) {
                case 'Flat Discount':

                    if (this.f.maxDiscount.invalid) {
                        this.f.maxDiscount.setValue(0);
                    }

                    if (this.f.percentageDiscount.invalid) {
                        this.f.percentageDiscount.setValue(0);
                    }


                    break;
                case 'Percentage Discount':
                    if (this.f.discountAmount.invalid){
                        this.f.discountAmount.setValue(0);
                    }

                    break;

                case 'Free Items':
                    break;
            }
        }
    }

    searchButtonClick(e) {
        this.performSearch(e)
    }

    makeParams() {
        try {
            let params = new HttpParams()


            params = params.append('pageNo', this.page);
            params = params.append('pageSize', this.rows);
            params = params.append('locationId', '')
            params = params.append('vendorId', '')

            params = params.append('categoryId', this.selectedCategory)
            params = params.append('departId', this.selectedDepartment)
            params = params.append('orderParam', this.orderParam)
            params = params.append('orderBy', this.orderBy)


            params = params.append('filterValue', this.searchText)
            return params
        } catch (error) {

        }
    }

    performSearch(e) {
        //let ser = this._purchaseService.newEditItem
        this.selectAll = false;

        this.page = this.page < 0 ? 0 : this.page
        this.rows = this.rows < 1 ? 10 : this.rows

        this.oldPage = this.page;
        this.loadingSearchData = true;
        setTimeout(() => { // this will make the execution after the above boolean has changed
            this.searchElement.nativeElement.blur();
        }, 0);

        this.getProducts();
       // this.op.toggle(e)
    }

    getProducts(){
        try {
           // this.searchList = [];
            // let qryParas = null
            // if (this.orderParam && this.orderParam.length > 0) {
            //     qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`
            // }
            this.isListLoading = true;
            this.firstTimeLoad = false;

            this._productService.getAllProductList4Inventory(this.makeParams()).subscribe(res => {
                if (res.success) {
                    if (res.data.length > 0){
                        this.firstTimeLoad = false;
                        let self = this;
                        this.searchList = [];


                        this.searchList = res.data

                        this.searchList.map(f => f.showAll = true)
                        this.totalRecords = res.totalRecords
                        this.searchList.map(function(value){
                            value.cost = value.crsItemSKUs[0].costPrice;
                            value.priceA = value.crsItemSKUs[0].salePriceA;
                            value.skuCode = value.crsItemSKUs[0].skuCode;
                            value.barcode = value.crsItemSKUs[0].barcode;
                            value.itemSkuId = value.crsItemSKUs[0].id;
                            value.alreadySaved = false;
                        })


                        this.loadingSearchData = false;
                        setTimeout(() => { // this will make the execution after the above boolean has changed
                            this.searchElement.nativeElement.focus();
                        }, 0);

                        this.searchList.map(f => f.checked = false)
                        this.searchList = this.searchList.filter(f => f.showAll)

                        // if (this.selectAll){
                        //     this.searchList.filter(f => f.checked = true)
                        // }


                    if (this.productList.length > 0) {
                       // this.selectAll = true;

                        this.searchList.map(function (v, i) {
                            v.checked = false;

                            if (self.productList.filter(f => f.itemSkuId == v.itemSkuId).length > 0) {
                                v.checked = true;
                                v.alreadySaved = true
                               // v.showAll = false
                            }
                            else{
                                //self.selectAll = false;
                            }

                            if (self.tempSearchProduct.filter(f => f.itemSkuId == v.itemSkuId).length > 0) {
                                v.checked = true;
                                v.alreadySaved = false
                                // v.showAll = false
                            }

                        })

                    }else{
                        this.searchList.map(function (v, i) {
                            v.checked = false;

                            if (self.tempSearchProduct.filter(f => f.itemSkuId == v.itemSkuId).length > 0) {
                                v.checked = true;
                                v.alreadySaved = false
                                // v.showAll = false
                            }

                        })
                    }

                        this.selectAll = false;
                    if (this.searchList.filter(f => !f.checked).length == 0){
                        this.selectAll = true;
                    }

                    if (this.searchList.length == 0) {
                        //this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
                       // this.op.hide()
                    }

                    if (res.data.length == 0){
                        this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
                       // this.op.hide()
                    }
                    this.isListLoading = false;
                    this.showProductDialog = true;
                    }else{
                        this.firstTimeLoad = false;
                        this.searchList = [];
                        this.loadingSearchData = false;
                        this.isListLoading = false;
                        this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
                    }
                }
                else {
                    this.firstTimeLoad = false;
                    this.searchList = [];
                    this.loadingSearchData = false;
                    this.isListLoading = false;
                    this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
                }
            })
        } catch (error) {
            this.firstTimeLoad = false;
        }
    }

    onPageChange(e) {

        e.totalRecords = this.totalRecords
        this.rows = e.rows
        this.page = e.page
        this.getProducts()

        // if (this.searchList.filter(f => f.checked && !f.alreadySaved).length > 0){
        //     this.confirmationService.confirm({
        //         target: event.target,

        //         message: 'Would you like to save selected products',
        //         icon: 'pi pi-exclamation-triangle',
        //         rejectLabel:"No",
        //         acceptLabel:"Yes",
        //         accept: () => {

        //             e.totalRecords = this.totalRecords
        //             this.rows = e.rows
        //             this.page = e.page
        //             this.selectAll = false;
        //             this.addItems();
        //            setTimeout(() => {
        //                this.getProducts()
        //            }, 1000);

        //         },
        //         reject: () => {
        //             e.totalRecords = this.totalRecords
        //             this.rows = e.rows
        //             this.page = e.page
        //             this.selectAll = false;
        //             this.getProducts()
        //             //reject action
        //         }
        //     });
        // }
        // else{
        //     e.totalRecords = this.totalRecords
        //     this.rows = e.rows
        //     this.page = e.page
        //     this.getProducts()
        // }


    }

    confirmRemove(event: Event) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Are you sure that you want to remove all products?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.productList =[];
                this.tempSearchProduct =[];
            },
            reject: () => {
                //reject action
            }
        });
    }

    updateStatus( e: boolean , obj : any) {
        try {

            this.searchList.filter(f => f.itemSkuId == obj.itemSkuId)[0].checked = e
            let self = this;

            this.tempSearchProduct.push(this.searchList.filter(f => f.itemSkuId == obj.itemSkuId)[0])



            // setTimeout(() => {
            //     let uniqueCustomersList = [];
            //     uniqueCustomersList = [...new Set(this.tempSearchProduct.map(item => item.itemName))]
            //     console.log(uniqueCustomersList)
            //     let self = this;
            //     this.tempSearchProduct.map(function (v, i) {
            //         if (uniqueCustomersList.filter(f => f == v.itemName).length == 0) {
            //             self.tempSearchProduct.splice(i, 1)
            //         }
            //     })
            //     console.log(this.tempSearchProduct.filter(f => f.checked))
            // }, 1000);



            this.selectAll = true;
            if (this.searchList.filter(f => !f.checked).length > 0) {
                this.selectAll = false;
            }

        } catch (error) {

        }
    }


    changeSelectAll(){
        try {
            setTimeout(() => {
                let self = this;

                this.searchList.map(function (v, i) {
                    v.checked = self.selectAll
                    if (self.tempSearchProduct.filter(f => f.itemSkuId == v.itemSkuId).length > 0){
                        self.tempSearchProduct.filter(f => f.itemSkuId == v.itemSkuId)[0].checked = self.selectAll
                    }
                    else{
                        self.tempSearchProduct.push(self.searchList.filter(f => f.itemSkuId == v.itemSkuId)[0])
                    }

                })

            }, 300);
        } catch (error) {

        }
    }

    hideForm(){
        this.onSubmit.emit(true);

        this._rewardProgramService.showNewEditTieredModal = false;
    }

    confirm(event: Event) {
        const nameControl = this.perkForm.get('displayName')
        const statusControl = this.perkForm.get('status')
        const rewardControl = this.perkForm.get('rewardType')
        const pointsControl = this.perkForm.get('pointsGoal')
        const discountControl = this.perkForm.get('discountAmount')

        if (nameControl.dirty || statusControl.dirty || rewardControl.dirty || pointsControl.dirty || discountControl.dirty){
            this.confirmationService.confirm({
                target: event.target,
                message: 'Are you sure that you want to proceed it will discard your changes?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.onSubmit.emit(true);
                    this._rewardProgramService.showNewEditTieredModal = false;
                },
                reject: () => {
                    //reject action
                }
            });
        }
        else{
            this.onSubmit.emit(true);
            this._rewardProgramService.showNewEditTieredModal = false;
        }

    }

    removeItem(item: any){
        this.productList.splice(this.productList.findIndex(f=> f.serial == item.serial), 1)
        this.updateSerial();
    }

    validateGoal(input: any){
        try {

            setTimeout(() => {
                if (input.value == null || isNaN(input.value)) {
                    this.f.pointsGoal.setValue(0);
                }

            }, 500);

        } catch (error) {

        }
    }

    validateQuantity(input: any, obj: any){
        try {

            setTimeout(() => {

                if (input.value == null || isNaN(input.value)) {
                    obj.quantity = 1;
                   // obj.valid = false;
                }
                else{
                  //  obj.valid = true;
                }

            }, 1000);

        } catch (error) {

        }
    }

    addItems(){
        try {
            if (this.searchList.filter(f=> f.checked).length == 0){

                this._messageService.add({ severity: 'error', summary: 'Please select at lease one product' })
                return false;
            }

            setTimeout(() => {
                let self = this;
                let productAdded = false;
                this.tempSearchProduct.map(function (v, i) {

                    if ( v.checked && self.productList.filter(f => f.itemSkuId == v.itemSkuId).length == 0) {
                        productAdded = true;
                        v.alreadySaved = true
                        self.productList.push({
                            serial: self.productList.length + 1,
                            product: v.itemName,
                            cost: v.cost,
                            priceA: v.priceA,
                            quantity: 1,
                            itemSkuId: v.itemSkuId,
                            productCode: v.productCode,
                            valid: true,
                            checked: v.checked,

                        })
                    }
                    else {
                       if (self.productList.findIndex(f => f.itemSkuId == v.itemSkuId) > -1){
                          //  self.productList.splice(self.productList.findIndex(f => f.itemSkuId == v.itemSkuId), 1)
                        }

                    }
                })
                this.updateSerial();

                if (productAdded){
                    this._messageService.add({ severity: 'success', summary: 'Product(s) has been added in list' })
                }

                if (!productAdded){
                    this._messageService.add({ severity: 'error', summary: 'Some of product(s) already in list' })
                }


               // this.op.hide()
            }, 300);
        } catch (error) {

        }
    }

    coseProductModal(){
        try {
            if(this.isListLoading){
                return false;
            }
            this.showProductDialog = false;
            this.page = 0;
            this.orderParam = 'itemName'
            this.orderBy = 'asc'
            this.tempSearchProduct = [];
            this.updateSerial();
          //  this.op.hide()
        } catch (error) {

        }
    }

    updateSerial(){
        this.productList.map(function (v, i) {
            v.serial = i + 1
        })
    }

    customSort(e) {
        const last = this.orderParam

        switch (e.sortField) {
            case 'itemName':
                this.orderParam = 'title'
                break;
            case 'barcode':
                this.orderParam = 'barcode'
                break;
            case 'skuCode':
                this.orderParam = 'skuCode'
                break;
            case 'cost':
                this.orderParam = 'cost'
                break;
            case 'priceA':
                this.orderParam = 'priceA'
                break;
            default:
                this.orderParam = 'itemName'
        }

        if (this.orderParam !== '') {
            this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
            if (last !== this.orderParam) this.orderBy = 'asc'

            if (!this.firstTimeLoad){

                this.getProducts()
            }

        }
    }

}
