<!------------- Compare Plan & Custom Plan Buttons Start ------------->
<!-- <div class="flex align-items-center justify-content-center text-center Compare-For-Detail">
    <p-button label="Compare Plans" styleClass="compare-plans-btn p-button mr-3">
    </p-button>
    <p-button label="Continue" styleClass="continue-btn p-button" icon="pi pi-arrow-right" iconPos="right">
    </p-button>
</div> -->
<!------------- Compare Plan & Custom Plan End ------------->

<!------------- Compare Plan in Detail Start ------------->
<div class="flex price-plan-details">
    <p-accordion [multiple]="true" class="col-12">
        <!------------- Features Start ------------->

        <!------------- Credit Card Fee Start ------------->
        <p-accordionTab header="Credit Card Fee">
            <p-table [value]="_signup.creditCardFee" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Credit Card Fee End ------------->

        <!------------- Website / Online eCommerce Start ------------->
        <p-accordionTab header="Website / Online eCommerce Store">
            <p-table [value]="_signup.websiteOnlineeCommerceStore" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>

                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Website / Online eCommerce End ------------->

        <!------------- Customer App Start ------------->
        <p-accordionTab header="Customer App">
            <p-table [value]="_signup.customerApp" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Customer App End ------------->

        <!------------- Hardware Start ------------->
        <p-accordionTab header="Hardware">
            <p-table [value]="_signup.hardware" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Hardware Start ------------->

        <!------------- Additional Equipment Start ------------->
        <p-accordionTab header="Additional Equipment">
            <p-table [value]="_signup.additionalEquipment" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>

        </p-accordionTab>
        <!------------- Additional Equipment End ------------->

        <!------------- Support Start ------------->
        <p-accordionTab header="Support">
            <p-table [value]="_signup.support" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>

        </p-accordionTab>
        <!------------- Support End ------------->

        <!------------- Services Start ------------->
        <p-accordionTab header="Services">
            <p-table [value]="_signup.services" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Services End ------------->

        <!------------- Notes Start ------------->
        <p-accordionTab header="Notes">
            <p-table [value]="_signup.notes" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <th>{{data.title}}</th>
                        <td>{{data.lite}}</td>
                        <td>{{data.standard}}</td>
                        <td>{{data.plus}}</td>
                        <td>{{data.premium}}</td>
                        <td>{{data.enterprise}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <!------------- Notes Start ------------->
    </p-accordion>
</div>
<!------------- Compare Plan in Detail End ------------->
