<p
    style="
        margin-top: 0px;
        font-size: 2.1rem;
        color: var(--bs-active-primary);
        font-weight: 900;
    "
>
    Customer Information
</p>
<br />
<div class="grid">
    <div class="col-12 lg:col-6 xl:col-6">
        <div class="conn-card">
            <app-customer-personal-info></app-customer-personal-info>
        </div>
    </div>
    <!-- <div class="col">
        <div class="conn-card">
            <app-customer-notification-permission></app-customer-notification-permission>
        </div>
    </div> -->
    <div class="col-12 lg:col-6 xl:col-6">
        <div class="conn-card">
            <h3
                style="
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-size: 1.7rem;
                    color: var(--bs-active-primary);
                    font-weight: 700;
                "
            >
                Loyalty Information
            </h3>
            <p-divider></p-divider>
            <br />
            <div class="grid">
                <div
                    class="col-6 lg:col-6 xl:col-6"
                    style="color: var(--bluegray-300); font-weight: 600px"
                >
                    Reward Points
                    <div class="row text-red-400 text-sm">
                        (max: {{ maxLoyaltyPoints | number : "1.2-2" }})
                    </div>
                </div>

                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="_customerService.activeItem.rewardPoints"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        mode="decimal"
                        inputId="minmax-buttons"
                        [min]="0"
                        [max]="maxLoyaltyPoints"
                        placeholder="0"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'center'
                        }"
                        [disabled]="!isEdit"
                        style="
                            background: #eceeef;
                            position: relative;
                            right: 4px;
                            border-radius: 5px;
                        "
                    >
                    </p-inputNumber>
                    <!-- {{ _customerService.activeItem?.rewardPoints }} -->
                </div>
                <div class="mt-2">
                    <button
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-outlined'
                                : 'p-button-rounded p-button-outlined'
                        "
                        iconPos="right"
                        (click)="isEdit = !isEdit"
                    ></button>
                </div>
            </div>
            <br />
            <div class="grid">
                <div
                    class="col-6 lg:col-6 xl:col-6"
                    style="color: var(--bluegray-300); font-weight: 600px"
                >
                    Points Last Updated
                </div>
                <div
                    class="col"
                    style="
                        background-color: var(--bluegray-50);
                        border-radius: 5px;
                        padding: 10px;
                    "
                >
                    <!-- {{ _customerService.activeItem?.lastPointsUpdated=='0001-01-01T00:00:00'?'N/A': _customerService.activeItem?.lastPointsUpdated}} -->
                    {{
                        _customerService.activeItem?.lastPointsUpdated
                            | date : "medium"
                    }}
                </div>
            </div>
            <br />
            <div class="grid">
                <div
                    class="col-6 lg:col-6 xl:col-6"
                    style="color: var(--bluegray-300); font-weight: 600px"
                >
                    Last Points Redeemed
                </div>
                <div
                    class="col"
                    style="
                        background-color: var(--bluegray-50);
                        border-radius: 5px;
                        padding: 10px;
                    "
                >
                    <!-- {{ _customerService.activeItem?.lastPointsRedeemedDate=='0001-01-01T00:00:00'?'N/A': _customerService.activeItem?.lastPointsRedeemedDate }} -->
                    {{
                        _customerService.activeItem?.lastPointsRedeemedDate
                            | date : "medium"
                    }}
                </div>
            </div>
            <br />
            <div class="grid">
                <div
                    class="col-6 lg:col-6 xl:col-6"
                    style="color: var(--bluegray-300); font-weight: 600px"
                >
                    Last Redeemed Amount
                </div>
                <div
                    class="col"
                    style="
                        background-color: var(--bluegray-50);
                        border-radius: 5px;
                        padding: 10px;
                    "
                >
                    {{
                        _customerService.activeItem?.lastRedeemedAmount
                            | currency
                    }}
                </div>
            </div>
            <br />
            <p-divider></p-divider>
            <div class="flex justify-content-center">
                <div
                    class="flex align-items-center justify-content-center"
                    style="padding: 10px 0px"
                >
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Save Loyalty Info"
                        class="p-button-outlined p-button-info"
                        (click)="saveLoyaltyInfoClicked()"
                        [disabled]="!isEdit"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="grid">
    <div class="col-12 lg:col-12 xl:col-12">
        <div class="conn-card">
            <!-- <app-customer-notification-permission></app-customer-notification-permission> -->
            <app-customer-sales-history></app-customer-sales-history>
        </div>
    </div>
</div>
