<p style="font-size: 1.25rem;  color: var(--gray-500); font-weight: bold">
    Invoice Payments
</p>

<p-table
    #dt
    [columns]=""
    [autoLayout]="true"
    [value]="_reportService.selectedInvoiceDetails?.tenders"
    responsiveLayout="scroll"
    showLoader="true"
    rowHover="true"
    [customSort]="true"
    [style]="{'min-width':'100%'}"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Date</th>
            <th style="text-align: right">Amount</th>
            <th style="text-align: right">Other Charges</th>
            <th style="text-align: right">Tip</th>
            <th style="text-align: right">Balance</th>
            <th>Method</th>
            <th>Remarks</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{ item.date |date: 'short' }}</td>
            <td style="text-align: right">{{ item.amount | currency }}</td>
            <td style="text-align: right">{{ item.ccFee | currency }}</td>
            <td  style="text-align: right">{{ item.tipAmount | currency }}</td>
            <td  style="text-align: right">{{ item.balance | currency }}</td>
            <td>{{item.tenderTypeString}}</td>
            <td>{{item?.remarks}}</td>
            <!-- <td style="text-align: right">{{ item.discount | currency }}</td>
            <td style="text-align: right">{{ item.linePrice | currency }}</td> -->
        </tr>
    </ng-template>
</p-table>
