import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BaseService } from 'src/app/_services/base.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Paginator } from 'primeng/paginator';

import { NgxSpinnerService } from 'ngx-spinner';
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-inventory-report',
  templateUrl: './inventory-report.component.html',
  styleUrls: ['./inventory-report.component.scss']
})
export class InventoryReportComponent implements OnInit {
  columns: any = ['']
  connForm: FormGroup
  // locations: any = null
  departments: any = ''
  dataList: any = ''

  page = 0
  rows = 10
  first = 0
  totalRecords = 0

  //order
  orderParam = ''
  orderBy = ''

  dateRange: any = null
  searchText = ''
  lastSearchText = ''
  selectedLocation: any = null
  selectedDepartment: any = null
  display = false

  gProductId: any = null
  selectedRow: any

  colheader = [[
    'Location', 'Product', 'Barcode', 'Sku', 'Opening Qty', 'Transfer-Out', 'Transer In'
    , 'Damage', 'Sold', 'Closing', 'Qty. On Hand', 'Variance'
  ]];

  colflds = ['locationName', 'itemName', 'barcode', 'skuCode',
    'openingStock', 'transferOut', 'transerIn', 'damage',
    'usedSince', 'closingStock', 'stockInHand', 'variance'];

  csvcols = []
  exportColumns: any = ''

  _globals = GlobalService

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    private fb: FormBuilder,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  async ngOnInit() {
    this._globals.componentName = 'Overview'
    this.intitializeForm()

    // if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
    //   this._filterService.initDates()

    // this._filterService.selectedLocation = this._abd.locations[0]
    // this._filterService.selectedCategory = this._abd.departments[0]
    // this.getDataList()
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }


      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.searchText) this._filterService.searchText = filter.searchText
        const rr = this._abd.getProductSellTypeFilter(filter.selectedProductSellTypeFilter)
        if (filter.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = rr
        if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
        if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)
        if (filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
      }

      let oorderby = this._filterService.getOrderby(this._globals.componentName)
      if (oorderby) {
        this.orderParam = oorderby.orderParam
        this.orderBy = oorderby.orderBy
      }

      this.getDataList()
      clearInterval(ti)
    }, 700);
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  intitializeForm() {
    this.connForm = this.fb.group({
      selectedLocation: [null],
      selectedDepartment: [null],
      to: [new Date()],
      from: [new Date()],
    })
  }

  getDataList() {
    let ti = null
    ti = setInterval(() => {
      //--
      let params = new HttpParams();
      let qryParas = null
      if (this.orderParam && this.orderParam.length > 0) {
        qryParas = `?orderParam=${this.orderParam}&orderBy=${this.orderBy}`
        this._filterService.updateOrderby(this._globals.componentName, { orderParam: this.orderParam, orderBy: this.orderBy })
      }

      try {
        if (!this._filterService.dateRange[0] || !this._filterService.dateRange[0].getFullYear()) {
          this._filterService.dateRange[0] = (new Date(new Date().setDate(new Date().getDate() - 7)))
          this._filterService.dateRange[1] = (new Date())
        }
        if (!this._filterService.dateRange[1]) {
          this._filterService.dateRange[1] = this._filterService.dateRange[0]
        }
      } catch {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = (new Date(new Date().setDate(new Date().getDate() - 7)))
        this._filterService.dateRange[1] = (new Date())
      }

      params = params.append('startDate', formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'));
      params = params.append('endDate', formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'));

      if (this._abd.locations && this._filterService.selectedLocation)
        params = params.append('locationId', this._filterService.selectedLocation?.id?.toString())
      else if (this._abd.locations?.length > 0)
        params = params.append('locationId', this._abd.locations[0].id);

      let sellOnline = this._filterService.selectedProductSellTypeFilter?.id;
      if (sellOnline) {
        if (sellOnline == 1)
          params = params.append('sellOnline', 'true')
        else if (sellOnline == 2)
          params = params.append('sellOnline', 'false')
      } else { }

      this.page = this.page < 1 ? 0 : this.page
      this.rows = this.rows < 1 ? 10 : this.rows

      if (this.page >= 0) params = params.append('pageNo', this.page.toString());
      if (this.rows > 0) params = params.append('pageSize', this.rows.toString());

      if (this._filterService.searchText) params = params.append('filterValue', this._filterService.searchText);

      const outfilter = {
        startDate: this._filterService.dateRange[0],
        endDate: this._filterService.dateRange[1],
        searchText: this._filterService.searchText ? this._filterService.searchText : '',
        selectedProductSellTypeFilter: sellOnline,
        selectedLocation: this._filterService.selectedLocation,
      }

      this._filterService.updateFilters(this._globals.componentName, outfilter)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

      let url = environment.InventoryBaseURL + environment.GetStockStatusReport
      if (qryParas) url += qryParas

      this._abd.showSpinner()
      this._bs.httpGetParams(url, params).subscribe(res => {
        if (res.success) {
          this._abd.hideSpinner()

          this.totalRecords = res.totalRecords
          this.dataList = res.data
        }
      })
      //--
      clearInterval(ti)
    }, 200);
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }

  clearSearchText() {
    this.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  searchTextSearch() {
    if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return

    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.colheader[0], this.colflds, 'Inventory Overview')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.colheader, this.colflds, 'Inventory Overview', true)
  }

  onRowSelect(e) {
    this.gProductId = e.data.itemSkuId
  }

  onRowUnselect(e) { }

  customSort(e) {
    if (!e.sortField) return

    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barcode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      // default:
      //   this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }


}