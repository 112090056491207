import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-transfer-receive-form',
  templateUrl: './transfer-receive-form.component.html',
  styleUrls: ['./transfer-receive-form.component.scss']
})
export class TransferReceiveFormComponent implements OnInit {
  isViewOnly = false
  isEdit = false
  isNew = false
  id = 0
  transferId = ''
  requestedById: any = ''

  creationDate = new Date()
  expectedDate = new Date(new Date().setDate(new Date().getDate() + 7))
  userName = ''
  activeItem: any = ''
  activeItem4Editing: any = ''
  localActiveItem: any = ''

  isLocationError = false
  isEnableDeleteButton = true
  isLoading = false
  isListLoading = false

  notes = ''
  receivedNotes = ''
  shippingCost = 0.0
  grandTotal = 0
  totalReceivedQuantity = 0

  receivingDate = new Date()
  receivedBy = ''
  receivedById = ''

  locations: any = []
  fromLocation: any = ''
  toLocation: any = ''
  saveWhat = 0

  dataList: any = []

  dummy = true

  _globals = GlobalService
  @ViewChild('opNotes') opNotes: OverlayPanel
  @ViewChild('opReceiveNote') opReceiveNote: OverlayPanel



  constructor(
    private _productService: ProductsService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.locations = this._abd.initActLocations()
    this._globals.componentName = 'Transfer Receive Form'
    this.receivedBy = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    this.receivedById = this._globals.userInfo.userID
    this.isViewOnly = this._productService.isViewOnly
    this.isEdit = this._productService.isEdit
    this.isNew = this._productService.isNew

    this.activeItem = this._productService.activeItem

    if (this.isViewOnly || this.isEdit) {
      this._spinner.show()
      this._productService.searchSingleTransferOrder(this.activeItem.transferNumber, 3).subscribe(res => {
        if (res.success) {
          this.localActiveItem = res.data
          this.id = this.localActiveItem.id
          this.transferId = res.data.transferNumber
          this.dataList = res.data.stockTransfersDetails
          this.shippingCost = res.data.shipping
          this.receivedNotes = res.data.receivedNotes
          this.creationDate = new Date(this.activeItem.createdDate)
          this.expectedDate = new Date(this.activeItem.dueDate)
          this.userName = this.localActiveItem.requestedBy

          // console.log('Transfer Receive Form', this.localActiveItem)

          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].costPrice = this.dataList[i].supplyPrice
            this.dataList[i].description = this.dataList[i].productName
            this.dataList[i].expiryDate = new Date(this.dataList[i].expiryDate)
            this.dataList[i].totalCost = this.dataList[i].costPrice * this.dataList[i].receivedQuantity
            // this.dataList[i].stockInHand = this.dataList[i].stockInHand
            this.dataList[i].isValueChanged = false
            this.dataList[i].isCostValueChanged = false
            this.dataList[i].uomDescription = this._abd.uom.find(f => f.id == this.dataList[i].uomId).unitName
            this.dataList[i].lineNotes = this.dataList[i].notes
          }
          this.calculateTotals()

          let lid = res.data.warehouseIdFrom
          this.fromLocation = this._abd.locations.find(f => f.id == lid)
          lid = res.data.warehouseIdTo
          this.toLocation = this._abd.locations.find(f => f.id == lid)

          // if (!this.fromLocation || !this.toLocation) this._abd.showErrorAndQuit('Invalid Location')
          if (!this.fromLocation || !this.toLocation) {
            this.fromLocation = this._abd.locations[0]
            this.toLocation = this._abd.locations.find(f => f.id !== this.fromLocation.id)
            this._abd.selectedLocation = this._abd.selectedLocation ? this._abd.selectedLocation.locationId : this.fromLocation
            this._abd.selectedExtra1Location = this._abd.selectedExtra1Location ? this._abd.selectedExtra1Location.locationId : this.toLocation
          }
        }
        this.totalReceivedQuantity = this.calculateTransferQuantityTotal()
        this._spinner.hide()
      })

    }
    else {
      this.fromLocation = this._abd.selectedLocation
      this.toLocation = this.locations.find(f => f.locationId !== this.fromLocation.locationId)
      this._abd.selectedExtra1Location = this.toLocation
      this.requestedById = this._globals.userInfo.userID
      this.transferId = Math.floor(Date.now() / 1000).toString()

    }
  }

  isOk2Save() {
    if (this.localActiveItem.transfersInStatus == 2) return true
    if (this.isViewOnly) return false
    if (this.calculateReceivedQuantityTotal() < 1) return false
    if (this.dataList.length < 1) return false
    try {
      if (this.fromLocation.id == this.toLocation.id) {
        if (!this.isLocationError) this.isLocationError = true
        return false
      }
    } catch { return false }

    for (let i = 0; i < this.dataList.length; i++) {
      if (!this.receivedQuantityValid(this.dataList[i])) return false

      try {
        if (!this.dataList[i].expiryDate) return false
        let date = new Date(this.dataList[i].expiryDate)
        if (isNaN(date.getTime())) return false
      }
      catch { return false }
    }
    return true
  }


  receivedQuantityValid(item: any) {
    // if (!item.isValueChanged) return true
    // if (!item.receivedQuantity) return false
    if (item.receivedQuantity > item.transferQuantity) return false
    return true
  }

  doShowFooter() {
    if (this.dataList.length > 0) return true
    else false
  }

  calculateTotals() {
    this.grandTotal = this.shippingCost
    for (let i = 0; i < this.dataList.length; i++) {
      this.grandTotal += this.dataList[i].totalCost
      this.dataList[i].srNo = i + 1
    }
  }

  calculateReceivedQuantityTotal() {
    let total = 0
    for (let i = 0; i < this.dataList.length; i++) {
      total += this.dataList[i].receivedQuantity
    }
    return total
  }

  calculateTransferQuantityTotal() {
    let total = 0
    for (let i = 0; i < this.dataList.length; i++) {
      total += this.dataList[i].transferQuantity
    }
    return total
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
      item.totalCost = item.costPrice * item.receivedQuantity
      item.isCostValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  receivedQuantityChanged(e, item) {
    try {
      item.receivedQuantity = e.value
      if (!item.receivedQuantity) item.receivedQuantity = 0
      item.totalCost = item.costPrice * e.value
      item.isValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  editButtonClick() {
    this._productService.isViewOnly = !this._productService.isViewOnly
    this.isViewOnly = !this.isViewOnly
    this.isEdit = !this.isEdit
    this._productService.isEdit = !this._productService.isEdit
  }

  lineNotesEditClick(e, item) {
    this.activeItem4Editing = item
    if (this.activeItem4Editing.lineNotes.length < 1) return
    this.opNotes.toggle(e)
  }

  showReceivingNote(e) {
    if (this.receivedNotes.length < 1) return
    this.opReceiveNote.toggle(e)
  }

  saveWhatString() {
    let status = this.localActiveItem.transfersInStatus
    if (status == 1 || status == 5) return 'Received'
    else if (status == 2) return 'Post to Inventory'
  }

  getSaveWhat() {
    let status = this.localActiveItem.transfersInStatus
    if (status == 1 || status == 5) return 2
    else if (status == 2) return 4
  }

  saveTransferInventory(what: any) {
    if (what == 5) this.saveWhat = 5
    if (what !== 5) this.saveWhat = this.getSaveWhat()

    if (!this.isOk2Save()) return
    const lst = this.dataList
      .filter(f => f.transferQuantity > 0
        && f.transferQuantity <= f.stockInHand)

    if (!lst.length) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Nothing to save' })
      return
    }

    let mlist = []
    for (let i = 0; i < lst.length; i++) {
      let sku = ''
      if (this.isEdit)
        sku = lst[i].skuItemCode
      else
        sku = lst[i].skuCode

      const m = {
        RequestedById: '',
        StockTransferId: this.transferId,
        ProductId: lst[i].productId,
        SkuItemCode: lst[i].sku,
        ProductName: lst[i].productName,
        Barcode: lst[i].barcode,
        UOMId: lst[i].uomId,
        UOMDescription: lst[i].uomDescription,
        EnableExpiryDate: true,
        ItemSKUId: lst[i].itemSKUId,
        StockInHand: lst[i].stockInHand,
        EnableCostPrice: true,
        CostPrice: lst[i].costPrice,
        TransferQuantity: lst[i].transferQuantity,
        SupplyPrice: lst[i].costPrice,
        SubTotal: lst[i].totalCost,
        TaxRate: 0,
        TaxAmount: 0,
        DiscountPercent: 0,
        DiscountAmount: 0,
        TotalAmount: this.grandTotal,
        Notes: lst[i].lineNotes,
        ReceivedQuantity: lst[i].receivedQuantity
      }
      mlist.push(m)
    }

    let id = 0
    const data = {
      warehouseIdTo: this.toLocation.id,
      warehouseTo: this.toLocation.locationName,
      warehouseIdFrom: this.fromLocation.id,
      warehouseFrom: this.fromLocation.locationName,
      dated: formatDate(new Date(this.creationDate), 'yyyy-MM-dd', 'en-US'),
      dueDate: formatDate(new Date(this.expectedDate), 'yyyy-MM-dd', 'en-US'),
      vendor: '',
      senderBy: this.localActiveItem.senderBy,
      senderById: this.localActiveItem.senderById,
      receivedById: this.receivedById,
      receivedBy: this.receivedBy,
      discountTotal: 0,
      grandTotal: this.grandTotal,
      transferNumber: this.transferId,
      subTotal: 0,
      taxTotal: 0,
      informViaEmail: false,
      informViaNotification: false,
      stockTransfersDetails: mlist,
      enableDueDate: false,
      enableCostPrice: true,
      enableExpiryDate: false,
      notes: this.notes,
      shipping: this.shippingCost,
      TransfersInStatus: this.saveWhat,
      TransfersOutStatus: this.localActiveItem.transfersOutStatus,
      totalQuantity: this.dataList.reduce((a, b) => a + b.transferQuantity, 0),
      id: this.id,
      ReceivedNotes: this.receivedNotes
    }

    this._spinner.show()
    this._productService.transferInventoryPost(data).subscribe(res => {
      if (res.success) {
        this._messageService.add({
          severity: 'info',
          summary: 'Success', detail: 'Transfer invoice saved successfully!'
        });
        window.history.back()
        this._spinner.hide()
      }
    })
  }

  cancelTransfer() {
    const data = {
      senderBy: this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName,
      senderById: this._globals.userInfo.userID,
      TransfersInStatus: 3,
      TransfersOutStatus: 6,
      id: this.id,
    }

    this._confirmationService.confirm({
      message: 'All data for this transaction will be lost. Do you wish to continue?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._spinner.show()
          this._productService.transferInventoryPost(data).subscribe(res => {
            if (res.success) {
              this._messageService.add({
                severity: 'info',
                summary: 'Success', detail: 'Transfer invoice cancelled successfully!'
              });
              window.history.back()
              this._spinner.hide()
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }


}
