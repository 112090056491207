<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Notification Permission
</h3>
<p-divider></p-divider>
<br />
<div class="flex justify-content-between flex-wrap">
    <div class="flex" style="color: var(--bluegray-300); font-weight: 600px">
        Send Alert Email
    </div>
    <div class="flex">
        <p-inputSwitch
            [(ngModel)]="
                _customerService.activeItem?.notificationSettings.sendEmailAlert
            "
        ></p-inputSwitch>
    </div>
</div>
<br />
<div class="flex justify-content-between flex-wrap">
    <div class="flex" style="color: var(--bluegray-300); font-weight: 600px">
        Send Marketing Email
    </div>
    <div class="flex">
        <p-inputSwitch
            [(ngModel)]="
                _customerService.activeItem?.notificationSettings
                    .sendEmailMarketing
            "
        ></p-inputSwitch>
    </div>
</div>
<br />
<div class="flex justify-content-between flex-wrap">
    <div class="flex" style="color: var(--bluegray-300); font-weight: 600px">
        Send Alert SMS
    </div>
    <div class="flex">
        <p-inputSwitch
            [(ngModel)]="
                _customerService.activeItem?.notificationSettings.sendSMSAlert
            "
        ></p-inputSwitch>
    </div>
</div>
<br />
<div class="flex justify-content-between flex-wrap">
    <div class="flex" style="color: var(--bluegray-300); font-weight: 600px">
        Send Marketing SMS
    </div>
    <div class="flex">
        <p-inputSwitch
            [(ngModel)]="
                _customerService.activeItem?.notificationSettings
                    .sendSMSMarketing
            "
        ></p-inputSwitch>
    </div>
</div>
<br>
<p-divider></p-divider>
<div class="flex justify-content-center">
    <div class="flex align-items-center justify-content-center">
        <button pButton pRipple type="button" label="Save Permissions" class="p-button-outlined p-button-info"></button>
    </div>
</div>
