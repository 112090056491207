import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-merchant-application-form',
  templateUrl: './merchant-application-form.component.html',
  styleUrls: ['./merchant-application-form.component.scss']
})
export class MerchantApplicationFormComponent implements OnInit {

    // _globals = GlobalService;
    // form: FormGroup;
    // secondForm: FormGroup;
    // thirdForm: FormGroup;
    // forthForm: FormGroup;
    // usaStates = UsStates;
    // showCityWait = false;
    // showCityWait2 = false;
    // sshowCityWait = false;
    // tshowCityWait = false;
    // fshowCityWait = false;
    // selectedState: any = '';
    // selectedState2: any = '';
    // sselectedState: any = '';
    // tselectedState: any = '';
    // fselectedState: any = '';
    // selectedCust: any = '';
    // selectedCust2: any = '';
    // sselectedCust: any = '';
    // tselectedCust: any = '';
    // fselectedCust: any = '';
    // noOwners:any ={second:false,third:false,forth:false};

    // constructor(
    //     private _fb: FormBuilder,
    //     public _abd: AppBaseDataService,
    //     private _spinner: NgxSpinnerService,
    //     private _connMessageService: ConnMessageService
    // ) { }

    ngOnInit(): void {
        // this.selectedState = this.usaStates.find(
        //     (f) => f.abbreviation == this.selectedCust.state
        // );
        // this.selectedState2 = this.usaStates.find(
        //     (f) => f.abbreviation == this.selectedCust2.state
        // );
        // this.sselectedState = this.usaStates.find(
        //     (f) => f.abbreviation == this.sselectedCust.state
        // );
        // this.tselectedState = this.usaStates.find(
        //     (f) => f.abbreviation == this.tselectedCust.state
        // );
        // this.fselectedState = this.usaStates.find(
        //     (f) => f.abbreviation == this.fselectedCust.state
        // );
        // // verify valid account
        // this.initForm();
    }

    // initForm() {
    //     try {
 
    //         this.form = this._fb.group({
    //             legalFirstName: ['', Validators.required],
    //             residenceAddress : ['', Validators.required],
    //             legalFullMiddleName : ['', Validators.required],
    //             city : ['', Validators.required],
    //             legalLastName: ['', Validators.required],
    //             title: ['', Validators.required],
    //             zipCode: ['', Validators.maxLength(5)],
    //             dateofBirth: ['', Validators.required],
    //             socialSecurityNumber: ['',Validators.required ],
    //             ownershipPercentage: ['',Validators.required ],
    //             legalName: ['',Validators.required ],
    //             dBAPhone: ['',[this._abd.phoneValidator(),Validators.required] ],
    //             businessDBAName: ['',Validators.required ],
    //             corporateEmail: ['',[ Validators.required,this.emailFormatValidator()], ],
    //             physicalStreetAddress: ['',Validators.required ],
    //             yearsOwned: ['',Validators.required ],
    //             cityb: ['',Validators.required ],
    //             typeofOwnership: ['',Validators.required ],
    //             largestSingleTransactionAmountExpected: ['',Validators.required ],
    //             zipCodeb: ['',Validators.maxLength(5) ],
    //             largestMonthlyVolumeExpected: ['',Validators.required ],
    //             websiteAddress: ['',Validators.required ],
    //             goodsSold: ['',Validators.required ],
    //             federalTaxIDEIN: ['',Validators.required ],
     
    //         });

    //         this.secondForm = this._fb.group({
    //             slegalFirstName: [''],
    //             sresidenceAddress : [''],
    //             slegalFullMiddleName : [''],
    //             scity : [''],
    //             slegalLastName: [''],
    //             stitle: [''],
    //             szipCode: [''],
    //             sdateofBirth: [''],
    //             ssocialSecurityNumber: [''],
    //             sownershipPercentage: [''],
    //         })
    //         this.thirdForm = this._fb.group({
    //             tlegalFirstName: [''],
    //             tresidenceAddress : [''],
    //             tlegalFullMiddleName : [''],
    //             tcity : [''],
    //             tlegalLastName: [''],
    //             ttitle: [''],
    //             tzipCode: [''],
    //             tdateofBirth: [''],
    //             tsocialSecurityNumber: [''],
    //             townershipPercentage: [''],
    //         })
    //         this.forthForm = this._fb.group({
    //             flegalFirstName: [''],
    //             fresidenceAddress : [''],
    //             flegalFullMiddleName : [''],
    //             fcity : [''],
    //             flegalLastName: [''],
    //             ftitle: [''],
    //             fzipCode: [''],
    //             fdateofBirth: [''],
    //             fsocialSecurityNumber: [''],
    //             fownershipPercentage: [''],
    //         })

    //     } catch {}
    // }

//         secondValidater(e){
//             if(e.checked === true){
//                 this.noOwners.second = true;
//                 this.secondForm.get('slegalFirstName').setValidators([Validators.required]);
//                 this.secondForm.get('sresidenceAddress').setValidators([Validators.required]);
//                 this.secondForm.get('slegalFullMiddleName').setValidators([Validators.required]);
//                 this.secondForm.get('scity').setValidators([Validators.required]);
//                 this.secondForm.get('slegalLastName').setValidators([Validators.required]);
//                 this.secondForm.get('stitle').setValidators([Validators.required]);
//                 this.secondForm.get('szipCode').setValidators([Validators.maxLength(5)]);
//                 this.secondForm.get('sdateofBirth').setValidators([Validators.required]);
//                 this.secondForm.get('ssocialSecurityNumber').setValidators([Validators.required]);
//                 this.secondForm.get('sownershipPercentage').setValidators([Validators.required]);
//              }else if(e.checked === false){
//                 this.noOwners.second = false;
//                 this.secondForm.get('slegalFirstName').clearValidators();
//                 this.secondForm.get('sresidenceAddress').clearValidators();
//                 this.secondForm.get('slegalFullMiddleName').clearValidators();
//                 this.secondForm.get('scity').clearValidators();
//                 this.secondForm.get('slegalLastName').clearValidators();
//                 this.secondForm.get('stitle').clearValidators();
//                 this.secondForm.get('szipCode').clearValidators();
//                 this.secondForm.get('sdateofBirth').clearValidators();
//                 this.secondForm.get('ssocialSecurityNumber').clearValidators();
//                 this.secondForm.get('sownershipPercentage').clearValidators();
//              }
//                 this.secondForm.get('slegalFirstName').updateValueAndValidity()
//                 this.secondForm.get('sresidenceAddress').updateValueAndValidity()
//                 this.secondForm.get('slegalFullMiddleName').updateValueAndValidity()
//                 this.secondForm.get('scity').updateValueAndValidity()
//                 this.secondForm.get('slegalLastName').updateValueAndValidity()
//                 this.secondForm.get('stitle').updateValueAndValidity()
//                 this.secondForm.get('szipCode').updateValueAndValidity()
//                 this.secondForm.get('sdateofBirth').updateValueAndValidity()
//                 this.secondForm.get('ssocialSecurityNumber').updateValueAndValidity()
//                 this.secondForm.get('sownershipPercentage').updateValueAndValidity()
//             }
//         thirdValidater(e){
//             if(e.checked === true){
//                 this.noOwners.third = true;
//                 this.thirdForm.get('tlegalFirstName').setValidators([Validators.required]);
//                 this.thirdForm.get('tresidenceAddress').setValidators([Validators.required]);
//                 this.thirdForm.get('tlegalFullMiddleName').setValidators([Validators.required]);
//                 this.thirdForm.get('tcity').setValidators([Validators.required]);
//                 this.thirdForm.get('tlegalLastName').setValidators([Validators.required]);
//                 this.thirdForm.get('ttitle').setValidators([Validators.required]);
//                 this.thirdForm.get('tzipCode').setValidators([Validators.maxLength(5)]);
//                 this.thirdForm.get('tdateofBirth').setValidators([Validators.required]);
//                 this.thirdForm.get('tsocialSecurityNumber').setValidators([Validators.required]);
//                 this.thirdForm.get('townershipPercentage').setValidators([Validators.required]);
                
//              }else if(e.checked === false){
//                 this.noOwners.third = false;
//                 this.thirdForm.get('tlegalFirstName').clearValidators();
//                 this.thirdForm.get('tresidenceAddress').clearValidators();
//                 this.thirdForm.get('tlegalFullMiddleName').clearValidators();
//                 this.thirdForm.get('tcity').clearValidators();
//                 this.thirdForm.get('tlegalLastName').clearValidators();
//                 this.thirdForm.get('ttitle').clearValidators();
//                 this.thirdForm.get('tzipCode').clearValidators();
//                 this.thirdForm.get('tdateofBirth').clearValidators();
//                 this.thirdForm.get('tsocialSecurityNumber').clearValidators();
//                 this.thirdForm.get('townershipPercentage').clearValidators();
//              }
//                 this.thirdForm.get('tlegalFirstName').updateValueAndValidity()
//                 this.thirdForm.get('tresidenceAddress').updateValueAndValidity()
//                 this.thirdForm.get('tlegalFullMiddleName').updateValueAndValidity()
//                 this.thirdForm.get('tcity').updateValueAndValidity()
//                 this.thirdForm.get('tlegalLastName').updateValueAndValidity()
//                 this.thirdForm.get('ttitle').updateValueAndValidity()
//                 this.thirdForm.get('tzipCode').updateValueAndValidity()
//                 this.thirdForm.get('tdateofBirth').updateValueAndValidity()
//                 this.thirdForm.get('tsocialSecurityNumber').updateValueAndValidity()
//                 this.thirdForm.get('townershipPercentage').updateValueAndValidity()
//         }
//    forthValidater(e){
//        if(e.checked === true){
//         this.noOwners.forth = true;
//         this.forthForm.get('flegalFirstName').setValidators([Validators.required]);
//         this.forthForm.get('fresidenceAddress').setValidators([Validators.required]);
//         this.forthForm.get('flegalFullMiddleName').setValidators([Validators.required]);
//         this.forthForm.get('fcity').setValidators([Validators.required]);
//         this.forthForm.get('flegalLastName').setValidators([Validators.required]);
//         this.forthForm.get('ftitle').setValidators([Validators.required]);
//         this.forthForm.get('fzipCode').setValidators([Validators.maxLength(5)]);
//         this.forthForm.get('fdateofBirth').setValidators([Validators.required]);
//         this.forthForm.get('fsocialSecurityNumber').setValidators([Validators.required]);
//         this.forthForm.get('fownershipPercentage').setValidators([Validators.required]);
        
//      }else if(e.checked === false){
//     this.noOwners.forth = false;
//         this.forthForm.get('flegalFirstName').clearValidators();
//         this.forthForm.get('fresidenceAddress').clearValidators();
//         this.forthForm.get('flegalFullMiddleName').clearValidators();
//         this.forthForm.get('fcity').clearValidators();
//         this.forthForm.get('flegalLastName').clearValidators();
//         this.forthForm.get('ftitle').clearValidators();
//         this.forthForm.get('fzipCode').clearValidators();
//         this.forthForm.get('fdateofBirth').clearValidators();
//         this.forthForm.get('fsocialSecurityNumber').clearValidators();
//         this.forthForm.get('fownershipPercentage').clearValidators();
//      }
//         this.forthForm.get('flegalFirstName').updateValueAndValidity()
//         this.forthForm.get('fresidenceAddress').updateValueAndValidity()
//         this.forthForm.get('flegalFullMiddleName').updateValueAndValidity()
//         this.forthForm.get('fcity').updateValueAndValidity()
//         this.forthForm.get('flegalLastName').updateValueAndValidity()
//         this.forthForm.get('ftitle').updateValueAndValidity()
//         this.forthForm.get('fzipCode').updateValueAndValidity()
//         this.forthForm.get('fdateofBirth').updateValueAndValidity()
//         this.forthForm.get('fsocialSecurityNumber').updateValueAndValidity()
//         this.forthForm.get('fownershipPercentage').updateValueAndValidity()
//    }
//     zipInputEvent(value: string) {
//         value = value.trim();
//         if (!value || value.length !== 5) return;
//         this.showCityWait = true;
//         this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
//             this.showCityWait = false;
//             if (res.success && res.data.city != undefined) {
//                 this.selectedState = this.usaStates.find(
//                     (s) => s.abbreviation == res.data.state
//                 );
//                 this.form.patchValue({ city: res.data.city });
//             } else {
//                 this.form.patchValue({ city: 'Invalid zip code' });
//             }
//         });
//     }
//     cancelButtonClick(){

//     }
//     zipInputEventb(value: string) {
//         value = value.trim();
//         if (!value || value.length !== 5) return;
//         this.showCityWait2 = true;
//         this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
//             this.showCityWait2 = false;
//             if (res.success && res.data.city != undefined) {
//                 this.selectedState2 = this.usaStates.find(
//                     (s) => s.abbreviation == res.data.state
//                 );
//                 this.form.patchValue({ cityb: res.data.city });
//             } else {
//                 this.form.patchValue({ cityb: 'Invalid zip code' });
//             }
//         });
//     }
//     szipInputEvent(value: string) {
//         value = value.trim();
//         if (!value || value.length !== 5) return;
//         this.sshowCityWait = true;
//         this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
//             this.sshowCityWait = false;
//             if (res.success && res.data.city != undefined) {
//                 this.sselectedState = this.usaStates.find(
//                     (s) => s.abbreviation == res.data.state
//                 );
//                 this.secondForm.patchValue({ scity: res.data.city });
//             } else {
//                 this.secondForm.patchValue({ scity: 'Invalid zip code' });
//             }
//         });
//     }
//     tzipInputEvent(value: string) {
//         value = value.trim();
//         if (!value || value.length !== 5) return;
//         this.tshowCityWait = true;
//         this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
//             this.tshowCityWait = false;
//             if (res.success && res.data.city != undefined) {
//                 this.tselectedState = this.usaStates.find(
//                     (s) => s.abbreviation == res.data.state
//                 );
//                 this.thirdForm.patchValue({ tcity: res.data.city });
//             } else {
//                 this.thirdForm.patchValue({ tcity: 'Invalid zip code' });
//             }
//         });
//     }
//     fzipInputEvent(value: string) {
//         value = value.trim();
//         if (!value || value.length !== 5) return;
//         this.fshowCityWait = true;
//         this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
//             this.fshowCityWait = false;
//             if (res.success && res.data.city != undefined) {
//                 this.fselectedState = this.usaStates.find(
//                     (s) => s.abbreviation == res.data.state
//                 );
//                 this.forthForm.patchValue({ fcity: res.data.city });
//             } else {
//                 this.forthForm.patchValue({ fcity: 'Invalid zip code' });
//             }
//         });
//     }
//     emailFormatValidator(): ValidatorFn {
//         return (control: AbstractControl): ValidationErrors | null => {
//             const value = control.value;
//             if (!value) return null;

//             const pvalid = value.match(this._globals.emailValidatorString);
//             return pvalid ? null : { emailStrength: true };
//         };
//     }

//     saveFn() {
//         let firstOwner = this.form.value
//         firstOwner.state = this.selectedState.abbreviation;
//         firstOwner.stateb = this.selectedState2.abbreviation;
       
//         if(this.noOwners.second === true){
//            let secondOwner = this.secondForm.value;
//            secondOwner.sstate =this.sselectedState.abbreviation;
//        }
//        if(this.noOwners.third === true){
//        let thirdOwner = this.thirdForm.value;
//        thirdOwner.tstate = this.tselectedState.abbreviation;}
//        if(this.noOwners.forth === true){
//        let forthOwner = this.forthForm.value;
//        forthOwner.fstate = this.fselectedState.abbreviation
//        }
      
//         // this._abd.showSpinner();
//            // const saveobj = {
//             //     firstName: this.form.get('firstName').value,
//             //     lastName: this.form.get('lastName').value,
//             //     phone: this.form.get('phone').value.replace(/\D/g, ''),
//             //     email: this.form.get('email').value,
//             //     postalCode: this.form.get('postalCode').value,
//             //     city: this.form.get('city').value,
//             // };
//             // this._customerService.createCustomer(saveobj).subscribe((res) => {
//             //     if (res.success) {
//             //         this._connMessageService.showToastMessage(
//             //             `Customer saved!`,
//             //             'success'
//             //         );
//             //         this._abd.hideSpinner();
//             //         this.refreshDataEmitter.emit();
//             //         this._customerService.showNewNewEdit = false;
//             //         this.editform.reset();
//             //     } else {
//             //         this._abd.hideSpinner();
//             //     }
//             // });
//         }
}
