<div class="grid mt-2">
    <div class="col">
        <label class="control-label">
            <strong
                >Would you like to have a customer app to target your potential
                customers?
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
    </div>
</div>


<div class="grid">
    <div
        class="col cursor-pointer"
        *ngFor="let ind of _signup.customAppBaseData"
    >
        <div
            [class]="
                ind.id == _signup.customAppBaseDataId
                    ? 'active-button'
                    : 'inactive-button'
            "
            (click)="_signup.customAppBaseDataId = ind"
        >
            <div
                class="flex align-items-center justify-content-center w-full font-bold text-xl text-900 mt-3 cursor-pointer"
            >
                {{ ind.name }}
            </div>
            <div
                class="flex align-items-center justify-content-center w-full text-lg text-500 mt-2 cursor-pointer"
            >
                {{ ind.detail }}
            </div>
        </div>
    </div>
</div>


<div class="grid">
    <div class="flex justify-content-between flex-wrap card-container purple-container mt-6">
        <div class="flex align-items-center justify-content-center">
            <p-button
                *ngIf="showFooter"
                label="Back"
                icon="pi pi-arrow-left"
                (onClick)="backClicked()"
            ></p-button>
        </div>
        <div class="flex align-items-center justify-content-center">
            <p-button
                label="Continue"
                icon="pi pi-arrow-right"
                iconPos="right"
                (onClick)="nextClicked()"
            ></p-button>
        </div>
    </div>
</div>
