<div *ngIf="hardwareList.length > 0">
    <h2 class="text-right">{{ totalHardwareCost | currency }}</h2>
    <div class="card" style="background-color: #eeeeee">
        <div class="grid">
            <div class="col-12" *ngFor="let hardware of hardwareList">
                <app-hardware-plan-widget-base
                    [hardware]="hardware"
                    (onSelectionChanged)="onSelectionChanged($event)"
                ></app-hardware-plan-widget-base>
            </div>
        </div>
    </div>

    <div class="flex justify-content-between flex-wrap card-container">
        <button
            pButton
            pRipple
            type="button"
            label="Back"
            class="p-button-warning"
            (click)="backButtonPressed()"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Next"
            (click)="nextButtonPressed()"
        ></button>
    </div>
</div>
