export interface Discount {
    discountName?: string;
    couponCodesList?: string[];
    discountValueType?: number;
    discountValue?: number;
    maximumAllowed?: number;
    limitDiscount?: number;
    ifOneTimeUse?: number;
    maxCouponUse?: number;
    maxCouponCodeUseLimit?: number;
    validity?: number;
    validityStartDate?: string;
    validityEndDate?: string;
    allowedDaysList?: any[];
    customerType?: number;
    minimumItemsInCart?: number;
    maximumItemsInCart?: number;
    minimumCartValueInDollar?: number;
    maximumItemsValueInDollar?: number;
    overrideAllOtherDiscounts?: number;
    notify?: number;
    printTextInFooter?: number;
    receiptFooterText?: string;
    allowedChannels?: number;
    allowedLocationsList?: number[];
    applyBy?: number;
    id?: string;
    allowedLocationsListString?: string[],
    printCouponAtRegister?: number;
    printCouponText?: number;
    printText?: string;
    printImage?: any;
    image?: string;
    printCouponStartDate?: string;
    printCouponEndDate?: string;
}



export const DiscountValueType = [
    { id: 0, viewValue: 'Flat' },
    { id: 1, viewValue: 'Percent' },
    // { id: 2, viewValue: 'Points' },
]

export const LimitDiscount = [
    { id: 0, viewValue: 'One Time Use' },
    { id: 1, viewValue: 'Set Max Use' },
]

export const IfOneTimeUse = [
  { id: 0, viewValue: 'None' },
    // { id: 0, viewValue: 'One Coupon per Invoice' },
    { id: 1, viewValue: 'One Coupon per Customer' },
    // { id: 2, viewValue: 'One Code per Invoice' },
    { id: 3, viewValue: 'One Code per Customer' },
   
]

export const Validity = [
    { id: 0, viewValue: 'Always' },
    { id: 1, viewValue: 'Between Dates' },
]

export const CustomerType = [
    { id: 0, viewValue: 'All' },
    { id: 1, viewValue: 'Loyalty' },
    { id: 2, viewValue: 'Cash Customer' },
    // { id: 3, viewValue: 'Group' },
]

export const OverrideAllOtherDiscounts = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]

export const Notify = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]

export const printCoupon = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]

export const printCouponText = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]

export const printImage = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]



export const PrintTextInFooter = [
    { id: 0, viewValue: 'No' },
    { id: 1, viewValue: 'Yes' },
]

export const AllowedChannels = [
    // { id: 0, viewValue: 'App' },
    { id: 1, viewValue: 'Online' },
    { id: 2, viewValue: 'In-Store' },
]

export const ApplyBy = [
    { id: 0, viewValue: 'Automatically' },
    { id: 1, viewValue: 'QR Code' },
    { id: 2, viewValue: 'Manual Entry' }
]

export const AllowedDaysList = [
    {
      id: 0,
      viewValue: "Sunday"
    },
    {
      id: 1,
      viewValue: "Monday"
    },
    {
      id: 2,
      viewValue: "Tuesday"
    },
    {
      id: 3,
      viewValue: "Wednesday"
    },
    {
      id: 4,
      viewValue: "Thursday"
    },
    {
      id: 5,
      viewValue: "Friday"
    },
    {
      id: 6,
      viewValue: "Saturday"
    },
    
  ]