import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
  @Input() mode:string='regular'
  @Input() selectedPlan=0
  @Input() ShowTrial=false


  listSubscriptions = []

  constructor(private _subscriptionPlanService: SubscriptionPlanService, private _router: Router) { }

  ngOnInit(): void {
    
    this._subscriptionPlanService.getSubscriptionPlanList().subscribe(res => {
      if (res.success) {
        let dd = res.data

        for (let i = 0; i < dd.length; i++) {
          dd[i].iisSelected = false
        }
        if(!this.ShowTrial)
        dd.splice(0, 1);        
        if(this.selectedPlan==0)
        dd[2].iisSelected = true
        else 
        dd[this.selectedPlan-1].iisSelected = true
        
        this.listSubscriptions = res.data
      }
    })
    this.saveToCache()
  }

  changeSubscription(id: any) {
    let dd = this.listSubscriptions
    for (let i = 0; i < dd.length; i++) {
      if (dd[i].id === id) {
        dd[i].iisSelected = true
      }
      else dd[i].iisSelected = false
    }
    this.saveToCache()
  }

  jumpNext() {
    this.saveToCache()
    this._router.navigateByUrl('uikit/signupBusinessDetail')
  }

  saveToCache() {
    this._subscriptionPlanService.selectedSubscription={}
    for (let i = 0; i < this.listSubscriptions.length; i++) {
      if (this.listSubscriptions[i].iisSelected) {
        this._subscriptionPlanService.selectedSubscription = this.listSubscriptions[i]
        break;
      }
    }
  }

}
