<div class="flex justify-content-start my-2">
    <div class="flex align-items-center justify-content-start  text-xl">
        Do you have a hardware device to run {{_global.whiteLabel.wlName}}
        <img title="Do you have a hardware device to run {{_global.whiteLabel.wlName}}" class="mx-2" src="assets/images/info.svg" />

    </div>
</div>

<div class="flex align-items-start justify-content-start  my-2 gap-4">
    <div class="col-12 md:col-6 lg:col-6 xl:col-5 xxl:col-5 align-items-center justify-content-start px-0 cursor-pointer "
        *ngFor="let hw of _signup.haveHardware">
        <div class="package-center" (click)="selectHaveHardware(hw)" [class]="
                hw.id == _signup.selectedHaveHardwareId
                    ? 'active-button'
                    : 'inactive-button'
            ">
            <div class="flex align-items-center justify-content-center w-full text-xl my-2
                text-900 cursor-pointer">
                {{ hw.name }}
            </div>
            <div class="flex align-items-center justify-content-center w-full text-500 mt-2 cursor-pointer">
                {{ hw.detail }}
            </div>
        </div>
    </div>
</div>

<div class="flex justify-content-start" *ngIf="_signup.selectedHaveHardwareId == 0">
    <div class="flex align-items-center justify-content-start  text-xl">
        Do you plan to accept credit cards?
        <img title="Do you have a hardware device to run {{_global.whiteLabel.wlName}}" class="mx-2" src="assets/images/info.svg" />

    </div>
</div>

<div class="flex align-items-start justify-content-start my-2 gap-4" *ngIf="_signup.selectedHaveHardwareId == 0">
    <div class="col-12 md:col-6 lg:col-6 xl:col-5 xxl:col-5 align-items-center justify-content-start px-0 cursor-pointer "
        *ngFor="let hw of _signup.doProcessOnlinePayments">
        <div class="package-center" (click)="_signup.selectedDoProcessOnlinePaymentsId = hw.id" [class]="
                hw.id == _signup.selectedDoProcessOnlinePaymentsId
                    ? 'active-button'
                    : 'inactive-button'
            ">
            <div class="flex align-items-center justify-content-center w-full text-xl my-2
                text-900  cursor-pointer">
                {{ hw.name }}
            </div>
            <div class="flex align-items-center justify-content-center w-full text-500 mt-2 cursor-pointer">
                {{ hw.detail }}
            </div>
        </div>
    </div>
</div>

<div *ngIf="showFooter" class="flex justify-content-between flex-wrap card-container purple-container mt-8">
    <div class="flex align-items-center justify-content-center">
        <p-button label="Back" class="signup-back-button" icon="pi pi-arrow-left" (onClick)="backClicked()"></p-button>
    </div>
    <!--  -->
    <div class="flex align-items-center justify-content-center">
        <p-button label="Continue" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextClicked()"></p-button>
        <!-- (onClick)="saveFn()" -->
    </div>
</div>
