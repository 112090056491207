<div class="flex justify-content-center align-items-center">
    <div class="m-2">
        <label for="minmax-buttons" class="mr-4 font-medium">
            Number of Locations
        </label>
        <p-inputNumber [(ngModel)]="_signup.outerLocationQty" (onInput)="changeValueLoc($event)"
            (onBlur)="changeValueLoc($event)" (onClear)="changeValueLoc($event)" [showButtons]="true"
            [disabled]="disabledLocationAndRegister" inputId="stacked" [step]="1" [min]="1" [max]="100"
            class="input-number-for-subscription" [allowEmpty]="false" class="input-number-for-subscription"
            [minFractionDigits]="0" [maxFractionDigits]="0" [maxlength]="3" [inputStyle]="{
                width: '70px',
                'text-align': 'center',
                height: '30px'
            }">
        </p-inputNumber>
    </div>

    <div class="m-4">
        <label for="minmax-buttons" class="mr-4 font-medium">
            Number of Registers
        </label>
        <p-inputNumber [(ngModel)]="_signup.outerRegisterQty" (onInput)="changeValueReg($event)"
            (onBlur)="changeValueReg($event)" (onClear)="changeValueReg($event)"
            [disabled]="disabledLocationAndRegister" mode="decimal" class="input-number-for-subscription"
            [showButtons]="true" inputId="stacked" [min]="_signup.outerLocationQty" [max]="999" [maxlength]="3"
            [allowEmpty]="false" [step]="1" [inputStyle]="{
                width: '70px',
                'text-align': 'center',
                height: '30px'
            }">
        </p-inputNumber>
    </div>
</div>