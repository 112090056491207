import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { SigninModel } from 'src/app/_models/user/signin';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-redirectsignup',
  templateUrl: './redirectsignup.component.html',
  styleUrls: ['./redirectsignup.component.scss']
})
export class RedirectsignupComponent implements OnInit {
  _globals=GlobalService
  constructor() { }

  ngOnInit(): void {
    
    window.location.replace(this._globals.whiteLabel.signupURL);
  }

}
