import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mask-input',
  templateUrl: './mask-input.component.html',
  styleUrls: ['./mask-input.component.scss']
})
export class MaskInputComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
