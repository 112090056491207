<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700;
    "
>
    Sales History
</h3>

<p-table
    [value]="dataList"
    responsiveLayout="scroll"
    [autoLayout]="true"
    [style]="{'width':'100%'}"
    [tableStyle]="{ width: 'max-content' }"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Invoice#</th>
            <th>Date-Time</th>
            <th>Store</th>
            <th>Status</th>
            <th>Purchase</th>
            <th>Loyalty Points</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{ item.invoiceNumber }}</td>
            <td>{{ item.date }} {{ item.time }}</td>
            <td>{{ item.location }} ({{ item.register }})</td>
            <td>{{ item.postedStatusString }}</td>
            <td>{{ item.purchase | currency }}</td>
            <td>{{ item.loyaltyAward | number }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" style="text-align: center">
        <tr>
            <td colspan="10" style="text-align: center">
                There is no data to show.
            </td>
        </tr>
    </ng-template>
</p-table>

<p-paginator
    #p
    *ngIf="dataList?.length"
    (onPageChange)="onPageChange($event)"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="[10, 20, 50, 100, 500, 1000]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="*Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
>
</p-paginator>
