import { Component, Input, OnInit } from '@angular/core';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transfer-detail-report2',
  templateUrl: './transfer-detail-report2.component.html',
  styleUrls: ['./transfer-detail-report2.component.scss']
})
export class TransferDetailReport2Component implements OnInit {

  @Input() transferReportData: any = '';

  colheader = [['Product', 'SKU', 'Barcode', 'Expiration', 'Transfered Qty (#)']];
  colflds = ['productName', 'itemSKUId', 'barcode', 'expiryDate', 'transferQuantity'];
  summaryHeader = [['Product', 'SKU', 'Barcode', 'Expiration', 'Transfered Qty (#)']];
  domSummaryflds = ['productName', 'itemSKUId', 'barcode', 'expiryDate', 'transferQuantity'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  
  dataList: any = ''

  constructor(
    private _abd: AppBaseDataService,
    private _bs: BaseService,) { }

  ngOnInit(): void {
    // console.log("Child component",this.transferReportData);
    this.getDataList();
    
  }

  getDataList() {
    let url = environment.InventoryBaseURL + "Transfer/SearchStockTransfer/"+this.transferReportData.transferId+"?TransfersStatusCheck="+this.transferReportData.transferType;
    this._abd.showSpinner();
    this._bs.httpGet(url).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList.stockTransfersDetails, this.summaryHeader[0], this.colflds, 'Transfer Detail Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList.stockTransfersDetails, this.summaryHeader, this.colflds, 'Transfer Detail Report', true)
  }

}
