<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }">

    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            
            <button
            
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="_customerService.showNewEdit = true"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'customer'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
        (deleteItemFn)="optionDeleteClicked()"
    ></app-report-top-filter-bar>
    <!-- (deleteItemFn)="deleteItem()" -->
    
    <br />
    <p-table
        #dt
        [tableStyle]="{ width: 'max-content' }"
        [value]=""
        [autoLayout]="true"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="rows"
        showLoader="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers"
        [globalFilterFields]="['customerName']"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]">

        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center">
                    <p-checkbox
                        [(ngModel)]="selectAll"
                        [binary]="true"
                        (onChange)="selectAllChanged($event)"
                    ></p-checkbox>
                </th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>Zip Code</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr class="text-center">
                <td style="text-align: center">
                    <p-checkbox
                        [(ngModel)]="item.selected"
                        [binary]="true"
                        (onChange)="selectionChanged($event)"
                    ></p-checkbox>
                </td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.postalCode }}</td>
                <td>{{ item.status }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<!-- <p-dialog
    *ngIf="true"
    header="Edit Customer"
    visible="true"
    [style]="{ width: '50%' }"
>
    <app-new-edit-customer [isNew]="false"></app-new-edit-customer>
</p-dialog> -->