<div *ngIf="token">
    <form #myFormPost name="myFormPost" action="https://test.authorize.net/payment/payment" target="paytm"
        method="post">
        <input type="hidden" #poptoken id="popupToken" name="token" [value]="token" />
    </form>
    <iframe name="paytm" width="100%" height="900px" frameBorder="1"
        src="https://test.authorize.net/payment/payment"></iframe>
</div>

<div *ngIf="!token">
    <p-progressBar mode="indeterminate"></p-progressBar>
</div>
