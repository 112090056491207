import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Discount } from '../_models/discount';
import { AppBaseDataService } from './app-base-data.service';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {

  _globals = GlobalService
  showEditDiscount: boolean = false;
  activeItem: Discount = null
  showNewEditDiscount: boolean = false;
  thImages: any = []
  thImageNames: any = []
  // isNewDiscount: boolean;

  constructor(private _bs: BaseService,
              private _abd: AppBaseDataService) { }

  blankActiveItem(){
    this.activeItem = {
      discountName: '',
      couponCodesList: [],
      discountValueType: 0,
      discountValue: null,
      maximumAllowed: 0,
      limitDiscount: 0,
      ifOneTimeUse: 0,
      maxCouponUse: 0,
      maxCouponCodeUseLimit: 0,
      validity: 0,
      validityStartDate:  new Date().toString(),
      validityEndDate: new Date(new Date().setDate(new Date().getDate() - 7)).toString(),
      allowedDaysList: [0,1,2,3,4,5,6],
      customerType: 0,
      minimumItemsInCart: 0,
      maximumItemsInCart: 0,
      minimumCartValueInDollar: 0,
      maximumItemsValueInDollar: 0,
      overrideAllOtherDiscounts: 0,
      notify: 0,
      printTextInFooter: 0,
      receiptFooterText: '',
      allowedChannels: 2,
      allowedLocationsList: (this._abd.actLocations.length > 1) ? [] : [this._abd.actLocations.map(a => a.locationId)[0]],
      applyBy: 0,
      id: "0",
      printCouponAtRegister : 0,
      // printCouponText: 0,
      printText: '',
      // printImage: 0,
      image: '',
      printCouponStartDate: new Date().toString(),
      printCouponEndDate: new Date(new Date().setDate(new Date().getDate() - 7)).toString(),
    }
  }

  getAllDiscounts(model){
    let url = environment.BusinessBaseURL + 'DiscountCoupon'
    return this._bs.httpGetParams(url, model).pipe(map((res: any) => {
      return res
    }))
  }

  addDiscounts(model: Discount){
    let url = environment.BusinessBaseURL + 'DiscountCoupon'
    return this._bs.httpPost(url, model).pipe(map((res: any) => {
      return res
    }))
  }

  deleteDiscount(discountId: any){
    let url = environment.BusinessBaseURL + 'DiscountCoupon' + "/" + discountId;
    return this._bs.httpDelete(url).pipe(map((res: any) => {
      return res
    }))
  }

  updateDiscount(discountId: any, model: Discount){
    let url = environment.BusinessBaseURL + 'DiscountCoupon' + "/" + discountId;
    return this._bs.httpPut(url, model).pipe(map((res: any) => {
      return res
    }))
  }
}
