<div
    class="flex justify-content-between flex-wrap card-container purple-container mt-6"
>
    <div class="flex align-items-center justify-content-center">
        <p-button
            *ngIf="!hideBackButton"
            label="Back"
            icon="pi pi-arrow-left"
            (onClick)="backClicked()"
        ></p-button>
    </div>
    <div class="flex align-items-center justify-content-center">
        <p-button
            label="Continue"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextClick()"
        ></p-button>
    </div>
</div>
