import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { Router } from '@angular/router';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [`
    :host ::ng-deep .p-password input {
    width: 100%;
    padding:1rem;
    }

    :host ::ng-deep .pi-eye{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }

    :host ::ng-deep .pi-eye-slash{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--brandColor) !important;
    }
  `]
})
export class ChangePasswordComponent implements OnInit {
  resetpasswordForm: FormGroup
  _globals = GlobalService

  constructor(
    private _fb: FormBuilder,
    public _userService: UserService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    this.intitializeResetpasswordForm()
  }

  intitializeResetpasswordForm() {

    this.resetpasswordForm = this._fb.group({
      password: [
        '',
        [Validators.required, this.passwordStrengthValidator()],
      ],
      confirmPassword: [
        '',
        [Validators.required, this.confirmPasswordStrengthValidator()],
      ],
      // oldPassword: [
      //   '',
      //   [Validators.required],
      // ],
    })
  }

  confirmPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      try {
        const value11 = this.resetpasswordForm.get('password').value
      } catch { return null }
      const value1 = this.resetpasswordForm.get('password').value
      if (!value) return null;

      return value === value1 ? null : { conpasswordStrength: true }
    }
  }

  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this._globals.passwordValidatorString)
      return pvalid ? null : { passwordStrength: true };
    }
  }

  changePasswordSubmit() {
    // const password = this.resetpasswordForm.controls.password.value
    // const oldpassword = this.resetpasswordForm.controls.oldPassword.value
    // const UserID = this._globals.userInfo.userID
    // const Username = this._globals.userInfo.username

    const user = {
      Password: this.resetpasswordForm.controls.password.value,
      // Oldpassword: this.resetpasswordForm.controls.oldPassword.value,
      UserID: this._globals.userInfo.userID,
      Username: this._globals.userInfo.username
    }

    try {
      this._abd.showSpinner()
      this._userService.changePassword(user).subscribe((res: any) => {
        if (res.success == true) {
          this._abd.hideSpinner()
          this._userService.logout()
          this._connMessageService.showToastMessage('New Password saved successfully.', 'success')
        
        } else {
          this._abd.hideSpinner()
          this._connMessageService.showToastMessage('Unable to save new password.', 'error')
          this._router.navigate(['pages/signin'])
        }
        this._abd.hideSpinner()
      })
    } catch {this._abd.hideSpinner()}
    
  }
  // ressetPasswordSubmit() {
  //   // const password = this.resetpasswordForm.controls.password.value
  //   // const oldpassword = this.resetpasswordForm.controls.oldPassword.value
  //   // const UserID = this._globals.userInfo.userID
  //   // const Username = this._globals.userInfo.username

  //   const user = {
  //     Password: this.resetpasswordForm.controls.password.value,
  //     Oldpassword: this.resetpasswordForm.controls.oldPassword.value,
  //     UserID: this._globals.userInfo.userID,
  //     Username: this._globals.userInfo.username
  //   }

  //   try {
  //     this._abd.showSpinner()
  //     this._userService.changePassword(user).subscribe((res: any) => {
  //       if (res.success == true) {
  //         this._abd.hideSpinner()
  //         this._userService.logout()
  //         this._connMessageService.showToastMessage('New Password saved successfully.', 'success')
  //         this._router.navigate(['pages/signin'])
  //       } else {
  //         this._abd.hideSpinner()
  //         this._connMessageService.showToastMessage('Unable to save new password.', 'error')
  //         this._router.navigate(['pages/signin'])
  //       }
  //       this._abd.hideSpinner()
  //     })
  //   } catch {this._abd.hideSpinner()}
    
  // }

  resetpassworSubmit() {
    if (!this._globals.userInfo?.userID) {
      this._router.navigate(['pages/signin'])
    }
    const password = this.resetpasswordForm.controls.password.value
    this._abd.showSpinner()
    this._userService.resetPassword(password,
      this._globals.userInfo.userID).subscribe((res: any) => {
        if (res.success == true) {
          this._abd.hideSpinner()
          this._userService.logout()
          this._connMessageService.showToastMessage('Password saved successfully.', 'success')
          this._router.navigate(['pages/signin'])
        } else {
          this._abd.hideSpinner()
          this._connMessageService.showToastMessage('Unable to save new password.', 'error')
          this._router.navigate(['pages/signin'])
        }
        this._abd.hideSpinner()
      })
  }

  resetFormOk() {
    const password = this.resetpasswordForm.controls.password.value
    const cpassword = this.resetpasswordForm.controls.confirmPassword.value

    if (password === cpassword) return true
    else return false
  }



}
