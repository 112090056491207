<div class="form-group">
    <input
        pInputText
        [class.is-invalid]="ngControl.touched && ngControl.invalid"
        type="{{ type }}"
        class="form-control mt-2"
        style="width:100%;"
        [disabled]="disabled"
        [formControl]="$any(ngControl.control)"
        placeholder="{{ label }}"
    />
    <span class="control-description text-muted" *ngIf="description.length>0"> {{description}}</span>
    <div *ngIf="ngControl.touched">
        <div *ngIf="ngControl.control.errors?.pattern" class="invalid-feedback">
            Invalid characters
        </div>
        <div *ngIf="ngControl.control?.errors?.required" class="invalid-feedback">
            Please enter a {{ name }}
        </div>
        <div *ngIf="ngControl.control?.errors?.minlength" class="invalid-feedback">
            {{ name }} must be at least
            {{ ngControl.control?.errors?.minlength["requiredLength"] }} characters!
        </div>
        <div *ngIf="ngControl.control?.errors?.maxlength" class="invalid-feedback">
            {{ name }} must be at least
            {{ ngControl.control?.errors?.maxlength["requiredLength"] }} characters!
        </div>
        <div *ngIf="ngControl.control?.errors?.email" class="invalid-feedback">
            Enter a valid email!
        </div>
        <div *ngIf="ngControl.control?.errors?.matching" class="invalid-feedback">
            Passwords do not match!
        </div>
        <div *ngIf="ngControl.control?.errors?.invalidzipcode" class="invalid-feedback">
            Invalid Zip Code!
        </div>
        <div *ngIf="ngControl.control?.errors?.emailStrength" class="invalid-feedback">
            Invalid email format!
        </div>
        <div *ngIf="ngControl.control?.errors?.min" class="invalid-feedback">
            {{ name }} must be greater than
            {{ ngControl.control?.errors?.min?.min }}!
        </div>
        <div *ngIf="ngControl.control?.errors?.max" class="invalid-feedback">
            {{ name }} must be less than
            {{ ngControl.control?.errors?.max?.max }}!
        </div>
    </div>
</div>
