// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//export const APIURL='https://connect360.azure-api.net/'
// export const APIURL='https://connect360-stg.azure-api.net'
export const APIURL='https://lsapim.azure-api.net'
export const environment = {
  production: false,
  apiUrl: APIURL,
  hubUrl: 'https://localhost:5001/hubs/',
// };
// export const environment = {
  // production: false,
  AzureAPIURLForLogin: APIURL+"/auth-svc/api/SignIn",
  AzureAPIURL: APIURL+"/auth-svc/api/SignIn",
  AzureUserInfo: APIURL+"/account-svc/api/B2CGraphClientApi",
  BaseURL: APIURL+"/auth-svc/api/",
  AuthenticationURL: APIURL+"/auth-svc/api/",
  BusinessBaseURL: APIURL+"/account-svc/api/",
  ValidateUserEmailForBusiness: "IsUserExist",
  CreateOwnerAccount: "SignUpByEmail",
  SetupBusiness: "SetBusinessInfo",
  CreateLocation: "SetLocationSetup",
  EditLocationSetup: "EditLocationSetup",
  GetAllLocations: "GetLocationSetup",
  CustomerFunctionalityBaseURL: APIURL+"/customer-svc/api/",
  GetCustomer: "Customer",
  CompanyExistCheck: "IsCompanyExist",
  InventoryBaseURL: APIURL+"/inventory-svc/api/",
  InventoryBaseURLDebug: "http://192.168.1.99/api/",
  GetDepartmentList: "Department",
  ChangeOrderStatus: "ChangeOrderStatus",
  CreateDepartment: "Department",
  CategoriesUrl: "Category",
  VendorURL: "Vendor",
  GetCustomerGroup: "CustomerGroups",
  CustomerBaseURL: APIURL+"/customer-svc/api/",
  GetCustomerURL: "Customer",
  SearchCustomerURL: "SearchCustomer",
  OrderBaseURL: APIURL+"/order-svc/api/",
  GetInvoiceByInvoiceNumber: "Invoice/GetOrderByInvoiceNo",
  GetRoles: "GetBusinessRoles",
  GetRights: "GetBusinessRights",
  GetRightsGroup: "GetBusinessRightsGroup",
  GetMeasuringUnit: "Unit",
  GetManufacturer: "Manufacturer",
  ItemModule: "Item",
  CloudStorageBaseURL: APIURL+"/storage-svc/api/",
  UploadProductFile: "Upload/ProductImage",
  AddQuantity: "AddQuantity",
  GetStockStatus: "GetStockStatus",
  GetPurchaseHistory: "Invoice/GetInvoice",
  GeneralDetail: "Items/GeneralDetail",
  GetIndustryList: "GetIndustry",
  SaveDirectPurchaseData: "Purchase/DirectPurchase",
  GetPurchase: "Purchase/GetPurchaseOrders",
  SearchInvoiceByCustomerId: "Invoice/GetInvoiceByCustId",
  GetByBarcode: "InventoryItem/GetByBarcode",
  PurchaseOrder: "Purchase/PurchaseOrder",
  GetStockStatusReport: "GetStockStatusReport",
  InventoryVerificationCount: "InventoryVerificationCount",
  GetTopWidgets: "Dashboard/GetTopWidgets",
  SearchPurchaseOrder: "Purchase/SearchPurchaseOrder",
  GetGoodsReceivingNoteByPO: "Purchase/GetGoodsReceivingNoteByPO",
  GetDailyCustomers: "Dashboard/GetDailyCustomers",
  GetDailySales: "Dashboard/GetDailySales",
  GetTopSoldProducts: "Dashboard/GetTopSoldProducts",
  GetLowStockProducts: "Dashboard/GetLowStockProducts",
  Report: "Reports/GetReport",
  SessionTimeOut: "24",
  TimeOutUnit: "hours",
  SetBusinessConfig: "SetBusinessConfig",
  SubscriptionPlanList: "GetSubscriptions",
  GetHardwarePackages: "GetHardwarePackages",
  GetHardwarePackagesv2: "GetHardwarePackagesv2",
  GetSupportPackages: "GetSupportPackages",
  SetBTaxConfig: "SetBTaxConfig",
  SetBPrintingConfig: "SetBPrintingConfig",
  SetBCashDropConfig: "SetBCashDropConfig",
  SetBusinessRoles: "SetBusinessRoles",
  EditBusinessRoles: "EditBusinessRoles",
  DeleteBusinessRoles: "DeleteBusinessRoles",
  SetBusinessInfoRuntime: "SetBusinessInfoRuntime",
  SetCreditcardProcessing: "SetCreditcardProcessing",
  AddBulkEmployees: "AddBulkEmployees",
  SetBusinessWizardFinished: "SetBusinessWizardFinished",
  SetBusinessConfigWizard: "SetBusinessConfigWizard",
  SetBusinessConfigComplete: "SetBusinessConfigComplete",
  GETUSERBYUSERNAME: "GETUSERBYUSERNAME",
  GetCreditcardProcessing: "GetCreditcardProcessing",
  ForgotPassword: "ForgetPassword",
  GetBusinessConfig: "GetBusinessConfig",
  SetBusinessConfigCompleted: "SetBusinessConfigCompleted",
  SetTaxConfig: "SetTaxConfig",
  SetCashHandlingConfig: "SetCashHandlingConfig",
  SetPrintingConfig: "SetPrintingConfig",
  SetCashDropConfig: "SetCashDropConfig",
  SetCashDrawerConfig: "SetCashDrawerConfig",
  SetSecurityConfig: "SetSecurityConfig",
  SetTenderScreenConfig: "SetTenderScreenConfig",
  GetLocationSetup: "GetLocationSetup",
  EmailForCustomPlan: "EmailForCustomPlan",
  CreateBusinessUser: "CreateBusinessUser",
  FinishSignUpProcess: "FinishSignUpProcess",
  ActivateUserAccount: "ActivateUserAccount",
  CreateBusinessInfo: "CreateBusinessInfo",
  GetIndustries: "GetIndustries",
  GetPurchaseOrders: "Purchase/GetPurchaseOrders",
  GetCurrency: "GetCurrency",
  GetDefaultLanguages: "GetDefaultLanguages",
  UpdateProfileImage: "Upload/ProfileImage",
  GetRegisters: "GetRegisters",
  SetRegisterStatus: "SetRegisterStatus",
  GoodsReceivingNote: "Purchase/GoodsReceivingNote",
  ImportInventory: "Item/ImportInventoryItems",
  ZipInfoService: "ZipInfoService",


  GetCashierList: "/Filters/GetCashierList",
  AuthorizeEnvironment: "testing", // authorize.net environment configuration
  CancelPurchaseOrder: "Purchase/CancelPurchaseOrder",
  TransferStock: "Transfer/StockTransfer",
  GetTransferList: "Transfer/GetStockTransfers",
  FilterInvoiceHistory: "Invoice/FilterInvoice",
  SearchStockTransfer: "Transfer/SearchStockTransfer",
  GetPurchaseOrderItems: "/Purchase/GetPurchaseOrderItems",
  AuthorizeMerchantURL: "https://apitest.authorize.net/xml/v1/request.api",

  SetTenderOtherCharges: "SetTenderOtherCharges",
  GetTenderOtherCharges: "GetTenderOtherCharges",
  GetByFilterValue: "FilterItems",
  AssignCustomerToGroup: "CustomerGroups/Assignment",
    GetAssignedCustomers: 'GetCustomersByGroupId',
    GetAssignedCustomersListV2: 'GetCustomerIdsByGroupId',
    AUTHORIZE_NET_PAYMENT_URL: "https://test.authorize.net/payment/payment",
    AUTHORIZE_NET_PAYMENT_CHANGE_CC_URL: "https://test.authorize.net/payment/payment",
    AUTHORIZE_NET_API_LOGIN_NAME: "3f7eGY64R",
    AUTHORIZE_NET_API_LOGIN_KEY:"7p8h84Q9k6uYQj2J",
    AUTHORIZE_NET_TOKEN_URL: "https://apitest.authorize.net/xml/v1/request.api",
    // AUTHORIZE_NET_PAYMENT_URL: "https://test.authorize.net/payment/payment",
    // AUTHORIZE_NET_API_LOGIN_NAME: "5T6cxQ6t",
    // AUTHORIZE_NET_API_LOGIN_KEY:"2b33wmJuS23ac9Pu",
    // AUTHORIZE_NET_TOKEN_URL: "https://apitest.authorize.net/xml/v1/request.api",


    //for use with live
    // AUTHORIZE_NET_PAYMENT_URL: "https://accept.authorize.net/payment/payment",
    // AUTHORIZE_NET_API_LOGIN_NAME: "76N3Zq7k5",
    // AUTHORIZE_NET_API_LOGIN_KEY: "2CsD7T7x48ruw642",
    // AUTHORIZE_NET_TOKEN_URL: "https://api2.authorize.net/xml/v1/request.api",



    RegularLoyaltyConfig: "RegularLoyaltyConfig",
    GetBusinessLoyaltyConfiguration: "GetBusinessLoyaltyConfiguration",
    BGeneralLoyaltyConfig: "BGeneralLoyaltyConfig",
    PerksReward: "PerksReward",
    LoyaltyConfigType:"LoyaltyConfigType",
    GetBusinessInvoice:"GetBusinessInvoice",
    GetBusinessInvoices:"GetBusinessInvoices",
    GetPaymentProfile:"GetPaymentProfile",
    CancelBusinessSubscription:"CancelBusinessSubscription",
    UpdateBusinessSubscription:"UpdateBusinessSubscription",
};

