<p-menu #dcmenu [popup]="true" [model]="drawerCountVerify"></p-menu>

<div class="conn-card reconciliation">
    <app-report-top-filter-bar
        *ngIf="step == 1"
        [paraReportName]="'cash-reconciliation-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <app-report-top-filter-bar
        *ngIf="step == 2"
        [paraReportName]="'cash-reconciliation-report-3'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <app-report-top-filter-bar
        *ngIf="step == 3"
        [disableAllControls]="true"
        [paraReportName]="'cash-reconciliation-report-3'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>
    <div *ngIf="step > 1">
        <br />
        <div class="grid">
            <div
                class="md:col-6 lg:col-4 xl:col-3"
                *ngFor="let tag of topTags"
            >
                <div

                    class="conn-card text-align-center "
                    [style]="getOverShortColor(tag)"
                >
                    <label
                        (click)="showDetail(tag.id, tag.allowPopup)"

                        class="widget-title font-bold text-1xl {{tag.class}}"
                    >
                        {{ tag.title }}

                        <i  [ngClass]="tag.class + ' ' + tag.icon_class "

                             pTooltip="{{tag.tooltip}}"
                            tooltipPosition="top">
                        </i>
                    </label>
                    <p-divider></p-divider>
                    <label
                        class="widiget-figure-main font-bold text-lg"
                        [style]="getOverShortCColor(tag)"
                    >
                        {{ calculateSummaryTotal(tag.id) | currency }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="step == 2" class="customaccordian">
    <p-accordion [multiple]="true" (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)">
        <p-accordionTab *ngFor="let tillObj of drawerCountList" [selected]="tillObj.selected" class="CustomAccordianHeader">
            <ng-template pTemplate="header" >
   
              <div class="header-content">
        <div class="start-content col"> &nbsp;
         <span class="till-heading">Register: &nbsp;</span>  
         <span>{{tillObj.registerName }}</span>
    </div>
        <div class="middle-content col">
           <span class="till-heading">Till: &nbsp;</span> 
           <span>{{tillObj.tillNo}}</span> 
        </div>
      </div>
    </ng-template>
            <table class="p-datatable table p-datatable-table ng-star-inserted" style="width: 100%; border-collapse: collapse;">
                <thead class="p-datatable-thead">
                    <tr class="ng-star-inserted">
                        <th class="text-center">Starting Cash</th>
                        <th class="text-center">Cash Sales</th>
                        <th class="text-center">Adjustment</th>
                        <th class="text-center">Expected Cash</th>
                        <th class="text-center">Manager Count</th>
                        <th class="text-center">Over/Short</th>
                        <th class="text-center">Notes</th>
                      </tr>
                </thead>
                <tbody class="p-element p-datatable-tbody">
                    <tr class="ng-star-inserted">
                        <td class="text-center">{{ tillObj.startingCash | currency }}</td>
                        <td class="text-center">{{ tillObj.cashSales | currency }}</td>
                        <td class="text-center">{{ tillObj.adjustments | currency}}</td>
                        <td class="text-center">{{ tillObj.expectedCash | currency }}</td>
                        <td class="text-center">{{ tillObj.managerCount | currency}} <i class="pi pi-window-maximize cursor-pointer" (click)="showManagerCountAmount(tillObj)" style="font-size: 1rem"></i></td>
                        <td class="text-center">{{ tillObj.overShort | currency }}</td>
                            <td class="text-center">
                                <i *ngIf="tillObj.tillNotes!=''" class="pi pi-book cursor-pointer" (click)="showTillNotes(tillObj)" style="font-size: 1.2rem; color: var(--brandColor);"></i>
                                <i *ngIf="tillObj.tillNotes==''" class="pi pi-book cursor-pointer" (click)="showTillNotes(tillObj)" style="font-size: 1.2rem;"></i>

                             </td>
                    </tr>
                </tbody>

            </table>
        <p-accordion (onOpen)="onTabOpenModule($event,tillObj)" (onClose)="onTabCloseModule($event,tillObj)" [multiple]="true">
            <p-accordionTab [selected]="tillObj.dropCashSelected">
                <ng-template pTemplate="header" >
                    <div class="flex align-items-center p-jc-between px-3">
                    Cash Drops
                    </div>
                    <div class="flex align-items-center p-jc-between px-3">

                        <button
                            pButton
                            type="button"
                            label="Add Cash Drops"
                            class="ml-2"
                            icon="pi pi-dollar"
                            iconPos="left"
                            style="width: 170px"
                            *ngIf="allowAddingCashDrop"
                            (click)="cashDropsHandler(tillObj)"
                        ></button>

                        </div>

                </ng-template>
                <ng-container *ngIf="step == 2">
                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="tillObj.cashDrops"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center">Register</th>
                                <th class="text-center">Dropped By</th>
                                <th class="text-center">Date & Time</th>
                                <th class="text-center">Total Drop</th>
                                <th class="text-center">Manger Count</th>
                                <th class="text-center">
                                    <!-- <p-inputSwitch *ngIf="tillObj.cashDrops.length>0" [(ngModel)]="tillObj.checkAllDropCash" (onChange)="verifyAllCountByManager($event,tillObj)"></p-inputSwitch> Verified  -->
                                    <button
                                    pButton
                                    *ngIf="tillObj.cashDrops.length>0" 
                                    (click)="verifyAllCashDropByManager(tillObj.cashDrops)"
                                    type="button"
                                    label="Verified"
                                    class="p-button-info"
                                ></button>
                                </th>
                                <th class="text-center">Manager Reason</th>
                                <!-- <th>Notes</th> -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class="text-center">{{ item.registerName }}</td>
                                <td class="text-center">{{ item.dropedBy }}</td>
                                <td class="text-center">{{ item.dateTime | date : "short" }}</td>
                                <td class="text-center">
                                    {{ item.totalDrop | currency }} <i *ngIf="item.noDenomination==false" class="pi pi-window-maximize cursor-pointer" (click)="getDropsDenomincationAmount(item,tillObj)" style="font-size: 1rem"></i>
                                </td>
                                <td class="text-center">
                                    <!-- <p-inputNumber
                                    [(ngModel)]="item.managerCount"
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    inputId="minmax-buttons"
                                    (onBlur)="verifyAmountByManager($event, item)"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly
                                    "
                                >
                                </p-inputNumber> -->
                                    <p-inputNumber
                                    [(ngModel)]="item.managerCount"
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    inputId="minmax-buttons"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly
                                    "
                                >
                                </p-inputNumber>
                                  </td>
                                <td class="text-center">
                                    <!-- <p-inputSwitch (onChange)="verifyCountByManager($event,item,tillObj)" [(ngModel)]="item.isVerified"></p-inputSwitch> </td> -->
                                    <p-inputSwitch  [(ngModel)]="item.isVerified"></p-inputSwitch> </td>
                                <td class="text-center">
                                    <i *ngIf="item.managerReason!=''"  class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem; color:var(--brandColor);"></i>
                                    <i *ngIf="item.managerReason==''"  class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem;"></i>
                                </td>


                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="17" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" style="text-align: right;" >
                            <tr>
                            <td colspan="7">
                                <div class="flex justify-content-end flex-wrap" *ngIf="tillObj.cashDrops.length>0">
                                    <div
                                        class="flex align-items-center justify-content-center m-2"
                                    >
                                        <button
                                            pButton
                                            type="button"
                                            label="Save"
                                            class="p-button-secondary"
                                            (click)="saveAllDropsConfirmationByManager(tillObj.cashDrops)"
                                        ></button>
                                    </div>
                                </div>
                            </td>
                            </tr>

                        </ng-template>
                    </p-table>

                </ng-container>

            </p-accordionTab>
            <p-accordionTab [selected]="tillObj.cashCountSelected">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center p-jc-between px-3">
                    Cash Count
                    </div>
                    <div class="flex align-items-center p-jc-between px-3">

                        <button
                            pButton
                            type="button"
                            label="Add Cash Count"
                            class="ml-2"
                            icon="pi pi-dollar"
                            iconPos="left"
                            *ngIf="allowAddingCashCount"
                            style="width: 170px"
                            (click)="cashDepositHandler(tillObj)"
                        ></button>
                        </div>
                </ng-template>
                <ng-container *ngIf="step == 2">

                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="tillObj.cashCounts"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center">Register</th>
                                <th class="text-center">Counted By</th>
                                <th class="text-center">Date & Time</th>
                                <th class="text-center">Total Count</th>
                                <th class="text-center">System Count</th>
                                <th class="text-center">Over/Short</th>
                                 <th class="text-center">
                                    <!-- <p-inputSwitch *ngIf="tillObj.cashCounts.length>0" [(ngModel)]="tillObj.checkAllCashCount" (onChange)="verifyAllCashCountByManager($event,tillObj)"></p-inputSwitch>Verified -->
                           
                                </th>
                                 <th class="text-center">Manager Reason</th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class="text-center" >{{ item.registerName }}</td>
                                <td class="text-center">{{ item.countedBy }}</td>
                                <td class="text-center">{{ item.dateTime | date : "short" }}</td>
                                <td class="text-center">
                                    {{ item.totalCount | currency }} <i *ngIf="item.noDenomination==false" class="pi pi-window-maximize cursor-pointer " (click)="getCountsDenomincationAmount(item, tillObj )" style="font-size: 1rem"></i>
                                </td>
                                <td class="text-center">
                                    {{ item.systemCount | currency }}
                                </td>
                                <td class="text-center">{{ item.overShort | currency }}</td>
                                <td class="text-center">
                                   <p-inputSwitch [(ngModel)]="item.isVerified"></p-inputSwitch> </td>
                                <td class="text-center">
                                     <i *ngIf="item.managerReason!=''" class="pi pi-book cursor-pointer" (click)="showCashCountReason(item)" style="font-size: 1.2rem; color:var(--brandColor);"></i>
                                     <i *ngIf="item.managerReason==''" class="pi pi-book cursor-pointer" (click)="showCashCountReason(item)" style="font-size: 1.2rem;"></i>
                                    </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="17" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" style="text-align: center">
                            <tr>
                                <td colspan="8">
                            <div class="flex justify-content-end flex-wrap" *ngIf="tillObj.cashCounts.length>0">
                                <div
                                    class="flex align-items-center justify-content-center m-2"
                                >
                                    <button
                                        pButton
                                        type="button"
                                        label="Save"
                                        class="p-button-secondary"
                                        (click)="saveAllCountConfirmationByManager(tillObj.cashCounts)"
                                    ></button>
                                </div>
                            </div>
                            </td>
                            </tr>

                        </ng-template>
                    </p-table>
                </ng-container>
            </p-accordionTab>
            <p-accordionTab [selected]="tillObj.payoutSelected">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center p-jc-between px-3">
                    Pay-out
                    </div>
                    <div class="flex align-items-center p-jc-between px-6">

                        <button
                            pButton
                            type="button"
                            label="Add Pay-out"
                            class="ml-2"
                            icon="pi pi-dollar"
                            iconPos="left"
                            *ngIf="allowAddingPayout"
                            style="width: 170px"
                            (click)="payOutHandler(tillObj)"
                        ></button>
                        </div>
                </ng-template>
                <ng-container *ngIf="step == 2">

                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="tillObj.payouts"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center">Register</th>
                                <th class="text-center">Payout By</th>
                                <th class="text-center">Date & Time</th>
                                <th class="text-center">Invoice #</th>
                                <th class="text-center">Payout Amount</th>
                                <th class="text-center">Payout Reason</th>
                                <th class="text-center">Manger Count</th>
                                <th class="text-center">
                                    <!-- <p-inputSwitch *ngIf="tillObj.payouts.length>0" [(ngModel)]="tillObj.checkAllPayout" (onChange)="verifyAllPayoutByManager($event,tillObj)"></p-inputSwitch>Verified -->
                                    <button
                                    pButton
                                    *ngIf="tillObj.payouts.length>0" 
                                    (click)="verifyAllPayoutByManager(tillObj.payouts)"
                                    type="button"
                                    label="Verified"
                                    class="p-button-info"
                                ></button>
                                </th>
                                <th class="text-center">Manager Reason </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class="text-center">{{ item.registerName }}</td>
                                <td class="text-center">{{ item.payoutBy }}</td>
                                <td class="text-center">{{ item.dateTime | date : "short" }}</td>
                                <td class="text-center">{{item.invoiceNumber}}</td>
                                <td class="text-center">{{ item.payoutAmount| currency  }}</td>
                                <td class="text-center">{{ item.payoutReason }}</td>
                                <td class="text-center">
                                    <!-- <p-inputNumber
                                    [(ngModel)]="item.managerCount"
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    inputId="minmax-buttons"
                                    (onBlur)="verifyAmountByManager($event, item)"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly
                                    "
                                >
                                </p-inputNumber> -->
                                <p-inputNumber
                                [(ngModel)]="item.managerCount"
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                inputId="minmax-buttons"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly
                                "
                            >
                            </p-inputNumber>
                                  </td>
                                <td class="text-center">
                                    <!-- <p-inputSwitch (onChange)="verifyCountByManager($event,item,tillObj)" [(ngModel)]="item.isVerified"></p-inputSwitch> </td> -->
                                    <p-inputSwitch [(ngModel)]="item.isVerified"></p-inputSwitch></td>
                                <td class="text-center">
                                    <i *ngIf="item.managerReason!=''" class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem; color: var(--brandColor);"></i>
                                    <i *ngIf="item.managerReason==''" class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem;"></i>

                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="7" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" style="text-align: center">
                            <tr>
                                <td colspan="9">
                            <div class="flex justify-content-end flex-wrap" *ngIf="tillObj.payouts.length>0">
                                <div
                                    class="flex align-items-center justify-content-center m-2"
                                >
                                    <button
                                        pButton
                                        type="button"
                                        label="Save"
                                        class="p-button-secondary"
                                        (click)="saveAllPayoutConfirmationByManager(tillObj.payouts)"
                                    ></button>
                                </div>
                            </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </p-accordionTab>
            <p-accordionTab [selected]="tillObj.payinSelected">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center p-jc-between px-3">
                    Pay-In
                    </div>
                    <div class="flex align-items-center p-jc-between pl-7 pr-6">

                        <button
                            pButton
                            type="button"
                            label="Add Pay-In"
                            class="ml-2"
                            icon="pi pi-dollar"
                            iconPos="left"
                            *ngIf="allowAddingPayin"
                            style="width: 170px"
                            (click)="payInHandler(tillObj)"
                        ></button>
                        </div>
                </ng-template>
                <ng-container *ngIf="step == 2">

                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="tillObj.payins"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center">Register</th>
                                <th class="text-center">Pay-in By</th>
                                <th class="text-center">Date & Time</th>
                                <th class="text-center">Invoice #</th>
                                <th class="text-center">Pay-in Amount</th>
                                <th class="text-center">Pay-In Reason</th>
                                <th class="text-center">Manger Count</th>
                                <th class="text-center">
                                    <!-- <p-inputSwitch *ngIf="tillObj.payins.length>0"  [(ngModel)]="tillObj.checkAllPayin" (onChange)="verifyAllPayinByManager($event,tillObj)"></p-inputSwitch>Verified -->
                                    <button
                                    pButton
                                    *ngIf="tillObj.payins.length>0"  
                                    (click)="verifyAllPayinByManager(tillObj.payins)"
                                    type="button"
                                    label="Verified"
                                    class="p-button-info"
                                ></button>
                                </th>
                                <th class="text-center">Manager Reason </th>
                                <!-- <th>Notes</th> -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class="text-center">{{ item.registerName }}</td>
                                <td class="text-center">{{ item.payinBy }}</td>
                                <td class="text-center">{{ item.dateTime | date : "short" }}</td>
                                <td class="text-center">{{item.invoiceNumber}}</td>
                                <td class="text-center">{{ item.payinAmount | currency  }}</td>
                                <td class="text-center">{{ item.payinReason }}</td>
                                <td class="text-center">
                                    <!-- <p-inputNumber
                                    [(ngModel)]="item.managerCount"
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    inputId="minmax-buttons"
                                    (onBlur)="verifyAmountByManager($event, item)"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly
                                    "
                                >
                                </p-inputNumber> -->
                                <p-inputNumber
                                [(ngModel)]="item.managerCount"
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                inputId="minmax-buttons"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly
                                "
                            >
                            </p-inputNumber>
                                  </td>
                                <td class="text-center">
                                    <!-- <p-inputSwitch (onChange)="verifyCountByManager($event,item, tillObj)" [(ngModel)]="item.isVerified"></p-inputSwitch> </td> -->
                                    <p-inputSwitch [(ngModel)]="item.isVerified"></p-inputSwitch> </td>
                                <!-- <td>{{ item.managerReason }}</td> -->
                                <td class="text-center">
                                    <i *ngIf="item.managerReason!=''" class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem; color: var(--brandColor);"></i>
                                    <i *ngIf="item.managerReason==''" class="pi pi-book cursor-pointer" (click)="addManagerReason(item)" style="font-size: 1.2rem;"></i>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="17" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" style="text-align: center">
                            <tr>
                                <td colspan="9">
                            <div class="flex justify-content-end flex-wrap" *ngIf="tillObj.payins.length>0">
                                <div
                                    class="flex align-items-center justify-content-center m-2"
                                >
                                    <button
                                        pButton
                                        type="button"
                                        label="Save"
                                        class="p-button-secondary"
                                        (click)="saveAllPayinConfirmationByManager(tillObj.payins)"
                                    ></button>
                                </div>
                            </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </p-accordionTab>
        </p-accordion>
        </p-accordionTab>

    </p-accordion>

    </div>

    <ng-container *ngIf="step == 1">
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="eodList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            selectionMode="single"
            [(selection)]="selectedRow1"
            (onRowSelect)="onRowSelectStep1($event)"
            (onRowUnselect)="onRowUnselectStep1($event)"
        >
            <ng-template pTemplate="header">
                <tr [pSelectableRow]="item">
                    <th>Date</th>
                    <!-- <th class="text-right" pTooltip="All “Add Tills” that occurred that day"> Starting Cash <i class="pi pi-window-maximize"  tooltipPosition="top">
                    </i></th> -->
                    <th class="text-center"> Location Starting Cash
                    </th>
                    <th class="text-center">System Total</th>
                    <th class="text-center">Expected Cash</th>
                    <th class="text-center">Counted at Registers</th>
                    <th class="text-center">Manager Count</th>
                    <th class="text-center">Status</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item">
                    <td>{{ item.dated | date : "mediumDate" }}</td>
                    <td class="text-center">
                        {{ item.startingCash | currency }}
                    </td>
                    <td class="text-center">
                        {{ item.systemTotal | currency }}
                    </td>
                    <td class="text-center">
                        {{ item.expectedCash | currency }}
                    </td>
                    <td class="text-center">
                        {{ item.registersCount | currency }}
                    </td>
                    <td class="text-center" *ngIf="item.allowReconcilation">
                        <button
                            pButton
                            type="button"
                            label="Reconcile"
                            (click)="reconcileClicked(item)"
                        ></button>
                    </td>
                    <td class="text-center" *ngIf="item.allowReconcilation">
                        Un-Reconciled
                    </td>
                    <td class="text-center" *ngIf="!item.allowReconcilation">
                        {{ item.managerCount | currency }}
                    </td>
                    <td class="text-center">{{ getStatusText(item) }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="7" style="text-align: center">
                        No data found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <ng-container *ngIf="step == 2">
        <div class="flex justify-content-between flex-wrap my-4">
            <div class="flex align-items-center justify-content-center m-2">
                <button
                    pButton
                    type="button"
                    label="Back"
                    class="p-button-help"
                    icon="pi pi-angle-left"
                    iconPos="left"
                    style="width: 150px"
                    (click)="backButtonClicked(2)"
                ></button>
            </div>

            <div
                class="flex align-items-center justify-content-center m-2"
                *ngIf="isViewOnly || selectedRow1.isManagerCount"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Next"
                            style="width: 150px"
                            (click)="confirmCount('false')"
                        ></button>
                    </div>
                </div>
            </div>

            <div
                class="flex align-items-center justify-content-center m-2 "
                *ngIf="!isViewOnly && !selectedRow1.isManagerCount"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Proceed"
                            class="ml-2"
                            style="width: 150px"
                            (click)="confirmCount('false')"
                            [disabled]="isViewOnly || drawerCountList.length == 0"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step == 3">
        <div class="row flex">
            <div class="col-6">
                <div
                class="justify-content-center flex-wrap card-container yellow-container my-4"
            >
                <p-table
                    #dt1
                    [columns]=""
                    styleClass="p-datatable-striped"
                    id="table-002"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }"
                    [value]="mockData"
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">Bills</th>
                            <th class="text-center">Quantity</th>
                            <th class="text-center">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td class="text-center">{{ item.bill }}</td>
                            <td  class="text-center">
                                <p-inputNumber
                                    [(ngModel)]="item.qty"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="itemQtyChanged($event, item)"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly &&
                                        !managerCountObj?.isConfirmed
                                    "
                                >
                                </p-inputNumber>
                            </td>
                            <td class="text-center">
                                {{ item.total | currency }}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="footer">
                        <tr class="summary-line">
                            <td
                                colspan="2"
                                style="
                                    text-align: right;
                                    background-color: var(--brandColor);
                                    color: white;
                            "
                                class="font-bold text-lg select-none"
                            >
                                Total
                            </td>
                            <td
                                style="
                                    text-align: center;
                                    background-color: var(--brandColor);
                                    color: white;
                                "
                                class="font-bold text-lg select-none"
                            >
                                {{ getTotalQtyDC() | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </div>
           <div class="col-6">
            <div
            class="justify-content-center flex-wrap card-container yellow-container my-4"
        >
            <p-table
                #dt1
                [columns]=""
                styleClass="p-datatable-striped"
                id="table-002"
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataNew"
                responsiveLayout="scroll"
                showLoader="true"
                rowHover="true"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Bills</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Total</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td  class="text-center">
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangednew($event, item)"
                                [inputStyle]="{
                                    width: '110px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly &&
                                    !managerCountObj?.isConfirmed
                                "
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                    <tr class="summary-line">
                        <td
                            colspan="2"
                            style="
                                text-align: right;
                                background-color: var(--brandColor);
                                color: white;
                        "
                            class="font-bold text-lg select-none"
                        >
                            Total
                        </td>
                        <td
                            style="
                                text-align: center;
                                background-color: var(--brandColor);
                                color: white;
                            "
                            class="font-bold text-lg select-none"
                        >
                            {{ getTotalQtyDCnewCurency() | currency }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </div>
            <div class="grid">
                <div class="xl:col-12 lg:col-12 sm:col-12 md:col-12">
                    <div class="conn-card">
                        <div class="grid">
                            <div class="col-6 mt-3 font-bold text-lg text-500">
                                Total Amount($)
                            </div>
                            <div class="col">
                                <!-- <p-inputNumber
                                    [disabled]="true"
                                    [(ngModel)]="gtax"
                                    mode="decimal"
                                    placeholder="0.00"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    [inputStyle]="{
                                        width: '100%',
                                        'text-align': 'right'
                                    }"
                                ></p-inputNumber> -->
                                {{ (getTotalQtyDCnewCurency() + getTotalQtyDC()) | currency }}
                            </div>
                        </div>

                        <div class="">
                            <div class="mt-3 font-bold text-lg text-500">
                                Notes:
                            </div>
                            <div class="col11">
                                <textarea class="manualtextareStyle" [(ngModel)]="managerConfirmreason" rows="5" cols="30" pInputTextarea [disabled]="
                                isViewOnly ||
                                managerCountObj?.isConfirmed
                            " ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           </div>

        </div>
        <br />
        <br />
        <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center m-2">
                <button
                    pButton
                    type="button"
                    label="Back"
                    class="p-button-help"
                    icon="pi pi-angle-left"
                    iconPos="left"
                    style="width: 100px"
                    (click)="backButtonClicked(3)"
                ></button>
            </div>
            <div
                class="flex align-items-center justify-content-center m-2"
                *ngIf="!isViewOnly && !managerCountObj?.isConfirmed"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Save Deposit Count"
                            class="p-button-secondary"
                            style="width: 160px"
                            (click)="saveManagerCount(false)"
                        ></button>
                        <button
                            pButton
                            type="button"
                            label="Confirm & Deposit"
                            class="ml-2"
                            style="width: 160px"
                            (click)="saveManagerCount(true)"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<br />
<div class="conn-card" *ngIf="step == 1 && eodList?.length">
    <p-divider class="mt-1" align="left" type="solid">
        <i class="pi pi-table mr-2"></i>
        <b class="font-bold text-600">&nbsp;F O R M U L A S</b>
    </p-divider>

    <div class="grid">
        <div [class]="col1" style="width: 130px">Location Starting Cash</div>
        <div [class]="col2">Till + Cash until previous day</div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">System Total</div>
        <div [class]="col2">
            (Sales + Payin + Till - Refund - Payout - Cash Drop) onward from
            last reset
        </div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Expected Cash</div>
        <div [class]="col2">(Sales + Payin - Refund - Payout) current day</div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Register Count</div>
        <div [class]="col2">Last Cash Count Amount on register</div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Manager Count</div>
        <div [class]="col2">Manager Counted Amount</div>
    </div>
</div>

<!-- [style]="{ width: isTabletFn() }" -->
<p-dialog
    header="Add Manager Reason"
    [(visible)]="showManagerReason"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>


    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div class="row" style="margin-top: 10px; ">
            <div class="col-12">
                    <textarea class="manualtextareStyle" [(ngModel)]="saveReasonObj.managerReason" [ngModelOptions]="
                    {standalone: true}" rows="5" cols="30" pInputTextarea [disabled]="
                    isViewOnly &&
                    !managerCountObj?.isConfirmed
                "></textarea>
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Save"
                class="ml-2"
                style="width: 130px"
                [disabled]="
                    isViewOnly &&
                    !managerCountObj?.isConfirmed
                "
                (click)="saveReconcilationReasons()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add Cash Count Reason"
    [(visible)]="showCashCountPopup"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>


    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div class="row" style="margin-top: 10px; ">
            <div class="col-12">
                    <textarea class="manualtextareStyle" [(ngModel)]="saveReasonObj.managerReason" [ngModelOptions]="
                    {standalone: true}" rows="5" cols="30" pInputTextarea [disabled]="
                    isViewOnly &&
                    !managerCountObj?.isConfirmed
                "></textarea>
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Save"
                class="ml-2"
                style="width: 130px"
                [disabled]="
                    isViewOnly &&
                    !managerCountObj?.isConfirmed
                "
                (click)="saveCashCountReasons()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add a Note"
    [(visible)]="showManagerTillNote"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>


    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div class="row" style="margin-top: 10px; ">
            <div class="col-12">
                    <textarea class="manualtextareStyle" [(ngModel)]="saveTillNotesObj.tillNotes" [ngModelOptions]="
                    {standalone: true}" rows="5" cols="30" pInputTextarea [disabled]="
                    isViewOnly &&
                    !managerCountObj?.isConfirmed
                " ></textarea>
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Save"
                class="ml-2"
                style="width: 130px"
                [disabled]="
                isViewOnly &&
                !managerCountObj?.isConfirmed
            "
                (click)="saveTillNotes()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Cash Drop Denomination Detail"
    [(visible)]="showDropsDenominationAmount"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-6 text-lg text-700 font-semibold">Location</div>
            <!-- <div class="col-4 text-lg text-700 font-semibold">Register</div> -->
            <div class="col-6 text-lg text-700 font-semibold">Till</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                ></p-dropdown>
            </div>
            <!-- <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                ></p-dropdown>
            </div> -->
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="drawerCountList"
                [(ngModel)]="selectedTill"
                optionLabel="tillNo"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
            </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    true"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add Manager Till Count"
    [(visible)]="showMangerTillConfirmAmount"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-6 text-lg text-700 font-semibold">Location</div>
            <!-- <div class="col-4 text-lg text-700 font-semibold">Register</div> -->
            <div class="col-6 text-lg text-700 font-semibold">Till</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
            <!-- <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                ></p-dropdown>
            </div> -->
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="drawerCountList"
                [(ngModel)]="selectedTill"
                optionLabel="tillNo"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
            </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                isViewOnly"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

     <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Manager Count"
                class="ml-2"
                style="width: 160px"
                (click)="postManagerTillConfirmCount()"
                [disabled]="
                isViewOnly"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Cash Count Denomination Amount"
    [(visible)]="showCountsDenominationAmount"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-6 text-lg text-700 font-semibold">Location</div>
            <div class="col-6 text-lg text-700 font-semibold">Till</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                ></p-dropdown>
            </div>
            <!-- <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                ></p-dropdown>
            </div> -->
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="drawerCountList"
                [(ngModel)]="selectedTill"
                optionLabel="tillNo"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
            </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    true"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

    <!-- <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Cash Count"
                class="ml-2"
                style="width: 130px"
                (click)="postCashDeposit()"
            ></button>
        </div>
    </div> -->
</p-dialog>
<p-dialog
    header="Add Cash Count"
    [(visible)]="showCashDeposit"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-4 text-lg text-700 font-semibold">Location</div>
            <div class="col-4 text-lg text-700 font-semibold">Register</div>
            <div class="col-4 text-lg text-700 font-semibold">Till</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
            <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
            <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="drawerCountList"
                [(ngModel)]="selectedTill"
                optionLabel="tillNo"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
            </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Cash Count"
                class="ml-2"
                style="width: 130px"
                (click)="postCashDeposit()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add Pay-out"
    [(visible)]="showPayout"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '50vw' }"
>
<div class="border-round shadow-2 border-1 border-400 bg-white">
    <div class="grid m-2">
        <div class="col-3 text-lg text-700 font-semibold">Location</div>
        <div class="col-3 text-lg text-700 font-semibold">Register</div>
        <div class="col-3 text-lg text-700 font-semibold">Till</div>
        <div class="col-3 text-lg text-700 font-semibold">Time</div>
    </div>
    <div class="grid ml-2 mr-2">
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="_abd.locations"
                [(ngModel)]="cdSelectedLocation"
                (onChange)="cdLocationChange($event)"
                optionLabel="locationName"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
        </div>
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
            [style]="{
                height: '40px',
                width: '100%'
            }"
            class="p-dropdown-1"
            [options]="registers"
            [(ngModel)]="cdSelectedRegister"
            optionLabel="registerName"
            appendTo="body"
            [disabled]="true"
            [dropdownIcon]="'none'"
        ></p-dropdown>
        </div>
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
            [style]="{
                height: '40px',
                width: '100%'
            }"
            class="p-dropdown-1"
            [options]="drawerCountList"
            [(ngModel)]="selectedTill"
            optionLabel="tillNo"
            appendTo="body"
            [disabled]="true"
            [dropdownIcon]="'none'"
        ></p-dropdown>
        </div>
         <div class="col-3 text-lg text-700 font-semibold">
            <p-calendar
                [(ngModel)]="payoutTime"
                [showSeconds]="true"
                timeOnly="true"
                showTime="true"
                (onBlur)="onPayOutSelect($event)"
                [required]="true"

                hourFormat="12"
                 ></p-calendar>
         </div>
    </div>
</div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">

        <div class="row" style="margin-top: 40px;">
             <div class="col-12">
                <label class="control-label" style="margin-right: 10px"
                ><strong
                    >Reason
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
                 <p-dropdown [style]="{
                height: '40px',
                width: '100%'
            }"
            class="p-dropdown-1" [options]="reasondatalist" [(ngModel)]="selectedReason" (onChange) ="updateList()"  optionLabel="reason" optionValue="id"></p-dropdown>
            </div>
        </div>
    <div class="row" style="margin-top: 10px; ">
        <div class="col-12">
                <textarea class="manualtextareStyle" [(ngModel)]="selectreasonTextArea" rows="5" cols="30" [ngStyle]="{ 'width': '100%;'}" pInputTextarea [disabled]="
                isViewOnly &&
                !managerCountObj?.isConfirmed
            " ></textarea>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-12">
                    <label class="flex control-label" style="margin-right: 10px"
            ><strong
                >Amount
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label>
            <p-inputNumber
            mode="decimal"
            inputId="minmax-buttons"
            [min]="0"
            placeholder="0"
            [(ngModel)]="payoutInputAmount"
            [inputStyle]="{
                width: '100%',
                'text-align': 'left',
                height: '50px'
            }"
             locale="en-US" [minFractionDigits]="2" class="manualtextareStyle"></p-inputNumber>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-12">
                    <label class="flex control-label" style="margin-right: 10px"
            ><strong
                >Invoice Number
                <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
            </strong></label>
            <!-- <p-inputNumber
            mode="decimal"
            inputId="minmax-buttons"
            [min]="0"
            placeholder="0"
            [(ngModel)]="payoutInvoiceNumber"
            [inputStyle]="{
                width: '100%',
                'text-align': 'left',
                height: '50px'
            }"

             locale="en-US" [minFractionDigits]="2" class="manualtextareStyle"></p-inputNumber> -->
             <input type="text" pInputText [(ngModel)]="payoutInvoiceNumber" class="manualtextareStyle" placeholder="Enter Invoice #"/>
        </div>
    </div>

    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Pay-out"
                class="ml-2"
                style="width: 130px"
                (click)="postPayout()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add Pay-In"
    [(visible)]="showPayIn"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '50vw' }"
>
<div class="border-round shadow-2 border-1 border-400 bg-white">
    <div class="grid m-2">
        <div class="col-3 text-lg text-700 font-semibold">Location</div>
        <div class="col-3 text-lg text-700 font-semibold">Register</div>
        <div class="col-3 text-lg text-700 font-semibold">Till</div>
        <div class="col-3 text-lg text-700 font-semibold">Time</div>
    </div>
    <div class="grid ml-2 mr-2">
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="_abd.locations"
                [(ngModel)]="cdSelectedLocation"
                (onChange)="cdLocationChange($event)"
                optionLabel="locationName"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
        </div>
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
                [style]="{
                    height: '40px',
                    width: '100%'
                }"
                class="p-dropdown-1"
                [options]="registers"
                [(ngModel)]="cdSelectedRegister"
                optionLabel="registerName"
                appendTo="body"
                [disabled]="true"
                [dropdownIcon]="'none'"
            ></p-dropdown>
        </div>
        <div class="col-3 text-lg text-700 font-semibold">
            <p-dropdown
            [style]="{
                height: '40px',
                width: '100%'
            }"
            class="p-dropdown-1"
            [options]="drawerCountList"
            [(ngModel)]="selectedTill"
            optionLabel="tillNo"
            appendTo="body"
            [disabled]="true"
            [dropdownIcon]="'none'"
        ></p-dropdown>
        </div>
         <div class="col-3 text-lg text-700 font-semibold">
            <p-calendar
                [(ngModel)]="payInTime"
                [showSeconds]="true"
                timeOnly="true"
                showTime="true"
                (onBlur)="onPayInSelect($event)"
                [required]="true"

                hourFormat="12"
                 ></p-calendar>
         </div>
    </div>
</div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">

        <div class="row" style="margin-top: 40px;">
             <div class="col-12">
                <label class="control-label" style="margin-right: 10px"
                ><strong
                    >Reason
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
                 <p-dropdown [style]="{
                height: '40px',
                width: '100%'
            }"
            class="p-dropdown-1" [options]="reasondatalist" [(ngModel)]="selectedReason" (onChange) ="updateList()"  optionLabel="reason" optionValue="id"></p-dropdown>
            </div>
        </div>
    <div class="row" style="margin-top: 10px; ">
        <div class="col-12">
                <textarea class="manualtextareStyle" [(ngModel)]="selectreasonTextArea" rows="5" cols="30" pInputTextarea [disabled]="
                isViewOnly &&
                !managerCountObj?.isConfirmed
            "></textarea>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-12">
                    <label class="flex control-label" style="margin-right: 10px"
            ><strong
                >Amount
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label>
            <p-inputNumber
            mode="decimal"
            inputId="minmax-buttons"
            [min]="0"
            [(ngModel)]="payInInputAmount"
            placeholder="0"
            [inputStyle]="{
                width: '100%',
                'text-align': 'left',
                height: '50px'
            }"
             locale="en-US" [minFractionDigits]="2" class="manualtextareStyle"></p-inputNumber>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-12">
                    <label class="flex control-label" style="margin-right: 10px"
            ><strong
                >Invoice Number
                <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
            </strong></label>
            <!-- <p-inputNumber
            mode="decimal"
            inputId="minmax-buttons"
            [(ngModel)]="payInInvoiceNumber"
            [min]="0"
            placeholder="0"
            [inputStyle]="{
                width: '100%',
                'text-align': 'left',
                height: '50px'
            }"

             locale="en-US" [minFractionDigits]="2" class="manualtextareStyle"></p-inputNumber> -->
             <input type="text" pInputText [(ngModel)]="payInInvoiceNumber" class="manualtextareStyle" placeholder="Enter Invoice #"/>
        </div>
    </div>

    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Pay-In"
                class="ml-2"
                style="width: 130px"
                (click)="postPayIn()"
            ></button>
        </div>
    </div>
</p-dialog>
<p-dialog
    header="Add Cash Drops"
    [(visible)]="showCashDrop"
    [blockScroll]="true"
    [draggable]="false"
    modal="true"

    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [style]="{ width: '50vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-3 text-lg text-700 font-semibold">Location</div>
            <div class="col-3 text-lg text-700 font-semibold">Register</div>
            <div class="col-3 text-lg text-700 font-semibold">Till</div>
            <div class="col-3 text-lg text-700 font-semibold">Time</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-3 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
            <div class="col-3 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
            <div class="col-3 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="drawerCountList"
                    [(ngModel)]="selectedTill"
                    optionLabel="tillNo"
                    appendTo="body"
                    [disabled]="true"
                    [dropdownIcon]="'none'"
                ></p-dropdown>
            </div>
             <div class="col-3 text-lg text-700 font-semibold">
                <p-calendar
                    [(ngModel)]="dropCashTime"
                    [showSeconds]="true"
                    timeOnly="true"
                    showTime="true"
                    (onBlur)="onSelect($event)"
                    [required]="true"

                    hourFormat="12"
                     ></p-calendar>
             </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Cash Drops"
                class="ml-2"
                style="width: 130px"
                (click)="postCashDrops()"
            ></button>
        </div>
    </div>
</p-dialog>

<p-dialog header="{{popupHeaderInfo}}"
    [modal]="visibleModal"
    [closable]="true"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '75vw' }"
    [(visible)]="visibleModal"
    [blockScroll]="true"
    [draggable]="false"
    modal="true"
   >

    <div class="flex align-items-start justify-content-center mt-4 mb-4">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 1">
            <app-till-report [filterLocation] = "_filterService.selectedLocation.locationId" [filterDate] = "_filterService.singleDateForReconcile" [showFilter]="false" *ngIf="visibleModal" ></app-till-report>

        </div>
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 4">
            <div
                class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3"
            >

                <div
                    class="flex align-items-center justify-content-center font-bold text-xl mb-4"
                >Pay-out
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="payoutReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="payoutReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right" class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ payoutReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3">
                <div
                    class="flex align-items-center justify-content-center font-bold text-xl m-4"
                >Pay-In
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="payInReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="payInReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right"
                                class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ payInReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3">
                <div
                    class="flex align-items-center justify-content-center font-bold text-xl m-4"
                >Refunds
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="refundReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="refundReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right"
                                class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ refundReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="flex justify-content-center align-content-center col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 5">
            <div
                class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3"
            >

                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }" [value]="expectedCashInfoList" responsiveLayout="scroll"
                showLoader="true" rowHover="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Head</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr class="{{item.class}}">
                        <td>{{ item.title }}</td>
                        <td >{{ item.value | currency }}</td>
                        <td>
                            <i *ngIf="item.allowPopup" (click)="showOtherPopup(info)" class="pi pi-eye"></i>

                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" style="text-align: center" >
                    <tr>
                        <td colspan="3" style="text-align: center">
                            No data found
                        </td>
                    </tr>
                </ng-template>

            </p-table>
            </div>
        </div>
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 7">
            <div
                class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3"
            >

                <div
                    class="flex align-items-center justify-content-center font-bold text-xl mb-4"
                ><textarea class="manualtextareStyle" [(ngModel)]="managerBankConfirmReason" rows="5" cols="30" pInputTextarea [disabled]="true
            " ></textarea>
                </div>
            </div>
        </div>
    </div>


</p-dialog>

<!--
    //  Starting Cash   Current Day Till + Cash until previous day
    //  System Total    (Sales + Payin + Till - Refund - Payout - Cash Drop) onward from last reset
    //  Expected Cash   (Sales + Payin - Refund - Payout) current day
    //  Register Count  Last Cash Count Amount on register
    //  Manager Count   Manager Counted Amount
-->

<style>
    :host ::ng-deep.p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #eff2f5;
        color: rgba(0, 0, 0, 0.87);
        padding: 1.5rem;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    :host ::ng-deep .p-dialog .p-dialog-content {
        background: #eff2f5;
        color: rgba(0, 0, 0, 0.87);
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    :host ::ng-host .p-dialog {
        border-radius: 4px;
        box-shadow: 0 -1px 5px 5px rgb(0 0 0 / 5%),
            0 5px 5px 5px rgb(0 0 0 / 5%), 0 5px 5px 5px rgb(0 0 0 / 5%);
    }

    :host ::ng-deep .p-dialog .p-dialog-header .p-dialog-title {
        font-weight: 900;
        font-size: 1.75rem;
        color: var(--brandColor);
    }
</style>
