import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

@Component({
  selector: 'app-zipcode-city-state-three-col',
  templateUrl: './zipcode-city-state-three-col.component.html',
  styleUrls: ['./zipcode-city-state-three-col.component.scss']
})
export class ZipcodeCityStateThreeColComponent implements OnInit {
  usaStates = UsStates
  showCityWait = false
  state: any

  editform: FormGroup

  @Input() enable: boolean = true

  constructor(
    private _fb: FormBuilder,
    public _abd: AppBaseDataService,
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.editform = this._fb.group({
      postalCode: [{ value: this._abd.zipcodeCityStateObj?.zipcode, disabled: this.enable }, Validators.required],
      city: [{ value: this._abd.zipcodeCityStateObj?.city, disabled: this.enable }, Validators.required],
    })
  }

  zipInputEvent(value: string) {
    if(!value) return
    value = value.trim()
    if (!value || value.length !== 5) return
    this.editform.patchValue({ postalCode: value })
    this._abd.zipcodeCityStateObj.zipcode = value
    this.showCityWait = true
    this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
      this.showCityWait = false
      if (res.success && res.data.city != undefined) {
        this._abd.zipcodeCityStateObj.city = res.data.city + '';
        const st = this.usaStates.find(s => s.abbreviation == res.data.state)
        this._abd.zipcodeCityStateObj.stateObj = st;
        this.editform.patchValue({ city: res.data.city })
        // //console.log('city-data', this._vendorService.activeItem)
      } else {
        this._abd.zipcodeCityStateObj.city = "Invalid zip code"
        this.editform.patchValue({ city: "Invalid zip code" })
        this._abd.zipcodeCityStateObj.stateObj=null
      }
    })
  }

  cityInputEvent(value: string) {
    if(!value) return
    value = value.trim()
    this.editform.patchValue({ city: value })
    if(!value) return
    this._abd.zipcodeCityStateObj.city = value
  }

}
