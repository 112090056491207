import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-transfer-receive-list',
  templateUrl: './transfer-receive-list.component.html',
  styleUrls: ['./transfer-receive-list.component.scss']
})
export class TransferReceiveComponent implements OnInit {
  page = 0
  rows = 10
  first=0
  totalRecords = 0

  lsearchText = ''
  csvcols = []

  dataList: any = ''
  selectedRow: any = ''

  locations: any = []
  selectedLocation: any = ''

  actions: MenuItem[] = []

  _globals = GlobalService
  @ViewChild('opActions') opActions: OverlayPanel
  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    private _abd: AppBaseDataService,
    private _productService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _messageService: MessageService,
    private _appMain: AppMainComponent,
    private _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    // this._appMain.toggleMenuDummy()

    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._globals.componentName = 'Inbound Transfer '
    this.locations = this._abd.initActLocations()
    if (!this.locations || this.locations.length < 1)
      this._abd.showErrorAndQuit('No locations found')

    if (!this._filterService.selectedLocation) this._filterService.selectedLocation = this.locations[0]
    this.getDataList()
  }

  ngAfterViewInit() {
    //-- restore page number **begin
    const obj = this._abd.getOptionsFromLocalStorage(this._globals.componentName, 'last-page')
    if (!obj && obj !== 0) return

    let lastpage = parseInt(obj)
    if (lastpage < 0) lastpage = 0
    if (lastpage > 0) {
      this.page = lastpage
      this.first = lastpage * this.rows
      this.getDataList()
    }
    else {
      this.page = 0
      this.first = 0
      this.getDataList()
    }
    //-- restore page number **end
  }

  ngOnDestroy() {
    this._globals.componentName = ''
    let x = window.innerWidth
  }

  getDataList() {
    this._productService.isReject = false
    this._spinner.show()
    this._productService.getTransfersList(
      this._filterService.selectedLocation ? this._filterService.selectedLocation.locationId : '',
      this._filterService.selectedExtra1Location ? this._filterService.selectedExtra1Location.locationId : '',
      formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
      0,
      3, // transfersStatusCheck 1=all, 2=out, 3=in
      this.page,
      this.rows,
      this._filterService.searchText,true
    ).subscribe(res => {
      this._spinner.hide()
      if (res.success) {
        this.totalRecords = res.totalRecords
        this.dataList = res.data

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
        }
        // console.log('transfer-in-data-list', this.dataList)
      }
    })
  }

  callbackFuncFromFilter(event) {
    if (this._filterService.searchText == '') {
      // this._abd.resetFilters()
      this.getDataList()
      this.lsearchText = this._filterService.searchText
      return
    }
    if (this.lsearchText == this._filterService.searchText) return
    this.lsearchText = this._filterService.searchText
    this.getDataList()
    // this._productService.searchSingleTransferOrder(this._abd.searchText, 2).subscribe(res => {
    //   if (res.success) {
    //     this.dataList = []
    //     this.dataList.push(res.data)
    //     this.page = 0
    //     this.rows = 10
    //   }
    // })
  }

  filterButtonPressed() {
    this.page = 0
    this.getDataList()
  }

  onRowSelect(e) {
    this._productService.activeItem = e.data
    this._productService.isViewOnly = true
    this._productService.isNew = false
    this._productService.isEdit = false
    this._router.navigate(['app/transfers-receive-form'])
  }

  onRowUnselect(e) { }

  onEditClick(item) {
    if (item.transfersStatus == 5) {
      this._messageService.add({
        severity: 'info',
        summary: 'Error', detail: 'Completed Transfer invoice cannot be edited!'
      })
    }
    this._productService.activeItem = item
    this._productService.isViewOnly = false
    this._productService.isNew = false
    this._productService.isEdit = true
    // console.log("transfer-out-ACTIVE-ITEM", this._productService.activeItem)
    this._router.navigate(['app/transfers-receive-form'])
  }

  exportExcel() { }

  exportPdf() { }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  locationChanged(e) {
    this.getDataList()
  }

  actionButtonClick(item) {
    let canEdit = false
    let canCancel = false

    if (item.transfersInStatus !== 1) {
      canEdit = true
      canCancel = true
    }

    this._productService.activeItem = JSON.parse(JSON.stringify(item))
    // this.actions = [
    //   // { id: '1', label: 'New', escape: false, command: (event) => { }, disabled: true },
    //   { id: '2', label: 'Edit', escape: false, command: (event) => { this.optionEditClicked() }, disabled: canEdit },
    //   { id: '3', label: 'Cancel', command: (event) => { }, disabled: canCancel },
    // ]
  }

  optionEditClicked(option = '') {
    const item = this._productService.activeItem
    const opt = this._productService.activeItem.transfersInStatus
    if (opt == 1 || opt == 5 || opt == 2) { } else { return }

    if (option == 'reject' && opt == 1) this._productService.isReject = true
    else this._productService.isReject = false

    this._productService.isViewOnly = false
    this._productService.isNew = false
    this._productService.isEdit = true
    this.opActions.hide()
    // console.log("transfer-out-ACTIVE-ITEM", this._productService.activeItem)
    this._router.navigate(['app/transfers-receive-form'])
  }

  doEnableActionButton(item) {
    const showOn = [1, 2, 5]
    // try {
    if (showOn.find(f => f == item)) return false
    else return true
    // } catch { return true }
  }

  getActionButtonLabel() {
    const opt = this._productService.activeItem.transfersInStatus
    if (opt == 1 || opt == 5) return 'Receive'
    else if (opt == 2) return 'Post to Inventory'
    return 'View'
  }

  // _abd.transfersInStatus = [
  //   { id: 1, title: 'Pending' },
  //   { id: 2, title: 'Received' },
  //   { id: 3, title: 'Rejected' },
  //   { id: 4, title: 'Closed' },
  //   { id: 5, title: 'Draft' },
  // ]



}
