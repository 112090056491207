<div class="conn-card">
    <div class="conn-card">
        <app-report-top-filter-bar
            [paraReportName]="'sales-tax-report'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
        >
        </app-report-top-filter-bar>

        <p-table
            [value]="dateDataList"
            dataKey="name"
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [scrollable]="true"
            *ngIf="dateDataList?.length > 0"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th
                        style="text-align: center"
                        *ngFor="let col of domColHeader"
                    >
                        <strong>{{ col }}</strong>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded">
                <tr>
                    <td style="text-align: center">{{ product.groupValue }}</td>
                    <td style="text-align: center">
                        {{ product.locationName }}
                    </td>
                    <td style="text-align: center">
                        {{ product.taxRete | number }} %
                    </td>
                    <td style="text-align: center">
                        {{ product.taxableSales | currency }}
                    </td>
                    <td style="text-align: center">
                        {{ product.nonTaxableSales | currency }}
                    </td>
                    <td style="text-align: center">
                        {{ product.taxAmount | currency }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <p-divider style="width: 100%; margin-top:-15px; margin-bottom:-15px;"></p-divider>
                </tr>
                <tr *ngIf="dateDataList?.length > 0">
                    <td rowspan="2" colspan="2" style="text-align: center">
                        <strong class="text-700 text-lg">Total</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td
                        class="text-700 text-lg"
                        *ngFor="let col of domSummaryflds"
                        style="text-align: center"
                    >
                        {{ calculateSummaryTotal(col) }}
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="flex" *ngIf="dateDataList?.length == 0">
            <div class="flex justify-content-center" style="width: 100%">
                <span class="control-description text-muted text-align-center">
                    No data found!</span
                >
            </div>
        </div>
    </div>
</div>
