import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { PaginatorComponent } from 'src/app/_forms/paginator/paginator.component';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { PermissionCheckerService } from 'src/app/_services/permission-checker.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  searchText = ''
  lastSearchText = ''
  dataList: any = null

  selectedProduct2: any = ''

  colheader = [[
    'Vendors', 'Status'
  ]];

  colflds = [
    'vendorName', 'isActive'
  ];

  csvcols = []
  exportColumns: any = ''

  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  @ViewChild('paginator') paginator: PaginatorComponent

  @Input() showReportTopFilter = true
  @Input() businessSetup = false;
  // @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    public _userService: UserService,
    private _abd: AppBaseDataService,
    public _router: Router,
    private _filterService: FilterServiceService,
    public _businessSetupService: BusinessSetupService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    private _permissionsCheckerService:PermissionCheckerService,
  ) { }

  ngOnInit(): void {
    this._abd.searchText = ''
  }

  ngAfterViewInit() {
    this._globals.componentName = 'Users'
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
    this.businessSetup = false;
  }

  refrestDataList(e: any) {
    if (this.businessSetup) {
      this.getDataList();
    }
  }


  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const obj = {
      "Name": "GETALLEMPLOYEES",
      "User": {
        "ParentID": (this._globals.userInfo.parentID != '' ? this._globals.userInfo.userID : this._globals.userInfo.parentID),
        "CompanyName": this._globals.userInfo.businessSetup[0].businessName.toUpperCase()
      },
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText
    }

    setTimeout(() => {
      this._abd.showSpinner()
      try {
        this._userService.getAllUsers(obj).subscribe(res => {
          if (res.success) {
            // debugger;
            this.totalRecords = res.totalRecords
            this.dataList = res.data
            this.refreshPaginator(res.totalRecords, this.page, this.rows)
            this._abd.hideSpinner()
          }
        })
      } catch { this._abd.hideSpinner() }
    }, 500);

  }

  optionDeleteClicked() {
    this.op.hide()
      // console.log(this._userService.activeItem.status ? 'Active': 'Inactive')
    this._confirmationService.confirm({
      message: 'Are you sure to proceed',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._userService.deactivateUser(
            this._userService.activeItem.userID,
            !this._userService.activeItem.status,
            this.page,
            this.rows
          ).subscribe(res => {
            this._abd.hideSpinner()
            if (res.success) {
              this.page = 0
                this._userService.activeItem.status = !this._userService.activeItem.status
                this.getDataList()

              this._connMessageService.showToastMessage(`User "${this._userService.activeItem.firstName} ${this._userService.activeItem.lastName}" status changed!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    })
  }

  actionButtonClick(item) {
    this._userService.activeItem = item
    this._userService.showNewEdit = false
    this._userService.isViewOnly = false
    this._userService.isNew = false
  }

  optionEditClicked() {
    if (!this.showReportTopFilter) {
      this._userService.showNewEdit = true
      this._userService.isViewOnly = false
      this._userService.isNew = false
      return
    }
    this._userService.showNewEdit = true
    this._userService.isViewOnly = false
    this._userService.isNew = false
    this._router.navigate(['app/users-new-edit'])
  }

 async  onRowSelect(event) {
  let permission =  await this._permissionsCheckerService.isPermissionValid(['2008',]) 
  if(permission){
 if (event.data.userID == this._globals.userInfo.userID){
        return false;
      }
    this._userService.isNew = false
    this._userService.activeItem = event.data
    if (this._router.url.includes('setup/business-setup'))
      this._userService.isViewOnly = true
    else {
      this._userService.isViewOnly = true
      this._router.navigate(['app/users-new-edit'])
    }
  }
 
  }

  isEditButtonEnabled(item) {
    if (this._globals.userInfo.userID == item.userID) return false
    if (item.type === 'Client') return true
    return false
  }

  onRowUnselect(event) { }

  filterButtonPressed() {
    this.page = 0
    this.getDataList()
  }

  callbackFuncFromFilter(e) {
    this.page = 0
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this.getDataList()
  }

  //++++++++++++++++++++++++++++++

  refreshPaginator(totalRecords, page, rows) {
    try {
      this.paginator.setTotalRecords_n_Pages(totalRecords, page, rows)
    } catch { }
  }

  onPage(e) {
    this.page = e.page
    this.rows = e.rows
    if (!e.isdummy)
      this.getDataList()
    // alert('123-098-987   '+ e.page + '   ' + e.rows)
  }

}
