import { ProductsService } from 'src/app/_services/products.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  editform: FormGroup

  constructor(
    public _productServices: ProductsService,
    private _fb: FormBuilder,
    public _platform: Platform
    
  ) { this.initForm()}

  ngOnInit(): void {
     this.initForm()
    // detailform
  }

  ngAfterViewInit() {
  }

  initForm() {
    // alert(7667)
    this.editform = this._fb.group({
      productName: [{value: this._productServices.pts.t_productName, disabled: this._productServices.isViewOnly}, Validators.required],
      descriptionUrl:[{value: this._productServices.pts.descriptionUrl, disabled: this._productServices.isViewOnly}],
      //productDescription:['']
    })
  }

  isFormValid() {
    return this.editform.valid
  }




  
}
