import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BaseService } from 'src/app/_services/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-order-closed',
  templateUrl: './purchase-order-closed.component.html',
  styleUrls: ['./purchase-order-closed.component.scss']
})
export class PurchaseOrderClosedComponent implements OnInit {
  isDataLoaded = false
  locations: any = null
  vendors: any = null

  dataList: any = []
  histList: any = []
  activeItem: any = null

  invoiceNo = ''
  shipping = 0
  comments1 = ''
  selectedOptions: any

  subTotalSum = 0
  sumSubTotal = 0
  sumDiscount = 0
  sumTax = 0
  grandTotal = 0

  showReceiveInvoice = false
  receiveData: any

  divideShipping: any = null
  isDivide: boolean = false
  selectedOptions1: boolean = false
  selectedOptions2: boolean = false

  actionsHist: MenuItem[]
  histSelectedRow: any

  isReadOnly = false


  summary = {
    totalCostSum: 0,
    subTotal: 0,
    discountAmount: 0,
    taxAmount: 0,
    shipping: 0,
    grandTotal: 0,
    total: 0
  }

  _globals = GlobalService
  @ViewChild('histmenu') histMenu: OverlayPanel

  isFooterNoteEnabled : boolean = false;
  footerNotesText: string = '';
  constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    private _confirmationService: ConfirmationService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    private _bs: BaseService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.actionsHist = [
        {
          label: 'Post To Inventory',
          icon: 'pi pi-window-maximize',
          command: () => { this.postToInvoice(); }
        },
        {
          label: 'View Receive Invoice',
          icon: 'pi pi-fw pi-eye',
          command: () => { this.viewReceiveInventory(); }
        },
      ]
      this.locations = this._abd.initActLocations()
      this.vendors = this._abd.initActVendors()

      if (!this.locations || !this.vendors) {
        this._messageService.add({ severity: 'error', summary: '[S-12.85.68] Missing basic data!' })
        window.history.back()
      }

      if (!this.selectedOptions || this.selectedOptions.length < 1) {
        this.selectedOptions = ['valA']
      }

      if (this._purchaseService.newEditItem.POStatus == 'CLOSEDFORRECEIVING') {
        this.isReadOnly = true
        this._purchaseService.newEditItem.POStatus = 'Closed For Receiving'
      }
      this.initData()
      this.getFooterNotesConfigValue();
    }

    postToInvoice() {
        const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
        const url = `${environment.InventoryBaseURL}ReceivePOTxn/${hr.id}`

        this._bs.httpPut(url).subscribe(res => {
          if (res.success) {
            this._messageService.add({ severity: 'success', summary: 'Posted to Inventory operation was successful!' })
          }
        })
      }

      viewReceiveInventory() {
        this.receiveData = ''
        const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
        const url = `${environment.InventoryBaseURL}ReceivePO/${hr.poRecivingId}`
        this._bs.httpGet(url).subscribe(res => {
          if (res.success) {
            this.receiveData = res.data
            this.receiveData.createdDate = new Date(this.receiveData.createdDate)
            this.receiveData.receivedDate = new Date(this.receiveData.receivedDate)
            this.showReceiveInvoice = true
          }
        })
      }

  initData() {
    // this._spinner.show()
    this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id, true).subscribe(res => {
      if (res.success) {
        this.isDataLoaded = true
        // this._spinner.hide()
        this.activeItem = res.data
        let item = this.activeItem

        this.dataList = []
        this.dataList = JSON.parse(JSON.stringify(res.data.rpoItems))
        this.histList = JSON.parse(JSON.stringify(res.data.txnItems))

        item.receiveNo = item.poNumber
        item.createdDate = new Date(item.createdDate)

        if (item.poStatus == 'CLOSEDFORRECEIVING') item.poStatus = 'CLOSED FOR RECEIVING'
        item.locationName = item.locationName
        item.vendorName = item.vendorName

        this.calculateTotals()
      }
    })
  }

  getFooterNotesConfigValue() {
    try {
        this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
            if (r.success) {
                this.isFooterNoteEnabled = r.data.po_enablefooternote;
                this.footerNotesText = r.data.po_footernote
            }
            else {
                this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
            }
        }, error => {
            this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
        })
    } catch (error) {

    }
}
  onHistRowSelect(e) {
    this.histSelectedRow = e.DataViewModule
  }

  onHistRowUnselect(e) { }

  histRowClicked(e, item) {
    this.histMenu.toggle(e)
    this.histSelectedRow = item
  }

  handleOnClickIsDivide($event) {
    //debugger;
  }

  cancelButtonClick() {
    this._confirmationService.confirm({
      header: `Cancel changes?`,
      message: 'Changes you made will be lost. Are you sure you want to proceed?',
      accept: () => {
        try {
          window.history.back()
        } catch { this._abd.hideSpinner() }
      },
    });
  }

  calculateTotals() {
    let s = this.summary

    // s.subTotal = 0
    // s.discountAmount = 0
    // s.taxAmount = 0
    // s.total = 0
    // s.grandTotal = 0

    for (let i = 0; i < this.dataList.length; i++) {
      const r = this.dataList[i]
      r.srNo = i + 1

      let subTotal = (r.orderedQuantity - r.dueQuantity) * r.costPrice
      let discount = (r.discountPercent / 100) * subTotal
      if (!discount) discount = 0
      let tax = (r.taxRate / 100) * (subTotal - discount)
      if (!tax) tax = 0

      s.subTotal += subTotal
      s.discountAmount += discount
      s.taxAmount += tax
      s.total += subTotal - discount + tax
    }
    s.grandTotal = s.total + this.activeItem.shipping
  }


//   calculateTotals() {
//     let tmp = this.dataList.filter(f => f.receivedQuantity > 0)

//     this.sumSubTotal = 0
//     this.sumDiscount = 0
//     this.sumTax = 0
//     this.grandTotal = 0

//     for (let i = 0; i < this.dataList.length; i++) {
//       this.dataList[i].srNo = i + 1
//       this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
//     }


//     if (tmp.length && this.selectedOptions && this.selectedOptions.find(f => f == 'valA')) {
//       this.divideShipping = true
//     } else {
//       this.divideShipping = false
//     }

//     let tmp1 = 0
//     let lineShipping = 0

//     if (this.shipping > 0 && this.dataList.length) {
//       let find = this.dataList.filter(f => f.orderedQty > 0)
//       lineShipping = this.shipping / find.length
//     }

//     for (let i = 0; i < this.dataList.length; i++) {
//       let dr = this.dataList[i]

//       let pc = (dr.costPrice * dr.orderedQty)
//       let disc = 0
//       if (dr.discountPercent > 0) disc = pc * (dr.discountPercent / 100)
//       dr.discountAmount = disc
//       dr.gsubtotal=pc
//       dr.netTotal = pc - disc


//       if (!dr.totalCost || isNaN(dr.totalCost) || dr.totalCost <= 0) dr.totalCost = dr.netTotal //-- check if to keep

//       let tax = 0
//       if (dr.taxRate > 0) tax = dr.netTotal * (dr.taxRate / 100)
//       dr.taxAmount = tax

//       dr.totalCost = dr.netTotal + tax + tmp1

//       dr.itemLevelItemCost = dr.netTotal + dr.taxAmount  + dr.shipping;
//       this.grandTotal += dr.itemLevelItemCost
//       this.sumSubTotal += dr.gsubtotal
//       this.sumDiscount += dr.discountAmount
//       this.sumTax += dr.taxAmount
//     }
//     this.grandTotal += this.shipping
//   }


  commitAllInventory () {
    this._confirmationService.confirm({
        message: 'Are you sure you want to Commit this Purchase Order?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          try {
            this.optionCommitClicked()
          } catch { }
        },
        reject: () => { }
      })
  }
  optionCommitClicked() {
    try {
      let data = {
        "poStatus": this._purchaseService.newEditItem.POStatus,
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()
      this._purchaseService.saveReceivedPurchaseOrder(this._purchaseService.newEditItem.Id, true, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
            this.initData();
          this._messageService.add({ severity: 'success', summary: 'Purchase Order processed successfully!' })

        }
      })

    } catch { this._abd.hideSpinner() }
  }


}
