<p-accordion [multiple]="true">
 
    <span (click)="customSort()" *ngIf="dataList.length > 1">
    <i class="icon-c" [ngClass]="isAsc ? 'pi pi-sort-amount-up-alt':'pi pi-sort-amount-down'" ></i> Sort
    </span>

    <div *ngFor="let item1 of dataList">
        <p-accordionTab >
            <ng-template pTemplate="header">
                
                {{ item1.dateTime | date : "short" }} |  Invoice#:   {{ item1.invoiceNo }}
                
            </ng-template>
            <div class="border-dotted-custom" style="padding: 30px; margin-bottom: 20px;">

                <ng-container>
                    <div class="nas-grid-2x">
                        <div class="nas-label">
                            Dated
                        </div>
                        <div class="nas-value">
                            {{ item1.dateTime | date : "short" }} 
                        </div>

                    </div>
                    <div class="nas-grid-2x">
                        <div class="nas-label">
                            Invoice #
                        </div>
                        <div class="nas-value">
                            {{ item1.invoiceNo }}
                        </div>

                    </div>

                    <div class="nas-grid-2x">
                        <div class="nas-label">
                            Invoice Type
                        </div>
                        <div class="nas-value">
                            {{ item1.invoiceTypeString }}
                        </div>

                    </div>
                    <div class="nas-grid-2x">
                        <div class="nas-label">
                           Applied Coupon
                        </div>
                        <div class="nas-value">
                            {{ item1?.couponCode ? item1.couponCode : "N/A" }}
                        </div>

                    </div>
                    <br />
                </ng-container>

                <h3 style="
                    margin-top: 0px;
                    font-size: 1rem;
                    color: var(--bs-active-primary);
                    font-weight: 700px;
                ">
                    Items
                </h3>
                <p-divider></p-divider>
                <p-table styleClass="p-datatable-striped" [autoLayout]="true" [tableStyle]="{ width: 'max-content' }"
                    [value]="item1.packageDetails" responsiveLayout="scroll" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Description</th>
                            <th style="text-align: right">Qty</th>
                            <th style="text-align: right">Cost</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item1>
                        <tr>
                            <td>{{getWordsWithSpace(item1.title)}}</td>
                            <td style="text-align: right">{{item1.quantity}}</td>
                            <td style="text-align: right">{{item1.subTotalCost | currency}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="2" style="text-align: right">Sub-Total</td>
                            <td style="text-align: right">{{item1.invoiceSubtotal | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: right">Discount</td>
                            <td style="text-align: right">{{item1.totalDiscountAmount | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: right">Tax</td>
                            <td style="text-align: right">{{item1.totalTaxAmount | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: right">Total</td>
                            <td style="text-align: right">{{item1.grandTotal | currency: 'USD'}}</td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </p-accordionTab>
    </div>

</p-accordion>