import { ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-purchase-order-receive',
  templateUrl: './purchase-order-receive.component.html',
  styleUrls: ['./purchase-order-receive.component.scss']
})
export class PurchaseOrderReceiveComponent implements OnInit {
  locations: any = null
  vendors: any = null

  searchText = ''
  lastSearchText = ' '
  isLoading = false
  isListLoading = false

  dataList: any = []
  histList: any = null
  searchList: any = []

  gsubtotal = 0
  sumofsubtotal = 0
  gtax = 0
  gdiscount = 0
  shippingCost = 0.0
  grandTotal = 0

  isViewOnly = false
  isEdit = false
  isNew = false
  isEnableDeleteButton = false

  isDataLoaded = false
  notes = ''
  activeItem: any

  ghistGtotal = 0
  gtotalItems = 0
  greceivedItems = 0
  gremainingItems = 0
  invoiceNumber = ''
  commitPartial = false

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel

  constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    private _messageService: MessageService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    let serObj = this._purchaseService.newEditItem
    this.locations = this._abd.initActLocations()
    this._globals.componentName = 'Purchase Order Receive'
    serObj.ReceivedBy = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    serObj.ReceivedById = this._globals.userInfo.userID

    this.isViewOnly = serObj.isViewOnly
    this.isEdit = serObj.isEdit
    this.isNew = serObj.isNew

    if (this.isViewOnly || this.isEdit) {
      // this._spinner.show()
      this._purchaseService.searchSingleOrder(serObj.Id, true).subscribe(res => {
        if (res.success) {
          this.isDataLoaded = true
          this.activeItem = res.data
          let item = this.activeItem
          this.invoiceNumber = item.invoiceNumber

          // console.log('PO RES.DATA', item)

          let qty = 0
          this.dataList = item.rpoItems
          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].srNo = i + 1
            this.dataList[i].subTotal = 0
            this.dataList[i].pdiscount = 0
            this.dataList[i].ptax = 0
            qty += this.dataList[i].quantity
          }
          this.calculateTotals()

          if (!this.activeItem.dated) this.activeItem.dated = new Date()
          else this.activeItem.dated = new Date(this.activeItem.dated)
          this.activeItem.receiveDate = new Date()

          if (this.activeItem.txnItems.length > 0) {
            this.histList = this.activeItem.txnItems
            let hist = this.activeItem.txnItems
            // ghistGtotal
            for (let i = 0; i < hist.length; i++) {
              hist[i].srNo = i + 1;
              this.ghistGtotal += hist[i].totalAmount
            }
          }
        }
      })
    }
  }



  receivedQuantityValid(item: any) {
    // if (!item.isValueChanged) return true
    // if (!item.receivedQuantity) return false
    if (item.receivedQuantity > item.dueQuantity) {
      item.receivedQuantity = item.dueQuantity
      this.calculateTotals()
      return false
    }
    this.calculateTotals()
    return true
  }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  receivedQuantityChanged(e, item) {
    try {
      item.receivedQuantity = e.value
      if (!item.receivedQuantity) item.receivedQuantity = 0
      item.totalCost = item.costPrice * e.value
      item.isValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
      item.totalCost = item.costPrice * item.receivedQuantity
      item.isCostValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  editButtonClick() {
    let serObj = this._purchaseService.newEditItem
    serObj.isViewOnly = !serObj.isViewOnly
    this.isViewOnly = !this.isViewOnly
    this.isEdit = !this.isEdit
    serObj.isEdit = !serObj.isEdit
  }

  discountChanged(e, item) {
    const v = e.value
    if (e.value > 100) item.pdiscount = 100
    else item.pdiscount = e.value
    // if (v > item.subTotal) item.discountTotal = 0
    this.calculateTotals()
  }

  shippingCostChanged(e) {
    const v = e.value
    this.shippingCost = e.value
    // if (v > item.subTotal) item.discountTotal = 0
    this.calculateTotals()
  }

  taxChanged(e, item) {
    const v = e.value
    item.ptax = e.value
    // if (v > item.subTotal) item.discountTotal = 0
    this.calculateTotals()
  }

  calculateTotals() {
    this.gsubtotal = 0
    this.gtax = 0
    this.gdiscount = 0
    this.sumofsubtotal = 0

    this.gtotalItems = 0
    this.greceivedItems = 0
    this.gremainingItems = 0

    let serObj = this._purchaseService.newEditItem
    this.grandTotal = serObj.Shipping

    for (let i = 0; i < this.dataList.length; i++) {
      let stotal = 0
      let tax = 0
      let disc = 0
      let total = 0
      let price = 0
      let qty = 0

      this.gtotalItems += this.dataList[i].orderedQuantity

      price = this.dataList[i].costPrice
      qty = this.dataList[i].receivedQuantity
      if (!qty || qty <= 0) continue

      disc = (this.dataList[i].pdiscount / 100) * price
      if (!disc) disc = 0
      price = price - disc

      tax = (this.dataList[i].ptax / 100) * price
      if (!tax) tax = 0

      price = price + tax
      stotal = price * qty
      let dq = disc * qty
      let tq = tax * qty

      this.dataList[i].totalCost = stotal
      this.gsubtotal += stotal
      this.grandTotal += stotal
      this.gtax += tq
      this.gdiscount += dq
      this.sumofsubtotal += this.dataList[i].costPrice * qty
      this.greceivedItems += qty
    }

    this.grandTotal += this.shippingCost
    this.gremainingItems = this.gtotalItems - this.greceivedItems

    try {
      if (!this.activeItem.txnItems && this.activeItem.txnItems.length < 1) return
      const obj = this.activeItem.txnItems
      let gq = 0
      for (let i = 0; i < obj.length; i++)
        gq += obj[i].receivedQuantity

      this.gremainingItems -= gq
      this.greceivedItems += gq
    } catch { }
  }

  createDateClear() {
    const x = this.activeItem.dated
    if (!this.activeItem.dated) this.activeItem.dated = new Date()
  }

  cancelButtonClicked() {
    window.history.back()
  }

  saveFn(saveAs: any, goback = true) {
    if (!this.invoiceNumber) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Missing Invoice number!' })
      return
    }

    let obj = this.activeItem
    this.calculateTotals()

    // if (!this.isOk2Save()) return
    const lst = this.dataList.filter(f => f.receivedQuantity > 0)

    if (!lst.length && saveAs !== "COMPLETED") {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Nothing to save' })
      return
    }

    let mlist = []

    for (let i = 0; i < lst.length; i++) {
      let sku = ''
      if (this.isEdit)
        sku = lst[i].skuItemCode
      else
        sku = lst[i].skuCode

      const itm = lst[i]

      const m = {
        "itemSKUId": itm.itemSKUId,
        "notes": itm.notes,
        "orderedQuantity": itm.orderedQuantity,
        "dueQuantity": itm.dueQuantity,
        "receivedQuantity": itm.receivedQuantity,
        "returnQuantity": 0,
        "committedQuantity": 0,
        "costPrice": itm.costPrice,
        "priceA": itm.priceA,
        "priceB": itm.priceB,
        "priceC": itm.priceC,
        "subTotal": itm.subTotal,
        "discountPercent": itm.pdiscount,
        "discountAmount": itm.discountAmount,
        "taxRate": itm.ptax,
        "taxAmount": itm.taxAmount,
        "totalAmount": itm.totalCost,
        "updatedById": this._globals.userInfo.userID,
        "updatedBy": this._globals.userInfo.displayName
      }
      mlist.push(m)

    }

    this.invoiceNumber = this.invoiceNumber.replace(/\D/g, '')
    const data = {
      "invoiceNumber": this.invoiceNumber.replace(/\D/g, ''),
      "notes": obj.notes,
      "poStatus": saveAs,
      "paymentStatus": "PENDING",
      "subTotal": this.gsubtotal,
      "netTotal": this.gsubtotal - this.gdiscount,
      "discountTotal": this.gdiscount,
      "taxTotal": this.gtax,
      "shipping": this.shippingCost,
      "grandTotal": this.grandTotal,
      "receivedDate": obj.receiveDate,
      "receivedById": this._globals.userInfo.userID,
      "receivedBy": this._globals.userInfo.displayName,
      "purchaseOrderId": obj.id,
      "RPOItems": mlist
    }

    this._spinner.show()
    try {
      this._purchaseService.saveReceivedPurchaseOrder(obj.id, this.commitPartial, data).subscribe(res => {
        if (res.success) {
          this._spinner.hide()
          this._messageService.add({ severity: 'success', summary: 'Info', detail: 'Purchase order receivement saved successfully!' })
          window.history.back()
        }
        else {
          this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to save data!' })
          this._spinner.hide()
        }
      })
    } catch { this._spinner.hide() }

  }



}
