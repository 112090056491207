<div class="fadeindown loyalty-program animation-duration-250 flex">
    <div class="conn-card flex align-content-between w-full">

        <div class=" w-full">
            <app-report-top-filter-bar [paraReportName]="'reward-program-general'"
                (filterCallbackFunc)="callbackFuncFromFilter($event)"
                (filterCallbackFuncUpdateFilter)="filterButtonPressed()" (exportExcelFn)="exportExcel()"
                (exportPdfFn)="exportPdf()" (deleteItemFn)="optionDeleteTieredClicked()"></app-report-top-filter-bar>
            <p-table
                [tableStyle]="{ width: 'max-content' }"
                [value]="dataList"
                *ngIf="dataList?.length > 0"
                [autoLayout]="true"
                responsiveLayout="scroll"
                [paginator]="false"
                [rows]="rows"
                showLoader="true"
                [showCurrentPageReport]="true"
                [customSort]="true"
                dataKey="id"
                [lazy]="true"
                (onLazyLoad)="customSort1($event)"
                [globalFilterFields]="['displayName']"
                selectionMode="single"
                (onRowSelect)="onRowSelect($event)">
                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="displayName">Perks <p-sortIcon field="displayName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="pointsGoal" class="text-center">Points Required <p-sortIcon
                                field="pointsGoal">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="rewardType">Reward Type <p-sortIcon field="rewardType"></p-sortIcon>
                        </th>
                        <th >Status
                        </th>
                        <!--<th>Redeemed</th> -->
                        <th>Action</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.displayName}}</td>
                        <td class="text-center">{{item.pointsGoal|number}}</td>
                        <td>{{_abd.getWordsWithSpace(item.rewardType)}}</td>
                        <td>
                            <span class="text-green-500" *ngIf="item.active">Active</span>
                            <span class="text-bluegray-400" *ngIf="!item.active">Inactive</span>
                        </td>
                       <!-- <td>{{item.redeemed}}</td> -->
                        <td>
                             <button pButton pRipple icon="pi pi-angle-down" iconPos="right" type="button"
                                 label="Actions" class="n-action-button" (click)="op.toggle($event)"
                                 (click)="actionButtonClick(item)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="flex" *ngIf="dataList?.length == 0">
                <div class="text-align-center" style="width: 100%">
                    <span class="control-description text-muted text-align-center">
                        No data found!</span>
                </div>
            </div>
            <div *ngIf="totalRecords > 9">
                <p-paginator #p [first]="first" *ngIf="dataList?.length" (onPageChange)="onPageChange($event)"
                    [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}">
                </p-paginator>
            </div>
        </div>
    </div>
</div>

<p-dialog
    *ngIf="_rewardProgramService.showNewEditTieredModal"
    header="Save Perk"
    [(visible)]="_rewardProgramService.showNewEditTieredModal"
    [breakpoints]="{'960px': '75vw'}"
    [style]="{width:'80vw','max-height':'95vh'}"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    modal="true">
    <app-new-edit-regular-reward
        [isNew]="isNew"
        [loyaltyObj]="loyaltyObj"
        (onSubmit)="refreshDataList($event)"
    ></app-new-edit-regular-reward>
</p-dialog>
<p-overlayPanel #op [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }">

    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">


            <button pButton pRipple type="button" label="Edit" class="p-button-text" [style]="{ width: '100%' }"
                icon="pi pi-pencil" iconPos="right" (click)="editTieredProgram()" ></button>
            <button pButton pRipple type="button" label="Delete" class="p-button-danger p-button-text"
                [style]="{ width: '100%' }"
                (click)="optionDeleteTieredClicked()"
                icon="pi pi-trash" iconPos="right"></button>
        </div>
    </ng-template>
</p-overlayPanel>
