import { Component, OnInit } from '@angular/core';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {

    _globals = GlobalService
    allowUsers: boolean = true;
  constructor(
    public _businessSetupService: BusinessSetupService,
    public _userService: UserService,

  ) { }

  ngOnInit(): void {
      if (this._globals.userInfo.businessSetup[0].noOfUsers == -1){
          this.allowUsers = true
      }
      else{
          this.allowUsers = false
      }


  }

  addUser() {}

  optionDeleteClicked() {}

  addNewUser() {
    this._userService.activeItem = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      postalCode: '',
      state: '',
      photo: '',
      password: '',
      username: '',
      role: [],
      userID: '',
      overrideCode1: '',
      overrideCode2: ''
    }
    this._businessSetupService.showNewEditUser = true
    this._userService.isNew=true
  }

  editButtonClicked(){
    this._businessSetupService.showNewEditUser = true;
  }

}
