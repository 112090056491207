import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss']
})
export class EmailValidationComponent implements OnInit {
  userName: string = "";
  wlid = ''
  serverSideError: string = "";
  sendingRequest: boolean = false;
  sucessMessage = "Your email has been verified, and account deployment is in progress. You will receive a confirmation email after the successful deployment.";
  failurMessage = "Expired or invalid Link";
  message = '';
  private httpClient: HttpClient;
  constructor(
    public _router: Router,
    private route: ActivatedRoute,
    public _userService: UserService,
    private http: HttpClient
  ) {
    // this.httpClient = new HttpClient(httpBackend);

  }
  _globals = GlobalService
  ngOnInit(): void {

    try {

      this.route.queryParams.subscribe(params => {
        this.userName = params['username'];
        this.wlid = params['wlid']
      })
      if (this.userName?.length > 0) {

        // this._userService.triggerDeployment(this.userName).subscribe(res => {
        this.triggerDeployment(this.userName).subscribe(res => {

          if (res.success) {
            this.message = this.sucessMessage;
          }
          else {
            this.message = this.failurMessage
          }
        })
      }
      //else
      //this._router.navigateByUrl('pages/signin')
    }
    catch (e) {
      //console.log(e);

    }
  }

  ngAfterViewInit() {
    // try {

    this.route.queryParams.subscribe(params => {
      this.userName = params['username'];
      this.wlid = params['wlid']
    })
    if (this.userName?.length > 0) {

      // this._userService.triggerDeployment(this.userName).subscribe(res => {
      this.triggerDeployment(this.userName).subscribe(res => {
        if (res.success) {
          this.message = this.sucessMessage;
        }
        else {
          this.message = this.failurMessage
        }
      })
    }
    //else
    //this._router.navigateByUrl('pages/signin')
    // }
    // catch (e) {
    //   //console.log(e);

    // }
  }
  triggerDeployment(id) {

    let url = environment.BaseURL + environment.ActivateUserAccount + "/" + id + '?WLID=' + this.wlid;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    // headers.append('WLID', this._globals.getWlId());
    headers = headers.append('do-need-to-process-interceptor', `skip`);

    try {
      return this.http.get(url, { 'headers': headers }).pipe(map((res: any) => {

        //console.log('this-is-in', res);
        // alert()
        return res
      }))
    }
    catch
    {
      // alert()
    }

    // return this.httpClient.get(url).pipe(map((res: any) => {
    //   //console.log(res);
    //   return res
    // }))
  }

  gotologin() {
    window.location.href = '://' + window.location.host
  }
}
