
<div (widgetSaved)="widgetSaved()" class="col-3 {{partialCSSid}}-child {{widgetSavedCC}}"
    [ngClass]="(additionalCSSinformaiton | async)?.partialBorder">

    <div class="grid justify-content-center">
        <div class="grid col-12 justify-content-end align-content-around" [ngClass]="(additionalCSSinformaiton | async)?.partialText">
            <span *ngIf="(additionalCSSinformaiton | async)?.partialText == 'enabled-text'"> </span>
            <span *ngIf="(additionalCSSinformaiton | async)?.partialText == 'disabled-text'">Disabled</span>
            <span class="no-text" *ngIf="(additionalCSSinformaiton | async)?.partialText == ''"></span>
        </div>
        <div class="grid col-12 {{partialCSSid}}-image" [ngClass]="(additionalCSSinformaiton | async)?.partialIcon">
            <img class="img-1-1" alt="No image"
              >

        </div>
        <div class="grid col-12 justify-content-center header-text" >
            <span>{{partialHeader}}</span>
        </div>
    </div>
</div>
