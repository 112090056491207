<style>
    .e-plan-name {
        font-size: 18px;
        color: var(--brandColor);
        font-weight: 500;
        border: 1px solid var(--brandColor);
        padding: 10px;
        text-align: center;
        border-radius: 50px;
        width: 154px;
        margin: auto auto;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .e-plan-price {
        font-size: 24px;
        text-align: center;
        font-weight: bolder;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .e-plan-action-btn {
        text-align: center;
    }
    .hd-cat-description {
        padding-top: 17px;
        padding-bottom: 17px;
    }
    label.e-plan-price--perid-label {
        font-size: 16px;
        color: #9d9d9d;
    }
    .hd-cat-description li {
        line-height: 28px;
        font-size: 14px;
    }
    label.e-com-bullet {
        border-radius: 100%;
        background: var(--brandColor);
        color: white;
        /* padding: 3px; */
        display: inline-block;
        margin-top: 6px;
        margin-bottom: 5px;
        margin-right: 12px;
        line-height: 13px;
        width: 20px;
        height: 20px;
        text-align: center;
        align-items: center;
        align-content: center;
        padding: 3px;
    }

    label.e-com-bullet i {
        font-size: 10px;
    }
</style>
<div class="grid">
    <div
        class="xl:col-4 lg:col-4 md:col-6 col-12"
        *ngFor="let item of _signup.customerAppSubscriptionPlans"
    >
        <div class="conn-card">
            <div class="e-plan-name">
                <label>
                    {{ item.title }}
                </label>
            </div>
            <div class="e-plan-price">
                <label class="e-plan-price-label">
                    {{ item.price | currency }}
                </label>
                /
                <label class="e-plan-price--perid-label"> Month </label>
            </div>
            <div class="e-plan-action-btn">
                <p-toggleButton
                    onLabel="Selected"
                    offLabel="Select"
                    (onChange)="selectPlan($event, item)"
                    [(ngModel)]="item.selected"
                ></p-toggleButton>
            </div>
            <div class="hd-cat-description">
                <div *ngFor="let feature of item.features">
                    <label class="e-com-bullet">
                        <i class="pi pi-check"></i>
                    </label>
                    <label>
                        {{ feature.value }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="flex justify-content-between flex-wrap card-container purple-container mt-6"
>
    <div class="flex align-items-center justify-content-center">
        <p-button
        *ngIf="showFooter"
            label="Back"
            icon="pi pi-arrow-left"
            (onClick)="backStep()"
        ></p-button>
    </div>
    <div class="flex align-items-center justify-content-center">
        <p-button
            label="Continue"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextStep()"
        ></p-button>
    </div>
</div>
