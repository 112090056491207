import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map } from 'rxjs';
import { CRSCustomers } from '../_models/customers/customer';
import { CustomerGroup } from '../_models/customer-group';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  _globals = GlobalService
  showNewEdit = false
  showNewGroupEdit = false
  showNewNewGroupEdit = false
  showCustomerList=false
  showNewNewEdit = false
  isViewOnly = false
  selectedCustomerGroup = ''
  selectedState: any = ''
  city: any = ''
  activeItem: any = null
  groupInfo : any = null;

  lastSearchText:any=null
  
  constructor(private _bs: BaseService) { }

  blankActiveItem(){
    this.activeItem = {
      firstName:'',
      lastName:'',
      phone:'',
      email:'',
      postalCode:'',
      city:'',
    }
  }

  createCustomerGroup(data: any){
    let url = environment.CustomerBaseURL + environment.GetCustomerGroup;
    return this._bs.httpPost(url, data).pipe(map(res => {
      return res;
    }))
  }

  updateCustomerLoyalty(data: any){
    let url = environment.CustomerBaseURL + 'LoyaltyPoints'
    return this._bs.httpPut(url, data).pipe(map(res => {
      return res;
    }))
  }

  getAllCustomers(paras: any) {
    let url = environment.CustomerBaseURL + environment.GetCustomer

    return this._bs.httpGetParams(url, paras).pipe(map(res => {
      return res
    }))
  }

  getAllCustomerGroups(params: any) {
    let url = environment.CustomerBaseURL + environment.GetCustomerGroup

    return this._bs.httpGetParams(url,params).pipe(map(res => {
      return res
    }))
  }

  getSingleCustomer(phone: string) {
    let url = environment.CustomerBaseURL + environment.SearchCustomerURL + "/" + phone;
    return this._bs.httpGet(url).pipe(map(res => {
      return res
    }))
  }

  createCustomer(data: any) {
    let url = environment.CustomerBaseURL + environment.GetCustomerURL;
    return this._bs.httpPost(url, data).pipe(map(res => {
      return res;
    }))
  }
  updateCustomer(data: any, custid: string) {
    let url = environment.CustomerBaseURL + environment.GetCustomerURL + "/" + custid;
    return this._bs.httpPut(url, data).pipe(map(res => {
      return res;
    }))
  }

  assignCustomerGroup(data: any) {
    let url = environment.CustomerBaseURL + environment.AssignCustomerToGroup;
    return this._bs.httpPut(url, data).pipe(map(res => {
      return res;
    }))
  }

  updateCustomerGroup(data: any, id: string) {
    let url = environment.CustomerBaseURL + environment.GetCustomerGroup + "/" + id;
    return this._bs.httpPut(url, data).pipe(map(res => {
      return res;
    }))
  }

  updateCustomerStatus(active: boolean, custID) {
    let status = "activate"

    status = active ? "Activate" : "deactivate"

    let url = environment.CustomerBaseURL + environment.GetCustomerURL + "/" + status + "/" + custID;
    return this._bs.httpPut(url).pipe(map(response => {
      return response;

    }))
  }
  deleteCustomer(id: string) {
    let url = environment.CustomerBaseURL + environment.GetCustomer + "/" + id;

    return this._bs.httpDelete(url).pipe(map(response => {
      return response;

    }))
  }

  deleteCustomerGroup(id: string) {
    let url = environment.CustomerBaseURL + environment.GetCustomerGroup + "/" + id;

    return this._bs.httpDelete(url).pipe(map(response => {
      return response;

    }))
  }

  searchCustomerHistoryByCustomerId(customerId: string, pageNo, pageSize) {
    let url = environment.OrderBaseURL + environment.SearchInvoiceByCustomerId + "/" + customerId+"?pageNo="+pageNo+"&pageSize="+pageSize ;
    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }

  getInvoiceList(pageNo:number , pageSize: number){
    let url = environment.OrderBaseURL+ environment.GetPurchaseHistory+"?pageNo="+pageNo+"&pageSize="+pageSize ;
    return this._bs.httpGet(url).pipe(map(response=>{
        return response;
    }))
  }


    getCustomerListBelongsToGroup(groupId: number,pageNo:number , pageSize: number) {
        let url = environment.CustomerBaseURL + environment.GetAssignedCustomers  +"?cGroupId="+groupId+"&pageNo="+pageNo+"&pageSize="+pageSize;
        return this._bs.httpGet(url).pipe(map(res => {
        return res
        }))
    }

    getCustomerListBelongsToGroupV2(groupId: number) {
        let url = environment.CustomerBaseURL + environment.GetAssignedCustomersListV2 + "?cGroupId=" + groupId ;
        return this._bs.httpGet(url).pipe(map(res => {
            return res
        }))
    }


}
