import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { ConnMessageService } from './conn-message.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  activeItem:any={
    id:'',
    departmentName: '',
    isActive: true,
  }
  
  showNewEdit = false
  showNewNewEdit = false
  isViewOnly = false

  constructor(
    private _bs: BaseService,
    private _connMessageService: ConnMessageService,
  ) { }

  getAllDepartments(params: any, auth: boolean = false, showSpinner: boolean = true) {
    let url = environment.InventoryBaseURL + environment.GetDepartmentList
    
    return this._bs.httpGetParams(url, params).pipe(map(res => {
      if (res.success) {
        return res
      }
    }))
  }

  createUpdateDepartment(obj: any) {
    let url = environment.InventoryBaseURL + environment.CreateDepartment
    url += obj.id ? ('/' + obj.id) : ''

    if (obj.id) {
      return this._bs.httpPut(
        url,
        JSON.stringify({
          "id": obj.id,
          "departmentName": obj.departmentName,
          "isActive": obj.isActive,
        }),
        true).pipe(map(res => {
          if (res.success) {
            this._connMessageService.showToastMessage('Department updated successfully.', 'success');
            return res
          }
        }))
    } else {
      return this._bs.httpPost(
        url,
        JSON.stringify({
          "id": obj.id,
          "departmentName": obj.departmentName,
          "isActive": obj.isActive,
        }),
        true).pipe(map(res => {
          if (res.success) {
            this._connMessageService.showToastMessage('Department saved successfully.', 'success');
            return res
          }
        }))
    }
  }

  deleteDepartment(id:string){
    let url = environment.InventoryBaseURL+ environment.CreateDepartment+"/"+id;

    return this._bs.httpDelete(url).pipe(map(response=>{
      return response;
    }))
  }


}
