<div class="flex print-page flex-column p-fluid w-6">
    <div class="field flex w-full">
        <label htmlfor="InputPrintPointsOnReceipt" class="col-12 mb-2 md:col-8 md:mb-0">
           Print points info on the receipt
        </label>
        <div class="col-12 md:col-4">
            <p-inputSwitch [(ngModel)]="InputPrintPointsOnReceipt" inputId="InputPrintPointsOnReceipt">
            </p-inputSwitch>
            <label class="control-label" for="InputPrintPointsOnReceipt"><strong> Enable</strong></label>
        </div>
    </div>
    <div class="card border-dotted-custom mb-5" [ngClass]="{'disabled-input': !InputPrintPointsOnReceipt}">
        <div class="flex" *ngFor="let info of printInfoList">
            <div class="col-12 md:col-2">
                <p-checkbox
                [ngClass]="{'disabled-input': !InputPrintPointsOnReceipt}"
                [binary]="true"
                [(ngModel)]="info.active"></p-checkbox>
            </div>
            <label class="col-12 mb-2 md:col-10 md:mb-0">
               {{info.title}}
            </label>
        </div>

    </div>
    <div class="field flex flex-column w-full">
        <div class="flex">
            <label htmlfor="InputDisplayPointsOnSC" class="col-12 mb-2 md:col-8 md:mb-0">
                Display points info on secondary screen
            </label>
            <div class="col-12 md:col-4">
                <p-inputSwitch
                    [(ngModel)]="InputDisplayPointsOnSC"
                    inputId="InputDisplayPointsOnSC">
                </p-inputSwitch>
                <label class="control-label" for="InputDisplayPointsOnSC"><strong> Enable</strong></label>
            </div>
        </div>
        <div class="flex">
            <div class="col-12 md:col-12 text-sm text-bluegray-300 mt-1">
                This will show customer point goals & available perks on screen
            </div>
        </div>
    </div>
    <div class="field flex w-full">
        <label htmlfor="InputSMSNotification" class="col-12 mb-2 md:col-8 md:mb-0">
            SMS notification to customers
        </label>
        <div class="col-12 md:col-4">
            <p-inputSwitch [(ngModel)]="InputSMSNotification" inputId="InputSMSNotification">
            </p-inputSwitch>
            <label class="control-label" for="InputSMSNotification"><strong> Enable</strong></label>
        </div>
    </div>
    <div class="card border-dotted-custom mb-5" [ngClass]="{'disabled-input': !InputSMSNotification}">
        <div class="flex" *ngFor="let info of smsNotificationList">
            <div class="col-12 md:col-2">
                <p-checkbox
                    [ngClass]="{'disabled-input': !InputSMSNotification}"
                    [binary]="true"
                    [(ngModel)]="info.active">
                </p-checkbox>

            </div>
            <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-10 md:mb-0">
                {{info.title}}
            </label>
        </div>
    </div>
    <div class="field flex w-full">
        <label htmlfor="InputEmailNotification" class="col-12 mb-2 md:col-8 md:mb-0">
           Email notification to customers
        </label>
        <div class="col-12 md:col-4">
            <p-inputSwitch [(ngModel)]="InputEmailNotification" inputId="InputEmailNotification">
            </p-inputSwitch>
            <label class="control-label" for="InputEmailNotification"><strong> Enable</strong></label>
        </div>
    </div>
    <div class="card border-dotted-custom mb-5" [ngClass]="{'disabled-input': !InputEmailNotification}">
        <div class="flex">
            <div class="col-12 md:col-2">
                <p-checkbox
                    [ngClass]="{'disabled-input': !InputEmailNotification}"
                    [binary]="true"
                    [(ngModel)]="sendEmailWhenPointsRedeemed">
                </p-checkbox>

            </div>
            <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-10 md:mb-0">
                Send Email when points are redeemed
            </label>
        </div>
        <div class="field my-3">
            <label htmlfor="InputMaxPointsAllowed">Subject  </label>
            <input
                pinputtext=""
                id="InputMaxPointsAllowed"
                type="text"
                [disabled]="!sendEmailWhenPointsRedeemed"
                placeholder="Points Redemption Confirmation"
                class="p-inputtext p-component p-element">
        </div>
        <div class="field">
            <label htmlfor="InputMaxPointsAllowed">Message </label>
            <p-editor
                [style]="{'height':'320px'}">
            </p-editor>
        </div>
          <div class="flex">
              <div class="col-12 md:col-2">
                  <p-checkbox
                     [ngClass]="{'disabled-input': !InputEmailNotification}"
                     [binary]="true"
                     [(ngModel)]="sendEmailWhenPointsAccumulated"
                  ></p-checkbox>

              </div>
              <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-10 md:mb-0">
                  Send Email when points are accumulated
              </label>
          </div>
    </div>
</div>
