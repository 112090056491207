import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';

@Component({
  selector: 'app-bizz-customer-subscription-invoice',
  templateUrl: './bizz-customer-subscription-invoice.component.html',
  styleUrls: ['./bizz-customer-subscription-invoice.component.scss']
})
export class BizzCustomerSubscriptionInvoiceComponent implements OnInit {
  stateOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  invoiceDetail: any = []
  productsDetail: any = []

  constructor(
    public _bizzCustomerService: BizzCustomersService,
  ) { }

    ngOnInit(): void {
        let item = this._bizzCustomerService.activeItem.invoice



       let planPricePerMonth = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[1].value
        if (this._bizzCustomerService.activeItem.subscriptionInterval == 12) {
            planPricePerMonth = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[0].value
        }
        let costHeading = "$" + planPricePerMonth + "/per month billed monthly"

        let selectedPlanInterval = this._bizzCustomerService.activeItem.subscriptionInterval
        if (selectedPlanInterval == 12) {
            costHeading = "$" + planPricePerMonth + "/per month X " + selectedPlanInterval + " = $" + planPricePerMonth * selectedPlanInterval + " billed annually"
        }


        if (this._bizzCustomerService.activeItem.isFreeTrial){
            costHeading = "$0.00"
        }

        this.invoiceDetail.push({ label: 'Invoice No', value: item.invoiceNo })
        // this.invoiceDetail.push({ label: 'Plan', value: item.planName })
       // this.invoiceDetail.push({ label: 'Base Plan Price', value: costHeading })
       // this.invoiceDetail.push({ label: 'Purchased Locations', value: this._bizzCustomerService.activeItem.noOfLocations })
        // this.invoiceDetail.push({ label: 'Next Charge Amount', value: item.nextChargeAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) })
        // this.invoiceDetail.push({ label: 'Next Charge Date', value: new Date(item.nextChargeDate) })

        item = this._bizzCustomerService.activeItem.invoice.packageDetails
        for (let i = 0; i < item.length; i++) {

            let additionalCost = "";
            if (selectedPlanInterval == 12 && (i == 1 || i == 2)){
                additionalCost = item[i].pricePerPackage.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + " X " + item[i].quantity  +" X 12 = "
            }
            let includedAddon = "";
            if (item[i].addonsIncluded){
                 includedAddon = " (addon included)"
            }

            this.productsDetail.push({
                srNo: i + 1,
                description: item[i].title + includedAddon,
                price: item[i].pricePerPackage.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                qty: (i == 0 ? 1 : item[i].quantity),
                subTotal: additionalCost+ item[i].subTotalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            })
        }

    }

    getWordsWithSpace(str) {
        if (str != '' && str != undefined) {
            return str.replace(/[A-Z]/g, ' $&').trim()
        }

    }
}
