<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'sales-tax-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        
        >
    </app-report-top-filter-bar>
    <br/>

    <div class="py-3">
        <p-button [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'" (onClick)="toggle(0)" styleClass="p-button-text" label="Date"></p-button>
        <p-button [icon]="activeState[1] ? 'pi pi-minus' : 'pi pi-plus'" (onClick)="toggle(1)" styleClass="p-button-text ml-2" label="Location"></p-button>
        <p-button [icon]="activeState[2] ? 'pi pi-minus' : 'pi pi-plus'" (onClick)="toggle(2)" styleClass="p-button-text ml-2" label="Year"></p-button>
    </div>
    <p-accordion>
        <p-accordionTab header="Date" [(selected)]="activeState[0]">
            <p-table [value]="dateDataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped"> [scrollable]="true"
                <ng-template pTemplate="header">
                    <tr>
                        <th>Date</th>
                        <th>Location</th>
                        <th style="text-align: center">Tax Rate (%)</th>
                        <th style="text-align: center">Taxable Sales ($)</th>
                        <th style="text-align: center">Non-Taxable Sales ($)</th>
                        <th style="text-align: center">Total Sales ($)</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-expanded="expanded">
                    <tr>
                        <td>{{product.groupValue}}</td>
                        <td>{{product.locationName}} ({{product.locationId}})</td>
                        <td style="text-align: center">{{product.taxRete | number}} (%)</td>
                        <td style="text-align: center">{{product.taxableSales | currency}}</td>
                        <td style="text-align: center">{{product.nonTaxableSales | currency}}</td>
                        <td style="text-align: center">{{product.totalSales | currency}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">
                            There is no data to show.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <p-accordionTab header="Location" [(selected)]="activeState[1]"> 
            <p-table [value]="locationDataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Location</th>
                        <th style="text-align: center">Tax Rate (%)</th>
                        <th style="text-align: center">Taxable Sales ($)</th>
                        <th style="text-align: center">Non-Taxable Sales ($)</th>
                        <th style="text-align: center">Total Sales ($)</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-expanded="expanded">
                    <tr>
                        <td>{{product.groupValue}} ({{product.locationId}})</td>
                        <td style="text-align: center">{{product.taxRete | number}} </td>
                        <td style="text-align: center">{{product.taxableSales | currency}}</td>
                        <td style="text-align: center">{{product.nonTaxableSales | currency}}</td>
                        <td style="text-align: center">{{product.totalSales | currency}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">
                            There is no data to show.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
        <p-accordionTab header="Year" [(selected)]="activeState[2]">
            <p-table [value]="yearDataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Year</th>
                        <th>Location</th>
                        <th style="text-align: center">Tax Rate (%)</th>
                        <th style="text-align: center">Taxable Sales ($)</th>
                        <th style="text-align: center">Non-Taxable Sales ($)</th>
                        <th style="text-align: center">Total Sales ($)</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-expanded="expanded">
                    <tr>
                        <td>{{product.groupValue}}</td>
                        <td>{{product.locationName}} ({{product.locationId}})</td>
                        <td style="text-align: center">{{product.taxRete | number}} </td>
                        <td style="text-align: center">{{product.taxableSales | currency}}</td>
                        <td style="text-align: center">{{product.nonTaxableSales | currency}}</td>
                        <td style="text-align: center">{{product.totalSales | currency}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">
                            There is no data to show.
                        </td>
                    </tr>
                </ng-template>
            </p-table>   
        </p-accordionTab>
    </p-accordion>
</div>