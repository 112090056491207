<div>
    <div>
        <div>
            <label class="control-label"
                ><strong
                    >
                    Category Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <!-- (ngSubmit)="editform.valid && saveFn()" -->
            <form
                [formGroup]="editform"
                (ngSubmit)="editform.valid && saveFn()"
                autocomplete="off"
            >
                <app-text-input
                    formControlName="categoryName"
                    [label]="'Category Name'"
                    [name]="'Category Name'"
                    [description]="
                        'Set the name of Category, it must be unique'
                    "
                    (input)="
                        _categoryService.activeItem.categoryName =
                            $event.target.value
                    "
                ></app-text-input>

                <br />
                <label class="control-label"
                    ><strong
                        >Departments
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <div class="p-float-label">
                    <p-dropdown
                        [style]="{
                            width: '100%',
                            'min-width': '120px',
                            'margin-top': '7px'
                        }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [(ngModel)]="_categoryService.selectedDepartment"
                        [options]="_abd.actDepartments"
                        optionLabel="departmentName"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="_categoryService.isViewOnly"
                    ></p-dropdown>
                </div>

                <div
                    class="flex justify-content-end flex-wrap card-container green-container mt-3"
                >
                    <div class="flex align-items-center justify-content-center">
                        <button
                            *ngIf="this.whatAction == 'Edit'"
                            pButton
                            pRipple
                            type="button"
                            (click)="saveFn()"
                            [label]="whatAction"
                            [style]="{'width':'80px'}"
                        ></button>

                        <button
                            *ngIf="this.whatAction == 'Save'"
                            pButton
                            pRipple
                            type="submit"
                            [disabled]="
                                (!editform.valid || _categoryService.isViewOnly) &&
                                this.whatAction !== 'Save'
                            "
                            [label]="whatAction"
                            [style]="{'width':'80px'}"
                        ></button>
                        <!-- [disabled]="!editform.valid || _categoryService.isViewOnly" -->
                    </div>
                    <div
                        class="flex align-items-center justify-content-center ml-2"
                    >
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Cancel"
                            class="p-button-outlined p-button-danger"
                            (click)="cancelButtonClick()"
                            [style]="{'width':'80px'}"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
