<p-menu #dcmenu [popup]="true" [model]="drawerCountVerify"></p-menu>

<div class="conn-card reconciliation">
    <app-report-top-filter-bar
        *ngIf="step == 1"
        [paraReportName]="'cash-reconciliation-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <app-report-top-filter-bar
        *ngIf="step == 2"
        [paraReportName]="'cash-reconciliation-report-3'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <app-report-top-filter-bar
        *ngIf="step == 3"
        [disableAllControls]="true"
        [paraReportName]="'cash-reconciliation-report-3'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <div *ngIf="step > 1">
        <br />

        <div class="grid">
            <div
                class="md:col-6 lg:col-4 xl:col-3"
                *ngFor="let tag of topTags"
            >
                <div

                    class="conn-card text-align-center "
                    [style]="getOverShortColor(tag)"
                >
                    <label
                        (click)="showDetail(tag.id, tag.allowPopup)"

                        class="widget-title font-bold text-1xl {{tag.class}}"
                    >
                        {{ tag.title }}

                        <i  [ngClass]="tag.class + ' ' + tag.icon_class "

                             pTooltip="{{tag.tooltip}}"
                            tooltipPosition="top">
                        </i>
                    </label>
                    <p-divider></p-divider>
                    <label
                        class="widiget-figure-main font-bold text-lg"
                        [style]="getOverShortCColor(tag)"
                    >
                        {{ calculateSummaryTotal(tag.id) | currency }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="step == 2" class="customaccordian">
        <p-accordion [activeIndex]="currentOpenedAccordion"  (onOpen)="open($event)">
            <p-accordionTab>
                <ng-template pTemplate="header" >
                    <div class="flex align-items-center p-jc-between px-3">
                    Cash Drops
                    <button
                        pButton
                        type="button"
                        label="Add Cash Drops"
                        class="p-button-info ml-2"
                        icon="pi pi-dollar"
                        iconPos="left"
                        style="width: 170px"
                        *ngIf="allowAddingCashDrop"
                        (click)="cashDropsHandler()"
                    ></button>

                    </div>
                </ng-template>
                <ng-container *ngIf="step == 2">
                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="cashDropList"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Register</th>
                                <th>Counted By</th>
                                <th>Date & Time</th>
                                <th class="text-center">$100</th>
                                <th class="text-center">$50</th>
                                <th class="text-center">$20</th>
                                <th class="text-center">$10</th>
                                <th class="text-center">$5</th>
                                <th class="text-center">$2</th>
                                <th class="text-center">$1</th>
                                <th class="text-center">25 cents (Quarters)</th>
                                <th class="text-center">10 cents (Dimes)</th>
                                <th class="text-center">5 cents (Nickels)</th>
                                <th class="text-center">1 cent (Penny)</th>
                                <th class="text-right">Total Count</th>
                                <!-- <th class="text-right">System Count</th>
                                <th class="text-right">Over/Short</th>
                                <th class="text-right">Last Count</th> -->
                                <!-- <th class="text-center">Verify</th> -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.registerName }}</td>
                                <td>{{ item.user }}</td>
                                <td>{{ item.dated | date : "short" }}</td>
                                <td class="text-center">{{ item.dollar100Qty }}</td>
                                <td class="text-center">{{ item.dollar50Qty }}</td>
                                <td class="text-center">{{ item.dollar20Qty }}</td>
                                <td class="text-center">{{ item.dollar10Qty }}</td>
                                <td class="text-center">{{ item.dollar5Qty }}</td>
                                <td class="text-center">{{ item.dollar2Qty }}</td>
                                <td class="text-center">{{ item.dollar1Qty }}</td>
                                <td class="text-center">{{ item.cent25Qty }}</td>
                                <td class="text-center">{{ item.cent10Qty }}</td>
                                <td class="text-center">{{ item.cent5Qty }}</td>
                                <td class="text-center">{{ item.cent1Qty }}</td>
                                <td class="text-right">
                                    {{ item.amount | currency }}
                                </td>
                                <!-- <td class="text-right">
                                    {{ item.systemCount | currency }}
                                </td>
                                <td class="text-right">{{ item.overShort | currency }}</td>
                                <td class="text-right">{{ item.lastCount | currency }}</td> -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="17" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </p-accordionTab>

            <p-accordionTab >
                <ng-template pTemplate="header">
                    <div class="flex align-items-center p-jc-between px-3">
                    Cash Count
                    <button
                        pButton
                        type="button"
                        label="Add Cash Count"
                        class="p-button-info ml-2"
                        icon="pi pi-dollar"
                        iconPos="left"
                        *ngIf="allowAddingCashCount"
                        style="width: 170px"
                        (click)="cashDepositHandler()"
                    ></button>
                    </div>
                </ng-template>
                <ng-container *ngIf="step == 2">

                    <p-table
                        #dt1
                        [columns]=""
                        styleClass="p-datatable-striped my-1"
                        id="table-001"
                        [autoLayout]="true"
                        [tableStyle]="{ width: 'max-content' }"
                        [value]="drawerCountList"
                        responsiveLayout="scroll"
                        showLoader="true"
                        rowHover="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Register</th>
                                <th>Counted By</th>
                                <th>Date & Time</th>
                                <th class="text-center">$100</th>
                                <th class="text-center">$50</th>
                                <th class="text-center">$20</th>
                                <th class="text-center">$10</th>
                                <th class="text-center">$5</th>
                                <th class="text-center">$2</th>
                                <th class="text-center">$1</th>
                                <th class="text-center">25 cents (Quarters)</th>
                                <th class="text-center">10 cents (Dimes)</th>
                                <th class="text-center">5 cents (Nickels)</th>
                                <th class="text-center">1 cent (Penny)</th>
                                <th class="text-right">Total Count</th>
                                <th class="text-right">System Count</th>
                                <th class="text-right">Over/Short</th>
                                <th class="text-right">Last Count</th>
                                <!-- <th class="text-center">Verify</th> -->
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.registerName }}</td>
                                <td>{{ item.user }}</td>
                                <td>{{ item.dated | date : "short" }}</td>
                                <td class="text-center">{{ item.dollar100Qty }}</td>
                                <td class="text-center">{{ item.dollar50Qty }}</td>
                                <td class="text-center">{{ item.dollar20Qty }}</td>
                                <td class="text-center">{{ item.dollar10Qty }}</td>
                                <td class="text-center">{{ item.dollar5Qty }}</td>
                                <td class="text-center">{{ item.dollar2Qty }}</td>
                                <td class="text-center">{{ item.dollar1Qty }}</td>
                                <td class="text-center">{{ item.cent25Qty }}</td>
                                <td class="text-center">{{ item.cent10Qty }}</td>
                                <td class="text-center">{{ item.cent5Qty }}</td>
                                <td class="text-center">{{ item.cent1Qty }}</td>
                                <td class="text-right">
                                    {{ item.amount | currency }}
                                </td>
                                <td class="text-right">
                                    {{ item.systemCount | currency }}
                                </td>
                                <td class="text-right">{{ item.overShort | currency }}</td>
                                <td class="text-right">{{ item.lastCount | currency }}</td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" style="text-align: center">
                            <tr>
                                <td colspan="17" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </p-accordionTab>
        </p-accordion>
    </div>

    <ng-container *ngIf="step == 1">
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="eodList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            selectionMode="single"
            [(selection)]="selectedRow1"
            (onRowSelect)="onRowSelectStep1($event)"
            (onRowUnselect)="onRowUnselectStep1($event)"
        >
            <ng-template pTemplate="header">
                <tr [pSelectableRow]="item">
                    <th>Date</th>
                    <!-- <th class="text-right" pTooltip="All “Add Tills” that occurred that day"> Starting Cash <i class="pi pi-window-maximize"  tooltipPosition="top">
                    </i></th> -->
                    <th class="text-right"> Starting Cash
                    </th>
                    <th class="text-right">System Total</th>
                    <th class="text-right">Expected Cash</th>
                    <th class="text-right">Counted at Registers</th>
                    <th class="text-center">Manager Count</th>
                    <th class="text-center">Status</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item">
                    <td>{{ item.dated | date : "mediumDate" }}</td>
                    <td class="text-right">
                        {{ item.startingCash | currency }}
                    </td>
                    <td class="text-right">
                        {{ item.systemTotal | currency }}
                    </td>
                    <td class="text-right">
                        {{ item.expectedCash | currency }}
                    </td>
                    <td class="text-right">
                        {{ item.registersCount | currency }}
                    </td>
                    <td class="text-center" *ngIf="item.allowReconcilation">
                        <button
                            pButton
                            type="button"
                            label="Reconcile"
                            (click)="reconcileClicked(item)"
                        ></button>
                    </td>
                    <td class="text-center" *ngIf="item.allowReconcilation">
                        Un-Reconciled
                    </td>
                    <td class="text-center" *ngIf="!item.allowReconcilation">
                        {{ item.managerCount | currency }}
                    </td>
                    <td class="text-center">{{ getStatusText(item) }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="7" style="text-align: center">
                        No data found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <ng-container *ngIf="step == 2">
        <div class="flex justify-content-between flex-wrap my-4">
            <div class="flex align-items-center justify-content-center m-2">
                <button
                    pButton
                    type="button"
                    label="Back"
                    class="p-button-help"
                    icon="pi pi-angle-left"
                    iconPos="left"
                    style="width: 150px"
                    (click)="backButtonClicked(2)"
                ></button>
            </div>

            <div
                class="flex align-items-center justify-content-center m-2"
                *ngIf="isViewOnly || selectedRow1.isManagerCount"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Next"
                            style="width: 150px"
                            (click)="confirmCount('false')"
                        ></button>
                    </div>
                </div>
            </div>

            <div
                class="flex align-items-center justify-content-center m-2 "
                *ngIf="!isViewOnly && !selectedRow1.isManagerCount"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Save"
                            class="p-button-secondary"
                            style="width: 150px"
                            (click)="confirmCount('true')"
                        ></button>
                        <button
                            pButton
                            type="button"
                            label="Confirm Count"
                            class="ml-2"
                            style="width: 150px"
                            (click)="confirmCount('false')"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step == 3">
        <div>
            <div
                class="flex justify-content-center flex-wrap card-container yellow-container my-4"
            >
                <p-table
                    #dt1
                    [columns]=""
                    styleClass="p-datatable-striped"
                    id="table-002"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }"
                    [value]="mockData"
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">Bills</th>
                            <th class="text-center">Quantity</th>
                            <th class="text-center">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td class="text-center">{{ item.bill }}</td>
                            <td>
                                <p-inputNumber
                                    [(ngModel)]="item.qty"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="itemQtyChanged($event, item)"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center',
                                        height: '50px'
                                    }"
                                    [disabled]="
                                        isViewOnly ||
                                        managerCountObj?.isConfirmed
                                    "
                                >
                                </p-inputNumber>
                            </td>
                            <td class="text-center">
                                {{ item.total | currency }}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="footer">
                        <tr class="summary-line">
                            <td
                                colspan="2"
                                style="
                                    text-align: right;
                                    background-color: var(--brandColor);
                                    color: white;
                                "
                                class="font-bold text-lg select-none"
                            >
                                Total
                            </td>
                            <td
                                style="
                                    text-align: center;
                                    background-color: var(--brandColor);
                                    color: white;
                                "
                                class="font-bold text-lg select-none"
                            >
                                {{ getTotalQtyDC() | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <br />
        <br />
        <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center m-2">
                <button
                    pButton
                    type="button"
                    label="Back"
                    class="p-button-help"
                    icon="pi pi-angle-left"
                    iconPos="left"
                    style="width: 100px"
                    (click)="backButtonClicked(3)"
                ></button>
            </div>
            <div
                class="flex align-items-center justify-content-center m-2"
                *ngIf="!isViewOnly && !managerCountObj?.isConfirmed"
            >
                <div class="flex justify-content-end flex-wrap">
                    <div
                        class="flex align-items-center justify-content-center m-2"
                    >
                        <button
                            pButton
                            type="button"
                            label="Save Manager Count"
                            class="p-button-secondary"
                            style="width: 160px"
                            (click)="saveManagerCount(false)"
                        ></button>
                        <button
                            pButton
                            type="button"
                            label="Confirm & Deposit"
                            class="ml-2"
                            style="width: 160px"
                            (click)="saveManagerCount(true)"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<br />
<div class="conn-card" *ngIf="step == 1 && eodList?.length">
    <p-divider class="mt-1" align="left" type="solid">
        <i class="pi pi-table mr-2"></i>
        <b class="font-bold text-600">&nbsp;F O R M U L A S</b>
    </p-divider>

    <div class="grid">
        <div [class]="col1" style="width: 100px">Starting Cash</div>
        <div [class]="col2">Sum of all last add tills for each register </div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">System Total</div>
        <div [class]="col2">
            (Sales + Payin + Add Till - Remove Till - Refund - Payout - Cash Drop) onward from
            last reset
        </div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Expected Cash</div>
        <div [class]="col2">(Sales + Payin - Refund - Payout) current day</div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Register Count</div>
        <div [class]="col2">Last Cash Count Amount on register</div>
    </div>
    <div class="grid">
        <div [class]="col1" style="width: 100px">Manager Count</div>
        <div [class]="col2">Manager Counted Amount</div>
    </div>
</div>

<!-- [style]="{ width: isTabletFn() }" -->
<p-dialog
    header="Add Cash Count"
    [(visible)]="showCashDeposit"
    [blockScroll]="true"
    modal="true"
    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [draggable]="false"
    [style]="{ width: '35vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-6 text-lg text-700 font-semibold">Location</div>
            <div class="col-6 text-lg text-700 font-semibold">Register</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                ></p-dropdown>
            </div>
            <div class="col-6 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                ></p-dropdown>
            </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Cash Count"
                class="ml-2"
                style="width: 130px"
                (click)="postCashDeposit()"
            ></button>
        </div>
    </div>
</p-dialog>

<p-dialog
    header="Add Cash Drops"
    [(visible)]="showCashDrop"
    [blockScroll]="true"
    [draggable]="false"
    modal="true"

    [breakpoints]="{
        '960px': '65vw',
        '640px': '90vw',
        '250px': '100vw'
    }"
    [style]="{ width: '50vw' }"
>
    <div class="border-round shadow-2 border-1 border-400 bg-white">
        <div class="grid m-2">
            <div class="col-4 text-lg text-700 font-semibold">Location</div>
            <div class="col-4 text-lg text-700 font-semibold">Register</div>
            <div class="col-4 text-lg text-700 font-semibold">Time</div>
        </div>
        <div class="grid ml-2 mr-2">
            <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.locations"
                    [(ngModel)]="cdSelectedLocation"
                    (onChange)="cdLocationChange($event)"
                    optionLabel="locationName"
                    appendTo="body"
                ></p-dropdown>
            </div>
            <div class="col-4 text-lg text-700 font-semibold">
                <p-dropdown
                    [style]="{
                        height: '40px',
                        width: '100%'
                    }"
                    class="p-dropdown-1"
                    [options]="registers"
                    [(ngModel)]="cdSelectedRegister"
                    optionLabel="registerName"
                    appendTo="body"
                ></p-dropdown>
            </div>
             <div class="col-4 text-lg text-700 font-semibold">
                <p-calendar
                    [(ngModel)]="dropCashTime"
                    [showSeconds]="true"
                    timeOnly="true"
                    showTime="true"
                    (onBlur)="onSelect($event)"
                    [required]="true"

                    hourFormat="12"
                     ></p-calendar>
             </div>
        </div>
    </div>

    <div class="border-round shadow-2 border-1 border-400 mt-3 bg-white">
        <div
            class="flex justify-content-around flex-wrap shadow-2 border-1 border-400"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 4px 4px 0px 0px;
            "
        >
            <div class="flex align-items-center justify-content-center ml-7nm">
                BILLS
            </div>
            <div class="flex align-items-center justify-content-center">
                QUANTITY
            </div>
            <div class="flex align-items-center justify-content-center ml-8">
                TOTAL
            </div>
        </div>

        <p-scrollPanel [style]="{ width: '100%', height: scrollPanelSize() }">
            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="mockDataCD"
                responsiveLayout="scroll"
                rowHover="true"
                [style]="{ margin: '15px' }"
            >

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="text-center">{{ item.bill }}</td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="item.qty"
                                mode="decimal"
                                inputId="minmax-buttons"
                                [min]="0"
                                placeholder="0"
                                (onInput)="itemQtyChangedCD($event, item)"
                                [inputStyle]="{
                                    width: '130px',
                                    'text-align': 'center',
                                    height: '50px'
                                }"
                                [disabled]="
                                    isViewOnly"
                            >
                            </p-inputNumber>
                        </td>
                        <td class="text-center">
                            {{ item.total | currency }}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </p-scrollPanel>
        <div
            class="flex justify-content-end flex-wrap card-container green-container mt-2"
            style="
                background-color: var(--brandColor);
                color: white;
                font-size: 16px;
                font-weight: 900px;
                height: 40px;
                border-radius: 0px 0px 4px 4px;
            "
        >
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 30px"
            >
                TOTAL
            </div>
            <div
                class="flex align-items-center justify-content-center"
                style="padding-right: 50px"
            >
                {{ getTotalQtyCD() | currency }}
            </div>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Add Cash Drops"
                class="ml-2"
                style="width: 130px"
                (click)="postCashDrops()"
            ></button>
        </div>
    </div>
</p-dialog>

<p-dialog header="{{popupHeaderInfo}}"
    [modal]="visibleModal"
    [closable]="true"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '75vw' }"
    [(visible)]="visibleModal"
    [blockScroll]="true"
    [draggable]="false"
    modal="true"
   >

    <div class="flex align-items-start justify-content-center mt-4 mb-4">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 1">
            <app-till-report [filterLocation] = "_filterService.selectedLocation.locationId"
            [filterDate] = "_filterService.singleDateForReconcile"
            [showFilter]="false" *ngIf="visibleModal" ></app-till-report>

        </div>
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 3">
            <div
                class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3"
            >

                <div
                    class="flex align-items-center justify-content-center font-bold text-xl mb-4"
                >Pay-out
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="payoutReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="payoutReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right" class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ payoutReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3">
                <div
                    class="flex align-items-center justify-content-center font-bold text-xl m-4"
                >Pay-In
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="payInReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="payInReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right"
                                class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ payInReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3">
                <div
                    class="flex align-items-center justify-content-center font-bold text-xl m-4"
                >Refunds
                </div>
                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }" [value]="refundReportData" responsiveLayout="scroll"
                    showLoader="true" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Location</th>
                            <th>Cashier Name</th>
                            <th>Reg</th>
                            <th>Date/Time</th>
                            <th>Reason</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.locationName }}</td>
                            <td>{{ item.cashierName }}</td>
                            <td>{{ item.registerName }}</td>
                            <td>{{ item.dated | date : "short" }}</td>
                            <td>{{ item.reason }}</td>
                            <td class="text-right">{{ item.amount | currency }}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="6" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" *ngIf="refundReportData.length > 0">
                        <tr class="summary-line">
                            <td colspan="5" style="text-align: right"
                                class="text-400 font-medium text-xl select-none">
                                Total:
                            </td>
                            <td style="text-align: right" class="text-500 font-medium text-xl select-none">
                                {{ refundReportTotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="flex justify-content-center align-content-center col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3" *ngIf="currentShowingTableNumber == 4">
            <div
                class="col-12 md:col-12 lg:col-12 xl:col-12 xxl:col-12  p-1  mx-3"
            >

                <p-table #dt1 [columns]="" styleClass="p-datatable-striped" id="table-001" [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }" [value]="expectedCashInfoList" responsiveLayout="scroll"
                showLoader="true" rowHover="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Head</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr class="{{item.class}}">
                        <td>{{ item.title }}</td>
                        <td >{{ item.value | currency }}</td>
                        <td>
                            <i *ngIf="item.allowPopup" (click)="showOtherPopup(info)" class="pi pi-eye"></i>

                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" style="text-align: center" >
                    <tr>
                        <td colspan="3" style="text-align: center">
                            No data found
                        </td>
                    </tr>
                </ng-template>

            </p-table>
            </div>
        </div>
    </div>


</p-dialog>

<!--
    //  Starting Cash   Current Day Till + Cash until previous day
    //  System Total    (Sales + Payin + Till - Refund - Payout - Cash Drop) onward from last reset
    //  Expected Cash   (Sales + Payin - Refund - Payout) current day
    //  Register Count  Last Cash Count Amount on register
    //  Manager Count   Manager Counted Amount
-->

<style>
    :host ::ng-deep.p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #eff2f5;
        color: rgba(0, 0, 0, 0.87);
        padding: 1.5rem;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    :host ::ng-deep .p-dialog .p-dialog-content {
        background: #eff2f5;
        color: rgba(0, 0, 0, 0.87);
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    :host ::ng-host .p-dialog {
        border-radius: 4px;
        box-shadow: 0 -1px 5px 5px rgb(0 0 0 / 5%),
            0 5px 5px 5px rgb(0 0 0 / 5%), 0 5px 5px 5px rgb(0 0 0 / 5%);
    }

    :host ::ng-deep .p-dialog .p-dialog-header .p-dialog-title {
        font-weight: 900;
        font-size: 1.75rem;
        color: var(--brandColor);
    }
</style>
