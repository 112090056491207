import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-cash-count-report',
  templateUrl: './cash-count-report.component.html',
  styleUrls: ['./cash-count-report.component.scss']
})
export class CashCountReportComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  topTags: any = null
  dataList: any = null
  cols: any[]

  toTotal = [
    'dollar100Qty', 'dollar50Qty', 'dollar20Qty', 'dollar10Qty',
    'dollar5Qty', 'dollar2Qty', 'dollar1Qty', 'cent25Qty',
    'cent10Qty', 'cent5Qty', 'cent1Qty'
  ]

  _globals = GlobalService

  constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._globals.componentName = 'Cash Count Report'
    this.fillInitData()
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getDataList() {
    const url = environment.InventoryBaseURL + environment.Report

    this._spinner.show()
    this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
      if (res.success) {
        const dt = res.data
        this.topTags = [
          { id: 1, title: 'Total Counted', value: dt.totalCounted },
          { id: 2, title: 'System Total', value: dt.systemTotal },
          { id: 3, title: 'Over/Short', value: dt.totalOverShort },
        ]
        this.dataList = dt.cashCount
        for (let i = 0; i < this.dataList.length; i++)
          this.dataList[i].date = new Date(this.dataList[i].date)

        this.makeCashierList()
      }
      this._spinner.hide()
    })
  }

  makeCashierList() {
    let arr = []
    let obj = this.dataList
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }

    this._abd.cashiers = arr
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  getParas() {

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
    }

    const data = {
      "ReportName": "CashCountReport",
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.getLocationsList(),
        "CashierIds": this.getCashierList(),
        "filterValue": this._filterService.searchText
      }
    }
    return data
  }

  getLocationsList() {
    let locationIds = [];
    // locationIds.push(this._abd.locations[0].locationId) // to be deleted later
    let sel = this._filterService.selectedLocation
    if (!sel) return locationIds

    sel = (typeof sel != 'undefined' && sel instanceof Array) ? sel : [sel];
    sel.map(function (value: { locationId: { toString: () => string; }; }) {
      locationIds.push(value.locationId.toString())
    })
    return locationIds
  }

  fillInitData() {
    this.cols = [
      { field: 'locationName', header: 'Location' },
      { field: 'cashierName', header: 'Cashier Name' },
      { field: 'registerName', header: 'Reg' },
      { field: 'date', header: 'Date/Time' },
      { field: 'dollar100Qty', header: '$100' },
      { field: 'dollar50Qty', header: '$50' },
      { field: 'dollar20Qty', header: '$20' },
      { field: 'dollar10Qty', header: '$10' },
      { field: 'dollar5Qty', header: '$5' },
      { field: 'dollar2Qty', header: '$2' },
      { field: 'dollar1Qty', header: '$1' },
      { field: 'cent25Qty', header: '25 cents (Quarters)' },
      { field: 'cent10Qty', header: '10 cents (Dimes)' },
      { field: 'cent5Qty', header: '5 cents (Nickels)' },
      { field: 'cent1Qty', header: '1 cents (Penny)' },
      { field: 'total', header: 'Total Count' },
      { field: 'systemCount', header: 'System Count' },
      { field: 'overShort', header: 'Over/Short' },
      { field: 'lastCount', header: 'Last Count' },
    ]

    this.topTags = [
      { id: 1, title: 'Total Counted', value: 0 },
      { id: 2, title: 'System Total', value: 0 },
      { id: 3, title: 'Over/Short', value: 0 },
    ]
  }

  calculateSummaryTotal(what) {
    try {
      const r = this.topTags.find(f => f.id == what)
      return r.value
    }
    catch { return 0 }
  }

  getOverShortColor(tag) {
    if (tag.id !== 3) return ""
    if (tag.id == 3 && tag.value < 0) return "background: var(--red-400); color: white;"
    else if (tag.id == 3 && tag.value > 0) return "background: var(--brandColor); color: white;"
    else return ""
  }

  calcSummaryTotals(what) {
    try {
      return this.dataList.reduce((accumulator, current) => accumulator + Number(current[what]), 0)
    } catch { }
  }

  callbackFuncFromFilter(e) {
    this.getDataList()
  }
  filterButtonPressed() {
    this.getDataList()
  }

  exportExcel() {
    let header = [
      'Location', 'Cashier Name', 'Reg', 'Date/Time',
      '$100', '$50', '$20', '$10', '$5', '$2', '$1',
      '25 cents (Quarters)', '10 cents (Dimes)',
      '5 cents (Nickels)', '1 cent (Penny)',
      'Total Count', 'System Count', 'Over/Short', 'Last Count'
    ]

    let ws = []
    let tmp = []
    const xd = this.dataList
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].locationName)
      inner.push(xd[j].cashierName)
      inner.push(xd[j].registerName)
      inner.push(formatDate(new Date(xd[j].date), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].dollar100Qty)
      inner.push(xd[j].dollar50Qty)
      inner.push(xd[j].dollar20Qty)
      inner.push(xd[j].dollar10Qty)
      inner.push(xd[j].dollar5Qty)
      inner.push(xd[j].dollar2Qty)
      inner.push(xd[j].dollar1Qty)
      inner.push(xd[j].cent25Qty)
      inner.push(xd[j].cent10Qty)
      inner.push(xd[j].cent5Qty)
      inner.push(xd[j].cent1Qty)
      inner.push('$ ' + xd[j].total.toFixed(2))
      inner.push('$ ' + xd[j].systemCount.toFixed(2))
      inner.push('$ ' + xd[j].overShort.toFixed(2))
      inner.push('$ ' + xd[j].lastCount.toFixed(2))
      tmp.push(inner)
    }

    ws.push(tmp)

    const workbook = {
      Sheets: {
        'cash-count': XLSX.utils.aoa_to_sheet(ws[0]),
      },
      SheetNames: ['cash-count']
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filename = 'cash-count-summary-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
    FileSaver.saveAs(data, filename);

  }

  exportPdf() {
    let cols = [
      { dataKey: "locationName", title: "Location", type: 'string' },
      { dataKey: "cashierName", title: "Cashier Name", type: 'string' },
      { dataKey: "registerName", title: "Reg", type: 'string' },
      { dataKey: "date", title: "Date/Time", type: 'date' },
      { dataKey: "dollar100Qty", title: "$100", type: 'string' },
      { dataKey: "dollar50Qty", title: "$50", type: 'string' },
      { dataKey: "dollar20Qty", title: "$20", type: 'string' },
      { dataKey: "dollar10Qty", title: "$10", type: 'string' },
      { dataKey: "dollar5Qty", title: "$5", type: 'string' },
      { dataKey: "dollar2Qty", title: "$2", type: 'string' },
      { dataKey: "dollar1Qty", title: "$1", type: 'string' },
      { dataKey: "cent25Qty", title: "25 cents (Quarters)", type: 'string' },
      { dataKey: "cent10Qty", title: "10 cents (Dimes)", type: 'string' },
      { dataKey: "cent5Qty", title: "5 cents (Nickels)", type: 'string' },
      { dataKey: "cent1Qty", title: "1 cent (Penny)", type: 'string' },
      { dataKey: "total", title: "Total Count", type: 'currency' },
      { dataKey: "systemCount", title: "System Count", type: 'currency' },
      { dataKey: "overShort", title: "Over/Short", type: 'currency' },
      { dataKey: "lastCount", title: "Last Count", type: 'currency' },
    ]

    const data = this._abd.parseForExport(this.dataList, cols)

    const d = {
      locationName: '',
      cashierName: '',
      registerName: '',
      date: 'TOTALS:',
      dollar100Qty: this.calcSummaryTotals(this.toTotal[0]),
      dollar50Qty: this.calcSummaryTotals(this.toTotal[1]),
      dollar20Qty: this.calcSummaryTotals(this.toTotal[2]),
      dollar10Qty: this.calcSummaryTotals(this.toTotal[3]),
      dollar5Qty: this.calcSummaryTotals(this.toTotal[4]),
      dollar2Qty: this.calcSummaryTotals(this.toTotal[5]),
      dollar1Qty: this.calcSummaryTotals(this.toTotal[6]),
      cent25Qty: this.calcSummaryTotals(this.toTotal[7]),
      cent10Qty: this.calcSummaryTotals(this.toTotal[8]),
      cent5Qty: this.calcSummaryTotals(this.toTotal[9]),
      cent1Qty: this.calcSummaryTotals(this.toTotal[10]),
    }

    data.push(d)

    const doc = new jsPDF('l', 'mm', 'a3');
    doc['autoTable'](cols, data)
    const filename = 'cash-count-report-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
    doc.save(filename);
  }

}
