<div class="flex justify-content-start" *ngIf="_signup.loyaltyProgramPrice > 0">
    <div class="flex align-items-center justify-content-start  text-xl">
        Would you like to subscribe our Loyalty Program for <span class="loyalty-price mx-2">
            {{_signup.loyaltyProgramPrice|currency}}/month?</span>
    </div>
</div>
<div class="flex align-items-start justify-content-start mt-4 mb-4 gap-5" *ngIf="_signup.loyaltyProgramPrice > 0">
    <div class="col-12 md:col-6 lg:col-6 xl:col-5 xxl:col-5 ecommerceOptions align-items-center justify-content-start cursor-pointer p-4 "
        *ngFor="let ind of _signup.loyaltyProgramAppBaseData" [ngClass]="{'selected-option': ind.selected}"
        (click)="selectLoyaltyProgramOption(ind)">
        <div>
            <div class="flex align-items-center justify-content-center w-full text-xl text-900 my-2
                cursor-pointer">
                {{ ind.name }}
            </div>
            <div class="flex align-items-center justify-content-center w-full text-lg text-500 cursor-pointer">
                {{ ind.detail }}
            </div>
        </div>
    </div>
</div>
<div class="flex" *ngIf="_signup.loyaltyProgramPrice == 0">
    <div
        class="col-12">
        <div>
            <div class="flex align-items-center justify-content-center w-full text-xl text-900 my-2
                cursor-pointer">
                Basic loyalty program is part of your subscription.
            </div>
            <div class="flex align-items-center justify-content-center w-full text-lg text-500 cursor-pointer">
                <!-- {{ ind.detail }} -->
            </div>
        </div>
    </div>
</div>


<div class="flex justify-content-between flex-wrap card-container purple-container mt-6" *ngIf="showFooter">
    <div class="flex align-items-center justify-content-center">
        <p-button label="Back" icon="pi pi-arrow-left" (onClick)="backClicked()" class="signup-back-button"></p-button>
    </div>
    <div class="flex align-items-center justify-content-center">
        <p-button label="Continue" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextClicked()"></p-button>
    </div>
</div>
