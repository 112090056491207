<div>
    <div>
        <div>
            <label class="control-label"
                ><strong
                    >Customer Group
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <!-- (ngSubmit)="editform.valid && saveFn()" -->
            <form
                [formGroup]="editform"
                (ngSubmit)="editform.valid && saveFn()"
                autocomplete="off"
            >
                <app-text-input
                    formControlName="customerGroupName"
                    [label]=""
                    [name]="'Customer Group Name'"
                    [description]="
                        'Please enter a Customer Group Name'
                    "
                    (input)="
                        _customerService.activeItem.title =
                            $event.target.value
                    "
                ></app-text-input>

                <br />

                <div
                    class="flex justify-content-end flex-wrap card-container green-container mt-3"
                >
                    <div class="flex align-items-center justify-content-center">
                        <button
                            *ngIf="this.whatAction == 'Edit'"
                            pButton
                            pRipple
                            type="button"
                            (click)="saveFn()"
                            [label]="whatAction"
                        ></button>

                        <button
                            *ngIf="this.whatAction == 'Save'"
                            pButton
                            pRipple
                            type="submit"
                            [disabled]="
                                (!editform.valid || _customerService.isViewOnly) &&
                                this.whatAction !== 'Save'
                            "
                            [label]="whatAction"
                        ></button>
                        <!-- [disabled]="!editform.valid || _customerService.isViewOnly" -->
                    </div>
                    <div
                        class="flex align-items-center justify-content-center ml-2"
                    >
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Cancel"
                            class="p-button-outlined p-button-danger"
                            (click)="cancelButtonClick()"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
