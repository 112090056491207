import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-invoice-print-preview',
  templateUrl: './invoice-print-preview.component.html',
  styleUrls: ['./invoice-print-preview.component.scss']
})
export class InvoicePrintPreviewComponent implements OnInit {
  activeItem:any=''

  constructor(
    public _reportService: ReportsService,
    protected _sanitizer: DomSanitizer,
    private http: HttpClient

  ) { }

  ngOnInit(): void {
    this.activeItem = this._reportService.selectedInvoiceDetails.receiptRecords
  }

  getRawData(src) {
    const httpOptions = {
      headers: new HttpHeaders({ 
      "Access-Control-Allow-Origin" : "*", 
      })}; 
    this.http.get(src, httpOptions).subscribe(
      data => {
        
        // console.log(data);
        return data;
      }); 

    
  }

}
