
<div class="flex justify-content-center my-2">
    <p class="justify-content-center" *ngIf="anyPlanFreeFound">Based on your subscription plan selection, included items
        are "Free". However, you may
        upgrade to the available plans according to your business needs.
    </p>
</div>
<div class="flex justify-content-center " *ngIf="_signup.eComSubscriptionPlans?.length">
    <div
        class="xxl:col-5 xl:col-5 lg:col-5 md:col-5 col-12 h-screen mx-1 flex justify-content-center align-items-start"
        *ngFor="let item of _signup.eComSubscriptionPlans"
    >
        <div class="conn-card border-none">
            <div class="e-plan-name"
                >
                <label>
                    {{ item.title }}
                </label>
            </div>
            <div class="e-plan-price"
            >
                <label class="e-plan-price-label">
                    {{ item.price | currency }}
                </label>
                /<label class="e-plan-price--perid-label">month </label>
            </div>
            <div class="flex justify-content-center" *ngIf="item.includedInPackage ">
                <div class="flex">
                    <small><span>(Included in Plan)</span></small>
                </div>
            </div>
            <!-- [disabled]="disableSelectButton(item)" -->
            <div class="e-plan-action-btn flex  justify-content-center ">

                <div
                    class="ecommerce-option cursor-pointer"
                  [ngClass]="{'selected-eCommerce-plan':item.selected, 'unselected-eCommerce-plan': !item.selected}"
                    (click)="selectPlan( item)"


                >
                    <span *ngIf="!item.selected">Select</span>
                    <span *ngIf="item.selected">Selected</span>
                </div>

            </div>
            <div class="hd-cat-description">
                <div *ngFor="let feature of item.features">
                    <label class="e-com-bullet">
                        <i class="pi pi-check"></i>
                    </label>
                    <label>
                        {{ feature.data }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex flex-wrap justify-content-between card-container purple-container mt-6"
    >

    <div class="flex align-items-center justify-content-center" >
        <span *ngIf="showBackButton">
              <p-button *ngIf="showFooter" label="Back" class="signup-back-button" icon="pi pi-arrow-left"
                  (onClick)="backStep()"></p-button>
        </span>
        <span *ngIf="!showBackButton">

        </span>
    </div>
    <div class="flex align-items-center justify-content-center">
        <p-button
         *ngIf="showFooter"
            label="Continue"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextStep()"
        ></p-button>
    </div>

</div>

<p-dialog
    [modal]="true"
    header=" "
    [closable]="true"
    [style]="{ width: '50vw' }"
    [(visible)]="showDomainDialog"
    *ngIf="showDomainDialog"
    [draggable]="false"
>
    <div class="flex justify-content-center">
        <div
            class="flex align-items-center justify-content-center font-bold text-xl"
        >
            Would you like to use a custom domain for your website/online store?
        </div>
    </div>
    <div class="flex align-items-start justify-content-center mt-4 mb-4">
        <div class="col-12 md:col-6 lg:col-5 xl:col-5 xxl:col-5  justify-content-center cursor-pointer ecommerceOptions align-items-center justify-content-center cursor-pointer p-1  mx-3"
            *ngFor="let ind of _signup.selectedWebsiteAndOnlineStorePlan.domainPlan"
            [ngClass]="{'selected-option':ind.selected}"
            (click)="domainPlanClicked(ind)"
          >
             <div
                 class="flex align-items-center justify-content-center w-full  text-lg text-900 mt-3 cursor-pointer">
                 {{ ind.description }}
             </div>
             <div class="flex align-items-center justify-content-center w-full text-600 mt-2 cursor-pointer">
                 i.e., {{ ind.title }}
             </div>
             <div
                 class="flex align-items-center justify-content-center w-full text-blue-700 mt-2 font-bold text-xl cursor-pointer mt-3 mb-3">
                 {{ ind.monthlyPrice | currency }}<span *ngIf="ind.monthlyPrice > 0">/month</span>
             </div>
        </div>
    </div>

    <div class="flex align-items-center justify-content-center">
        <p-button
              label="Continue"
              icon="pi pi-arrow-right"
              iconPos="right"
              (click)="hideCustomDomainPopup()"
        ></p-button>
    </div>

</p-dialog>
