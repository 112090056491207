import { JsonPipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Injectable } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { retry } from 'rxjs';
import { AuthorizePaymentTokenModel } from 'src/app/_models/authorize.net/authorize-net-payment-token-modal';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { environment } from 'src/environments/environment';

declare const Stripe;

export interface iframeInterface {
  token: string;
  messages: Messages;
}

export interface subscInterface {
  refId: string;
  messages: Messages;
}

export interface Messages {
  resultCode: string;
  message: Message[];
}

export interface Message {
  code: string;
  text: string;
}

export interface BillTo {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface TransactionData {
  accountType: string;
  accountNumber: string;
  transId: string;
  responseCode: string;
  authorization: string;
  merchantName: string;
  billTo: BillTo;
  totalAmount: string;
  dateTime: string;
}

// export interface CreatePaymentProfileResponse {
//   success: string;
//   message: string;
// }

// export interface paymentResponse {
//   resultCode: string;
//   messageCode: string;
//   transactionData: TransactionData;
//   createPaymentProfileResponse: CreatePaymentProfileResponse;
// }


@Component({
  selector: 'app-signup-payment-step2',
  templateUrl: './signup-payment-step2.component.html',
  styleUrls: ['./signup-payment-step2.component.scss']
})
export class SignupPaymentStep2Component  {
    
    
    // -----------------
    // Stripe Payment 
    // --------------------
    
    
    title = 'pay';
    posting = false;
    @ViewChild('myFormPost', { static: false })
    myFormPost!: ElementRef;
    token: any = null
    paymentFormURL: any;
    customerProfileId: any
    _globals = GlobalService
    private httpClient: HttpClient;
    constructor(
        handler: HttpBackend,
        private _signup: SignupService,
        private _connMessageService: ConnMessageService    ) {

    this.httpClient = new HttpClient(handler)
    this.sendDataToCentralHub({}, true);
  }

  ngOnInit(): void {
    this.sendDataToCentralHub({}, true);
  }

    
    sendDataToCentralHub(responseDataFromAuthorize: any , authorizeResponseStatus:boolean) {
        try {
            let websiteDomain : string = "";
            websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
            var n = websiteDomain.lastIndexOf('/');
            var result = websiteDomain.substring(n + 1);
    
            if (result == "") {
                websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);
    
            }
            let hardwarePackagesId = [];
            let hardwarePkg = [];
            let SubscribedFeatures = [];

            let taxRate = 0;
            if (this._signup.checkoutPageCalcVars.taxRate > 0) {
                this._signup.checkoutPageCalcVars.taxRate = 8.25;
                taxRate = ((this._signup.checkoutPageCalcVars.taxRate /= 100))
            }



            if (this._signup.selectedHardware?.length > 0){
                this._signup.selectedHardware.map(function(value){
                    hardwarePackagesId.push(value.id)

                    let taxAmount = (value.subtotal * taxRate)
                    taxAmount = taxAmount * value.subtotal
                    let addonsArray = [];
                    let addonPrice = 0;
                    if(value.addons.length > 0){
                        value.addons.map(function(a){
                            if (a.addonAdded == "yes"){
                                addonPrice = addonPrice + a.singleAddonTotalPrice;
                                addonsArray.push({
                                    "orderNo": a.orderNo,
                                    "title": a.title,
                                    "price": a.price,
                                    "description": a.description,
                                    "recommended": a.recommended,
                                    "limits": a.limits,
                                    "featureHeading": a.featureHeading,
                                    "features": a.features,
                                    "prices": a.prices,
                                    "image": a.image,
                                    "isActive": a.isActive,
                                    "id": a.id,
                                    "createdDate": a.createdDate,
                                    "checked": a.checked,
                                    "qty": value.selectedQty,
                                    "totalPrice": a.singleAddonTotalPrice.toFixed(2).toString()
                                })
                            }
                        })
                    }


                    // formula changed
                    let hardWareTax = 0;
                    hardWareTax = (value.withoutAddonPrice + addonPrice) * taxRate;
                    let subtotal = (value.withoutAddonPrice + addonPrice)

                    hardwarePkg.push({
                        "packageId": value.id,
                        "qty": value.selectedQty,
                        "pricePerPackage": value.price,
                        "subTotalCost": subtotal.toFixed(2).toString(),
                        "packageType": value.packageType,
                        "subscriptionInvoiceAddons": addonsArray,
                        "discountValue": 0,
                        "taxValue": taxRate * 100,
                        "discountAmount": 0,
                        "taxAmount": hardWareTax.toFixed(2).toString(),
                        "isDiscountPercent": false,
                        "isTaxPercent": (taxRate > 0 ? true: false),
                        "totalPrice": (subtotal + hardWareTax).toFixed(2).toString(),
                    })
                })
            }


            if (this._signup.checkoutPageCalcVars.additionalLocationsCost > 0){
                let taxAmount = (this._signup.checkoutPageCalcVars.additionalLocationsCost * taxRate )

                SubscribedFeatures.push({
                    "type": this._signup.selectedPlan.featureData.filter(f => f.typeString == "AdditionalLocationPlus1Reg")[0].type,
                    "title": "AdditionalLocationPlus1Reg",
                    "value": this._signup.checkoutPageCalcVars.additionalLocationsCost.toFixed(2).toString(),
                    "price": this._signup.checkoutPageCalcVars.additionalLocationsCost.toFixed(2).toString(),
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": true,
                    "isCustomized": false,
                    "isActive": true,
                    "qty": this._signup.checkoutPageCalcVars.locationsQty - 1,
                    "pricePerPackage": this._signup.selectedPlan.featureData.filter(f => f.typeString == "AdditionalLocationPlus1Reg")[0].price,
                    "discountValue": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.additionalLocationsCost + taxAmount).toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.additionalLocationsCost.toFixed(2).toString()
                })

                this._signup.outerLocationQty = 1;
                this._signup.outerRegisterQty = 1;
            }

            if (this._signup.checkoutPageCalcVars.additionalRegistersCost > 0) {
                let taxAmount = (this._signup.checkoutPageCalcVars.additionalRegistersCost * taxRate)
                SubscribedFeatures.push({
                    "type": this._signup.selectedPlan.featureData.filter(f => f.typeString == "AdditionalRegister")[0].type,
                    "title": "AdditionalRegister",
                    "value": this._signup.checkoutPageCalcVars.additionalRegistersCost.toFixed(2).toString(),
                    "price": this._signup.checkoutPageCalcVars.additionalRegistersCost.toFixed(2).toString(),
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": true,
                    "isCustomized": false,
                    "isActive": true,
                    "qty": this._signup.checkoutPageCalcVars.registersQty - this._signup.checkoutPageCalcVars.locationsQty,
                    "pricePerPackage": this._signup.selectedPlan.featureData.filter(f => f.typeString == "AdditionalRegister")[0].price,
                    "discountValue": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.additionalRegistersCost + taxAmount).toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.additionalRegistersCost.toFixed(2).toString()
                })
            }


            let wooCommercePlanPkg = []
            if (this._signup.checkoutPageCalcVars.staticWebsite > 0 || (this._signup.eComSubscriptionPlans.filter(f => f.title == 'Static Website')[0].includedInPackage &&
                this._signup.eComSubscriptionPlans.filter(f => f.title == 'Static Website')[0].selected)) {
                let taxAmount = this._signup.checkoutPageCalcVars.staticWebsite * taxRate

                wooCommercePlanPkg.push({
                    "packageId": this._signup.selectedWebsiteAndOnlineStorePlan.id,
                    "qty": 1,
                    "pricePerPackage": this._signup.checkoutPageCalcVars.staticWebsite.toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.staticWebsite.toFixed(2).toString(),
                    "packageType": 0,
                    "discountValue": 0,
                    "taxValue": taxRate * 100,
                    "discountAmount": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.staticWebsite + taxAmount).toFixed(2).toString()
                })
            }

            if (this._signup.checkoutPageCalcVars.ecomCost > 0 || (this._signup.eComSubscriptionPlans.length > 0 && this._signup.eComSubscriptionPlans.filter(f => f.title == 'Basic eCommerce')[0].includedInPackage &&
                this._signup.eComSubscriptionPlans.filter(f => f.title == 'Basic eCommerce')[0].selected)) {
                let taxAmount = this._signup.checkoutPageCalcVars.ecomCost * taxRate

                wooCommercePlanPkg.push({
                    "packageId": this._signup.selectedWebsiteAndOnlineStorePlan.id,
                    "qty": 1,
                    "pricePerPackage": this._signup.checkoutPageCalcVars.ecomCost.toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.ecomCost.toFixed(2).toString() ,
                    "packageType": 0,
                    "discountValue": 0,
                    "taxValue": taxRate * 100,
                    "discountAmount": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.ecomCost + taxAmount).toFixed(2).toString()
                })
            }

            let domainPlanPkg  = [];
            if (this._signup.checkoutPageCalcVars.customDomainCost > 0) {
                let taxAmount = (this._signup.checkoutPageCalcVars.customDomainCost * taxRate)
                domainPlanPkg.push({
                    "packageId": this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan.id,
                    "qty": 1,
                    "pricePerPackage": this._signup.checkoutPageCalcVars.customDomainCost.toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.customDomainCost.toFixed(2).toString(),
                    "packageType": 0,
                    "discountValue": 0,
                    "taxValue": taxRate * 100,
                    "discountAmount": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.customDomainCost + taxAmount).toFixed(2).toString()
                })
            }

            if (this._signup.checkoutPageCalcVars.loyaltyProgram > 0 || this._signup.selectedPlan.featureData.filter(f => f.typeString == 'LoyaltyProgram')[0].value == 'Included') {
                let taxAmount = (this._signup.checkoutPageCalcVars.loyaltyProgram * taxRate)
                SubscribedFeatures.push({
                    "type": this._signup.selectedPlan.featureData.filter(f => f.typeString == "LoyaltyProgram")[0].type,
                    "title": "LoyaltyProgram",
                    "value": this._signup.checkoutPageCalcVars.loyaltyProgram.toFixed(2).toString(),
                    "price": this._signup.checkoutPageCalcVars.loyaltyProgram.toFixed(2).toString(),
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": true,
                    "isCustomized": false,
                    "isActive": true,
                    "qty": 1,
                    "pricePerPackage": this._signup.selectedPlan.featureData.filter(f => f.typeString == 'LoyaltyProgram')[0].price,
                    "discountValue": 0,
                    "taxAmount": taxAmount.toFixed(2).toString(),
                    "isDiscountPercent": false,
                    "isTaxPercent": (taxRate > 0 ? true : false),
                    "totalPrice": (this._signup.checkoutPageCalcVars.loyaltyProgram + taxAmount).toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.loyaltyProgram.toFixed(2).toString()
                })
            }

            let taxAmount = (this._signup.checkoutPageCalcVars.subscriptionPlanPrice * taxRate)
            let TransactionDetails = {
                "accountType": "",
                "accountNumber": "",
                "transId": "",
                "responseCode": "1",
                "paymentMethod":"Stripe",
                "authorization": "",
                "merchantName": "Connect-360",
                "billTo": {
                    "phoneNumber": this._signup.businessInfo?.phone,
                    "firstName": "",
                    "lastName": "",
                    "address": this._signup.businessInfo?.address,
                    "city": this._signup.businessInfo?.city,
                    "state": this._signup.businessInfo?.state,
                    "zip": this._signup.businessInfo?.zip,
                    "country": "US"
                },
                "totalAmount": this._signup.checkoutPageCalcVars.gTotal,
                "dateTime": new Date()
            }
            // if (!authorizeResponseStatus){
            //     TransactionDetails = responseDataFromAuthorize
            // }

            let CCCustomerProfile = {
                "TransactionId": "",
                "StartDate": "2022-10-18",
                "IntervalLength": (this._signup.selectedPlanTimeframe == "BilledMonthly" ? "1" : "12"),
                "Amount": "0.00",
                "TrialAmount": 0,
                "SubTotal": 0,
                "Tax": 0
            };
            if (!authorizeResponseStatus) {
                const d = new Date(responseDataFromAuthorize.dateTime);
                let totalAmount = this._signup.checkoutPageCalcVars.subTotal -  this._signup.checkoutPageCalcVars.hardwareCost;
                let taxAmount = (totalAmount * taxRate)
                 CCCustomerProfile = {
                    "TransactionId": responseDataFromAuthorize.transId,
                    "StartDate": d.toLocaleDateString("en-US"),
                    "IntervalLength": (this._signup.selectedPlanTimeframe == "BilledMonthly" ? "1" : "12"),
                    "Amount": (totalAmount + taxAmount).toFixed(2),
                    "TrialAmount": 0,
                    "SubTotal": totalAmount,
                    "Tax": taxAmount
                }
            }           
            let couponObj:any ;
            if(this._signup.couponCodeData?.couponCodes !== undefined){
                 couponObj = {
              discountValueType:this._signup.couponCodeData?.discountValueType,
              id:this._signup.couponCodeData?.id,
              couponCodes:this._signup.couponCodeData?.couponCodes ,
              discountName:this._signup.couponCodeData?.discountName ,
              discountValue:this._signup.couponCodeDiscount
            }
          } else{
              couponObj = {}
          }
            let data = {
                "businessId": this._signup.businessInfo.businessId,
                "NoOfLocations": this._signup.checkoutPageCalcVars.locationsQty,
                "PerNoOfRegister": 1,
                "NoOfRegisters": this._signup.checkoutPageCalcVars.registersQty,
                "userID": this._signup.businessInfo.userID,
                "SaveInfo": authorizeResponseStatus,
                "subscription": {
                    "TransactionDetails": TransactionDetails,
                    "hardwarePkgId": hardwarePackagesId,
                    "totalHardwarePkgCost": this._signup.checkoutPageCalcVars.hardwareCost.toFixed(2).toString(),
                    "planCost": this._signup.checkoutPageCalcVars.subscriptionPlanPrice.toFixed(2).toString(),
                    "planId": this._signup.selectedPlan.id,
                    "totalCost": this._signup.checkoutPageCalcVars.gTotal.toFixed(2).toString(),
                    "subscriptionPlanPkg": [
                        {
                            "packageId": this._signup.selectedPlan.id,
                            "qty": 1,
                            "pricePerPackage": this._signup.checkoutPageCalcVars.planCost,
                            "subTotalCost": this._signup.checkoutPageCalcVars.subscriptionPlanPrice,
                            "packageType": 0,
                            "discountValue": 0,
                            "taxValue": taxRate * 100,
                            "discountAmount": 0,
                            "taxAmount": taxAmount.toFixed(2).toString(),
                            "isDiscountPercent": false,
                            "isTaxPercent": (taxRate > 0 ? true : false),
                            "totalPrice": (this._signup.checkoutPageCalcVars.subscriptionPlanPrice + taxAmount).toFixed(2).toString()
                        }
                    ],

                    "WooCommercePlanPkg": wooCommercePlanPkg,
                    "DomainPlanPkg": domainPlanPkg ,
                    "hardwarePkg": hardwarePkg,
                    "TotalTaxAmount": this._signup.checkoutPageCalcVars.tax.toFixed(2).toString(),
                    "subTotalCost": this._signup.checkoutPageCalcVars.subTotal.toFixed(2).toString(),
                    "TotalDiscountAmount": this._signup.couponCodeDiscount,
                    "TotalTaxValue": taxRate,
                    "TotalDiscountValue": 0,
                    "IsTotalTaxPercente": true,
                    "IsTotalDiscountPercent": false,
                    "SubscribedFeatures": SubscribedFeatures
                },
                CCCustomerProfile: CCCustomerProfile,
                DiscountDet:couponObj,
                CouponCode:this._signup.couponCodeData?.couponCodes || null,
                SuccessUrl:`${websiteDomain}/#/setup/subscription-payment-success-merchant`,
                FailureUrl:`${websiteDomain}/#/setup/signup`,
            }
            this._signup.saveUserSignupInfo(data).pipe(retry(3)).subscribe(res => {
                
                if (res.success && res.responseStatusCode == 903){
                //   if(res.success){
                
                    this._signup.saveUserSignupInfoForStripe(data).pipe(retry(3)).subscribe(data => {
                //    if(data.success){
                    let session = {
                        publicKey:data.publicKey,
                        sessionId:data.sessionId
                    }
                    // sessionStorage.setItem('paymentMethod','stripe')
                       this.redirectToCheckout(session)
                //    }
                    })
                //   }
                    // if (!authorizeResponseStatus){
                        // this._signup.loadInit()
                        // sessionStorage.clear();
                        // sessionStorage.setItem('payment-successfully', '1');
                    //     // this.route.navigate(['/setup/subscription-payment-success'])
                    //     this.route.navigate(['/setup/subscription-payment-success-merchant'])
                    // }

                }
                // else{
                //     if (authorizeResponseStatus){
                //         this.sendDataToCentralHub({}, true);
                //     }
                //     else{
                //         this._connMessageService.showToastMessage(res.message, 'error')
                //     }

                // }
            },error=>{
                if (authorizeResponseStatus) {
                    // this.sendDataToCentralHub({}, true);
                }
                else {
                    this._connMessageService.showToastMessage(error.message, 'error')
                }
            })


        } catch (error) {
            this.sendDataToCentralHub({}, true);
        }
    }



  redirectToCheckout(session: any) {
  let url = window.location.origin + '/#/setup/signup';
  localStorage.setItem('goto',url)
    const stripe = Stripe(session.publicKey);
    stripe?.redirectToCheckout({
      sessionId: session.sessionId,
    });
  }



}
