import { Department } from 'src/app/_models/department';
import { GlobalService } from 'src/app/_services/global.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { DepartmentService } from 'src/app/_services/department.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/_services/products.service';
import { HttpParams } from '@angular/common/http';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-new-edit-department',
  templateUrl: './new-edit-department.component.html',
  styleUrls: ['./new-edit-department.component.scss']
})
export class NewEditDepartmentComponent implements OnInit {
  whatAction = ''
  privateSelected: any = null
  name: any = ''
  editform: FormGroup
  validationErrors: string[] = []

  _globals = GlobalService
  @Input() isNew: boolean = true
  @Output() refreshFn = new EventEmitter<any>()

  constructor(
    public _abd: AppBaseDataService,
    private _fb: FormBuilder,
    public _departmentService: DepartmentService,
    private _spinner: NgxSpinnerService,
    public _productService: ProductsService,
    private _connMessageService: ConnMessageService,
  ) { }

  ngOnInit(): void {
    if (this.isNew || !this._departmentService.isViewOnly) {
      this.whatAction='Save'
      if(this.isNew) this._departmentService.activeItem = this.initModel()
    } else {
      this.whatAction='Edit'
    }
  }

  ngAfterContentInit() {
    this.initForm()
  }

  initModel(): Department {
    const model = {
      id: null,
      departmentName: null,
      createdDate: null,
      isActive: true,
      lastEdited: null,
      addedBy: null,
      locationId: null,
      isTaxable: null,
      tax1: null,
      tax2: null,
      departmentId: null,
      partitionKey: null,
    }
    return model
  }

  initForm() {
    this.editform = this._fb.group({
      departmentName: [{value: this._departmentService.activeItem.departmentName, disabled: this._departmentService.isViewOnly}, Validators.required]
    })
  }

  onSelectItem(e) {
    this.isNew = false
    this.privateSelected = JSON.parse(JSON.stringify(e))
    this.editform.patchValue({ departmentName: e.departmentName })
  }

  saveFn() {
    if(this.whatAction=='Edit') {
      this._departmentService.isViewOnly = false
      this.whatAction='Save'
      this.editform.controls['departmentName'].enable()
      return
    }

    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    if (this.isNew) {
      this._departmentService.activeItem.addedBy = this._globals.userInfo.userID
      this._departmentService.activeItem.id = null
    }

    this._abd.showSpinner()
    this._departmentService.createUpdateDepartment(this._departmentService.activeItem).subscribe(res => {
      if (res.success) {
        // this._connMessageService.showToastMessage(`Department saved successfully!`, 'success')
        this.cancelButtonClick()
        this._abd.getAllDepartments()
        this.refreshFn.emit(true)
        // this.refreshParentData()
        this._abd.hideSpinner()
      }
    })
  }

  refreshParentData() {
    this._abd.loadInit().subscribe(res => {
      if (res.success) {
        this._abd.initDepartments(this._abd.initActDepartments())
        this._abd.selectedDepartment = this._abd.actDepartments.filter(f => 
          f.departmentName.toLowerCase() == this.editform.get('departmentName').value.toLowerCase())[0]
      }
    })
  }

  cancelButtonClick() {
    this._departmentService.showNewEdit = false
    this._departmentService.showNewNewEdit = false
    this._productService.isNewDepartment = false
    this._departmentService.isViewOnly=false
  }


}
