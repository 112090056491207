import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-sales-history',
  templateUrl: './customer-sales-history.component.html',
  styleUrls: ['./customer-sales-history.component.scss']
})
export class CustomerSalesHistoryComponent implements OnInit {
  page = 0
  rows = 10
  totalRecords = 0

  dataList: any = []

  _globals = GlobalService

  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Sales History'
    this.getDataList()
  }

  getDataList() {
    this._spinner.show()
    try {
      const id = this._customerService.activeItem.custID
      this._customerService.searchCustomerHistoryByCustomerId(id, this.page, this.rows).subscribe(res => {
        if (res.success) {
          this.dataList = res.data
          this.totalRecords = res.totalRecords
          this._spinner.hide()
          //console.log('CustomerSalesHistoryComponent', this.dataList)
        }
      })
    } catch { this._spinner.hide() }
  }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    //console.log(e)
    this.getDataList()
  }

}
