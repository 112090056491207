import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { BaseService } from 'src/app/_services/base.service';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-partial-invoice-order-detail',
  templateUrl: './partial-invoice-order-detail.component.html',
  styleUrls: ['./partial-invoice-order-detail.component.scss']
})
export class PartialInvoiceOrderDetailComponent implements OnInit {
  model: any = []
  source: any = []

  constructor( private _confirmationService: ConfirmationService,
    private _bs: BaseService,
    public _reportService: ReportsService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.source = this._reportService.selectedInvoiceDetails
   
    // this.model.push({ label: 'Invoice Date', value: source.createdDate })
  }



}
