import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerGroup } from 'src/app/_models/customer-group';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';
import { CustomerGroupComponent } from '../customer-group.component';

@Component({
  selector: 'app-new-edit-customer-group',
  templateUrl: './new-edit-customer-group.component.html',
  styleUrls: ['./new-edit-customer-group.component.scss']
})
export class NewEditCustomerGroupComponent implements OnInit {

  whatAction = ''
  privateSelected: any = null
  name: any = ''
  editform: FormGroup
  validationErrors: string[] = []
  _globals = GlobalService
  @Input() isNew: boolean = true
  @ViewChild(CustomerGroupComponent) __parent!: CustomerGroupComponent
  @Output() loadFreshDataEvent = new EventEmitter();

  constructor(
    public _abd: AppBaseDataService,
    public _fb: FormBuilder,
    public _customerService: CustomersService,
    public _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
  ) { }

  ngOnInit(): void {
    // this.initModel()
    // console.log("Is new variable",this.isNew)
    if (this.isNew || !this._customerService.isViewOnly) {
      this.whatAction='Save'
      if(this.isNew) {
        this._customerService.activeItem = this.initModel()
        // this._customerService.selectedCustomerGroup = this._abd.customerGroup[0]
      }
    } else {
      this.whatAction='Edit'
      // const xx = this._abd.customerGroup.filter(f => f.id === this._customerService.activeItem.id)
      // this._customerService.selectedCustomerGroup = xx[0]
    }
    // console.log("activeItem new edit =>",this._customerService.activeItem)
  }

  ngAfterContentInit() {
    this.initForm()
  }

  initForm() {
    let self = this;
    this.editform = this._fb.group({
      customerGroupName: [
        {value: this._customerService.activeItem?.title, disabled: this._customerService.isViewOnly}, Validators.required],
      // department: ['', Validators.required],
    })
  }

  initModel(): CustomerGroup {
    var model: CustomerGroup = {
      // id: null,
      // createdDate: new Date(S),
      title: null,
      isActive: true
    }
    return model
  }

  saveFn() {
    // console.log(this._customerService.activeItem)
    if(this.whatAction=='Edit') {
      this._customerService.isViewOnly = false
      this.whatAction='Save'
      this.editform.controls['categoryName'].enable()
      return
    }
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);


    this._abd.showSpinner()
    this._customerService.createCustomerGroup(this._customerService.activeItem).subscribe(res => {
      if (res.success) {
        this._connMessageService.showToastMessage(`Customer Group saved successfully!`, 'success')
        this.cancelButtonClick()
        // this.refreshParentData()
        this.loadFreshDataEvent.emit(true);
        this._abd.hideSpinner()
      }
    })
  }

  refreshParentData() {
    // this._abd.loadInit().subscribe(res => {
    //   if (res.success) {
    //     this._abd.loadInit().subscribe(res => {
    //       this._abd.initCategories(this._abd.initActCategories())
    //       this._abd.selectedCategory = this._abd.actCategories.filter(f => f.categoryName.toLowerCase() == this.editform.get('categoryName').value.toLowerCase())[0]
    //       return res
    //     })
    //   }
    // })
  }

  cancelButtonClick(){

    this._customerService.showNewGroupEdit = false
    this._customerService.showNewNewGroupEdit = false
    this._customerService.isViewOnly=false
  }



}
