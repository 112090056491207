// Note: Insert the following code in p-table if you want to show the transfer detail
// selectionMode = "single"
// [(selection)] = "selectedRow"
// (onRowSelect) = "onRowSelect($event)"
// (onRowUnselect) = "onRowUnselect($event)"


import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transfer-report',
  templateUrl: './transfer-report.component.html',
  styleUrls: ['./transfer-report.component.scss']
})
export class TransferReportComponent implements OnInit {
  _globals = GlobalService


  colheader = [['Date', 'Transfer Type', 'Transfer No (#)', 'Sender Location', 'Receiver Location', 'Total Transfered Qty (#)', 'Total Price ($)', 'Shipping cost ($)', 'Transfer Out Status', 'Transfer In Status']];
  colflds = ['date', 'transferTypeString', 'transferNo', 'senderLocation', 'receiverLocation', 'totalTransferedQty', 'totalPrice', 'shippingCost', 'transfersOutStatusString', 'transfersInStatusString'];
  summaryHeader = [['Date', 'Transfer Type', 'Transfer No (#)', 'Sender Location', 'Receiver Location', 'Total Transfered Qty (#)', 'Total Price ($)', 'Shipping cost ($)', 'Transfer Out Status', 'Transfer In Status']];
  domSummaryflds = ['transferTypeString', 'transferNo', 'senderLocation', 'receiverLocation', 'totalTransferedQty', 'totalPrice', 'shippingCost', 'transfersOutStatusString', 'transfersInStatusString'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  selectedRow: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';
  data: any = "";

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _messageService: MessageService,
    private currencyPipe: CurrencyPipe,
    public _reportService: ReportsService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Transfer Report'
    this._filterService.selectedLocation = this._abd.locations[0];
    this._filterService.selectedExtra1Location = this._abd.locations[0];
    this._filterService.selectedTranferType = this._abd.transferType[0];
    this._filterService.selectedTranferStatus = this._abd.transferStatus[0];

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()
    // this._abd.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    if(this._filterService.selectedLocation.locationId==this._filterService.selectedExtra1Location.locationId) {
      this._messageService.add({ severity: 'error', summary: 'Same Locations', detail: 'To and From locations must be different!' })
      return
    }
    this.getLocationsList();

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'TransferReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "SenderLocationIds": [this._filterService.selectedLocation.locationId.toString()],
        "ReceiverLocationIds": [this._filterService.selectedExtra1Location.locationId.toString()],
        "TransferDetailsStatus": this._filterService.selectedTranferStatus.value,
        "TransfersStatusCheck": this._filterService.selectedTranferType.value,
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if (x.isOnline) {
          x.isOnline = 'Yes'
        } else if (!x.isOnline) {
          x.isOnline = 'No'
        }
      })
      this._abd.hideSpinner();
    })
  }

  onRowSelect(e) {
    this.data = e.data;
    this._reportService.showTransferDetail = true;
    // console.log("Button pressed");
  }

  onRowUnselect(e) { }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Transfer Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Transfer Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("totalPrice") || col.includes("shippingCost")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("totalTransferedQty")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
