<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div></div>
        <!-- <button
            pButton
            pRipple
            type="button"
            label="Edit"
            class="p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-pencil"
            iconPos="right"
            (click)="editLabelPrint()"
        ></button> -->
        <button
        pButton
        pRipple
        type="button"
        label="Delete"
        [style]="{ width: '100%' }"
        class="p-button-danger p-button-text"
        icon="pi pi-trash"
        iconPos="right"
        (click)="optionDeleteClicked()"
    ></button>

    </ng-template>
</p-overlayPanel>


<div class="fadeindown animation-duration-500 flex">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            [paraReportName]="'barcode-label'"
            (filterCallbackFunc)="callbackFuncFromFilter()"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (deleteItemFn)="deleteMultiple()"
            (reloadFn)="reloadList()"
       >
        </app-report-top-filter-bar>

        <br />
        <p-table
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            styleClass="p-datatable-striped"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect()"
            [lazy]="true"
            (onLazyLoad)="customSort($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center" *ngIf="dataList?.length > 0">
                        <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        >
                        </p-checkbox>
                    </th>
                    <th pSortableColumn="itemName" style="text-align: center">
                        Product<p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="barcode" style="text-align: center">
                        Barcode<p-sortIcon field="barcode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="sku" style="text-align: center">
                        SKU<p-sortIcon field="sku"></p-sortIcon>
                    </th>
                    <th style="text-align: center">Print Location</th>
                    <th style="text-align: right">Price</th>
                    <th style="text-align: center">Copies</th>
                    <th style="text-align: center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr
                    class="text-center"
                    [ngClass]="item.selected == true ? 'p-highlight' : ''"
                >
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                        >
                        </p-checkbox>
                    </td>
                    <td style="text-align: center">{{ item.productName }}</td>
                    <td style="text-align: center">{{ item.barcode }}</td>
                    <td style="text-align: center">{{ item.sku }}</td>
                    <td style="text-align: center">{{ getLocationName(item.location)}}</td>
                

                    <td style="text-align: right">
                        {{
                            item.price
                                | currency : "USD" : "symbol-narrow" : "0.2-2"
                        }}
                    </td>
                    <td style="text-align: center">
                        {{ item.noOfCopy  }}
                    </td>
                    <td style="text-align: center">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <ng-container 
            *ngIf="dataList?.length > 0">
        <p-paginator 
            [first]="first"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
            #p
        >
        </p-paginator></ng-container>
    </div>
</div>

<!-- Label Print -->
<p-dialog
    *ngIf="_barcodeLabelServices.showEditBarcodeLabel"
    header="Barcode Printing"
    [(visible)]="_barcodeLabelServices.showEditBarcodeLabel"
    [style]="{ width: '70%' }"
    modal="true"
>
    <app-barcode-label-printing-form
        [isNew]="false"
        [isProductLabel]="true"
        [fromList]="true"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-barcode-label-printing-form>
</p-dialog>
<ng-container *ngIf="_barcodeLabelServices.showNewEditBarcodeLabel">
    <p-dialog
        header="Barcode Printing"
        [(visible)]="_barcodeLabelServices.showNewEditBarcodeLabel"
        [style]="{ width: '70%' }"
        modal="true"
    >
        <app-barcode-label-printing-form
            [isNew]="true"
            [fromList]="true"
            [isProductLabel]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-barcode-label-printing-form>
    </p-dialog>
</ng-container>
<!-- /Label Print -->

<!-- Custom Label Print -->
<p-dialog
    *ngIf="_barcodeLabelServices.showEditCustomBarcodeLabel"
    header="Custom Barcode Label"
    [(visible)]="_barcodeLabelServices.showEditCustomBarcodeLabel"
    [style]="{ width: '70%' }"
    modal="true"
>
    <app-barcode-label-printing-form
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        [fromList]="true"
        [isProductLabel]="false"
        (onSubmit)="refrestDataList($event)"
    ></app-barcode-label-printing-form>
</p-dialog>
<ng-container *ngIf="_barcodeLabelServices.showNewEditCustomBarcodeLabel">
    <p-dialog
        header="Custom Barcode Label"
        [(visible)]="_barcodeLabelServices.showNewEditCustomBarcodeLabel"
        [style]="{ width: '70%' }"
        modal="true"
    >
        <app-barcode-label-printing-form
            [isNew]="true"
            [isViewOnly]="false"
            [fromList]="true"
            [isProductLabel]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-barcode-label-printing-form>
    </p-dialog>
</ng-container>
<!-- /Custom Label Print -->