import { CurrencyPipe, formatDate, PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeNode } from 'primeng/api';
import { from, groupBy, mergeMap, toArray } from 'rxjs';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';


/*
  Comments: Removed the category dropdown from the report
  Reasoning: The category dropdown was not working properly. The code bleeds and changes selected category on initial download.
*/
@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss']
})
export class ProductReportComponent implements OnInit {
  _globals = GlobalService


  colFields = [
    // { header: 'Product', field: 'productName' },
    { header: 'Date', field: 'date' },
    { header: 'Sell', field: 'isOnline' },
    { header: 'Location', field: 'locationName' },
    { header: 'Department', field: 'department' },
    { header: 'Category', field: 'category' },
    { header: 'UOM', field: 'uom' },
    { header: 'Sold', field: 'soldqty' },
    { header: 'Return Qty', field: 'returnedQty' },
    { header: 'Returns', field: '' },
    { header: 'Discounts', field: 'totalDiscount' },
    { header: 'Net Sales', field: 'netSales' },
    { header: 'COGS', field: 'netCost' },
    { header: 'Gross Profit', field: 'grossProfit' },
    { header: 'Profit Margin', field: 'grossMargin' }];


  colheader = [['Product', 'Date', 'Sell', 'Location', 'Department', 'Category', 'UOM', 'Sold', 'Return Qty', 'Returns', 'Discounts', 'Net Sales', 'COGS', 'Gross Profit', 'Profit Margin']];
  colflds = ['date', 'isOnline', 'locationName', 'department', 'category', 'sku', 'barcode', 'productName', , 'uom', 'soldQty', 'returnedQty', 'totalReturned', 'totalDiscount', 'netSales', 'netCost', 'grossProfit', 'grossMargin'];
  summaryHeader = [['Product', 'Sell', 'Location', 'Department', 'Category', 'UOM', 'Sold', 'Return Qty', 'Returns', 'Discounts', 'Net Sales', 'COGS', 'Gross Profit', 'Profit Margin']];
  domSummaryflds = ['isOnline', 'locationName', 'department', 'category', 'productName', 'uom', 'soldQty', 'returnedQty', 'totalReturned', 'totalDiscount', 'netSales', 'netCost', 'grossProfit', 'grossMargin'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];
  ignoreSummarycols = [['Date', 'Sell', 'Location', 'SKU', 'Barcode', 'Product', 'UOM']];
  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  deptIds: string[] = [];
  groupby: string = 'Location';


  constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Product Report'
    this._filterService.selectedCategory = this._abd.categories;
    // console.log("selectedCategory",this._abd.selectedCategory)
    this._filterService.selectedDepartment = this._abd.actDepartments;

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDepartmentList() {
    let self = this;
    this.deptIds = [];
    let selDepartment = this._filterService.selectedDepartment;
    // The below line of code verifies if the selDepartment is already an array or not. If not, it converts it to an array.
    selDepartment = (typeof selDepartment != 'undefined' && selDepartment instanceof Array) ? selDepartment : [selDepartment];
    selDepartment.map(function (value: { id: { toString: () => string; }; }) {
      self.deptIds.push(value.id.toString())
    })
  }

  getCategoryList() {
    let self = this;

    this.catIds = [];
    let selCategory = this._filterService.selectedCategory || this._abd.actCategories;
    selCategory = (typeof selCategory != 'undefined' && selCategory instanceof Array) ? selCategory : [selCategory];
    selCategory.map(function (value: { id: { toString: () => string; }; }) {
      self.catIds.push(value.id.toString())

    })

  }

  getDataList() {
    this.cats = [];
    this.departs = [];
    this.dates = [];
    this.getLocationsList();
    this.getDepartmentList();
    this.getCategoryList();

    let sp = this._filterService.selectedProductSellTypeFilter
    if (!sp) sp = 'All'
    else sp = sp.Value
    if ([...new Set(this.CategoriesList.map(obj => obj))].length == 1) {
      this.GroupByView = { option: 'Category', value: 'category' };
      //this.groupByChanged()
    }
    const data = {
      "ReportName": 'SalesByProductReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "CategoriesIds": this.catIds,
        "DepartmentIds": this.deptIds,
        // "DiscountType": this.groupby,
        'filterValue': this._filterService.searchText,
        'OrderPlacement': sp,
        // 'GroupBy': this.GroupByView.value
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this.CategoriesList = []
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.cats = this.findUnique(res.data, d => d.category).map(function (el) {
        return el.category;
      });
      this.departs = this.findUnique(res.data, d => d.department).map(function (el) {
        return el.department;
      });

      this.dataList.map(x => {
        if (x.isOnline) {
          x.isOnline = 'Online'
        } else if (!x.isOnline) {
          x.isOnline = 'In-Store'
        }
        //this.expandedRows[x.category] = true;

        this.CategoriesList.push(x.department)
      })


      // if ([...new Set(this.CategoriesList.map(obj => obj))].length == 1) {
      //   this.GroupByView = { option: 'Category', value: 'category' };
      this.groupByChanged()
      // }

      // this.GroupByView.value == "category"
      if (this.GroupByView.value == "department") this.nasMakeDataDep(this.dataList)
      if (this.GroupByView.value == "category") this.nasMakeDataCat(this.dataList)
      this._abd.hideSpinner();

    })
  }

  salesItems: any = null
  nasMakeDataCat(dList) {
    this.salesItems = []
    let dep = []
    for (let i = 0; i < dList.length; i++) {
      let x = dep.find(f => f.category == dList[i].category)
      if (!x) dep.push(JSON.parse(JSON.stringify(dList[i])))
      dList[i].id = i + 1
    }

    for (let i = 0; i < dep.length; i++) {
      let pro = []
      let p = dList.filter(f => f.category == dep[i].category)
      if (!p || p?.length < 1) continue

      pro.push(JSON.parse(JSON.stringify(p[0])))
      let found = false
      for (let j = 0; j < p.length; j++) {
        found = false
        for (let k = 0; k < pro.length; k++) {
          if (pro[k].sku == p[j].sku) {
            found = true
            break
          }
        }

        if (!found)
          pro.push(JSON.parse(JSON.stringify(p[j])))
      }

      for (let j = 0; j < pro.length; j++) {
        let det = []
        for (let k = 0; k < p.length; k++) {
          if (pro[j].sku == p[k].sku)
            det.push(JSON.parse(JSON.stringify(p[k])))
        }
        pro[j].details = det
      }
      dep[i].items = pro
    }

    this.salesItems = dep
    // console.log('this.salesItems', this.salesItems)
    this.doCalc()
  }

  nasMakeDataDep(dList) {
    this.salesItems = []
    let dep = []
    for (let i = 0; i < dList.length; i++) {
      let x = dep.find(f => f.department == dList[i].department)
      if (!x) dep.push(JSON.parse(JSON.stringify(dList[i])))
      dList[i].id = i + 1
    }

    for (let i = 0; i < dep.length; i++) {
      let pro = []
      let p = dList.filter(f => f.department == dep[i].department)
      if (!p || p?.length < 1) continue

      pro.push(JSON.parse(JSON.stringify(p[0])))
      let found = false
      for (let j = 0; j < p.length; j++) {
        found = false
        for (let k = 0; k < pro.length; k++) {
          if (pro[k].sku == p[j].sku) {
            found = true
            break
          }
        }

        if (!found)
          pro.push(JSON.parse(JSON.stringify(p[j])))
      }

      for (let j = 0; j < pro.length; j++) {
        let det = []
        for (let k = 0; k < p.length; k++) {
          if (pro[j].sku == p[k].sku)
            det.push(JSON.parse(JSON.stringify(p[k])))
        }
        pro[j].details = det
      }
      dep[i].items = pro
    }

    this.salesItems = dep
    // console.log('this.salesItems', this.salesItems)
    this.doCalc()
  }

  doCalc() {
    const si = this.salesItems
    for (let i = 0; i < si.length; i++) {
      let ss = si[i]
      for (let j = 0; j < si[i].items.length; j++) {
        let item = si[i].items[j]
        let soldQty3 = 0
        let returnedQty3 = 0
        let returnedAmount3 = 0
        let discount3 = 0
        let netSales3 = 0
        let netCost3 = 0
        let grossProfit3 = 0
        let grossMargin3 = 0
        for (let k = 0; k < item.details.length; k++) {
          const detail = item.details[k]
          soldQty3 += detail.soldQty
          returnedQty3 += detail.returnedQty
          returnedAmount3 += detail.totalReturned
          discount3 += detail.totalDiscount
          netSales3 += detail.netSales
          netCost3 += detail.netCost
          grossProfit3 += detail.grossProfit
          grossMargin3 += detail.grossmargin
        }
        item.soldQty = soldQty3
        item.returnedQty = returnedQty3
        item.totalReturned = returnedAmount3
        item.totalDiscount = discount3
        item.netSales = netSales3
        item.netCost = netCost3
        item.grossProfit = grossProfit3
        item.grossmargin = grossMargin3
      }
    }

    for (let i = 0; i < si.length; i++) {
      let ss = si[i]
      let soldQty3 = 0
      let returnedQty3 = 0
      let returnedAmount3 = 0
      let discount3 = 0
      let netSales3 = 0
      let netCost3 = 0
      let grossProfit3 = 0
      let grossMargin3 = 0
      for (let j = 0; j < si[i].items.length; j++) {
        let item = si[i].items[j]
        soldQty3 += item.soldQty
        returnedQty3 += item.returnedQty
        returnedAmount3 += item.totalReturned
        discount3 += item.totalDiscount
        netSales3 += item.netSales
        netCost3 += item.netCost
        grossProfit3 += item.grossProfit
        grossMargin3 += item.grossmargin
      }
      ss.soldQty = soldQty3
      ss.returnedQty = returnedQty3
      ss.totalReturned = returnedAmount3
      ss.totalDiscount = discount3
      ss.netSales = netSales3
      ss.netCost = netCost3
      ss.grossProfit = grossProfit3
      ss.grossmargin = grossMargin3
    }
  }


  getItemByGroup(value) {
    if (this.GroupByView.value == "category")
      return this.dataList.filter(function (record) { return record.category == value });
    return this.dataList.filter(function (record) { return record.department == value });


  }
  public groupbyArray = [];
  public cats: any;
  public departs: any;
  public dates: any;
  findUnique(arr, predicate) {
    var found = {};
    arr.forEach(d => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
  }

  groupByChanged() {
    if (this.GroupByView.value == "category")
      this.groupbyArray = this.cats;
    else
      this.groupbyArray = this.departs;

    if (this.GroupByView.value == "department") this.nasMakeDataDep(this.dataList)
    if (this.GroupByView.value == "category") this.nasMakeDataCat(this.dataList)
  }
  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Product Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Product Report', true)
  }
  GroupByView = { option: 'Department', value: 'department' };
  GroupByOptions = [{ option: 'Department', value: 'department' }, { option: 'Category', value: 'category' }]
  DisplayRows: boolean = true;
  ToggleText = "COLLAPSE ALL";
  CategoriesList = [];
  ToggleExpandAll() {
    const thisRef = this;
    this.DisplayRows = !this.DisplayRows;
    this.ToggleText = this.DisplayRows ? "COLLAPSE ALL" : "EXPAND ALL"

    this.CategoriesList.forEach(function (item) {

      thisRef.expandedRows[item] = thisRef.DisplayRows;

    });



  }
  ToggleRow(row) {
    const thisRef = this;
    thisRef.expandedRows[row] = !thisRef.expandedRows[row];
  }
  expandedRows: {} = {};
  calculateSummaryTotal(col: string, source) {
    //

    let total = 0;
    if (this.dataList.length > 0) {

      let total = "0";
      if (col.includes("totalDiscount") || col.includes("totalReturned") || col.includes("netSales") || col.includes("netCost") || col.includes("grossProfit")) {
        return this.currencyPipe.transform(source.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("grossMargin")) {
        try {
          let COGS = this.dataList.reduce((sum, item) => sum + (item["netCost"]), 0)
          let GrossProfit = this.dataList.reduce((sum, item) => sum + (item["netSales"] - item["netCost"]), 0)
          let netSales = this.dataList.reduce((sum, item) => sum + item["netSales"], 0)
          return this.percentPipe.transform(GrossProfit / (netSales > 0 ? netSales : 100), '.2')

        }
        catch (e) {
          alert(e);
          return 0.0;
        }


      }
      else if (col.includes("soldQty") || col.includes("returnedQty")) {
        return source.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }



}
