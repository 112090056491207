import { Component, OnInit } from '@angular/core';

interface ExpiryDuration {
    name: string,
    title: string
}

@Component({
  selector: 'app-points-accumulation',
  templateUrl: './points-accumulation.component.html',
  styleUrls: ['./points-accumulation.component.scss']
})
export class PointsAccumulationComponent implements OnInit {

    expiryDurationList: ExpiryDuration[];

    selectedExpiry: ExpiryDuration;
    InputDiscountOnItem: boolean = true;
    InputDiscountOpenDepart: boolean = false;
    selectedValue: string;
    constructor() {
        this.expiryDurationList = [
            { name: 'Never', title: 'Never' },
            { name: 'Weekly', title: 'Weekly' },
            { name: 'Monthly', title: 'Monthly' },
            { name: 'Yearly', title: 'Yearly' },
        ];
    }

    ngOnInit(): void {
    }

}
