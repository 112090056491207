import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { SalesChannelService } from 'src/app/_business-dashboard/_services/bizz-sales-channel.services';
import { Status } from 'src/app/_models/coupon-management';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-bizz-sales-channel-form',
  templateUrl: './bizz-sales-channel-form.component.html',
  styleUrls: ['./bizz-sales-channel-form.component.scss']
})
export class BizzSalesChannelFormComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>()
  editform: FormGroup
  
  // Bind the dropdown values to the form from the service model
  statusList:any[] = Status; 
 
  fullNameDescription = `Name of the channel i.e Facebook, Instagram or
  a sales person “John Moris”`

  salesChannelData: any;
  code:any[]=[]
  url:any[]=[]
  couponArray:any[] = []; 

  @Input() isNew: boolean = true
  @Input() isViewOnly: boolean 
  @Output() onSubmit = new EventEmitter();
  
  constructor(
    private _fb: FormBuilder,
    public _salesChannelService: SalesChannelService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    public _abd: AppBaseDataService,
    ) { }

  ngOnInit(): void {
     this.salesChannelData = {
      fullName: this._salesChannelService.activeItem?.fullName,
      status: this._salesChannelService.activeItem?.status === true ? 1 : 0,
      description: this._salesChannelService.activeItem?.description,
      }
      this.initForm();
 
     let code =this._salesChannelService.activeItem?.couponCodes;
     if(code.includes(',')){
      code = code.split(',')
      this.code = code.map((v)=>{
        return {code:v}
       })
      
     }else if(code !== ""){
        this.code = [code]
     }else{
      this.code = []
     }
     let url =this._salesChannelService.activeItem?.url;
     if(url.includes(',')){
      url = url.split(',')
      this.url = url.map((v)=>{
       return {url:v}
      })
     }else if(url !== ""){
        this.url = [url]
     }else{
      this.url = []
     }
     this.couponArray = url.map((x, index) => ({ x, y: code[index] }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

   


  get f() {
    return this.editform.controls;
  }

  initForm() {
  this.editform = this._fb.group({
    fullName: [{value: this.salesChannelData.fullName, disabled: this.isViewOnly}, [Validators.required,Validators.maxLength(255)]],
    status: [{value: this.salesChannelData.status, disabled: this.isViewOnly}, [Validators.required]],
    description: [{value: this.salesChannelData.description, disabled: this.isViewOnly},Validators.maxLength(50000)],
       })
  }

  saveFn(){

    this._abd.showSpinner();

   const formData: any = {
    fullName: this.f.fullName.value,
    status: this.f.status.value,
    description: this.f.description.value,
      }

    // //console.log(dummy)
    if(this.isNew){
      this._salesChannelService.addChannel(formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Channel added successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Channel could not be added.`, 'error');
          this._abd.hideSpinner()
        }
      })
    }
    
    else{
      this._salesChannelService.updateChannel(this._salesChannelService.activeItem?.id,formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Channel updated successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Channel could not be updated.`, 'error');
          this._abd.hideSpinner()
        }
      })
    }
  }

  cancelButtonClick(){
    this._salesChannelService.showEditChannel = false;
    this._salesChannelService.showNewEditChannel = false;
  }
} 