import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CouponReportsService {

  _globals = GlobalService
  
  constructor(private _bs: BaseService,
              private _abd: AppBaseDataService) { }

  getCouponReportsList(model?:any){
    let url = environment.InventoryBaseURL + 'CouponAnalytReport'
    return this._bs.httpGetParams(url, model).pipe(map((res: any) => {
      return res
    }))
  }
  getSalesChannelReportsList(model?:any){
    let url = environment.InventoryBaseURL + 'SalesChannelReport?'+model
    return this._bs.httpPost(url).pipe(map((res: any) => {
      return res
    }))
  }
 
  salesChannelList:any[]=[
    {
    id:1,
    channelName:'Instagram',
    details:[{
     id:1,
     couponCode:"Ins-12/23=92",
     leads:5,
     signup:1,
     trial:1,
     lite:0,
     standard:0,
     plus:0,
     premium:0,
     custom:0,
     planCost:229.99,
     hardwareCostCost:260.00,
     total:300,
    },
    {
      id:2,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },
     {
      id:3,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },
     {
      id:4,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },],
  },
  {
    id:2,
    channelName:'Facebook',
    details:[{
     id:1,
     couponCode:"Ins-12/23=92",
     leads:5,
     signup:1,
     trial:1,
     lite:0,
     standard:0,
     plus:0,
     premium:0,
     custom:0,
     planCost:229.99,
     hardwareCost:260.00,
     total:300,
    },
    {
      id:2,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },
     {
      id:3,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },
     {
      id:4,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },],
  },
  {
    id:3,
    channelName:'Justin',
    details:[{
      id:1,
      couponCode:"Ins-12/23=92",
     leads:5,
     signup:1,
     trial:1,
     lite:0,
     standard:0,
     plus:0,
     premium:0,
     custom:0,
     planCost:229.99,
     hardwareCost:260.00,
     total:300,
    },
    {
      id:2,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:229.99,
      hardwareCost:260.00,
      total:300,
     },
     {
      id:3,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:209.99,
      hardwareCost:200.00,
      total:300,
     },
     {
      id:4,
      couponCode:"Ins-12/23=92",
      leads:5,
      signup:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:129.99,
      hardwareCost:330.00,
      total:300,
     },],
  },
  {
    id:4,
    channelName:'Direct Leads',
    details:[{
      id:1,
     couponCode:"Ins-12/23=92",
     leads:5,
     signup:1,
     trial:1,
     lite:0,
     standard:0,
     plus:0,
     premium:0,
     custom:0,
     planCost:559.99,
     hardwareCost:220.00,
     total:300,
    },
    {
      id:2,
      couponCode:"Ins-12/23=92",
      leads:5,
      signups:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:29.99,
      hardwareCost:160.00,
      total:300,
     },
     {
      id:3,
      couponCode:"Ins-12/23=92",
      leads:5,
      signups:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:329.99,
      hardwareCost:320.00,
      total:300,
     },
     {
      id:4,
      couponCode:"Ins-12/23=92",
      leads:5,
      signups:1,
      trial:1,
      lite:0,
      standard:0,
      plus:0,
      premium:0,
      custom:0,
      planCost:129.99,
      hardwareCost:220.00,
      total:300,
     },],
  },



]

}
