<div
    class="grid c2c-section bg-blue-700 text-white text-center"
    style="font-weight: 700"
>
    <div class="col">Items</div>
    <div class="col">Price</div>
</div>

<div class="grid c3c-section text-center mb-5">
    <div class="col text-left">
        <!-- {{ _globals.businessName }} Subscription <br /> -->
        <strong>{{
            _subscriptionPlanService.selectedSubscription.title
        }}</strong>
        <br />
        <button
            pButton
            pRipple
            type="button"
            label="Change plan"
            class="p-button-text"
            (click)="changePlanButtonClicked()"
        ></button>
    </div>
    <div class="col">
        Number of Location(s)<br />
        <p-inputNumber
        style="text-align:center"
            [(ngModel)]="noOfLocations"
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="horizontal"
            spinnerMode="horizontal"
            [step]="1"
            [min]="1"
            [max]="10"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
        >
        </p-inputNumber>
    </div>
    <div class="col">Price</div>
</div>

<p-dialog header="Subscription Plans" [(visible)]="displaySubscriptionPlans" [style]="{width: '70%'}" [baseZIndex]="10000">
    <app-subscription-list [mode]="'modal'"></app-subscription-list>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="displaySubscriptionPlans=false; saveToCache()" label="Yes" class="p-button-text"></p-button>
        <p-button icon="pi pi-times" (onClick)="displaySubscriptionPlans=false" label="No"></p-button>
    </ng-template>
</p-dialog>
