<div class="grid">
    <div class="col-7 col-7 md:col-12 lg:col-12 xl:col-8 xxl:col-8  pl-0 ">
        <div class="flex flex-column">
            <div class="flex justify-content-between flex-wrap dotted-border-bottom py-2">
                <div class="flex align-items-center justify-content-center font-bold widget-heading text-2xl">
                    Subscription Plan
                </div>
                <div class="flex align-items-center justify-content-center">
                    <p-button
                        class="signup-back-button p-button-sm"
                        (click)="changeSubscriptionPlan()">
                        Change Plan
                    </p-button>
                </div>
            </div>
            <div class="flex justify-content-between flex-wrap flex-column py-2">
                <div class="flex justify-content-start py-2">
                    <table class="w-full">
                        <tr class="text-lg font-bold py-2">
                            <th></th>
                            <th>Location(s)</th>
                            <th>Register(s)</th>
                            <th class="text-end">Price</th>
                        </tr>
                        <tr class="py-2">
                            <td>
                                <div class="flex">
                                    <div class="flex-initial flex align-items-center justify-content-center font-bold device-description">
                                    {{_globals.whiteLabel.wlName}} {{ _signup.selectedPlan.title }}
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex-initial flex align-items-center justify-content-center">
                                        <p-chip
                                            [label]=" _signup.selectedPlanTimeframe == 'BilledMonthly' ? 'Monthly Subscription': 'Annually Subscription'"
                                            styleClass="green-chip"
                                        ></p-chip>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p-inputNumber
                                    [(ngModel)]="_signup.checkoutPageCalcVars.locationsQty"
                                    (onInput)="locationQtyClicked($event)"
                                    [maxlength]="3"
                                    [step]="1"
                                    [min]="1"
                                    [max]="100"
                                    class="input-number-for-subscription"
                                    [allowEmpty]="false"
                                    [disabled]="!_signup.allowedLocationMoreThanOne"
                                    [showButtons]="true" inputId="stacked" [step]="1" [min]="1" [minFractionDigits]="0"
                                    [maxFractionDigits]="0" [inputStyle]="{ width: '70px','text-align': 'center',height: '20px' }">
                                </p-inputNumber>
                            </td>
                            <td>
                                <p-inputNumber [(ngModel)]="_signup.checkoutPageCalcVars.registersQty"
                                    [disabled]="!_signup.allowedRegisterMoreThanOne"
                                    (onInput)="updateLocation($event)"
                                    [allowEmpty]="false"
                                    [step]="1"
                                    [min]="_signup.checkoutPageCalcVars.locationsQty"
                                    [max]="999"
                                    class="input-number-for-subscription"
                                    [showButtons]="true" inputId="stacked"
                                    [minFractionDigits]="0"
                                    [maxFractionDigits]="0" [inputStyle]="{
                            width: '70px',
                            'text-align': 'center',
                            height: '20px'
                        }">
                                </p-inputNumber>
                            </td>
                            <td class=" align-items-end justify-content-end text-right">

                                <span class="font-bold">{{ getSetSubscriptionPlanPrice() }}</span>
                                <span>/month</span>

                                <span class="flex justify-content-end" *ngIf="_signup.selectedPlanTimeframe ==
                                    'BilledMonthly'"> billed monthly</span>
                                <span class="flex justify-content-end" *ngIf="_signup.selectedPlanTimeframe !=
                                    'BilledMonthly'">billed annually</span>
                            </td>
                        </tr>
                        <tr class="py-2">
                            <td>
                                <div class="flex align-items-start justify-content-start font-bold mt-4 ">
                                    Additional Location(s) Cost
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div class="flex align-items-end justify-content-end font-bold mt-4">
                                    {{ getSetAdditionalLocationsPrice() }}
                                </div>
                                <div class="flex align-items-end justify-content-end text-sm">
                                    {{
                            _signup.selectedPlanTimeframe == "BilledMonthly"
                                ? "billed monthly"
                                : "billed annually"
                        }}
                                </div>
                            </td>
                        </tr>
                        <tr class="py-2">
                            <td>
                                <div class="flex align-items-start justify-content-start font-bold mt-3">
                                    Additional Register(s) Cost
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div class="flex align-items-end justify-content-end font-bold mt-3">
                                    {{ getSetAdditionalRegistersPrice() }}
                                </div>
                                <div class="flex align-items-end justify-content-end text-sm">
                                    {{
                            _signup.selectedPlanTimeframe == "BilledMonthly"
                                ? "billed monthly"
                                : "billed annually"
                        }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="flex flex-column py-2">
                    <div class="flex justify-content-between flex-wrap dotted-border-bottom py-2">
                        <div class="flex align-items-center justify-content-center font-bold widget-heading text-2xl">
                            Website & Online Store
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p-button
                                label=""
                                class="signup-back-button p-button-sm"
                                (click)="changeWebsitePlan()">
                                Change Plan
                            </p-button>
                        </div>
                    </div>
                    <div class="flex justify-content-start"
                        *ngIf="isStaticWebsiteSelected || isECommerceWebsiteSelected ">
                        <table class="w-full">
                            <tr class="text-lg font-bold py-2">
                                <td colspan="2"></td>
                                <td colspan="2" class="text-center py-2">
                                    <div class=" flex justify-content-end text-lg font-bold">
                                        Price
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="isStaticWebsiteAvailable && isStaticWebsiteSelected">
                                <td colspan="2">
                                    <span class=" font-bold">
                                        {{ _signup.selectedWebsiteAndOnlineStorePlan.title }}
                                        <span *ngIf="_signup.selectedWebsiteAndOnlineStorePlan.includedInPackage">
                                            <small>(included in plan)</small>
                                        </span>
                                    </span>
                                </td>
                                <td colspan="2">
                                    <div class=" flex justify-content-end">
                                        <span class="font-bold">
                                            {{_signup.selectedWebsiteAndOnlineStorePlan.price |currency }}
                                            <span class="text-sm">
                                                <span *ngIf="_signup.selectedPlanTimeframe =='BilledMonthly'"> /monthly</span>
                                                <span *ngIf="_signup.selectedPlanTimeframe !='BilledMonthly'"> /annually</span>
                                            </span>
                                        </span>
                                    </div>

                                    <div class=" flex justify-content-end">
                                        <a class="text-red-600 font-bold"  style="cursor:pointer"
                                            (click)="removeWebsiteAndOnlineStorePlan()">Remove</a>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="isECommerceWebsiteAvailable && isECommerceWebsiteSelected">
                                <td colspan="2">
                                    <span class=" font-bold">
                                        {{ _signup.selectedECommerceWebsitePlan.title }}
                                        <span *ngIf="_signup.selectedECommerceWebsitePlan.includedInPackage">
                                            <small>(included in plan)</small>
                                        </span>
                                    </span>
                                </td>
                                <td colspan="2">
                                    <div class=" flex justify-content-end  ">
                                        <span
                                            class="font-bold">{{_signup.selectedECommerceWebsitePlan.price |currency }}</span>
                                        <span class="text-sm">

                                            <span *ngIf="_signup.selectedPlanTimeframe =='BilledMonthly'"> /monthly</span>
                                            <span *ngIf="_signup.selectedPlanTimeframe !='BilledMonthly'"> /annually</span>
                                        </span>
                                    </div>
                                    <div class=" flex justify-content-end">
                                        <a class="text-red-600 font-bold" style="cursor:pointer"
                                            (click)="removeWebsiteAndOnlineStorePlan()">Remove</a>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                *ngIf="_signup.showCustomDomain && (isStaticWebsiteAvailable || isECommerceWebsiteAvailable )">
                                <td colspan="2">
                                    <span class=" font-bold">Custom Domain</span>
                                </td>
                                <td colspan="2">
                                    <div class="flex justify-content-end ">
                                        <span
                                            class="font-bold">{{_signup.checkoutPageCalcVars.customDomainCost | currency}}
                                        </span>
                                            <span class=" text-sm">

                                                <span
                                                    *ngIf="_signup.selectedPlanTimeframe =='BilledMonthly'"> /monthly</span>
                                                <span
                                                    *ngIf="_signup.selectedPlanTimeframe !='BilledMonthly'"> /annually</span>
                                        </span>
                                    </div>
                                    <div class="col-12 flex justify-content-end">
                                        <a class="text-red-600 font-bold" style="cursor:pointer"
                                            (click)="removeCustomDomain()">Remove</a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="flex justify-content-center p-3"
                        *ngIf="!isStaticWebsiteSelected && !isECommerceWebsiteSelected">
                        <div class="ml-3 text-gray-500">No web plan selected</div>
                    </div>
                </div>
                <div class="flex flex-column py-2">
                    <div class="flex justify-content-between flex-wrap dotted-border-bottom py-2">
                        <div class="flex align-items-center justify-content-center font-bold widget-heading text-2xl">
                            Loyalty Program
                        </div>
                        <div class="flex align-items-center justify-content-center" *ngIf="!isLoyaltyProgramAvailable">
                            <p-button label="" class="signup-back-button" (click)="changeLoyaltyPlan()">
                                Change Plan
                            </p-button>
                        </div>
                    </div>
                    <div class="flex flex-column justify-content-start " *ngIf="isLoyaltyProgramAvailable">
                        <div class="flex ">
                            <div class="col-6 "></div>
                            <div class="col-6 flex justify-content-end text-lg font-bold">Price</div>
                        </div>
                        <div class="flex">
                            <div class="col-6  font-bold">Loyalty Program
                                <span *ngIf="isLoyaltyProgramAvailableUsingSubscription">(included in plan)</span>
                            </div>
                            <div class="col-6 flex justify-content-end flex-column">
                                <div class="col-12 flex justify-content-end">
                                    <span class="font-bold"> {{_signup.loyaltyProgramPrice |currency }}</span>
                                    <span class="text-sm">

                                        <span *ngIf="_signup.selectedPlanTimeframe =='BilledMonthly'"> /monthly</span>
                                        <span *ngIf="_signup.selectedPlanTimeframe !='BilledMonthly'"> /annually</span>
                                    </span>

                                </div>
                                <div class="col-12 flex justify-content-end"
                                    *ngIf="isLoyaltyProgramAvailable && !isLoyaltyProgramAvailableUsingSubscription &&
                                    _signup.loyaltyProgramPrice > 0">
                                    <a class="text-red-600 font-bold" style="cursor:pointer"
                                        (click)="removeLoyaltyProgram()">Remove</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-content-center p-3" *ngIf="!isLoyaltyProgramAvailable">
                        <div class="ml-3 text-gray-500">Loyalty plan details not found</div>
                    </div>
                </div>
                <div class="flex flex-column py-2">
                    <div class="flex justify-content-between flex-wrap dotted-border-bottom py-2">
                        <div class="flex align-items-center justify-content-center font-bold widget-heading text-2xl">
                            Hardware
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p-button
                                class="signup-back-button p-button-sm"
                                (click)="displayHardwareList = true">
                                Shop More
                            </p-button>
                        </div>
                    </div>
                    <div class="flex justify-content-start" *ngIf="_signup.selectedHardware?.length">
                        <table class="w-full py-2">
                            <tr class="text-lg font-bold py-2">
                                <th></th>
                                <th></th>
                                <th class="text-center">Quantity</th>
                                <th class="text-end">Price</th>
                            </tr>
                            <tr *ngFor="let item of _signup.selectedHardware" class="dotted-border-bottom  py-2">
                                <td class="dotted-border-bottom py-2">
                                    <img [src]="item.image" alt="Logo" height="60" />
                                </td>
                                <td class=" flex-column dotted-border-bottom py-2">
                                    <span class="font-bold"> {{ item.title }}</span>
                                    <span *ngIf="item.addons.length > 0">


                                        <div class="flex flex-column" *ngIf="item.addonAdded">
                                            <div class="flex-initial ">
                                                <p-chip
                                                    styleClass="green-chip">Add-on Included</p-chip>
                                            </div>
                                            <div class="flex pt-2" *ngFor="let singleAddon of item.addons">
                                                <p *ngIf="singleAddon.addonAdded == 'yes'">{{singleAddon.title}}</p>
                                            </div>
                                        </div>

                                    </span>
                                </td>
                                <td class="text-center dotted-border-bottom py-2">
                                    <p-inputNumber
                                        [(ngModel)]="item.selectedQty"
                                        [showButtons]="true"
                                        (onInput)="updateHardwareQuantity()"
                                        [allowEmpty]="false"
                                        inputId="stacked"
                                        [step]="1"
                                        [min]="1"
                                        class="input-number-for-subscription"
                                        [minFractionDigits]="0"
                                        [maxFractionDigits]="0"
                                        [inputStyle]="{
                                        width: '70px',
                                        'text-align': 'center',
                                        height: '20px' }"
                                    >
                                    </p-inputNumber>
                                </td>
                                <td class="dotted-border-bottom  py-2">
                                    <div class="flex align-items-end justify-content-end  mt-3">

                                        <span class="font-bold">{{ calcSingleHardwarePrice(item) | currency }}</span>
                                    </div>
                                    <div class="flex align-items-end justify-content-end">
                                        <a class="text-red-600 font-bold" style="cursor:pointer"
                                            (click)="removeHardware(item)">Remove</a>
                                    </div>
                                    <div class="flex align-items-end justify-content-end  mt-3"
                                        *ngIf="item.addons.length > 0">
                                        <div *ngFor="let addonPrice of item.addons">
                                            <span class="pt-2"
                                                *ngIf="addonPrice.addonAdded == 'yes' && addonPrice.singleAddonTotalPrice >
                                                0">
                                                <span
                                                >{{ item.selectedQty | number }} x
                                                {{ addonPrice.price | currency }} =
                                                <span class="font-bold">
                                                    {{ addonPrice.singleAddonTotalPrice | currency }}
                                                </span>

                                                </span>

                                                <div class="flex align-items-end justify-content-end"
                                                >

                                                    <a class="text-red-600 font-bold" style="cursor:pointer"
                                                        (click)="removeAddon(item,addonPrice)" >Remove</a>
                                                </div>
                                            </span>
                                        </div>

                                    </div>

                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="flex justify-content-center p-3"
                        *ngIf="!_signup.selectedHardware?.length">
                        <div class="ml-3 text-gray-500">No Hardware selected</div>
                    </div>
                </div>
            <!-- Billing Address -->
                <div class="flex justify-content-start flex-wrap dotted-border-bottom py-2">
                    <div class="flex align-items-center justify-content-center font-bold text-blue-500">
                        Billing Address
                    </div>
                </div>
                <div class="flex py-2"
                >
                    <form [formGroup]="billingForm" (ngSubmit)="billingForm.valid && saveFn()" autocomplete="off"
                    >
                        <div class="field"
                            >
                            <label class="control-label">
                            Address<span class="required">*</span>
                            </label>
                            <app-text-input
                                [formControl]="$any(billingForm.controls)['address']"
                                [name]="'Address'"
                                [label]="" 
                                (input)="_signup?.businessInfo.address =$event.target.value">
                            </app-text-input>
                        </div>
                        <div class="flex flex-row flex-wrap justify-content-between"
                            >
                            <div class="flex  md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                    Zip Code <span class="required">*</span>
                                    </label>
                                    <app-text-input
                                        formControlName="postalCode"
                                        [label]=""
                                        [name]="'zip code'"
                                        (input)="zipInputBEvent($event.target.value)">
                                    </app-text-input>
                                </div>
                            </div>
                            <div class="flex md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                        City<span class="required">*</span>
                                    </label>
                                    <span class="p-input-icon-right" [style]="{ width: '100%' }">
                                        <i class="pi pi-spin pi-spinner" *ngIf="showBCityWait"></i>
                                        <app-text-input
                                            formControlName="city"
                                            [name]="'city'"
                                           [label]="''"></app-text-input>
                                    </span>
                                </div>
                            </div>
                            <div class="flex md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                        State
                                            <span class="required">*</span>

                                    </label>
                                    <p-dropdown [style]="{
                                        width: '100%',
                                        'min-width': '200px',
                                        'margin-top': '7px'
                                    }" class="p-dropdown-1"
                                        appendTo="body"
                                        [options]="usaStates"
                                        [(ngModel)]="selectedBState"
                                        optionLabel="name"
                                        [filter]="true"
                                        filterBy="name"
                                        [ngModelOptions]="{ standalone: true }"
                                     ></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                 <!-- /Billing Address -->
                 <!-- Shipping Address -->
                <div class="flex justify-content-between flex-wrap dotted-border-bottom py-2">
                    <div class="flex align-items-center justify-content-center font-bold text-blue-500">
                        Shipping Address
                    </div>
                    <div class="flex justify-content-end">
                        <div class="flex align-items-center justify-content-center font-bold text-blue-500">
                            Use Business Address For Shipment
                        </div>
                        <div class="flex align-items-center justify-content-center ml-2">
                            <p-checkbox
                                [(ngModel)]="_signup.checkoutPageInfo.useBusinessAddressForShipment"
                                [binary]="true"
                                (onChange)=" useBusinessAddressForShipmentChanged($event)"
                            >
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex py-2" *ngIf="!isBillingAndShipmentSame" 
                >
                    <form [formGroup]="editform" (ngSubmit)="editform.valid && saveFn()" autocomplete="off"
                    >
                        <div class="field"
                            >
                            <label class="control-label">
                            Address<span class="required">*</span>
                            </label>
                            <app-text-input
                                [formControl]="$any(editform.controls)['address']"
                                [ngClass]="{'shipping-address-disabled':_signup.checkoutPageInfo.useBusinessAddressForShipment}"
                                [name]="'Address'"
                                [disabled]="_signup.checkoutPageInfo?.useBusinessAddressForShipment"
                                [label]="" (input)="_signup?.checkoutPageInfo.address =$event.target.value">
                            </app-text-input>
                        </div>
                        <div class="flex flex-row flex-wrap justify-content-between"
                            >
                            <div class="flex  md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                    Zip Code <span class="required">*</span>
                                    </label>
                                    <app-text-input
                                        formControlName="postalCode"
                                        [label]=""
                                        [ngClass]="{'shipping-address-disabled':_signup.checkoutPageInfo.useBusinessAddressForShipment}"
                                        [disabled]="_signup.checkoutPageInfo?.useBusinessAddressForShipment"
                                        [name]="'zip code'"
                                        (input)="zipInputEvent($event.target.value)">
                                    </app-text-input>
                                </div>
                            </div>
                            <div class="flex md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                        City<span class="required">*</span>
                                    </label>
                                    <span class="p-input-icon-right" [style]="{ width: '100%' }">
                                        <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                                        <app-text-input
                                            formControlName="city"
                                            [name]="'city'"
                                            [ngClass]="{'shipping-address-disabled':_signup.checkoutPageInfo.useBusinessAddressForShipment}"
                                            [disabled]="_signup.checkoutPageInfo?.useBusinessAddressForShipment"
                                            [label]="''"></app-text-input>
                                    </span>
                                </div>
                            </div>
                            <div class="flex md:col-4 lg:col-4 xl:col-4 xxl:col-4">
                                <div class="field">
                                    <label class="control-label">
                                        State
                                            <span class="required">*</span>

                                    </label>
                                    <p-dropdown [style]="{
                                        width: '100%',
                                        'min-width': '200px',
                                        'margin-top': '7px'
                                    }" class="p-dropdown-1"
                                        appendTo="body"
                                        [options]="usaStates"
                                        [(ngModel)]="selectedState"
                                        optionLabel="name"
                                        [filter]="true"
                                        filterBy="name"
                                        [ngModelOptions]="{ standalone: true }"
                                        [disabled]=" _signup.checkoutPageInfo?.useBusinessAddressForShipment
                                    "></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                 <!-- /Shipping Address -->
                <div class="flex justify-content-between flex-wrap card-container purple-container mt-6">
                    <div class="flex align-items-center justify-content-center">
                        <p-button label="Back" class="signup-back-button" icon="pi pi-arrow-left"
                            (onClick)="backClicked()"></p-button>
                    </div>
                </div>
            </div>
         </div>
    </div>
    <div class="col-5 col-5 md:col-5 lg:col-5 xl:col-4 xxl:col-4 height-content check-wizard-margin-top">
        <div class="card-container white-containerborder-round-md border-1 surface-border p-3 ">
            <table class="w-full">
                <tr>
                    <td class=" text-lg">Plan Cost</td>
                    <td class=" text-lg text-end">
                        {{ _signup.checkoutPageCalcVars.subscriptionPlanPrice | currency }}
                    </td>
                </tr>
                <tr class=" pt-3 ">
                    <td class=" pt-3" >
                        Additional Location(s)
                    </td>
                    <td class=" text-right">
                        {{
                        _signup.checkoutPageCalcVars.additionalLocationsCost
                            | currency
                    }}
                    </td>
                </tr>
                <tr>
                    <td class="" style="padding-top: 5px">
                        Additional Register(s)
                    </td>
                    <td class="" style="text-align: right; padding-top: 5px">
                        {{
                        _signup.checkoutPageCalcVars.additionalRegistersCost
                            | currency
                    }}
                    </td>
                </tr>

                <tr *ngIf="isStaticWebsiteSelected">
                    <td class=" text-lg py-2">
                        Static Website
                    </td>
                    <td class=" text-lg text-end py-2">

                        {{
                        _signup.checkoutPageCalcVars.staticWebsite
                            | currency
                    }}
                    </td>
                </tr>
                 <tr class="my-2" *ngIf="isECommerceWebsiteSelected">
                     <td class=" text-lg py-2">
                         Basic eCommerce
                     </td>
                     <td class=" text-lg text-end py-2">

                         {{
                        _signup.checkoutPageCalcVars.ecomCost
                            | currency
                    }}
                     </td>
                 </tr>
                <tr class="my-2"
                    *ngIf="_signup.showCustomDomain && (isStaticWebsiteSelected || isECommerceWebsiteSelected )">
                    <td class=" text-lg py-2">
                        Custom Domain
                    </td>
                    <td class=" text-lg text-end py-2">
                        {{ _signup.checkoutPageCalcVars.customDomainCost | currency }}
                    </td>
                </tr>

                <tr class="my-2" *ngIf="_signup.selectedCustomerAppPlan">
                    <td class=" text-lg py-2">
                        {{ _signup.selectedCustomerAppPlan.title }}
                    </td>
                    <td class=" text-lg text-end py-2">
                        {{ calcCustomerAppPrice() | currency }}
                    </td>
                </tr>
                <tr class="my-2" *ngIf="isLoyaltyProgramAvailable">
                    <td class=" text-lg py-2">
                        Loyalty Program
                    </td>
                    <td class=" text-lg text-end">

                        <span>
                             {{ _signup.loyaltyProgramPrice | currency }}
                        </span>

                    </td>
                </tr>



                <tr class="my-2" *ngIf="_signup.checkoutPageCalcVars.hardwareCost > 0">

                    <td class=" text-lg py-2" >
                        Hardware Cost
                    </td>
                    <td class=" text-lg py-2 text-end">
                        {{ calcHardwareNetPrice() | currency }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <p-divider></p-divider>
                    </td>
                </tr>
                <tr class="my-2">
                    <td class="font-medium text-2xl text-600 py-2">
                        Subtotal
                    </td>
                    <td class="font-medium text-2xl text-600 text-end py-2">
                        {{_signup.checkoutPageCalcVars.subTotal| currency  }}
                    </td>
                </tr>
                <tr class="my-2">
                    <td class="font-medium text-2xl text-600 py-2" >
                        Discount
                        <span class="text-base" *ngIf="couponCodeDiscountValue.type === 1">({{discount}}%)</span>
                        <!-- <span class="text-base" *ngIf="couponCodeDiscountValue.type === 'f'">(${{couponCodeDiscountValue.value}})</span> -->
                    </td>
                    <td class="font-medium text-2xl text-600 py-2 text-end" *ngIf="_signup.couponCodeDiscount">
                        -${{ _signup.couponCodeDiscount }}
                    </td>
                    <td class="font-medium text-2xl text-600 py-2 text-end" *ngIf="!_signup.couponCodeDiscount">
                         ${{ '0.00'  }}
                    </td>
                </tr>
                <tr class="my-2">
                    <td class="font-medium text-2xl text-600 py-2" >
                        Tax
                        <span class="text-base" *ngIf="_signup.checkoutPageCalcVars.tax > 0">(8.25% included)</span>
                    </td>
                    <td class="font-medium text-2xl text-600 py-2 text-end">
                        {{ _signup.checkoutPageCalcVars.tax | currency  }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <p-divider></p-divider>
                    </td>
                </tr>
               <tr class="my-2">
                <td colspan="2">
                   <div class="my-1 " *ngIf="!showCouponCode">
                         <div class="flex justify-content-between">
                             <input type="text" class="w-full" placeholder="Add coupon code"  pInputText [formControl]="couponCodeInput"/>
                             <span class="cursor-pointer apply" (click)="couponCodeChecking()">
                         Apply
                        </span>
                         </div>
                     
                        <p-progressBar mode="indeterminate" *ngIf="loading"  [style]="{'height': '2px','width':'97%'}"></p-progressBar>
                        <div  class="invalid-feedback" *ngIf="couponCodeNotValid">
                          Invalid coupon code
                        </div>
                    </div>
                   <div class="my-1 " *ngIf="showCouponCode">
                    <div class="coupon-code-show mr-1">
                        <span class="coupon-code-data">
                         {{couponCode}}
                        </span>
                        <span class="cursor-pointer text-red-500  py-1" (click)="onRemoveCouponCode()">
                            Remove
                        </span>
                    </div>
                   </div>
                </td>
                       
                </tr> 
                <tr>
                    <td colspan="2">
                        <p-divider></p-divider>
                    </td>
                </tr>
                <tr class="my-2">
                    <td class="font-bold text-2xl text-600 py-2">
                        Total
                    </td>
                    <td class="font-bold text-2xl text-600 py-2 text-end">
                        ${{
                        _signup.checkoutPageCalcVars.gTotal
                            | number: "1.2-2"
                    }}
                    </td>
                </tr>
            </table>
            <p-divider></p-divider>
            <!--<div class="flex align-items-center justify-content-center m-2 text-xs">
                Shipment fee will be included on payment page
            </div> -->
            <div class="mt-5">
                <!-- <input type="text" disabled="true" pInputText class="p-inputtext-lg" placeholder="Add Promo Code Here" />
                -->
                    <!-- <app-text-input
                [(ngModel)]="_signup.checkoutPageInfo.promoCode"
                [label]="''"
                [placeholder]="Add Promo Code Here"
            ></app-text-input> -->
            </div>
            <div class="mt-6 mb-3">
                <button pButton class="flex w-full p-button-lg" type="button" [disabled]="billingForm.invalid" label="Proceed to Payments"
                    (click)="makeReturnObject()"></button>
            </div>
        </div>
    </div>
</div>



<p-dialog
    header="Subscription Plans"
    modal="true"
    [closable] = "false"
    [(visible)]="displaySubscriptionPlan"
   [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '95vw'}"
>

<ng-template pTemplate="footer">
    <button pButton type="button" (click)="closeSubscriptionModal(1)" class="p-button-secondary" label="Close"></button>
   <button pButton type="button" [disabled]="!_signup.planChanged" class="checkout-popup-btn-save"
       (click)="closeSubscriptionModal(2)"
       label="Next"></button>
</ng-template>
    <app-subscription-plans-layout
        [showFooter]="!displaySubscriptionPlan"
        (closeDialogSubscriptionPlan)="closeDialogSubscriptionPlan()"
    ></app-subscription-plans-layout>
</p-dialog>

<p-dialog
    header="Website & Online Store"
    modal="true"
     [closable] = "false"
     [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '75vw'}"
    [(visible)]="displayWebsiteAndOnlineStore"
    *ngIf="displayWebsiteAndOnlineStore"
>
<ng-template pTemplate="footer">
    <button pButton type="button" (click)="closeWebsiteAndOnlineModal()" class="p-button-secondary" label="Close"></button>
    <button pButton type="button" class="checkout-popup-btn-save" (click)="saveAndCloseWebsiteAndOnlineModal()"
        label="Next"></button>
</ng-template>
    <app-signup-website-and-online-store
        [showFooter]="false"
        [checkoutPage]="true"
        (calcEcommercePrice)="calcEcommercePrice()"
        (closeDialogEcomm)="closeDialogEcomm()"
    ></app-signup-website-and-online-store>
</p-dialog>

<p-dialog
    header="Hardware"
    modal="true"
    [closable] = "false"
    *ngIf="displayHardwareList"
    [(visible)]="displayHardwareList"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '95vw'}"
>
<ng-template pTemplate="footer">
    <button pButton type="button" (click)="closeHardWareModal()" class="p-button-secondary"
        label="Close"></button>
    <button pButton type="button" class="checkout-popup-btn-save" (click)="closeHardWareModal()"
        label="Next"></button>
</ng-template>
    <app-signup-hardware-list
        [showFooter]="false"
        (closeDialogHardware)="closeDialogHardware()"
    ></app-signup-hardware-list>
</p-dialog>

<p-dialog
    header="Customer App"
    modal="true"
    *ngIf="displayCustomerApp"
    [(visible)]="displayCustomerApp"
    [style]="{ width: '90vw' }"
>
    <app-customer-app-plans
        [showFooter]="false"
        (calcCustomerAppPrice)="calcCustomerAppPrice()"
        (closeDialogCustApp)="closeDialogCustApp()"
    ></app-customer-app-plans>
</p-dialog>

<p-dialog header="Loyalty Program" modal="true" [closable]="false" *ngIf="showChangeLoyaltyProgram"
    [(visible)]="showChangeLoyaltyProgram"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '75vw'}">
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="closeLoyaltyPointsModal()" class="p-button-secondary" label="Close"></button>
        <button pButton type="button" class="checkout-popup-btn-save" (click)="closeLoyaltyPointsModal()"
            label="Next"></button>
    </ng-template>
    <app-signup-loyalty-program [showFooter]="false" (closeDialogHardware)="closeDialogHardware()">
    </app-signup-loyalty-program>
</p-dialog>
