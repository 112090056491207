<p-overlayPanel
    #op
    [style]="{
        'min-width': '200px',
        padding: '-20px',
        margin: '-20px'
    }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="grid">
    <div class="mb-12 pt-2">
        <h1 class="fw-bolder wizard-title-inner d-flex align-items-center text-dark mx-2 wizard-title-active">
            User Setup
            <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                ngbTooltip="Billing is issued based on your selected account type"
            ></i>
        </h1>

        <div class="fs-6 mx-2 text-muted">
            Manage different users of your business
        </div>
    </div>
</div>

<div class="grid p-3 mt-4 mb-12" id="skip-warning" style="display:none">
    <div class="col-1 align-content-evenly">
        <span id="skip-icon" class="pi pi-info"></span>
    </div>
    <div class="col-9">
        <div class="grid">
            <div class="col-12">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    This step is optional for now!
                </h3>
            </div>
            <div class="col-12">
                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Dont worry! you would be able to add the user(s) to your
                    business from the dashboard settings.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-nowrap card-container mb-6 justify-content-end">
    <div class="flex align-items-center border-round mx-2">
        <button
            pButton
            class="light-button"
            type="button"
            label="New User"
            icon="pi pi-plus"
            *ngIf="allowUsers"
            (click)="this.addNewUser()"
            iconPos="left"
            [style]="{
                'margin-top': '30px',
                'min-width': '100%',
                width: '150px',
                height: '45px'
            }"
        ></button>
    </div>
</div>

<div
    class="fadeindown animation-duration-150 flex flex-nowrap card-container mb-12 justify-content-end"
>
    <div class="conn-card" style="width: 100%">
        <app-users-list [showReportTopFilter]="false" style="width: 100%" [businessSetup]="true"></app-users-list>
    </div>
</div>

<!-- <p-dialog
    *ngIf="_businessSetupService.showNewEditUser"
    [closable]="false"
    [modal]="true"
    header="New User"
    [visible]="true"
    [style]="{ width: '60%' }"
>
    <app-new-edit-user [isNew]="true" (onSubmit)="refrestDataList($event)"></app-new-edit-user>
</p-dialog> -->
