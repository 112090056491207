<p-overlayPanel #opNotes [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <textarea
            [rows]="5"
            [cols]="30"
            pInputTextarea
            [(ngModel)]="this.activeItem4Editing.lineNotes"
        ></textarea>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
<!-- [value]="dataList" -->
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="searchList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice: 0:48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div>
    <h2 class="bs-page-header mb-3">Inventory Adjustment</h2>
</div>

<div class="grid">
    <!-- <div class="xl:col-3 lg:col-3 sm:col-12 md:col-12">
        <div class="conn-card">
            <!-- <div class="text-muted">Dates</div>
            <br /> -->
            <!-- <div class="col">
                <div class="grid">
                    <div class="col-12">Creation Date</div>
                    <div class="col-12">
                        <p-calendar
                            [(ngModel)]="creationDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            [disabled]="true"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <!-- <div class="text-muted">Locations</div>
            <br /> -->
            <div class="col">
                <div class="grid">
                    <div class="col-12">From Location</div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="locations"
                            [(ngModel)]="fromLocation"
                            optionLabel="locationName"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                        >
                        </p-dropdown>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-12">Adjustment Type</div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="AdjustmentType"
                            optionLabel="title"
                            [(ngModel)]="adjustmentTypeModel"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-12">User Name</div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="userName"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>

<div class="conn-card">
    <br />
    <div class="grid">
        <div class="col-fixed" style="width: 300px">
            <div class="p-inputgroup">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search by scanning barcode"
                    [disabled]="isLoading || isViewOnly"
                    [(ngModel)]="searchText"
                    (keydown.enter)="searchButtonClick($event)"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [disabled]="isLoading || isViewOnly"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                ></button>
            </div>
        </div>
        <div class="col">
            <div class="flex justify-content-end flex-wrap">
                <div class="flex align-items-center justify-content-center m-2" *ngIf="!isViewOnly">
                     <button

                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                        iconPos="right"
                        (click)="removeItem()"
                        [disabled]="isViewOnly || isEnableDeleteButton"
                    ></button>
                    <!-- label="Delete" -->
                </div>

                <div
                    *ngIf="!isNew"
                    class="flex align-items-center justify-content-center m-2"
                >
                    <button
                        *ngIf="activeItem.transfersOutStatus == 1"
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-warning p-button-outlined'
                                : 'p-button-rounded p-button-info p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                    ></button>
                    <!-- label="Edit" -->
                </div>

                <div class="flex align-items-center justify-content-center">
                    <p-chip
                        *ngIf="!isNew"

                    ></p-chip>
                    <p-chip
                        *ngIf="isNew"
                        label="New"
                        [styleClass]="_abd.getTransfersOutClass(0)"
                    ></p-chip>
                </div>
            </div>
        </div>
    </div>

    <br />

    <div>
        <p-table
            [value]="dataList"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <p-checkbox
                        [(ngModel)]="selectAll"
                            [binary]="true"
                            [(ngModel)]="selectAll"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th>
                    <th>Sr#</th>
                    <th>Item</th>
                    <th>SKU</th>
                    <th>Barcode</th>
                    <th>QOH</th>
                    <th>Adjust Qty</th>
                    <th>UOM</th>
                    <th>Unit Cost ($)</th>
                    <th>Reason</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td>
                    <td>{{ item.srNo }}</td>
                    <td>

                         {{ item.productName }}
                    </td>

                     <td>
                        {{ item.skuCode }}
                    </td>
                    <td>
                        {{ item.barcode }}
                    </td>

                    <td>
                        {{item.stockInHand}}
                    </td>
                    <td>
                        <p-inputNumber
                        [(ngModel)]="item.quantity"
                        mode="decimal"
                        inputId="minmax-buttons"
                        placeholder="0"
                        [min]="-99999999"
                        [max]="99999999"
                        [disabled]="isViewOnly"
                        (onInput)="updateQuantity($event,item)"
                        [inputStyle]="{
                            width: '85px',
                            'text-align': 'center'
                        }"
                    >
                    </p-inputNumber>

                    </td>

                    <td>{{ item.uomDescription }}</td>
                   <td>
                    {{ item.costPrice | currency }}
                   </td>
                    <td>
                        <input type="text"   [disabled]="isViewOnly" [(ngModel)]="item.lineNotes" pInputText />
                    </td>

                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="9"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Products:
                    </td>
                    <td
                        style="text-align: left"
                        class="text-700 font-medium text-lg"

                    >
                        {{ calculateItemTotal() }}
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="9"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Cost of Adjustment:
                    </td>
                    <td style="text-align: left">
                        <p-inputNumber
                            [disabled]="true"
                            [(ngModel)]="productCost"
                            mode="currency"
                            currency="USD"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                            [inputStyle]="{
                                width: '',
                                'text-align': 'left'
                            }"
                        ></p-inputNumber>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div
        class="flex justify-content-end flex-wrap" *ngIf="!isViewOnly"

    >
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Cancel"
                (click)="cancelAdjustment()"
                [disabled]="isViewOnly"
                class="p-button-info"

            ></button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
            <p-button
                label="Post"
                (onClick)="saveAdjustmentInventory(1)"
                [disabled]="isViewOnly"
            ></p-button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"

        >
            <button
                pButton
                class="p-button-danger"
                label="Post and Create New"
                (click)="saveAdjustmentInventory(2)"
                [disabled]="isViewOnly"

            ></button>
        </div>
    </div>
</div>
