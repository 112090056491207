<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>DataView</h5>
			<p-dataView #dv [value]="products" [paginator]="true" [rows]="9" filterBy="name" [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
				<ng-template pTemplate="header">
					<div class="grid grid-nogutter flex-column md:flex-row justify-content-between">
						<p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)"></p-dropdown>
						<span class="p-input-icon-left mb-2 md:mb-0">
                            <i class="pi pi-search"></i>
                            <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
                        </span>	
						<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
					</div>
				</ng-template>

				<ng-template let-product pTemplate="listItem">
					<div class="col-12">
						<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
							<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name" class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5"/>
							<div class="flex-1 text-center md:text-left">
								<div class="font-bold text-2xl">{{product.name}}</div>
								<div class="mb-3">{{product.description}}</div>
								<p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
								<div class="flex align-items-center mt-2">
									<i class="pi pi-tag mr-2"></i>
									<span class="font-semibold">{{product.category}}</span>
								</div>
							</div>
							<div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
								<span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end">${{product.price}}</span>
								<p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'" class="mb-2"></p-button>
								<span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template let-product pTemplate="gridItem">
					<div class="col-12 md:col-4">
						<div class="card m-3 border-1 surface-border">
							<div class="flex align-items-center justify-content-between">
								<div class="flex align-items-center">
									<i class="pi pi-tag mr-2"></i>
									<span class="font-semibold">{{product.category}}</span>
								</div>
								<span [class]="'sm:ml-2 product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
							</div>
							<div class="text-center">
								<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name" class="w-9 shadow-2 my-3 mx-0"/>
								<div class="text-2xl font-bold">{{product.name}}</div>
								<div class="mb-3">{{product.description}}</div>
								<p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
							</div>
							<div class="flex align-items-center justify-content-between">
								<span class="text-2xl font-semibold">${{product.price}}</span>
								<p-button icon="pi pi-shopping-cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
		</div>
	</div>

	<div class="col-12 lg:col-8">
		<div class="card">
			<h5>PickList</h5>
			<p-pickList [source]="sourceCities" [target]="targetCities" sourceHeader="From" targetHeader="To" dragdrop="true"
						[responsive]="true" [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}">
				<ng-template let-city pTemplate="item">
					<div>{{city.name}}</div>
				</ng-template>
			</p-pickList>
		</div>
	</div>

	<div class="col-12 lg:col-4">
		<div class="card">
			<h5>OrderList</h5>
			<p-orderList [value]="orderCities" header="Cities" dragdrop="true" [listStyle]="{'height':'250px'}">
				<ng-template let-city pTemplate="item">
					<div>{{city.name}}</div>
				</ng-template>
			</p-orderList>
		</div>
	</div>
</div>
