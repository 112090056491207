<div class="p-3 h-full" *ngIf="!subscription.isCustom">
    <div
        class="shadow-2 p-3 h-full flex flex-column surface-card"
        style="border-radius: 6px"
    >
        <div
            *ngIf="subscription.recommended"
            class="text-900 font-medium text-xl mb-2 text-green-500 text-center"
            style="height: 2rem"
        >
            <strong>Recommended</strong>
        </div>
        <div class="text-900 font-medium text-xl mb-2">
            <strong>{{ subscription.title }}</strong>
        </div>
        <div class="text-600">{{ subscription.description }}</div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <div class="flex align-items-center" *ngIf="!subscription.isCustom">
            <span class="font-bold text-2xl text-900"
                >${{ subscription.price }}</span
            >
            <span class="ml-2 font-medium text-900">*</span>
        </div>
        <div class="text-600">
            {{ subscription.durationType.replace("/", " ") }}
        </div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <ul class="list-none p-0 m-0 flex-grow-1">
            <li
                class="flex align-items-center mb-3"
                *ngFor="let sub of subscription.features"
            >
                <span *ngIf="sub.isActive">
                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                    <span class="text-900">{{ sub.data }}</span>
                </span>
            </li>
        </ul>
        <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
        <button
            *ngIf="subscription.iisSelected"
            pButton
            pRipple
            label="Selected"
            class="p-3 w-full mt-auto"
        ></button>
        <button
            *ngIf="!subscription.iisSelected"
            pButton
            pRipple
            type="button"
            label="Select Plan"
            class="p-button-outlined p-button-help"
            (click)="subscriptionClicked()"
        ></button>
    </div>
</div>
