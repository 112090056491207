import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';

@Component({
  selector: 'app-bizz-customer-subscription-plan-details',
  templateUrl: './bizz-customer-subscription-plan-details.component.html',
  styleUrls: ['./bizz-customer-subscription-plan-details.component.scss']
})
export class BizzCustomerSubscriptionPlanDetailsComponent implements OnInit {
  localItem:any=''
  localObj:any=''
    planPrice: number = 0;
  constructor(
    public _bizzCustomerService: BizzCustomersService,
  ) { }

  ngOnInit(): void {
    this.localItem = this._bizzCustomerService.activeItem
    this.localObj = this.localItem.subscriptionInvoice.plans[0]

      this.planPrice = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[1].value
      if (this._bizzCustomerService.activeItem.subscriptionInterval == 12) {
          this.planPrice = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[0].value
      }
    //   let costHeading = "$" + planPricePerMonth + "/per month billed monthly"

    //   let selectedPlanInterval = this._bizzCustomerService.activeItem.subscriptionInterval
    //   if (selectedPlanInterval == 12) {
    //       costHeading = "$" + planPricePerMonth + "/per month X " + selectedPlanInterval + " = $" + planPricePerMonth * selectedPlanInterval + " billed annually"
    //   }
  }

}
