import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/_services/global.service';
import { BaseService } from 'src/app/_services/base.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';


@Injectable({
  providedIn: 'root'
})
export class CouponCodeService {

  _globals = GlobalService
  showEditCoupon: boolean = false;
  activeItem: any = null
  showNewEditCoupon: boolean = false;

  constructor(private _bs: BaseService,
              private _abd: AppBaseDataService) { }

  blankActiveItem(){
    this.activeItem = {
        Id: "0",
        discountName: '',
        appNotificationText:'',
        customerType:'',
        discountValueType:0,
        discountValue: '',
        maximumAllowed: '',//Max Discount
        maxCouponUse: '',
        validity: 1,
        validityStartDate:  new Date(new Date().setDate(new Date().getDate() - 7)).toString(),
        validityEndDate:new Date().toString(), 
        allowedDaysList: [],
        allowedLocationsList:[], //SubscriptionPlanList
        isActive: true,
        couponCodesList:'',
        printText:this._globals.whiteLabel.siteURL,
    }
  }

  getAllCouponCodes(model?:any){
    let url = environment.BusinessBaseURL + 'Coupon'
    return this._bs.httpGetParams(url, model).pipe(map((res: any) => {
      return res
    }))
  }

  addCoupon(model: any){
    let url = environment.BusinessBaseURL + 'Coupon'
    return this._bs.httpPost(url, model).pipe(map((res: any) => {
      return res
    }))
  }

  deleteCoupon(Id: any){
    let url = environment.BusinessBaseURL + 'Coupon' + "/" + Id;
    return this._bs.httpDelete(url).pipe(map((res: any) => {
      return res
    }))
  }

  updateCoupon(Id: any, model: any){
    let url = environment.BusinessBaseURL + 'Coupon' + "/" + Id;
    return this._bs.httpPut(url, model).pipe(map((res: any) => {
      return res
    }))
  }
}
