import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { CustomerService } from 'src/app/service/customerservice';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  totalRecords = 0

  searchText = ''
  lastSearchText = ''
  dataList: any = null

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(
    public _customerService: CustomerService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  makeParams() {
    let params = new HttpParams();

    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    params = params.append('pageNo', this.page.toString());
    params = params.append('pageSize', this.rows.toString());
    if (this.searchText.length) params = params.append('filterValue', this.searchText);

    return params
  }

  

  clearSearchText() {
    this.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }

  searchTextSearch() {
    if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return

    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

  implementLocalFilter() {
    
  }

  callbackFuncFromFilter(event) {
    this.implementLocalFilter()
  }

  filterButtonPressed() {
    this.implementLocalFilter()
  }

  selectAllChanged(event) {
    if (event.checked) {
      this._globals.showProductDelButton = true
      for (let i = 0; i < this._abd.vendors.length; i++) {
        this._abd.vendors[i].selected = true
      }
    } else {
      this._globals.showProductDelButton = false
      for (let i = 0; i < this._abd.vendors.length; i++) {
        this._abd.vendors[i].selected = false
      }
    }
  }

  selectionChanged(event) {
    let ret = false
    for (let i = 0; i < this._abd.vendors.length; i++)
      if (this._abd.vendors[i].selected) {
        ret = true
        break
      }

    this._globals.showProductDelButton = ret
  }

  optionDeleteClicked(){
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try{
          this._connMessageService.showToastMessage(`Vendor deleted!`, 'success')
        }
        catch{
          this._abd.hideSpinner()
        }
      },
      reject: () => {}
    })
  }

  exportPdf(){

  }
  
  exportExcel(){

  }

}
