<div class="conn-card" *ngIf="1 == 1">
    <app-report-top-filter-bar
        [paraReportName]="'inventory-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        *ngIf="dataList?.length > 0"
        #dt
        [columns]="csvcols"
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        [customSort]="true"
        [lazy]="true"
        (onLazyLoad)="customSort($event)"
        selectionMode="single"
        [(selection)]="selectedRow"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
        [lazy]="true"
        (onLazyLoad)="customSort($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <!-- <th pSortableColumn="locationName">
                    Location <p-sortIcon field="locationName"></p-sortIcon>
                </th> -->
                <th
                    *ngIf="_abd.locations.length > 1"
                    pSortableColumn="locationName"
                >
                    Location
                </th>
                <!-- <th>Department</th> -->
                <th pSortableColumn="itemName">
                    <p-sortIcon field="itemName"></p-sortIcon> Product
                </th>
                <th pSortableColumn="barcode">
                    <p-sortIcon field="barcode"></p-sortIcon> Barcode
                </th>
                <th pSortableColumn="skuCode">
                    <p-sortIcon field="skuCode"></p-sortIcon> SKU
                </th>
                <th style="text-align: center">Opening QTY</th>
                <th style="text-align: center">Purchasing</th>
                <th style="text-align: center">Transfer-Out</th>
                <th style="text-align: center">Transfer-In</th>
                <th style="text-align: center">Damage</th>
                <th style="text-align: center">Sold</th>
                <th style="text-align: center">Closing</th>
                <th style="text-align: center" title="Quantity on Hand">
                    Qty. On Hand
                </th>
                <!-- <th style="text-align: center"pSortableColumn="itemName" ><p-sortIcon field="itemName"></p-sortIcon>Variance</th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td *ngIf="_abd.locations.length > 1">
                    {{ item.locationName }}
                </td>
                <td [title]="item.itemName">
                    {{ item.itemName | slice : 0 : 20 }}
                    <span *ngIf="item.itemName.length > 20">...</span>
                </td>
                <!-- <td>{{ item.department }}</td> -->
                <td>{{ item.barcode }}</td>
                <td>{{ item.skuCode }}</td>

                <td style="text-align: center">{{ item.openingStock }}</td>
                <td style="text-align: center">{{ item.purchase }}</td>
                <td style="text-align: center">{{ item.transferOut }}</td>
                <td style="text-align: center">{{ item.transerIn }}</td>
                <td style="text-align: center">{{ item.damage }}</td>
                <td style="text-align: center">{{ item.usedSince }}</td>
                <td style="text-align: center">{{ item.closingStock }}</td>
                <td style="text-align: center">{{ item.stockInHand }}</td>
                <!-- <td style="text-align: center">{{ item.variance }}</td> -->
            </tr>
        </ng-template>
    </p-table>
    <div class="grid" *ngIf="dataList?.length == 0">
        <div class="text-align-center col-12" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
            <br />

            <br />
        </div>
    </div>
    <p-paginator
        #p
        [first]="first"
        *ngIf="dataList?.length > 0"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    ></p-paginator>
</div>

<p-sidebar
    *ngIf="gProductId"
    header="Item"
    [(visible)]="gProductId"
    [fullScreen]="true"
    class="productviewsidebar"
>
    <app-product-view-only [GID]="gProductId"></app-product-view-only>
</p-sidebar>
