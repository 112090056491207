import { Component, OnInit } from '@angular/core';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-connect360-return-and-refund',
  templateUrl: './connect360-return-and-refund.component.html',
  styleUrls: ['./connect360-return-and-refund.component.scss']
})
export class Connect360ReturnAndRefundComponent implements OnInit {
  _global = GlobalService
  whiteLabelList = WhiteLabelList
  who:any = null
  
  constructor() { }

  ngOnInit(): void {
    let host = window.location.host.toLowerCase()
    this.who = this.whiteLabelList.find(f=>host.includes(f.wlName.toLowerCase()))
    if(!this.who) this.who = WhiteLabelList[0] // --to be removed later
    this.who.name2parts = this.who.wlName.split('-')
  }

  getUrl() {
    return this._global.whiteLabel.siteURL
    if(!this.who) return
    let ret = this.who.website.substring(12)
    ret = ret.substring(0,ret.length-1)
    return ret
  }

}
