
    <div class="flex justify-content-center align-items-center flex-column h-screen">
        <div class="flex justify-content-center p-4 flex-column align-items-center ">
            <div class="flex justify-content-center my-1">
                <img src="assets/images/Verification.png" />
            </div>
            <div class="flex justify-content-center my-2 ">
                <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                    Your subscription has been started!</div>
            </div>
        </div>

        <div class="flex align-items-end justify-content-center">

            <a class="btn p-button-link cursor-pointer text-lg"
                style="color: var(--brandColor); cursor:pointer;"
               
                (click)="login()"
                >
                <div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">
                    Click here to login    <i class="pi pi-arrow-right mx-2"></i>
                </div>
            </a>
        </div>
    </div>
     <div class="flex justify-content-center align-items-center flex-column h-screen" *ngIf="!isRealAccountAccess">
         <div class="flex justify-content-center p-4 flex-column align-items-center ">

             <div class="flex justify-content-center my-2 ">
                 <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                    You don't have not access to this page we are redirecting you to login page in ({{timeLeft}}
                    seconds)...</div>
             </div>
         </div>
     </div>

