<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Filter Menu</h5>
			<p-table #dt1 [value]="customers1" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
				<ng-template pTemplate="caption">
					<div class="flex justify-content-between flex-column sm:flex-row">
						<button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search Keyword" class="w-full"/>
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th>
							<div class="flex justify-content-between align-items-center">
								Name
								<p-columnFilter type="text" field="name" display="menu" placeholder="Search by name"></p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Country
								<p-columnFilter type="text" field="country.name" display="menu" placeholder="Search by country"></p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Agent
								<p-columnFilter field="representative" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
									<ng-template pTemplate="header">
										<div class="px-3 pt-3 pb-0">
											<span class="font-bold">Agent Picker</span>
										</div>
									</ng-template>
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
											<ng-template let-option pTemplate="item">
												<div class="p-multiselect-representative-option">
													<img [alt]="option.label" src="assets/demo/images/avatar/{{option.image}}" width="32" style="vertical-align: middle" />
													<span class="ml-2">{{option.name}}</span>
												</div>
											</ng-template>
										</p-multiSelect>
									</ng-template>
								</p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Date
								<p-columnFilter type="date" field="date" display="menu" placeholder="mm/dd/yyyy"></p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Balance
								<p-columnFilter type="numeric" field="balance" display="menu" currency="USD"></p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Status
								<p-columnFilter field="status" matchMode="equals" display="menu">
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any" [style]="{'min-width': '12rem'}" >
											<ng-template let-option pTemplate="item">
												<span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
											</ng-template>
										</p-dropdown>
									</ng-template>
								</p-columnFilter>
							</div>
						</th>
						<th>
							<div class="flex justify-content-between align-items-center">
								Activity
								<p-columnFilter field="activity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
									<ng-template pTemplate="filter" let-filter="filterCallback">
										<p-slider [ngModel]="activityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3" [style]="{'min-width': '12rem'}" ></p-slider>
										<div class="flex align-items-center justify-content-between px-2">
											<span>{{activityValues[0]}}</span>
											<span>{{activityValues[1]}}</span>
										</div>
									</ng-template>
								</p-columnFilter>
							</div>
						</th>
						<th style="width: 8rem">
							<div class="flex justify-content-between align-items-center">
								Verified
								<p-columnFilter type="boolean" field="verified" display="menu"></p-columnFilter>
							</div>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer>
					<tr>
						<td style="min-width: 12rem;">
							{{customer.name}}
						</td>
						<td style="min-width: 12rem;">
							<img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text ml-2">{{customer.country.name}}</span>
						</td>
						<td style="min-width: 14rem;">
							<img [alt]="customer.representative.name" src="assets/demo/images/avatar/{{customer.representative.image}}" width="32" style="vertical-align: middle"/>
							<span class="image-text ml-2">{{customer.representative.name}}</span>
						</td>
						<td style="min-width: 10rem;">
							{{customer.date | date: 'MM/dd/yyyy'}}
						</td>
						<td style="min-width: 10rem;">
							{{customer.balance | currency:'USD':'symbol'}}
						</td>
						<td style="min-width: 12rem;">
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td style="min-width: 12rem; ">
							<p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>
						</td>
						<td class="text-center" style="min-width: 8rem;">
							<i class="pi" [ngClass]="{'true-icon pi-check-circle text-green-500': customer.verified, 'false-icon pi-times-circle text-pink-500 ': !customer.verified}"></i>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="7">No customers found.</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="loadingbody">
					<tr>
						<td colspan="7">Loading customers data. Please wait.</td>
					</tr>
				</ng-template>
    		</p-table>
		</div>

	</div>

	<div class="col-12">
		<div class="card">
			<h5>Frozen Columns</h5>
			<p-toggleButton [(ngModel)]="idFrozen" [onIcon]="'pi pi-lock'" offIcon="pi pi-lock-open" [onLabel]="'Unfreeze Id'" offLabel="Freeze Id" [style]="{'width': '12rem'}"></p-toggleButton>

			<p-table [value]="customers3" scrollDirection="both" [scrollable]="true" scrollHeight="400px" styleClass="mt-3" responsiveLayout="scroll">
				<ng-template pTemplate="header">
					<tr>
						<th style="width:200px" pFrozenColumn>Name</th>
						<th style="width:200px" alignFrozen="left" pFrozenColumn [frozen]="idFrozen">Id</th>
						<th style="width:200px">Country</th>
						<th style="width:200px">Date</th>
						<th style="width:200px">Company</th>
						<th style="width:200px">Status</th>
						<th style="width:200px">Activity</th>
						<th style="width:200px">Representative</th>
						<th style="width:200px" pFrozenColumn alignFrozen="right">Balance</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer>
					<tr>
						<td style="width:200px" pFrozenColumn>{{customer.name}}</td>
						<td style="width:200px" alignFrozen="left" pFrozenColumn [frozen]="idFrozen">{{customer.id}}</td>
						<td style="width:200px">
							<img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text ml-2">{{customer.country.name}}</span>
						</td>
						<td style="width:200px">{{customer.date}}</td>
						<td style="width:200px">{{customer.company}}</td>
						<td style="width:200px">
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td style="width:200px">{{customer.activity}}</td>
						<td style="width:200px">
							<img [alt]="customer.representative.name" src="assets/demo/images/avatar/{{customer.representative.image}}" width="32" style="vertical-align: middle"/>
							<span class="image-text ml-2">{{customer.representative.name}}</span>
						</td>
						<td style="width:200px" pFrozenColumn alignFrozen="right">{{formatCurrency(customer.balance)}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="col-12">
		<div class="card">
			<h5>Row Expand</h5>
			<p-toast></p-toast>
			<p-table [value]="products" dataKey="name" [expandedRowKeys]="expandedRows" responsiveLayout="scroll">
				<ng-template pTemplate="caption">
					<button pButton icon="pi pi-fw {{isExpanded ? 'pi-minus' : 'pi-plus'}}" label="{{isExpanded ? 'Collapse All' : 'Expand All'}}" (click)="expandAll()"></button>
					<div class="flex table-header">
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem"></th>
						<th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
						<th>Image</th>
						<th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
						<th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
						<th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
						<th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-product let-expanded="expanded">
					<tr>
						<td>
							<button type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
						</td>
						<td style="min-width: 12rem;">{{product.name}}</td>
						<td><img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name" width="100" class="shadow-4" /></td>
						<td style="min-width: 8rem;">{{product.price | currency:'USD'}}</td>
						<td style="min-width: 10rem;">{{product.category}}</td>
						<td style="min-width: 10rem;"><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
						<td><span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span></td>
					</tr>
				</ng-template>
				<ng-template pTemplate="rowexpansion" let-product>
					<tr>
						<td colspan="7">
							<div class="p-3">
								<p-table [value]="product.orders" dataKey="id" responsiveLayout="scroll">
									<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th>
						<th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
						<th pSortableColumn="date">Date <p-sortIcon field="date"></p-sortIcon></th>
						<th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
						<th pSortableColumn="stats">Status <p-sortIcon field="status"></p-sortIcon></th>
						<th style="width: 4rem"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-order>
					<tr>
						<td>{{order.id}}</td>
						<td>{{order.customer}}</td>
						<td>{{order.date}}</td>
						<td>{{order.amount | currency:'USD'}}</td>
						<td><span [class]="'order-badge order-' + order.status.toLowerCase()">{{order.status}}</span></td>
						<td><p-button type="button" icon="pi pi-search"></p-button></td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">There are no order for this product yet.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		</td>
		</tr>
		</ng-template>
		</p-table>
	</div>
	</div>

	<div class="col-12">
		<div class="card">
			<h5>Subheader Grouping</h5>
			<p-table [value]="customers3" rowGroupMode="subheader" groupRowsBy="representative.name" sortField="representative.name" sortMode="single" (onSort)="onSort()" responsiveLayout="scroll" [scrollable]="true" scrollHeight="400px">
				<ng-template pTemplate="header">
					<tr>
						<th>Name</th>
						<th>Country</th>
						<th>Company</th>
						<th>Status</th>
						<th>Date</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
					<tr pRowGroupHeader *ngIf="rowGroupMetadata[customer.representative.name].index === rowIndex">
						<td colspan="5" style="min-width: 200px;">
							<img [alt]="customer.representative.name" src="assets/demo/images/avatar/{{customer.representative.image}}" width="32" style="vertical-align: middle" />
							<span class="font-bold ml-2">{{customer.representative.name}}</span>
						</td>
					</tr>
					<tr>
						<td style="min-width: 200px;">
							{{customer.name}}
						</td>
						<td style="min-width: 200px;">
							<img src="assets/demo/flags/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text" style="margin-left: .5em">{{customer.country.name}}</span>
						</td>
						<td style="min-width: 200px;">
							{{customer.company}}
						</td>
						<td style="min-width: 200px;">
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td style="min-width: 200px;">
							{{customer.date}}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>
