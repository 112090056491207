<div class="flex  loyalty-program perks-popup">
    <form [formGroup]="regularForm" class="w-4" (keydown.enter)="$event.preventDefault()"  (ngSubmit)="saveForm()"
        autocomplete="off">
        <div class="col-12 md:col-12  mb-5">
            <div class="p-fluid">
                <div class="field">
                    <label htmlfor="loyaltyPoints">Each Points Equal to ($) <span class="text-red-500">*</span></label>
                    <p-inputNumber
                        inputId="locale-us"
                        mode="decimal"
                        locale="en-US"
                        [min]="0"
                        [max]="1000000"
                        formControlName="loyaltyPoints"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                    <div class="text-sm text-bluegray-300 mt-1">Define value of each point to a dollar ($) i.e. 1 Point
                        =$X</div>
                    <div *ngIf="(!regularForm.get('loyaltyPoints')?.valid
                            && (regularForm.get('loyaltyPoints')?.dirty || regularForm.get('loyaltyPoints')?.touched)
                        )
                            ">
                        <div class="input-required mt-1"
                            [hidden]="regularForm.get('loyaltyPoints').value?.length == 0 && !regularForm.get('loyaltyPoints').errors.required">
                            Please enter a Loyalty Point Value
                        </div>
                    </div>
                </div>
                 <div class="field">
                     <label htmlfor="pointRedemptionThreshold">Minimum Points required to redeem <span class="text-red-500">*</span></label>

                     <p-inputNumber
                        inputId="locale-us"
                        mode="decimal"
                        locale="en-US"
                        [min]="0"
                        formControlName="pointRedemptionThreshold"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                     <div class="text-sm text-bluegray-300 mt-1">Minimum points needed to trigger redemption i.e. X
                         Points</div>
                     <div *ngIf="(!regularForm.get('pointRedemptionThreshold')?.valid
                            && (regularForm.get('pointRedemptionThreshold')?.dirty || regularForm.get('pointRedemptionThreshold')?.touched)
                        )
                            ">
                         <div class="input-required mt-1"
                             [hidden]="regularForm.get('pointRedemptionThreshold').value?.length == 0 && !regularForm.get('pointRedemptionThreshold').errors.required">
                             Please enter a Point Redemption Threshold
                         </div>
                     </div>
                 </div>
            </div>
        </div>
        <div class="grid" style="margin-top: 10px">
            <div class="col" style="margin-top: 7px">
                <div class="flex justify-content-start flex-wrap card-container green-container mt-3">
                    <div class="flex align-items-center justify-content-center">
                        <button
                            [disabled]="!regularForm.valid"
                            pButton pRipple
                            type="submit"
                            label="Save"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
