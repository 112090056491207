import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaxConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-tax-setting',
  templateUrl: './tax-setting.component.html',
  styleUrls: ['./tax-setting.component.scss']
})
export class TaxSettingComponent implements OnInit {
  taxform: FormGroup
  taxSettingData: TaxConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();

  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService,

              ) { }

  ngOnInit(): void {

    this.taxSettingData = {
      tax1: this._abd.systemConfigs.taxConfig.tax1,
      // active: this._abd.systemConfigs.taxConfig.active,
    }
  }

  ngAfterContentInit() {
    this.initForm()
    // const enable=this.taxform.get('enableTaxForm');
    // enable?.valueChanges
    //   .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){
    // if(value==false){
    //   this.taxform.get('taxAmount')?.disable();
    // }else if (value==true){
    //   this.taxform.get('taxAmount')?.enable();
    // }
  }

  initForm() {
    this.taxform = this._fb.group({
      enableTaxForm: [this.taxSettingData.active],
      taxAmount: [this.taxSettingData.tax1, [Validators.required, Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]]
    })
  }

  get f() {
    return this.taxform.controls;
  }

  saveFn(){
    let taxValue: TaxConfig = {
      tax1: this.taxform.get('taxAmount').value,
      // active: this.taxform.get('enableTaxForm').value,
        isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveTaxSetting(taxValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.taxConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Tax rate saved successfully!`, 'success')
          this._businessSetupService.showTaxSetting = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("tax");

              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving tax rate!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showTaxSetting = false
  }
}
