import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { LocationsService } from 'src/app/_services/locations.service';

@Component({
  selector: 'app-location-information',
  templateUrl: './location-information.component.html',
  styleUrls: ['./location-information.component.scss']
})
export class LocationInformationComponent implements OnInit {
  rows = 10
  totalRecords = 0

  selectedRow: any = ''
  searchText = ''
  lastSearchText = ''
  dataList: any = null

  isViewOnly = false
  isNew = false

  addLocationLabel='New Location'
  disableAdd=false

  @ViewChild('op') op: OverlayPanel
  colheader = [[
    'Location', 'Manage Inventory on Store', 'Allow Shipping', 'Accept Online Orders'
    , 'Phone', 'Station'
  ]];

  colflds = ['locationName', 'manageInventory', 'allowShipping', 'acceptOnlineOrders',
    'phone'
  ];



  constructor(
    public _businessSetupService: BusinessSetupService,
    public _abd: AppBaseDataService,
    public _locationService: LocationsService,
    private _connMessageService: ConnMessageService,

  ) {}

  ngOnInit(): void {
    this._abd.initLocations(this._abd.initActLocations())

    if (this._locationService.isLocationAvailable()) {
      this.addLocationLabel = 'New Location'
    }
    else {
      this.addLocationLabel = 'Location limit exceeded.'
        this.disableAdd = this._locationService.isLocationAvailable() ? false : true;
    }
  }

  addLocation() {
    this.isNew = true;
    this.isViewOnly=false
      this.disableAdd = this._locationService.isLocationAvailable() ? false : true;
      this._locationService.blankActiveItem();
    this._businessSetupService.isEditLocation = true;
  }

  actionButtonClick(item: any) {
    if (this._locationService.isLocationAvailable())
      this._businessSetupService.activeItem = JSON.parse(JSON.stringify(item))
      else
      this._connMessageService.showToastMessage('Location limit exceeded.', 'error')
  }

  optionDeleteClicked() {
      this.disableAdd = this._locationService.isLocationAvailable() ? false : true;
  }

    locationDeleted(e:any){
        this.disableAdd = this._locationService.isLocationAvailable() ? false : true;
        if (this._locationService.isLocationAvailable()) {
            this.addLocationLabel = 'New Location'
        }
    }
}
