import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'app-image-multi',
  templateUrl: './image-multi.component.html',
  styleUrls: ['./image-multi.component.scss']
})
export class ImageMultiComponent implements OnInit {
  fileToUpload: any;
  imageData = "assets/150-26.jpg";

  constructor(
    public _productService: ProductsService
  ) { }

  ngOnInit(): void {
  }

  handleFileInput(event: any) {
    let file = event.target.files;
    this.fileToUpload = file.item(0);
    let fileName = this.fileToUpload.name
    const self = this
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageData = event.target.result;
    }
    reader.onloadend = function () {
      self._productService.thImages.push(reader.result)
      self._productService.thImageNames.push(fileName)
      // //console.log(self._productService.thImageNames)
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  handleFileInputMultiple(event: any) {
    try {
      if (this._productService.thImages.length >= 10) {
        alert('images limit exceeded')
        return
      }
    }
    catch { }

    let file = event.target.files;
    const self = this
    for (let i = 0; i < file.length; i++) {
      this.fileToUpload = file.item(i);
      let fileName = this.fileToUpload.name
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageData = event.target.result;
      }
      reader.onloadend = function () {
        self._productService.thImages.push(reader.result)
        self._productService.thImageNames.push(fileName)
        // //console.log(self._productService.thImageNames)
      }
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  onRemoveClick(event: any, i: any) {
    this._productService.thImages.splice(i, 1)
    this._productService.thImageNames.splice(i, 1)
  }

  ok2AddThumbnails() {
    try {
      if (this._productService.thImages.length <= 9)
        return true
    }
    catch { return true }
    return false
  }

}
