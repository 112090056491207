import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppConfig } from 'src/app/api/appconfig';
import { ConfigService } from 'src/app/service/app.config.service';
import { GlobalService } from 'src/app/_services/global.service';
import { NextLevelFormIntegrationService } from 'src/app/_services/next-level-form-integration.service';

@Component({
  selector: 'app-next-level-form-integration',
  templateUrl: './next-level-form-integration.component.html',
  styleUrls: ['./next-level-form-integration.component.scss']
})
export class NextLevelFormIntegrationComponent implements OnInit {

    _globals = GlobalService;
    config: AppConfig;

    items: MenuItem[];

    activeIndex: number = 0;

    value1!: string;

    tabList: any[] = [];

    constructor(
        public _configService: ConfigService,
        public _nextLevelIntegrationService: NextLevelFormIntegrationService) { }

    ngOnInit(): void {
        document.documentElement.style.setProperty(
            '--brandColor',
            this._globals.whiteLabel.brandColor
        );
        document.documentElement.style.setProperty(
            '--bs-primary',
            this._globals.whiteLabel.brandColor
        );
        this.config = this._configService.config;

        this.tabList = [
            { label: 'Owner(s) Details', disabled: false },
            { label: 'Beneficiaries Details', disabled: true },
            { label: 'Business & Other Details', disabled: true },
        ];
    }

    onTabItemClick(tab: any) {
        //this.activeItem = tab
    }

    ownersFormDetailsSave(e: any) {
        this.tabList[1].disabled = false;
        this.activeIndex = 1;
    }

    backStep() {
        this.activeIndex = this.activeIndex - 1;
    }

    beneficiariesFormSaved() {
        this.tabList[2].disabled = false;
        this.activeIndex = 2;
    }

    saveBusinessAndOther(e: any) {
        // console.log(this.activeIndex);
    }


}
