import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-subscription-plans-header-desktop',
  templateUrl: './subscription-plans-header-desktop.component.html',
  styleUrls: ['./subscription-plans-header-desktop.component.scss']
})
export class SubscriptionPlansHeaderDesktopComponent implements OnInit {
  planButtons = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Annually (Saves upto 30%)', value: 'Annually (Saves upto 30%)' },
  ]

  outerSelectedPlanTime: string = 'Monthly'
    @Input() currentSubscriptionId: number = 1;
    @Output() subscriptionDurationChanged = new EventEmitter();
  constructor(
    public _signup: SignupService,
  ) { }

    ngOnInit(): void {


    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes['currentSubscriptionId'];
            let cur = JSON.stringify(chng?.currentValue);
            let prev = JSON.stringify(chng?.previousValue);

            this._signup.planDurationSet();
            this._signup.outerSelectedPlanTime = (this._signup.selectedPlanTimeframe == 'BilledMonthly' ? 'Monthly' : 'Annually (Saves upto 30%)');
        }
    }

  changeOption(e: any) {
    if (e.value=='Monthly'){
        this._signup.selectedPlanTimeframe = 'BilledMonthly'
        this.subscriptionDurationChanged.emit('BilledMonthly');
    }

    else{
        this._signup.selectedPlanTimeframe = 'BilledAnnually'
        this.subscriptionDurationChanged.emit('BilledMonthly');
    }

  }

}
