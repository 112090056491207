<p-overlayPanel #op [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }">
    <ng-template pTemplate>
        <!-- <div class="conn-grid" style="margin-top: 1px"> -->
        <div></div>
        <button pButton pRipple type="button" 
        label="View" class="p-button-text" [style]="{ width: '100%' }"
            icon="pi pi-eye" iconPos="right" (click)="ViewCustomer()"></button>
            <!-- *ngxPermissionsOnly="['2060']" -->
        <button pButton pRipple type="button" label="Edit Info"
        class="p-button-text" [style]="{ width: '100%' }"
            icon="pi pi-pencil" iconPos="right" (click)="optionEditClicked()"></button>
            <!-- *ngxPermissionsOnly="['2047']"    -->
        <button pButton pRipple type="button" label="Change Status" class="p-button-danger p-button-text"
        [style]="{ width: '100%' }"  icon="pi pi-users" iconPos="right" (click)="optionDeleteClicked()"></button>
        <!-- </div> -->
    </ng-template>
</p-overlayPanel>

<!-- this is the one -->
<div class="fadeindown animation-duration-500 flex" *ngIf="1 == 1">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar [paraReportName]="'Customers'" (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()" (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()" (exportCsvFn)="dt.exportCSV()" (deleteItemFn)="deleteMultiple()"
            (addNewFn)="addNewFn($event)"></app-report-top-filter-bar>
        <br />
        <p-table *ngIf="dataList?.length > 0" #dt [columns]="csvcols" styleClass="p-datatable-striped"
            [autoLayout]="true" [tableStyle]="{ width: 'max-content' }" [value]="dataList" responsiveLayout="scroll"
            showLoader="true" rowHover="true" selectionMode="single" [(selection)]="selectedRow" dataKey="id"
            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">
                        <!-- <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        ></p-checkbox> -->
                    </th>

                    <th style="text-align: center">Name</th>
                    <th style="text-align: center">Phone</th>
                    <th style="text-align: center">City</th>
                    <th style="text-align: center">State</th>
                    <th style="text-align: center">Zip</th>
                    <th style="text-align: center">Reward Points</th>
                    <th style="text-align: center">Reward Points Value</th>
                    <th style="text-align: center">Status</th>
                    <th style="text-align: center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" class="text-center {{
                        item.selected == true ? 'p-highlight' : ''
                    }}">
                    <td style="text-align: center">
                        <!-- <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged(item)"
                        ></p-checkbox> -->
                    </td>

                    <td>
                        <span class="conn-flex-image-first-letter">{{ item.firstName | slice : 0 : 1
                            }}{{ item.lastName | slice : 0 : 1 }}</span>
                        {{ item.firstName + " " + item.lastName }}
                    </td>

                    <td style="text-align: center" *ngIf="item.phone.length >= 10">
                        {{ _globals.generatePhoneFormatUS(item.phone) }}
                    </td>
                    <td style="text-align: center" *ngIf="item.phone.length < 10"></td>
                    <td style="text-align: center">{{ item.city }}</td>
                    <td style="text-align: center">{{ item.state }}</td>
                    <td style="text-align: center">{{ item.postalCode }}</td>
                    <td style="text-align: center">{{ item.rewardPoints }}</td>
                    <td style="text-align: center">{{ item.rewardPointsValue | currency }}</td>
                    <td style="text-align: center;padding:20%" class="{{
                            item.status == true
                                ? 'Active badge'
                                : 'In-Active badge'
                        }}">
                        <span>{{
                            item.status == true ? "Active" : "Inactive"
                            }}</span>
                    </td>

                    <td style="text-align: center">
                        <button pButton pRipple icon="pi pi-angle-down" iconPos="right" type="button" label="Actions"
                            class="n-action-button" (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"></button>

                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex" *ngIf="dataList?.length == 0">
            <div class="text-align-center" style="width: 100%">
                <span class="control-description text-muted text-align-center">
                    No data found!</span>
            </div>
        </div>
        <p-paginator #p [first]="first" *ngIf="dataList?.length > 0" (onPageChange)="onPageChange($event)" [rows]="rows"
            [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"></p-paginator>
    </div>
</div>

<ng-container *ngIf="_customerService.showNewNewEdit">
<p-dialog #p  header="Create Customer " [(visible)]="_customerService.showNewNewEdit"
    [style]="{ width: '60%' }" modal="true">
    <app-customer-info-change [isNew]="true" (refreshDataEmitter)="getDataList()"></app-customer-info-change>
</p-dialog>
</ng-container>
<ng-container *ngIf="_customerService.showNewEdit">
<p-dialog #p  header="Edit Customer " [(visible)]="_customerService.showNewEdit"
    [style]="{ width: '60%' }" modal="true">
    <app-customer-info-change #changecustomer [isNew]="false"
        (refreshDataEmitter)="getDataList()"></app-customer-info-change>
</p-dialog>
</ng-container>
