import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  filterInventoryCount = {
    searchText: '',
    selectedLocation: '',
    selectedCategory: '',
    selectedVendor: '',
    selectedManufacturer: '',
    dateRange: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]
  }

  constructor(private _bs: BaseService) { }

  getAllDepartments(params: any) {
    let url = environment.InventoryBaseURL + environment.GetDepartmentList
    return this._bs.httpGetParams(url, params).pipe(map(res => {
      return res
    }))
  }

  getAllLocations() {
    let url = environment.BusinessBaseURL + environment.GetAllLocations
    return this._bs.httpGet(url).pipe(map(res => {
      return res
    }))
  }

  saveInventoryVerificationCount(model: any) {
    const url = environment.InventoryBaseURL + environment.InventoryVerificationCount
    return this._bs.httpPost(url, model).pipe(map(res => {

      return res
    }))
  }




}
