import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-sales-report',
  templateUrl: './category-sales-report.component.html',
  styleUrls: ['./category-sales-report.component.scss']
})
export class CategorySalesReportComponent implements OnInit {
  _globals = GlobalService

  colheader= [['Group Value', 'Total Sales', 'Invoice Count', 'Total Profit']];
  colflds= ['groupValue', 'totalSales', 'invoiceCount', 'totalProfit'];
  summaryHeader= [['Group Value', 'Total Sales ($)', 'Invoice Count', 'Total Profit ($)']];
  summaryflds= ['totalSales', 'invoiceCount', 'totalProfit'];

  domColHeader = this.colheader[0];
  domSummaryflds = this.summaryflds;


  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';
  // public groupby$: Observable<GroupBy[]>;
  printOnce: boolean = true;

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3,Value:'All' }
    this._abd.groupby = [
      { id: "Category", title: "Category" },
      { id: "Location", title: "Store" },
      { id: "Date", title: "Date" }
    ]
    this._filterService.selectedGroup=this._abd.groupby[0]

    this._globals.componentName = 'Category Sales Report'

    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    // this._abd.selectedCategory = this._abd.actCategories[0];
    // this._abd.groupby$.subscribe(x => this._abd.selectedGroup = x[0]);
    this.getDataList()
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDepartmentList() {
    //
    let self = this;
    this.catIds = [];
    let selectedOpt = this._filterService.selectedCategory
    selectedOpt = (typeof selectedOpt != 'undefined' && selectedOpt instanceof Array) ? selectedOpt : [selectedOpt];
    try {
      selectedOpt.map(value => {

        if (value != "" && value.id != undefined){
            self.catIds.push(value.id)
        }

      })
    }
    catch {

    }
  }

  getGroupbyList() {
    try {

      this.groupby = this._filterService.selectedGroup.id;
    } catch {}
  }

  getDataList() {
    this.getLocationsList();
    this.getDepartmentList();
    this.getGroupbyList();

    let sp = this._filterService.selectedProductSellTypeFilter
    if(!sp) sp=this._abd.groupby[0]
    const data = {
      "ReportName": 'CategoriesSalesReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "CategoriesIds": this.catIds,
        "GroupBy": this.groupby,
        'OrderPlacement' : sp.Value
      }
    }


    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
    })
  }

  filterButtonPressed(event) {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Category Sales Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Category Sales Report', true)
  }

  calculateSummaryTotal(col: string) {
    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("totalinvoices")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else if (col.includes("taxRete")) {
        return this.dataList.reduce((sum, item) => item[col], 0).toFixed(2) + " %";
      }
      else {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
    }
    return total;

  }
}
