<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="_vendorService.showNewEdit = true"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'vendors'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
        (deleteItemFn)="optionDeleteClicked()"
        (addNewFn)="addNewFn($event)"
    ></app-report-top-filter-bar>
    <br />

    <p-table
        #dt
        [columns]="csvcols"
        styleClass="p-datatable-striped"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
        [lazy]="true"
        (onLazyLoad)="customSort($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center; width: 30px">
                    <p-checkbox
                        [(ngModel)]="selectAll"
                        [binary]="true"
                        (onChange)="selectAllChanged($event)"
                        (onClick)="selectAllChanged($event)"
                    ></p-checkbox>
                </th>
                <th pSortableColumn="vendorName">
                    <p-sortIcon field="vendorName"></p-sortIcon>Vendor
                </th>
                <th>Company</th>
                <th>Phone</th>
                <th>Email</th>
                <th>State</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td style="text-align: center">
                    <p-checkbox
                        [(ngModel)]="item.selected"
                        [binary]="true"
                        (onChange)="selectionChanged($event)"
                    ></p-checkbox>
                </td>

                <td>
                    <span class="conn-flex-image-first-letter">
                        {{ item.vendorName | slice : 0 : 2 }}</span
                    >
                    {{ item.vendorName }}
                </td>
                <td>{{ item.company }}</td>
                <td>{{ _globals.generatePhoneFormatUS(item.phone) }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.stateName }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="10" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator
        #p
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    ></p-paginator>
</div>

<p-dialog
    *ngIf="_vendorService.showNewEdit"
    header="Edit Vendors "
    [(visible)]="_vendorService.showNewEdit"
    modal="true"
>
    <app-new-edit-vendor [isNew]="false"></app-new-edit-vendor>
</p-dialog>

<p-dialog
    *ngIf="_vendorService.showNewNewEdit"
    header="Edit Vendors "
    [(visible)]="_vendorService.showNewNewEdit"
    modal="true"
>
    <app-new-edit-vendor
        [isNew]="true"
        (refreshFn)="refreshFn($event)"
    ></app-new-edit-vendor>
</p-dialog>
