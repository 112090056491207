<div class="flex py-3 flex-column justify-content-center signup-subscription">
    <!-- <div class="flex justify-content-center  align-items-start my-2" *ngIf="!showSpinner">
        <div class="flex">
            <img [src]="_globals.whiteLabel.logo" alt="logo" width="150px" />
        </div>
    </div> -->
    <!-- <div class="flex justify-content-center align-items-center" *ngIf="showSelectedPlan === 'true'">

        <div class="default-cursor text-xl flex justify-content-center text-left font-bold text-lg"
            *ngIf="_signup.currentStep < 7 && selectedPlan">
            Your selected plan: <span (click)="backClicked('true')"
                class="selected-plan  page-heading-color">{{selectedPlan.title
                }}
                <span>
                    <i class="pi pi-pencil icon"></i>
                </span>
            </span>
        </div>
    </div> -->

    <div class="flex justify-content-center align-items-center my-2" *ngIf="showSelectedPlan === 'false'">
        <!-- <div class="flex justify-content-between align-items-center w-4">

            <div class="default-cursor text-xl flex justify-content-center text-left font-bold text-lg"
                *ngIf="_signup.currentStep < 7 && selectedPlan">
                Your selected plan: <span (click)="backClicked('false')"
                    class="selected-plan page-heading-color">{{selectedPlan.title
                    }}
                    <span>
                        <i class="pi pi-pencil icon"></i>
                    </span>
                </span>
            </div>
            <div class="default-cursor text-xl flex justify-content-center text-left font-bold text-lg"
                *ngIf="_signup.currentStep < 7 && currentSubscriptionPlan">
                Your current plan: <span class="default-cursor page-heading-color">{{currentSubscriptionPlan.title
                    }}
                </span>
            </div>
        </div> -->
    </div>

    <div class="flex justify-content-center flex-row align-items-start">
        <div class="flex justify-content-center w-11">
            <div class="col-3 lg:col-4 xl:col-3  xxl:col-3" *ngIf="!showSpinner && _signup.currentStep > 0">
                <div class="conn-card">
                    <app-left-navigator [currentStep]="_signup.currentStep"></app-left-navigator>
                </div>
            </div>
            <div class="col lg:col-8 xl:col-9  xxl:col-9">
              <div class="c top-radious justify-content-center flex-row flex-wraps align-content-center top-logo  p-5 m-0" *ngIf="!showSpinner && _signup.currentStep > 0">
                <div class="flex justify-content-center flex-row flex-wraps align-content-center">
                  <img [src]="_globals.whiteLabel.logo" alt="logo" width="180px" />
               </div> 
             
               <span class="flex justify-content-center align-items-center font-bold text-lg py-3">
                Your selected plan:&nbsp; <span class="period">{{_signup.selectedPlan.title}}</span>  
               </span>
               <!-- <span class="flex justify-content-center align-items-center font-bold text-lg period py-2">
                30-Day Free Trile
               </span>
               <p class="flex justify-content-center align-items-center  py-2 p-text">
                Unlimited access to all features. No credit card required.
               </p> -->
                 </div>
                <div class="conn-card  bottom-radious px-5">
                    <div class="flex flex-wrap justify-content-between" *ngIf="!showSpinner && _signup.currentStep > 0 ">
                        <div
                            *ngIf=" _signup.steps[_signup.currentStep - 1] != undefined &&  _signup.steps[_signup.currentStep - 1] != null">
                            <div class="font-bold text-3xl page-heading-color" *ngIf="_signup.currentStep !== 3">
                                <span *ngIf="_signup.currentStep < 7">
                                    {{ _signup.steps[_signup.currentStep - 1].title }}
                                </span>
                                <span *ngIf="_signup.currentStep >= 7">
                                    {{ _signup.steps[_signup.currentStep-2].title }}
                                </span>
                            </div>
                            <div class="text-lg text-bluegray-300 mt-1" *ngIf="_signup.currentStep !== 3">
                                <span *ngIf="_signup.currentStep < 7">
                                    {{ _signup.steps[_signup.currentStep - 1].detail }}
                                </span>
                                <span *ngIf="_signup.currentStep >= 7">
                                    {{ _signup.steps[_signup.currentStep-2].detail }}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="!_signup.freeTrialAccount && _signup.accountVerified ">
                                <div class="flex align-items-top justify-content-end">
                                    <a style="cursor:pointer" (click)="op.toggle($event)">
                                        <img class="shopping-cart" src="assets/images/bucket.svg"
                                            title="Shopping Cart" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <ng-container *ngIf="_signup.currentStep == 0">
                        <app-subscription-plans-layout></app-subscription-plans-layout>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 1">
                        <app-signup-personal-info></app-signup-personal-info>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 2">
                        <app-signup-business-info
                            (backButtonClick)="backButtonClickHandle()"></app-signup-business-info>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 3">
                        <app-signup-verification (backButtonClick)="backButtonClickHandle()"></app-signup-verification>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 4">
                        <app-signup-website-and-online-store (backButtonClick)="backButtonClickHandle()"
                            [checkoutPage]="false">
                        </app-signup-website-and-online-store>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 5">
                        <app-signup-loyalty-program [showFooter]="true" (backButtonClick)="backButtonClickHandle()">
                        </app-signup-loyalty-program>

                    </ng-container>

                    <ng-container *ngIf="_signup.currentStep == 6">
                        <app-signup-hardware [showFooter]="true" (backButtonClick)="backButtonClickHandle()">
                        </app-signup-hardware>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 7">
                        <app-signup-hardware-list [showFooter]="true"></app-signup-hardware-list>
                    </ng-container>
                    <ng-container *ngIf="_signup.currentStep == 8">
                        <app-signup-checkout (backButtonClick)="backButtonClickHandle()"></app-signup-checkout>
                    </ng-container>
                    <!-- <ng-container *ngIf="_signup.currentStep == 9">
                        <app-signup-payment-step1></app-signup-payment-step1>
                    </ng-container> -->
                    <!-- <ng-container *ngIf="_signup.currentStep == 9">
                        <app-signup-hardware-list></app-signup-hardware-list>
                    </ng-container> -->
                    <!-- <app-bottom-navigator  [currentStep]="_signup.currentStep"></app-bottom-navigator> -->
                </div>
            </div>
        </div>
    </div>
    <div class="flex absolute w-full h-screen justify-content-center flex-column align-items-center bg-white"
        *ngIf="showSpinner">
        <div class="flex justify-content-center flex-column align-items-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="flex justify-content-center flex-column align-items-center">
            <p>Please wait...</p>
        </div>
    </div>

</div>
<!-- to be deleted later -->
<!-- <br>
<br>
<button pButton type="button" [label]="'Click '+_signup.currentStep" (click)="dummy()"></button> -->

<p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '350px'}">
    <ng-template pTemplate>
        <div class="flex flex-column">
            <div class="flex">
                <h4>Shopping Cart</h4>
            </div>
            <div class="flex my-1">
                <!-- <p>You have selected : {{_signup.selectedPlan.title}} subscription plan</p> -->
            </div>
            <div class="flex justify-content-center align-content-center my-2" *ngIf="_signup.selectedPlan.title != ''">
                <p-button label="View Shopping Cart" (click)="viewCheckout()" class="signup-back-button">

                </p-button>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<!-- to be deleted later -->

<p-dialog [showHeader]="false" [(visible)]="displayConfirmContinueDialog" [style]="{width: '35vw'}" modal="true"
    [baseZIndex]="10000" class="p3">
    <!-- <ng-template pTemplate="header">
        <div class="flex justify-content-center">
            <div class="text-blue-500 text-2xl font-bold flex align-items-center justify-content-center mt-0">Verification Success</div>
        </div>
    </ng-template> -->
    <div class="flex justify-content-center p-4 flex-column align-items-center " *ngIf="_signup.freeTrialAccount">
        <div class="flex justify-content-center my-1">
            <img src="assets/images/Verification.png" />
        </div>
        <div class="flex justify-content-center my-2 ">
            <div class="text-3xl device-price font-bold flex align-items-center justify-content-center mt-0">
                Your
                30-day free trial has started!</div>
        </div>
        <div class="flex justify-content-center my-2">
            <div class="text-500 flex">Your free trial will end on <span
                    class="font-bold mx-1 text-lg text-900">{{trialDate | date: 'fullDate'}}</span></div>
        </div>
        <div class="flex justify-content-center my-2">
            <!--  <div class="text-500 flex">For next 30 days, you will enjoy the following features of {{_globals.whiteLabel.wlName}} for free.
                Explore {{_globals.whiteLabel.wlName}} and grow your business effectively. </div> -->
            <div class="text-500 flex">Please Explore {{_globals.whiteLabel.wlName}} and feel free to contact us if
                you
                have any questions!</div>

        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex align-items-end justify-content-center" *ngIf="_signup.freeTrialAccount">
            <p-button label="Login to Dashboard" iconPos="right" (onClick)="login()">
            </p-button>
        </div>
    </ng-template>
</p-dialog>