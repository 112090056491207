import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-subscription-plan-base',
  templateUrl: './subscription-plan-base.component.html',
  styleUrls: ['./subscription-plan-base.component.scss']
})
export class SubscriptionPlanBaseComponent implements OnInit {
  @Input() subscription: any
  @Output() onSubscriptionSelected = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  subscriptionClicked() {
    this.onSubscriptionSelected.emit();
  }

}
