<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700;
    "
>
    Personal Information
</h3>
<p-divider></p-divider>
<br>
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        First Name
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.firstName }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Last Name
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.lastName }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Email
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.email }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Phone
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _globals.generatePhoneFormatUS(_customerService.activeItem?.phone) }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        City
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.city }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        State
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.state }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Zipcode
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.postalCode }}
    </div>
</div>
