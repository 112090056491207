<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'transfer-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>
    <p-table 
        [value]="dataList" 
        dataKey="name" #dt [columns]="csvcols" 
        *ngIf="dataList?.length > 0" 
        styleClass="p-datatable-striped" 
        [scrollable]="true" 
        
        >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product" class="text-center">
                <td>
                   <span>{{ product.date | date:'MM/dd/yyyy'}}</span>
                </td>
                <td>
                   <span>{{ product.transferTypeString }}</span>
                </td>
                <td>
                   <span>{{ product.transferNo }}</span>
                </td>
                <td>
                    <span>{{ product.senderLocation }}</span>
                </td>
                <td>
                    <span>{{ product.receiverLocation }}</span>
                </td>
                <td>
                    <span>{{ product.totalTransferedQty}}</span>
                </td>
                <td>
                    <span>{{ product.totalPrice | currency}}</span>
                </td>
                <td>
                    <span>{{ product.shippingCost | currency}}</span>
                </td>
                <td>
                    <span>{{ product.transfersOutStatusString}}</span>
                </td>
                <td>
                    <span>{{ product.transfersInStatusString}}</span>
                </td>
                
                
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                
                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>

<p-dialog
    *ngIf="_reportService.showTransferDetail"
    header=""
    [(visible)]="_reportService.showTransferDetail"
    modal="true"
>
    <app-transfer-detail-report2 [transferReportData] = 'data'></app-transfer-detail-report2>
</p-dialog>