import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-notes',
  templateUrl: './product-notes.component.html',
  styleUrls: ['./product-notes.component.scss']
})
export class ProductNotesComponent implements OnInit {
  editform: FormGroup

  constructor(
    private _fb: FormBuilder,
    public _productServices: ProductsService
  ) { }

  ngOnInit(): void {
  }

  isReturnableHandle(e) {
    // console.log(this._productServices.isReturnable)
    if(this._productServices.isReturnable) {
           this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable.\n','')
     this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable.','')
     this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable','')
   } else {
        this._productServices.productNotes='Not Returnable.\n'+this._productServices.productNotes
      //this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable.\n','')
     // this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable.','')
     // this._productServices.productNotes=this._productServices.productNotes.replace('Not Returnable','')
    }
  }

}
