<div class="form-group">
    <p-inputNumber
        [mode]="mode "
        class="form-control mt-2"
        style="width: 100%"
        [formControl]="$any(ngControl.control)"
        placeholder="{{ label }}"
        [minFractionDigits]="minFractionDigits"
        [maxFractionDigits]="maxFractionDigits"
        [step]="step"
        [max]="max"
    ></p-inputNumber>
</div>
<span class="control-description text-muted" *ngIf="description.length>0"> {{description}}</span>
    <div class="flex">
        <div
            *ngIf="ngControl.control?.errors?.required"
            class="invalid-feedback"
        >
            Please enter a {{ name }}
        </div>
        <div *ngIf="ngControl.control?.errors?.min" class="invalid-feedback">
            {{ name }} must be greater than
            <!-- {{ ngControl.control?.errors?.min["requiredLength"] }}! -->
            {{ ngControl.control?.errors?.max?.max }}!
        </div>
        <div *ngIf="ngControl.control?.errors?.max" class="invalid-feedback">
            {{ name }} must be less than
            <!-- {{ ngControl.control?.errors?.max["requiredLength"] }}! -->
            {{ ngControl.control?.errors?.max?.max }}!
        </div>
        <div
            *ngIf="ngControl.control?.errors?.nonzerovalidate"
            class="invalid-feedback"
        >
            {{ name }} cannot be less than or equal to zero!
        </div>
    </div>

