import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    selector: 'app-subscription-plans-layout',
    templateUrl: './subscription-plans-layout.component.html',
    styleUrls: ['./subscription-plans-layout.component.scss'],
})
export class SubscriptionPlansLayoutComponent implements OnInit {
    _global = GlobalService
    @Input() showFooter: boolean = true;
    @Input() showTrial: boolean = true;
    @Input() AllowQtyChange: boolean = true;
    @Input() DefaultInterval = 13;
    @Input() AlreadySubscribedCustomer: boolean = false;
    @Input() selectedPlan = 4;
    @Output() closeDialogSubscriptionPlan = new EventEmitter<any>();
    @Output() subscriptionPlanChanged = new EventEmitter<any>();

    showLessPlans: boolean = false;
    tempSubscriptionList: any[] = [];
    _globals = GlobalService;
    freeAccountChecked: boolean = false;

    isLastCheckoutPage: boolean = false;
    constructor(
        public _signup: SignupService,
        private _router: Router,
        private activeRouter: ActivatedRoute,
        private _spinner: NgxSpinnerService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes['showFooter'];
            let cur = JSON.stringify(chng?.currentValue);
            let prev = JSON.stringify(chng?.previousValue);

            if (
                (cur && this._signup.currentStep == 8) ||
                this._router.url.includes('/app/subscription-management')
            ) {
                this.isLastCheckoutPage = true;
                this._signup.subscriptionPlans
                    .filter((f) => f.isCustom || f.isTrial)
                    .map((f) => (f.showPlan = false));
            } else {
                this.isLastCheckoutPage = false;
            }
        }
    }

    ngOnInit(): void {
        try {
            this.showLessPlans = false;
            document.body.style.background = '#f2f8fc';
            this._signup.resetVars();

            if (this._signup.subscriptionPlans.length < 1)
                this.getSubscriptionPlans();

            if (this.showFooter) {
                this._signup.subscriptionPlans.map((f) => (f.showPlan = true));
            } else {
                // this._signup.subscriptionPlans
                //     .filter((f) => f.isCustom || f.isTrial)
                //     .map((f) => (f.showPlan = false));
                this._signup.subscriptionPlans
                    .filter((f) => f.isCustom || f.isTrial)
                    .map((f) => (f.showPlan = true));
                this._signup.subscriptionPlans
                    .filter((f) => !f.isCustom && !f.isTrial)
                    .map((f) => (f.showPlan = true));

                this.showLessPlans =
                    this._signup.subscriptionPlans.filter((f) => f.showPlan)
                        .length == 4
                        ? true
                        : false;
            }

            let fromBusinessWebsite =
                this.activeRouter.snapshot.queryParamMap
                    .get('selected_plan')
                    ?.toString() ?? '';

            if (fromBusinessWebsite != '') {
                this._signup.subscriptionPlans.map((f) => (f.showPlan = true));
                // this._signup.subscriptionPlans
                //     .filter((f) => f.isTrial)
                //     .map((f) => (f.showPlan = false));
                this._signup.subscriptionPlans
                    .filter((f) => f.isTrial)
                    .map((f) => (f.showPlan = true));
                this._signup.subscriptionPlans
                    .filter((f) => f.isCustom)
                    .map((f) => (f.showPlan = false));

                this.showLessPlans = true;
            }

            let freeTrialAccount: any =
                sessionStorage.getItem('free-trial-account');
            this.freeAccountChecked = freeTrialAccount == '1' ? true : false;

            if (this.freeAccountChecked) {
                // this._signup.subscriptionPlans
                //     .filter((f) => f.isTrial)
                //     .map((f) => (f.showPlan = false));
                this._signup.subscriptionPlans
                    .filter((f) => f.isTrial)
                    .map((f) => (f.showPlan = true));
            }
            this.sessionStorageTime();

            if (this._router.url == '/setup/subscription-plans') {
                let isAnyUserInfoSaved: any = localStorage.getItem('user');
                if (
                    isAnyUserInfoSaved != undefined &&
                    isAnyUserInfoSaved != ''
                ) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('lastLoginDT');
                    sessionStorage.clear();
                }
            }

            let isAnyUserInfoSaved: any = localStorage.getItem('user');
            if (
                isAnyUserInfoSaved != undefined &&
                isAnyUserInfoSaved != '' &&
                !this._signup.isLoginWithSubDashboard()
            ) {
                sessionStorage.setItem('sub-from-dashboard', '1');
                let accountVerified: any =
                    sessionStorage.getItem('selectedPlan');
                if (accountVerified == undefined) {
                    let fromBusinessWebsite =
                        this.activeRouter.snapshot.queryParamMap
                            .get('selected_plan')
                            ?.toString() ?? '';

                    if (fromBusinessWebsite != '') {
                        this._signup.selectedPlan =
                            this._signup.subscriptionPlans.find(
                                (f) => f.planId == fromBusinessWebsite
                            );
                        sessionStorage.setItem(
                            'selected-from-business-website',
                            '1'
                        );
                    }
                }
            }

            if (
                this._signup.isLoginWithSubDashboard() &&
                this._router.url.includes('/setup/signup')
            ) {
                this._router.navigate(['/setup/signup']);
            }
        } catch (error) {}
    }

    getSubscriptionPlans() {
        try {
            this._spinner.show();
            this._signup.getSubscriptionPlans().subscribe((res) => {
                if (res.success) {
                    this._spinner.hide();
                    this._signup.subscriptionPlans = res.data;

                    let obj = this._signup.subscriptionPlans;
                    this._signup.subscriptionPlans.map(
                        (f) => (f.showPlan = true)
                    );
                    for (let i = 0; i < obj.length; i++) {
                        obj[i].locationAmount = 0;
                        obj[i].registerAmount = 0;
                    }
                    this.tempSubscriptionList = this._signup.subscriptionPlans;
                    let selectedHardware: any =
                        sessionStorage.getItem('selectedPlan');
                    if (selectedHardware != undefined) {
                        this._signup.selectedPlan =
                            JSON.parse(selectedHardware);

                        let locationsQty: any =
                            sessionStorage.getItem('locationsQty');
                        if (locationsQty != undefined) {
                            this._signup.checkoutPageCalcVars.locationsQty =
                                parseInt(locationsQty);
                            this._signup.outerLocationQty =
                                parseInt(locationsQty);
                        }

                        let registersQty: any =
                            sessionStorage.getItem('registersQty');
                        if (registersQty != undefined) {
                            this._signup.checkoutPageCalcVars.registersQty =
                                parseInt(registersQty);
                            this._signup.outerRegisterQty =
                                parseInt(registersQty);
                        }

                        let selectedPlanTimeframe: any = sessionStorage.getItem(
                            'selectedPlanTimeframe'
                        );
                        if (selectedPlanTimeframe != undefined) {
                            this._signup.selectedPlanTimeframe =
                                selectedPlanTimeframe;
                        }
                    }

                    if (!this._signup.selectedPlan)
                        this._signup.selectedPlan =
                            this._signup.subscriptionPlans.find(
                                (f) => f.title == 'Plus'
                            );
                }

                let fromBusinessWebsite =
                    this.activeRouter.snapshot.queryParamMap
                        .get('selected_plan')
                        ?.toString() ?? '';

                if (fromBusinessWebsite != '') {
                    this._signup.selectedPlan =
                        this._signup.subscriptionPlans.find(
                            (f) => f.planId == fromBusinessWebsite
                        );
                    sessionStorage.setItem(
                        'selected-from-business-website',
                        '1'
                    );
                    this.showLessPlans = true;
                    this.planSelectedFromBusinessWebsite();
                }

                if (!this.showTrial) {
                    // this._signup.subscriptionPlans
                    //     .filter((f) => f.isTrial)
                    //     .map((f) => (f.showPlan = false));
                    this._signup.subscriptionPlans
                        .filter((f) => f.isTrial)
                        .map((f) => (f.showPlan = true));
                }

                if (this.DefaultInterval == 12)
                    this._signup.selectedPlanTimeframe = 'BilledAnnually';
                else if (this.DefaultInterval == 1)
                    this._signup.selectedPlanTimeframe = 'BilledMonthly';
            });
        } catch {
            this._spinner.hide();
        }
    }

    continueButtonClick() {
        if (this.AlreadySubscribedCustomer) {
        } else {
            if (this.showFooter) {
                sessionStorage.setItem('show-selected-plan', 'true');
                if (this._signup.accountVerified) {
                    this._signup.currentStep = 1;
                } else {
                    this._signup.currentStep = 1;
                }

                if (this._signup.selectedPlan.isTrial) {
                    this.trialAccountSet();
                } else {
                    this._signup.savedSteps = 1;
                    sessionStorage.setItem(
                        'currentStep',
                        this._signup.currentStep.toString()
                    );
                    sessionStorage.setItem(
                        'savedSteps',
                        this._signup.savedSteps.toString()
                    );

                    sessionStorage.setItem(
                        'selectedPlan',
                        JSON.stringify(this._signup.selectedPlan)
                    );
                    sessionStorage.setItem(
                        'locationsQty',
                        this._signup.checkoutPageCalcVars.locationsQty
                    );
                    sessionStorage.setItem(
                        'registersQty',
                        this._signup.checkoutPageCalcVars.registersQty
                    );
                    sessionStorage.setItem(
                        'selectedPlanTimeframe',
                        this._signup.selectedPlanTimeframe
                    );
                    sessionStorage.setItem('free-trial-account', '0');
                    this._signup.freeTrialAccount = false;
                    this.sessionStorageTime();

                    this.navigateToSignup(
                        this._signup.selectedPlan.planId,
                        this._signup.selectedPlanTimeframe
                    );
                }
            } else {
                sessionStorage.setItem(
                    'locationsQty',
                    this._signup.checkoutPageCalcVars.locationsQty
                );
                sessionStorage.setItem(
                    'registersQty',
                    this._signup.checkoutPageCalcVars.registersQty
                );
                sessionStorage.setItem(
                    'selectedPlanTimeframe',
                    this._signup.selectedPlanTimeframe
                );
            }
        }
    }

    freeTrialSelected() {}
    navigateToSignup(selectedPlanId: string, selectedPlanTimeframe: string) {
        const queryParams: NavigationExtras = {
            queryParams: {
                selected_plan: selectedPlanId,
                duration:
                    selectedPlanTimeframe === 'BilledAnnually' ? 'y' : 'm',
            },
        };

        this._router.navigate(['/setup/signup'], queryParams);
    }
    planSelectedFromBusinessWebsite() {
        try {
            this.continueButtonClick();
        } catch (error) {}
    }

    trialAccountSet() {
        this._signup.selectedPlan = this._signup.subscriptionPlans.filter(
            (f) => f.isTrial
        )[0];
        sessionStorage.setItem(
            'selectedPlan',
            JSON.stringify(this._signup.selectedPlan)
        );
        sessionStorage.setItem('free-trial-account', '1');
        this._signup.savedSteps = 1;
        sessionStorage.setItem(
            'currentStep',
            this._signup.currentStep.toString()
        );
        sessionStorage.setItem(
            'savedSteps',
            this._signup.savedSteps.toString()
        );
        this._signup.freeTrialAccount = true;
        this.freeAccountChecked = true;
        this.sessionStorageTime();
        this.navigateToSignup(this._signup.selectedPlan.planId, 'm');
    }

    planChanged(selectedValue: any) {
        if (this._signup.accountVerified) {
            this._signup.planChanged = true;
        }

        this.subscriptionPlanChanged.emit(selectedValue);

        if (selectedValue) {
            this.closeDialogSubscriptionPlan.emit(this._signup.selectedPlan);
        }
    }

    locationChanged() {}

    registerChanged() {}

    subscriptionDurationChanged() {}

    sessionStorageTime() {
        const isSignupLSInfoSaved = sessionStorage.getItem(
            'signup-info-temp-hours'
        );
        if (isSignupLSInfoSaved == undefined) {
            var d1 = new Date(),
                d2 = new Date(d1);
            d2.setMinutes(d1.getMinutes() + 180);
            sessionStorage.setItem('signup-info-temp-hours', d2.toString());
        } else {
            if (
                Date.parse(
                    sessionStorage.getItem('signup-info-temp-hours').toString()
                ) < Date.parse(new Date().toString())
            ) {
                if (!this._signup.isLoginWithSubDashboard()) {
                    this._signup.loadInit();
                    this._signup.outerLocationQty = 1;
                    this._signup.outerRegisterQty = 1;
                    this._signup.checkoutPageCalcVars.locationsQty = 1;
                    this._signup.checkoutPageCalcVars.registersQty = 1;
                    this._signup.outerSelectedPlanTime = 'Monthly';
                    this._signup.selectedPlanTimeframe = 'BilledMonthly';
                    this._signup.currentStep = 1;

                    this._signup.freeTrialAccount = false;
                    sessionStorage.clear();
                }
            }
        }
    }

    comparePlanRedirect() {
        if (
            this._globals.whiteLabel.id ==
            'E0D7F13C-7A78-40B7-8049-CF8A1F1C245F'
        ) {
            var anchor = document.createElement('a');
            anchor.href =
                `https://cloudstoragesvc.blob.core.windows.net/commonfiles/${this._global.whiteLabel.wlName}PricingPlan.pdf`;
            anchor.target = '_blank';
            anchor.click();
        } else {
            var anchor = document.createElement('a');
            anchor.href =
                `https://cloudstoragesvc.blob.core.windows.net/commonfiles/${this._global.whiteLabel.wlName}PricingPlan.pdf`;
            anchor.target = '_blank';
            anchor.click();
        }
    }
}
