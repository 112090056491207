<!-- <span class="subscription-management"> -->
     <!-- <div class="card py-1 border-radius-none" *ngIf="planInfo.isFreeTrial">
        <div class="flex flex-row flex-wrap  card-container  ">

            <div class="flex align-items-center justify-content-center m-2 flex-grow-1 ">
                <span *ngIf="!planInfo.isFreeTrial && 1==0">
                    <div class="font-bold text-lg">
                        You Have {{dayLeftInRenewal}} Days Left in for Renewal.
                    </div>
                </span>
            </div>

        </div>
    </div> -->
    <div class="grid">
        <div class="col">
            <div class="conn-card">
                <app-bizz-customer-personal-info></app-bizz-customer-personal-info>
            </div>
        </div>
    </div>
    <div class="conn-grid-main create-product">
        <h4>Subscription</h4>
        <div>
            <p-tabMenu #tab
                [model]="items"
                [activeItem]="activeItem"
                [scrollable]="true"
                (click)="onTabItemClick(tab)">
            </p-tabMenu>

            <div *ngIf="activeItem.label === 'Subscription'" class="fadeinright animation-duration-200 mt-3">
                <div class="conn-card">
                    <div class="conn-grid ">
                        <app-subscription
                            [planInfo]="planInfo"
                            [businessSetup]="businessSetup"
                        >
                        </app-subscription>
                        <div class="justify-content-end flex-wrap" *ngIf="!planInfo.isFreeTrial">
                            <div class="align-items-center justify-content-center m-2">
                                <button pButton type="button" label="Transfer" class="ml-4"
                                    (click)="transferBusinessRrquestForm()"></button>
                                    <button pButton type="button" label="Contact Support" class="ml-2"
                                    (click)="talkToExpertSupportGuy()"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="activeItem.label == 'Billing Address' && !planInfo.isFreeTrial"
                class="fadeinright animation-duration-200 mt-3">
                <div class="conn-card">
                    <div class="conn-grid ">
                        <app-billing-address></app-billing-address>
                    </div>
                </div>
            </div>

            <div *ngIf="activeItem.label == 'Billing History' && !planInfo.isFreeTrial" class="fadeinright animation-duration-200 mt-3">
                <div class="conn-card">
                    <div class="conn-grid ">

                        <app-billing-history></app-billing-history>
                    </div>
                </div>
            </div>
            <div *ngIf="activeItem.label == 'Hardware' && !planInfo.isFreeTrial" class="fadeinright animation-duration-200 mt-3">
                <div class="conn-card">
                    <div class="conn-grid ">
                        <app-hardware-details-history></app-hardware-details-history>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-dialog header="Contact Support" [(visible)]="talkToSupportExpertForm" [blockScroll]="true"
    modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Your Question
            </div>
            <div class="">
                <input type="text" [(ngModel)]="expertSupportQuestion" pInputText style="width: 100%;" />
            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Details
            </div>
            <div class="">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="expertSupportMessage" pInputTextarea
                    style="width: 100%;">​</textarea>

            </div>
        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Submit Request" class="ml-2" style="width: 160px"
                (click)="submitTalkExperGuyRequest()"></button>
        </div>
    </div>
</p-dialog>
<p-dialog header="Change Ownership Request " [(visible)]="businessTransferFormRequest" [blockScroll]="true"
modal="true" [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '40vw' }">
<ng-container>
    <br />
    <div class="nas-grid-2x m-2">
        <div class="nas-label">Your Question
        </div>
        <div class="">
            <input type="text" [(ngModel)]="changerOwnerShipSubject" pInputText style="width: 100%;" />
        </div>
    </div>
    <div class="nas-grid-2x m-2">
        <div class="nas-label">Details
        </div>
        <div class="">
            <textarea [rows]="5" [cols]="30" [(ngModel)]="changerOwnerShipMessage" pInputTextarea
                style="width: 100%;">​</textarea>

        </div>
    </div>
</ng-container>
<div class="flex justify-content-end flex-wrap">
    <div class="flex align-items-center justify-content-center m-2">
        <button pButton type="button" label="Submit Request" class="ml-2" style="width: 160px"
            (click)="sendTransferBusinessRequest()"></button>
    </div>
</div>
</p-dialog>
