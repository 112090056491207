 <div class="flex flex-wrap justify-content-start text-start flex-column">
     <div class="font-bold text-2xl page-heading-color">
         Loyalty Program
     </div>
 </div>
<div class="fadeindown flex-grow-1 py-4 flex align-items-center justify-content-center">
    <div class="conn-card flex-grow-1 flex align-items-center justify-content-start w-9">


        <img class="my-2 py-4" src="assets/images/subscrb-image.png" />
        <p class="font-bold text-xl text-700 my-4">Boost Your Business with {{_globals.whiteLabel.wlName}} Loyalty Program!</p>
        <div class="col:12 md:6 w-6  subscribe-plan-body my-2">
            <p class="text-center">{{_globals.whiteLabel.wlName}} is the ultimate loyalty solution for small businesses and retailers. Our Loyalty Program
                offers a range of features and benefits designed to help you build stronger, more loyal relationships with
                your customers.</p>
            <p class="text-center">Here s what you can expect with {{_globals.whiteLabel.wlName}} Loyalty Program:</p>
            <ul class="text-start">
                <li>Customizable loyalty point system to reward your customers for their purchases.</li>
                <li>Advanced reporting and analytics tools to track customer behavior and loyalty trends.</li>
                <li>Special promotions and discounts for your customers, exclusively from your business.</li>
                <li>Automated loyalty campaigns and communications to keep your customers engaged
                    and motivated.</li>
            </ul>
            <p class="text-center">Don t miss out on this opportunity to build lasting, loyal relationships with your
                customers. The {{_globals.whiteLabel.wlName}}
                Loyalty
                Program has everything you need to create a successful and effective loyalty program that drives results for
                your
                business. Subscribe to the {{_globals.whiteLabel.wlName}} Loyalty Program today and start seeing the benefits for yourself!</p>
        </div>
         <button class="flex justify-content-center my-2 flex-grow-0" pButton pRipple type="button"
             label="Subscribe to Loyalty Program" (click)="subscribe()"></button>
    </div>
</div>
