<div class="conn-card" *ngIf="showFilter" >
    <app-report-top-filter-bar
        [paraReportName]="'app-till-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>
</div>

<br />
<div
    class="font-medium text-3xl select-none mb-2"
    style="text-align: center; color: var(--brandColor)"
>

    <span *ngIf="showFilter" >Current</span>
    Till Status
</div>
<div class="conn-card">
    <div class="mx-5">
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="currentTills"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">Location</th>
                    <th style="text-align: center">Register</th>
                    <th style="text-align: center">Till Status</th>
                    <th style="text-align: center">Till Number</th>
                    <th style="text-align: center">Current Amount</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">{{ item.locationName }}</td>
                    <td style="text-align: center">{{ item.registerName }}</td>
                    <td style="text-align: center; font-weight: bold;" *ngIf="item.tillAmount > 0">
                        Till Added - {{ item.tillAmount | currency }}
                    </td>
                    <td style="text-align: center" *ngIf="item.tillAmount <= 0">
                        No Till Added
                    </td>
                    <td style="text-align: center">{{ item.tillNo }}</td>
                    <td style="text-align: center">{{ item.totalTillAmount }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="6" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line">
                    <td
                    colspan="4"
                        style="
                            color: white;
                            background-color: var(--brandColor);
                        "
                        style="text-align: right"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    >
                        TOTAL
                    </td>
                    <td
                        style="text-align: center"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    >
                        {{ tillsSum | currency }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<br />
<div
    class="font-medium text-3xl select-none mb-2"
    style="text-align: center; color: var(--brandColor)"
>
    Till Detail
</div>
<div class="conn-card">
    <div class="mx-5">
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="tillHistory"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">Location</th>
                    <th style="text-align: center">Cashier</th>
                    <th style="text-align: center">Register</th>
                    <th style="text-align: center">Till Number</th>
                    <th style="text-align: center">Amount Added</th>
                    <th style="text-align: center">Amount Removed</th>
                    <th style="text-align: center">Date-time</th>

                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">{{ item.locationName }}</td>
                    <td style="text-align: center">{{ item.cashierName }}</td>
                    <td style="text-align: center">{{ item.registerName }}</td>
                    <td style="text-align: center"> {{ item?.invoiceNumber }} </td>
                    <td style="text-align: center" >
                        <span>{{ item.addedAmount }}</span>

                    </td>
                    <td style="text-align: center" >
                        <span>{{ item.removedAmount }}</span>
                    </td>
                    <td style="text-align: center">{{ item.dated | date : "short" }}</td>

                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="7" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line">
                    <td
                        colspan="4"
                        style="
                            color: white;
                            background-color: var(--brandColor);
                        "
                        style="text-align: left"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    >
                        TOTAL
                    </td>
                    <td
                        style="text-align: center"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    >
                        {{ detailSum | currency }}
                    </td>
                    <td
                        style="text-align: center"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    >
                        {{ negativeSum  | currency }}
                    </td>
                    <td
                        style="text-align: center"
                        class="text-0 font-medium text-lg select-none bg-blue-700"
                    ></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
