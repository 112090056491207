import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-settings-main',
  templateUrl: './settings-main.component.html',
  styleUrls: ['./settings-main.component.scss']
})
export class SettingsMainComponent implements OnInit {
  _globals = GlobalService

  options: any = [
    { name: 'Tax Settings', id: 'tax-settings', icon: 'pi pi-percentage' },
    { name: 'Invoice Configuration', id: 'invoice-config', icon: 'pi pi-book' },
    { name: 'Printing Configuration', id: 'printing-config', icon: 'pi pi-print' },
    { name: 'Cash Drop Configuration', id: 'cash-drop-config', icon: 'pi pi-dollar' },

    { name: 'Cash Drawer Configuration', id: 'cash-drawer-config', icon: 'pi pi-folder' },
    { name: 'Security Configuration', id: 'security-drawer-config', icon: 'pi pi-lock' },
    { name: 'Tender Configuration', id: 'tender-config', icon: 'pi pi-briefcase' },
    { name: 'Role Management', id: 'role-management', icon: 'pi pi-tags' },

    { name: 'Rights Management', id: 'rights-management', icon: 'pi pi-users' },
    { name: 'Subscription', id: 'subscription', icon: 'pi pi-shopping-cart' },


  ]

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this._globals.componentName = 'Settings'
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  optionClicked(what: string) {
    switch (what) {
      case 'rights-management':
        this._router.navigate(['app/settings-rights-management'])
        break;
      default:
        alert('UNDER CONSTRUCTION!')
    }
  }

}
