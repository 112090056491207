import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { UsStates } from 'src/app/_data/us-states';
import { FormIdList } from 'src/app/_models/next-level-integration/business&others/form-id';
import { beneficiariesDetailsModel } from 'src/app/_models/next-level-integration/next-level-model';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';


interface City {
    name: string;
    code: string;
}
@Component({
  selector: 'app-beneficiaries-details',
  templateUrl: './beneficiaries-details.component.html',
  styleUrls: ['./beneficiaries-details.component.scss']
})
export class BeneficiariesDetailsComponent implements OnInit {

    @Output() beneficiariesFormSaved: EventEmitter<boolean> =
        new EventEmitter();
    @Output() backButtonClicked: EventEmitter<boolean> = new EventEmitter();
    isSubmitted: boolean = false;
    usaStates = UsStates;
    othersOwnersNumber: number = 0;
    public fields: any[] = [];
    unsubscribe: any;
    public arrayTitle = [
        '2nd Beneficiary',
        '3rd Beneficiary',
        '4th Beneficiary',
    ];
    showOwnerZipCodeLoading: boolean = false;

    beneficiariesForm!: FormGroup;
    formIdList = FormIdList;

    beneficiaryObj = <beneficiariesDetailsModel>{};
    noOfBeneficiaries: number = 3;
    dob1: Date = new Date();
    dob2: Date = new Date();
    dob3: Date = new Date();
    dob01: Date = new Date();
    dob02: Date = new Date();
    dob03: Date = new Date();
    dob001: Date = new Date();
    dob002: Date = new Date();
    dob003: Date = new Date();

    constructor(private fb: FormBuilder, public _abd: AppBaseDataService) { }

    ngOnInit(): void {
        this.initBeneficiariesDetailObj();
        this.initBeneficiariesForm();
    }

    initBeneficiariesForm() {
        this.beneficiariesForm = this.fb.group({
            beneficiaries: this.fb.array([]),
        });

        for (let i = 0; i < this.noOfBeneficiaries; i++) {
            this.beneficiaries.push(this.createFields(i));
        }
    }

    get beneficiaries(): FormArray {
        return this.beneficiariesForm.get('beneficiaries') as FormArray;
    }

    get beneficiariesOwnersList() {
        return this.beneficiariesForm.controls.beneficiaries['controls'];
    }
    initBeneficiariesDetailObj() {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        this.beneficiaryObj.title = '';
        this.beneficiaryObj.ownerPercentageControlName = 1;
        this.beneficiaryObj.ssnControlName = '';
        this.beneficiaryObj.dobControlName = new Date(currentDate);
        this.beneficiaryObj.formId = this.formIdList[0].view;
        this.beneficiaryObj.beneficiaryNumber = '';
        this.beneficiaryObj.expiryDate = new Date(currentDate);
        this.beneficiaryObj.ownerPhoneControlName = '';
        this.beneficiaryObj.addressControlName = '';
        this.beneficiaryObj.role = '';
        this.beneficiaryObj.zipCodeControlName = '';
        this.beneficiaryObj.cityControlName = '';
        this.beneficiaryObj.stateControlName = this.usaStates[0].name;
        this.beneficiaryObj.issueStateControlName = '';
        this.beneficiaryObj.issueDate = new Date(currentDate);
    }

    getSessionBeneficiaryValue() {
        return sessionStorage.getItem('beneficiary_array');
    }

    getBeneficiaryDetails(i: number) {
        let anyBeneficiaryFound: any = this.getSessionBeneficiaryValue();
        if (anyBeneficiaryFound != undefined) {
            anyBeneficiaryFound = JSON.parse(anyBeneficiaryFound);

            if (i < anyBeneficiaryFound.length) {
                if (anyBeneficiaryFound.length > 0) {
                    return anyBeneficiaryFound[i];
                }
            }
        }
        return false;
    }

    createFields(i: number): FormGroup {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        let sessionObj: any = this.getBeneficiaryDetails(i);

        if (typeof sessionObj === 'object') {
            this.beneficiaryObj.title = sessionObj.title;
            this.beneficiaryObj.ownerPercentageControlName =
                sessionObj.ownerPercentageControlName;
            this.beneficiaryObj.ssnControlName = sessionObj.ssnControlName;
            this.beneficiaryObj.dobControlName = new Date(
                sessionObj.dobControlName
            );
            this.beneficiaryObj.formId = this.formIdList[0].view;
            this.beneficiaryObj.beneficiaryNumber =
                sessionObj.beneficiaryNumber;
            this.beneficiaryObj.expiryDate = new Date(sessionObj.expiryDate);
            this.beneficiaryObj.ownerPhoneControlName =
                sessionObj.ownerPhoneControlName;
            this.beneficiaryObj.addressControlName =
                sessionObj.addressControlName;
            this.beneficiaryObj.role = sessionObj.role;
            this.beneficiaryObj.zipCodeControlName =
                sessionObj.zipCodeControlName;
            this.beneficiaryObj.cityControlName = sessionObj.cityControlName;

            this.beneficiaryObj.stateControlName = this.usaStates.filter(
                (f) => f.abbreviation == sessionObj.stateControlName
            )[0].name;
            this.beneficiaryObj.issueStateControlName =
                sessionObj.issueStateControlName;
            this.beneficiaryObj.issueDate = new Date(sessionObj.issueDate);
        }
        return this.fb.group({
            showOwnerZipCodeLoading: ['0'],
            title: [this.beneficiaryObj.title, Validators.required],
            ownerPercentageControlName: [
                this.beneficiaryObj.ownerPercentageControlName,
                Validators.required,
            ],
            ssnControlName: [
                this.beneficiaryObj.ssnControlName,
                Validators.required,
            ],
            dobControlName: [
                new Date(this.beneficiaryObj.dobControlName),
                Validators.required,
            ],
            formId: [this.beneficiaryObj.formId],
            beneficiaryNumber: [this.beneficiaryObj.beneficiaryNumber],
            expiryDate: [
                new Date(this.beneficiaryObj.expiryDate),
                Validators.required,
            ],
            ownerPhoneControlName: [this.beneficiaryObj.ownerPhoneControlName],
            addressControlName: [this.beneficiaryObj.addressControlName],
            role: [this.beneficiaryObj.role, Validators.required],
            zipCodeControlName: [
                this.beneficiaryObj.zipCodeControlName,
                Validators.required,
            ],
            cityControlName: [
                this.beneficiaryObj.cityControlName,
                Validators.required,
            ],
            stateControlName: [
                this.usaStates.filter(
                    (f) => f.name == this.beneficiaryObj.stateControlName
                )[0],
            ],
            issueStateControlName: [this.beneficiaryObj.issueStateControlName],
            issueDate: [
                new Date(this.beneficiaryObj.issueDate),
                Validators.required,
            ],
        });
    }

    ownersZipFiled(formIndex: number) {
        let t = this.beneficiariesOwnersList[formIndex];

        if (
            !t.controls.zipCodeControlName.value ||
            t.controls.zipCodeControlName.value.length !== 5
        )
            return;
        t.controls.showOwnerZipCodeLoading.setValue('1');
        this._abd
            .getCityAndStateInfo(t.controls.zipCodeControlName.value)
            .subscribe((res: any) => {
                if (res.success && res.data.city != undefined) {
                    t.controls.showOwnerZipCodeLoading.setValue('0');
                    t.controls.cityControlName.setValue(res.data.city);
                    let selectedState = this.usaStates.filter(
                        (f) => f.abbreviation == res.data.state
                    )[0];
                    t.controls.stateControlName.patchValue(selectedState);
                } else {
                    t.controls.showOwnerZipCodeLoading.setValue('0');
                    t.controls.cityControlName.setValue('');
                }
            });
    }

    saveForm() {
        if (this.beneficiariesForm.invalid) {
            // Got focus to the error field
            let invalidFields = [].slice.call(
                document.getElementsByClassName('ng-invalid')
            );
            invalidFields[1].focus();
        }

        let tempList = [];

        for (let i = 0; i < this.noOfBeneficiaries; i++) {
            let t = this.beneficiariesOwnersList[i];
            let tempObj = <any>{};
            tempObj.title = t.controls.title.value;
            tempObj.ownerPercentageControlName =
                t.controls.ownerPercentageControlName.value;
            tempObj.ssnControlName = t.controls.ssnControlName.value;
            tempObj.dobControlName = t.controls.dobControlName.value;
            tempObj.formId = t.controls.formId.value;
            tempObj.beneficiaryNumber = t.controls.beneficiaryNumber.value;
            tempObj.expiryDate = t.controls.expiryDate.value;
            tempObj.ownerPhoneControlName =
                t.controls.ownerPhoneControlName.value;
            tempObj.addressControlName = t.controls.addressControlName.value;
            tempObj.role = t.controls.role.value;
            tempObj.zipCodeControlName = t.controls.zipCodeControlName.value;
            tempObj.cityControlName = t.controls.cityControlName.value;
            tempObj.stateControlName =
                t.controls.stateControlName.value.abbreviation;
            tempObj.issueStateControlName =
                t.controls.issueStateControlName.value;
            tempObj.issueDate = t.controls.issueDate.value;
            tempList.push(tempObj);
        }
        sessionStorage.setItem('beneficiary_array', JSON.stringify(tempList));
        this.beneficiariesFormSaved.emit(true);
    }
    validateDate(inputType: number, isOtherBeneficiariesIndex: number = -1) {
        let currentDate = formatDate(new Date(), 'MM/dd/yyy', 'en');
        let t = this.beneficiariesOwnersList[isOtherBeneficiariesIndex];

        switch (inputType) {
            case 1:
                if (
                    Object.prototype.toString.call(
                        t.controls.dobControlName
                    ) === '[object Date]'
                ) {
                    if (isNaN(t.controls.dobControlName)) {
                        t.controls.dobControlName.setValue(currentDate);
                    }
                } else {
                    t.controls.dobControlName.setValue(currentDate);
                }
                break;
            case 2:
                if (
                    Object.prototype.toString.call(t.controls.expiryDate) ===
                    '[object Date]'
                ) {
                    if (isNaN(t.controls.expiryDate)) {
                        t.controls.expiryDate.setValue(currentDate);
                    }
                } else {
                    t.controls.expiryDate.setValue(currentDate);
                }
                break;
            case 3:
                if (
                    Object.prototype.toString.call(t.controls.issueDate) ===
                    '[object Date]'
                ) {
                    if (isNaN(t.controls.issueDate)) {
                        t.controls.issueDate.setValue(currentDate);
                    }
                } else {
                    t.controls.issueDate.setValue(currentDate);
                }
                break;

            default:
                break;
        }
    }

    backButtonClick() {
        this.backButtonClicked.emit(true);
    }

}
