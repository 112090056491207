<div class="conn-card">
    <h2 style="color: var(--primary-color)">Roles</h2>
    <div class="conn-flex">
        <div *ngFor="let role of roles" style="margin-left: 20px">
            <p-radioButton
                name="role.name"
                [value]="role.name"
                [label]="role.name"
                [(ngModel)]="selectedRole"
            ></p-radioButton>
        </div>
    </div>
    
</div>

<div class="conn-card">
    <h5 style="color: var(--primary-color)" class="conn-flex">
        {{checkAllRights?'Uncheck all rights':'Check all rights'}}<span style="margin-left:10px;"
            ><p-inputSwitch [(ngModel)]="checkAllRights" ></p-inputSwitch
        ></span>
    </h5>

    <p-treeTable [value]="rights">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Size</th>
                <th>Type</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr>
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.name}}
                </td>
                <!-- <td>{{rowData.size}}</td>
                <td>{{rowData.type}}</td> -->
            </tr>
        </ng-template>
    </p-treeTable>
</div>
