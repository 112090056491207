import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-tax-report-v2',
  templateUrl: './sales-tax-report-v2.component.html',
  styleUrls: ['./sales-tax-report-v2.component.scss']
})
export class SalesTaxReportV2Component implements OnInit, OnDestroy {
  _globals = GlobalService

  dateDataList: any[] = [];
  locationDataList: any[] = [];
  yearDataList: any[] = [];

  colheader = this._abd.reports.filter(x => x.componentName == 'Sales Tax Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Sales Tax Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Sales Tax Report')[0].summaryHeader;
  domSummaryHeader = this._abd.reports.filter(x => x.componentName == 'Sales Tax Report')[0].summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Sales Tax Report')[0].summaryflds;

  csvcols = []
  exportColumns: any = '';
  activeState: boolean[] = [true, false, false];
  locationIds: string[] = [];

  constructor(private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _filterService: FilterServiceService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Sales Tax Report'

    this._abd.initDates()
    this._abd.selectedLocation = this._abd.locations;

    this.getDateDateList();
    // this.getLocationDateList();
    // this.getYearDateList();

  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    if (!selLocation || selLocation.length < 1) {
      selLocation = JSON.parse(JSON.stringify(this._abd.locations))
      this._filterService.selectedLocation=selLocation
    }
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }


  getDateDateList() {
    this.getLocationsList();
    let x95 = this._filterService.selectedProductSellTypeFilter
    if (!x95) x95 = 'All'
    else x95 = x95.Value

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy": 'Date',
        'OrderPlacement': x95
      }
    }

    const url = environment.InventoryBaseURL + environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dateDataList = res.data;
      this._abd.hideSpinner();
    })
  }


  getLocationDateList() {
    this.getLocationsList();
    if (!this._abd.dateRange) {
      this._abd.initDates()
    }

    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy": 'Location',
      }
    }

    const url = environment.InventoryBaseURL + environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.locationDataList = res.data;
      this._abd.hideSpinner();
    })
  }



  getYearDateList() {
    this.getLocationsList();
    if (!this._abd.dateRange) {
      this._abd.initDates()
    }

    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy": 'Year',
      }
    }

    const url = environment.InventoryBaseURL + environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.yearDataList = res.data;
      this._abd.hideSpinner();
    })
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  // toggle(index: number) {
  //   this.activeState[index] = !this.activeState[index];
  // }


  filterButtonPressed() {
    this.getDateDateList();
    // this.getLocationDateList();
    // this.getYearDateList();
  }


  callbackFuncFromFilter(event) {
    this.getDateDateList();
    // this.getLocationDateList();
    // this.getYearDateList();
  }

  exportExcel() {
    this._abd.exportExcel(this.dateDataList, this.summaryHeader[0], this.colflds, 'Sales Tax Report')
  }

  exportPdf() {

    this._abd.exportPdf(this.dateDataList, this.summaryHeader, this.colflds, 'Sales Tax Report', true)
  }

  calculateSummaryTotal(col: string) {
    // 

    ;
    let total = "0";
    if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("totalinvoices")) {
      total = this.dateDataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
    }
    else if (col.includes("taxRete")) {
      total = this.dateDataList.reduce((sum, item) => sum + item[col], 0).toFixed(2) + " %";
    }
    else {
      total = this.currencyPipe.transform(this.dateDataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
    }
    return total;

  }

}
