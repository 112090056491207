import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-menu',
    template: `
    <a class="layout-topbar-logo" routerLink="" style="display: block;
    text-align: center;
    border-bottom: 1px dashed #393945;
    margin-bottom: 20px;
}">
    <img [src]="_globals.whiteLabel.logoWhite" alt="Logo"  style="margin-bottom: 30px;width: 150px;"/>
</a>
    <p-scrollPanel  [style]="{'margin-right':'0px', width: '100%', height: 'calc(90vh - 9rem)'}">
        <div class="layout-menu-container">
            <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
                <li app-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true">
                    <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">{{item.label}}</div>
                    <ul role="menu">
                        <li app-menuitem *ngFor="let child of item.items" [item]="child" [index]="i" role="none"></li>
                    </ul>
                </li>
            </ul>
        </div>
        </p-scrollPanel>
    `,
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: any[];
    _globals = GlobalService

    constructor(public appMain: AppMainComponent) { }

    ngOnInit() {
        this.model = [
            {
                label: '',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
            // {
            //     label: '',
            //     items: [
            //         { label: 'bizSetup', icon: 'pi pi-fw pi-home', routerLink: ['setup/business-setup'] }
            //     ]
            // },
            {
                label: '',
                items: [
                    {
                        label: 'Inventory', icon: 'pi pi-fw pi-list',
                        items: [
                            { label: 'Overview', icon: 'pi pi-fw pi-bookmark', routerLink: ['app/inventory-report'] },
                            { label: 'Products', icon: 'pi pi-fw pi-tags', routerLink: ['app/products'] },
                            { label: 'Departments', icon: 'pi pi-fw pi-table', routerLink: ['app/departments'] },
                            { label: 'Categories', icon: 'pi pi-fw pi-qrcode', routerLink: ['app/categories'] },
                            { label: 'Manufacturer', icon: 'pi pi-fw pi-user', routerLink: ['app/manufacturer'] },
                            { label: 'Vendors', icon: 'pi pi-fw pi-users', routerLink: ['app/vendors'] },
                            { label: 'Inventory Count', icon: 'pi pi-fw pi-clone', routerLink: ['app/inventory-count'] },

                        ]
                    },

                ]
            },
            {
                label: '',
                items: [
                    {
                        label: 'Reports', icon: 'pi pi-fw pi-chart-pie',
                        items: [
                            // {
                            // label: 'Sales', icon: 'pi pi-fw pi-bookmark',
                            // items: [
                            { label: 'Daily Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/daily-sales-report'] },
                            { label: 'Tenders', icon: 'pi pi-fw pi-dollar', routerLink: ['app/tender-report'] },
                            { label: 'Hourly Sales', icon: 'pi pi-fw pi-clock', routerLink: ['app/hourly-sales-report'] },
                            { label: 'Departmental Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/department-sales-report'] },
                            { label: 'Category Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/category-sales-report'] },
                            // { label: 'Sales Tax Report', icon: 'pi pi-fw pi-server', routerLink: ['app/sales-tax-report'] },
                            { label: 'Sales Tax', icon: 'pi pi-fw pi-money-bill', routerLink: ['app/sales-tax-report-v2'] },
                            { label: 'Credit Card Sales', icon: 'pi pi-fw pi-credit-card', routerLink: ['app/credit-card-sales-report'] },
                            { label: 'Credit Card Summary', icon: 'pi pi-fw pi-credit-card', routerLink: ['app/credit-card-summary-report'] },
                            { label: 'Refunds', icon: 'pi pi-fw pi-replay', routerLink: ['app/return-report'] },
                            { label: 'Daily Money Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/daily-money-report'] },
                            { label: 'Discount Sales Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/discount-sales-report'] },
                            // ]
                            // },
                        ]
                    },
                ]
            },
            // {
            //     label: '',
            //     items: [
            //         { label: 'Customers', icon: 'pi pi-fw pi-user', routerLink: ['app/customer-list'] }
            //     ]
            // },
            {
                label: '',
                items: [
                    { label: 'Sales', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['app/sales-invoices'] }
                ]
            },
            {
                label: '',
                items: [
                    { label: 'Customers', icon: 'pi pi-fw pi-users', routerLink: ['app/customers'] }
                ]
            },
            {
                label: '',
                items: [
                    {
                        label: 'Settings', icon: 'pi pi-fw pi-cog',
                        items: [
                            { label: 'Locations', icon: 'pi pi-fw pi-book', routerLink: ['app/locations-list'] },
                            { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: ['app/users-list'] },
                            { label: 'Business', icon: 'pi pi-fw pi-shopping-bag', routerLink: ['app/business'] },
                        ]
                    },
                ]
            },
            // {
            //     label: 'UI Components',
            //     items: [
            //         { label: 'Subscription Plan', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/CheckoutNFinishSignupComponent'] }, //--
            //         { label: 'Signup Business Detail', icon: 'pi pi-fw pi-id-card', routerLink: ['uikit/signupBusinessDetail'] }, //--
            //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
            //         { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
            //         { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
            //         { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
            //         { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon' },
            //         { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
            //         { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
            //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
            //         { label: 'Misc', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc'] }
            //     ]
            // },
            // {
            //     label: 'Utilities',
            //     items: [
            //         { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/icons'] },
            //         { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
            //     ]
            // },
            // {
            //     label: 'Pages',
            //     items: [
            //         { label: 'Crud', icon: 'pi pi-fw pi-user-edit', routerLink: ['/pages/crud'] },
            //         { label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline'] },
            //         { label: 'Landing', icon: 'pi pi-fw pi-globe', routerLink: ['pages/landing'] },
            //         { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['pages/signin'] }, //--
            //         { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['pages/error'] },
            //         { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['pages/notfound'] },
            //         { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['pages/access'] },
            //         { label: 'Empty', icon: 'pi pi-fw pi-circle', routerLink: ['/pages/empty'] }
            //     ]
            // },
            // {
            //     label: 'Hierarchy',
            //     items: [
            //         {
            //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
            //             items: [
            //                 {
            //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
            //                     items: [
            //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
            //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
            //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
            //                     items: [
            //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
            //                     ]
            //                 },
            //             ]
            //         },
            //         {
            //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
            //             items: [
            //                 {
            //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
            //                     items: [
            //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
            //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
            //                     items: [
            //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
            //                     ]
            //                 },
            //             ]
            //         }
            //     ]
            // },
            // {
            //     label: 'Get Started',
            //     items: [
            //         {
            //             label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
            //         },
            //         {
            //             label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/connect-app-ng'], target: '_blank'
            //         }
            //     ]
            // }
        ];
    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = (<HTMLDivElement>event.target);
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }
}
