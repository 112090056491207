<div>
    <h2 class="bs-page-header mb-3">Closed For Receiving - Purchase Order</h2>
</div>

<p-progressBar mode="indeterminate" *ngIf="!isDataLoaded"></p-progressBar>

<div class="grid" *ngIf="isDataLoaded">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-5 mt-3">PO#</div>
                    <div class="col">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.poNumber"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col mt-3">Creation Date</div>
                    <div class="col">
                        <p-calendar
                            [(ngModel)]="activeItem.createdDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            dateFormat="D d, MM yy"
                            [disabled]="true"
                            [inputSTyle]="{'width':'100%'}"
                        ></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col mt-3">Post Date</div>
                    <div class="col">
                        <p-calendar
                            [(ngModel)]="activeItem.createdDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            dateFormat="D d, MM yy"
                            [disabled]="true"
                        ></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col mt-3">Receive Date</div>
                    <div class="col">
                        <p-calendar
                            [(ngModel)]="activeItem.createdDate"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            dateFormat="D d, MM yy"
                            [disabled]="true"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-12 mt-3">Location</div>
                <div class="col-12">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="activeItem.locationName"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-12">Vendor</div>
                <div class="col-12">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="activeItem.vendorName"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-12 mt-3">Received By</div>
                <div class="col-12">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="activeItem.requestedBy"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-12">Status</div>
                <div class="col-12">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="activeItem.poStatus"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<div class="card" *ngIf="isDataLoaded">
    <p-table
        [value]="dataList"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        ><ng-template pTemplate="header">
            <tr>
                <th>Sr#</th>
                <th>Barcode</th>
                <th>SKU</th>
                <th>Product</th>
                <th>UOM</th>
                <th style="text-align: center">Ordered Qty</th>
                <th style="text-align: center">Received Qty</th>
                <th style="text-align: center">Due Qty</th>
                <th style="text-align: center">Unit Cost($)</th>
                <th style="text-align: center">Price A($)</th>
                <th style="text-align: center">Price B($)</th>
                <th style="text-align: center">Price C($)</th>
                <th style="text-align: center">Discount (%)</th>
                <th style="text-align: center">Net Cost($)</th>
                <th style="text-align: center">Tax (%)</th>
                <th style="text-align: center">Shipping($)</th>
                <th style="text-align: right">Total Cost($)</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.srNo }}</td>
                <td>{{ item.barcode }}</td>
                <td>{{ item.skuCode }}</td>
                <td>
                    {{ item.productName | slice : 0 : 25 }}
                    <span *ngIf="item.productName?.length > 25">...</span>
                </td>
                <td style="text-align: center">{{ item.uomDescription }}</td>
                <td style="text-align: center">{{ item.orderedQuantity | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.receivedQuantity | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.dueQuantity | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.costPrice | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.priceA | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.priceB | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.priceC | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.discountPercent | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.netTotal | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.taxRate | number : "1.2-2" }}</td>
                <td style="text-align: center">{{ item.shipping | number : "1.2-2" }}</td>
                <td style="text-align: right">{{ item.totalAmount | number : "1.2-2" }}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="17" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br />
    <div class="grid">
        <div class="col-4">
            <p class="mt-3 font-bold text-500 text-lg">
                Comments or Special Instructions
            </p>
            <textarea
                style="width: 100%"
                pInputTextarea
                [(ngModel)]="activeItem.poNote"
                [rows]="3"
                [disabled]="true"
            ></textarea>
            <!-- <div class="conn-card" *ngIf="isFooterNoteEnabled">
                <label [innerHTML]="footerNotesText"></label>
            </div> -->
        </div>

        <div class="col-4"></div>

        <div class="col-4">
            <div class="grid mt-5">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Sub Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="summary.subTotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Total Discount($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="summary.discountAmount"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        [disabled]="true"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Total Tax($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="summary.taxAmount"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [disabled]="true"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Shipping($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="summary.shipping"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [disabled]="true"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-5 mt-3 text-lg text-500 font-bold">
                    Grand Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="summary.grandTotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [disabled]="true"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>
        </div>
    </div>

    <div
        class="flex justify-content-end flex-wrap mt-5"
        *ngIf="activeItem.poStatus !== 'CLOSED'"
    >
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Cancel"
                class="p-button-danger"
                style="width: 150px"
                (click)="cancelButtonClick()"
            ></button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Post To Inventory"
                class="p-button-help"
                style="width: 150px"
                (click)="inventoryPostClicked()"
            ></button>
        </div>
    </div>
</div>

<br />
<div class="flex justify-content-between my-2" >
    <h2 class="bs-page-header mb-3" *ngIf="isDataLoaded">
        Receive History for PO
    </h2>
    <button
    *ngIf="histList.length > 0"
    pButton
    type="button"
    label="Post to Inventory"
    class="p-button-info"
    style="width: 160px"
    (click)="commitAllInventory()"
></button>
</div>
<div class="card" *ngIf="isDataLoaded">
    <p-table
        [value]="histList"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="histSelectedRow"
        dataKey="id"
        (onRowSelect)="onHistRowSelect($event)"
        (onRowUnselect)="onHistRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Invoice#</th>
                <th>Receive#</th>
                <th>Date</th>
                <th style="text-align: center">SKU</th>
                <th>Product</th>
                <th>UOM</th>
                <th>Received</th>
                <th>Remaining</th>
                <th>Unit Cost($)</th>
                <th>Price A($)</th>
                <th>Price B($)</th>
                <th>Price C($)</th>
                <th>Discount%</th>
                <th>Net Cost($)</th>
                <th>Tax%</th>
                <th>Shipping($)</th>
                <th>Total Cost($)</th>
                <th style="text-align: center">Inventory Status</th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.poRecivingId }}</td>
                <td>{{ item.dated | date : "medium" }}</td>
                <td style="text-align: center">{{ item.skuCode }}</td>
                <td>
                    {{ item.productName | slice : 0 : 25 }}
                    <span *ngIf="item.productName?.length > 25">...</span>
                </td>
                <td>{{ item.uomDescription }}</td>
                <td>{{ item.receivedQuantity | number : "1.0-0" }}</td>
                <td>{{ item.dueQuantity | number : "1.0-0" }}</td>
                <td>{{ item.costPrice | number : "1.2-2" }}</td>
                <td>{{ item.priceA | number : "1.2-2" }}</td>
                <td>{{ item.priceB | number : "1.2-2" }}</td>
                <td>{{ item.priceC | number : "1.2-2" }}</td>
                <td>{{ item.discountPercent }}</td>
                <td>{{ item.netTotal | number : "1.2-2" }}</td>
                <td>{{ item.taxRate }}</td>
                <td>{{ item.shipping | number : "1.2-2" }}</td>
                <td>{{ item.totalAmount | number : "1.2-2" }}</td>
                <td style="text-align: center">
                    {{ item.inventoryCommitStatus }}
                </td>
                <td>
                    <button
                        #btn
                        type="button"
                        pButton
                        icon="pi pi-bars"
                        label="Show"
                        (click)="histRowClicked($event, item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="18" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-tieredMenu
    #histmenu
    [model]="actionsHist"
    [popup]="true"
    style="width: 200px"
></p-tieredMenu>

<p-dialog
    [header]="'Purchase Order Id: ' + histSelectedRow?.id"
    [(visible)]="showReceiveInvoice"
    [style]="{ width: '92vw' }"
>
    <div class="grid">
        <div class="col-4">
            <div class="conn-card">
                <div class="grid">
                    <div class="col-5 text-lg">PO#</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.purchaseOrderId }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Invoice Number</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.invoiceNumber }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Created Date</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.createdDate | date : "mediumDate" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="conn-card">
                <div class="grid">
                    <div class="col-5">Received By</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.receivedBy }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Received Date</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.receivedDate | date : "mediumDate" }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Status</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.poStatus }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="conn-card">
                <div class="grid">
                    <div class="col-5">Div. Ship. Item</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.dividedShippingItems ? "Yes" : "No" }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">Shipping</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.shipping }}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-5">TOTAL</div>
                    <div class="col font-bold text-500">
                        {{ receiveData?.grandTotal | currency }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br />
    <br />
    <div class="conn-card">
        <p-table
            [value]="receiveData?.rpoItems"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            rowHover="true"
            dataKey="id"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">Invoice#</th>
                    <th style="text-align: center">Date</th>
                    <th style="text-align: center">Item SKU</th>
                    <th style="text-align: center">Due Qty</th>
                    <th style="text-align: center">Received</th>
                    <th style="text-align: center">Remaining</th>
                    <th style="text-align: center">Unit Cost($)</th>
                    <th style="text-align: center">Price A($)</th>
                    <th style="text-align: center">Price B($)</th>
                    <th style="text-align: center">Price C($)</th>
                    <th style="text-align: center">Discount%</th>
                    <th style="text-align: center">Net Cost($)</th>
                    <th style="text-align: center">Tax%</th>
                    <th style="text-align: center">Shipping($)</th>
                    <th style="text-align: center">Total Cost($)</th>
                    <th style="text-align: center">Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center">{{ item.invoiceNumber }}</td>
                    <td style="text-align: center">
                        {{ item.createdDate | date : "medium" }}
                    </td>
                    <td style="text-align: center">{{ item.itemSKUId }}</td>
                    <td style="text-align: center">
                        {{ item.dueQuantity | number : "1.0-0" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.receivedQuantity | number : "1.0-0" }}
                    </td>
                    <td style="text-align: center">
                        {{
                            item.dueQuantity - item.receivedQuantity < 0
                                ? 0
                                : (item.dueQuantity - item.receivedQuantity
                                  | number : "1.0-0")
                        }}
                    </td>
                    <td style="text-align: center">
                        {{ item.costPrice | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceA | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceB | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.priceC | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.discountPercent }}
                    </td>
                    <td style="text-align: center">
                        {{ item.netTotal | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">{{ item.taxRate }}</td>
                    <td style="text-align: center">
                        {{ item.shipping | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.totalAmount | number : "1.2-2" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.inventoryCommitStatus }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="18" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
