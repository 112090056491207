<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'app-cash-count-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>

    <br />
    <div class="grid summary">
        <div class="col" *ngFor="let tag of topTags">
            <div class="conn-card" [style]="getOverShortColor(tag)">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <label class="widget-title">
                            {{ tag.title }}
                        </label>
                        <label class="widiget-figure-main">
                            {{ calculateSummaryTotal(tag.id) | currency }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br />
    <br />
    <p-table
        #dt1
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        [globalFilterFields]="[
            'locationName',
            'cashierName',
            'registerName',
            'date'
        ]"
    >
        <ng-template pTemplate="header">
            <tr>
                <!-- <th>Location</th> -->
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Location
                        <p-columnFilter
                            type="text"
                            field="locationName"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <!-- <th>Cashier Name</th> -->
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Cashier Name
                        <p-columnFilter
                            type="text"
                            field="cashierName"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <!-- <th>Reg</th> -->
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Reg
                        <p-columnFilter
                            type="text"
                            field="registerName"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <!-- <th>Date/Time</th> -->
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Date/Time
                        <p-columnFilter
                            type="date"
                            field="date"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <th class="text-center">$100</th>
                <th class="text-center">$50</th>
                <th class="text-center">$20</th>
                <th class="text-center">$10</th>
                <th class="text-center">$5</th>
                <th class="text-center">$2</th>
                <th class="text-center">$1</th>
                <th class="text-center">25 cents (Quarters)</th>
                <th class="text-center">10 cents (Dimes)</th>
                <th class="text-center">5 cents (Nickels)</th>
                <th class="text-center">1 cents (Penny)</th>
                <th class="text-right">Total Count</th>
                <th class="text-right">System Count</th>
                <th class="text-right">Over/Short</th>
                <th class="text-right">Last Count</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.locationName }}</td>
                <td>{{ item.cashierName }}</td>
                <td>{{ item.registerName }}</td>
                <td>{{ item.date | date : "short" }}</td>
                <td class="text-center">{{ item.dollar100Qty }}</td>
                <td class="text-center">{{ item.dollar50Qty }}</td>
                <td class="text-center">{{ item.dollar20Qty }}</td>
                <td class="text-center">{{ item.dollar10Qty }}</td>
                <td class="text-center">{{ item.dollar5Qty }}</td>
                <td class="text-center">{{ item.dollar2Qty }}</td>
                <td class="text-center">{{ item.dollar1Qty }}</td>
                <td class="text-center">{{ item.cent25Qty }}</td>
                <td class="text-center">{{ item.cent10Qty }}</td>
                <td class="text-center">{{ item.cent5Qty }}</td>
                <td class="text-center">{{ item.cent1Qty }}</td>
                <td class="text-right">{{ item.total | currency }}</td>
                <td class="text-right">{{ item.systemCount | currency }}</td>
                <td class="text-right">{{ item.overShort | currency }}</td>
                <td class="text-right">{{ item.lastCount | currency }}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="19" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer" let-item>
            <tr class="summary-line">
                <td
                    colspan="4"
                    style="text-align: right; color: var(--brandColor)"
                    class="font-bold text-xl select-none"
                >
                    Totals:
                </td>
                <td
                    *ngFor="let t of toTotal"
                    style="
                        text-align: center;
                        color: var(--brandColor);
                        background-color: var(--surface-100);
                    "
                    class="font-bold text-lg select-none"
                >
                    {{ calcSummaryTotals(t) }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
