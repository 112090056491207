<div class="grid" style="align-items: center;
align-content: center;
height: 100vh;
">

    <div class="conn-card" style=" margin: auto auto;
    max-width: 800px;
    width: 90vw;">
        <div class="col-12">
            <div class="logo-wizard">
                <div class="flex align-items-center justify-content-center"
                    style="margin-top: 1rem; margin-bottom: 1rem">
                    <!-- <img [src]="_globals.whiteLabel.logo" alt="logo" width="150px" /> -->
                </div>
            </div>
        </div>
        <div class="col">
            <div class="" >
                <div class="image-icon text-align-center">
                    <i class="pi pi-envelope" style="    font-size: 51px;
                    font-weight: lighter;
                    color: var(--brandColor);"></i>
                </div>
                <h1 class="fw-bolder text-gray-900 mb-5 text-align-center">Thank You for verifying your account</h1>
                <p class="fw-semibold text-gray-500 text-align-center" style="font-size: 20px;" *ngIf="message.length>0">
                    {{message}}
                </p>
                <p class="fw-semibold text-gray-500 text-align-center" style="font-size: 20px;" *ngIf="message.length==0">
                   {{_globals.loginErrorMessage}}
                </p>
                <div class="action-class text-align-center">
                    <p-button class="text-align-center" (onClick)="gotologin()">
                        Click here to login 
                    </p-button>
                </div>

            </div>
            
        </div>

    </div>

</div>