import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from '../../_services/bizz-customers.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { Router } from '@angular/router';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { MenuItem } from 'primeng/api';
import { BusinessSetupModel, SubscriptionModel } from 'src/app/_models/subscription-management/subscription-model';
import { PlanInfo } from 'src/app/_models/subscription-management/user-subscription-info';
import { Plan } from 'src/app/_models/subscription-plan/plan';

import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-bizz-customer-detail',
  templateUrl: './bizz-customer-detail.component.html',
  styleUrls: ['./bizz-customer-detail.component.scss']
})
export class BizzCustomerDetailComponent implements OnInit {

    items: MenuItem[];


    activeItem: MenuItem;
    _globals = GlobalService;
    planInfo = <any>{};
    businessSetup = <BusinessSetupModel>{}
    dayLeftInRenewal: number = 0;

  constructor(
    public _bizzCustomerService: BizzCustomersService,
    private _subscriptionManagementService: SubscriptionPlanService,
        private _router: Router,
        private _abd: AppBaseDataService
  ) { }

  currentCustomer:any
  ngOnInit(): void {

    this.currentCustomer = this._bizzCustomerService.activeItem
    this.items = [
        { label: 'Subscription' },
        { label: 'Billing History' },
        { label: 'Hardware' },
    ];
    if (this.currentCustomer.isFreeTrial){
            this.items.splice(1,1);
        }
   this.getData();
  }
  getData() {
    this._abd.showSpinner()
    try {
        this.activeItem = this.items[0];
        this.businessSetup = this._globals.userInfo.businessSetup[0];
        this.planInfo = this.currentCustomer;

        // if (this.currentCustomer.isFreeTrial){
        //     this.items.splice(1,1);
        //     //this.items.splice(1,1);
        // }

        if (this.planInfo.isFreeTrial) {

            let startDate: Date = new Date(this.planInfo.freeTrialStartDate.toString());
            let endDate: Date = new Date(this.planInfo.freeTrialEndDate.toString());

            let diff = Math.abs(endDate.getTime() - startDate.getTime());
            this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));
        }
        // else {
        //     this.items.push({
        //         label: 'Billing Address',
        //     })
        //     let startDate: Date = new Date(this.planInfo.createdDate.toString());
        //     let endDate: Date = new Date(this.planInfo.nextChargeDate.toString());

        //     let diff = Math.abs(endDate.getTime() - startDate.getTime());
        //     this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));
        // }

    }
    catch
    {

    }
    finally
    {
        this._abd.hideSpinner()
    }

}
onTabItemClick(tab: any) {
    this.activeItem = tab.activeItem
}


}
