import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorsService {
  private static errorsList = [
    {
      code: "IMS0008900",
      responseStatusCode: 900,
      message: "Department name is required",
      unAuthorize: false
    },
    {
      code: "IMS0008901",
      responseStatusCode: 901,
      message: "During save category found error. \nError code is: IMS0008901",
      unAuthorize: false
    },
    {
      code: "IMS0008919",
      responseStatusCode: 919,
      message: "Category already exist with same title. Please try different title",
      unAuthorize: false
    },
    {
      code: "IMS0006907",
      responseStatusCode: 907,
      message: "Unauthorize request. We are logging you out",
      unAuthorize: true
    },
    {
      code: "IMS0011907",
      responseStatusCode: 907,
      message: "Unauthorize request. We are logging you out",
      unAuthorize: true
    },
    {
      code: "IMS0012907",
      responseStatusCode: 907,
      message: "Unauthorize request. We are logging you out",
      unAuthorize: true
    },
    {
      code: "IMS0013900",
      responseStatusCode: 907,
      message: "Department name is required",
      unAuthorize: false
    },
    {
      code: "IMS0013919",
      responseStatusCode: 919,
      message: "This department already added in system",
      unAuthorize: false
    },
    {
      code: "IMS0013906",
      responseStatusCode: 906,
      message: "Unable to save department. \nError code is: IMS0013906 ",
      unAuthorize: false
    },
    {
      code: "IMS0014900",
      responseStatusCode: 900,
      message: "Please provide all the required fields to update department",
      unAuthorize: false
    },
    {
      code: "IMS0014907",
      responseStatusCode: 907,
      message: "Unauthorize request. We are logging you out",
      unAuthorize: true
    },
    {
      code: "IMS0014906",
      responseStatusCode: 906,
      message: "Unable to update department. \nError code is: IMS0014906",
      unAuthorize: true
    },
    {
      code: "IMS0015901",
      responseStatusCode: 901,
      message: "Unable to delete department. \nError code is: IMS0015901",
      unAuthorize: true
    },
    {
      code: "IMS0015904",
      responseStatusCode: 904,
      message: "Unable to delete department",
      unAuthorize: true
    },
    {
      code: "AS0016901",
      responseStatusCode: 901,
      message: "Unable to load subscription packages.\nError code is: AS0016901",
      unAuthorize: true
    },
    {
      code: "AS0001907",
      responseStatusCode: 907,
      message: "Business Name can't be empty / Business Not Exist!",
      unAuthorize: true
    },
    {
      code: "AS0003907",
      responseStatusCode: 907,
      message: "Business does not exist!",
      unAuthorize: true
    },
    {
      code: "AS0003900",
      responseStatusCode: 900,
      message: "Customer Profile data is not valid!",
      unAuthorize: false
    },
    {
      code: "AS0003922",
      responseStatusCode: 922,
      message: "Invalid Subscription Details",
      unAuthorize: false
    },
    {
      code: "AS0003906",
      responseStatusCode: 906,
      message: "Found error during signup.Account has been created successfully.\nError code is: AS0003906",
      unAuthorize: false
    },
    {
      code: "AS0003901",
      responseStatusCode: 901,
      message: "Unable to create account.\nError code is: AS0003901",
      unAuthorize: true
    },
    {
      code: "AMS0010904",
      responseStatusCode: 904,
      message: "No record found",
      unAuthorize: false
    },
    {
      code: "AMS0010900",
      responseStatusCode: 900,
      message: "Location info invalid",
      unAuthorize: false
    },
    {
      code: "AMS0010901",
      responseStatusCode: 900,
      message: "Request not processed.\nError code is: AMS0010901",
      unAuthorize: false
    },
    {
      code: "CMS0001907",
      responseStatusCode: 907,
      message: "Unable to complete request.",
      unAuthorize: true
    },
    {
      code: "CMS0001904",
      responseStatusCode: 904,
      message: "No result found",
      unAuthorize: false
    },
    {
      code: "CMS0001901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: AMS0010901",
      unAuthorize: false
    },
    {
      code: "AMS0002901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: AMS0002901",
      unAuthorize: false
    },
    {
      code: "OPS0003900",
      responseStatusCode: 900,
      message: "Invoice number is empty",
      unAuthorize: false
    },
    {
      code: "OPS0004907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: OPS0003901",
      unAuthorize: true
    },
    {
      code: "OPS0001",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0001901",
      unAuthorize: false
    },
    {
      code: "OPS0004901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0004901",
      unAuthorize: false
    },
    {
      code: "OPS0007901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0007901",
      unAuthorize: false
    },
    {
      code: "OPS0011903",
      responseStatusCode: 903,
      message: "No result found",
      unAuthorize: false
    },
    {
      code: "OPS0001",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0001901",
      unAuthorize: false
    },
    {
      code: "OPS0011907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: OPS0011907",
      unAuthorize: true
    },
    {
      code: "OPS0002901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0002901",
      unAuthorize: false
    },
    {
      code: "OPS0004901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: OPS0004901",
      unAuthorize: false
    },
    {
      code: "AS0014901",
      responseStatusCode: 901,
      message: "Request not processed",
      unAuthorize: false
    },
    {
      code: "AS0014903",
      responseStatusCode: 903,
      message: "Invalid email/username and password",
      unAuthorize: false
    },
    {
      code: "AMS0010903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0084903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0058903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0004903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "CMS0001903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0033903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "AMS0065903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0056903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0054903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0086903",
      responseStatusCode: 903,
      message: "Request Completed Successfully",
      unAuthorize: false
    },
    {
      code: "IMS0019901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0019901",
      unAuthorize: false
    },
    {
      code: "IMS0020901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0020901",
      unAuthorize: false
    },
    {
      code: "IMS0021901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0021901",
      unAuthorize: false
    },
    {
      code: "IMS0022901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0022901",
      unAuthorize: false
    },
    {
      code: "IMS0045919",
      responseStatusCode: 919,
      message: "Manufacturer is duplicate. Please try different manufacturer.",
      unAuthorize: false
    },
    {
      code: "AMS0030901",
      responseStatusCode: 919,
      message: "Request not processed.\nError code is: AMS0030901",
      unAuthorize: false
    },
    {
      code: "IMS0018907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: IMS0018907",
      unAuthorize: true
    },
    {
      code: "IMS0018900",
      responseStatusCode: 900,
      message: "Request not processed.\nError code is: IMS0018900",
      unAuthorize: false
    },
    {
      code: "IMS0018901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0018901",
      unAuthorize: false
    },
    {
      code: "IMS0020907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: IMS0020907",
      unAuthorize: true
    },
    {
      code: "IMS0020901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0020901",
      unAuthorize: false
    },
    {
      code: "IMS0021907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: IMS0021907",
      unAuthorize: true
    },
    {
      code: "IMS0021901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0020901",
      unAuthorize: false
    },
    {
      code: "IMS0022907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: IMS0022907",
      unAuthorize: true
    },
    {
      code: "IMS0022901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0022901",
      unAuthorize: false
    },
    {
      code: "IMS0023907",
      responseStatusCode: 907,
      message: "Request not processed.\nError code is: IMS0023907",
      unAuthorize: true
    },
    {
      code: "IMS0023901",
      responseStatusCode: 901,
      message: "Request not processed.\nError code is: IMS0023901",
      unAuthorize: false
    },
    {
      code: "CMS0007904",
      responseStatusCode: 904,
      message: "No customer found with the given search input",
      unAuthorize: false
    },
    {
      code: "AMS0002903",
      responseStatusCode: 903,
      message: "Phone Number already exist!",
      unAuthorize: false
    },
    {
      code: "AS0004914",
      responseStatusCode: 914,
      message: "Invalid Link Activiation Link!",
      unAuthorize: false
    },
  ];
  constructor() {

  }

  public static getErrorObject(data: any) {
    const errObj = this.errorsList.find((x: any) => x.code === data.code)
    return errObj
  }
}
