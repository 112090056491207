import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hourly-sales-report',
  templateUrl: './hourly-sales-report.component.html',
  styleUrls: ['./hourly-sales-report.component.scss']
})
export class HourlySalesReportComponent implements OnInit, OnDestroy {
  _globals = GlobalService
  orignalDataList: any[] = [];
  dataList: any[] = [];
  revisedDataList: any[] = [];
  locationIds: string[] = [];

  colheader = this._abd.reports.filter(x => x.componentName == 'Hourly Sales Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Hourly Sales Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Hourly Sales Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Hourly Sales Report')[0].summaryflds;
  csvcols = []
  exportColumns: any = ''


  constructor(
    private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Hourly Sales Report'

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  filterButtonPressed() {
    this.getDataList()
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    let x = this._filterService.selectedProductSellTypeFilter
    if (!x) x = 3
    else x = x.Value

    this.getLocationsList();
    const data = {
      "ReportName": 'HourlyReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.singleDate, 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.singleDate, 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'OrderPlacement': x
      }

    }

    const url = this._abd.reports.filter(x => x.componentName == 'Daily Sales Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      var refinedData = [];
      this.dataList = res.data;
      // this.orignalDataList = res.data;
      this._abd.hideSpinner();
      // if(this.orignalDataList.length>0){
      //   refinedData = this.consolidateData(this.orignalDataList);
      // }
      // this.dataList=refinedData;
    })
  }

  consolidateData(dataList: any[]) {
    this.revisedDataList = [];
    let self = this;
    this.locationIds.map((value, i) => {
      self.revisedDataList.push({
        storeName: self.orignalDataList.filter(f => f.locationId == value)[0].locationName,
        data: self.orignalDataList.filter(f => f.locationId == value)
      })
    })
    return this.revisedDataList
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Hourly Sales Report')
  }

  exportPdf() {
    // let data = []

    // for (let i = 0; i < this.dataList.length; i++) {
    //   let row = []
    //   for (let j = 0; j < this.colflds.length; j++)
    //     row.push(this.dataList[i][this.colflds[j]])
    //   data.push(row)
    // }
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Hourly Sales Report', true, true);
  }

  calculateSummaryTotal(col: string) {
    // 
    ;
    let total = "0";
    if (col.includes("Count")) {
      total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
    }
    else {
      total = this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
    }
    return total;

  }


}
