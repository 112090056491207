import { DropDownModel } from "./drop-down-model";

export let BusinessTypeList: DropDownModel[] = [
    {
        view: 'Retail',
        viewValue: 'Retail'
    },
    {
        view: 'Rewards',
        viewValue: 'Rewards'
    },
    {
        view: 'QST/SM Tkt',
        viewValue: 'QST/SM Tkt'
    },
    {
        view: 'Lodging',
        viewValue: 'Lodging'
    },
    {
        view: 'Supermarket B2B',
        viewValue: 'Supermarket B2B'
    },
    {
        view: 'Utility Petroleum',
        viewValue: 'Utility Petroleum'
    },
    {
        view: 'MOTO/Internet',
        viewValue: 'MOTO/Internet'
    },
    {
        view: 'Restaurant',
        viewValue: 'Restaurant'
    },
    {
        view: 'Mail Order',
        viewValue: 'Mail Order'
    },
]
