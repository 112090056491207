import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { LoyaltyConfig } from 'src/app/_models/reward-program/loyalty-configuration';
import { PerksReward } from 'src/app/_models/reward-program/perk-model';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';

@Component({
  selector: 'app-tiered-reward-configuration',
  templateUrl: './tiered-reward-configuration.component.html',
  styleUrls: ['./tiered-reward-configuration.component.scss']
})
export class TieredRewardConfigurationComponent implements OnInit {

    _globals = GlobalService
    @ViewChild('op') op: OverlayPanel
    page = 0
    rows = 10
    first = 0
    totalRecords = 0
    dataList: PerksReward[] = []
    searchText = ''
    lastSearchText = ''
    masterDataList: any = null
    locationIds: string[] = [];
    isViewOnly = false



    actions: MenuItem[] = [
        { label: 'Edit', icon: 'pi pi-book' },
        { label: 'Delete', icon: 'pi pi-trash' },
    ]

    colheader = [[
        'Name', 'Codes', 'Start Date', 'End Date'
        , 'Limit Discount', 'Customer Type', 'Allowed Locations'
    ]];

    colflds = ['discountName', 'couponCodesListWithSpace', 'validityStartDate', 'validityEndDate',
        'limitDiscount', 'customerType', 'allowedLocationsListString'
    ];

    @ViewChild('p', { static: false }) paginator: Paginator;

    showGeneralRewardSaveModel: boolean = false;
    isNew : boolean = true;
    @Output() tieredRewardSaved = new EventEmitter();

    lsearchText = ''

    orderParam = 'displayName'
    orderBy = 'desc'

    constructor(
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService,
        private _confirmationService: ConfirmationService,
        public _abd: AppBaseDataService,
        private _messageService: MessageService,
        public _filterService: FilterServiceService,
        public _rewardProgramService: RewardProgramService
    ) { }

    ngOnInit(): void {

        this.isNew = true;
    //
        this.orderParam = 'displayName'
        this.orderBy = 'desc'

        this.getTieredProgramData();
    }

    getTieredProgramData() {
        try {
            this._abd.showSpinner()
            this._rewardProgramService.getTieredDiscount(this.makeParams()).subscribe((r) => {
                if (r.success) {
                    this.dataList = [];
                    this.totalRecords = r.totalRecords
                    this.dataList = r.data;
                    this._abd.hideSpinner()
                }
                else {
                    this.dataList = [];
                    this._messageService.add({ severity: 'error', summary: 'No data found' })
                    this._abd.hideSpinner()
                }
            }, error => {
                this.dataList = [];
                this._messageService.add({ severity: 'error', summary: 'Unable to load data' })
                this._abd.hideSpinner()
            })
        } catch (error) {

        }
    }

    makeParams() {
        try {
            let params = new HttpParams()
            params = params.append('startDate', '1800-01-01');
            params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

            params = params.append('pageNo', this.page);
            params = params.append('pageSize', this.rows);
            params = params.append('locationId', '')
            params = params.append('vendorId', '')

            params = params.append('orderParam', this.orderParam)
            params = params.append('orderBy', this.orderBy)

            if (this._filterService.searchText.length) params = params.append('filterValue', this._filterService.searchText);


            return params
        } catch (error) {

        }
    }

    customSort1(e) {
        const last = this.orderParam

        switch (e.sortField) {
            case 'displayName':
                this.orderParam = 'displayName'
                break;
            case 'pointsGoal':
                this.orderParam = 'pointsGoal'
                break;
            case 'rewardType':
                this.orderParam = 'rewardType'
                break;
            default:
        }

        if (this.orderParam !== '') {
            this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
            if (last !== this.orderParam) this.orderBy = 'asc'
            this.getTieredProgramData();
        }
    }

    filterButtonPressed() {
        this.page = 0
        this.getTieredProgramData();
    }

    onPageChange(e) {
        this.page = e.page
        this.rows = e.rows
        this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
        this.getTieredProgramData();
    }

    optionDeleteTieredClicked() {
        try {
            this.op.hide()
            this._confirmationService.confirm({
                message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                accept: () => {
                    this._abd.showSpinner()
                    try {
                        this._rewardProgramService.deleteTiered(this._rewardProgramService.discountRewardObj.id).subscribe((r)=>{
                            if(r.success){
                                this._rewardProgramService.discountRewardObj = null;
                                this.getTieredProgramData();

                            }
                            else{
                                this._abd.hideSpinner()
                            }
                        })
                    } catch {
                        this._abd.hideSpinner()
                    }
                },
                reject: () => { }
            });
        } catch (error) {

        }
    }

    exportExcel() { }

    exportPdf() { }


    onRowSelect(e) {
        this._rewardProgramService.discountRewardObj = JSON.parse(JSON.stringify(e.data))

    }
    onRowUnselect(e) { }

    editButtonClicked() {
        this._rewardProgramService.showNewEditTieredModal = true;
        this.showGeneralRewardSaveModel = true;
    }

    editTieredProgram(){
        this.isNew = false;
        this._rewardProgramService.showNewEditTieredModal = true;
    }

    actionButtonClick(item: any) {
        this._rewardProgramService.discountRewardObj = JSON.parse(JSON.stringify(item))
    }

    refreshDataList(){
        this.tieredRewardSaved.emit(true);
        this.isNew = true;
        this.getTieredProgramData();
        this._rewardProgramService.discountRewardObj = null;
    }

    callbackFuncFromFilter(event) {
        if (this._abd.searchText == '') {
            this.page = 0
            this.paginator?.changePage(0)
            // this._abd.resetFilters()
            this.getTieredProgramData()
            this.lsearchText = this._abd.searchText
            return
        }
        if (this.lsearchText == this._abd.searchText) return
        this.page = 0
        this.paginator?.changePage(0)
        this.lsearchText = this._abd.searchText
        this.getTieredProgramData()
    }

    searchTextSearch() {
        if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
        if (this.searchText === this.lastSearchText) return

        this.lastSearchText = this.searchText
        this.filterButtonPressed()
    }
}
