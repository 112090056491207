<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Plan
</h3>

<div class="nas-grid-2x">
    <div class="nas-label">Title</div>
    <div class="nas-value">
        {{ localObj.title }}
        <span class="mx-1" *ngIf="_bizzCustomerService.activeItem?.subscriptionInterval == 1">monthly</span>
        <span class="mx-1"  *ngIf="_bizzCustomerService.activeItem?.subscriptionInterval == 12">annually</span>
    </div>
</div>

<br>
<div class="nas-grid-2x">
    <div class="nas-label">Description</div>
    <div class="nas-value">
        {{ localObj.description }}
    </div>
</div>

<br>
<div class="nas-grid-2x">
    <div class="nas-label">Base Plan Price</div>
    <div class="nas-value">
        <span *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
            {{ planPrice  | currency:'USD':'symbol' }}/per month
            <span *ngIf="_bizzCustomerService.activeItem?.subscriptionInterval == 12">X</span>

            <span
                *ngIf="_bizzCustomerService.activeItem?.subscriptionInterval == 12">{{_bizzCustomerService.activeItem?.subscriptionInterval}}
                = </span>
           <span *ngIf="_bizzCustomerService.activeItem?.subscriptionInterval == 12">
               {{ planPrice * _bizzCustomerService.activeItem?.subscriptionInterval | currency:'USD':'symbol'}}
            </span>
        </span>
        <span *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
            {{ planPrice  | currency:'USD':'symbol'}}
        </span>
    </div>
</div>

<br>
<div class="nas-grid-2x">
    <div class="nas-label">Subscribed Location(s)</div>
    <div class="nas-value">
        {{ _bizzCustomerService.activeItem?.subscriptionInvoice.noOfLocations }}
    </div>
</div>

<br>
<div class="nas-grid-2x">
    <div class="nas-label">Subscribed Register(s)</div>
    <div class="nas-value">
        {{ _bizzCustomerService.activeItem?.subscriptionInvoice.noOfRegisters }}
    </div>
</div>
