<p-overlayPanel #opReceiveNote [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <textarea
            [rows]="7"
            [cols]="50"
            pInputTextarea
            [(ngModel)]="notes"
            [disabled]="true"
        ></textarea>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->
<h1>This is the beginning</h1>
<div>
    <h2 class="bs-page-header mb-3">{{ this._globals.componentName }}</h2>
</div>

<p-progressBar
    mode="indeterminate"
    *ngIf="!isDataLoaded && !isNew"
></p-progressBar>

<div class="grid mt-3" *ngIf="isDataLoaded">
    <div class="xl:col-3 lg:col-3 sm:col-12 md:col-6">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="col">
                <div class="grid">
                    <div class="col-12 text-700 font-medium text-lg font-bold">
                        PO#
                    </div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.poNumber"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-12 text-700 font-medium text-lg font-bold">
                        Sender
                    </div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.requestedBy"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 sm:col-12 md:col-6">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="col">
                <div class="grid">
                    <div class="col-12 text-700 font-medium text-lg font-bold">
                        Creation Date
                    </div>
                    <div class="col-12">
                        <p-calendar
                            [(ngModel)]="activeItem.dated"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            [disabled]="true"
                        ></p-calendar>
                    </div>
                </div>
            </div>

            <br />
            <div class="text-700 font-medium text-lg mb-2">Receiving Date</div>
            <div class="mt-2">
                <p-calendar
                    (onInput)="createDateClear()"
                    [(ngModel)]="activeItem.receiveDate"
                    showIcon="true"
                    [style]="{ width: '100%' }"
                    [disabled]="isViewOnly"
                    [minDate]="activeItem?.dated"
                ></p-calendar>
            </div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 sm:col-12 md:col-6">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="col">
                <div class="grid">
                    <div class="col-12 text-700 font-medium text-lg font-bold">
                        Location
                    </div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.locationName"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-12 text-700 font-medium text-lg font-bold">
                        Vendor
                    </div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="activeItem.vendorName"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 sm:col-12 md:col-6">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="flex justify-content-between">
                <div
                    class="flex align-items-center justify-content-center text-700 font-medium text-lg m-3"
                >
                    Total Ordered Quantity
                </div>
                <div
                    class="flex align-items-center justify-content-center text-400 font-medium text-2xl m-3 font-bold"
                >
                    {{ gtotalItems | number : "1.0-0" }}
                </div>
            </div>

            <div class="flex justify-content-between mt-4">
                <div
                    class="flex align-items-center justify-content-center text-700 font-medium text-lg m-3"
                >
                    Total Received Quantity
                </div>
                <div
                    class="flex align-items-center justify-content-center text-400 font-medium text-2xl m-3 font-bold"
                >
                    {{ greceivedItems | number : "1.0-0" }}
                </div>
            </div>

            <div class="flex justify-content-between mt-4">
                <div
                    class="flex align-items-center justify-content-center text-700 font-medium text-lg m-3"
                >
                    Total Remaining Quantity
                </div>
                <div
                    class="flex align-items-center justify-content-center text-400 font-medium text-2xl m-3 font-bold"
                >
                    {{ gremainingItems | number : "1.0-0" }}
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<div class="conn-card" *ngIf="isDataLoaded">
    <div
        class="shadow-2 p-2 h-full flex flex-column surface-card"
        style="border-radius: 3px"
    >
        <div class="flex justify-content-between">
            <div class="flex justify-content-start">
                <div class="flex mr-3">
                    <label class="mr-2" style="margin-top: 12px">
                        <strong
                            >Invoice Number
                            <span
                                style="
                                    color: red;
                                    font-size: 1.25rem;
                                    margin-right: 4px;
                                "
                                >*</span
                            >
                        </strong></label
                    >
                    <div class="form-group" style="margin-top: 0px">
                        <p-inputMask
                            [(ngModel)]="invoiceNumber"
                            [autoClear]="false"
                            [unmask]="true"
                            [autoFocus]="true"
                            [required]="true"
                            [slotChar]="''"
                            [disabled]="isViewOnly"
                            mask="99999999999999999999"
                        ></p-inputMask>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex align-items-center justify-content-center">
                        <div
                            class="flex align-items-center ml-2 font-medium text-600"
                        >
                            Receivied By
                            <span class="ml-2">
                                <input
                                    type="text"
                                    pInputText
                                    placeholder="Quick search by scanning barcode"
                                    [disabled]="true"
                                    [(ngModel)]="activeItem.requestedBy"
                                />
                                <!-- to be changed -->
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex align-items-center justify-content-center">
                <div
                    *ngIf="!isNew"
                    class="flex align-items-center justify-content-center m-2"
                >
                    <button
                        *ngIf="activeItem.poStatus == 'POSTED'"
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-outlined'
                                : 'p-button-rounded p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                    ></button>
                </div>

                <div class="flex align-items-center justify-content-center">
                    <p-chip
                        *ngIf="!isNew"
                        [label]="
                            _purchaseService.getPurchaseStatusName(
                                activeItem.poStatus
                            )
                        "
                        [styleClass]="
                            _purchaseService.getPoStatusClass(
                                activeItem.poStatus
                            )
                        "
                    ></p-chip>
                </div>
            </div>
        </div>
    </div>

    <br />
    <div>
        <p-table
            [value]="dataList"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Sr#</th>
                    <th>Barcode</th>
                    <th>SKU</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th style="text-align: center">Ordered Qty</th>
                    <th style="text-align: center">Due Qty</th>
                    <th style="text-align: center">Received Qty</th>
                    <th style="text-align: center">Remaining Qty</th>
                    <th style="text-align: right">Unit Cost</th>
                    <th style="text-align: right">Price A</th>
                    <th style="text-align: right">Price B</th>
                    <th style="text-align: right">Price C</th>
                    <!-- <th style="text-align: right">Sub Total</th> -->
                    <th style="text-align: right">Discount (%)</th>
                    <!-- <th style="text-align: right">Net Total</th> -->
                    <th style="text-align: right">Tax (%)</th>
                    <th style="text-align: right">Total Cost</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.srNo }}</td>
                    <td>
                        {{ item.barcode }}
                    </td>
                    <td>
                        {{ item.itemSKUId }}
                    </td>
                    <td>
                        {{ item.productName | slice : 0 : 25 }}
                        <span *ngIf="item.productName?.length > 25">...</span>
                    </td>
                    <td>{{ item.uomDescription }}</td>
                    <td style="text-align: center">
                        {{ item.orderedQuantity }}
                    </td>
                    <td style="text-align: center">
                        {{ item.dueQuantity }}
                    </td>
                    <!-- <td>{{ product.transferQuantity }}</td> -->
                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.receivedQuantity"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="
                                        receivedQuantityChanged($event, item)
                                    "
                                    [ngClass]="[
                                        !receivedQuantityValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!receivedQuantityValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                cannot be greater than due quantity
                            </span>
                        </div>
                    </td>

                    <td style="text-align: center">
                        {{ item.dueQuantity - item.receivedQuantity }}
                    </td>

                    <td style="min-width: 90px">
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.costPrice"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="costPriceChanged($event, item)"
                                    [ngClass]="[
                                        !costPriceValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!costPriceValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                Cost price cannot be less than zero (0)
                            </span>
                        </div>
                    </td>

                    <td>
                        <!-- {{ item.totalCost | number: "1.2-2" }} -->
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.priceA"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <!-- {{ item.totalCost | number: "1.2-2" }} -->
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.priceB"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.priceC"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <!-- <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.subTotal"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '130px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td> -->

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.pdiscount"
                                    (onInput)="discountChanged($event, item)"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    [max]="100"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <!-- <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.netTotal"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '130px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td> -->

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.ptax"
                                    (onInput)="taxChanged($event, item)"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.totalCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '130px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="15" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Sum of Subtotal ($):
                    </td>
                    <td
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        {{ sumofsubtotal | number : "1.2-2" }}
                    </td>
                </tr>

                <tr class="summary-line">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Discount ($):
                    </td>
                    <td
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        {{ gdiscount | number : "1.2-2" }}
                    </td>
                </tr>

                <tr class="summary-line">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Tax ($):
                    </td>
                    <td
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        {{ gtax | number : "1.2-2" }}
                    </td>
                </tr>

                <tr class="summary-line">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Shipping ($):
                    </td>
                    <td>
                        <p-inputNumber
                            [disabled]="!dataList.length || isViewOnly"
                            [(ngModel)]="shippingCost"
                            mode="decimal"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            (onInput)="shippingCostChanged($event)"
                            [maxFractionDigits]="2"
                            [inputStyle]="{
                                width: '130px',
                                'text-align': 'right'
                            }"
                        ></p-inputNumber>
                    </td>
                </tr>

                <tr class="summary-line">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        GRAND TOTAL ($):
                    </td>
                    <td
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        {{ grandTotal | number : "1.2-2" }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <br />
    <br />
    <div class="grid" *ngIf="dataList.length > 0">
        <div class="field col">
            <label for="notes" class="text-700 font-medium text-lg"
                >Receiving Notes</label
            >
            <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                class="w-full"
                [(ngModel)]="receivedNotes"
                [disabled]="isViewOnly"
            ></textarea>
        </div>
    </div>

    <div *ngIf="histList">
        <br />
        <br />
        <p-accordion>
            <p-accordionTab header="Receiving History" [selected]="true">
                <!-- ----- history list -- begin -->
                <p-table
                    #dh1
                    [value]="histList"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'max-content' }"
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                    [rows]="5"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[5, 10, 25]"
                    [loading]="loading"
                    [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [filterDelay]="0"
                    [globalFilterFields]="[
                        'productName',
                        'barcode',
                        'itemSKUId'
                    ]"
                >
                    <ng-template pTemplate="caption">
                        <div class="flex justify-content-end">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="
                                        dh1.filterGlobal(
                                            $event.target.value,
                                            'contains'
                                        )
                                    "
                                    placeholder="Search keyword"
                                />
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Sr#</th>
                            <th>Date</th>
                            <th>Invoice#</th>
                            <th>Barcode</th>
                            <th>SKU</th>
                            <th pSortableColumn="productName">
                                Item
                                <p-sortIcon field="productName"></p-sortIcon>
                            </th>
                            <th style="text-align: center">UOM</th>
                            <th style="text-align: center">Received Qty</th>
                            <th style="text-align: center">Remaining Qty</th>
                            <th style="text-align: center">Unit Cost</th>
                            <th style="text-align: center">Commited Status</th>
                            <th style="text-align: right">Total</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.srNo }}</td>
                            <td>{{ item.dated | date : "mediumDate" }}</td>
                            <td>{{ item.invoiceNumber }}</td>
                            <td>{{ item.barcode }}</td>
                            <td>{{ item.itemSKUId }}</td>
                            <td>
                                {{ item.productName | slice : 0 : 25 }}
                                <span *ngIf="item.productName?.length > 25"
                                    >...</span
                                >
                            </td>
                            <td style="text-align: center">
                                {{ item.uomDescription }}
                            </td>
                            <td style="text-align: center">
                                {{ item.receivedQuantity }}
                            </td>
                            <td style="text-align: center">
                                {{ item.dueQuantity }}
                            </td>
                            <td style="text-align: center">
                                {{ item.costPrice | currency }}
                            </td>
                            <td style="text-align: center">
                                {{ item.committedQuantity > 0 ? "Yes" : "No" }}
                            </td>
                            <td style="text-align: right">
                                {{ item.totalAmount | currency }}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template
                        pTemplate="emptymessage"
                        style="text-align: center"
                    >
                        <tr>
                            <td colspan="12" style="text-align: center">
                                There is no data to show.
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="footer">
                        <tr class="summary-line">
                            <td
                                colspan="11"
                                style="text-align: right"
                                class="text-700 font-medium text-lg"
                            >
                                TOTAL:
                            </td>
                            <td
                                style="text-align: right"
                                class="text-700 font-medium text-lg"
                            >
                                {{ ghistGtotal | currency }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- ----- history list -- end -->
            </p-accordionTab>
        </p-accordion>
    </div>

    <br />
    <br />
    <div class="flex justify-content-end flex-wrap">
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus == 'RECEIVED'"
        >
            <button
                pButton
                type="button"
                label="Post to Inventory"
                class="p-button-info"
                style="background-color: green"
                (click)="saveFn('COMPLETED')"
            ></button>
        </div>

        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus !== 'RECEIVED'"
        >
            <p-checkbox
                class="text-500 font-bold text-lg"
                [(ngModel)]="commitPartial"
                [binary]="true"
                label="Commit Partial"
            ></p-checkbox>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus !== 'RECEIVED'"
        >
            <button
                pButton
                type="button"
                label="Partial"
                class="p-button-info"
                style="width: 130px"
                (click)="saveFn('PARTIAL')"
            ></button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="activeItem.poStatus !== 'RECEIVED'"
        >
            <button
                pButton
                type="button"
                label="Receive"
                class="p-button-help"
                style="width: 130px"
                (click)="saveFn('RECEIVED')"
            ></button>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                label="Cancel"
                class="p-button-danger"
                style="width: 130px"
                (click)="cancelButtonClicked()"
            ></button>
        </div>
    </div>
</div>
