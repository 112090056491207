import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hardware-plan-widget-base',
  templateUrl: './hardware-plan-widget-base.component.html',
  styleUrls: ['./hardware-plan-widget-base.component.scss']
})
export class HardwarePlanWidgetBaseComponent implements OnInit {
  @Input() hardware: any
  @Output() onSelectionChanged = new EventEmitter<any>();
  
  selected=false

  constructor() { }

  ngOnInit(): void {
this.selected=this.hardware.selected
  }

  selectClicked(id: number) {
    this.onSelectionChanged.emit(id);
  }

}
