import { ManufacturersService } from 'src/app/_services/manufacturers.service';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Renderer2, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { InventoryCategoriesComponent } from '../../inventory/inventory-categories/inventory-categories.component';
import { Manufacturer } from 'src/app/_models/manufacturer';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-new-edit-manufacturer',
  templateUrl: './new-edit-manufacturer.component.html',
  styleUrls: ['./new-edit-manufacturer.component.scss']
})
export class NewEditManufacturerComponent implements OnInit {
  whatAction = ''
  privateSelected: any = null
  name: any = ''
  editform: FormGroup
  validationErrors: string[] = []


  _globals = GlobalService
  @Input() isNew: boolean = true
  @ViewChild(InventoryCategoriesComponent) __parent!: InventoryCategoriesComponent
  @ViewChild("inpmanufacturerName") afControl: ElementRef<HTMLInputElement>
  @Output() refreshFn = new EventEmitter<any>()

  constructor(
    public _abd: AppBaseDataService,
    public _fb: FormBuilder,
    public _manufacturerService: ManufacturersService,
    public _productService: ProductsService,
    public _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.isNew || !this._manufacturerService.isViewOnly) {
      this.whatAction='Save'
      if(this.isNew) this._manufacturerService.activeItem = this.initModel()
    } else {
      this.whatAction='Edit'
    }
  }

  ngAfterViewInit() {
    // this.afControl.nativeElement.focus()
    // setTimeout(() => this.afControl.nativeElement.focus(),500)
  }

  ngAfterContentInit() {
    this.initForm()
    
  }

  initModel(): Manufacturer {
    var model: Manufacturer = {
      id: null,
      manufacturerName: null,
      manufacturerId: null,
      description: null,
      createdDate: null,
      isActive: true,
      isDefault: null,
      lastEdited: null,
      addedBy: null,
      partitionKey: null,
    }
    return model
  }

  initForm() {
    this.editform = this._fb.group({
      manufacturerName: [{value: this._manufacturerService.activeItem?.manufacturerName, disabled: this._manufacturerService.isViewOnly}, Validators.required]
    })
    // setTimeout(() => this.afControl.nativeElement.focus(),500)
  }

  onSelectItem(e) {
    this.isNew = false
    this.privateSelected = JSON.parse(JSON.stringify(e))
    this.editform.patchValue({ manufacturerName: e.manufacturerName })
  }

  saveFn() {
    if(this.whatAction=='Edit') {
      this._manufacturerService.isViewOnly = false
      this.whatAction='Save'
      this.editform.controls['manufacturerName'].enable()
      // setTimeout(() => this.afControl.nativeElement.focus(),500)
      // this._renderer.selectRootElement('#manufacturerName').focus()
      return
    }

    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);
    this.name = this.editform.get('manufacturerName').value.toLowerCase()

    if (this.isNew) {
      this._manufacturerService.activeItem.addedBy = this._globals.userInfo.userID
      this._manufacturerService.activeItem.id = null
    }

    this._abd.showSpinner()
    this._manufacturerService.createUpdateManufacturer(this._manufacturerService.activeItem).subscribe(res => {
      if (res.success) {
        this._connMessageService.showToastMessage(`Manufacturer saved successfully.`, 'success');
        this.cancelButtonClick()
        this._abd.getAllManufacturers()
        this.refreshFn.emit(true)
        this._abd.hideSpinner()

      } else { this._abd.hideSpinner() }
    })
  }

  cancelButtonClick() {
    this.editform.reset()
    this._manufacturerService.activeItem.manufacturerName = ''
    this.editform.patchValue({ manufacturerName: '' })
    this._manufacturerService.showNewEdit = false
    this._manufacturerService.showNewNewEdit = false
    this._productService.isNewManufacturer = false
  }



}
