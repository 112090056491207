<p-overlayPanel
    #opActions
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                [label]="getActionButtonLabel()"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="optionEditClicked('edit')"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Reject"
                class="p-button-danger p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionEditClicked('reject')"
            ></button>
            <!-- <button
                pButton
                pRipple
                type="button"
                label="Change Status"
                class="p-button-danger p-button-text"
                icon="pi pi-sort"
                iconPos="right"
                (click)="changeStatusClicked()"
            ></button> -->
        </div>
    </ng-template>
</p-overlayPanel>
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'transfer-receive-list'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
        (locationChangedEmitter)="locationChanged($event)"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]="csvcols"
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Sr#</th>
                <th>Date</th>
                <th>Transfer #</th>
                <th>Sender Store</th>
                <th>Receiving Store</th>
                <th class="text-center">Item Xfered</th>
                <th class="text-right">Shipment Cost</th>
                <th class="text-right">Total Cost ($)</th>
                <th class="text-center">Status</th>
                <th>Action</th>
            </tr>
        </ng-template>

        <!-- <ng-template pTemplate="header">
            <tr>
                <th rowspan="2">SR#</th>
                <th rowspan="2">Date</th>
                <th rowspan="2">Transfer #</th>
                <th colspan="2" class="text-center">Stores</th>
                <th rowspan="2" colspan="1" class="text-center">Qty</th>
                <th colspan="2"  class="text-center">Costs ($)</th>
                <th rowspan="2"  class="text-center">Status</th>
                <th rowspan="2"  class="text-center">Action</th>
            </tr>
            <tr>
                <th>Sender</th>
                <th>Receiver</th>
                <th class="text-right">Shipment</th>
                <th class="text-right">Sub-Total</th>
            </tr>
        </ng-template> -->

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.srNo }}</td>
                <td>{{ item.dated | date: "mediumDate" }}</td>
                <td>{{ item.transferNumber }}</td>
                <td>{{ item.warehouseFrom }}</td>
                <td>{{ item.warehouseTo }}</td>
                <td class="text-center">{{ item.totalQuantity }}</td>
                <td class="text-center">{{ item.shipping | currency }}</td>
                <td class="text-center">{{ item.grandTotal | currency }}</td>
                <!-- <td class="text-center" [styleClass]="getTransactionClass(item.transfersOutStatus
 )">{{ getTransactionStatus(item.transfersStatus) }}</td> -->
                <td class="text-center">
                    <p-chip
                        [label]="item.transfersInStatusString"
                        [styleClass]="
                            _abd.getTransfersInClass(item.transfersInStatus)
                        "
                    ></p-chip>
                </td>
                <td class="text-center">
                    <!-- <p-splitButton
                        label="Actions"
                        styleClass="p-button-text p-button-info"
                        (onClick)="actionButtonClicked(item)"
                        (onDropdownClick)="actionButtonClicked(item)"
                        [model]="actions"
                        [disabled]="doEnableActionButton(item.transfersInStatus)"
                    ></p-splitButton> -->
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="opActions.toggle($event)"
                        (click)="actionButtonClick(item)"
                        [disabled]="
                            doEnableActionButton(item.transfersInStatus)
                        "
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" style="text-align: center">
            <tr>
                <td colspan="10" style="text-align: center">
                    There is no data to show.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        [first]="first"
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
