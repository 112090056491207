import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-transfers-list',
  templateUrl: './transfers-list.component.html',
  styleUrls: ['./transfers-list.component.scss']
})
export class TransfersListComponent implements OnInit {
  page = 0
  rows = 10
  first=0
  totalRecords = 0

  lsearchText = ''
  csvcols = []

  dataList: any = ''
  selectedRow: any = ''

  actions: MenuItem[] = []

  _globals = GlobalService
  @ViewChild('opActions') opActions: OverlayPanel

  constructor(
    public _abd: AppBaseDataService,
    private _productService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _messageService: MessageService,
    private _appMain:AppMainComponent,
    private _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Transfer Out'

    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    // if(!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date().setDate(new Date().getDate() - 7)
    // if(!this._abd.dateRange[1]) this._abd.dateRange[1] = new Date()

    const locations = this._abd.initActLocations()

    if (this._abd.initActLocations()) {
      this._filterService.selectedLocation = locations[0]
    } else {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'Please select a location!'
      })
      return
    }
     this.getDataList()
  }

  ngAfterViewInit() {

    //-- restore page number **begin
    const obj = this._abd.getOptionsFromLocalStorage(this._globals.componentName, 'last-page')
    if (!obj && obj !== 0) return

    let lastpage = parseInt(obj)
    if (lastpage < 0) lastpage = 0
    if (lastpage > 0) {
      this.page = lastpage
      this.first = lastpage * this.rows
      this.getDataList()
    }
    else {
      this.page = 0
      this.first = 0
      this.getDataList()
    }
    //-- restore page number **end
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getDataList() {
    this._productService.isReject=false
    this._spinner.show()
    const from=this._filterService.selectedLocation ? this._filterService.selectedLocation.locationId : this._abd.actLocations[0]
    const to = this._filterService.selectedExtra1Location ? this._filterService.selectedExtra1Location.locationId : ''

    if(from == '' && to=='') {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'Please select a location!'
      })
      return
    }
    this._productService.getTransfersList(
      this._filterService.selectedLocation ? this._filterService.selectedLocation.locationId : '',
      this._filterService.selectedExtra1Location ? this._filterService.selectedExtra1Location.locationId : '',
      formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
      0,
      2, // transfersStatusCheck 1=all, 2=out, 3=in
      this.page,
      this.rows,
      this._filterService.searchText
    ).subscribe(res => {
      this._spinner.hide()
      if (res.success) {
        this.totalRecords = res.totalRecords
        this.dataList = res.data

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
        }
        // console.log('transfer-data-list', this.dataList)
      }
    })
  }

  callbackFuncFromFilter(event) {
    if (this._filterService.searchText == '') {
      // this._abd.resetFilters()
      this.getDataList()
      this.lsearchText = this._filterService.searchText
      return
    }
    if (this.lsearchText == this._filterService.searchText) return
    this.lsearchText = this._filterService.searchText
    this.getDataList()
    // this._productService.searchSingleTransferOrder(this._abd.searchText, 3).subscribe(res => {
    //   if (res.success) {
    //     this.dataList = []
    //     this.dataList.push(res.data)
    //     this.page = 0
    //     this.rows = 10
    //   }
    // })
  }

  filterButtonPressed() {
    this.page = 0
    this.getDataList()
  }

  onRowSelect(e) {
    this._productService.activeItem = e.data
    this._productService.isViewOnly = true
    this._productService.isEdit = false
    if(this._abd.whichRoute=='any') this._router.navigate(['app/transfer-out-form'])
    else this._router.navigate(['handheld/' + 'transfer-out-form'])
  }

  onRowUnselect(e) { }

  exportExcel() { }

  exportPdf() { }

  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  actionButtonClicked(item) {
    this._productService.activeItem = item
    let canEdit = false
    let canCancel = false

    if (item.transfersOutStatus !== 1) {
      canEdit = true
      canCancel = true
    }

    this.actions = [
      // { id: '1', label: 'New', escape: false, command: (event) => { }, disabled: true },
      { id: '2', label: 'Edit', escape: false, command: (event) => { this.optionEditClicked() }, disabled: canEdit },
      { id: '3', label: 'Cancel', command: (event) => { }, disabled: canCancel },
    ]
  }
  optionEditClicked() {
    const item = this._productService.activeItem

    if (!item || item.transfersOutStatus !== 1) {
      return
    }

    this._productService.isViewOnly = false
    this._productService.isNew = false
    this._productService.isEdit = true
    this.opActions.hide()
    // console.log("transfer-out-ACTIVE-ITEM", this._productService.activeItem)
    if(this._abd.whichRoute=='any') this._router.navigate(['app/transfer-out-form'])
    else this._router.navigate(['handheld/' + 'transfer-out-form'])
  }
  optionDeleteClicked() {  }

  onEditClick(item) {}

  locationChanged(e) {
    this.getDataList()
  }

  doEnableActionButton(item) {
    const showOn = [1]
    // try {
      if (showOn.find(f => f == item)) return false
      else return true
    // } catch { return true }
  }

  // _abd.transferStatusOut = [
  //   { id: 1, title: 'Draft' },
  //   { id: 2, title: 'Cancelled' },
  //   { id: 3, title: 'Posted' },
  //   { id: 4, title: 'Transferred' },
  //   { id: 5, title: 'Completed' },
  // ]


}
