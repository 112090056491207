<form [formGroup]="editform" (ngSubmit)="editform.valid && saveFn()" autocomplete="off">

    <div class="grid mt-2">
        <div class="col">
            <label class="control-label">
                First Name
                <span style="color: red; font-size: 1.25rem">*</span>
            </label>
            <app-text-input [formControl]="$any(editform.controls)['firstName']" [name]="'First Name'"
                [label]="''" (input)="_signup?.personalInfo.firstName = $event.target.value"></app-text-input>
        </div>
        <div class="col">
            <label class="control-label">
                Last Name
                <span style="color: red; font-size: 1.25rem">*</span>
            </label>
            <app-text-input [formControl]="$any(editform.controls)['lastName']" [name]="'Last Name'"
                [label]="''" (input)="_signup?.personalInfo.lastName = $event.target.value"></app-text-input>
        </div>
    </div>

    <div class="grid mt-2">
        <div class="col-6" *ngIf="!_signup.userAccountPendingForPayment">
            <label class="control-label">
                Email
                <span style="
                            color: red;
                            font-size: 1.25rem;
                            margin-bottom: 7px; ;
                        ">*</span>
            </label>
            <div class="form-group mt-2">
                <input #email id="personal-info-email" style="width: 100%" type="text" pInputText placeholder=""
                    name="email" autocomplete="new-password" [disabled]="_signup.accountVerified"
                    [ngClass]="{'shipping-address-disabled':_signup.accountVerified}"
                    [formControl]="$any(editform.controls)['email']"
                    (input)="_signup?.personalInfo.email = $event.target.value" (blur)="checkForDuplicateUser()" />
            </div>
            <div *ngIf="f.email.touched && !_signup.userAccountPendingForPayment">
                <div *ngIf="f.email.errors?.required && !_signup.userAccountPendingForPayment" class="invalid-feedback"
                    style="margin-top: 7px">
                    Please enter a valid email!
                </div>
                <div *ngIf="f.email.errors?.userexist && !_signup.userAccountPendingForPayment" class="invalid-feedback"
                    style="margin-top: 7px">
                    User already exist with this email, please choose another email!
                </div>
                <div *ngIf="f.email.errors?.emailStrength && !_signup.userAccountPendingForPayment"
                    class="invalid-feedback" style="margin-top: 7px">
                    Invalid email format!
                </div>
            </div>
        </div>
        <div class="col-6">
            <label class="control-label">
                Phone
                <span style="color: red; font-size: 1.25rem">*</span>
            </label>
            <div class="p-inputgroup" style="margin-top: 7px">
                <div class="p-inputgroup">
                    <app-phone-input style="width:100%" placeholder=""
                        [formControl]="$any(editform.controls)['phone']" (keyup)="inputPersonalInfoPhone($event)">
                    </app-phone-input>

                    <!-- (input)="
                            _signup?.personalInfo.phone = $event.target.value
                        " -->
                </div>
            </div>
        </div>
        <!-- <div class="col">
            <label class="control-label">
                Confirm Email
                <span style="color: red; font-size: 1.25rem">*</span>
            </label>
            <app-text-input [formControl]="$any(editform.controls)['confirmEmail']" [name]="'Confirm email'"
                [label]="''" [disabled]="_signup.accountVerified"
                [ngClass]="{'shipping-address-disabled':_signup.accountVerified}" (input)="
                    _signup?.personalInfo.confirmEmail = $event.target.value
                "></app-text-input>
            <div *ngIf="f.confirmEmail.touched && f.confirmEmail.invalid && !_signup.userAccountPendingForPayment"
                class="invalid-feedback">
                <div class="invalid-feedback" *ngIf="
                       ( f.confirmEmail.errors?.required ||
                        f.confirmEmail.errors?.mismatchEmail
                        ) && !_signup.userAccountPendingForPayment
                    " style="margin-top: 7px">
                    Mismatch email.
                </div>
            </div>
        </div> -->
    </div>

    <div class="grid mt-2" *ngIf="!_signup.userAccountPendingForPayment">
        <div class="col">
            <label class="control-label">
                Password
                <span style="color: red; font-size: 1.25rem">*</span>
          <img src="../../../../assets/images/info.svg" pTooltip="Password must contain a combination of uppercase & lowercase letters, numbers, and special characters !#$%&*." class="px-2" alt="Info">
                <!-- <i class="pi pi-info-circle px-1" pTooltip="Password must contain one Capita one small one spacial and one nomaric"></i> -->
            </label>
            <p-password id="password" [formControl]="editform.controls['password']" placeholder=""
                [toggleMask]="true" [feedback]="true" autocomplete="new-password"
                [style]="{ width: '100%', 'margin-top': '8px' }" [inputStyle]="{ width: '100%' }"
                [strongRegex]="passwordValidatorString"
                styleClass="w-full mb-5 p-password p-component p-inputwrapper p-input-icon-right"
                (input)="_signup?.personalInfo.password = $event.target.value">
                        <ng-template pTemplate="footer">
                    <p-divider></p-divider>
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>At least one special character</li>
                        <li>Between 8 and 16 characters</li>
                    </ul>
                </ng-template>
            </p-password>
            <div *ngIf="f.password.touched && f.password.invalid && !_signup.userAccountPendingForPayment"
                class="invalid-feedback">
                <div class="invalid-feedback" *ngIf="
                        f.password.errors?.required ||
                        f.password.errors?.passwordStrength
                    " style="margin-top: -20px">
                    Your password must have lower case, upper case, special
                    character, numeric characters and length between 8-16
                    characters.
                </div>
            </div>
        </div>
        <div class="col">
            <label class="control-label">
                Confirm Password
                <span style="color: red; font-size: 1.25rem">*</span>
            </label>
            <p-password id="confirmPassword" [formControl]="editform.controls['confirmPassword']"
                placeholder="" [toggleMask]="true" [feedback]="true"
                [style]="{ width: '100%', 'margin-top': '8px' }" [inputStyle]="{ width: '100%' }"
                styleClass="w-full mb-5 p-password p-component p-inputwrapper p-input-icon-right" (input)="
                    _signup?.personalInfo.confirmPassword = $event.target.value
                ">
                <!-- <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">Suggestions</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>At least one special character</li>
                            <li>Between 8 and 16 characters</li>
                        </ul>
                    </ng-template> -->
            </p-password>
            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid && !_signup.userAccountPendingForPayment"
                class="invalid-feedback">
                <div class="invalid-feedback" *ngIf="
                        f.confirmPassword.errors?.required ||
                        f.confirmPassword.errors?.conpasswordStrength
                    " style="margin-top: -20px">
                    Mismatch password.
                </div>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col">
           
        </div>
        <!-- <div class="col">
            <label class="control-label">
                <strong
                    >Phone
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <div class="p-inputgroup" style="margin-top: 7px">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"
                        ><img
                            src="assets/images/us-flag-icon.png"
                            style="width: 25px"
                    /></span>
                    <input
                        type="text"
                        class="input-mask text-600 font-bold"
                        [style]="{
                            border: 'none',
                            width: '100%',
                            'font-size': '14px'
                        }"
                        mask="(000) 000-0000"
                        [formControl]="$any(editform.controls)['phone']"
                        [name]="'Phone'"
                        (input)="
                            _signup?.personalInfo.phone = $event.target.value
                        "
                    />
                </div>
            </div>
        </div> -->
        <div class="col"></div>
    </div>
</form>
<!-- (input)="_signup.personalInfo.phone = $event.target.value" -->
<!-- *** terms & conditions *** -->
<br />
<div class="grid">
    <div class="col-fixed" style="width: 30px; margin-top: 5px">
        <p-checkbox [(ngModel)]="_signup.personalInfo.doAgree" binary="true" inputId="binary"></p-checkbox>
    </div>
    <div class="col">
        <p class="text-sm text-left text-500" style="margin: 0px">
            By checking this box you agree to {{_globals.whiteLabel.wlName}}’s
            <span class="text-sm text-blue-700 font-bold"><a (click)="showTerms()" style="cursor:pointer">Terms of Service</a></span>
            and acknowledge
            <span class="text-sm text-blue-700 font-bold"><a (click)="showEndUserAggreement()"
                    style="cursor:pointer">Privacy Policy</a></span>.
        </p>
        <p class="text-sm text-500" style="margin: 0px">
            Your account will be created and will be available for future login.
        </p>
    </div>
</div>

<div class="flex justify-content-between flex-wrap card-container purple-container mt-6">
    <div class="flex align-items-center justify-content-center">
        <p-button label="Change Plan" class="signup-back-button" icon="pi pi-arrow-left"
            (onClick)="backClicked()"></p-button>
    </div>
    <div class="flex align-items-center justify-content-center">
        <p-button label="Continue" icon="pi pi-arrow-right" iconPos="right"
            [disabled]="!editform.valid || !_signup.personalInfo.doAgree" (onClick)="saveFn()"></p-button>
    </div>
</div>

<!-- *** dialogs-to-show terms & conditions *** -->
<p-dialog [(visible)]="displayTerms" [modal]="true" [maximizable]="true" [style]="{ width: '75vw' }">
    <app-connect360-terms-and-conditions  *ngIf="
            displayWhat === 'end-user-agreement'"></app-connect360-terms-and-conditions>
    <!-- <app-connect-cbd-terms-and-conditions
        *ngIf="
            displayWhat === 'terms-and-conditions' &&
            displayWhos == 'connect-cbd'
        "
    ></app-connect-cbd-terms-and-conditions> -->

    <app-connect360-end-user-agreement *ngIf="
            displayWhat === 'terms-and-conditions'"></app-connect360-end-user-agreement>
    <!-- <app-connect-cbd-return-and-refund
        *ngIf="
            displayWhat === 'end-user-agreement' && displayWhos == 'connect-cbd'
        "
    ></app-connect-cbd-return-and-refund> -->


</p-dialog>