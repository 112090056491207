<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <!-- <div class="conn-grid" style="margin-top: 1px"> -->
        <div></div>
        <button
            pButton
            pRipple
            type="button"
            label="Edit"
            class="p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-pencil"
            iconPos="right"
            (click)="editButtonClicked()"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Delete"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-trash"
            iconPos="right"
            (click)="optionDeleteClicked()"
        ></button>
        <!-- </div> -->
    </ng-template>
</p-overlayPanel>

<div class="grid">
    <div class="mb-12 pt-2 mb-5">
        <h1 class="fw-bolder wizard-title-inner d-flex align-items-center text-dark mx-2 wizard-title-active">
            Location Setup
            <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                ngbTooltip="Billing is issued based on your selected account type"
            ></i>
        </h1>

        <div class="fs-6 mx-2 text-muted">
            Manage different locations of your business
        </div>
    </div>
</div>

<div class="grid p-3 mt-4 mb-12" id="skip-warning" style="display: none">
    <div class="col-1 align-content-evenly">
        <span id="skip-icon" class="pi pi-info"></span>
    </div>
    <div class="col-9">
        <div class="grid">
            <div class="col-12">
                <h3 class="fw-bolder d-flex align-items-center text-dark mx-2">
                    This step is optional for now!
                </h3>
            </div>
            <div class="col-12">
                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Don't worry! you would be able to add the location(s) to
                    your business from the dashboard settings.
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="flex flex-nowrap card-container mb-6 justify-content-end"
    *ngIf="!disableAdd"
>
    <div class="flex align-items-center border-round mx-2">
        <button
            class="alternate-button light-button"
            pButton
            type="button"
            [label]="addLocationLabel"
            icon="pi pi-plus"
            (click)="addLocation()"
            iconPos="left"
            [style]="{
                'margin-top': '30px',
                'min-width': '100%',
                width: '150px',
                height: '45px'
            }"
        ></button>
    </div>
</div>

<div
    class="fadeindown animation-duration-150 flex flex-nowrap card-container mb-12 justify-content-end"
>
    <app-locations-list (locationUpdated)="locationDeleted($event)" [showReportTopFilter]="false" style="width: 100%"></app-locations-list>

</div>

<p-dialog
    *ngIf="_businessSetupService.isEditLocation"
    header="New Location"
    [modal]="true"
    [(visible)]="_businessSetupService.isEditLocation"
    [style]="{ width: '50vw' }"
>
    <app-location-new-edit
        [isNew]="isNew"
        (locationUpdated)="locationDeleted($event)"
        [isViewOnly]="isViewOnly"
    ></app-location-new-edit>
</p-dialog>
