import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LoyaltyConfig } from 'src/app/_models/reward-program/loyalty-configuration';
import { SaveRegularReward } from 'src/app/_models/reward-program/perk-model';
import { LoyaltyConfigType } from 'src/app/_models/reward-program/reward-program-response';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';



@Component({
  selector: 'app-regular-reward-configuration',
  templateUrl: './regular-reward-configuration.component.html',
  styleUrls: ['./regular-reward-configuration.component.scss']
})
export class RegularRewardConfigurationComponent implements OnInit {


    regularForm!: FormGroup;
    dataList: any[] = [];
    @Input() regularRewardProgramObj = <LoyaltyConfigType>{}
    @Output() configurationSaved = new EventEmitter();
    constructor(
        private fb: FormBuilder,
        public _rewardProgramService: RewardProgramService,
        public _abd: AppBaseDataService,
        private _messageService: MessageService) {

    }

    ngOnInit(): void {

        this.initRegularForm();
    }

    get f() { return this.regularForm.controls; }

    initRegularForm() {
        this.regularForm = this.fb.group({
            loyaltyPoints: ['', Validators.required],
            pointRedemptionThreshold: ['', Validators.required],
        });

        this.getRegularLoyaltyConfiguration();
    }

    getRegularLoyaltyConfiguration(){
        try {
            // console.log(this.regularRewardProgramObj)
            this.f.loyaltyPoints.setValue(this.regularRewardProgramObj.eachPointDiscount);
            this.f.pointRedemptionThreshold.setValue(this.regularRewardProgramObj.minRedeemPPoints);

        } catch (error) {

        }
    }

    saveForm() {
        try {
            if (this.regularForm.invalid) {
                // Got focus to the error field
                let invalidFields = [].slice.call(
                    document.getElementsByClassName('ng-invalid')
                );
                invalidFields[1].focus();
            }
            let obj = <SaveRegularReward>{}

            obj.eachPointDiscount = this.f.loyaltyPoints.value;
            obj.minRedeemPPoints = this.f.pointRedemptionThreshold.value;
            this._abd.showSpinner()
            this._rewardProgramService.saveRegularReward(obj).subscribe((r)=>{
                if (r.success){
                    this.configurationSaved.emit(true);
                    this._messageService.add({ severity: 'success', summary: 'Configuration has been saved' })
                }
            }, error=>{
                this._messageService.add({ severity: 'error', summary: 'Unable to save configuration ' })
            })
        } catch (error) {

        }
    }



}
