import { CurrencyPipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable } from 'rxjs';
import { GroupBy } from 'src/app/_models/reports/reports';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-department-sales-report',
  templateUrl: './department-sales-report.component.html',
  styleUrls: ['./department-sales-report.component.scss']
})
export class DepartmentSalesReportComponent implements OnInit {
  _globals = GlobalService

  colheader = this._abd.reports.filter(x => x.componentName == 'Department Sales Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Department Sales Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Department Sales Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Department Sales Report')[0].summaryflds;


  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  departmentIds: string[] = [];
  groupby: string = 'Location';
  printOnce: boolean = true;

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    if (!this._filterService.selectedProductSellTypeFilter)
      this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }

    this._abd.groupby = [
      { id: "Department", title: "Department" },
      { id: "Location", title: "Store" },
      { id: "Date", title: "Date" }
    ]

    if (!this._filterService.selectedGroup)
      this._filterService.selectedGroup = this._abd.groupby[0]

    this._globals.componentName = 'Department Sales Report'

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    if (!this._filterService.selectedLocation)
      this._filterService.selectedLocation = this._abd.locations;
    // this._abd.selectedDepartment = this._abd.actDepartments[0];
    // this._abd.groupby$.subscribe(x => this._abd.selectedGroup = x[0]);
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    //
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDepartmentList() {
    let self = this;
    this.departmentIds = [];
    let selDepartment = this._filterService.selectedDepartment;
    selDepartment = (typeof selDepartment != 'undefined' && selDepartment instanceof Array) ? selDepartment : [selDepartment];
    try {
      selDepartment.map(value => {

        if (value != "" && value.id != undefined){
            self.departmentIds.push(value.id)
        }
      })
    }
    catch {

    }
  }

  getGroupbyList() {
    try {
      this.groupby = this._filterService.selectedGroup.id;
    }
    catch { }
  }


  getDataList() {
    let x95 = this._filterService.selectedProductSellTypeFilter
    if (!x95) x95 = 'All'
    else x95 = x95.Value

    this.getLocationsList();
    this.getDepartmentList();
    this.getGroupbyList();

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'DepartmentReport',
      Parameter: {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds ? this.locationIds : 'All',
        "DepartmentIds": this.departmentIds ? this.departmentIds : 'All',
        "GroupBy": this.groupby,
        'OrderPlacement': x95
      }
    }

    const url = this._abd.reports.filter(x => x.componentName == 'Department Sales Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this._abd.hideSpinner();
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Department Sales Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Department Sales Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("totalinvoices")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else if (col.includes("taxRete")) {
        return this.dataList.reduce((sum, item) => item[col], 0).toFixed(2) + " %";
      }
      else {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
