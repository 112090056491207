<div class="conn-card">
    <app-report-top-filter-bar *ngIf="1==1"
        [paraReportName]="'daily-money-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
    ></app-report-top-filter-bar>

    <p-card class="my-4" *ngIf="dataList.length>0">
        <div class="grid justify-content-around">
            <div class="col-3" style="text-align: center;">
                <h5 class="daily-money-report-summary-label">Total Invoices</h5>
                <h4>{{dataList[0].totalInvoices | number}}</h4>
            </div>
            <div class="col-3" style="text-align: center;">
                <h5 class="daily-money-report-summary-label">Avg. Ticket</h5>
                <h4>{{dataList[0].avgTicket | currency}}</h4>
            </div>
            <div class="col-3" style="text-align: center;">
                <h5 class="daily-money-report-summary-label">Total Sales</h5>
                <h4>{{dataList[0].totalGrossSales | currency}}</h4>
            </div>
        </div>
    </p-card>

    <div *ngIf="dataList.length>0">
        <p-card class="my-4" *ngFor="let item of dataListDisplay">
            <div class="p-card-header ng-star-inserted" id="header-daily-sales-report">
                {{item.heading}}
            </div>
            <ng-template pTemplate="content">
                <div class="grid" *ngFor="let x of item.data">
                    <div class="col-6 pl-6" id="subheading">{{x.name}}</div>
                    <div class="flex col-6 justify-content-center" id="subheading-value">{{x.value}}</div>
                </div>
            </ng-template>
        </p-card>
    </div>


    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
