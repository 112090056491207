import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
    selector: 'app-customer-info-change',
    templateUrl: './customer-info-change.component.html',
    styleUrls: ['./customer-info-change.component.scss'],
})
export class CustomerInfoChangeComponent implements OnInit {
    _globals = GlobalService;
    @Input() isNew: boolean = true;
    @Output() refreshDataEmitter = new EventEmitter<any>();

    editform: FormGroup;
    usaStates = UsStates;
    showCityWait = false;
    selectedState: any = '';
    selectedCust: any = '';

    constructor(
        public _customerService: CustomersService,
        private _fb: FormBuilder,
        public _abd: AppBaseDataService,
        private _spinner: NgxSpinnerService,
        private _connMessageService: ConnMessageService
    ) {}

    ngOnInit(): void {
        this.selectedState = this.usaStates.find(
            (f) => f.name == this.selectedCust.state
        );
        // this.selectedState = this.usaStates.find(
        //     (f) => f.abbreviation == this.selectedCust.state
        // );
        //console.log('selected-cust===>', this.selectedCust)

        this.initForm();
    }

    initForm() {
        if (this._customerService.activeItem == null) {
            try {
                if (this.selectedCust.postalCode == ' ')
                    this.selectedCust.postalCode = '';
                this.editform = this._fb.group({
                    firstName: ['', Validators.required],
                    lastName: ['', Validators.required],
                    phone: ['', this._abd.phoneValidator()],
                    email: [''
                        ,
                        [Validators.required, this.emailFormatValidator()],
                    ],
                    postalCode: ['', Validators.required],
                    city: ['', Validators.required],
                });
            } catch {}
        } else {
            try {
                this._abd.showSpinner();

                this._customerService
                    .getSingleCustomer(this._customerService.activeItem.custID)
                    .subscribe((res) => {
                        if (res.success) {
                            this.selectedCust = res.data[0];
                            this.selectedState = this.usaStates.find(
                                (f) => f.name == this.selectedCust.state
                            );
                            // this.selectedState = this.usaStates.find(
                            //     (f) => f.abbreviation == this.selectedCust.state
                            // );
                            //console.log('selected-cust===>', this.selectedCust)
                            // this.initForm()

                            if (this.selectedCust.postalCode == ' ')
                                this.selectedCust.postalCode = '';
                            this.editform = this._fb.group({
                                firstName: [
                                    this.selectedCust.firstName,
                                    Validators.required,
                                ],
                                lastName: [
                                    this.selectedCust.lastName,
                                    Validators.required,
                                ],
                                phone: [
                                    this.selectedCust.phone,
                                    this._abd.phoneValidator(),
                                ],
                                email: [
                                    this.selectedCust.email,
                                    [
                                        Validators.required,
                                        this.emailFormatValidator(),
                                    ],
                                ],
                                postalCode: [
                                    this.selectedCust.postalCode,
                                    Validators.required,
                                ],
                                city: [
                                    this.selectedCust.city,
                                    Validators.required,
                                ],
                            });
                            this._abd.hideSpinner();
                        } else {
                            this._abd.hideSpinner();
                        }
                    });
            } catch {
                this._abd.hideSpinner();
            }
        }
    }
    // this._customerService.showNewEdit = true}
    emailFormatValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) return null;

            const pvalid = value.match(this._globals.emailValidatorString);
            return pvalid ? null : { emailStrength: true };
        };
    }
    saveFn() {
        // this.selectedCust.firstName = this.editform.get('firstName').value
        // this.selectedCust.lastName = this.editform.get('lastName').value
        // this.selectedCust.phone = this.editform.get('phone').value
        // this.selectedCust.email = this.editform.get('email').value
        // this.selectedCust.postalCode = this.editform.get('postalCode').value
        // this.selectedCust.state = this.selectedState.abbreviation

        this._abd.showSpinner();
        if (this.isNew === false) {
            const updateobj = {
                firstName: this.editform.get('firstName').value,
                lastName: this.editform.get('lastName').value,
                phone: this.editform.get('phone').value.replace(/\D/g, ''),
                email: this.editform.get('email').value,
                postalCode: this.editform.get('postalCode').value,
                // state: this.selectedState.abbreviation,
                state: this.selectedState.name,
                city: this.editform.get('city').value,
                custID: this.selectedCust.custID,
                userID: this.selectedCust.userID,
                username: this.selectedCust.username,
            };

            this._customerService
                .updateCustomer(
                    updateobj,
                    this._customerService.activeItem.custID
                )
                .subscribe((res) => {
                    if (res.success) {
                        this._connMessageService.showToastMessage(
                            `Customer updated!`,
                            'success'
                        );
                        this._abd.hideSpinner();
                        this.refreshDataEmitter.emit();
                        this._customerService.showNewEdit = false;
                        this.editform.reset();
                    } else {
                        this._abd.hideSpinner();
                    }
                });
        } else {
            const saveobj = {
                firstName: this.editform.get('firstName').value,
                lastName: this.editform.get('lastName').value,
                phone: this.editform.get('phone').value.replace(/\D/g, ''),
                email: this.editform.get('email').value,
                postalCode: this.editform.get('postalCode').value,
                state: this.selectedState.name,
                city: this.editform.get('city').value,
            };
            this._customerService.createCustomer(saveobj).subscribe((res) => {
                if (res.success) {
                    this._connMessageService.showToastMessage(
                        `Customer saved!`,
                        'success'
                    );
                    this._abd.hideSpinner();
                    this.refreshDataEmitter.emit();
                    this._customerService.showNewNewEdit = false;
                    this.editform.reset();
                } else {
                    this._abd.hideSpinner();
                }
            });
        }
    }

    zipInputEvent(value: string) {
        value = value.trim();
        if (!value || value.length !== 5) return;
        this.showCityWait = true;
        this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
            this.showCityWait = false;
            if (res.success && res.data.city != undefined) {
                this.selectedState = this.usaStates.find(
                    (s) => s.abbreviation == res.data.state
                );
                this.editform.patchValue({ city: res.data.city });
            } else {
                this.editform.patchValue({ city: 'Invalid zip code' });
            }
        });
    }

    cancelButtonClick() {
        this._customerService.showNewEdit = false;
        this._customerService.showNewNewEdit = false;
    }
}
