<div class="grid">
    <div class="mb-12 pt-2">
        <h1 class="fw-bolder wizard-title-inner d-flex align-items-center text-dark mx-2 wizard-title-active">
            General Info
        </h1>

        <div class="fs-6 mx-2 text-muted">
            Set some generic info about your business.
        </div>
    </div>
</div>

<br />
<br />
<div class="grid mb-2 mx-2">
    <label
        class="text-dark d-block fs-4 mb-3"
        style="font-size: 1.5rem; font-weight: 600; margin-top: 10px"
        >Business Structure
    </label>
</div>
<div class="flex" *ngIf="_globals.userInfo">
    <div
        class="flex-1 flex align-items-center justify-content-center m-2"
        *ngFor="let item of _abd.businessTypes"
    >
        <button
            type="button"
            [ngClass]="
                _businessSetup.activeItem.businessType == item.id
                    ? 'n-button-active'
                    : 'n-button'
            "
            (click)="selectBusinessType(item)"
        >
            <p class="n-head-label font-bold">{{ item.title }}</p>
            <p
                [ngClass]="
                    _businessSetup.activeItem.businessType == item.id
                        ? 'n-desc-label-active'
                        : 'n-desc-label'
                "
            >
                {{ item.desc }}
            </p>
        </button>
    </div>
</div>
<br />
<br />
<div class="flex">
    <div class="flex-1 flex font-bold text-gray-900 m-2">Time Zone</div>
</div>
<div class="flex">
    <div class="flex-1 flex font-bold text-gray-900 m-2">
        <p-dropdown
            [(ngModel)]="selectedTimeZone"
            [style]="{
                height: '3rem',
                width: '40rem',
                'min-width': '120px'
            }"
            class="p-dropdown-1"
            [options]="usTimeZones"
            optionLabel="name"
            placeholder=" "
            (onChange)="selectedTimezoneChange()"
        ></p-dropdown>
    </div>
</div>

<!-- <div [formGroup]="form">
    <div class="grid mb-2 mx-2">
        <label class="text-dark d-block fs-4"> Business Structure </label>
    </div>
    <div class="grid">
        <div
            class="flex justify-content-between flex-nowrap card-container mb-12"
        >
            <div
                class="flex align-items-center border-round mx-2"
                style="min-width: 150px; min-height: 100px"
            >
                <input
                    formControlName="accountType"
                    name="accountType"
                    type="radio"
                    class="btn-check"
                    id="sole"
                    value="Sole Proprietorship"
                    (change)="updateBusinessSetup()"
                />
                <label
                    class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center"
                    for="sole"
                >
                    <span class="d-block fw-bold text-center">
                        <span class="text-dark fw-bolder d-block fs-4 mb-2"
                            >Sole Proprietorship</span
                        >
                        <span class="text-gray-400 fw-bold fs-6">
                            I own an unincorporated business by myself
                        </span>
                    </span>
                </label>
            </div>

            <div
                class="flex align-items-center border-round mx-2"
                style="min-width: 150px; min-height: 100px"
            >
                <input
                    formControlName="accountType"
                    name="accountType"
                    type="radio"
                    class="btn-check"
                    id="partnership"
                    value="Partnership"
                    (change)="updateBusinessSetup()"
                />
                <label
                    class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center"
                    for="partnership"
                >
                    <span class="d-block fw-bold text-center">
                        <span class="text-dark fw-bolder d-block fs-4 mb-2"
                            >Partnership</span
                        >
                        <span class="text-gray-400 fw-bold fs-6">
                            My business is owned by a two or more people
                        </span>
                    </span>
                </label>
            </div>

            <div
                class="flex align-items-center border-round mx-2"
                style="min-width: 150px; min-height: 100px"
            >
                <input
                    formControlName="accountType"
                    name="accountType"
                    type="radio"
                    class="btn-check"
                    id="corporation"
                    value="Corporation"
                    (change)="updateBusinessSetup()"
                />
                <label
                    class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center"
                    for="corporation"
                >
                    <span class="d-block fw-bold text-center">
                        <span class="text-dark fw-bolder d-block fs-4 mb-2"
                            >Corporation</span
                        >
                        <span class="text-gray-400 fw-bold fs-6">
                            My business has different stakeholders
                        </span>
                    </span>
                </label>
            </div>
        </div>
    </div>

    <div class="grid">
        <div
            class="flex justify-content-between flex-nowrap card-container mb-12"
        >
            <div class="col-10">
                <label class="text-dark d-block fs-4 mb-2"> Time Zone </label>
                <p-dropdown
                    formControlName="timeZone"
                    [style]="{
                        height: '3rem',
                        width: '40rem',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    [options]="usTimeZones"
                    optionLabel="name"
                    placeholder=" "
                    (onChange)="updateBusinessSetup()"
                ></p-dropdown>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="grid"> -->
<!-- <div class="flex flex-nowrap justify-content-between card-container ">
            <div class="flex align-items-center justify-content-center" *ngIf="showBackButton">
                <button
                    pButton
                    type="button"
                    label="Back"
                    icon="pi pi-arrow-left"
                    (click)="continue()"
                    iconPos="left"
                    [style]="{ 'margin-top': '30px', 'min-width': '100%'}"
                ></button>
            </div>
            <div class="flex align-items-center justify-content-center" *ngIf="showContinueButton">
                <button
                    pButton
                    type="button"
                    label="Continue"
                    icon="pi pi-arrow-right"
                    (click)="continue()"
                    iconPos="right"
                    [style]="{ 'margin-top': '30px', 'min-width': '100%'}"
                ></button>
            </div>
        </div> -->
<!-- </div> -->
