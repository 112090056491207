<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <!-- <label class="control-label"
                    ><strong
                        >Tax Rate (%)
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
                <br />
                <form
                    [formGroup]="taxform"
                    (ngSubmit)="taxform.valid && saveFn()"
                    autocomplete="off"
                >
                    <div class="mb-4 hidden">
                        <p-inputSwitch [formControl]="$any(taxform.controls)['enableTaxForm']" inputId="rememberme2"></p-inputSwitch> 
                        <label class="control-label" for="rememberme2"><strong> Enable</strong></label>
                    </div>
                    <br/> 
                    

                    <label class="control-label"
                    ><strong>Tax Rate (%) </strong></label>
                    <app-text-input
                        [formControl]="$any(taxform.controls)['taxAmount']"
                        [label]="'Tax Rate (%)'"
                        [name]="'Tax Rate (%)'"
                        (input)="8.25"
                        [description]=""
                    ></app-text-input>
                    <div
                        *ngIf="f.taxAmount.touched && f.taxAmount.invalid"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.taxAmount.errors.pattern">Value should be number </div>
                    </div>
    
                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!taxform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2" 
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
    