<p-overlayPanel #op [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }">
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button pButton pRipple type="button" label="Edit" class="p-button-text" [style]="{ width: '100%' }"
                icon="pi pi-pencil" iconPos="right" (click)="editButtonClicked()"></button>
            <button pButton pRipple type="button" label="Delete" [style]="{ width: '100%' }"
                class="p-button-danger p-button-text" icon="pi pi-trash" iconPos="right"
                (click)="optionDeleteClicked()"></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div class="fadeindown animation-duration-250 flex">
    <div class="conn-card flex align-content-between w-full"
        *ngIf="_globals.userInfo.businessSetup[0].enableLoyaltyReward">
        <div class="flex flex-wrap justify-content-between flex-column">
            <div class="font-bold text-2xl page-heading-color">
                Type of Reward Program
            </div>
            <div class="text-lg text-bluegray-300 mt-1">
                Select the type of reward program you want for your business
            </div>
        </div>
        <div class="flex" *ngIf="loyaltyObj.active">
            <div class="flex flex-row justify-content-start  w-6 ">
                <div
                    *ngFor="let program of rewardTypeList"
                    [ngClass]="{'active-option': (selectedValue == program.title)}"
                    (click)="changeProgram(program.title,currentTab)"
                    class="flex align-items-center w-6 reward-options  justify-content-start  h-4rem   border-round m-2">
                    <p-radioButton
                        name="groupname"
                        [value]="program.title"
                        class="px-3"
                        [(ngModel)]="selectedValue">

                    </p-radioButton>
                    {{program.title}}
                </div>

            </div>
        </div>
        <div class="flex w-full dotted-border-bottom my-3" ></div>
        <span *ngIf="loyaltyObj.active">
            <app-regular-reward-configuration
                (configurationSaved)="getLoyaltyConfigurationV1()"
                [regularRewardProgramObj]="regularRewardProgramObj"
                *ngIf="currentTab == 'Regular'">
            </app-regular-reward-configuration>
            <app-tiered-reward-configuration
                *ngIf="currentTab == 'Tiered'">
            </app-tiered-reward-configuration>
        </span>
        <div class="" *ngIf="!loyaltyObj.active && isFirstTimeLoading">
            <div class="flex   overflow-hidden">
                <div
                    class="flex-grow-1 flex align-items-center justify-content-center text-lg  m-2 px-5 py-3">
                    <i class="pi pi-info-circle"  style="font-size: 2.5rem; margin-right: 20px; color:var(--brandColor)"></i>
                    Loyalty program configuration is not enabled
                </div>

            </div>
        </div>
    </div>
    <div class="conn-card flex align-content-between w-full"
        *ngIf="!_globals.userInfo.businessSetup[0].enableLoyaltyReward">
        <div class="flex card-container blue-container overflow-hidden">

            <div class="flex-grow-1 flex align-items-center justify-content-center text-3xl m-2 px-5 py-3
                  border-round">
                <i _ngcontent-ghs-c91="" class="pi pi-exclamation-triangle mx-2 text-3xl"></i> No access found
            </div>

        </div>
    </div>
</div>


