<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'gross-profit-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>
    <br />
    <div class="grid summary">
        <div class="col">
            <div class="conn-card" style="background:#f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                                Net Sales
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{calculateSummaryTotal("netSales") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="conn-card" style="background:#f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                                Cost of Goods
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{calculateSummaryTotal("netCost") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="conn-card" style="background:var(--brandColor);color:white">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                                Profit
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{calculateSummaryTotal("grossProfit") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="conn-card" style="background:#f5f8fa">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                                Profit Margin
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{calculateSummaryTotal("grossMargin") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td style="text-align: center">
                    <span>{{ product.date | date:'MM/dd/yyyy'}}</span>
                 </td>
                 <td style="text-align: center">
                    <span>{{ product.isOnline }}</span>
                 </td>
                 <td style="text-align: center">
                    <span>{{ product.locationName }}</span>
                 </td>
                <td style="text-align: center">
                    <span>{{ product.netSales | currency}}</span>
                </td>
                <td style="text-align: center">
                    <span>{{ product.netCost | currency}}</span>
                </td>
                <td style="text-align: center">
                    <span>{{ product.grossProfit | currency}}</span>
                </td>
                <td style="text-align: center">
                    <span>{{ (product.grossProfit)/(product.netSales>0?product.netSales:100)
                        |percent : '1.2'}}</span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>

                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
