import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { DataViewModule } from 'primeng/dataview';
import { BaseService } from 'src/app/_services/base.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { environment } from 'src/environments/environment';
import { ReportsService } from 'src/app/_services/reports.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterServiceService } from 'src/app/_services/filter-service.service';


export interface ReturnReport {
  locationName? : string;
  userName? : string;
  invoiceCount? : number;
  cash? : number;
  credirCard? : number;
  itemsCount? : number;
  tax? : number;
  grandTotal? : number;
}
@Component({
  selector: 'app-return-report',
  templateUrl: './return-report.component.html',
  styleUrls: ['./return-report.component.scss']
})


export class ReturnReportComponent implements OnInit {
  _globals = GlobalService

  dataList: any = ''

  colheader = this._abd.reports.filter(x => x.componentName == 'Return Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Return Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Return Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Return Report')[0].summaryflds;

  csvcols = []
  exportColumns: any = ''
  locationIds: string[] = [];

  constructor(
    private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe:CurrencyPipe,
    private _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    // this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3,Value:'All' }
    this._globals.componentName = 'Return Report'

    // this._abd.initDates()
    this._abd.selectedLocation=JSON.parse(JSON.stringify(this._abd.locations))
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let sel = this._filterService.selectedLocation;
    if(!sel || sel.length<1) {
      this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations))
      sel = this._filterService.selectedLocation;
    }
    for(let i=0; i<sel.length; i++) {
      this.locationIds.push(sel[i].locationId)
    }
  }

  getDataList() {

    this.getLocationsList();

    let op=this._filterService.selectedProductSellTypeFilter?.Value
    if(!op) op='All'

    const data = {
      "ReportName": 'ReturnReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'OrderPlacement' : op
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner()
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data
      this._abd.hideSpinner()
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportExcel(data,this.summaryHeader[0], this.colflds, 'Return Report')
  }

  exportPdf() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportPdf(data, this.summaryHeader, this.colflds, 'Return Report', true)
  }

  /*
      The following code seperates the tender data from the API data and passes it to the PDF generator
  */
  refineCCDetails(dataInput: any[]){
    let dummyData: Array<ReturnReport> =  []
    dataInput.filter(user => {
      user.cashierReturns.map(cc => {
        cc.locationName = user.locationName;
        dummyData.push(cc)
      });     
    });
    return dummyData;
  }

  calculateSummaryTotal(col: string){
    // 
    let data = this.refineCCDetails(this.dataList);

    ;
    let total = "0";
    if(col.includes("Count") || col.includes("Item") || col.includes("item") || col.toLocaleLowerCase().includes("totalinvoices"))
    {
      total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
    }
    else if (col.includes("taxRete"))
    {
      total = this.dataList.reduce((sum, item) => item[col], 0).toFixed(2) + " %";
    }
    else{
    total = this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD',"$");
    }
    return total;
  }

}
