<form
#formDirective="ngForm"
    [formGroup]="editform"
    (ngSubmit)="editform.valid && saveFn()"
    autocomplete="off"
>
    <div class="box">
        <div class="one" style="margin-right: 10px">
            <label class="control-label">Location Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
            <app-text-input
                formControlName="locationName"
                [label]="'Location Name'"
                [name]="'Location Name'"
                (input)="
                    _locationService.activeItem.locationName =
                        $event.target.value
                "
            ></app-text-input>
        </div>
        <div class="two">
            <div style="margin-top: 0px" [style]="{ width: '100%' }">
                <label class="control-label">Phone
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </label>
                <app-phone-input
                    [formControl]="$any(editform.controls)['phone']"
                    (input)="
                        _locationService.activeItem.phone = $event.target.value
                    "
                >
                </app-phone-input>
            </div>
        </div>
    </div>

    <!-- second row -->
  <!--  <div class="flex" style="margin-top: 20px">
        <div class="flex">
            <p-inputSwitch formControlName="manageInventory"></p-inputSwitch>
            <label
                class="control-label"
                style="margin-top: -5px; margin-left: 10px">Manage Inventory on this Store
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
        </div>
        <div class="flex" style="margin-left: 30px">
            <p-inputSwitch formControlName="allowShipping"></p-inputSwitch>
            <label
                class="control-label"
                style="margin-top: -5px; margin-left: 10px">Allow Shipping
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
        </div>
        <div class="flex" style="margin-left: 30px">
            <p-inputSwitch formControlName="acceptOnlineOrders"></p-inputSwitch>
            <label
                class="control-label"
                style="margin-top: -5px; margin-left: 10px">Accept Online Orders
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
        </div>
    </div>
-->
    <!-- 3rd row -->
    <div
        class="row"
        style="margin-top: 40px; margin-left: -5px; margin-right: -5px"
    >
        <div class="col-12">
            <label class="control-label">Address
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
            <app-text-input
                formControlName="address"
                [label]="'Address'"
                [name]="'address'"
                (input)="
                    _locationService.activeItem.address = $event.target.value
                "
            ></app-text-input>
        </div>
    </div>

    <!-- 4th row -->
    <div class="conn-grid-3x" style="margin-top: 40px">
        <div>
            <label class="control-label">Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
            <br />
            <p-inputMask
                formControlName="zip"
                mask="99999"
                placeholder="Zip Code"
                (onInput)="zipInputEvent($event.target.value)"
                [style]="{ 'margin-top': '7px', width: '100%' }"
            ></p-inputMask>
            <div
                *ngIf="f.zip.touched && f.zip.invalid"
                class="invalid-feedback"
            >
                <div *ngIf="f.zip.errors.required">zip code is required.</div>
            </div>
        </div>
        <div>
            <label class="control-label">City
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
            <br />
            <span class="p-input-icon-right" [style]="{ width: '100%' }">
                <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                <app-text-input
                    formControlName="city"
                    [label]="'City'"
                    [name]="'city'"
                    (input)="
                        _locationService.activeItem.city = $event.target.value
                    "
                ></app-text-input>
            </span>
        </div>
        <div>
            <label class="control-label">State
                    <span style="color: red; font-size: 1.25rem">*</span>
                </label>
            <br />
            <p-dropdown
                [style]="{
                    width: '100%',
                    'min-width': '200px',
                    'margin-top': '7px'
                }"
                class="p-dropdown-1"
                appendTo="body"
                [options]="usaStates"
                [(ngModel)]="_locationService.activeItem.selectedState"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="isViewOnly"
            ></p-dropdown>
        </div>
    </div>
    <br />
    <br />
    <p-divider align="right">
        <button
            pButton
            pRipple
            type="submit"
            label="Save"
            [disabled]="!editform.valid || showCityWait"
        ></button>
        <button
            [style]="{ 'margin-left': '10px' }"
            pButton
            pRipple
            type="button"
            label="Cancel"
            class="p-button-outlined p-button-danger"
            (click)="cancelButtonClick()"
        ></button>
    </p-divider>
</form>
