import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
  selector: 'app-subscription-payment-successful',
  templateUrl: './subscription-payment-successful.component.html',
  styleUrls: ['./subscription-payment-successful.component.scss']
})
export class SubscriptionPaymentSuccessfulComponent implements OnInit {

    showModal : boolean = true;
    isRealAccountAccess: boolean = false;
    timeLeft: number = 5;
    _globals = GlobalService
    constructor(
        public _abd: AppBaseDataService,
        public _signup: SignupService,
        private _router: Router,
        private _connMessageService: ConnMessageService,
        private _spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        // verify valid account
        localStorage.clear();
        let paymentSuccessfully: any = sessionStorage.getItem('payment-successfully');
        if (paymentSuccessfully != undefined ){
            this.isRealAccountAccess = (paymentSuccessfully == "1" ? true: false)
           // sessionStorage.clear()

            sessionStorage.setItem('payment-successfully', '1');

        }

        if (!this.isRealAccountAccess){
            localStorage.clear();
            sessionStorage.clear()
            this.startTimer();
        }
    }

    startTimer() {
        setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this._router.navigate(['/pages/signin'])
            }
        }, 1000);
    }


    login() {


       // this._router.navigate(['/pages/signin'])
        let websiteDomain: string = "";
        websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
        var n = websiteDomain.lastIndexOf('/');
        var result = websiteDomain.substring(n + 1);

        if (result == "") {
            websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

        }
        window.location.href = websiteDomain +"/pages/signin"

    }

}
