import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomersService } from 'src/app/_services/customers.service';
import { UsStates } from 'src/app/_data/us-states'
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-edit-customer',
  templateUrl: './new-edit-customer.component.html',
  styleUrls: ['./new-edit-customer.component.scss']
})
export class NewEditCustomerComponent implements OnInit {
  @Input() isNew: boolean = false
  editform: FormGroup
  usaStates = UsStates
  showCityWait=false

  constructor(
    public _abd: AppBaseDataService,
    private _fb: FormBuilder,
    public _customerService: CustomersService,
    private _confirmationService: ConfirmationService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this._customerService.selectedState=this.usaStates[0]
    this.initForm()
  }

  ngAfterContentInit() {
    this._customerService.activeItem.firstName="dfg-poi"
  }

  initForm() {
    this.editform = this._fb.group({
      firstName:[this._customerService.activeItem.firstName, Validators.required],
      lastName:[this._customerService.activeItem.lastName, Validators.required],
      phone:[this._customerService.activeItem.phone, Validators.required],
      email:[this._customerService.activeItem.email, Validators.email],
      zip:[this._customerService.activeItem.zip, Validators.required],
      city:[this._customerService.activeItem.city, Validators.required],
    })
  }

  zipcodeInputEvent(value: string) {
    if (!value || value.length !== 5) return
    this.showCityWait = true
    this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
      this.showCityWait = false
      if (res.success && res.data.city != undefined) {
        this._customerService.activeItem.city = res.data.city + '';
        const st = this.usaStates.filter(s => s.abbreviation == res.data.state)
        this._customerService.selectedState = st[0];
        this.editform.patchValue({ city: res.data.city })
         //console.log('city-data', this._customerService.activeItem)
      } else {
        this._customerService.activeItem.city = "Invalid zip code"
        this.editform.patchValue({ city: "Invalid zip code" })
        this._customerService.selectedState = this.usaStates[0]
      }
    })
  }

  saveFn() {
    const obj = {
      firstName:this._customerService.activeItem.firstName,
      lastName:this._customerService.activeItem.lastName,
      phone:this._customerService.activeItem.phone.replace(/\D/g,''),
      email:this._customerService.activeItem.email,
      postalCode:this._customerService.activeItem.zip,
      city:this._customerService.activeItem.city,
      state:this._customerService.selectedState.abbreviation
    }
  }

  cancelButtonClick() {
    this._confirmationService.confirm({
      header: `Cancel changes?`,
      message: 'Changes you made will be lost. Are you sure you want to proceed?',
      accept: () => {
        try {
          window.history.back()
          // this._router.navigate(['app/new-product'])
        } catch { this._abd.hideSpinner() }
      },
    });
  }

}
