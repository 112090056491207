import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tiered-reward-customer-report',
  templateUrl: './tiered-reward-customer-report.component.html',
  styleUrls: ['./tiered-reward-customer-report.component.scss']
})
export class TieredRewardCustomerReportComponent implements OnInit {
    _globals = GlobalService

    colheader = [['Customer Phone',  'Customer Name', 'Total Points', 'Total Value of Points']];
    colflds = ['customerPhone', 'customerName','value', 'value'];
    summaryHeader = [['Customer Phone',  'Customer Name', 'Total Points', 'Total Value of Points']];
    domSummaryflds = ['customerPhone', 'customerName', 'value', 'value'];
    domColHeader = this.colheader[0];
    domSummaryHeader = this.summaryHeader[0];


    dataList: any = ''
    locations: any = ''
    selectedLocation: any = ''
    departments: any = ''
    selectedDepartments: any = ''
    dateRange: any = ''

    showFilterPanel = false
    csvcols = []
    exportColumns: any = ''

    selectedGroupby: any = ''
    locationIds: string[] = [];
    catIds: string[] = [];
    groupby: string = 'Location';

    // public groupby$: Observable<GroupBy[]>;
    printOnce: boolean = true;

  constructor( private _bs: BaseService,
    private _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
    private currencyPipe: CurrencyPipe
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._abd.groupby = [
        { id: "Discount", title: "Discount" },
        { id: "Coupon", title: "Coupon" },
        { id: "Reward", title: "Reward" }
      ]
      this._abd.selectedGroup = this._abd.groupby[0]
      this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
      // this._abd.groupby = [
      //   { id: "Category", title: "Category" },
      //   { id: "Location", title: "Store" },
      //   { id: "Date", title: "Date" }
      // ]
      // this._abd.selectedGroup=this._abd.groupby[0]

      this._globals.componentName = 'Customer Report'
      this._abd.initDates()
      this._abd.selectedLocation = this._abd.locations;
      this._abd.selectedCategory = this._abd.actCategories[0];
      // this._abd.groupby$.subscribe(x => this._abd.selectedGroup = x[0]);
      this.getDataList()
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
      this._globals.componentName = ''
    }

    getGroupbyList() {
      try {
        this.groupby = this._filterService.selectedGroup.id;
      }
      catch {
        this.groupby = null
      }
    }

    getLocationsList() {
      let self = this;
      this.getGroupbyList()
      this.locationIds = [];
      let selLocation = this._filterService.selectedLocation;

      if (!selLocation || selLocation.length < 1) {
        selLocation = JSON.parse(JSON.stringify(this._abd.locations))
        this._filterService.selectedLocation = selLocation
      }

      selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
      selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
        self.locationIds.push(value.locationId.toString())
      })
    }

    getDataList() {
      this.getLocationsList();

      let x95 = this._filterService.selectedProductSellTypeFilter
      if (!x95) x95 = 'All'
      else x95 = x95.Value

      try {
        const sd = this._filterService.dateRange[0]
        const ed = this._filterService.dateRange[1]
        if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
          this._filterService.dateRange[1] = sd
        else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
          this._filterService.dateRange = []
          this._filterService.dateRange[0] = new Date()
          this._filterService.dateRange[1] = new Date()
        }
      } catch {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }

      const data = {
        "ReportName": 'InvoiceDiscountReport',
        "Parameter": {
          "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
          "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
          "LocationIds": this.locationIds,
          // "CategoriesIds": this.catIds,
          "DiscountType": "Reward",
          'filterValue': this._filterService.searchText,
          'OrderPlacement': x95
        }
      }

      const url = environment.InventoryBaseURL + environment.Report
      this._abd.showSpinner();
      this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
        this.dataList = res.data;
        this.dataList.map(x => {
          if (x.isOnlineOrder) {
            x.isOnlineOrder = 'Yes'
          } else if (!x.isOnlineOrder) {
            x.isOnlineOrder = 'No'
          }
        })
        this._abd.hideSpinner();
      })
    }

    filterButtonPressed() {
      this.getDataList()
    }

    callbackFuncFromFilter(event) {
      this.getDataList()
    }

    exportExcel() {
      this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Customer Reward Point Report')
    }

    exportPdf() {
      this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Customer Reward Point Report', true)
    }

    calculateSummaryTotal(col: string) {
      //

      let total = 0;
      if (this.dataList.length > 0) {
        ;
        let total = "0";
        if (col.includes("value")||col.includes("value")) {
          return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
        }
        else {
          return "";
        }
      }
      return total;

    }

  }
