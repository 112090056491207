import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-signup-yours-details',
  templateUrl: './signup-yours-details.component.html',
  styleUrls: ['./signup-yours-details.component.scss']
})
export class SignupYoursDetailsComponent implements OnInit {
  signupForm: FormGroup
  emailAlreadyExist=false

  formName = 'app-signup-yours-details'
  prevFormName = 'app-signup-business-detail'

  _globals = GlobalService
  
  validationErrors: string[] = ['this','that']

  get f() { return this.signupForm.controls }
  
  constructor(private fb: FormBuilder, private _router: Router, private _bs: BaseService) {}

  ngOnInit(): void {
    this.intitializeForm()
    const fdata = this._globals.getCacheFormData(this.formName)
    if (fdata) this._globals.setFormData(this.signupForm, fdata)
  }

  intitializeForm() {
    this.signupForm = this.fb.group({
      firstName: ['Marshal', Validators.required],
      lastName: ['John', Validators.required],
      email: ['John', [this.validateEmail(), Validators.email, Validators.required]],
      confirmEmail: ['John', [Validators.required, this.matchValues('email')]],
      password: ['John', Validators.required],
      confirmPassword: ['', [Validators.required, this.matchValues('password')]],
      useSameBusinessPhone: [false],
      phone: ['John', Validators.required],
    })
  }

  validateEmail(): ValidatorFn {
    return (control: AbstractControl) => {
      const xy = this.emailExist(control.value)
      return !this.emailAlreadyExist ? null : { isMatching: true }
    }
  }

  matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value === control?.parent?.controls[matchTo].value 
        ? null : {isMatching: true}
    }
  }

  emailExist(value: string) {
    value = value.trim()
    this.emailAlreadyExist = false
    if (value.length < 3) return false
    if (!this._globals.isValidEmail(value)) return false

    let ret = false
    this._bs.httpPost('auth-svc/api/IsUserExist', { Username: value }).subscribe(res => {

      if (res.success && res.data) {
        this.emailAlreadyExist = res.data
        return res.data
      } else {
        return false
      }
    })
  }

  backButtonPressed() {
    this._globals.updateCacheFormData(this.formName, this.signupForm.value)
    this._router.navigateByUrl('uikit/signupBusinessDetail')
  }

  nextButtonPressed() {
    this._globals.updateCacheFormData(this.formName, this.signupForm.value)
    this._router.navigateByUrl('uikit/HardwarePlanWidget')
  }

  checkboxOnChange(event) {
    if(event.checked) {
      this._globals.updateCacheFormData(this.formName, this.signupForm.value)
      const fdata = this._globals.getCacheFormData(this.prevFormName)
      if (fdata) this.signupForm.controls['phone'].setValue(fdata.businessPhone)
    } else {
      const fdata = this._globals.getCacheFormData(this.formName)
      if (fdata) this.signupForm.controls['phone'].setValue(fdata.phone)
    }
  }

}
