<div class="conn-card">
    <app-report-top-filter-bar *ngIf="1==1"
        [paraReportName]="'daily-sales-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>

    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"   responsiveLayout="scroll" [autoLayout]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <!-- <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th> -->
                <th pSortableColumn="date" ><strong>Date</strong> <p-sortIcon field="date"></p-sortIcon></th>
                <th pSortableColumn="locationName" ><strong>Location</strong> <p-sortIcon field="locationName"></p-sortIcon></th>
                <th pSortableColumn="totalInvoices" ><strong>Invoices</strong> <p-sortIcon fietotalInvoicesld=""></p-sortIcon></th>
                <th pSortableColumn="totalSaleItems" ><strong>Sold Items</strong> <p-sortIcon ftotalSaleItemsield=""></p-sortIcon></th>
                <th pSortableColumn="totalReturnItems" ><strong>Return Items</strong> <p-sortIcon ftotalReturnItemsield=""></p-sortIcon></th>
                <th pSortableColumn="totalVoidItems" ><strong>Void Items</strong> <p-sortIcon field="totalVoidItems"></p-sortIcon></th>
                <th pSortableColumn="subTotal" ><strong>SubTotal</strong> <p-sortIcon field="subTotal"></p-sortIcon></th>
                <th pSortableColumn="discount" ><strong>Discount</strong> <p-sortIcon field="discount"></p-sortIcon></th>
                <th ><strong>Net Sales</strong></th>
                <th pSortableColumn="salesTax" ><strong>Sales Tax</strong> <p-sortIcon field="salesTax"></p-sortIcon></th>
                <th pSortableColumn="shippingAmount" ><strong>Shipping</strong> <p-sortIcon field="shippingAmount"></p-sortIcon></th>
                <th pSortableColumn="shippingTax" ><strong>Shipping Tax</strong> <p-sortIcon field="shippingTax"></p-sortIcon></th>
                <th pSortableColumn="otherChargesAmount" ><strong>Other Charges</strong> <p-sortIcon field="otherChargesAmount"></p-sortIcon></th>
                <th pSortableColumn="tipAmount" ><strong>Tip Amount</strong> <p-sortIcon field="tipAmount"></p-sortIcon></th>
                <th pSortableColumn="grandTotal" ><strong>Grand Total</strong> <p-sortIcon field="grandTotal"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-expanded="expanded">
            <tr>
                <td>{{product.date | date:'MM/dd/yyyy'}}</td>
                <td>{{ product.locationName }}</td>
                <td>{{ product.totalInvoices | number }}</td>
                <td>
                    {{ product.totalSaleItems | number }}
                </td>
                <td>
                    {{ product.totalReturnItems | number }}
                </td>
                <td>
                    {{ product.totalVoidItems | number }}
                </td>
                <td>
                    {{ product.subTotal | currency }}
                </td>
                <td>{{ product.discount | currency }}</td>
                <td>
                    {{ product.subTotal-product.discount | currency }}
                </td>
                <td>{{ product.salesTax | currency }}</td>
                <td>{{ product.shippingAmount | currency }}</td>
                <td>{{ product.shippingTax | currency }}</td>
                <td>{{ product.otherChargesAmount | currency }}</td>
                <td>{{ product.tipAmount | currency }}</td>
                <td>
                    {{ product.grandTotal | currency }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                <td *ngFor="let col of domSummaryflds" style="text-align: left">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>

    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>

</div>
