import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tender-report',
  templateUrl: './tender-report.component.html',
  styleUrls: ['./tender-report.component.scss']
})
export class TenderReportComponent implements OnInit, OnDestroy {
  _globals = GlobalService

  dataList: any[] = [];
  locationDataList: any[] = [];
  yearDataList: any[] = [];

  colheader = this._abd.reports.filter(x => x.componentName == 'Tender Report')[0].colheader;
  domColHeader = this.colheader[0];
  colflds = this._abd.reports.filter(x => x.componentName == 'Tender Report')[0].colflds;
  summaryHeader = this._abd.reports.filter(x => x.componentName == 'Tender Report')[0].summaryHeader;
  domSummaryHeader = this.summaryHeader[0];
  domSummaryflds = this._abd.reports.filter(x => x.componentName == 'Tender Report')[0].summaryflds;

  csvcols = []
  exportColumns: any = '';
  activeState: boolean[] = [true, false, false];
  locationIds: string[] = [];


  constructor(private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Tender Report'

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._filterService.selectedLocation = this._abd.locations;
    this.getDataList();
  }
  
// $575,414.71
  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    
    if (!selLocation || selLocation.length < 1) {
      selLocation = JSON.parse(JSON.stringify(this._abd.locations))
      this._filterService.selectedLocation = selLocation
    }

    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }


  getDataList() {
    this.getLocationsList()

    let x95 = this._filterService.selectedProductSellTypeFilter
    if (!x95) x95 = 'All'
    else x95 = x95.Value

    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    const data = {
      "ReportName": 'PaymentMethodSalesReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        'OrderPlacement': x95
      }
    }

    const url = this._abd.reports.filter(x => x.componentName == 'Tender Report')[0].reportURL;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {

      this.dataList = res.data;
      this._abd.hideSpinner();
    })
  }

  filterButtonPressed() {
    this.getDataList()
  }


  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportExcel(data, this.summaryHeader[0], this.colflds, 'Tender Report')
  }

  exportPdf() {
    let data = this.refineCCDetails(this.dataList);
    this._abd.exportPdf(data, this.summaryHeader, this.colflds, 'Tender Report', true)
  }


  /*
      The following code seperates the tender data from the API data and passes it to the PDF generator
  */
  refineCCDetails(dataInput: any[]) {

    let dummyData = []
    dataInput.filter(user => {
      user.paymentMethodSales.filter(cc => dummyData.push(cc));
    });
    return dummyData;
  }

  calculateSummaryTotal(col: string) {
    // 
    let data = this.refineCCDetails(this.dataList);
    let total = 0;
    if (col === 'invoiceCount' || col === 'paymentMethodCount' || col === 'returnInvoiceCount') {
      return data.reduce((sum, item) => sum + item[col], 0)
    }
    else {
      return this.currencyPipe.transform(data.reduce((sum, item) => sum + item[col], 0));
    }



  }


}
