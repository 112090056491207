import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { PermissionCheckerService } from 'src/app/_services/permission-checker.service';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  @Input() showReportTopFilter=true

  rows = 10
  totalRecords = 0

  selectedRow:any=''
  searchText = ''
  lastSearchText = ''
  dataList: any = null
  dialogWidth = window.innerWidth <= 1200 ? '85%' : '65%'

  isViewOnly = false

  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  colheader = [[
    'Location', 'Manage Inventory on Store', 'Allow Shipping', 'Accept Online Orders'
    , 'Phone', 'Station'
  ]];

  colflds = ['locationName', 'manageInventory', 'allowShipping', 'acceptOnlineOrders',
    'phone'
  ];

  csvcols = []
  exportColumns: any = ''

    @Output() locationUpdated = new EventEmitter<any>()

  constructor(
    public _abd: AppBaseDataService,
    public _locationService: LocationsService,
    private _filterService: FilterServiceService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    private _permissionsCheckerService:PermissionCheckerService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
    try { this.totalRecords = this._abd.locations.length }
    catch { }
  }

  ngOnInit(): void {
    this._globals.componentName = 'Locations'
    // this.getDataList()
    this._abd.initLocations(this._abd.initActLocations())
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  editButtonClicked() {
    this.isViewOnly=false
    this._locationService.isEditLocation = true
  }

  getDataList() {
    this.searchText = ''
    this._filterService.searchText = ''
    try {
      this.totalRecords = this._abd.actLocations.length
      this._abd.initLocations(this._abd.actLocations)

      // const filtr = this._abd.locations.filter(f=>!f.isDeleted)
      // this.dataList = JSON.parse(JSON.stringify(this._abd.locations))
    }
    catch {
      this._abd.loadInit()
    }
  }

  actionButtonClick(item: any) {
    this._locationService.activeItem = JSON.parse(JSON.stringify(item))
  }

  refreshData() {
    // const loc = this._abd.locations.filter(f =>
    //   (!f.isDeleted && f.active)
    // )
    this._abd.initLocations(this._abd.initActLocations())
  }

  optionDeleteClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._locationService.deleteItem(this._locationService.activeItem.id).subscribe(res => {
            this._abd.hideSpinner()
            if (res.success) {
              this._abd.loadInit().subscribe(resD => {
                this.refreshData()
                if (resD.success) {
                    this.locationUpdated.emit(true);
                }
              })
              this._connMessageService.showToastMessage(`Location "${this._locationService.activeItem.locationName}" deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  filterButtonPressed() {
    this.implementLocalFilter()
  }

  callbackFuncFromFilter(event) {
    this.implementLocalFilter()
    // this.getDataList()
  }

  async deleteMultiple() {
    await this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {


          let ids:any[]
          for (let i = 0; i < this._abd.locations.length; i++) {
            if(this._abd.locations[i].selected) ids.push(this._abd.locations[i].id)
          }

          if(ids.length < 1) {
            this._connMessageService.showToastMessage(`Nothing selected!`, 'error')
            this._abd.hideSpinner()
            return
          }

          const idstr = ids.toString()

          this._locationService.deleteItem(idstr).subscribe(res => {
            this._abd.hideSpinner()
            if (res.success) {
              this._abd.loadInit().subscribe(resD => {
                this.refreshData()
                if (resD.success) { }
              })
              this._connMessageService.showToastMessage(`Location "${this._locationService.activeItem.locationName}" deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  implementLocalFilter() {
    this.searchText = this._abd.searchText
    try {
      this.searchText = this.searchText.toLowerCase()
      if (this.searchText.length < 1) {
        this.refreshData()
        return
      }
      const loc = this._abd.initActLocations().filter(f =>
        (!f.isDeleted && f.active)
        && f.locationName.toLowerCase().includes(this.searchText)
        || f.phone.toLowerCase().includes(this.searchText)
      )
      this._abd.initLocations(loc)
    }
    catch { }

  }

  selectionChanged(event) {
    let ret = false
    for (let i = 0; i < this._abd.locations.length; i++)
      if (this._abd.locations[i].selected) {
        ret = true
        break
      }

    this._globals.showProductDelButton = ret
  }

  selectAllChanged(event) {
    if (event.checked) {
      this._globals.showProductDelButton = true
      for (let i = 0; i < this._abd.locations.length; i++) {
        this._abd.locations[i].selected = true
      }
    } else {
      this._globals.showProductDelButton = false
      for (let i = 0; i < this._abd.locations.length; i++) {
        this._abd.locations[i].selected = false
      }
    }
  }

  exportExcel() {
    // this._globals.exportExcel(this.dataList, this.colflds, 'Manufacturer Report')
  }

  exportPdf() {
    let data = []

    for (let i = 0; i < this.dataList.length; i++) {
      let row = []
      for (let j = 0; j < this.colflds.length; j++)
        row.push(this.dataList[i][this.colflds[j]])
      data.push(row)
    }

    // this._globals.exportPdf(data, this.colheader, 'Manufacturer Report')
  }

  async onRowSelect(e) {
    let permission =  await this._permissionsCheckerService.isPermissionValid(['2013',]) 
    if(permission){
    this.actionButtonClick(e.data)
    this.isViewOnly=true
    this._locationService.isEditLocation = true
    }
  }


  onRowUnselect(e) {}

}
