<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <!-- <label class="control-label"
                    ><strong
                        >Invoice Configuration
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
                <br />
                <form
                    [formGroup]="cashdrawerform"
                    (ngSubmit)="cashdrawerform.valid && saveFn()"
                    autocomplete="off"
                >
                <div class="mb-4">
                    <p-inputSwitch [formControl]="$any(cashdrawerform.controls)['enableCashdrawerForm']" inputId="rememberme8"></p-inputSwitch>
                    <label class="control-label" for="rememberme8"><strong> Enable</strong></label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['useCashDrawer']"
                        inputId="rememberme1"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme1">Use Cash Drawer</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['openDrawerOnCashTransaction']"
                        inputId="rememberme2"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme2">Open Cash Drawer On Cash Transaction</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['openDrawerOnCashDrop']"
                        inputId="rememberme3"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme3">Open Cash Drawer On Cash Drop</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['openDrawerOnPayout']"
                        inputId="rememberme4"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme4">Open Cash Drawer On Pay Out</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['enableTillManagement']"
                        inputId="EnableTill"
                        [binary]="true"
                        styleClass="mr-2"
                        (onChange)="EnableTillManagment()"
                    ></p-checkbox>
                    <label for="EnableTill">Enable Till Management</label>
                </div>
                <br/>
                <div class="field-checkbox" *ngIf="cashdrawerform.get('enableTillManagement').value">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['reqVerificationTillManagement']"
                        inputId="RequireVerification"
                        [binary]="true"
                        styleClass="mr-2" 
                        
                    ></p-checkbox>
                    <label for="RequireVerification">Require Verification for Cash Management</label>
                </div>
                <br/>
                
                <div class="field-checkbox" *ngIf="cashdrawerform.get('enableTillManagement').value">
                    <p-checkbox
                        [formControl]="$any(cashdrawerform.controls)['forceTillManagement']"
                        inputId="ForceTill"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="ForceTill">Require Till in System for Checkout</label>
                </div>
                <br/>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!cashdrawerform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
