import { ReportsService } from 'src/app/_services/reports.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-payment-details',
  templateUrl: './invoice-payment-details.component.html',
  styleUrls: ['./invoice-payment-details.component.scss']
})
export class InvoicePaymentDetailsComponent implements OnInit {

  constructor(public _reportService: ReportsService) { }

  ngOnInit(): void {
  }

}
