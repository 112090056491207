<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'app-inventory-adjustment-detail'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>



    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" [tableStyle]="{ width: 'max-content' }" [autoLayout]="true" styleClass="p-datatable-striped"  responsiveLayout="scroll"
     *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-inventory_adjustment_details>
            <tr>
                <td>
                   <span>{{ inventory_adjustment_details.dated | date:'MM/dd/yyyy'  }}</span>
                </td>
                <td>
                   <span>{{ inventory_adjustment_details.user }}</span>
                </td>
                <td>
                   <span>{{ inventory_adjustment_details.adjustmentId }}</span>
                </td>
                <td>
                   <span>{{ inventory_adjustment_details.adjustmentType }}</span>
                </td>
                <td>
                   <span>{{ inventory_adjustment_details.locationName }}</span>
                </td>
                <td>
                    <span>{{ inventory_adjustment_details.itemName }}</span>
                 </td>
                 <td>
                    <span>{{ inventory_adjustment_details.skuCode }}</span>
                 </td>
                 <td>
                    <span>{{ inventory_adjustment_details.barcode }}</span>
                 </td>
                 <td>
                    <span>{{ inventory_adjustment_details.quantity }}</span>
                 </td>
                 <td>
                    <span>{{ inventory_adjustment_details.uom }}</span>
                 </td>
                <td>
                   <span>{{ inventory_adjustment_details.unitCost | currency }}</span>
                </td>
                <td>
                   <span>{{ inventory_adjustment_details.totalCost | currency }}</span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: left">
                    <strong>Total</strong>
                </td>

                <td *ngFor="let col of domSummaryflds" style="text-align: left">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
