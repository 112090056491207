import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
})
export class NotfoundComponent {

  constructor(
    private _abd: AppBaseDataService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._abd.whichRoute = 'any'
    let x = window.location.href
    this._abd.initMenuList()
  }
}