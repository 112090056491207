import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { CurrentPlanGeneralInformation } from 'src/app/_models/subscription-management/current-plan-general-info';
import {
    PlanInfo,
    UserSubscriptionDetail,
} from 'src/app/_models/subscription-management/user-subscription-info';

@Component({
  selector: 'app-bizz-customer-hardware-details',
  templateUrl: './bizz-customer-hardware-details.component.html',
  styleUrls: ['./bizz-customer-hardware-details.component.scss']
})
export class BizzCustomerHardwareDetailsComponent implements OnInit {
  localItem:any=''
  hardwareObj:any=''
  localObj:any=''

  stateOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  invoiceDetail: any = []
  productsDetail: any = []
  hardwaredetails: any= []
  currentPlanSubscription = <UserSubscriptionDetail>{};
  loadingContent: boolean = false;
dataList: any = [];
totalRecords: number = 0;
planPricePerMonth: number = 0;
basePlanPrice: number = 0;
costHeading: string = "";
userCurrentPlan = <PlanInfo>{};
currentPlanGeneralInformation: CurrentPlanGeneralInformation[] = [];
subscribedPackages: any[] = [];

  constructor(
    public _bizzCustomerService: BizzCustomersService,
        public _signup: SignupService,
        public _getSubPlanInvoice: SubscriptionPlanService,
  ) { }

  ngOnInit(): void {
    this.localItem = this._bizzCustomerService.activeItem

    this.localObj = this.localItem.subscriptionInvoice.hardwarePkgs

    this.hardwareObj = this._bizzCustomerService.activeItem.currentSubscription.hardwareList

    // this._getSubPlanInvoice.getBusinessInvoice();
    // let item = this._bizzCustomerService.activeItem.currentSubscription.hardwareList
    // let planPricePerMonth = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[1].value
    //  if (this._bizzCustomerService.activeItem.subscriptionInterval == 12) {
    //      planPricePerMonth = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[0].value
    //  }
    //  let costHeading = "$" + planPricePerMonth + "/per month billed monthly"

    //  let selectedPlanInterval = this._bizzCustomerService.activeItem.subscriptionInterval
    //  if (selectedPlanInterval == 12) {
    //      costHeading = "$" + planPricePerMonth + "/per month X " + selectedPlanInterval + " = $" + planPricePerMonth * selectedPlanInterval + " billed annually"
    //  }


    //  if (this._bizzCustomerService.activeItem.isFreeTrial){
    //      costHeading = "$0.00"
    //  }
    //  this.invoiceDetail.push({ label: 'Invoice No', value: item.invoiceNo })
    //  item = this._bizzCustomerService.activeItem.currentSubscription.hardwareList

    //  for (let i = 0; i < item.length; i++) {

    //      let additionalCost = "";
    //      if (selectedPlanInterval == 12 && (i == 1 || i == 2)){
    //          additionalCost = item[i].pricePerPackage.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + " X " + item[i].quantity  +" X 12 = "
    //      }
    //      let includedAddon = "";
    //      if (item[i].addonsIncluded){
    //           includedAddon = " (addon included)"
    //      }
    //      this.hardwaredetails.push({
    //          title: item[i].title,
    //          qty: item[i].qty,
    //          totalPrice: item[i].totalPrice,
    //      })
    //  }

  }

}
