import { GlobalService } from 'src/app/_services/global.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    selector: 'app-subscription-plan-card-data-desktop',
    templateUrl: './subscription-plan-card-data-desktop.component.html',
    styleUrls: ['./subscription-plan-card-data-desktop.component.scss'],
})
export class SubscriptionPlanCardDataDesktopComponent implements OnInit {
    @Input() card: any;
    @Input() cardindex: any;

    _globals = GlobalService;

    @Output() planChanged: EventEmitter<boolean> = new EventEmitter();
    @Input() locationChangedTrigger: number = 1;
    @Input() registerChangedTrigger: number = 1;
    @Input() selectedPlanTimeframe: string = 'BilledMonthly';
    subscriptionGrandTotal: number = 0;
    constructor(public _signup: SignupService) {}

    ngOnInit(): void {
        // console.log('CARD', this.card)
        this.planChanged.emit(false);
        // this._signup.selectedPlanTimeframe = 'BilledMonthly1'
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes['locationChangedTrigger'];
            let chngr = changes['registerChangedTrigger'];
            let chngDuration = changes['selectedPlanTimeframe'];
            //  let cur = JSON.stringify(chng.currentValue);
            //  let prev = JSON.stringify(chng.previousValue);
            this.getPlanPrice();
            this.subscriptionPlanSelected(this._signup.selectedPlan);
            this.updatePrices();
        }
    }

    updatePrices() {
        try {
            this._signup.subscriptionPlans.map((f) => (f.grandTotal = 0));
            this._signup.subscriptionPlans.map((f) => (f.locationAmount = 0));
            this._signup.subscriptionPlans.map((f) => (f.registerAmount = 0));
            this._signup.subscriptionPlans.map((f) => (f.registerTotal = 0));
            this._signup.subscriptionPlans.map((f) => (f.locationTotal = 0));
            this._signup.subscriptionPlans.map(
                (f) => (f.locationSubscriptionPrice = 0)
            );
            this._signup.subscriptionPlans.map(
                (f) => (f.registerSubscriptionPrice = 0)
            );
            this._signup.subscriptionPlans.map((f) => (f.locationTotal = 0));
            this._signup.subscriptionPlans.map((f) => (f.locationCount = 1));
            this._signup.subscriptionPlans.map((f) => (f.RegisterCount = 1));
            this._signup.subscriptionPlans.map(
                (f) => (f.subscriptionBasePlanPrice = 0)
            );
            let self = this;

            if (
                this._signup.outerLocationQty > 1 ||
                this._signup.outerRegisterQty > 1
            ) {
                this._signup.subscriptionPlans.map(function (value) {
                    if (
                        !value.isTrial &&
                        value.title != 'Lite' &&
                        value.durationType != 'Custom Quote'
                    ) {
                        if (
                            self._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                        ) {
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledMonthly'
                                )[0].value;
                            if (self._signup.outerLocationQty - 1 > 0) {
                                value.locationCount =
                                    self._signup.outerLocationQty - 1;
                                value.RegisterCount =
                                    self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1);
                                value.locationSubscriptionPrice =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString ==
                                            'AdditionalLocationPlus1Reg'
                                    )[0].price;
                                value.locationAmount =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString ==
                                            'AdditionalLocationPlus1Reg'
                                    )[0].price *
                                    (self._signup.outerLocationQty - 1);
                            }

                            if (
                                self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1) >
                                0
                            ) {
                                value.locationCount =
                                    self._signup.outerLocationQty - 1;
                                value.RegisterCount =
                                    self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1);
                                value.registerSubscriptionPrice =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString == 'AdditionalRegister'
                                    )[0].price;
                                value.registerAmount =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString == 'AdditionalRegister'
                                    )[0].price *
                                    (self._signup.outerRegisterQty -
                                        1 -
                                        (self._signup.outerLocationQty - 1));
                            }

                            value.registerTotal = value.registerAmount;
                            value.locationTotal = value.locationAmount;

                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        } else {
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledAnnually'
                                )[0].value;
                            if (self._signup.outerLocationQty - 1 > 0) {
                                value.locationCount =
                                    self._signup.outerLocationQty - 1;
                                value.RegisterCount =
                                    self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1);
                                value.locationSubscriptionPrice =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString ==
                                            'AdditionalLocationPlus1Reg'
                                    )[0].price;
                                value.locationAmount =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString ==
                                            'AdditionalLocationPlus1Reg'
                                    )[0].price *
                                    (self._signup.outerLocationQty - 1);
                            }
                            if (
                                self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1) >
                                0
                            ) {
                                value.locationCount =
                                    self._signup.outerLocationQty - 1;
                                value.RegisterCount =
                                    self._signup.outerRegisterQty -
                                    1 -
                                    (self._signup.outerLocationQty - 1);
                                value.registerSubscriptionPrice =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString == 'AdditionalRegister'
                                    )[0].price;
                                value.registerAmount =
                                    value.featureData.filter(
                                        (f) =>
                                            f.typeString == 'AdditionalRegister'
                                    )[0].price *
                                    (self._signup.outerRegisterQty -
                                        1 -
                                        (self._signup.outerLocationQty - 1));
                            }
                            value.registerTotal = value.registerAmount;
                            value.locationTotal = value.locationAmount;
                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        }
                    }
                    if (value.title == 'Lite') {
                        value.locationAmount = 0;
                        value.registerAmount = 0;
                        value.registerTotal = 0;
                        value.locationTotal = 0;
                        value.locationCount = 1;
                        value.RegisterCount = 1;
                        if (
                            self._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                        ) {
                            //  value.locationCount = 1
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledMonthly'
                                )[0].value;
                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        } else {
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledAnnually'
                                )[0].value;
                            //value.RegisterCount = 1
                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        }
                    }
                });
            } else {
                this._signup.subscriptionPlans.map(function (value) {
                    if (
                        !value.isTrial &&
                        value.durationType != 'Custom Quote'
                    ) {
                        value.locationAmount = 0;
                        value.registerTotal = 0;
                        value.locationTotal = 0;
                        value.registerAmount = 0;
                        value.locationCount = 0;
                        value.RegisterCount = 0;
                        if (
                            self._signup.selectedPlanTimeframe ==
                            'BilledMonthly'
                        ) {
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledMonthly'
                                )[0].value;
                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        } else {
                            value.subscriptionBasePlanPrice =
                                value.prices.filter(
                                    (f) =>
                                        f.priceLimitTypeString ==
                                        'BilledAnnually'
                                )[0].value;

                            value.grandTotal =
                                value.locationAmount +
                                value.registerAmount +
                                value.subscriptionBasePlanPrice;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    contactUs() {
        if (this.cardindex == 5) return 'Contact Us';

        if (this._signup.selectBtn == this.card.id) return 'Selected';
        else return 'Select';
        // {{subscriptionplanservice.selectBtn == card.id? 'Selected' : 'Select'}}
    }

    getPlanPrice() {
        try {
            if (this._signup.selectedPlanTimeframe !== 'BilledMonthly')
                return this.card.prices[0].value;
            else return this.card.prices[1].value;
        } catch (error) {}
    }

    isCardInRange() {
        if (
            this.cardindex > 0 &&
            this.cardindex < this._signup.subscriptionPlans.length - 1
        )
            return true;
        else return false;
    }

    calcPlanTotal() {
        //this.subscriptionGrandTotal =
        //let total = this.getPlanPrice()
        // total += this.card.locationAmount + this.card.registerAmount
        //return total
    }

    subscriptionPlanSelected(card) {
        if (card.isCustom) {
            window.location.href = 'https://www.connect-360.net/#contact';
        }
        if (this._signup.selectedPlan?.id != card.id) {
            this._signup.recalEcomPlans();
        }

        this._signup.selectedPlan = card;
        this._signup.checkoutPageCalcVars.locationsQty =
            this._signup.outerLocationQty;
        this._signup.checkoutPageCalcVars.registersQty =
            this._signup.outerRegisterQty;
        if (card.isTrial) {
            sessionStorage.setItem('free-trial-account', '1');
            this._signup.freeTrialAccount = true;
        } else {
            sessionStorage.setItem('free-trial-account', '0');
            this._signup.freeTrialAccount = false;
        }

        this.planChanged.emit(true);
    }

    getWordsWithSpace(str) {
        return str.replace(/[A-Z]/g, ' $&').trim();
    }
}
