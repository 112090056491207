import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-connect-cbd-return-and-refund',
  templateUrl: './connect-cbd-return-and-refund.component.html',
  styleUrls: ['./connect-cbd-return-and-refund.component.scss']
})
export class ConnectCbdReturnAndRefundComponent implements OnInit {
   _global = GlobalService
  constructor() { }

  ngOnInit(): void {
  }

}
