<!-- <p-dialog header="Title" [(visible)]="showSearchPanel" >
    <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
</p-dialog> -->

<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div>
    <h2 class="bs-page-header mb-3">Purchase Order</h2>
</div>

<p-progressBar
    mode="indeterminate"
    *ngIf="!isDataLoaded && !isNew"
></p-progressBar>

<div class="grid" *ngIf="isDataLoaded || isNew">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-12">PO#</div>
                    <div class="col-12">
                        <input
                            [style]="{ width: '100%' }"
                            type="text"
                            [(ngModel)]="_purchaseService.newEditItem.PONumber"
                            pInputText
                            [disabled]="true"
                        />
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="grid">
                    <div class="col-12">Dated</div>
                    <div class="col-12">
                        <p-calendar
                            [(ngModel)]="_purchaseService.newEditItem.Dated"
                            showIcon="true"
                            [style]="{ width: '100%' }"
                            dateFormat="D d, MM yy"
                            [disabled]="isViewOnly"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="col">
                <div class="grid">
                    <div class="col-12">Location</div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="locations"
                            [(ngModel)]="
                                _purchaseService.newEditItem.locationObj
                            "
                            optionLabel="locationName"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                            (onChange)="locationChanged($event)"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <div class="grid mt-3">
                    <div class="col-12">
                        <div>
                            Vendor
                            <span
                                ><a
                                    style="
                                        background-color: var(--bs-info);
                                        color: white;
                                        padding: 2px;
                                        border-radius: 50%;
                                    "
                                    class="text-red-600 font-bold"
                                    href="javascript:void(0);"
                                    (click)="showVendorInfoDialogFn()"
                                    ><span
                                        ><i
                                            class="pi pi-info"
                                            style="color: white"
                                        ></i></span></a
                            ></span>
                        </div>
                    </div>
                    <div class="col-12">
                        <p-dropdown
                            [options]="vendors"
                            [(ngModel)]="_purchaseService.newEditItem.vendorObj"
                            optionLabel="vendorName"
                            [style]="{ width: '100%' }"
                            [disabled]="isViewOnly"
                            (onChange)="vendorChanged($event)"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-4 mt-3">Created By</div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="_purchaseService.newEditItem.RequestedBy"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">Method</div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="method"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-4 mt-3">Status</div>
                <div class="col">
                    <input
                        [style]="{ width: '100%' }"
                        type="text"
                        [(ngModel)]="_purchaseService.newEditItem.POStatus"
                        pInputText
                        [disabled]="true"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="conn-card" *ngIf="isDataLoaded || isNew">
    <div class="grid">
        <div class="col-fixed" style="width: 300px">
            <div class="p-inputgroup">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search by scanning barcode"
                    [disabled]="isLoading || isViewOnly"
                    [(ngModel)]="searchText"
                    (keydown.enter)="searchButtonClick($event)"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [disabled]="isLoading || isViewOnly"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                ></button>
            </div>
        </div>
        <div class="col">
             <div class="flex justify-content-end flex-wrap">
                <div class="flex align-items-center justify-content-center m-2">
                    <button
                        *ngIf="showDeleteButton()"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                        iconPos="right"
                        (click)="removeItem()"
                    ></button>
                </div>

                <div
                    *ngIf="!isNew || isEdit"
                    class="flex align-items-center justify-content-center m-2"
                >
                    <button
                        *ngIf="_purchaseService.newEditItem.POStatus == 'DRAFT'"
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-warning p-button-outlined'
                                : 'p-button-rounded p-button-info p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                    ></button>
                </div>
            </div>
        </div>
    </div>

    <br />
    <div>
        <p-table
            [value]="dataList"
            [autoLayout]="true"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                     <th style="text-align: center; width: 30px">
                        <p-checkbox
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th>
                    <th>Sr#</th>
                    <th>Barcode</th>
                    <th>SKU</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th style="text-align: center">Qty</th>
                    <th style="text-align: center">Unit Cost($)</th>
                    <th style="text-align: center">Cost($)</th>
                    <th style="text-align: center">Discount(%)</th>
                    <th style="text-align: center">Net Cost</th>
                    <th style="text-align: center">Tax(%)</th>
                    <th style="text-align: center">Shipping($)</th>
                    <th style="text-align: right">Total Cost($)</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                     <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td>
                    <td class="select-none">{{ item.srNo }}</td>
                    <td class="select-none">{{ item.barcode }}</td>
                    <td class="select-none">{{ item.skuCode }}</td>
                    <td class="select-none">
                        {{ item.description | slice : 0 : 25 }}
                        <span *ngIf="item.description?.length > 25">...</span>
                    </td>
                    <td class="select-none">{{ item.uomDescription }}</td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.receivedQty"

                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="orderedQtyChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.costPrice"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="costPriceChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                            <div
                                class="flex justify-content-start text-red-500 mt-1"
                                *ngIf="!costPriceValid(item) && !isViewOnly"
                            >
                                <span style="font-size: 12px">
                                    Cost price cannot be less than zero (0)
                                </span>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.netCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '120px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.discountPercent"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    [max]="100"
                                    placeholder="0"
                                    (onInput)="
                                        discountAmountChanged($event, item)
                                    "
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.netTotal"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '120px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.taxRate"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="taxAmountChanged($event, item)"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.shipping"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'right'
                                    }"
                                    (onInput)="
                                        itemShippingChanged($event, item)
                                    "
                                    [disabled]="
                                        isViewOnly ||
                                        !enableShippingCostInput
                                    "
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="flex justify-content-end select-none">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.itemLevelItemCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '140px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="13" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<br />
<div class="grid" *ngIf="isDataLoaded || isNew">
    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <label for="notes">Notes</label>
            <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                class="w-full"
                [(ngModel)]="activeItem.notes"
                [disabled]="isViewOnly"
                style="margin-top: 10px"
            ></textarea>
        </div>
        <!-- <div class="conn-card" *ngIf="isFooterNoteEnabled">
            <label [innerHTML]="footerNotesText"></label>
        </div> -->
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card" *ngIf="isNew || isEdit">
            <p-checkbox
                name="enableShippingCost"
                [binary]="true"
                inputId="binary"
                label="Enable Item Level Shipping Cost"
                [disabled]="isViewOnly"
                (onChange)="shippingOptionRadioButtonClicked()"
                [(ngModel)]="enableShippingCostInput">
            </p-checkbox>
        </div>
    </div>

    <div class="xl:col-4 lg:col-4 sm:col-12 md:col-12">
        <div class="conn-card">
            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Sub Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gsubtotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Total Discount($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gdiscount"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Total Tax($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="gtax"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Shipping($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [(ngModel)]="shippingCost"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                        (onInput)="shippingChanged($event)"
                        [disabled]="enableShippingCostInput|| isViewOnly"
                    ></p-inputNumber>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 mt-3 font-bold text-lg text-500">
                    Grand Total($)
                </div>
                <div class="col">
                    <p-inputNumber
                        [disabled]="true"
                        [(ngModel)]="grandTotal"
                        mode="decimal"
                        placeholder="0.00"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [inputStyle]="{
                            width: '100%',
                            'text-align': 'right'
                        }"
                    ></p-inputNumber>
                </div>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<div class="flex justify-content-end flex-wrap" *ngIf="isDataLoaded || isNew">
    <div class="flex align-items-center justify-content-center m-2" *ngIf="!isViewOnly">
        <p-checkbox
            [(ngModel)]="isDuplicate"
            [binary]="true"
            label="Duplicate"
            class="font-bold text-lg text-700"
            [disabled]="isViewOnly || dataList.length == 0"
        ></p-checkbox>
    </div>
    <div class="flex align-items-center justify-content-center m-2">
        <button
            pButton
            type="button"
            label="Cancel"
            class="p-button-danger"
            [style]="{ width: '120px' }"
            (click)="doCancel()"
        ></button>
    </div>
    <div class="flex align-items-center justify-content-center m-2"  *ngIf="!isViewOnly">
        <button
            pButton
            label="Save"
            (click)="saveFn('DRAFT')"
            [disabled]="isViewOnly || dataList.length == 0"
            [style]="{ width: '120px' }"
        ></button>
    </div>
    <div class="flex align-items-center justify-content-center m-2">
        <button
            pButton
            class="p-button-help"
            label="Post"
            (click)="saveFn('OPEN')"
            [disabled]="!isOk2Save()"
            [style]="{ width: '120px' }"
        ></button>
    </div>
</div>

<p-dialog
    *ngIf="showVendorInfoDialog"
    header="Vendor Information"
    [(visible)]="showVendorInfoDialog"
    modal="true"
>
    <app-new-edit-vendor
        [isNew]="false"
        [showActionButtons]="false"
    ></app-new-edit-vendor>
</p-dialog>
