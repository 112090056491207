<div class="flex justify-content-between mt-5">
    <div class="flex align-items-center justify-content-center"
    (click)="activateSinglePage(page-1)">
        {{ displayString }}
    </div>
    <div class="flex align-items-center justify-content-center" *ngIf="totalPages>0">
        <ng-container *ngFor="let p of pageArray">
            <!-- *ngIf="p.isActive" -->
            <button
                pButton
                pRipple
                type="button"
                [label]="p.label"
                [disabled]="p.disabled"
                [ngClass]="p.isActive ? 'p-button' : 'p-button-outlined'"
                style="margin-right: 3px; min-width: 40px"
                (click)="pageClicked(p)"
            ></button>

            <!-- <button
                *ngIf="!p.isActive"
                pButton
                pRipple
                type="button"
                [label]="p.label"
                [disabled]="p.disabled"
                class="p-button-outlined"
                style="margin-right: 3px; min-width: 40px"
                (click)="pageClicked(p)"
            ></button> -->
        </ng-container>
    </div>
    <div class="flex align-items-center justify-content-center m-2" *ngIf="totalPages>0">
        <p-dropdown
            [options]="rowsPerPageOptions"
            [(ngModel)]="selectedPageCount"
            (onChange)="rowsCountChange($event)"
        ></p-dropdown>
    </div>
</div>
