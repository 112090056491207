import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SalesChannelService {

  _globals = GlobalService
  showEditChannel: boolean = false;
  activeItem: any = null
  showNewEditChannel: boolean = false;
  
// For Testing 
   SalesChannelList:any[]=[
    {id:1,discountDate:'22-Jan',fullName:'Instagram',couponCode:'Insta20Pls22',discountType:'$20 discount',subscriptionPlan:'Plus',noCouponUsed:5,discountValue:'$20 off',discountAmount:100,status:1},
    {id:2,discountDate:'21-Nov',fullName:'Omar',couponCode:'Lte5%OM24',discountType:'Percentage off',subscriptionPlan:'Lite',noCouponUsed:2,discountValue:'5% off',discountAmount:15,status:0},
    {id:3,discountDate:'23-Nov',fullName:'Justin',couponCode:'Pre20Jus23',discountType:'$20 discount',subscriptionPlan:'Premium',noCouponUsed:1,discountValue:'$20 off',discountAmount:20,status:0},
    {id:4,discountDate:'22-Feb',fullName:'Facebook',couponCode:'Sta20Fac24',discountType:'Percentage off',subscriptionPlan:'Standard',noCouponUsed:4,discountValue:'45% off',discountAmount:80,status:1},
   ]



  constructor(private _bs: BaseService,
              private _abd: AppBaseDataService) { }

  blankActiveItem(){
    this.activeItem = 
    {
      id:'0',
      fullName: '',
      status:true,
      description: '',
      couponCodes:null,
      url:null,
    }
  }

  getAllSalesChannel(model?:any){
    let url = environment.BusinessBaseURL + 'SalesChannel'
    return this._bs.httpGetParams(url, model).pipe(map((res: any) => {
      return res
    }))
  }

  addChannel(model: any){
    let url = environment.BusinessBaseURL + 'SalesChannel'
    return this._bs.httpPost(url, model).pipe(map((res: any) => {
      return res
    }))
  }
updateChannel(Id: any, model: any){
    let url = environment.BusinessBaseURL + 'SalesChannel' + "/" + Id;
    return this._bs.httpPut(url, model).pipe(map((res: any) => {
      return res
    }))
  }
  deleteChannel(Id: any){
    let url = environment.BusinessBaseURL + 'SalesChannel' + "/" + Id;
    return this._bs.httpDelete(url).pipe(map((res: any) => {
      return res
    }))
  }
}
