<div class="conn-container">
    <div class="items">
        <div class="items-head">
            <p>Customer Details</p>
        </div>

        <div class="items-body">
            <div class="items-body-content">
                <p-table
                    #dt
                    [columns]=""
                    [autoLayout]="true"
                    [value]="
                        _reportService.selectedInvoiceDetails.invoiceCustomerDetails
                    "
                    responsiveLayout="scroll"
                    showLoader="true"
                    rowHover="true"
                    [customSort]="true"
                    [style]="{ 'min-width': '100%' }"
                >
                <ng-template pTemplate="body" let-item>
                    <tr [ngStyle]="{ color: 'var(--gray-600)' }">
                        <td style="font-weight: 700; color: var(--gray-400)">{{ item.label }}</td>
                        <td style="text-align:right">{{ item.value }}</td>
                    </tr>
                </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
