<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'app-cash-drawer-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>
    <br />
    <div class="row">
        <br />
        <br />
        <div
            class="font-bold text-xl ml-2 text-center"
            [style]="{ color: 'var(--brandColor)' }"
        >
            No-Sale
        </div>
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="nosaleList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Date/Time</th>
                    <th>Location</th>
                    <th>Register</th>
                    <th>User</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.date | date : "short" }}</td>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td>{{ item.cashierName }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="4" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="row">
        <br />
        <br />
        <br />
        <div
            class="font-bold text-xl ml-2 text-center"
            [style]="{ color: 'var(--brandColor)' }"
        >
            Cash Drawer Reset
        </div>
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="cashList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Date/Time</th>
                    <th>Location</th>
                    <th>Register</th>
                    <th>User</th>
                    <th>Amount Adjusted</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.date | date : "short" }}</td>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td>{{ item.cashierName }}</td>
                    <td>{{ item.amount }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="5" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="row">
        <br />
        <br />
        <br />
        <div
            class="font-bold text-xl ml-2 text-center"
            [style]="{ color: 'var(--brandColor)' }"
        >
            Amount in Cash Drawer (Current)
        </div>
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="currentList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Location</th>
                    <th>Register</th>
                    <th class="text-right">Amount</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td class="text-right">{{ item.amount | currency }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="3" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="row" *ngIf="false">
        <br />
        <br />
        <br />
        <div
            class="font-bold text-xl ml-2 text-center"
            [style]="{ color: 'var(--brandColor)' }"
        >
            Other Transactions
        </div>
        <br />
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="otherList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Location</th>
                    <th>Register</th>
                    <th class="text-right">Payin #</th>
                    <th class="text-right">Payout #</th>
                    <th class="text-right">Drops #</th>
                    <th class="text-right">Count #</th>
                    <th class="text-right">Cash Sale #</th>
                    <th class="text-right">Cash Refund #</th>
                    <th class="text-right">Till #</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td class="text-right">{{ item.payIn | currency }}</td>
                    <td class="text-right">{{ item.payOut | currency }}</td>
                    <td class="text-right">{{ item.drops | currency }}</td>
                    <td class="text-right">{{ item.count | currency }}</td>
                    <td class="text-right">{{ item.cashSale | currency }}</td>
                    <td class="text-right">{{ item.cashRefund | currency }}</td>
                    <td class="text-right">{{ item.till | currency }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="9" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
