import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transfer-detail-report',
  templateUrl: './transfer-detail-report.component.html',
  styleUrls: ['./transfer-detail-report.component.scss'],
})
export class TransferDetailReportComponent implements OnInit {
  _globals = GlobalService


  colheader = [['Date', 'Transfer#', 'Product', 'SKU', 'Barcode', 'Sender Location', 'Receiver Location', 'Transferred Qty (#)', 'Unit Price ($)', 'Total Price ($)', 'Status']];
    colflds = ['date','transferNo', 'productName', 'skuCode', 'barcode', 'senderLocation', 'receiverLocation', 'totalTransferredQty', 'unitPrice', 'totalPrice', 'transferTypeString'];
  summaryHeader = [
        [
            'Date',
            'Transfer#',
            'Product',
            'SKU',
            'Barcode',
            'Sender Location',
            'Receiver Location',
            'Transferred Qty (#)',
            'Unit Price ($)',
            'Total Price ($)',
            'Status']
    ];
  domSummaryflds = ['productName', 'Transfer#', 'skuCode', 'barcode', 'senderLocation', 'receiverLocation', 'totalTransferredQty', 'unitPrice', 'totalPrice', 'transferTypeString'];
  domColHeader = this.colheader[0];
  domSummaryHeader = this.summaryHeader[0];

  dataList: any = ''
  locations: any = ''
  selectedLocation: any = ''
  departments: any = ''
  selectedDepartments: any = ''
  dateRange: any = ''

  showFilterPanel = false
  csvcols = []
  exportColumns: any = ''

  selectedGroupby: any = ''
  locationIds: string[] = [];
  catIds: string[] = [];
  groupby: string = 'Location';

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this._globals.componentName = 'Transfer Detail Report'

    if(!this._abd.locations || this._abd.locations.length<2) {

    }

    try {
      this._filterService.selectedLocation = this._abd.locations[0];
      this._filterService.selectedExtra1Location = this._abd.locations[1];
      this._filterService.selectedTranferType = this._abd.transferType[0];
      this._filterService.selectedTranferStatus = this._abd.transferStatus[0];
    } catch { }

    if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
      this._filterService.initDates()

    this._abd.initDates()
    this.getDataList()
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    if (!this._filterService.selectedProductSellTypeFilter)
      this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    const data = {
      "ReportName": 'TransferDetailReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "SenderLocationIds": [this._filterService.selectedLocation.locationId.toString()],
        "ReceiverLocationIds": [this._filterService.selectedExtra1Location.locationId.toString()],
        "TransferDetailsStatus": this._filterService.selectedTranferStatus.value,
        "TransfersStatusCheck": this._filterService.selectedTranferType.value,
        'OrderPlacement': this._filterService.selectedProductSellTypeFilter?.Value
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if (x.isOnline) {
          x.isOnline = 'Yes'
        } else if (!x.isOnline) {
          x.isOnline = 'No'
        }
      })
      this._abd.hideSpinner();
    })
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
      this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Transfer Detail Report', true)
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Transfer Detail Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      let total = "0";
      if (col.includes("unitPrice") || col.includes("totalPrice") || col.includes("grossProfit")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else if (col.includes("totalTransferredQty")) {
        return this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(0);
      }
      else {
        return "";
      }

      //total = this.dataList.reduce((sum, item) => sum + item[col], 0).toFixed(2);
    }
    return total;

  }

}
