<form [formGroup]="editform" autocomplete="off">
    <div class="grid">
        <div class="col">
            <label class="control-label">
                <strong
                    >Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <app-text-input
                formControlName="postalCode"
                [label]=""
                [name]="'zip code'"
                (input)="zipInputEvent($event.target.value)"
            ></app-text-input>
        </div>

        <div class="col">
            <label class="control-label">
                <strong
                    >City
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <span class="p-input-icon-right" [style]="{ width: '100%' }">
                <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                <app-text-input
                    formControlName="city"
                    [label]="''"
                    (input)="cityInputEvent($event.target.value)"
                ></app-text-input>
            </span>
        </div>

        <div class="col">
            <label class="control-label">
                <strong
                    >State
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <p-dropdown
                [style]="{
                    width: '100%',
                    'min-width': '200px',
                    'margin-top': '7px'
                }"
                class="p-dropdown-1"
                appendTo="body"
                [options]="usaStates"
                [(ngModel)]="_abd.zipcodeCityStateObj.stateObj"
                optionLabel="name"
                [filter]="true"
                [disabled]="enable"
                filterBy="name"
                [ngModelOptions]="{ standalone: true }"
            ></p-dropdown>
        </div>
    </div>
</form>
