<p-overlayPanel
    #opActions
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="optionEditClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                class="p-button-danger p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionEditClicked()"
            ></button>
            <!-- <button
                pButton
                pRipple
                type="button"
                label="Change Status"
                class="p-button-danger p-button-text"
                icon="pi pi-sort"
                iconPos="right"
                (click)="changeStatusClicked()"
            ></button> -->
        </div>
    </ng-template>
</p-overlayPanel>
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'purchase-orders-receive-list'"
        [statusList]="_purchaseService.purchaseStatusList"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    >
    </app-report-top-filter-bar>
    <br />

    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Sr#</th>
                <th>Date</th>
                <th>PO#</th>
                <th>Location</th>
                <th>Vendor</th>
                <th class="text-center">Order Qty</th>
                <th class="text-center">Receive Qty</th>
                <th class="text-right">Sub Total</th>
                <th class="text-right">Discount</th>
                <th class="text-right">Net Cost</th>
                <th class="text-right">Total Tax</th>
                <th class="text-right">Total Cost</th>
                <th class="text-center">Status</th>
                <th>Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.srNo }}</td>
                <td>{{ item.dated | date : "mediumDate" }}</td>
                <td>{{ item.poNumber }}</td>
                <td>{{ item.locationName }}</td>
                <td>{{ item.vendorName }}</td>
                <td class="text-center">
                    {{ item.totalQty | number : ".0-0" }}
                </td>
                <td class="text-center">
                    {{ item.totalReceivedQty | number : ".0-0" }}
                </td>
                <td class="text-center">{{ item.subTotal | currency }}</td>
                <td class="text-right">{{ item.discountTotal | currency }}</td>
                <td class="text-right">{{ item.netTotal | currency }}</td>
                <td class="text-right">{{ item.taxTotal | currency }}</td>
                <td class="text-right">{{ item.grandTotal | currency }}</td>
                <td class="text-center">
                    <p-chip
                        [label]="
                            _purchaseService.getPurchaseStatusName(
                                item.poStatus
                            )
                        "
                        [styleClass]="
                            _purchaseService.getPoStatusClass(item.poStatus)
                        "
                    ></p-chip>
                </td>
                <td class="text-center">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="opActions.toggle($event)"
                        (click)="actionButtonClicked(item)"
                        [disabled]="
                            !(
                                item.poStatus == 'POSTED' ||
                                item.poStatus == 'PARTIAL'
                            )
                        "
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator
        #p
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
