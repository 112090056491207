<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="printingConfigurationform"
                    (ngSubmit)="printingConfigurationform.valid && saveFn()"
                    autocomplete="off"
                >

                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(printingConfigurationform.controls)['enableConfigurationForm']" inputId="rememberme8"></p-inputSwitch>
                        <label class="control-label" for="rememberme8"><strong> Enable Printing</strong></label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['receiptPrinting']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Enable Receipt Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['paymentPrinting']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Enable Electronic Payment Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['cashdropPrinting']"
                            inputId="rememberme3"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme3">Enable Cash Drop Printing</label>
                    </div>
                    <br/>

                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['payoutPrinting']"
                            inputId="rememberme4"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme4">Enable PayOut Printing</label>
                    </div>
                    <br/>
                    <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['barcodePrinting']"
                            inputId="rememberme6"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme6">Enable Barcode Printing On Receipt</label>

                    </div>
                    <div class="flex" style="list-style: none; margin: 20px;">
                        <li>
                            <p-image src="https://cloudstoragesvc.blob.core.windows.net/commonfiles/DefaultImages/SampleQRCode.png" alt="QR Code" ></p-image>
                        </li>
                        <li>
                            <p-image src="https://cloudstoragesvc.blob.core.windows.net/commonfiles/DefaultImages/SampleBarcode.png" alt="Barcode"></p-image>
                        </li>
                    </div>
                    <!-- <div class="field-checkbox">
                        <p-checkbox
                            [formControl]="$any(printingConfigurationform.controls)['voidItemPrinting']"
                            inputId="rememberme5"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme5">Show Void Item On Receipt</label>
                    </div> -->
                    <br/>

                    <div class="ml-6">
                        <h5>Footer Text</h5>
                        <textarea [formControl]="$any(printingConfigurationform.controls)['footerText']"
                         rows="5" cols="30" pInputTextarea></textarea><br/>
                         <span class="control-description text-muted"> Reboot of POS App required for Sync </span>
                    </div>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!printingConfigurationform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
