<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'coupon-summary'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    ></app-report-top-filter-bar>
    <br />
    <p-table
        #dt
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        *ngIf="dataList?.length > 0"
        [autoLayout]="true"
        responsiveLayout="scroll"
        [paginator]="false"
        [customSort]="true"
        (onLazyLoad)="customSort($event)"
        [lazy]="true"
        [rows]="rows"
        showLoader="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} discounts"
        [globalFilterFields]="['fullName']"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        selectionMode="single"
        >
        <!-- (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)" -->
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="text-align: center">Discount Date</th> -->
                <th pSortableColumn="channelName" style="text-align: center"> Channel Name <p-sortIcon field="channelName"></p-sortIcon></th>
                <th  style="text-align: center">Coupon Code </th>
                <th pSortableColumn="discountType" style="text-align: center">Discount type <p-sortIcon field="discountType"></p-sortIcon></th>
                <th style="text-align: center">Discount Value </th>
                <th style="text-align: center">Subscription plan</th>
                <th style="text-align: center">coupon used</th>
                <th style="text-align: center">Discount Amount</th>
                <!-- <th style="text-align: center">Promotion Status</th> -->
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-itemIndex="itemIndex" >
            <tr [pSelectableRow]="item" class="text-center">
                <!-- <td style="text-align: center">{{ item.discountDate }}</td> -->
                <td style="text-align: center">{{ item.channelName }}</td>
                <td style="text-align: center">{{ item.couponCode }}</td>
                <td style="text-align: center">{{ getDiscountType(item.discountType)}}</td>
                <td style="text-align: center">{{ getDiscountValue(item.discountValue,item.discountType)}}</td>
                <td style="text-align: center">{{ getSubscriptionPlanList(item.subscriptionPlan)}}</td>
                <td style="text-align: center">{{ item.couponUsed }}</td>
                <td style="text-align: center">{{ item.discountAmount | currency }}</td>
                <!-- <td style="text-align: center">
                    <span *ngIf="item.status == 0" class="disable">Disable</span>
                    <span *ngIf="item.status == 1" class="enable">Enable</span>
                </td> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-item>
            <tr *ngIf="dataList?.length > 0">
                <td  style="text-align: center">
                    <strong>Total</strong>
                </td>
                  <td></td>  <td></td>  <td></td>  <td></td>
                <td style="text-align: center">
                    {{calculateSummaryTotal('couponUsed')}}
                </td>
                <td style="text-align: center">
                    {{calculateSummaryTotal('discountAmount')}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
</div>

<p-dialog
    *ngIf="salesChannelServices.showEditChannel"
    header="Edit Channel"
    [(visible)]="salesChannelServices.showEditChannel"
    [style]="{ width: '40%' }"
    modal="true"
>
    <app-bizz-sales-channel-form
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-bizz-sales-channel-form>
</p-dialog>
<ng-container *ngIf="salesChannelServices.showNewEditChannel">
    <p-dialog
        header="Add a New Channel"
        [(visible)]="salesChannelServices.showNewEditChannel"
        [style]="{ width: '40%' }"
        modal="true"
    >
        <app-bizz-sales-channel-form
            [singupBusinessConfig]="true"
            [isNew]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-bizz-sales-channel-form>
    </p-dialog>
</ng-container>
