import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-po-closed-for-receiving',
  templateUrl: './po-closed-for-receiving.component.html',
  styleUrls: ['./po-closed-for-receiving.component.scss']
})
export class PoClosedForReceivingComponent implements OnInit, AfterViewInit {
  @ViewChild('inputFieldInVoiceNumber', { static: false }) inputFieldInVoiceNumber: ElementRef;

  isDataLoaded = false
  locations: any = null
  vendors: any = null

  dataList: any = []
  histList: any = []
  activeItem: any = null

  invoiceNo = ''
  shipping = 0
  comments1 = ''
  selectedOptions: any

  subTotalSum = 0
  sumSubTotal = 0
  sumDiscount = 0
  sumTax = 0
  grandTotal = 0

  showReceiveInvoice = false
  receiveData: any

  divideShipping: any = null
  isDivide: boolean = false
  selectedOptions1: boolean = false
  selectedOptions2: boolean = false

  actionsHist: MenuItem[]
  histSelectedRow: any

  isReadOnly = false
  totalQuantity: number = 0
  quantityBasedShipping: number = 0;
  divideShippingCostInput: boolean = false;

  _globals = GlobalService
  @ViewChild('histmenu') histMenu: OverlayPanel

  isFooterNoteEnabled: boolean = false;
  footerNotesText: string = '';

  constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _bs: BaseService,
    private _router: Router,
  ) { }
  ngAfterViewInit(): void {
    //this.inputFieldInVoiceNumber.nativeElement.focus();
    //throw new Error('Method not implemented.');
  }

  ngOnInit(): void {

    this.locations = this._abd.initActLocations()
    this.vendors = this._abd.initActVendors()

    if (!this.locations || !this.vendors) {
      this._messageService.add({ severity: 'error', summary: '[S-12.85.68] Missing basic data!' })
      window.history.back()
    }

    if (!this.selectedOptions || this.selectedOptions.length < 1) {
      this.selectedOptions = ['valA']
    }

    if (this._purchaseService.newEditItem.POStatus == 'CLOSEDFORRECEIVING') {
      this.isReadOnly = true
      this._purchaseService.newEditItem.POStatus = 'CLOSEDFORRECEIVING'
    }
    this.initData()
  }

  getFooterNotesConfigValue() {
    try {
      this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
        if (r.success) {
          this.isFooterNoteEnabled = r.data.po_enablefooternote;
          this.footerNotesText = r.data.po_footernote
        }
        else {
          this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
        }
      }, error => {
        this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
      })
    } catch (error) {

    }
  }

  postToInvoice() {
    const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
    const url = `${environment.InventoryBaseURL}ReceivePOTxn/${hr.id}`

    this._bs.httpPut(url).subscribe(res => {
      if (res.success) {
        this.initData();
        this._messageService.add({ severity: 'success', summary: 'Posted to Inventory operation was successful!' })
      }
      else {
        this._messageService.add({ severity: 'error', summary: 'Transaction is already posted!' })
      }
    })
  }

  viewReceiveInventory() {
    this.receiveData = ''
    const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
    const url = `${environment.InventoryBaseURL}ReceivePO/${hr.poRecivingId}`
    this._bs.httpGet(url).subscribe(res => {
      if (res.success) {
        this.receiveData = res.data

        this.receiveData.createdDate = new Date(this.receiveData.createdDate)
        this.receiveData.receivedDate = new Date(this.receiveData.receivedDate)
        this.showReceiveInvoice = true
      }
    })
  }

  initData() {

    // this._spinner.show()
    this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id, true).subscribe(res => {
      if (res.success) {
        this.isDataLoaded = true
        this.divideShippingCostInput = true;

        this.activeItem = res.data
        let item = this.activeItem
        this._purchaseService.activeItemFull = item

        this.comments1 = this.activeItem.poNote

        if (this.activeItem)
          this.activeItem.notes = this._purchaseService.activeItemFull.notes
        else
          this.activeItem = {
            notes: this._purchaseService.activeItemFull.notes
          }
        this.dataList = []
        this.dataList = JSON.parse(JSON.stringify(res.data.rpoItems))
        this.histList = JSON.parse(JSON.stringify(res.data.txnItems))
        this.shipping = this.activeItem.shipping
        item.receiveNo = this._purchaseService.generatePoNumber()
        item.receiveBy = this._globals.userInfo.displayName
        item.receiveDate = new Date()
        item.dated = new Date(this.activeItem.dated)
        this._purchaseService.newEditItem.locationObj = this.locations.find(f => f.locationId == item.locationId)
        this.activeItem.vendorObj= this.vendors.find(f => f.id == this.activeItem.vendorId)
        item.locationObj = this.locations.find(f => f.locationId == item.locationId)

        // console.log('this.dataList', this.dataList)
        // console.log('_purchaseService.activeItemFull', this._purchaseService.activeItemFull)

        let itemfull = this._purchaseService.activeItemFull.rpoItems
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
          this.dataList[i].receivedQuantity = this.dataList[i].dueQuantity
          this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
          this.dataList[i].remainingQty = this.dataList[i].remainingQty < 0 ? 0 : this.dataList[i].remainingQty

          for (let j = 0; j < itemfull.length; j++) {
            if (this.dataList[i].id == itemfull[j].id) {
              this.dataList[i].discountPercent = itemfull[j].discountPercent
              this.dataList[i].taxRate = itemfull[j].taxRate

              this.calculateTotals()
              break
            }
          }
        }
        this.calculateTotals()
      } else {

      }
    })

    this.getFooterNotesConfigValue();
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  discountChanged(e, item) {
    try {
      item.discountPercent = e.value
      if (!item.discountPercent) item.discountPercent = 0
    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  taxChanged(e, item) {
    try {
      item.taxRate = e.value
      if (!item.taxRate) item.taxRate = 0
    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  shippingChanged(e) {
    try {
      this.shipping = e.value
    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  receivedQtyChanged(e, item) {
    try {
      // debugger;
      item.receivedQuantity = e.value
      // if (!item.receivedQuantity) item.receivedQuantity = 0
      if (item.receivedQuantity > item.dueQuantity) {
        item.receivedQuantity = item.dueQuantity
      }
      e.value = item.dueQuantity
    }
    catch {
      item.receivedQuantity = item.dueQuantity
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  onChangeValA(e) {
    this.calculateTotals()
  }
  shippingOptionButtonClicked() {
    if(!this.divideShippingCostInput){
        this.dataList.map(f=> f.shipping = 0)
    }
    this.calculateTotals()
  }

  isDivideShipping() {
    const found = this.selectedOptions.find(f => f == 'valA')
    if (found) return true
    else return false
  }

  calculateTotals() {
    let tmp = this.dataList.filter(f => f.receivedQuantity > 0)
    this.sumSubTotal = 0
    this.sumDiscount = 0
    this.sumTax = 0
    this.grandTotal = 0
    let tmpNetCost = 0
    this.totalQuantity = this.dataList.reduce((a, b) => a + b.receivedQuantity, 0)
    this.quantityBasedShipping = (parseFloat(parseFloat(this.shipping.toString()).toFixed(2)) / this.totalQuantity)

    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].srNo = i + 1
      this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
      if (this.dataList[i].receivedQuantity > this.dataList[i].dueQuantity) {
        this.dataList[i].receivedQuantity = this.dataList[i].dueQuantity
        this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
      }

      if (this.dataList[i].remainingQty < 0) {
        this.dataList[i].receivedQuantity = this.dataList[i].dueQuantity
        this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
      }
    }



    let tmp1 = 0

    let dueSum = 0
    this.subTotalSum = 0
    for (let i = 0; i < this.dataList.length; i++) {
      let dr = this.dataList[i]
     let sc = 0
     if(this.divideShippingCostInput){

        sc = this.dataList[i].receivedQuantity * this.quantityBasedShipping
        sc = parseFloat(parseFloat(sc.toString()).toFixed(2))
        dr.shipping = sc
       // dr.totalCost += sc
     }
     else {
        sc=dr.shipping
     }
      let pc = (dr.costPrice * dr.receivedQuantity)

      let disc = 0
      if (dr.discountPercent > 0) disc = pc * (dr.discountPercent / 100)
      dr.discountAmount = disc

      let tax = 0
      if (dr.taxRate > 0) tax = (pc - disc) * (dr.taxRate / 100)
      dr.taxAmount = tax

      this.sumDiscount += dr.discountAmount
      this.sumTax += dr.taxAmount

      dr.netTotal = pc - dr.discountAmount
      dr.totalCost = pc - disc + tax + sc

      this.subTotalSum += pc

    }

    if(!this.divideShippingCostInput){
        this.shipping =this.dataList.reduce((a,b)=> a+ b.shipping,0)
    }
    this.grandTotal = this.subTotalSum - this.sumDiscount + this.sumTax
    this.grandTotal += this.shipping

    if (dueSum == 0) {
      const c = this.selectedOptions
      const f = c.find(f => f == 'valB')
      if (!f) {
        this.selectedOptions.push('valB')
      }
    }


  }

  onHistRowSelect(e) {
    this.histSelectedRow = e.DataViewModule
  }

  onHistRowUnselect(e) { }

  histRowClicked(e, item) {
    this.histMenu.toggle(e)
    this.histSelectedRow = item
    if (item.inventoryCommitStatus!="POSTED") {
      this.actionsHist = [
        {
          label: 'Post To Inventory',
          icon: 'pi pi-window-maximize',
          command: () => { this.postToInvoice(); }
        },
        {
          label: 'View Receive Invoice',
          icon: 'pi pi-fw pi-eye',
          command: () => { this.viewReceiveInventory(); }
        },
      ]
    }
    else {

      this.actionsHist = [

        {
          label: 'View Receive Invoice',
          icon: 'pi pi-fw pi-eye',
          command: () => { this.viewReceiveInventory(); }
        },
      ]

    }
  }

  handleOnClickIsDivide($event) {
    //debugger;
  }

  cancelButtonClick() {
    this._confirmationService.confirm({
      header: `Cancel changes?`,
      message: 'Changes you made will be lost. Are you sure you want to proceed?',
      accept: () => {
        try {
          window.history.back()
        } catch { this._abd.hideSpinner() }
      },
    });
  }

  receivePoClicked() {

    let anyDueQuantityFound = false;
    this.dataList.map(function (value) {

      if ((value.dueQuantity - value.receivedQuantity) > 0) {
        anyDueQuantityFound = true
      }
    });


    if (this.selectedOptions1 && anyDueQuantityFound) {
      this.verifyMark();
    }

    else {
      this._confirmationService.confirm({
        header: `Save changes?`,
        message: 'Are you sure you want to save changes?',
        accept: () => {
          try {
            this.receivePo()
          } catch { this._spinner.hide() }
        },
      });
    }
  }

  receivePo() {
    let found = this.selectedOptions.filter(f => f == 'valB')
    let itemsSelected = this.dataList.filter(f => f.receivedQuantity > 0)

    if (this.histList?.length < 1 && itemsSelected?.length < 1) {
      this._messageService.add({ severity: 'error', summary: 'Nothing to save!' })
      return
    }

    if (!this.invoiceNo && itemsSelected) {
      this._messageService.add({ severity: 'error', summary: 'Invalid invoice number!' })
      return
    }

    let isValidDate = Date.parse(this.activeItem.receiveDate);
    if (isNaN(isValidDate) && itemsSelected) {
      this._messageService.add({ severity: 'error', summary: 'Invalid receiving date!' })
      return
    }
    let markPo = false
    let postToInventory = false
    if (this.selectedOptions) {
      let tmp = this.selectedOptions.filter(f => f == 'valB')
      if (tmp.length) markPo = true

      tmp = this.selectedOptions.filter(f => f == 'valC')
      if (tmp.length) postToInventory = true
    }

    let items = []

    for (let i = 0; i < itemsSelected.length; i++) {
      const obj = {
        "itemSKUId": itemsSelected[i].itemSKUId,
        "notes": "",
        "orderedQuantity": itemsSelected[i].orderedQuantity,
        "dueQuantity": itemsSelected[i].dueQuantity,
        "receivedQuantity": itemsSelected[i].receivedQuantity,
        "returnQuantity": itemsSelected[i].returnQuantity,
        "committedQuantity": 0,
        "costPrice": itemsSelected[i].costPrice,
        "priceA": itemsSelected[i].priceA,
        "priceB": itemsSelected[i].priceB,
        "priceC": itemsSelected[i].priceC,
        "subTotal": itemsSelected[i].netTotal,
        "netTotal": itemsSelected[i].netTotal,
        "discountPercent": itemsSelected[i].discountPercent,
        "discountAmount": itemsSelected[i].discountAmount,
        "taxRate": itemsSelected[i].taxRate,
        "taxAmount": itemsSelected[i].taxAmount,
        "Shipping":  itemsSelected[i].shipping,
        "totalAmount": itemsSelected[i].totalCost,
        "updatedById": this._globals.userInfo.userID,
        "updatedBy": this._globals.userInfo.displayName,
      }
      items.push(obj)
    }
    let data = {
      "invoiceNumber": this.invoiceNo,
      "notes": this.activeItem.notes,
      "poStatus": this.selectedOptions1 ? 'CLOSEDFORRECEIVING' : "OPEN",
      "subTotal": this.sumSubTotal,
      "discountTotal": this.sumDiscount,
      "taxTotal": this.sumTax,
      "shipping": this.shipping,
      "grandTotal": this.grandTotal,
      "receivedDate": formatDate(this.activeItem.receiveDate, 'MM/dd/yyyy', 'en-US'),
      "receivedById": this._globals.userInfo.userID,
      "receivedBy": this._globals.userInfo.displayName,
      "purchaseOrderId": this.activeItem.id,
      "DividedShippingItems": this.divideShippingCostInput,
      "RPOItems": items,
      "FooterNotes": this.isFooterNoteEnabled ? this.footerNotesText : ''
    }

    try {
      this._spinner.show()
      this._purchaseService.purchaseOrderReceive(data, postToInventory).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._messageService.add({ severity: 'success', summary: 'Operation completed successfully!' })
          this._router.navigate(['app/purchase-orders'])
        }
      })
    } catch { this._spinner.hide() }
  }

  handleDivideRadio(e) {
    this.isDivide = !this.isDivide
  }

  verifyMark() {
    this._confirmationService.confirm({
      header: `Mark PO as Received`,
      message: 'You have selected to "mark PO as Received", It will close PO for further receiving while you still have quantity to receive, Do you wish the save and close PO?',
      accept: () => {
        try {
          this.receivePo()
        } catch { }
      },
      reject: () => {
        try {
          this.selectedOptions1 = false;
        } catch { }
      },
    });
  }

  itemShippingChanged(e, item) {
    item.shipping = e.value
    this.calculateTotals()
  }
  commitAllInventory() {
    this._confirmationService.confirm({
      message: 'Are you sure you want to Post this Purchase Order?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        try {
          this.optionCommitClicked()
        } catch { }
      },
      reject: () => { }
    })
  }
  optionCommitClicked() {
    try {
      let data = {
        "poStatus": this._purchaseService.newEditItem.POStatus,
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()

      this._purchaseService.saveReceivedPurchaseOrder(this._purchaseService.newEditItem.Id, true, data).subscribe(res => {

        if (res.success) {

          this.initData();
          this._spinner.hide()
          this._messageService.add({ severity: 'success', summary: 'Purchase Order processed successfully!' })
          //   this.viewReceiveInventory()
        }
      })

    } catch { this._abd.hideSpinner() }
  }

}
