<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'cash-detail-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>
    <br />
    <div class="grid summary">
        <div class="col" *ngFor="let tag of topTags">
            <div class="conn-card" [style]="getOverShortColor(tag)">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <label class="widget-title">
                            {{ tag.title }}
                        </label>
                        <label class="widiget-figure-main">
                            {{ calculateSummaryTotal(tag.id) | currency }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngFor="let table of tables">
        <br />
        <br />
        <div
            class="font-bold text-3xl ml-2 text-center"
            [style]="{ color: 'var(--brandColor)' }"
        >
            {{ table.title }}
        </div>
        <br />

        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="table.data"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Location</th>
                    <th>Cashier</th>
                    <th>Register</th>
                    <th *ngIf="table.title == 'Till'">Till Number</th>
                    <th *ngIf="table.title == 'Pay-In'">Amount Added</th>
                    <th *ngIf="table.title == 'Pay-Out'">Amount Removed</th>
                    <th *ngIf="table.title == 'Till'">Amount Added</th>
                    <th *ngIf="table.title == 'Till'">Amount Removed</th>
                    <th>Date-Time</th>
                    <!-- <th>Reason</th> -->
                    <!-- <th class="text-right">Total</th> -->
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.cashierName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td *ngIf="table.title == 'Till'">{{ item.tillNumber }}</td>
                    <td *ngIf="table.title == 'Pay-Out'">{{ item.total }}</td>
                    <td *ngIf="table.title == 'Pay-In'">{{ item.total }}</td>
                    <td *ngIf="table.title == 'Till'">{{ item.addedAmount }}</td>
                    <td *ngIf="table.title == 'Till'">{{ item.removedAmount }}</td>
                    <td>{{ item.date | date : "short" }}</td>
                    <!-- <td>{{ item.reason }}</td> -->
                    <!-- <td class="text-right">{{ item.total | currency }}</td> -->
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="6" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer" *ngIf="table.data?.length > 0">
                <tr class="summary-line" *ngIf="table.title == 'Till'">
                    <td
                        colspan="4"
                        style="text-align: left"
                        class="text-400 font-medium text-xl select-none"
                    >
                        Total:
                    </td>
                    <td
                        style="text-align: left"
                        class="text-500 font-medium text-xl select-none"
                    >
                        {{ addedAmount | currency }}
                    </td>
                    <td
                        style="text-align: left"
                        class="text-500 font-medium text-xl select-none"
                    >
                        {{ removedAmount | currency }}
                    </td>
                    <td></td>
                </tr>

                <tr class="summary-line" *ngIf="table.title !== 'Till'">
                    <td
                        colspan="3"
                        style="text-align: left"
                        class="text-400 font-medium text-xl select-none"
                    >
                        Total:
                    </td>
                    <td
                        style="text-align: left"
                        class="text-500 font-medium text-xl select-none"
                    >
                        {{ table.total | currency }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <br />
    <br />
    <div
        class="font-bold text-3xl ml-2 text-center"
        [style]="{ color: 'var(--brandColor)' }"
    >
        Cash Drop
    </div>
    <br />
    <div class="row">
        <p-table
            #dt1
            [columns]=""
            styleClass="p-datatable-striped"
            id="table-001"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="cashDrops"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Location</th>
                    <th>Cashier Name</th>
                    <th>Reg</th>
                    <th>Date/Time</th>
                    <th class="text-center">$100</th>
                    <th class="text-center">$50</th>
                    <th class="text-center">$20</th>
                    <th class="text-center">$10</th>
                    <th class="text-center">$5</th>
                    <th class="text-center">$2</th>
                    <th class="text-center">$1</th>
                    <th class="text-center">25 cents (Quarters)</th>
                    <th class="text-center">10 cents (Dimes)</th>
                    <th class="text-center">5 cents (Nickels)</th>
                    <th class="text-center">1 cents (Penny)</th>
                    <th class="text-right">Total</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.locationName }}</td>
                    <td>{{ item.cashierName }}</td>
                    <td>{{ item.registerName }}</td>
                    <td>{{ item.date | date : "short" }}</td>
                    <td class="text-center">{{ item.dollar100Qty }}</td>
                    <td class="text-center">{{ item.dollar50Qty }}</td>
                    <td class="text-center">{{ item.dollar20Qty }}</td>
                    <td class="text-center">{{ item.dollar10Qty }}</td>
                    <td class="text-center">{{ item.dollar5Qty }}</td>
                    <td class="text-center">{{ item.dollar2Qty }}</td>
                    <td class="text-center">{{ item.dollar1Qty }}</td>
                    <td class="text-center">{{ item.cent25Qty }}</td>
                    <td class="text-center">{{ item.cent10Qty }}</td>
                    <td class="text-center">{{ item.cent5Qty }}</td>
                    <td class="text-center">{{ item.cent1Qty }}</td>
                    <td class="text-right">{{ item.total | currency }}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="16" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line" *ngIf="showCashDropsSummary()">
                    <td
                        colspan="15"
                        style="text-align: right"
                        class="text-400 font-medium text-xl select-none"
                    >
                        Total:
                    </td>
                    <td
                        style="text-align: right"
                        class="text-500 font-medium text-xl select-none"
                    >
                        {{ cashDropTotal | currency }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
