export interface PlanInformation{
    id: number;
    feature_title: string;
    lite_plan_info: string;
    standard_plan_info: string;
    plus_plan_info: string;
    premium_plan_info: string;
}

export let PlanInformationList: PlanInformation[] = [
    {
        id: 1,
        feature_title: "Locations",
        lite_plan_info: "1",
        standard_plan_info: "1+",
        plus_plan_info: "1+",
        premium_plan_info: "1+"
    },
    {
        id: 2,
        feature_title: "Registers",
        lite_plan_info: "1",
        standard_plan_info: "1+",
        plus_plan_info: "1+",
        premium_plan_info: "1+"
    },
    {
        id: 3,
        feature_title: "Users",
        lite_plan_info: "1",
        standard_plan_info: "1+",
        plus_plan_info: "1+",
        premium_plan_info: "1+"
    },
    {
        id: 4,
        feature_title: "Inventory",
        lite_plan_info: "1",
        standard_plan_info: "1+",
        plus_plan_info: "1+",
        premium_plan_info: "1+"
    },
]
