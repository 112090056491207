<!-- <div class="conn-grid-2x"> -->
<div>
    <div>
        <div>
            <label class="control-label"
                ><strong
                    >Department Name
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label
            >
            <br />
            <form
                [formGroup]="editform"
                (ngSubmit)="editform.valid && saveFn()"
                autocomplete="off"
            >
                <app-text-input
                    [formControl]="$any(editform.controls)['departmentName']"
                    [label]="'Department Name'"
                    [name]="'Department Name'"
                    (input)="
                        _departmentService.activeItem.departmentName =
                            $event.target.value
                    "
                    [description]="
                        'Set the name of department, it must be unique'
                    "
                ></app-text-input>

                <div
                    class="flex justify-content-end flex-wrap card-container green-container mt-3"
                >
                    <div class="flex align-items-center justify-content-center">
                        <button
                            *ngIf="this.whatAction == 'Edit'"
                            pButton
                            pRipple
                            type="button"
                            (click)="saveFn()"
                            [label]="whatAction"
                            [style]="{'width':'80px'}"
                        ></button>
                        <button
                            *ngIf="this.whatAction == 'Save'"
                            pButton
                            pRipple
                            type="submit"
                            label="Save"
                            [disabled]="
                                !editform.valid || _departmentService.isViewOnly
                            "
                            [style]="{'width':'80px'}"
                        ></button>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center ml-2"
                    >
                        <button
                            pButton
                            pRipple
                            type="button"
                            label="Cancel"
                            class="p-button-outlined p-button-danger"
                            (click)="cancelButtonClick()"
                            [style]="{'width':'80px'}"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- <div>
        <p-table
            #dt
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="_abd.actDepartments"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Department</th>
                    <th style="text-align: center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr class="text-center">
                    <td>
                        {{ item.departmentName }}
                    </td>
                    <td style="text-align: center">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-help p-button-text"
                            (click)="onSelectItem(item)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div> -->
</div>
