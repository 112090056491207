import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-invoice-payment-address',
  templateUrl: './invoice-payment-address.component.html',
  styleUrls: ['./invoice-payment-address.component.scss']
})
export class InvoicePaymentAddressComponent implements OnInit {
item:any=null
  constructor(public _reportService: ReportsService,) { }

  ngOnInit(): void {
    this.item = this._reportService.selectedInvoiceDetails.invoiceBilling[0]
  }

}
