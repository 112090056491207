<div class="margin business-other-container">
    <form [formGroup]="businessDetail" (ngSubmit)="saveForm()" autocomplete="off">
        <div>
            <h4>Business Details</h4>
            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-6 field">
                    <label htmlfor="ownerShipType">Ownership Type
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="ownershipTypeList" optionLabel="viewValue" formControlName="ownerShipType"
                        id="ownerShipType" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" [filter]="true" filterBy="view" required [showClear]="false"
                        placeholder="Select a ownership type">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">The type of ownership of the business</small>
                    <div *ngIf="
                            (!businessDetail.get('ownerShipType')?.valid &&
                                (businessDetail.get('ownerShipType')?.dirty ||
                                    businessDetail.get('ownerShipType')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('ownerShipType').value
                                    ?.length == 0 &&
                                !businessDetail.get('ownerShipType').errors
                                    .required
                            ">
                            Please select a Ownership type
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="businessIncorporate">Business Incorporated
                        <span class="text-red-500">*</span></label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" pInputText required
                        id="businessIncorporate" formControlName="businessIncorporate" />
                    <small id="username1-help" class="block text-muted">The state where your business is located</small>
                    <div *ngIf="
                            (!businessDetail.get('businessIncorporate')
                                ?.valid &&
                                (businessDetail.get('businessIncorporate')
                                    ?.dirty ||
                                    businessDetail.get('businessIncorporate')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('businessIncorporate').value
                                    ?.length == 0 &&
                                !businessDetail.get('businessIncorporate')
                                    .errors.required
                            ">
                            Please enter a Business incorporated type
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="federalTaxId">Federal Tax ID Number
                        <span class="text-red-500">*</span></label>
                    <div class="p-float-label p-inputgroup align-items-center">
                        <p-inputMask formControlName="federalTaxId" id="federalTaxId" class="p-inputtext-lg my-1 w-full"
                            placeholder="xx-xxxxxxx" mask="99-9999999" required>
                        </p-inputMask>
                    </div>
                    <small id="username1-help" class="block text-muted">Your federal tax id number here</small>
                    <div *ngIf="
                            (!businessDetail.get('federalTaxId')?.valid &&
                                (businessDetail.get('federalTaxId')?.dirty ||
                                    businessDetail.get('federalTaxId')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('federalTaxId').value
                                    ?.length == 0 &&
                                !businessDetail.get('federalTaxId').errors
                                    .required
                            ">
                            Please enter a Federal tax ID number
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="typeOfBusiness">Type of Business
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="businessTypeList" optionLabel="viewValue" formControlName="typeOfBusiness"
                        id="typeOfBusiness" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" [filter]="true" filterBy="view"
                        placeholder="Select a business type">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Merchant’s method of conducting business for
                        pricing
                        categories</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="taxTypeId">Tax ID Type <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="taxTypeIdList" optionLabel="viewValue" formControlName="taxTypeId"
                        id="taxTypeId" required optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">The type of ownership of the business</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="lengthOgOwnership">Length of Ownership
                        <span class="text-red-500">*</span></label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1"
                        formControlName="lengthOgOwnership" id="lengthOgOwnership" placeholder="10 Years" pInputText
                        required />
                    <small id="username1-help" class="block text-muted">
                        Length of time the merchant has owned the
                        business</small>
                    <div *ngIf="
                            (!businessDetail.get('lengthOgOwnership')?.valid &&
                                (businessDetail.get('lengthOgOwnership')
                                    ?.dirty ||
                                    businessDetail.get('lengthOgOwnership')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('lengthOgOwnership').value
                                    ?.length == 0 &&
                                !businessDetail.get('lengthOgOwnership').errors
                                    .required
                            ">
                            Please enter a Length of ownership
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="numberOfLocations">Number of Locations
                        <span class="text-red-500">*</span></label>
                    <p-inputNumber class="p-component p-element p-filled" formControlName="numberOfLocations"
                        id="numberOfLocations" id="numberOfLocations" required [min]="0"></p-inputNumber>
                    <small id="username1-help" class="block text-muted">Number of locations merchant has processing
                        through
                        Secure Bancard</small>
                    <div *ngIf="
                            (!businessDetail.get('numberOfLocations')?.valid &&
                                (businessDetail.get('numberOfLocations')
                                    ?.dirty ||
                                    businessDetail.get('numberOfLocations')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('numberOfLocations').value
                                    ?.length == 0 &&
                                !businessDetail.get('numberOfLocations').errors
                                    .required
                            ">
                            Please enter a Number of locations
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="businessFax">Business Fax</label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" pInputText
                        formControlName="businessFax" id="businessFax" />
                    <small id="username1-help" class="block text-muted">Corporate fax number</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="businessPhone">Phone <span class="text-red-500">*</span></label>
                    <div class="p-inputgroup align-items-center">
                        <span class="p-inputgroup-addon">
                            <img _ngcontent-ewi-c77="" src="assets/images/us-flag-icon.png" alt="USA Flag Icon"
                                width="19" />
                        </span>
                        <span class="p-float-label">
                            <p-inputMask mask="(999) 999-9999" formControlName="businessPhone"
                                class="p-inputtext-lg my-1 w-full" required>
                            </p-inputMask>
                        </span>
                    </div>
                    <small id="username1-help" class="block text-muted">Corporate phone number</small>
                    <div *ngIf="
                            (!businessDetail.get('businessPhone')?.valid &&
                                (businessDetail.get('businessPhone')?.dirty ||
                                    businessDetail.get('businessPhone')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('businessPhone').value
                                    ?.length == 0 &&
                                !businessDetail.get('businessPhone').errors
                                    .required
                            ">
                            Please enter a Business phone
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-12 field">
                    <label htmlfor="mailingAddress">Mailing Address
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="mailingAddress" id="mailingAddress"
                        class="p-inputtext p-component p-element p-filled mb-1" required pInputText />
                    <small id="username1-help" class="block text-muted">Corporate address here</small>
                    <div *ngIf="
                            (!businessDetail.get('mailingAddress')?.valid &&
                                (businessDetail.get('mailingAddress')?.dirty ||
                                    businessDetail.get('mailingAddress')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('mailingAddress').value
                                    ?.length == 0 &&
                                !businessDetail.get('mailingAddress').errors
                                    .required
                            ">
                            Please enter a Business Address
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="mailingZipCode">Mailing Zip Code
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="mailingZipCode" id="mailingZipCode"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required
                        (keyup)="getZipCode(1)" />
                    <small id="username1-help" class="block text-muted">Corporate address zip code here</small>
                    <div *ngIf="
                            (!businessDetail.get('mailingZipCode')?.valid &&
                                (businessDetail.get('mailingZipCode')?.dirty ||
                                    businessDetail.get('mailingZipCode')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('mailingZipCode').value
                                    ?.length == 0 &&
                                !businessDetail.get('mailingZipCode').errors
                                    .required
                            ">
                            Please enter a Zip code
                        </div>
                    </div>
                    <div *ngIf="isMailingZipCodeDataLoaded">
                        <i class="pi pi-spin pi-spinner"></i>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="mailingCity">Mailing City <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="mailingCity" id="mailingCity"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">City of corporate address here</small>
                    <div *ngIf="
                            (!businessDetail.get('mailingCity')?.valid &&
                                (businessDetail.get('mailingCity')?.dirty ||
                                    businessDetail.get('mailingCity')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('mailingCity').value
                                    ?.length == 0 &&
                                !businessDetail.get('mailingCity').errors
                                    .required
                            ">
                            Please enter a City
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="mailingState">Mailing State
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="usaStates" optionLabel="name" formControlName="mailingState"
                        id="mailingState" optionValue="name" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" [filter]="true" filterBy="name" placeholder="Select State"
                        required>
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">State of corporate address here</small>
                    <div *ngIf="
                            (!businessDetail.get('mailingState')?.valid &&
                                (businessDetail.get('mailingState')?.dirty ||
                                    businessDetail.get('mailingState')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('mailingState').value
                                    ?.length == 0 &&
                                !businessDetail.get('mailingState').errors
                                    .required
                            ">
                            Please enter a State
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="emailAddress">Email Address</label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1"
                        formControlName="emailAddress" id="emailAddress" pInputText />
                    <small id="username1-help" class="block text-muted">Email address here for MOTO/ internet merchants
                    </small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="url">URL</label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" formControlName="url"
                        id="url" pInputText />
                    <small id="username1-help" class="block text-muted">Merchant’s URL required for Internet
                        merchants</small>
                </div>
                <br />
                <br />
                <div class="col-12 md:col-6 field">
                    <label htmlfor="contactFirstName">Contact First Name
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="contactFirstName" id="contactFirstName"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">First name of the contact person at the DBA
                        location</small>
                    <div *ngIf="
                            (!businessDetail.get('contactFirstName')?.valid &&
                                (businessDetail.get('contactFirstName')
                                    ?.dirty ||
                                    businessDetail.get('contactFirstName')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('contactFirstName').value
                                    ?.length == 0 &&
                                !businessDetail.get('contactFirstName').errors
                                    .required
                            ">
                            Please enter a Contact First Name
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="contactLastName">Contact Last Name
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="contactLastName" id="contactLastName"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Last name of the contact person at the DBA
                        location</small>
                    <div *ngIf="
                            (!businessDetail.get('contactLastName')?.valid &&
                                (businessDetail.get('contactLastName')?.dirty ||
                                    businessDetail.get('contactLastName')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('contactLastName').value
                                    ?.length == 0 &&
                                !businessDetail.get('contactLastName').errors
                                    .required
                            ">
                            Please enter a Contact Last Name
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="contactPhone">Phone <span class="text-red-500">*</span></label>
                    <div class="p-inputgroup align-items-center">
                        <span class="p-inputgroup-addon">
                            <img _ngcontent-ewi-c77="" src="assets/images/us-flag-icon.png" alt="USA Flag Icon"
                                width="19" />
                        </span>
                        <span class="p-float-label">
                            <p-inputMask mask="(999) 999-9999" formControlName="contactPhone" id="contactPhone"
                                class="p-inputtext-lg my-1 w-full" required>
                            </p-inputMask>
                        </span>
                    </div>
                    <small id="username1-help" class="block text-muted">Contact Person phone</small>
                    <div *ngIf="
                            (!businessDetail.get('contactPhone')?.valid &&
                                (businessDetail.get('contactPhone')?.dirty ||
                                    businessDetail.get('contactPhone')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('contactPhone').value
                                    ?.length == 0 &&
                                !businessDetail.get('contactPhone').errors
                                    .required
                            ">
                            Please enter a Contact phone
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="contactFax">Contact Fax</label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" pInputText
                        formControlName="contactFax" id="contactFax" />
                    <small id="username1-help" class="block text-muted">Fax number for DBA contact person</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Same as Mailing Address</label>
                    <p-dropdown [options]="sameAsInputList" optionLabel="viewValue"
                        formControlName="sameAsMailingAddress" id="sameAsMailingAddress" optionValue="view"
                        (onChange)="locationInfoFiller()" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Select if location address below is same as
                        mailing
                        address</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="locationCountry">Location Country</label>
                    <p-dropdown [options]="countryList" optionLabel="viewValue" formControlName="locationCountry"
                        id="locationCountry" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Country where DBA location is located</small>
                </div>
                <div class="col-12 md:col-12 field">
                    <label htmlfor="locationAddress">Location Address
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="locationAddress" id="locationAddress"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Corporate address here</small>
                    <div *ngIf="
                            (!businessDetail.get('locationAddress')?.valid &&
                                (businessDetail.get('locationAddress')?.dirty ||
                                    businessDetail.get('locationAddress')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('locationAddress').value
                                    ?.length == 0 &&
                                !businessDetail.get('locationAddress').errors
                                    .required
                            ">
                            Please enter a Location Address
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="locationZipCode">Location Zipcode
                        <span class="text-red-500">*</span></label>
                    <input type="text" (keyup)="getZipCode(2)" formControlName="locationZipCode" id="locationZipCode"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Zipcode for the DBA location</small>
                    <div *ngIf="
                            (!businessDetail.get('locationZipCode')?.valid &&
                                (businessDetail.get('locationZipCode')?.dirty ||
                                    businessDetail.get('locationZipCode')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('locationZipCode').value
                                    ?.length == 0 &&
                                !businessDetail.get('locationZipCode').errors
                                    .required
                            ">
                            Please enter a Zip code
                        </div>
                    </div>
                    <div *ngIf="isLocationZipCodeDataLoaded">
                        <i class="pi pi-spin pi-spinner"></i>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="locationCity">Location City
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="locationCity" id="locationCity"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">City of the DBA location</small>
                    <div *ngIf="
                            (!businessDetail.get('locationCity')?.valid &&
                                (businessDetail.get('locationCity')?.dirty ||
                                    businessDetail.get('locationCity')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('locationCity').value
                                    ?.length == 0 &&
                                !businessDetail.get('locationCity').errors
                                    .required
                            ">
                            Please enter a City
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <label htmlfor="locationState">Location State
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="usaStates" optionLabel="name" formControlName="locationState"
                        id="locationState" optionValue="name" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" [filter]="true" filterBy="name" placeholder="Select State"
                        required>
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">State of the DBA location</small>
                    <div *ngIf="
                            (!businessDetail.get('locationState')?.valid &&
                                (businessDetail.get('locationState')?.dirty ||
                                    businessDetail.get('locationState')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('locationState').value
                                    ?.length == 0 &&
                                !businessDetail.get('locationState').errors
                                    .required
                            ">
                            Please enter a State
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="averageTicketAmount">Average Ticket Amount
                        <span class="text-red-500">*</span></label>
                    <p-inputNumber inputId="averageTicketAmount" formControlName="averageTicketAmount"
                        class="p-element p-filled" required>
                    </p-inputNumber>
                    <small id="username1-help" class="block text-muted">The dollar amount of the average sale</small>
                    <div *ngIf="
                            (!businessDetail.get('averageTicketAmount')
                                ?.valid &&
                                (businessDetail.get('averageTicketAmount')
                                    ?.dirty ||
                                    businessDetail.get('averageTicketAmount')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('averageTicketAmount').value
                                    ?.length == 0 &&
                                !businessDetail.get('averageTicketAmount')
                                    .errors.required
                            ">
                            Please enter a Average ticket amount
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="averageMonthVolume">Average Month Volume
                        <span class="text-red-500">*</span></label>
                    <p-inputNumber inputId="averageMonthVolume" formControlName="averageMonthVolume"
                        class="p-component p-element p-filled" required>
                    </p-inputNumber>
                    <small id="username1-help" class="block text-muted">Average amount merchant expects to process
                        monthly</small>
                    <div *ngIf="
                            (!businessDetail.get('averageMonthVolume')?.valid &&
                                (businessDetail.get('averageMonthVolume')
                                    ?.dirty ||
                                    businessDetail.get('averageMonthVolume')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('averageMonthVolume').value
                                    ?.length == 0 &&
                                !businessDetail.get('averageMonthVolume').errors
                                    .required
                            ">
                            Please enter a Average month volume
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div class="col-12 md:col-6 field">
                    <label htmlfor="inputId">Have Other Business
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="sameAsInputList" optionLabel="viewValue" formControlName="haveOtherBusiness"
                        inputId="haveOtherBusiness" optionValue="view" [showTransitionOptions]="'0ms'" required
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Does the merchant own another business
                        processing
                        through us or Synovus</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="processingAccount">Processing Account
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="sameAsInputList" optionLabel="viewValue" formControlName="processingAccount"
                        inputId="processingAccount" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Does the merchant currently or has previously
                        had a
                        processing account</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label htmlfor="bankCardTerminated">Bankcard Terminated
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="sameAsInputList" optionLabel="viewValue" formControlName="bankCardTerminated"
                        inputId="bankCardTerminated" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Has the merchant previously been added to the
                        TMF</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Banks Name <span class="text-red-500">*</span></label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" required
                        formControlName="bankName" pInputText />
                    <small id="username1-help" class="block text-muted">Name of the bank that the merchant’s checking
                        account
                        is at</small>
                    <div *ngIf="
                            (!businessDetail.get('bankName')?.valid &&
                                (businessDetail.get('bankName')?.dirty ||
                                    businessDetail.get('bankName')?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('bankName').value?.length ==
                                    0 &&
                                !businessDetail.get('bankName').errors.required
                            ">
                            Please enter a Bank Name
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Bank Transit Number
                        <span class="text-red-500">*</span></label>
                    <input type="text" formControlName="bankTransitNumber"
                        class="p-inputtext p-component p-element p-filled mb-1" pInputText required />
                    <small id="username1-help" class="block text-muted">Identifier number for merchant’s bank (can only
                        be 9
                        digits)</small>
                    <div *ngIf="
                            (!businessDetail.get('bankTransitNumber')?.valid &&
                                (businessDetail.get('bankTransitNumber')
                                    ?.dirty ||
                                    businessDetail.get('bankTransitNumber')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('bankTransitNumber').value
                                    ?.length == 0 &&
                                !businessDetail.get('bankTransitNumber').errors
                                    .required
                            ">
                            Please enter a Bank Transit Number
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Bank DDA Number
                        <span class="text-red-500">*</span></label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" pInputText required
                        formControlName="bankDDANumber" />
                    <small id="username1-help" class="block text-muted">Bank Checking (Demand Deposit Account)
                        Number</small>
                    <div *ngIf="
                            (!businessDetail.get('bankDDANumber')?.valid &&
                                (businessDetail.get('bankDDANumber')?.dirty ||
                                    businessDetail.get('bankDDANumber')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('bankDDANumber').value
                                    ?.length == 0 &&
                                !businessDetail.get('bankDDANumber').errors
                                    .required
                            ">
                            Please enter a Bank DDA number
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Location Type
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="locationTypeList" optionLabel="viewValue" formControlName="locationType"
                        id="locationType" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Identifies the type of location the business
                        occupies</small>
                </div>
                <div class="col-12 md:col-6 field">
                    <label>Form of ID <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="formIdList" optionLabel="viewValue" formControlName="formOfId" id="formOfId"
                        optionValue="view" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Form of ID is the merchant supplying</small>
                </div>
                <div class="col-12 md:col-12 field">
                    <label>Business Description
                        <span class="text-red-500">*</span></label>
                    <input type="text" class="p-inputtext p-component p-element p-filled mb-1" pInputText
                        formControlName="businessDescription" required />
                    <small id="username1-help" class="block text-muted">Description of the product/service</small>
                    <div *ngIf="
                            (!businessDetail.get('businessDescription')
                                ?.valid &&
                                (businessDetail.get('businessDescription')
                                    ?.dirty ||
                                    businessDetail.get('businessDescription')
                                        ?.touched))
                        ">
                        <div class="input-required mt-1" [hidden]="
                                businessDetail.get('businessDescription').value
                                    ?.length == 0 &&
                                !businessDetail.get('businessDescription')
                                    .errors.required
                            ">
                            Please enter a Business Description
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <label>Refund Policy
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="refundPolicyList" optionLabel="viewValue" formControlName="refundPolicy"
                        id="refundPolicy" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" styleClass="my-1">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">What is merchant’s refund policy?</small>
                </div>
                <div class="col-12 md:col-6">
                    <label>Delivery Time
                        <span class="text-red-500">*</span></label>
                    <p-dropdown [options]="sameAsInputList" optionLabel="viewValue" formControlName="deliveryTime"
                        id="deliveryTime" optionValue="view" [showTransitionOptions]="'0ms'"
                        [hideTransitionOptions]="'0ms'" styleClass="my-1">
                    </p-dropdown>
                    <small id="username1-help" class="block text-muted">Are the goods or services delivered at the time
                        of the
                        transaction</small>
                </div>
            </div>
        </div>
        <div class="grid mt-5 justify-content-between">
            <p-button label="Back" [disabled]="isSubmitted" class="next-level-back-button" (click)="backButtonClick()"
                icon="pi pi-arrow-left" iconPos="left"></p-button>

            <p-button label="Submit" icon="pi pi-arrow-right" [disabled]="!businessDetail.valid" iconPos="right"
                class="submit-button" type="submit">
                <i class="pi pi-spin pi-spinner" *ngIf="isSubmitted"></i>
            </p-button>
        </div>
    </form>
</div>
