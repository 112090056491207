<!-- background-image: url(https://preview.keenthemes.com/metronic8/demo2/assets/media/auth/bg4.jpg); -->
<div
    class="grid"
    style="
        width: 100%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        align-items: center;
        margin: 0;
    "
>
    <div
        class="xl:col-6 lg:col-6 col-12"
        style="
background-position: center;
background-size: cover;
background-repeat: no-repeat;
background-color: white;
height: 100vh;
background-image: url('{{ _globals.whiteLabel.backgroundImage }}');"
    >
    </div>

    <div
        class="xl:col-5 lg:col-5 col-12"
        style="align-items: center; align-content: center"
    >
        <div class="conn-card" style="margin: auto auto; max-width: 500px">
            <div class="conn-grid">
                <div
                    class="flex align-items-center justify-content-center"
                    style="margin-top: 1rem; margin-bottom: 1rem"
                >
                    <img
                        [src]="_globals.whiteLabel.logo"
                        alt="logo"
                        width="120px"
                    />
                </div>
            </div>

            <h4 style="text-align: center; font-weight: 700">
                Change Your Password
            </h4>
            <br />
            <form
                [formGroup]="resetpasswordForm"
                (ngSubmit)="resetpasswordForm.valid && changePasswordSubmit()"
                autocomplete="off"
            >
                <!-- <div>
                    <label
                        for="password"
                        class="block text-900 font-medium text-xl mb-2"
                        >Old Password</label
                    >
                    <p-password
                        id="password"
                        [formControl]="
                            resetpasswordForm.controls['oldPassword']
                        "
                        placeholder="Old Password"
                        [toggleMask]="true"
                        [feedback]="true"
                        [style]="{ width: '100%', 'margin-top': '8px' }"
                        [inputStyle]="{ width: '100%' }"
                        styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                    >
                    </p-password>
                    <div
                        *ngIf="
                            resetpasswordForm.controls.oldPassword.touched &&
                            resetpasswordForm.controls.oldPassword.invalid
                        "
                        class="invalid-feedback"
                    >
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                resetpasswordForm.controls.oldPassword.errors
                                    ?.required
                            "
                            style="margin-top: -10px"
                        >
                            Field cannot be blank
                        </div>
                    </div>
                </div> -->
                <div>
                    <label
                        for="password"
                        class="block text-900 font-medium text-xl mb-2"
                        >New Password</label
                    >
                    <p-password
                        id="password"
                        [formControl]="resetpasswordForm.controls['password']"
                        placeholder="Password"
                        [toggleMask]="true"
                        [feedback]="true"
                        [style]="{ width: '100%', 'margin-top': '8px' }"
                        [inputStyle]="{ width: '100%' }"
                        [strongRegex]="_globals.passwordValidatorString"
                        (input)="resetFormOk()"
                        styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                    >
                        <ng-template pTemplate="footer">
                            <p-divider></p-divider>
                            <p class="mt-2">Suggestions</p>
                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                <li>At least one lowercase</li>
                                <li>At least one uppercase</li>
                                <li>At least one numeric</li>
                                <li>At least one special character</li>
                                <li>Between 8 and 16 characters</li>
                            </ul>
                        </ng-template>
                    </p-password>
                    <div
                        *ngIf="
                            resetpasswordForm.controls.password.touched &&
                            resetpasswordForm.controls.password.invalid
                        "
                        class="invalid-feedback"
                    >
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                resetpasswordForm.controls.password.errors
                                    ?.required ||
                                resetpasswordForm.controls.password.errors
                                    ?.passwordStrength
                            "
                            style="margin-top: -10px"
                        >
                            Your password must have lower case, upper case,
                            special character, numeric characters and length
                            between 8-16 characters.
                        </div>
                    </div>
                </div>

                <br />
                <div>
                    <label
                        for="password"
                        class="block text-900 font-medium text-xl mb-2"
                        >Confirm Password</label
                    >
                    <p-password
                        id="password"
                        [formControl]="
                            resetpasswordForm.controls['confirmPassword']
                        "
                        placeholder="Confirm Password"
                        [toggleMask]="true"
                        [feedback]="true"
                        [style]="{ width: '100%', 'margin-top': '8px' }"
                        [inputStyle]="{ width: '100%' }"
                        [strongRegex]="_globals.passwordValidatorString"
                        (input)="resetFormOk()"
                        styleClass="w-full mb-3 p-password p-component p-inputwrapper p-input-icon-right"
                    >
                        <ng-template pTemplate="footer">
                            <p-divider></p-divider>
                            <p class="mt-2">Suggestions</p>
                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                <li>At least one lowercase</li>
                                <li>At least one uppercase</li>
                                <li>At least one numeric</li>
                                <li>At least one special character</li>
                                <li>Between 8 and 16 characters</li>
                            </ul>
                        </ng-template>
                    </p-password>
                    <div
                        *ngIf="
                            resetpasswordForm.controls.confirmPassword
                                .touched &&
                            resetpasswordForm.controls.confirmPassword.invalid
                        "
                        class="invalid-feedback"
                    >
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                resetpasswordForm.controls.confirmPassword
                                    .errors?.required ||
                                resetpasswordForm.controls.confirmPassword
                                    .errors?.conpasswordStrength
                            "
                            style="margin-top: -10px; margin-bottom: 20px"
                        >
                            Mismatch passwords.
                        </div>
                    </div>
                </div>
                <p class="text-600 text-sm line-height-3 text-align-center">
                    Please enter password between 8-16 characters long with at
                    least one uppercase character, one numeric and one special
                    character
                </p>
                <br />

                <button
                    [disabled]="!resetpasswordForm.valid || !resetFormOk()"
                    pButton
                    pRipple
                    label="Change Password"
                    class="w-full p-3 text-xl mb-2"
                    style="background-color: var(--brandColor)"
                    isDefault
                ></button>
            </form>
        </div>
    </div>
</div>
<style>
    h2.brand-slogon {
        color: #414042;
        margin-top: 10px;
        padding: 10px;
    }
</style>
