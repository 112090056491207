<form
 [formGroup]="editform"
 autocomplete="off"
 (ngSubmit)="editform.valid && saveFn()"
>
 <div class="conn-card">
     <div class="flex row py-2">
         <div class="col-8 pl-0">
             <label class="control-label">
                 <strong>
                    Full Name
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <app-text-input
                 [formControl]="$any(editform.controls)['fullName']"
                 [name]="'Full Name'"
                 [description]="fullNameDescription"
                 [label]=""
             >
             </app-text-input>
         </div>      

         <div class="col-4 pr-0">
             <label class="control-label">
                 <strong>
                    Status
                    <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <p-dropdown
                 [formControl]="$any(editform.controls)['status']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="statusList"
                 optionLabel="viewValue"
                 optionValue="id"
                 [autoDisplayFirst]="false"
             >
             </p-dropdown>
             <div *ngIf="$any(editform.controls)['status'].invalid && $any(editform.controls)['status'].touched" class="invalid-feedback">
                Please select a status
            </div>
         </div>       
     </div>
     <div class="conn-grid py-2">
            <div>
             <label class="control-label py-2">
                 <strong>
                    Description
                     <span style="color: red; font-size: 1.25rem"></span>
                 </strong>
             </label>
             <br />
             <textarea [rows]="5" [cols]="30" [autoResize]="true" class="mt-2"  [formControl]="$any(editform.controls)['description']" pInputTextarea
         style="width: 100%;">​</textarea>
 
         </div>
     </div>
     <div  *ngIf="!isNew">
        <p-table
        #dt
        [tableStyle]="{ width: 'min-content' }"
        [value]="couponArray"
        *ngIf="couponArray.length > 0"
        [autoLayout]="true"
        [paginator]="false"

        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} discounts"
        [rowsPerPageOptions]="[5, 10, 50]"
        selectionMode="single"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center">Code</th>
                <th style="text-align: start">Url</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item >
            <tr [pSelectableRow]="item" class="text-center">
                <td style="text-align: center">{{ item.x }}</td>
                <td style="text-align: start">{{ item.y}}</td>

            </tr>
        </ng-template>
    </p-table>
      
    </div>
 </div>

 <p-divider style="margin-top: 10px"></p-divider>
 <div class="grid">
     <div class="col">
         <div
             class="flex justify-content-end flex-wrap card-container green-container mt-3"
         >
             <div class="flex align-items-center justify-content-center">
                 <button
                     pButton
                     pRipple
                     type="submit"
                     label="Save"
                     [disabled]="!editform.valid"
                     *ngIf="!isViewOnly"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
             <div
                 class="flex align-items-center justify-content-center ml-2"
             >
                 <button
                     pButton
                     pRipple
                     type="button"
                     label="Cancel"
                     class="p-button-outlined p-button-danger"
                     (click)="cancelButtonClick()"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
         </div>
     </div>
 </div>
</form>


