import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
@Component({
  selector: 'app-inventory-adjustement-summary-report',
  templateUrl: './inventory-adjustement-summary-report.component.html',
  styleUrls: ['./inventory-adjustement-summary-report.component.scss']
})
export class InventoryAdjustementSummaryReportComponent implements OnInit {
    _globals = GlobalService

    colheader = [['Date', 'User', 'Adjustment Type', 'Location', 'Cost ($)' ]];
    colflds = ['dated','user', 'adjustmentId', 'adjustmentTypeString', 'locationName', 'cost' ];
    summaryHeader = [['Date', 'User', 'Adjustment ID', 'Adjustment Type', 'Location', 'Cost ($)' ]];
    domSummaryflds = ['user', 'adjustmentTypeString', 'locationName', 'cost' ];
    domColHeader = this.colheader[0];
    domSummaryHeader = this.summaryHeader[0];

    dataList: any = ''
    locations: any = ''
    selectedLocation: any = ''
    departments: any = ''
    selectedDepartments: any = ''
    dateRange: any = ''

    showFilterPanel = false
    csvcols = []
    exportColumns: any = ''

    selectedGroupby: any = ''
    locationIds: string[] = [];
    catIds: string[] = [];
    groupby: string = 'Location';


  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Inventory Adjustment Summary'
    this._filterService.selectedLocation = this._abd.locations[0];

    if(!this._filterService.dateRange || this._filterService.dateRange.length<1)
    this._filterService.initDates()

    this.getDataList()
  }
  ngOnDestroy() {
    this._globals.componentName = ''
  }


  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  getDataList() {
    if (!this._filterService.selectedInventoryAdjustment)
            this._filterService.selectedInventoryAdjustment = this._abd.inventoryAdjustmentTypes[0]
    const data = {
      "ReportName": 'AdjustmentSummaryReport',
      "Parameter": {
        "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": [this._filterService.selectedLocation.locationId.toString()],
        "VendorIds": [],
        'filterValue': this._filterService.searchText,
        "AdjustmentType": this._filterService.selectedInventoryAdjustment.name
      }
    }

    const url = environment.InventoryBaseURL + environment.Report
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dataList = res.data;
      this.dataList.map(x => {
        if (x.adjustmentType=='4') {
           x.adjustmentType = 'Transfer In'
         }
         else if (x.adjustmentType=='5') {
           x.adjustmentType = 'Transfer Out'
         }
        else if (x.adjustmentType=='6') {
          x.adjustmentType = 'Purchase'
        }
        else if (x.adjustmentType=='7') {
           x.adjustmentType = 'Inventory Count'
         }
        else if (x.adjustmentType=='8') {
           x.adjustmentType = 'Damage'
         }
         else if (x.adjustmentType=='9') {
           x.adjustmentType = 'Return To Sender'
         }

         else if (x.adjustmentType=='10') {
           x.adjustmentType = 'Lost'
         }
       else  if (x.adjustmentType=='11') {
           x.adjustmentType = 'Expired'
         }
         else  if (x.adjustmentType=='12') {
          x.adjustmentType = 'Exchange'

       }
       else if (x.adjustmentType == '2') {
        x.adjustmentType = 'Auto Adjustment'

      }
       else  if (x.adjustmentType=='13') {
          x.adjustmentType = 'Other'

       }

      })
      this._abd.hideSpinner();
    })
  }


  filterButtonPressed() {
    this.getDataList()
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.summaryHeader[0], this.colflds, 'Inventory Adjustment Summary Report')
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.summaryHeader, this.colflds, 'Inventory Adjustment Summary Report', true)
  }

  calculateSummaryTotal(col: string) {
    //

    let total = 0;
    if (this.dataList.length > 0) {
      ;
      let total = "0";
      if (col.includes("cost")) {
        return this.currencyPipe.transform(this.dataList.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      else {
        return "";
      }
    }
    return total;

  }

}
