<div class="conn-card">
    <!-- <img class="img-1-1" src="assets/images/ecm001.svg"> -->
    <p style="font-size: 1.25rem;  color: var(--gray-500); font-weight: bold">
        Shipping Address
    </p>
    
    <div *ngIf="item?.address1!==''">
        <br>
        <div class="grid text-600 text-lg ml-2 mb-3">{{item.address1}}</div>
        <div *ngIf="item.address2" class=" grid text-600 text-lg ml-2 mb-3">{{item.address2}}</div>
        <div class="grid text-600 text-lg ml-2 mb-3">{{item.city}}</div>
        <div class="grid text-600 text-lg ml-2 mb-3">{{item.state+ ' ,' + item.zipCode}}</div>
    </div>

    <div *ngIf="item?.address1==''">
        <p  class="flex flex-wrap text-400 font-medium text-lg mb-5">No data to display</p>
    </div>
</div>


