import { DropDownModel } from "./drop-down-model";

export let FormIdList: DropDownModel[] = [
    {
        view: 'DL #',
        viewValue: 'DL #'
    },
    {
        view: 'Passport #',
        viewValue: 'Passport #'
    },
    {
        view: 'State issued ID #',
        viewValue: 'State issued ID #'
    },
    {
        view: 'Military ID #',
        viewValue: 'Military ID #'
    },
]
