import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/_services/base.service';
import { ReportsService } from 'src/app/_services/reports.service';

@Component({
  selector: 'app-partial-invoice-detail',
  templateUrl: './partial-invoice-detail.component.html',
  styleUrls: ['./partial-invoice-detail.component.scss']
})
export class PartialInvoiceDetailComponent implements OnInit {
  invoiceNumber: any = ''
  dataList: any = null

  constructor(
    private _bs: BaseService,
    public _reportService: ReportsService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    //let ss = this._reportService.selectedInvoiceDetails.invoiceItems
  }
}
