<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'transfer-detail-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    >
    </app-report-top-filter-bar>
    <p-table
        [value]="dataList"
        dataKey="name"
        #dt
        [resizableColumns]="false" [autoLayout]="true"
        [columns]="csvcols"

        styleClass="p-datatable-striped"
        [scrollable]="true"
        *ngIf="dataList?.length > 0"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{ col }}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>
                    <span>{{ product.date | date : "MM/dd/yyyy" }}</span>
                </td>
                <td>
                    <span>{{ product.transferNo }}</span>
                </td>
                <td>
                    <span>{{ product.productName }}</span>
                </td>
                <td>
                    <span>{{ product.skuCode }}</span>
                </td>
                <td>
                    <span>{{ product.barcode }}</span>
                </td>
                <td>
                    <span>{{ product.senderLocation }}</span>
                </td>
                <td>
                    <span>{{ product.receiverLocation }}</span>
                </td>
                <td>
                    <span>{{ product.totalTransferredQty }}</span>
                </td>
                <td>
                    <span>{{ product.unitPrice | currency }}</span>
                </td>
                <td>
                    <span>{{ product.totalPrice | currency }}</span>
                </td>
                <td>
                    <span>{{ product.transferTypeString }}</span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td
                    rowspan="2"
                    colspan="1"
                    style="text-align: center"
                    class="text-lg font-bold"
                >
                    <strong>Total</strong>
                </td>

                <td
                    *ngFor="let col of domSummaryflds"
                    style="text-align: center"
                    class="text-lg font-bold"
                >
                    {{ calculateSummaryTotal(col) }}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
</div>
