<div class="conn-card">
    <app-report-top-filter-bar
        *ngIf="0 == 0"
        [paraReportName]="'credit-card-sales-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>

    <!-- [scrollable]="true" -->
    <p-table
        [globalFilterFields]="[
            'customerName',
            'invoiceNumber',
            'cashier',
            'type',
            'entryMethod',
            'last4'
        ]"
        [value]="dataList"
        dataKey="invoiceNumber"
        #dt
        [columns]="csvcols"
        styleClass="p-datatable-striped"
        [autoLayout]="true"
        [resizableColumns]="true"
        *ngIf="dataList?.length > 0"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                        pInputText
                        type="text"
                        (input)="
                            dt.filterGlobal($event.target.value, 'contains')
                        "
                        placeholder="Search keyword"
                    />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="text-align: center" *ngFor="let col of domColHeader">
                    <strong>{{ col }}</strong>
                </th> -->
                <th style="text-align: center">Name</th>
                <th style="text-align: center">Sale</th>
                <th style="text-align: center">Fees</th>
                <th style="text-align: center">Return</th>
                <th style="text-align: center">Voids</th>
                <th style="text-align: center">Type</th>
                <th style="text-align: center">Last 4</th>
                <th style="text-align: center">Approval</th>
                <th style="text-align: center">Invoice</th>
                <th style="text-align: center">Ref#</th>
                <th style="text-align: center">Entry Method</th>
                <th style="text-align: center">Station</th>
                <th style="text-align: center">Date</th>
                <th style="text-align: center">Time</th>
                <th style="text-align: center">Clerk</th>
                <th style="text-align: center">Tip Amount</th>
                <th style="text-align: center">Changed</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-expanded="expanded">
            <tr>
                <!-- <td title="Customer ID"> </td> -->
                <td style="text-align: center">
                    {{ product.customerName }}
                </td>
                <td style="text-align: center">
                    {{ product.sales | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.feesTotal | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.returns | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.voids | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.type }}
                </td>
                <td style="text-align: center">
                    {{ product.last4 }}
                </td>
                <td style="text-align: center">
                    {{ product.approval }}
                </td>
                <td style="text-align: center">
                    {{ product.invoiceNumber }}
                </td>
                <td style="text-align: center">
                    {{ product.refNumber }}
                </td>
                <td style="text-align: center">
                    {{ product.entryMethod }}
                </td>
                <td style="text-align: center">
                    {{ product.station }}
                </td>
                <td style="text-align: center">
                    {{ product.date }}
                </td>
                <td style="text-align: center">
                    {{ product.time}}
                </td>
                <td style="text-align: center">
                    {{ product.cashier }}
                </td>
                <td style="text-align: center">
                    {{ product.tipAmount | currency }}
                </td>
                <td style="text-align: center">
                    {{ product.invoiceTotal | currency }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
</div>
