export interface CouponCode {
    discountName?: string;
    salesChannelList?: string[];
    customerType?: number;
    discountType?: number;
    discountValue?: number;
    maxDiscount?: number;
    maxCouponCodeUse?: number;
    validity?: number;
    validityStartDate?: string;
    validityEndDate?: string;
    allowedDaysList?: any[];
    allowedSubscriptionPlanList?: number[];
    couponCodes?:any[];
}
export interface SalesChannel {
    fullName?: string;
    status?: number;
    description?: string;
    couponCodes?:string
    url?:string
}

export const CustomerTypeList = [
  {id:4,viewValue:'New Customers'},
  {id:5,viewValue:'Existing Customers'},
]

export const DiscountTypeList =[
  {id:0,viewValue:'Flat'},
  {id:1,viewValue:'Percentage'},
  {id:2,viewValue:'Tracking Only'},
]

export const ValidityList =[
  {id:0,viewValue:'Valid always'},
  {id:1,viewValue:'Valid between a date range'},
]
export const AllowedDaysList = [
  {
    id: 0,
    viewValue: "Sunday"
  },
  {
    id: 1,
    viewValue: "Monday"
  },
  {
    id: 2,
    viewValue: "Tuesday"
  },
  {
    id: 3,
    viewValue: "Wednesday"
  },
  {
    id: 4,
    viewValue: "Thursday"
  },
  {
    id: 5,
    viewValue: "Friday"
  },
  {
    id: 6,
    viewValue: "Saturday"},
]

export const ChannelList =[
  {id:1,viewValue:'Instagram'},
  {id:2,viewValue:'Omar'},
  {id:3,viewValue:'Justin'},
  {id:4,viewValue:'Facebook'},
]


 export const Status =[
  {id:0,viewValue:'Disable'},
  {id:1,viewValue:'Enable'},
 ]