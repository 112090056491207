<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div></div>
        <button
            pButton
            pRipple
            type="button"
            label="Edit"
            class="p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-pencil"
            iconPos="right"
            (click)="productEditClicked()"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Delete"
            class="p-button-danger p-button-text"
            [style]="{ width: '100%' }"
            icon="pi pi-trash"
            iconPos="right"
            (click)="productDelete()"
        ></button>
    </ng-template>
</p-overlayPanel>
<ng-container *ngIf="_barcodeLabelServices.productListPrintBarcodeLabel">
    <p-dialog
        header="Barcode Printing"
        [(visible)]="_barcodeLabelServices.productListPrintBarcodeLabel"
        [style]="{ width: '70%' }"
        modal="true"
    >
        <app-barcode-label-printing-form
        *ngIf="_barcodeLabelServices.productListPrintBarcodeLabel"
            [isNew]="true"
            [fromList]="false"
            [isProductLabel]="true"
        ></app-barcode-label-printing-form>
    </p-dialog>
</ng-container>

<div class="fadeindown animation-duration-500 flex">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            [paraReportName]="'products'"
            (filterCallbackFunc)="callbackFuncFromFilter()"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
            (exportCsvFn)="dt.exportCSV()"
            (deleteItemFn)="deleteMultiple()"
            (printLabelFn)="printLabel()"
        >
        </app-report-top-filter-bar>

        <br />
        <p-table
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            styleClass="p-datatable-striped"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            [lazy]="true"
            (onLazyLoad)="customSort($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="selectAll"
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                        >
                        </p-checkbox>
                    </th>
                    <th></th>
                    <th pSortableColumn="itemName">
                        Product<p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="barCode" style="text-align: center">
                        Barcode<p-sortIcon field="barCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="skuCode" style="text-align: center">
                        SKU<p-sortIcon field="skuCode"></p-sortIcon>
                    </th>
                    <th style="text-align: center">Track Quantity</th>
                    <th style="text-align: center">Sell Online</th>
                    <th style="text-align: right">Sale Price</th>
                    <th pSortableColumn="lastEdited" style="text-align: center">
                        Last Updated<p-sortIcon field="lastEdited"></p-sortIcon>
                    </th>
                    <th style="text-align: center">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr
                    class="text-center"
                    [ngClass]="item.selected == true ? 'p-highlight' : ''"
                >
                    <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                        >
                        </p-checkbox>
                    </td>
                    <td style="max-width: 40px">
                        <div
                            *ngIf="item.imageThumbnail !== ''"
                            class="conn-flex-image conn-flex-image-badge"
                            style="background-image:url({{item.imageThumbnail}})"
                        ></div>
                        <span
                            *ngIf="item.imageThumbnail === ''"
                            class="conn-flex-image-first-letter"
                        >
                            {{ item.itemName | slice : 0 : 2 }}</span
                        >
                    </td>

                    <td
                        [title]="item.itemName"
                        (click)="
                            this._productService.activeItem = item;
                            productViewClicked()
                        "
                        style="cursor: pointer"
                    >
                        {{ item.itemName | slice : 0 : 20 }}
                        <span *ngIf="item.itemName.length > 20">...</span>
                    </td>
                    <td style="text-align: center">{{ item.barCode }}</td>
                    <td style="text-align: center">{{ item.skuCode }}</td>
                    <td style="text-align: center">
                        {{ item.trackStock ? "Yes" : "No" }}
                    </td>
                    <td style="text-align: center">
                        {{ item.sellOnline ? "Yes" : "No" }}
                    </td>
                    <td style="text-align: right">
                        {{
                            item.sellingPrice
                                | currency : "USD" : "symbol-narrow" : "0.2-2"
                        }}
                    </td>
                    <td style="text-align: center">
                        {{ item.lastEdited | date : "LLL dd, YYYY" }}
                    </td>
                    <td style="text-align: center">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="10" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>

                <tr>
                    <td colspan="10" style="text-align: center">
                        <p-button
                            label="Click to add product"
                            (onClick)="_router.navigate(['app/new-product'])"
                        >
                        </p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!-- *ngIf="dataList?.length" -->
        <p-paginator
            [first]="first"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
            #p
        >
        </p-paginator>
    </div>
</div>
