import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-tax-report',
  templateUrl: './sales-tax-report.component.html',
  styleUrls: ['./sales-tax-report.component.scss']
})
export class SalesTaxReportComponent implements OnInit, OnDestroy {

  _globals = GlobalService

  dateDataList: any[] = [];
  locationDataList: any[] = [];
  yearDataList: any[] = [];

  colheader = [[
    'Location Name', 'Department', 'Barcode', 'SkuCode', 'Item Name'
    , 'Opening Stock', 'Transfer Out', 'Transer In'
    , 'Damage', 'Used Since', 'Closing Stock', 'Variance'
  ]];

  colflds = ['locationName', 'department', 'barcode', 'skuCode', 'itemName',
    'openingStock', 'transferOut', 'transerIn', 'damage',
    'usedSince', 'closingStock', 'variance'];

  csvcols = []
  exportColumns: any = '';
  activeState: boolean[] = [true, false, false];
  locationIds: string[] = [];

  constructor(private _inventoryService: InventoryService,
    private _bs: BaseService,
    private _reportService: ReportsService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
  ) {
    this.csvcols = this._abd.makeCsvCols(this.colheader, this.colflds)
    this.exportColumns = this.csvcols.map(col => ({ title: col.header, dataKey: col.field }))
  }

  ngOnInit(): void {
    this._abd.selectedProductSellTypeFilter = { Type: "All", id: 3,Value:'All' }
    this._globals.componentName = 'Sales Tax Report-2'

    this._abd.initDates()
    this._abd.selectedLocation=JSON.parse(JSON.stringify(this._abd.locations))
    
    this.getDateDateList();
    this.getLocationDateList();
    this.getYearDateList();

  }

  getLocationsList() {
    let self=this;
    this.locationIds = [];
    this._abd.selectedLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }

  
  getDateDateList(){
    this.getLocationsList();
    if (!this._abd.dateRange) {
      this._abd.initDates()
    }
    
    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy":'Date',
      }
    }

    const url = environment.InventoryBaseURL+ environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.dateDataList = res.data;
      this._abd.hideSpinner();
    })
  }
  
  
  getLocationDateList(){
    this.getLocationsList();
    if (!this._abd.dateRange) {
      this._abd.initDates()
    }

    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy":'Location',
      }
    }
    
    const url = environment.InventoryBaseURL+ environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.locationDataList = res.data;
      this._abd.hideSpinner();
    })
  }

  
  
  getYearDateList(){
    this.getLocationsList();
    if (!this._abd.dateRange) {
      this._abd.initDates()
    }

    const data = {
      "ReportName": 'SalesTaxReport',
      "Parameter": {
        "StartDate": formatDate(this._abd.dateRange[0], 'MM/dd/yyyy', 'en-US'),
        "EndDate": formatDate(this._abd.dateRange[1], 'MM/dd/yyyy', 'en-US'),
        "LocationIds": this.locationIds,
        "GroupBy":'Year',
      }
    }

    const url = environment.InventoryBaseURL+ environment.Report;
    this._abd.showSpinner();
    this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
      this.yearDataList = res.data;
      this._abd.hideSpinner();
    })
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }


  filterButtonPressed() {
    this.getDateDateList();
    this.getLocationDateList();
    this.getYearDateList();
  }


  callbackFuncFromFilter(event) {
    this.getDateDateList();
    this.getLocationDateList();
    this.getYearDateList();
  }

  exportExcel() {
    // this._globals.exportExcel(this.dateDataList, this.colflds, 'Inventory Overview')
  }

  exportPdf() {
    let data = []

    for (let i = 0; i < this.dateDataList.length; i++) {
      let row = []
      for (let j = 0; j < this.colflds.length; j++)
        row.push(this.dateDataList[i][this.colflds[j]])
      data.push(row)
    }

    // this._globals.exportPdf(data, this.colheader, 'Inventory Overview')
  }

}
