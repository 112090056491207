import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { environment } from 'src/environments/environment';


import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

interface DropDownModel {
    name: string,
    title: string
}

@Component({
  selector: 'app-cash-reconciliation-new',
  templateUrl: './cash-reconciliation-new.component.html',
  styleUrls: ['./cash-reconciliation-new.component.scss']
})



export class CashReconciliationNewComponent implements OnInit, AfterViewInit{
    page = 0
  rows = 10
  first = 0
  totalRecords = 0
  step = 1
  title = "End of Day (EOD)"
  title2 = ""
  selectreasonTextArea= ""
  tillform:FormGroup;
  totalCount = 0
  totalCount1=0
  totalCoinsCount = 0
  totalDeposit = 0
  totalDepositCent=0
  totalDepositCD = 0
  selectedReason: any= null;
  topTags: any = null
  dataList: any = null

  drawerCountList: any = null
  managerCountObj: any = null
  managerCountList: any = null
  eodList: any = null
  cols: any[]
  registers: any = []

  isViewOnly = false
  selectedRow1: any = null
  selectedDC: any = null
  selectedLocations: any = null

  cdSelectedLocation: any = null
  cdSelectedRegister: any = null

  isConfirmedDC = false
  enableDC = true
  enableRectification = true
  showFilter = true
  showCashDeposit = false
  managerConfirmreason:string =''
  managerBankConfirmReason:string = ''

  drawerCountSummaryTotals: any = null

  drawerCountVerify: MenuItem[]
  lastDateRange: any = null

  mockData: any = []
  mockDataNew: any = []
  mockDataCD: any = []

  col1 = 'col-fixed font-bold text-sm white-space-nowrap'
  col2 = 'col text-600 text-sm'
  reasonList: DropDownModel[];
  csvcols = [
    { field: '', header: 'Location' },
    { field: '', header: 'Counted By' },
    { field: '', header: 'Reg' },
    { field: '', header: 'Date/Time' },
    { field: '', header: '$100' },
    { field: '', header: '$50' },
    { field: '', header: '$20' },
    { field: '', header: '$10' },
    { field: '', header: '$5' },
    { field: '', header: '$2' },
    { field: '', header: '$1' },
    { field: '', header: '25 cents (Quarters)' },
    { field: '', header: '10 cents (Dimes)' },
    { field: '', header: '5 cents (Nickels)' },
    { field: '', header: '1 cent (Penny)' },
    { field: '', header: 'Total Count' },
    { field: '', header: 'System Count' },
    { field: '', header: 'Over/Short' },
    { field: '', header: 'Last Count' },
    { field: '', header: 'Verify' },
  ]

  _globals = GlobalService
  @ViewChild('dcmenu') dcmenu: OverlayPanel

  showCashDrop: boolean = false;
  showPayout: boolean= false;
  showPayIn: boolean=false;
  showManagerReason: boolean= false;
  showCashCountPopup:boolean=false;
  showDropsDenominationAmount: boolean = false;
  showCountsDenominationAmount: boolean = false;
  showManagerTillNote:boolean=false;
  showMangerTillConfirmAmount:boolean=false;
  managerVerify:boolean=false;
  cashDropList : any[] =[];
  topReconcialtionHeaderList : any[] = [];

  visibleModal : boolean = false;
  selectLocationFilter: any[] =[]
  tillReportData: any[] = [];
  tillReportTotal: number = 0;

  currentShowingTableNumber : number = 1;

    payoutReportData: any[] = [];
    payoutReportTotal: number = 0;

    payInReportData: any[] = [];
    payInReportTotal: number = 0;

    refundReportData: any[] = [];
    refundReportTotal: number = 0;

    currentOpenedAccordion: number = 0;
    currentOpenedAccordion1:any=0;

    expectedCashInfoList: any[] = [];
    popupHeaderInfo : string = '';

    reasondatalist: any[] = [];


    allowAddingCashDrop: boolean = false;
    allowAddingCashCount: boolean = false;
    allowAddingPayin: boolean = false;
    allowAddingPayout: boolean = false;
    lastReconciliationDate: Date = new Date()

    dropCashTime: Date = new Date();
    payoutTime: Date = new Date();
    payInTime: Date=new Date()
    today: Date = new Date();
    tillData?:any = [];
    saveReasonObj: any = {};
    saveTillNotesObj: any = {};
    payoutInputAmount: number = 0;
    payInInputAmount : number= 0;
    payoutInvoiceNumber: any = '';
    payInInvoiceNumber : any = '';
    selectedTill: any = null
    clickedTill: any = {}

    private panelStates: Record<string, boolean> = {};
    constructor(
    private _bs: BaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    public _filterService: FilterServiceService,
    private _fb:FormBuilder,
  ) {
    this.tillform = this.createForm()
    const savedStates = localStorage.getItem('accordionPanelStates');
    if (savedStates) {
      this.panelStates = JSON.parse(savedStates);
    }
   }
    createForm(): FormGroup {
       return this._fb.group({
        managerCount:[],
        isVarified:[],
       })
    }

   getPanelState(panelId: string): boolean {
    return this.panelStates[panelId] || false;
  }
  setPanelState(panelId: string, isOpen: boolean): void {
    this.panelStates[panelId] = isOpen;
    localStorage.setItem('accordionPanelStates', JSON.stringify(this.panelStates));
  }

// Accordion Section State Handling 10/6/2023

  private accordionStateKey = 'accordionState';
  activeTabIndices: number[] = JSON.parse(localStorage.getItem(this.accordionStateKey) || '[]');

  onTabOpen(event: any) {
    // Add the opened tab's index to the array
    this.activeTabIndices.push(event.index);
    // Store the updated state in localStorage
    localStorage.setItem(this.accordionStateKey, JSON.stringify(this.activeTabIndices));
          this.drawerCountList[event.index].selected = true
          localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
  }

  onTabClose(event: any) {
    // Remove the closed tab's index from the array
    const index = this.activeTabIndices.indexOf(event.index);
    if (index !== -1) {
      this.activeTabIndices.splice(index, 1);
    }
    // Store the updated state in localStorage
    localStorage.setItem(this.accordionStateKey, JSON.stringify(this.activeTabIndices));
    this.drawerCountList[event.index].selected = false
    localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
    
  }

  onTabOpenModule(event : any, obj: any){
    switch(event.index){
        case 0 :
            obj.dropCashSelected = true;
        break;
        case 1 :
            obj.cashCountSelected = true;
        break;
        case 2 :
            obj.payoutSelected = true;
        break;
        case 3 :
            obj.payinSelected = true;
        break;
    }
    localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
  }

  onTabCloseModule(event : any, obj: any){
    switch(event.index){
        case 0 :
            obj.dropCashSelected = false;
        break;
        case 1 :
            obj.cashCountSelected = false;
        break;
        case 2 :
            obj.payoutSelected = false;
        break;
        case 3 :
            obj.payinSelected = false;
        break;
    }
    localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
 
  }

  ngOnInit(): void {

    this._globals.componentName = 'Reconciliation'
    this._globals.secondTitle = 'End of Day (EOD)'

    try {
      if (this._abd.locations.length < 1) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error', detail: 'No locations in system location!'
        })
        window.history.back()
      }
    } catch {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'System locations error!'
      })
      window.history.back()
    }
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      try {
        let ppages = this._filterService.getPages(this._globals.componentName)
        if (ppages) {
          let lastpage = parseInt(ppages.page)
          if (lastpage < 0) lastpage = 0
          if (lastpage > 0) {
            this.page = lastpage
            this.first = lastpage * this.rows
          }
        }

        let filter = this._filterService.getFilters(this._globals.componentName)
        if (filter) {
          this._filterService.dateRange = []
          if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
          if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

          if (filter.selectedLocation) {
            this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == filter.selectedLocation)
          } else {
            this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.locations[0]))
          }
        } else {
          const loc = this._filterService.selectedLocation?.locationId
          if (!loc)
            this._filterService.selectedLocation = JSON.parse(JSON.stringify(this._abd.actLocations[0]
            ))
          if (!this._filterService.dateRange || this._filterService.dateRange.length < 1)
            this._filterService.initDates()
        }
      } catch { clearInterval(ti) }

      clearInterval(ti)
      this.getDataList()
    }, 100);

  }

  ngOnDestroy() {
    this._globals.componentName = ''
    this._globals.secondTitle = ''
  }

  getLocations() {
    let locations = []
    const loc = this._filterService.selectedLocation
    if (!loc) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
      return locations
    }

    for (let i = 0; i < loc.length; i++) {
      const id = loc[i].LocationId
      if (id)
        locations.push(id)
    }
    return locations
  }

  getDataList() {
    this.showFilter = true

    let locations = []

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
    } else {
      locations.push(this._filterService.selectedLocation.locationId)
    }

    const sd = this._filterService.dateRange[0]
    const ed = this._filterService.dateRange[1]
    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    let body =
    {
      "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'), //"12/29/2022",
      "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'), //"12/30/2022",
      "LocationIds": locations
    }
      this.selectLocationFilter = locations

    const _url = environment.OrderBaseURL + 'ReconciliationEOD'
    this._spinner.show()
    this._bs.httpPost(_url, body).subscribe(res => {
      if (res.success) {
        this.eodList = res.data

        for (let i = 0; i < this.eodList.length; i++) {
          this.eodList[i].dated = new Date(this.eodList[i].dated)
        }
        this._spinner.hide()
      }
    })

    const outfilter = {
      selectedLocation: locations[0],
      startDate: this._filterService.dateRange[0],
      endDate: this._filterService.dateRange[1]
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
  }
  getPayOutReasonList()
  {
    let ti = null
    ti = setInterval(() => {
      //--
      let params = new HttpParams();
      let qryParas = null

      this.page = this.page < 1 ? 0 : this.page
      this.rows = this.rows < 1 ? 10 : this.rows
      params=params.append('drawerType','Payout'.toString());
      if (this.page >= 0) params = params.append('pageNo', this.page.toString());
      if (this.rows > 0) params = params.append('pageSize', this.rows.toString());

      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
      let url = environment.OrderBaseURL + 'CashDrawer/GetCashDrawerReasons'
     // let url = 'https://connect360-stg.azure-api.net/order-svc/api/CashDrawer/GetCashDrawerReasons'
      if (qryParas) url += qryParas

      this._abd.showSpinner()
      this._bs.httpGetParams(url, params).subscribe(res => {
        if (res.success) {
          this._abd.hideSpinner()

          this.totalRecords = res.totalRecords;
          this.reasondatalist = res.data
             this.selectedReason  = this.reasondatalist[0]
             this.selectreasonTextArea = this.reasondatalist[0].reason
        //  this.reasondatalist = [
            // {  title: 'System Cash', value: res.data.reason },
            // { id: 2, title: 'Drops', value: res.data.totalDrop },
            // { id: 3, title: 'Over/Short', value: res.data.totalOverShort },
         // ]
        }
      })
      //--
      clearInterval(ti)
    }, 200);
  }
  getPayInReasonList()
  {
    let ti = null
    ti = setInterval(() => {
      //--
      let params = new HttpParams();
      let qryParas = null

      this.page = this.page < 1 ? 0 : this.page
      this.rows = this.rows < 1 ? 10 : this.rows
      params=params.append('drawerType','Payin'.toString());
      if (this.page >= 0) params = params.append('pageNo', this.page.toString());
      if (this.rows > 0) params = params.append('pageSize', this.rows.toString());

      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
      let url = environment.OrderBaseURL + 'CashDrawer/GetCashDrawerReasons'
     // let url = 'https://connect360-stg.azure-api.net/order-svc/api/CashDrawer/GetCashDrawerReasons'
      if (qryParas) url += qryParas

      this._abd.showSpinner()
      this._bs.httpGetParams(url, params).subscribe(res => {
        if (res.success) {
          this._abd.hideSpinner()

          this.totalRecords = res.totalRecords;
          this.reasondatalist = res.data
             this.selectedReason  = this.reasondatalist[0]
             this.selectreasonTextArea = this.reasondatalist[0].reason
        //  this.reasondatalist = [
            // {  title: 'System Cash', value: res.data.reason },
            // { id: 2, title: 'Drops', value: res.data.totalDrop },
            // { id: 3, title: 'Over/Short', value: res.data.totalOverShort },
         // ]
        }
      })
      //--
      clearInterval(ti)
    }, 200);
  }
  getCashDropDataList() {
    this.showFilter = true

    let locations = []

    if (!this._filterService.selectedLocation) {
      this._filterService.selectedLocation = this._abd.locations[0]
      locations.push(this._abd.locations[0].locationId)
    } else {
      locations.push(this._filterService.selectedLocation.locationId)
    }

    const sd = this._filterService.dateRange[0]
    const ed = this._filterService.dateRange[1]
    try {
      const sd = this._filterService.dateRange[0]
      const ed = this._filterService.dateRange[1]
      if (sd.getFullYear() < 1200 && ed.getFullYear() < 1200)
        this._filterService.dateRange[1] = sd
      else if (sd.getFullYear() < 1200 || ed.getFullYear() < 1200) {
        this._filterService.dateRange = []
        this._filterService.dateRange[0] = new Date()
        this._filterService.dateRange[1] = new Date()
      }
    } catch {
      this._filterService.dateRange = []
      this._filterService.dateRange[0] = new Date()
      this._filterService.dateRange[1] = new Date()
    }

    let body =
    {
      "StartDate": formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'), //"12/29/2022",
      "EndDate": formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'), //"12/30/2022",
      "LocationIds": locations
    }

    const _url = environment.OrderBaseURL + 'ReconciliationEOD'
    this._spinner.show()
    this._bs.httpPost(_url, body).subscribe(res => {
      if (res.success) {
        // this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
        this.eodList = res.data

        for (let i = 0; i < this.eodList.length; i++) {
          this.eodList[i].dated = new Date(this.eodList[i].dated)
        }
        this._spinner.hide()
      }
    })

    const outfilter = {
      selectedLocation: locations[0],
      startDate: this._filterService.dateRange[0],
      endDate: this._filterService.dateRange[1]
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
  }

  reconcileClicked(item) {
    this.selectedRow1 = item
    // this.selectedRow1.push(item)
    this.isViewOnly = false
    let ti = null
    ti = setInterval(() => {
      this._filterService.singleDateForReconcile = ''
      this._filterService.singleDateForReconcile = new Date(item.dated)
      this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == item.locationId)

      clearInterval(ti)
      this.getDrawerCountList()
    }, 200);
  }

  getCashierList() {
    let ids = [];
    let sel = this._filterService.selectedCashier;
    if (!sel) return ids

    for (let i = 0; i < sel.length; i++)
      ids.push(sel[i].id)

    return ids
  }

  getLocationsList() {
    let locationIds = [];
    if(this.showFilter){
      locationIds.push(this._filterService.selectedLocation.locationId)
  }
    return locationIds
  }

  makeCashierList() {
    let arr = []
    let obj = this.dataList
    if (obj) {
      for (let j = 0; j < obj.length; j++) {
        const ff = arr.find(f => f.id == obj[j].cashierId)
        if (!ff) {
          arr.push({ id: obj[j].cashierId, name: obj[j].cashierName })
        }
      }
    }
    this._abd.cashiers = arr
  }

  calculateSummaryTotal(what) {
    try {
      const r = this.topTags.find(f => f.id == what)
      return r.value
    }
    catch { return 0 }
  }

  calcMockup() {
    for (let i = 0; i < this.mockData.length; i++) {
      this.mockData[i].total = this.mockData[i].qty * this.mockData[i].value
    }
  }

  getOverShortColor(tag) {

    return ""
  }

  getOverShortCColor(tag) {

    return ""
  }

  getVerifyTotalStyle() {
    if (this.totalDeposit > 0)
      return { 'text-align': 'right', 'background-color': 'var(--green-500)', 'color': 'white' }
    else if (this.totalDeposit < 0)
      return { 'text-align': 'right', 'background-color': 'var(--red-500)', 'color': 'white' }
    else
      return { 'text-align': 'right', 'background-color': 'var(--brandColor)', 'color': 'white' }
  }

  fillInitData() {
    this.drawerCountVerify = [
      { label: 'Yes', command: () => { this.updateVerify('Yes') } },
      { label: 'No', command: () => { this.updateVerify('No') } }
    ]

    this.cols = [
      { field: 'locationName', header: 'Location' },
      { field: 'cashierName', header: 'Cashier Name' },
      { field: 'registerName', header: 'Reg' },
      { field: 'date', header: 'Date/Time' },
      { field: 'dollar100Qty', header: '$100' },
      { field: 'dollar50Qty', header: '$50' },
      { field: 'dollar20Qty', header: '$20' },
      { field: 'dollar10Qty', header: '$10' },
      { field: 'dollar5Qty', header: '$5' },
      { field: 'dollar2Qty', header: '$2' },
      { field: 'dollar1Qty', header: '$1' },
      { field: 'cent25Qty', header: '25 cents (Quarters)' },
      { field: 'cent10Qty', header: '10 cents (Dimes)' },
      { field: 'cent5Qty', header: '5 cents (Nickels)' },
      { field: 'cent1Qty', header: '1 cents (Penny)' },
      { field: 'total', header: 'Total Count' },
      { field: 'systemCount', header: 'System Count' },
      { field: 'overShort', header: 'Over/Short' },
      { field: 'lastCount', header: 'Last Count' },
    ]

    this.mockData = [
      { bill: '$100', qty: 1, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 1, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
    //   { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
    //   { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
    //   { bill: '5 cents (Nickels)', qty: 7, total: 0, value: .05, verify: true },
    //   { bill: '1 cent (Penny)', qty: 2, total: 0, value: .01, verify: true },
    ]
    this.mockDataNew = [
        // { bill: '$100', qty: 1, total: 0, value: 100, verify: true },
        // { bill: '$50', qty: 1, total: 0, value: 50, verify: true },
        // { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
        // { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
        // { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
        // { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
        // { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
        { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
        { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
        { bill: '5 cents (Nickels)', qty: 7, total: 0, value: .05, verify: true },
        { bill: '1 cent (Penny)', qty: 2, total: 0, value: .01, verify: true },
      ]

    this.enableDC = true
    this.totalCount = 0
    this.totalCount1=0
    this.totalCoinsCount = 0
    let moc = this.mockData
    for (let i = 0; i < moc.length; i++) {
      moc[i].total = moc[i].qty * moc[i].value
      this.totalCount += moc[i].total
      this.totalCoinsCount += moc[i].qty
      if (!moc[i].isConfirmed) this.enableDC = false
    }
    this.totalDeposit = this.totalCount
    let moc1 = this.mockDataNew
    for (let i = 0; i < moc1.length; i++) {
      moc1[i].total = moc1[i].qty * moc1[i].value
      this.totalCount1 += moc1[i].total
      this.totalCoinsCount += moc1[i].qty
      if (!moc1[i].isConfirmed) this.enableDC = false
    }
    this.totalDepositCent = this.totalCount1
  }

  confirmCountClicked() {
    this.step = 2
    this.title = 'Count'
  }

  saveCountClicked() {
    this.step = 3
    this.title = 'Deposit'
  }

  countGoBackClicked() {
    this.step = 2
    this.title = 'Count'
  }

  cancelCountClicked() {
    //this.step = 2
  }

  callbackFuncFromFilter(e) {
    if (this.step == 1)
      this.getDataList()
    else if (this.step == 2)
      this.getDrawerCountList()
  }

  filterButtonPressed() { }

  getStatusText(item) {
    if (item.allowReconcilation) return ''

    const x = item.overShort

    if (x == 0) return 'Balanced'
    else if (x > 0) return 'Over by $' + this.formatNumberToString(x)
    else if (x < 0) return 'Short by $' + this.formatNumberToString(x)
    else return 'error'
  }

  formatNumberToString(num: number) {
    let x = Number(num.toFixed(2)).toLocaleString()
    return x
  }

  getDrawerCountList() {
    try {
      this.lastDateRange = JSON.parse(JSON.stringify(this._filterService.dateRange))
      let locations = []
      if (!this._filterService.selectedLocation) {
        locations.push(this._abd.locations[0].locationId)
      } else
        locations.push(this._filterService.selectedLocation.locationId)

      const paras = {
        "StartDate": formatDate(new Date(this._filterService.singleDateForReconcile), 'MM/dd/yyyy', 'en-US'),
        "LocationIds": locations
      }

      const _url = environment.OrderBaseURL + 'CashCount/ReconciliationV1'

     this._spinner.show()
      this._bs.httpPost(_url, paras).subscribe(res => {
        if (res.success) {
        this.currentOpenedAccordion1=[0];
        this.allowAddingCashDrop = res.data.allowAddingCashDrop && !this.isViewOnly
        this.allowAddingCashCount = res.data.allowAddingCashCount && !this.isViewOnly
        this.allowAddingPayout = res.data.allowAddingPayout && !this.isViewOnly
        this.allowAddingPayin = res.data.allowAddingPayin && !this.isViewOnly
        this.managerBankConfirmReason=res.data.managerCountReason

          this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
          this.drawerCountList = res.data.tillReconcilationEODData      
          // localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
         // this.selectedTill = this.drawerCountList[0]
         for (let i = 0; i < this.drawerCountList.length; i++) {
            this.drawerCountList[i].dated = new Date(this.drawerCountList[i].dated)
            this.drawerCountList[i].verify = this.drawerCountList[i].isVerified ? 'Yes' : 'No'

            let dc = this.drawerCountList[i]
            this.drawerCountList[i].totalCoinsCount =
              dc.dollar100Qty + dc.dollar50Qty + dc.dollar20Qty + dc.dollar10Qty + dc.dollar5Qty +
              dc.dollar2Qty + dc.dollar1Qty + dc.cent25Qty + dc.cent10Qty + dc.cent5Qty + dc.cent1Qty
          }

          if(this.drawerCountList.length > 0){
           this.drawerCountList.map(f=> f.selected =  false)
           this.drawerCountList[0].selected = true
     

          this.drawerCountList.map(function(f){

            f.dropCashSelected = false;
            f.cashCountSelected = false;
            f.payoutSelected = false;
            f.payinSelected = false;
            f.checkAllDropCash = false;
            f.checkAllCashCount = false;
            f.checkAllPayout = false;
            f.checkAllPayin = false;
          })
    }
          let reconcilationIndex = localStorage.getItem('reconcilationIndex');
          if(reconcilationIndex != undefined){
           // 
           let reconcilationIndex1 = JSON.parse(reconcilationIndex)

           for (let index = 0; index < reconcilationIndex1.length; index++) {
            const element = reconcilationIndex1[index];
            if(element != undefined && this.drawerCountList.filter(f=> f.tillId == element.tillId ).length > 0){
              if(this.drawerCountList[index] !== undefined){
              this.drawerCountList[index].selected = element.selected


                this.drawerCountList[index].dropCashSelected = element.dropCashSelected
                this.drawerCountList[index].cashCountSelected = element.cashCountSelected
                this.drawerCountList[index].payoutSelected = element.payoutSelected
                this.drawerCountList[index].payinSelected = element.payinSelected
                this.drawerCountList[index].checkAllDropCash = element.checkAllDropCash
                this.drawerCountList[index].checkAllCashCount = element.checkAllCashCount
                this.drawerCountList[index].checkAllPayout = element.checkAllPayout
                this.drawerCountList[index].checkAllPayin = element.checkAllPayin
              }

            }


           }

          }
          if (this.step == 1) this.step = 2
          this.showFilter = true
          this.title2 = ' - Drawer Count'
          this.calcDrawerCountTotals()

          this.cashDropList = res.data.tillReconcilationEODData.cashDrops
          this.topTags = [
              { icon_class:'pi pi-window-maximize', id: 1, title: 'Location Starting Cash', tooltip: 'Open Details', value: res.data.startingCash, allowPopup: true, class:'cursor-pointer' },
              { icon_class:'', id: 2, title: 'System Total', tooltip: 'System Total', value: res.data.systemTotal, allowPopup: false, class: '' },
              { icon_class:'', id: 3, title: 'Cash Sales', tooltip: 'Cash from sales', value: res.data.cashSales, allowPopup: false, class: '' },
              { icon_class:'pi pi-window-maximize', id: 4, title: 'Adjustments', tooltip: 'Open Details', value: res.data.moneyAdjustments, allowPopup: true, class: 'cursor-pointer' },
              { icon_class:'pi pi-window-maximize', id: 5, title: 'Expected Cash', tooltip: 'Open Details', value: res.data.expectedCash, allowPopup: true, class: 'cursor-pointer' },
              { icon_class:'', id: 6, title: 'Manager Count', tooltip: 'Manager Counted Amount', value: res.data.managerCount, allowPopup: false, class: '' },
              { icon_class:'pi pi-eye', id: 7, title: 'Bank Deposit', tooltip: 'Bank Deposited Amount', value: res.data.bankDeposit, allowPopup: true, class: 'cursor-pointer' },
              { icon_class:'', id: 8, title: 'Over/Short', tooltip: 'Manager count - Expected cash', value: res.data.overShort, allowPopup: false, class: '' },
          ]

        }
        else{
            this.enableRectification = false;
            this.allowAddingCashDrop = false;
        }
            this._spinner.hide()
      })
    }
    catch {
      this._spinner.hide()
    }
  }

  addManagerReason(obj: any){
       this.saveReasonObj = obj

        this.showManagerReason = true

  }
  showCashCountReason(obj: any){
    this.saveReasonObj = obj
     this.showCashCountPopup = true

}

  showTillNotes(obj: any){
    this.saveTillNotesObj = obj
  this.showManagerTillNote=true
}
  getDropsDenomincationAmount(item:any,obj:any) {

    this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.locationId == this.cdSelectedLocation.locationId)
   // this.clickedTill = item
    this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'No registers in system!'
      })
      return
    }
    this.mockDataCD = [
      { bill: '$100', qty: item.dollar100Qty, total: 0, value: 100, verify: true },
      { bill: '$50', qty: item.dollar50Qty, total: 0, value: 50, verify: true },
      { bill: '$20', qty: item.dollar20Qty, total: 0, value: 20, verify: true },
      { bill: '$10', qty: item.dollar10Qty, total: 0, value: 10, verify: true },
      { bill: '$5', qty: item.dollar5Qty, total: 0, value: 5, verify: true },
      { bill: '$2', qty: item.dollar2Qty, total: 0, value: 2, verify: true },
      { bill: '$1', qty: item.dollar1Qty, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: item.cent25Qty, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: item.cent10Qty, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: item.cent5Qty, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: item.cent1Qty, total: 0, value: .01, verify: true },
    ]
    this.showDropsDenominationAmount=true
  }
  getCountsDenomincationAmount(item, obj:any) {

    this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.locationId == this.cdSelectedLocation.locationId)
   // this.clickedTill = item
    this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]
    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'No registers in system!'
      })
      return
    }

    this.mockDataCD = [
      { bill: '$100', qty: item.dollar100Qty, total: 0, value: 100, verify: true },
      { bill: '$50', qty: item.dollar50Qty, total: 0, value: 50, verify: true },
      { bill: '$20', qty: item.dollar20Qty, total: 0, value: 20, verify: true },
      { bill: '$10', qty: item.dollar10Qty, total: 0, value: 10, verify: true },
      { bill: '$5', qty: item.dollar5Qty, total: 0, value: 5, verify: true },
      { bill: '$2', qty: item.dollar2Qty, total: 0, value: 2, verify: true },
      { bill: '$1', qty: item.dollar1Qty, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: item.cent25Qty, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: item.cent10Qty, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: item.cent5Qty, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: item.cent1Qty, total: 0, value: .01, verify: true },
    ]
    this.showCountsDenominationAmount=true
  }

  showManagerCountAmount(obj:any) {
    this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.locationId == this.cdSelectedLocation.locationId)
    this.clickedTill = obj
    this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'No registers in system!'
      })
      return
    }

    this.mockDataCD = [
      { bill: '$100', qty: obj.managerTillCount.dollar100Qty, total: 0, value: 100, verify: true },
      { bill: '$50', qty: obj.managerTillCount.dollar50Qty, total: 0, value: 50, verify: true },
      { bill: '$20', qty: obj.managerTillCount.dollar20Qty, total: 0, value: 20, verify: true },
      { bill: '$10', qty: obj.managerTillCount.dollar10Qty, total: 0, value: 10, verify: true },
      { bill: '$5', qty: obj.managerTillCount.dollar5Qty, total: 0, value: 5, verify: true },
      { bill: '$2', qty: obj.managerTillCount.dollar2Qty, total: 0, value: 2, verify: true },
      { bill: '$1', qty: obj.managerTillCount.dollar1Qty, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: obj.managerTillCount.cent25Qty, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: obj.managerTillCount.cent10Qty, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: obj.managerTillCount.cent5Qty,total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: obj.managerTillCount.cent1Qty,total: 0, value: .01, verify: true },
    ]
    this.showMangerTillConfirmAmount=true
  }
  saveReconcilationReasons (){  
    const obj = [this.saveReasonObj]
          this.showManagerReason = false
          const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&addManagerCount=true&reasonCheck=true'
          // const _url = environment.OrderBaseURL + 'CashDrawer/'+this.saveReasonObj.id+'?reasonCheck=true'
        this._confirmationService.confirm({
          header: `Manager Reason Confirmation`,
          message: `Are you sure you want to save the changes?`,
          accept: () => {
            try {
              this.showManagerReason = false
             // this._abd.showSpinner()
              this._bs.httpPut(_url, obj).subscribe(res => {
                this._spinner.hide()
                if (res.success) {
                  this.step = 2
                  this.saveReasonObj = {}
                  // this.getDrawerCountList();
                }
              })
            } catch {
              this.showManagerReason = false
             // this._abd.hideSpinner()
            }
          },
            reject: (type) => {
                this.showManagerReason = true
            }
        });

  }
  saveCashCountReasons (){
    
   const obj =  [{
         Id: this.saveReasonObj.id,
         Reason: this.saveReasonObj.managerReason,
         cent5Qty:this.saveReasonObj.cent5Qty,
         cent10Qty:this.saveReasonObj.cent10Qty,
         cent25Qty:this.saveReasonObj.cent25Qty,
         countedBy:this.saveReasonObj.countedBy,
         dateTime:this.saveReasonObj.dateTime,
         dollar1Qty:this.saveReasonObj.dollar1Qty,
         dollar2Qty:this.saveReasonObj.dollar2Qty,
         dollar5Qty:this.saveReasonObj.dollar5Qty,
         dollar10Qty:this.saveReasonObj.dollar10Qty,
         dollar20Qty:this.saveReasonObj.dollar20Qty,
         dollar50Qty:this.saveReasonObj.dollar50Qty,
         dollar100Qty:this.saveReasonObj.dollar100Qty,
         CountnoDenomination:this.saveReasonObj.CountnoDenomination,
         overShort:this.saveReasonObj.overShort,
         registerId:this.saveReasonObj.registerId,
        }]

      this.showCashCountPopup = false
    const _url = environment.OrderBaseURL + 'CashCount/Verification?verifyCheck=true&reasonCheck=true'
    this._confirmationService.confirm({
      header: `Manager Reason Confirmation`,
      message: `Are you sure you want to save the changes?`,
      accept: () => {
        try {
          this.showCashCountPopup = false
         // this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
              this.step = 2
              this.saveReasonObj = {}
              // this.getDrawerCountList();
            }
          })
        } catch {
          this.showCashCountPopup = false
          //this._abd.hideSpinner()
        }
      },
        reject: (type) => {
            this.showCashCountPopup = true
        }
    });

}
  saveTillNotes (){
    const obj = {
      ManagerReason: this.saveTillNotesObj.tillNotes,
      IsVerified: true,
    }
      this.showManagerTillNote = false
    const _url = environment.OrderBaseURL + 'CashDrawer/'+this.saveTillNotesObj.tillId+'?reasonCheck=true'
    this._confirmationService.confirm({
      header: `Manager Reason Confirmation`,
      message: `Are you sure you want to add this reason?`,
      accept: () => {
        try {
          this.showManagerTillNote = false
         // this._abd.showSpinner()
          this._bs.httpPut(_url, obj).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
              this.step = 2
              this.saveTillNotesObj = {}
              this.getDrawerCountList();
            }
          })
        } catch {
          this.showManagerTillNote = false
          //this._abd.hideSpinner()
        }
      },
        reject: (type) => {
                      this.showManagerTillNote = true
        }
    });

}
  confirmCount(what: string) {
    try {
      const t = this.drawerCountList
      let data = []
        let x = {
            "LocationId": this._filterService.selectedLocation.locationId,
            "Dated": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
           "IsVerified": true, //t[i].verify == 'Yes' ? true : false,
           "VerifiedBy": this._globals.userInfo.userID
        }
        data.push(x)

      this._spinner.show()
      const _url = environment.OrderBaseURL + 'CashCount/Verification?onlySave=' + what+'&version=v1'
      this._bs.httpPost(_url, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this.totalDeposit = res.data.amount
          if (what == 'false') {
            const d = res.data
            this.managerConfirmreason=res.data.reason
            this.mockData = [
              { id: 0, bill: '$100', qty: d.dollar100Qty, total: 0, value: 100 },
              { id: 1, bill: '$50', qty: d.dollar50Qty, total: 0, value: 50, verify: true },
              { id: 2, bill: '$20', qty: d.dollar20Qty, total: 0, value: 20, verify: true },
              { id: 3, bill: '$10', qty: d.dollar10Qty, total: 0, value: 10, verify: true },
              { id: 4, bill: '$5', qty: d.dollar5Qty, total: 0, value: 5, verify: true },
              { id: 5, bill: '$2', qty: d.dollar2Qty, total: 0, value: 2, verify: true },
              { id: 6, bill: '$1', qty: d.dollar1Qty, total: 0, value: 1, verify: true },
            ]
            this.mockDataNew = [
                { id: 7, bill: '25 cents(Quarter)', qty: d.cent25Qty, total: 0, value: .25, verify: false },
                { id: 8, bill: '10 cents (Dimes)', qty: d.cent10Qty, total: 0, value: .10, verify: true },
                { id: 9, bill: '5 cents (Nickels)', qty: d.cent5Qty, total: 0, value: .05, verify: true },
                { id: 10, bill: '1 cent (Penny)', qty: d.cent1Qty, total: 0, value: .01, verify: true },
              ]
            this.calcMockup()
            //-----------------------
            this.managerCountObj = d
            this.step = 3
            this.title2 = ' -  Manager Count'
            this.showFilter = false

            this._globals.secondTitle = 'End of Day (EOD) - Manager Count'
          }
          this.calcDrawerCountTotals()
        }
      })
    }
    catch {
      this._spinner.hide()
      return
    }
  }

  saveManagerCount(what: boolean) {
    this.saveManagerCount1(what,1)
  }
  saveManagerCount1(what: boolean,step) {
    const m = this.mockData
    const m1=this.mockDataNew
    const today = new Date()
    const td = new Date(this.selectedRow1.dated)
    const data = {
      "locationId": this.selectedRow1.locationId,
      "amount": (this.totalDeposit + this.totalDepositCent) ? (this.totalDeposit + this.totalDepositCent) : 0,
      "dated": formatDate(today, 'MM/dd/yyyy', 'en-US'),
      "time": today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds(),
      "userId": this._globals.userInfo.userID,
      "reason": this.managerConfirmreason,
      "invoiceNumber": "",
      "details": "",
      "dollar100Qty": m[0].qty ? m[0].qty : 0,
      "dollar50Qty": m[1].qty ? m[1].qty : 0,
      "dollar20Qty": m[2].qty ? m[2].qty : 0,
      "dollar10Qty": m[3].qty ? m[3].qty : 0,
      "dollar5Qty": m[4].qty ? m[4].qty : 0,
      "dollar2Qty": m[5].qty ? m[5].qty : 0,
      "dollar1Qty": m[6].qty ? m[6].qty : 0,
      "cent25Qty": m1[0].qty ? m1[0].qty : 0,
      "cent10Qty": m1[1].qty ? m1[1].qty : 0,
      "cent5Qty": m1[2].qty ? m1[2].qty : 0,
      "cent1Qty": m1[3].qty ? m1[3].qty : 0,
      "CountLevel": "Manager",
      "IsConfirmed": what,
      "CountDated": formatDate(td, 'MM/dd/yyyy', 'en-US')
    }

    const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashCount'
    if (what) {
      this._confirmationService.confirm({
        header: `Bank Deposit Confirmation`,
        message: `Are you sure you want to deposit this amount?
        Once the deposit is made, the cash reconciliation cannot be changed.
        Please double check that the amount and details are correct before proceeding.`,
        accept: () => {
          try {
           // this._abd.showSpinner()
            this._bs.httpPost(_url, data).subscribe(res => {
              this._spinner.hide()
              if (res.success) {
                this.step = step
                this.getDataList()
              }
            })
          } catch { this._abd.hideSpinner() }
        },
        reject: () => { return }
      });
    } else {
      try {
       // this._abd.showSpinner()
        this._bs.httpPost(_url, data).subscribe(res => {
          this._spinner.hide()
          if (res.success) {
            this.step = step
            this.getDataList()
          }
        })
      } catch { this._abd.hideSpinner() }
    }


  }

  verifyAmountByManager(e, item) {
    const obj = [{
        Id: item.id,
        IsVerified: false,
        ManagerCount: item.managerCount,
    }]
    const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&addManagerCount=true'
    // this._confirmationService.confirm({
    //   header: `Manager Amount Confirmation`,
    //   message: `Are you sure you want to add this manager amount into the reconciliation after verification?. Then it will be verified automatically, but when you will do un-verify then it will come again default value`,
    //   accept: () => {
        try {
         // this._abd.showSpinner()
          this._bs.httpPut(_url,obj).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
              this.step = 2
              //this.saveReasonObj = {}
              localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
             
              this.getDrawerCountList();
            }
          })
        } catch {
          //this._abd.hideSpinner()
          this.getDrawerCountList();
        }
    //  },
    //     reject: (type) => {
    //        // tillObj.isVerified = !e.checked;
    //        this.getDrawerCountList();
    //     }
    // });
  }
  itemQtyChanged(e, item) {
    item.qty = e.value
    item.total = item.qty * item.value
    this.getTotalQtyDC()
  }
  itemQtyChangednew(e, item) {
    item.qty = e.value
    item.total = item.qty * item.value
    this.getTotalQtyDCnewCurency()
  }

  itemQtyChangedCD( e, item) {
    if ( e.value=== null) {
        item.qty = 0;
    }
    else {
        item.qty = e.value
        item.total = item.qty * item.value
    }
    this.getTotalQtyDC()
  }

  getTotalQtyCD() {
    try {
      let total = 0
      let sum = 0
      for (let i = 0; i < this.mockDataCD.length; i++) {
          if(this.mockDataCD[i].qty === null){
            this.mockDataCD[i].qty = 0;
          }
        this.mockDataCD[i].total = this.mockDataCD[i].qty * this.mockDataCD[i].value
        sum += this.mockDataCD[i].qty
        total += this.mockDataCD[i].qty * this.mockDataCD[i].value
      }
      this.totalDepositCD = total
      return total
    }
    catch {
      this.totalDepositCD = 0
      return 0
    }
  }

  getTotalQtyDC() {

    try {
      let total = 0
      let sum = 0
      for (let i = 0; i < this.mockData.length; i++) {
        if(this.mockData[i].qty === null){
            this.mockData[i].qty = 0;
          }
        this.mockData[i].total = this.mockData[i].qty * this.mockData[i].value
        sum += this.mockData[i].qty
        total += this.mockData[i].qty * this.mockData[i].value
      }

      this.totalDeposit = total
      return total
    }
    catch {
      this.totalDeposit = 0
      return 0
    }
  }
  getTotalQtyDCnewCurency() {
    try {
      let total = 0
      let sum = 0
      for (let i = 0; i < this.mockDataNew.length; i++) {
        this.mockDataNew[i].total = this.mockDataNew[i].qty * this.mockDataNew[i].value
        sum += this.mockDataNew[i].qty
        total += this.mockDataNew[i].qty * this.mockDataNew[i].value
      }

      this.totalDepositCent = total
      return total
    }
    catch {
      this.totalDepositCent = 0
      return 0
    }
  }

  calcDrawerCountTotals() {
    this.drawerCountSummaryTotals = [
      { name: 'total100', value: 0 },
      { name: 'total50', value: 0 },
      { name: 'total20', value: 0 },
      { name: 'total10', value: 0 },
      { name: 'total5', value: 0 },
      { name: 'total2', value: 0 },
      { name: 'total1', value: 0 },
      { name: 'total25c', value: 0 },
      { name: 'total10c', value: 0 },
      { name: 'total5c', value: 0 },
      { name: 'total1c', value: 0 },
      { name: 'totalCount', value: 0 },
      { name: 'systemCount', value: 0 },
      { name: 'overShort', value: 0 },
      { name: 'lastCount', value: 0 },
    ]

    let d = this.drawerCountSummaryTotals

    for (let i = 0; i < this.drawerCountList.length; i++) {
      const t = this.drawerCountList[i]

      d[0].value += t.dollar100Qty
      d[1].value += t.dollar50Qty
      d[2].value += t.dollar20Qty
      d[3].value += t.dollar10Qty
      d[4].value += t.dollar5Qty
      d[5].value += t.dollar2Qty
      d[6].value += t.dollar1Qty
      d[7].value += t.cent25Qty
      d[8].value += t.cent10Qty
      d[9].value += t.cent5Qty
      d[10].value += t.cent1Qty
      d[11].value += t.amount //t.totalCoinsCount
      d[12].value += t.systemCount
      d[13].value += t.overShort
      d[14].value += t.lastCount
    }
  }

  updateVerify(e) {
    this.selectedDC.verify = e
  }

  dcmenuClick(e) {
    this.selectedDC = e
  }

  onRowSelectStep1(e) {
    try {
      this.isViewOnly = true

      this._filterService.singleDateForReconcile = new Date(e.data.dated)
      this._filterService.selectedLocation = this._abd.locations.find(f => f.locationId == e.data.locationId)
      this.getDrawerCountList()
    } catch { }
  }

  onRowUnselectStep1(e) { }

  backButtonClicked(what) {
    if (what == 2) { 

      this.step = 1
      this._globals.secondTitle = 'End of Day (EOD)'    
      }
    else if (what == 3) {
      this.step = 2
      this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
      // this._confirmationService.confirm({
      //   header: `Save Your Changes`,
      //   message: `Would you like to save your changes before leaving this page?`,
      //   accept: () => {
      //     try {
      //       this.saveManagerCount1(false,2)
      //         this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
      //     } catch { this._abd.hideSpinner() }
      //   },
      //   reject: () => { 
      //     this.step = 2
      //     this._globals.secondTitle = 'End of Day (EOD) - Drawer Count'
      //    }
      // });
    
    }
    this._filterService.dateRange[0] = new Date(this.lastDateRange[0])
    this._filterService.dateRange[1] = new Date(this.lastDateRange[1])
  }

  cashDepositHandler(obj:any) {

    this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.registerId === +obj.registerId)
    
      this.clickedTill = obj
      this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'Active Till not found. (Please check Till Report)'
      })
       this._spinner.hide();
      return
    }
    this.mockDataCD = [
      { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
    ]
    this.showCashDeposit = true
      this.currentOpenedAccordion = 0;
  }

 cashDropsHandler(obj:any) {
    this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
      this.registers = this._abd.registers.filter(f => f.registerId === +obj.registerId)

            this.clickedTill = obj
            this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]        
             if (this._abd.registers?.length < 1 || !this.registers) {
            this._messageService.add({
             severity: 'error',
             summary: 'Error', detail: 'Active Till not found. (Please check Till Report)'
             })
              this._spinner.hide();
             return
             }
            this.mockDataCD = [
              { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
              { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
              { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
              { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
              { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
              { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
              { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
              { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
              { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
              { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
              { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
            ]
            this.showCashDrop = true
            localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
            this.currentOpenedAccordion = 1;
         
  }
  payOutHandler(obj:any) {
     this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
    this.registers = this._abd.registers.filter(f => f.registerId === +obj.registerId)
       
      this.clickedTill = obj
    this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'Active Till not found. (Please check Till Report)'
      })
       this._spinner.hide();
      return
    }
    this.mockDataCD = [
      { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
    ]
    this.getPayOutReasonList();
    this.showPayout = true
    this.currentOpenedAccordion = 2;

  }
  payInHandler(obj:any) {
       this.cdSelectedLocation=  this._abd.locations.find(f=> f.locationId == this._filterService.selectedLocation.locationId)
    if (this.cdSelectedLocation)
    this.registers = this._abd.registers.filter(f => f.registerId === +obj.registerId)
      this.clickedTill = obj
    this.selectedTill = this.drawerCountList.filter(f=> f.tillId == obj.tillId)[0]

    if (this._abd.registers?.length < 1 || !this.registers) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error', detail: 'Active Till not found. (Please check Till Report)'
      })
       this._spinner.hide();
      return
    }
    this.mockDataCD = [
      { bill: '$100', qty: 0, total: 0, value: 100, verify: true },
      { bill: '$50', qty: 0, total: 0, value: 50, verify: true },
      { bill: '$20', qty: 0, total: 0, value: 20, verify: true },
      { bill: '$10', qty: 0, total: 0, value: 10, verify: true },
      { bill: '$5', qty: 0, total: 0, value: 5, verify: true },
      { bill: '$2', qty: 0, total: 0, value: 2, verify: true },
      { bill: '$1', qty: 0, total: 0, value: 1, verify: true },
      { bill: '25 cents(Quarter)', qty: 0, total: 0, value: .25, verify: false },
      { bill: '10 cents (Dimes)', qty: 0, total: 0, value: .10, verify: true },
      { bill: '5 cents (Nickels)', qty: 0, total: 0, value: .05, verify: true },
      { bill: '1 cent (Penny)', qty: 0, total: 0, value: .01, verify: true },
    ]
    this.getPayInReasonList();
    this.showPayIn = true
    this.currentOpenedAccordion = 3;

  }
  postCashDeposit() {
    const obj = {
      LocationId: this.cdSelectedLocation.locationId,
      PartitionKey: this.cdSelectedLocation.locationId,
      RegisterId: this.cdSelectedRegister.registerId,
      Amount: this.totalDepositCD,
      UserId: this._globals.userInfo.userID,
      Dollar100Qty: this.mockDataCD[0].qty,
      Dollar50Qty: this.mockDataCD[1].qty,
      Dollar20Qty: this.mockDataCD[2].qty,
      Dollar10Qty: this.mockDataCD[3].qty,
      Dollar5Qty: this.mockDataCD[4].qty,
      Dollar2Qty: this.mockDataCD[5].qty,
      Dollar1Qty: this.mockDataCD[6].qty,
      Cent25Qty: this.mockDataCD[7].qty,
      Cent10Qty: this.mockDataCD[8].qty,
      Cent5Qty: this.mockDataCD[9].qty,
      Cent1Qty: this.mockDataCD[10].qty,
      CountLevel: 'CashDrawer',
      TillNo: this.selectedTill.tillNo,
    }

      this.showCashDeposit = false
    const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashCount'
    this._confirmationService.confirm({
      header: `Cash Count Confirmation`,
      message: `Are you sure you want to confirm this cash count amount? (Once the count is confirmed, the cash reconciliation cannot be changed. Please double check the amount and details before proceeding).`,
      accept: () => {
        try {
          this.showCashDeposit = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {
            this._spinner.hide()
            if (res.success) {
              this.step = 2
                this.getDrawerCountList()
            }
          })
        } catch {
          this.showCashDeposit = false
          this._abd.hideSpinner()
        }
      },
        reject: (type) => {
            this.showCashDeposit = true
        }
    });
  }
  postCashDrops() {
    const obj = {
        locationId: this.cdSelectedLocation.locationId,
        PartitionKey: this.cdSelectedLocation.locationId,
        registerId: this.cdSelectedRegister.registerId,
        Amount: -1*(this.totalDepositCD),
        UserId: this._globals.userInfo.userID,
        Dollar100Qty: this.mockDataCD[0].qty,
        Dollar50Qty: this.mockDataCD[1].qty,
        Dollar20Qty: this.mockDataCD[2].qty,
        Dollar10Qty: this.mockDataCD[3].qty,
        Dollar5Qty: this.mockDataCD[4].qty,
        Dollar2Qty: this.mockDataCD[5].qty,
        Dollar1Qty: this.mockDataCD[6].qty,
        Cent25Qty: this.mockDataCD[7].qty,
        Cent10Qty: this.mockDataCD[8].qty,
        Cent5Qty: this.mockDataCD[9].qty,
        Cent1Qty: this.mockDataCD[10].qty,
        CountLevel: 'CashDrawer',
        type:'CashDrop',
        time: formatDate(this.dropCashTime, 'H:mm:ss', 'en-US'),
        dated: formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
        TillNo: this.selectedTill.tillNo,
        Reason: 'Cash Drop',
    }

      const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashDrawer?getDateTime=true'
      this.showCashDrop = false
    this._confirmationService.confirm({
      header: `Cash Drops Confirmation`,
      message: `Are you sure you want to drop this amount?
      Once the drop is made, the cash reconciliation cannot be changed.
      Please double check that the amount and details are correct before proceeding.`,

      accept: () => {
        try {
          this.showCashDrop = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {

            if (res.success) {
                this.dropCashTime = new Date();
              this.step = 2
              this.getDrawerCountList()
              this.drawerCountList.filter(f=> f.tillNo == this.selectedTill.tillNo)[0].dropCashSelected = true

               this._spinner.hide()
            }
            else{
               this._spinner.hide()
            }
          })

        } catch {
          this.showCashDrop = false
          this._abd.hideSpinner()
        }
      },
    reject: (type) => {
        this.drawerCountList.filter(f=> f.tillNo == this.selectedTill.tillNo)[0].dropCashSelected = true
        this.showCashDrop = true
    }

    });
  }
  postPayout() {
    const obj = {
        locationId: this.cdSelectedLocation.locationId,
        PartitionKey: this.cdSelectedLocation.locationId,
        registerId: this.cdSelectedRegister.registerId,
        Amount: (this.payoutInputAmount),
        UserId: this._globals.userInfo.userID,
        CountLevel: 'CashDrawer',
        InvoiceNumber: this.payoutInvoiceNumber,
        Reason: this.selectreasonTextArea,
        type:'Payout',
        time: formatDate(this.payoutTime, 'H:mm:ss', 'en-US'),
        dated: formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
        TillNo: this.selectedTill.tillNo,
    }

      const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashDrawer?getDateTime=true'
      this.showPayout = false
    this._confirmationService.confirm({
      header: `Add Payout Confirmation`,
      message: `Are you sure you want to payout this amount?
      Once the payout is made, the cash reconciliation cannot be changed.
      Please double check that the amount and details are correct before proceeding.`,

      accept: () => {
        try {
          this.showPayout = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {

            if (res.success) {
                this.payoutTime = new Date();
              this.step = 2
                this.getDrawerCountList()
               this._spinner.hide()
            }
            else{
                this._spinner.hide()
            }
          })

        } catch {
          this.showPayout = false
          this._abd.hideSpinner()
        }
      },
    reject: (type) => {
        this.showPayout = true
    }

    });
  }
  postPayIn() {
    const obj = {
        locationId: this.cdSelectedLocation.locationId,
        PartitionKey: this.cdSelectedLocation.locationId,
        registerId: this.cdSelectedRegister.registerId,
        Amount: (this.payInInputAmount),
        UserId: this._globals.userInfo.userID,
        CountLevel: 'CashDrawer',
        InvoiceNumber: this.payInInvoiceNumber,
        Reason: this.selectreasonTextArea,
        type:'Payin',
        time: formatDate(this.payInTime, 'H:mm:ss', 'en-US'),
        dated: formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
        TillNo: this.selectedTill.tillNo,
    }

      const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashDrawer?getDateTime=true'
      this.showPayIn = false
    this._confirmationService.confirm({
      header: `Add Pay-in Confirmation`,
      message: `Are you sure you want to pay-in this amount?
      Once the pay-in is made, the cash reconciliation cannot be changed.
      Please double check that the amount and details are correct before proceeding.`,

      accept: () => {
        try {
          this.showPayIn = false
          this._abd.showSpinner()
          this._bs.httpPost(_url, obj).subscribe(res => {

            if (res.success) {
                this.payInTime = new Date();
              this.step = 2
                this.getDrawerCountList()
                this._spinner.hide()
            }
            else{
               this._spinner.hide()
            }
          })

        } catch {
          this.showPayIn = false
          this._abd.hideSpinner()
        }
      },
    reject: (type) => {
        this.showPayIn = true
    }

    });
  }
  postManagerTillConfirmCount() {
    const today = new Date()
    const td = new Date(this.selectedRow1.dated)
      const obj = {
        dated: formatDate(today, 'MM/dd/yyyy', 'en-US'),
        time: today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds(),
        userId: this._globals.userInfo.userID,
        reason: "Manager Till Count",
        invoiceNumber: "",
        details: "",
        CountDated: formatDate(td, 'MM/dd/yyyy', 'en-US'),
        LocationId: this.cdSelectedLocation.locationId,
        RegisterId: '',
        Amount: this.totalDepositCD,
        UserId: this._globals.userInfo.userID,
        Dollar100Qty: this.mockDataCD[0].qty,
        Dollar50Qty: this.mockDataCD[1].qty,
        Dollar20Qty: this.mockDataCD[2].qty,
        Dollar10Qty: this.mockDataCD[3].qty,
        Dollar5Qty: this.mockDataCD[4].qty,
        Dollar2Qty: this.mockDataCD[5].qty,
        Dollar1Qty: this.mockDataCD[6].qty,
        Cent25Qty: this.mockDataCD[7].qty,
        Cent10Qty: this.mockDataCD[8].qty,
        Cent5Qty: this.mockDataCD[9].qty,
        Cent1Qty: this.mockDataCD[10].qty,
        CountLevel: 'TillNo',
        TillNo: this.selectedTill.tillNo,
        IsConfirmed : true,
      }
        this.showMangerTillConfirmAmount = false
      const _url = environment.OrderBaseURL + 'CashDrawer/CreateCashCount'
      this._confirmationService.confirm({
        header: `Manage Till Cash Count Confirmation`,
        message: `Are you sure you want to deposit this amount?
        Once the deposit is made, the cash reconciliation cannot be changed.
        Please double check that the amount and details are correct before proceeding.`,
        accept: () => {
          try {
            this.showMangerTillConfirmAmount = false
            this._abd.showSpinner()
            this._bs.httpPost(_url, obj).subscribe(res => {
              this._spinner.hide()
              if (res.success) {
                this.step = 2
                  this.getDrawerCountList()
              }
            })
          } catch {
            this.showMangerTillConfirmAmount = false
            this._abd.hideSpinner()
          }
        },
          reject: (type) => {
              this.showMangerTillConfirmAmount = true
          }
      });

  }
  isTabletFn() {

    const w = this._abd.getScreenWidth
    const h = this._abd.getScreenHeight

    if (w > 1201) return '25vw'
    if (w < 1200 && w >= 991) return '35vw'
    if (w < 990 && w > 665) return '45vw'
    if (w > 764 && w > 550) return '85vw'
    else return '100vw'
  }

  scrollPanelSize() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    if (isTablet) return '70vh'
    else return '40vh'
  }

  cdLocationChange(e) {
    const loc = e.value.locationId
    if (!loc) return

    this.registers = this._abd.registers.filter(f => f.locationId == loc)
  }

    getParas() {
        this.selectLocationFilter = [];
        this.selectLocationFilter.push(this._filterService.selectedLocation.locationId)

        const data = {
            "ReportName": this.getReportTypeCall(this.currentShowingTableNumber),
            "Parameter": {
                "StartDate": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
                "EndDate": formatDate(this._filterService.singleDateForReconcile, 'MM/dd/yyyy', 'en-US'),
                "LocationIds": this.selectLocationFilter,
                "CashierIds": this.getCashierList(),
                "filterValue": this._filterService.searchText,
            }
        }
        return data
    }

    getReportTypeCall(reportType: number){
        try {
            let returnReportType: string = 'CashDetailReport';
            switch (reportType) {
                case 1:
                    returnReportType
                    break;
                case 4:
                    returnReportType = 'CashAdjustmentDetail'
                    break;

                case 5:
                    returnReportType = 'ExpectedCashDetail'
                    break;

                default:

                    break;
            }
            return returnReportType;
        } catch (error) {

        }
    }


    getDataListForPopUp(objId:number) {
        const url = environment.InventoryBaseURL + environment.Report

        try {
            this._spinner.show()
            this._bs.httpPost(url, JSON.stringify(this.getParas())).subscribe(res => {
                if (res.success) {
                    this.visibleModal = true;
                    if (objId == 1){
                        this.popupHeaderInfo = 'Till Report';
                        this.tillReportData = [];
                        this.tillReportTotal=0;
                        this.tillReportData = res.data.till    
                        if (this.tillReportData.length > 0) {
                            this.tillReportTotal = this.tillReportData.reduce((a, b) => a + b.total, 0)
                        }
                    }


                    if (objId == 4) {
                        this.popupHeaderInfo = 'Adjustments';
                        this.payoutReportData = [];
                        this.payoutReportTotal=0;
                        this.payoutReportData = res.data.payoutDetail
                        if (this.payoutReportData.length > 0) {
                            this.payoutReportTotal = this.payoutReportData.reduce((a, b) => a + b.amount, 0)
                        }

                        this.payInReportData = [];
                        this.payInReportTotal=0;
                        this.payInReportData = res.data.payinDetail
                        if (this.payInReportData.length > 0) {
                            this.payInReportTotal = this.payInReportData.reduce((a, b) => a + b.amount, 0)
                        }

                        this.refundReportData = [];
                        this.refundReportData = res.data.refundDetail
                        if (this.refundReportData.length > 0) {
                            this.refundReportTotal = this.refundReportData.reduce((a, b) => a + b.amount, 0)
                        }
                    }

                    if(objId == 5){
                        this.popupHeaderInfo = 'Expected Cash';
                        this.expectedCashInfoList = [];
                        this.expectedCashInfoList = [
                            {
                                id:1,
                                title: 'Sales',
                                value: res.data.totalSales,
                                allowPopup: false,
                                class: ''
                            },
                            {
                                id: 2,
                                title: 'Pay-in',
                                value: res.data.totalPayin,
                                allowPopup: true,
                                class: 'cursor-pointer'
                            },
                            {
                                id: 3,
                                title: 'Pay-out',
                                value: res.data.totalPayout,
                                allowPopup: true,
                                class: 'cursor-pointer'
                            },
                            {
                                id: 4,
                                title: 'Refunds',
                                value:res.data.totalRefunds,
                                allowPopup: true,
                                class: ''
                            },
                            {
                                id: 5,
                                title: 'Gift Card',
                                value: res.data.totalGiftCardSales,
                                allowPopup: false,
                                class: ''
                            },
                            {
                                id: 5,
                                title: 'Expected Cash',
                                value: res.data.expectedCashAmount,
                                allowPopup: false,
                                class: 'bold-title'
                            },
                        ];
                    }
                    if(objId == 7){
                        this.popupHeaderInfo = 'Manager Reason';

                    }

                }
                else{
                    this.payInReportData = [];
                    this.payoutReportData = [];
                    this.tillReportData = [];
                    this._spinner.hide()
                }
            })
        }
        catch {
            this._spinner.hide()
        }
    }

    showDetail(objId: number, showPopup: boolean){
        if (showPopup){
            this.currentShowingTableNumber = objId;
           
            this.getDataListForPopUp(objId);
        }

    }

    open($event:any){
        this.currentOpenedAccordion = $event.index;
    }

    showOtherPopup(){
        this.currentShowingTableNumber = 4;
        this.visibleModal = true;
        this.getDataListForPopUp(4);
    }

    onSelect($event) {
        try {

            if (this.dropCashTime === null){
                this.dropCashTime = new Date();
            }
        } catch (error) {

        }
    }
    onPayOutSelect($event) {
        try {

            if (this.payoutTime === null){
                this.payoutTime = new Date();
            }
        } catch (error) {

        }
    }
    onPayInSelect($event) {
        try {

            if (this.payInTime === null){
                this.payInTime = new Date();
            }
        } catch (error) {

        }
    }
saveAllDropsConfirmationByManager(cashDrops:any){
  this.saveAllCashDropsConfirmationByManager(cashDrops,2)
}
saveAllCashDropsConfirmationByManager(cashDrops:any,step){
  const obj = cashDrops
  const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&addManagerCount=true'
      try {
        this._abd.showSpinner()
        this._bs.httpPut(_url, obj).subscribe(res => {
          
          if (res.success) {
            this.step = step
            this.saveReasonObj = {}
            localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
            // this.getDrawerCountList();
          }
          this._spinner.hide()
        })
      } catch {
        this._abd.hideSpinner()
      }
}

saveAllCountConfirmationByManager(cashCounts:any){
  this.saveAllCountConfirmByManager(cashCounts,2)  
}
saveAllCountConfirmByManager(cashCounts:any,step){
  let array = [];
  cashCounts.map(function(f){
      array.push({
          Id: f.id,
          IsVerified: f.isVerified,
          ManagerReason: f.managerReason,
          ManagerCount: f.managerCount,
          cent5Qty:f.cent5Qty,
          cent10Qty:f.cent10Qty,
          cent25Qty:f.cent25Qty,
          countedBy:f.countedBy,
          dateTime:f.dateTime,
          dollar1Qty:f.dollar1Qty,
          dollar2Qty:f.dollar2Qty,
          dollar5Qty:f.dollar5Qty,
          dollar10Qty:f.dollar10Qty,
          dollar20Qty:f.dollar20Qty,
          dollar50Qty:f.dollar50Qty,
          dollar100Qty:f.dollar100Qty,
          CountnoDenomination:f.CountnoDenomination,
          overShort:f.overShort,
          registerId:f.registerId,
      })
  })
  const obj = array
  const _url = environment.OrderBaseURL + 'CashCount/Verification?verifyCheck=true'
  this._confirmationService.confirm({
    header: `Cash Count Verification`,
    message: `Are you sure you want to verify the all the cash count amount?`,
    accept: () => {
      try {
        this._abd.showSpinner()
        this._bs.httpPost(_url, obj).subscribe(res => {
          this._spinner.hide()
          if (res.success) {
            this.step = step
            // this.getDrawerCountList();
          }
        })
      } catch {
        this._abd.hideSpinner()
      }
    },
      reject: (type) => {
          // tillObj.checkAllCashCount =false
          // tillObj.isVerified = !e.checked;
      }
  });
}

saveAllPayoutConfirmationByManager(payouts:any){
this.saveAllPayoutConfirmByManager(payouts,2)
}
saveAllPayoutConfirmByManager(payouts:any,step){
  const obj = payouts
  const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&addManagerCount=true'
  
      try {
        this._abd.showSpinner()
        this._bs.httpPut(_url, obj).subscribe(res => {
         
          if (res.success) {
            this.step = step
            this.saveReasonObj = {}
            localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
            // this.getDrawerCountList();
          } 
          this._spinner.hide()
        })
      } catch {
        this._abd.hideSpinner()
      }
}

  saveAllPayinConfirmationByManager(payins:any){
    this.saveAllPayinConfirmByManager(payins,2)
  }
  saveAllPayinConfirmByManager(payins:any,step){
    const obj = payins
    const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&addManagerCount=true'
     try {
          this._abd.showSpinner()
          this._bs.httpPut(_url, obj).subscribe(res => {
           
            if (res.success) {
              this.step = step
              this.saveReasonObj = {}
              localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
              // this.getDrawerCountList();
            }
             this._spinner.hide()
          })
        } catch {
          this._abd.hideSpinner()
        }

  }
//   saveAllDropsConfirmationByManager(tillObj:any){
//     const obj = tillObj.cashDrops
//     const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&reasonCheck=true&addManagerCount=true'
//     this._confirmationService.confirm({
//       header: `Cash Drop Verification`,
//       message: `Are you sure you want to verify the all cash drop amount?`,
//       accept: () => {
//         try {
//           this._abd.showSpinner()
//           this._bs.httpPut(_url, obj).subscribe(res => {
//             this._spinner.hide()
//             if (res.success) {
//               this.step = 2
//               this.getDrawerCountList();
//             }
//           })
//         } catch {
//           this._abd.hideSpinner()
//         }
//       },
//         reject: (type) => {
//             tillObj.checkAllDropCash =false
//         }
//     });
// }
// saveAllCountConfirmationByManager(tillObj:any){
//   // let array = [];
//   // tillObj.cashCounts.map(function(f){
//   //     array.push({
//   //         Id: f.id,
//   //         IsVerified: f.isVerified,
//   //         //ManagerReason: f.managerReason,
//   //         //ManagerCount: f.managerCount,
//   //     })
//   // })
//   const obj = tillObj.cashCounts
//   const _url = environment.OrderBaseURL + 'CashCount/Verification?verifyCheck=true'
//   this._confirmationService.confirm({
//     header: `Cash Count Verification`,
//     message: `Are you sure you want to verify the all the cash count amount?`,
//     accept: () => {
//       try {
//         this._abd.showSpinner()
//         this._bs.httpPost(_url, obj).subscribe(res => {
//           this._spinner.hide()
//           if (res.success) {
//             this.step = 2
//             this.getDrawerCountList();
//           }
//         })
//       } catch {
//         this._abd.hideSpinner()
//       }
//     },
//       reject: (type) => {
//           tillObj.checkAllCashCount =false
//           // tillObj.isVerified = !e.checked;
//       }
//   });
// }
// saveAllPayoutConfirmationByManager(tillObj:any){
//   const obj = tillObj.payouts
//   const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&reasonCheck=true&addManagerCount=true'
//   this._confirmationService.confirm({
//     header: `Payout Amount Verification`,
//     message: `Are you sure you want to verify the all Payout amount?.`,
//     accept: () => {
//       try {
//         this._abd.showSpinner()
//         this._bs.httpPut(_url, obj).subscribe(res => {
//           this._spinner.hide()
//           if (res.success) {
//             this.step = 2
//             this.getDrawerCountList();
//           }
//         })
//       } catch {
//         this._abd.hideSpinner()
//       }
//     },
//       reject: (type) => {
//           tillObj.checkAllPayout =false
//           // tillObj.isVerified = !e.checked;
//       }
//   });
// }
  // saveAllPayinConfirmationByManager(tillObj:any){
  //     let array = [];
  //     tillObj.payins.map(function(f){
  //         array.push({
  //             Id: f.id,
  //             IsVerified: f.isVerified,
  //             ManagerReason: f.managerReason,
  //             ManagerCount: f.managerCount,
  //         })
  //     })
  //     const obj = tillObj.payins
  //     const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true&reasonCheck=true&addManagerCount=true'
  //     this._confirmationService.confirm({
  //       header: `Payin Amount Verification`,
  //       message: `Are you sure you want to verify the all Payin amount?`,
  //       accept: () => {
  //         try {
  //           this._abd.showSpinner()
  //           this._bs.httpPut(_url, obj).subscribe(res => {
  //             this._spinner.hide()
  //             if (res.success) {
  //               this.step = 2
  //               this.getDrawerCountList();
  //             }
  //           })
  //         } catch {
  //           this._abd.hideSpinner()
  //         }
  //       },
  //         reject: (type) => {
  //             tillObj.checkAllPayin =false
  //             // tillObj.isVerified = !e.checked;
  //         }
  //     });
  // }


    verifyAllCashDropByManager(cashDrops:any){
      let isVerified =  cashDrops.every(el => el.isVerified === true);
      if(isVerified === true) return;
    
     let array = [];

     cashDrops.map(function(f){
         array.push({
             Id: f.id,
             IsVerified: true,
         })
     })
     const obj = array
     const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true'
     this._confirmationService.confirm({
       header: `Cash Drop Verification`,
       message: `Are you sure you want to verify the cash drop amounts?`,
       accept: () => {
         try {
           this._abd.showSpinner()
           this._bs.httpPut(_url, obj).subscribe(res => {
             this._spinner.hide()
             if (res.success) {
               this.step = 2
               //this.saveReasonObj = {}
               cashDrops.map(f=> f.isVerified = true)
              //  
               localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
             
              //  this.getDrawerCountList();
             }
           })
         } catch {
           this._abd.hideSpinner()
         }
       },
         reject: (type) => {

         }
     });
 }
    verifyAllPayoutByManager(payouts:any){
      let isVerified =  payouts.every(el => el.isVerified === true);
      if(isVerified === true) return;
       let array = [];
        payouts.map(function(f){
            array.push({
                Id: f.id,
                IsVerified: true,
            })
        })
        const obj = array
        const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true'
        this._confirmationService.confirm({
          header: `Payout Verification`,
          message: `Are you sure you want to verify the payout amounts?`,
          accept: () => {
            try {
              this._abd.showSpinner()
              this._bs.httpPut(_url, obj).subscribe(res => {
                this._spinner.hide()
                if (res.success) {
                  this.step = 2
                  //this.saveReasonObj = {}
                  payouts.map(f=> f.isVerified = true)
                  // 
                  localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
                
                  // this.getDrawerCountList();
                }
              })
            } catch {
              this._abd.hideSpinner()
            }
          },
            reject: (type) => {
               
            }
        });
    }
    verifyAllPayinByManager(payins:any){
      let isVerified =  payins.every(el => el.isVerified === true);
      if(isVerified === true) return;
     
        let array = [];

        payins.map(function(f){
            array.push({
                Id: f.id,
                IsVerified: true,
            })
        })
        const obj = array
        const _url = environment.OrderBaseURL + 'CashDrawers?verifyCheck=true'
        this._confirmationService.confirm({
          header: `Payin Verification`,
          message: `Are you sure you want to verify the payin amounts?`,
          accept: () => {
            try {
              this._abd.showSpinner()
              this._bs.httpPut(_url, obj).subscribe(res => {
                this._spinner.hide()
                if (res.success) {
                  this.step = 2
                  // this.saveReasonObj = {}
                  payins.map(f=> f.isVerified = true)
                  localStorage.setItem('reconcilationIndex', JSON.stringify(this.drawerCountList));
                  // this.getDrawerCountList();
                }
              })
            } catch {
              this._abd.hideSpinner()
            }
          },
            reject: (type) => {
                // tillObj.checkAllPayin =false
                // tillObj.isVerified = false;
            }
        });
    }
  
    // verifyCountByManager(e,verifyObj:any, p:any) {
    //     let verifyManger = e.checked;
    //     p.checkAllDropCash =  p.cashDrops.filter(f=> !f.isVerified).length > 0 ? false : true
    //     p.checkAllPayout =  p.payouts.filter(f=> !f.isVerified).length > 0 ? false : true
    //     p.checkAllPayin =  p.payins.filter(f=> !f.isVerified).length > 0 ? false : true
    //           }
              verifyAllCashCountByManager(e,tillObj:any){
                let verifyManger = e.checked;
                tillObj.checkAllCashCount = verifyManger? true : false
                tillObj.cashCounts.map(f=> f.isVerified = verifyManger? true : false)
                let array = [];

                tillObj.cashCounts.map(function(f){
                    array.push({
                        Id: f.id,
                        IsVerified: f.isVerified,
                    })
                })
            }
      updateList(){
        try {
            this.selectreasonTextArea = this.reasondatalist.find(f=> f.id == this.selectedReason).reason
        } catch (error) {

        }
    }

}

