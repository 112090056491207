<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'purchasing-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        >
    </app-report-top-filter-bar>
    <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [scrollable]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of domColHeader">
                    <strong>{{col}}</strong>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-purchase>
            <tr>
                <td>
                   <span>ggg{{ purchase.locationName }}</span>
                </td>
                <td>
                   <span>{{ purchase.poNumber }}</span>
                </td>
                <td>
                   <span>{{ purchase.poDated | date:'MM/dd/yyyy' }}</span>
                </td>
                <td>
                   <span>{{ purchase.poStatus }}</span>
                </td>
                <td>
                   <span>{{ purchase.itemName }}</span>
                </td>
                <td>
                   <span>{{ purchase.barcode }}</span>
                </td>
                <td>
                   <span>{{ purchase.skuCode }}</span>
                </td>
                <td>
                   <span>{{ purchase.orderedQuantity }}</span>
                </td>
                <td>
                   <span>{{ purchase.receivedQuantity }}</span>
                </td>
                <td>
                   <span>{{ purchase.dueQuantity }}</span>
                </td>
                <td>
                    <span>{{ purchase.orignalCost | currency  }}</span>
                 </td>
                 <td>
                    <span>{{ purchase.orignalTotalCost | currency  }}</span>
                 </td>
                 <td>
                    <span>{{ purchase.receivedCost | currency }}</span>
                 </td>
                 <td>
                    <span>{{ purchase.receivedTotalCost | currency}}</span>
                 </td>
                 <td>
                    <span>{{ purchase.commitQuantity }}</span>
                 </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>

                <td *ngFor="let col of domSummaryflds" style="text-align: center">
                    {{calculateSummaryTotal(col)}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>
</div>
