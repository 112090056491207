<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
Permissions
</h3>
<p-divider></p-divider>
<br>
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Login Permission
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
        {{ _customerService.activeItem?.notificationSettings.sendEmailAlert ? 'Enabled' : 'Disabled' }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Send Marketing Email
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
    {{ _customerService.activeItem?.notificationSettings.sendEmailMarketing ? 'Enabled' : 'Disabled' }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Send Alert SMS
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
    {{ _customerService.activeItem?.notificationSettings.sendSMSAlert ? 'Enabled' : 'Disabled' }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Send Alert SMS
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
    {{ _customerService.activeItem?.notificationSettings.sendSMSMarketing ? 'Enabled' : 'Disabled' }}
    </div>
</div>
<br />
<div class="grid">
    <div class="col-4" style="color: var(--bluegray-300); font-weight: 600px">
        Send Alert SMS
    </div>
    <div
        class="col"
        style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        "
    >
    {{ _customerService.activeItem?.notificationSettings.sendSMSMarketing ? 'Enabled' : 'Disabled' }}
    </div>
</div>

