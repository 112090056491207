<div>
    <ng-container style="margin:50px; padding: 30px;" *ngFor="let item of activeItem">
        <h2 style="color: var(--brandColor); font-weight: 900px; font-size: 24px;">{{ item.recordTypeString }}</h2>
        <div class="shadow-2 p-3 m-5 h-full flex flex-column surface-card" style="border-radius: 6px">
           
            <img *ngIf="item.format=='Image'" src={{item.data}}/>
            <a [href]="item.data" target="_blank" *ngIf="item.format=='Html'">View Receipt</a>
        </div>
    </ng-container>
</div>
