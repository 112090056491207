<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'coupon-code'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (deleteItemFn)="optionDeleteClicked()"
    ></app-report-top-filter-bar>
    <br />
    <p-table
        #dt
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        *ngIf="dataList?.length > 0"
        [autoLayout]="true"
        responsiveLayout="scroll"
        [paginator]="false"
        [rows]="rows"
        [customSort]="true"
        (onLazyLoad)="customSort($event)"
        [lazy]="true"
        showLoader="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} discounts"
        [globalFilterFields]="['fullName']"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        selectionMode="single"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th  style="text-align: center">#</th>
                <th style="text-align: center">Coupon Name</th>
                <th style="text-align: center">Code</th>
                <th style="text-align: center">Discount Type</th>
                <th style="text-align: center">Value</th>
                <th pSortableColumn="isActive" style="text-align: center">Status <p-sortIcon field="isActive"></p-sortIcon></th>
                <th style="text-align: center">Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item >
            <tr [pSelectableRow]="item" class="text-center">
                <td style="text-align: center">{{ item.index }}</td>
                <td style="text-align: center">{{ item.discountName }}</td>
                <td style="text-align: center">{{ item.couponCodesList }}</td>
                <td style="text-align: center">
                    {{getDiscountType(item.discountValueType)}}
                </td>
                <td style="text-align: center">
                   {{getDiscountValue(item.discountValue,item.discountValueType)}}
                </td>
                <td style="text-align: center">
                    <span *ngIf="item.isActive == false" class="disable">Disable</span>
                    <span *ngIf="item.isActive == true" class="enable">Enable</span>
                </td>
                <td style="text-align: center">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="op.toggle($event)"
                        (click)="actionButtonClick(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
    <div *ngIf="totalRecords > 9">
        <p-paginator
            #p
            [first]="first"
            *ngIf="dataList?.length"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
        >
        </p-paginator>
    </div>
</div>

<p-dialog
    *ngIf="couponCodeServices.showEditCoupon"
    header="Edit Coupon"
    [(visible)]="couponCodeServices.showEditCoupon"
    [style]="{ width: '80%' }"
    modal="true"
>
    <app-bizz-coupon-codes-form
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-bizz-coupon-codes-form>
</p-dialog>
<ng-container *ngIf="couponCodeServices.showNewEditCoupon">
    <p-dialog
        header="Add New Coupon"
        [(visible)]="couponCodeServices.showNewEditCoupon"
        [style]="{ width: '80%' }"
        modal="true"
    >
        <app-bizz-coupon-codes-form
            [isNew]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-bizz-coupon-codes-form>
    </p-dialog>
</ng-container>
